/* eslint-disable consistent-return */
import produce from 'immer';
// import { orderBy } from 'lodash';

import {
  PUT_MSG_ON_QUEUE,
  PRINT_MSG_ON_FEED,
  LIST_MSG_AS_SENT,
  SET_HANDLING_QUEUE,
  CHAT_INITIAL_LOAD_REQUEST,
  CHAT_INITIAL_LOAD_SUCCESS,
  NEW_MESSAGE,
  OPEN_CHAT_REQUEST,
  OPEN_CHAT_SUCCESS,
  UPDATE_CHAT_LIST,
  RESET_CHAT_STATE,
  SET_LOADING_USERS,
  SET_NOT_LOADING_USERS,
  MODIFY_USERS_ARRAY,
  MODIFY_USERS_PAGINATION,
  PRINT_OLD_MESSAGES,
  PRINT_OLD_CHATS,
  PRINT_MORE_USERS,
  SET_LOADING_OLD_MSGS,
  SET_LOADING_OLD_CHATS,
  SET_LOADING_MORE_USERS,
  ADD_SELECTED_USER,
  REMOVE_SELECTED_USER,
  REMOVE_ALL_SELECTED_USERS,
  MARK_CHAT_AS_READ_IN_CHATS_ARRAY,
  SET_LOCATION,
  CLEAN_CURRENT_CHAT,
  POPULATE_CHATS_ON_LOGIN,
  LOAD_CHATS_ON_LOGIN,
  DELETE_CHAT,
  REPLACE_EDITED_CHAT,
  SET_ACTIVE_TAB,
  SET_SELECT_MODE_ON,
  SET_CHOSEN_CHAT_TO_MANAGE,
  SET_SHOW_EDIT_CHAT_MODAL,
} from './actionsTypes';

import { ADD_UNREAD_MSG } from '../notifications/actionsTypes';

const initialState = {
  connectionStatus: 'lost',
  loadingMessages: true,
  loadingOldMsgs: false,
  loadingOldChats: false,
  loadingMoreUsers: false,
  usersLoading: true,
  currentChat: null,
  loading: true,
  loadingChatsOnLogin: false,
  handlingQueue: false,
  users: [],
  selectedUsers: [],
  chats: [],
  messages: [],
  queuedMsgs: [],
  msgsPagination: [],
  chatsPagination: [],
  usersPagination: [],
  msgsScrollbarPosition: [],
  chatsScrollbarPosition: [],
  usersScrollbarPosition: [],
  currChatMsgsFirstLoaded: false,
  thereIsStillOldMsgsToBeLoaded: false,
  thereIsStillOldChatsToBeLoaded: false,
  thereIsStillMoreUsersToBeLoaded: false,
  location: null,
  activeTab: '1',
  selectModeOn: false,
  chosenChatToManage: null,
  showEditChatModal: false,
};

export default function Chat(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_CHAT_STATE: {
        return initialState;
      }
      case CHAT_INITIAL_LOAD_REQUEST: {
        draft.loading = true;
        draft.messages = [];
        if (draft.currentChat) {
          draft.currentChat = null;
        }
        break;
      }
      case CHAT_INITIAL_LOAD_SUCCESS: {
        const {
          users,
          usersPagination,
          // chats,
          // chatsPagination,
        } = action.payload;

        draft.users = users;
        draft.usersPagination = usersPagination;
        // draft.chats = chats;
        // draft.chatsPagination = chatsPagination;
        draft.loading = false;
        draft.usersLoading = false;

        break;
      }
      case CLEAN_CURRENT_CHAT: {
        draft.currentChat = null;
        break;
      }
      case PRINT_MSG_ON_FEED: {
        draft.messages = [...draft.messages, action.payload];
        break;
      }
      case PUT_MSG_ON_QUEUE: {
        draft.queuedMsgs = [...draft.queuedMsgs, action.payload];
        break;
      }
      case LIST_MSG_AS_SENT: {
        const message = action.payload;

        draft.messages = draft.messages.map(msg => {
          if (msg.ref === message.ref) {
            return message;
          }
          return msg;
        });

        const filteredQueue = draft.queuedMsgs.filter(
          queuedMsg => queuedMsg.ref !== message.ref
        );
        draft.queuedMsgs = [...filteredQueue];
        break;
      }

      case SET_HANDLING_QUEUE: {
        draft.handlingQueue = action.payload;
        break;
      }

      case OPEN_CHAT_REQUEST: {
        const { chat } = action.payload;
        draft.loadingMessages = true;
        draft.currentChat = chat;
        break;
      }
      case OPEN_CHAT_SUCCESS: {
        const { chat, messages, msgsPagination } = action.payload;

        draft.chats = draft.chats.map(c =>
          c.id === chat.id ? { ...c, has_unread_messages: false } : c
        );

        draft.messages = messages;
        draft.msgsPagination = msgsPagination;
        draft.loadingMessages = false;
        break;
      }

      case NEW_MESSAGE: {
        const { message } = action.payload;

        draft.messages = [...draft.messages, message];
        break;
      }

      case UPDATE_CHAT_LIST: {
        const { chats, chatsPagination } = action.payload;

        draft.chats = chats;
        if (chatsPagination) {
          draft.chatsPagination = chatsPagination;
        }
        break;
      }
      case SET_LOADING_USERS: {
        draft.usersLoading = action.payload;
        break;
      }
      case SET_NOT_LOADING_USERS: {
        draft.usersLoading = false;
        break;
      }
      case MODIFY_USERS_ARRAY: {
        draft.users = action.payload;
        break;
      }
      case MODIFY_USERS_PAGINATION: {
        draft.usersPagination = action.payload;
        break;
      }
      case PRINT_OLD_MESSAGES: {
        const { oldMsgs, msgsPagination } = action.payload;

        draft.messages = [...oldMsgs, ...draft.messages];
        draft.msgsPagination = msgsPagination;

        break;
      }
      case PRINT_OLD_CHATS: {
        const { oldChats, chatsPagination } = action.payload;

        draft.chats = [...draft.chats, ...oldChats];
        draft.chatsPagination = chatsPagination;
        draft.loadingOldChats = false;

        break;
      }
      case PRINT_MORE_USERS: {
        const { incomingUsers, usersPagination } = action.payload;

        draft.users = [...draft.users, ...incomingUsers];
        draft.usersPagination = usersPagination;
        draft.loadingMoreUsers = false;

        break;
      }
      case SET_LOADING_OLD_MSGS: {
        draft.loadingOldMsgs = action.payload;
        break;
      }
      case SET_LOADING_OLD_CHATS: {
        draft.loadingOldChats = action.payload;
        break;
      }
      case SET_LOADING_MORE_USERS: {
        draft.loadingMoreUsers = action.payload;
        break;
      }
      case ADD_SELECTED_USER: {
        draft.selectedUsers = [...draft.selectedUsers, action.payload];
        break;
      }
      case REMOVE_SELECTED_USER: {
        draft.selectedUsers = draft.selectedUsers.filter(user => {
          return user.id !== action.payload.id;
        });
        break;
      }
      case REMOVE_ALL_SELECTED_USERS: {
        draft.selectedUsers = [];
        break;
      }
      case ADD_UNREAD_MSG: {
        if (draft.chats.length) {
          const isInChats = draft.chats.some(
            chat => chat.id === action.payload.id
          );
          if (isInChats) {
            const myDraft = draft.chats.filter(
              chat => chat.id !== action.payload.id
            );

            draft.chats = [action.payload, ...myDraft];
          } else {
            draft.chats = [action.payload, ...draft.chats];
          }
        } else {
          draft.chats = [action.payload];
        }
        break;
      }

      case MARK_CHAT_AS_READ_IN_CHATS_ARRAY: {
        draft.chats = draft.chats.map(chat => {
          if (chat.id === action.payload.id) {
            return action.payload;
          }
          return chat;
        });
        break;
      }

      case SET_LOCATION: {
        draft.location = action.payload;
        break;
      }
      case LOAD_CHATS_ON_LOGIN: {
        draft.loadingChatsOnLogin = true;

        break;
      }
      case POPULATE_CHATS_ON_LOGIN: {
        const { chats, chatsPagination } = action.payload;

        draft.chats = chats;
        draft.chatsPagination = chatsPagination;
        draft.loadingChatsOnLogin = false;
        // if (draft.loading && draft.chats) {
        //   draft.loading = false;
        // }

        break;
      }
      case DELETE_CHAT: {
        draft.chats = draft.chats.filter(chat => chat.id !== action.payload.id);

        break;
      }
      case SET_ACTIVE_TAB: {
        draft.activeTab = action.payload;

        break;
      }
      case SET_SHOW_EDIT_CHAT_MODAL: {
        draft.showEditChatModal = action.payload;

        break;
      }
      case SET_SELECT_MODE_ON: {
        draft.selectModeOn = action.payload;

        break;
      }
      // eslint-disable-next-line no-duplicate-case
      case DELETE_CHAT: {
        draft.chats = draft.chats.filter(c => c.id !== action.payload.id);
        break;
      }
      case SET_CHOSEN_CHAT_TO_MANAGE: {
        draft.chosenChatToManage = action.payload;
        break;
      }
      case REPLACE_EDITED_CHAT: {
        draft.chats = draft.chats.map(chat => {
          if (chat.id === action.payload.id) {
            return action.payload;
          }
          return chat;
        });
        if (draft.currentChat && action.payload.id === draft.currentChat.id) {
          draft.currentChat = action.payload;
        }

        if (action.payload.has_unread_messages) {
          draft.notifications = draft.notifications.map(notification => {
            if (action.payload.id === notification.id) {
              return action.payload;
            }
            return notification;
          });
        }

        break;
      }

      default:
        return state;
    }
  });
}
