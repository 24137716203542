import React, { useMemo } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import history from '~/services/history';
import useRoles from '~/hooks/useRoles';
import { CANCELLED, SEARCHING } from '~/util/deliveryStatuses';
import CancelSaleModal from './Modals/CancelCartModal';
import TransferSaleModal from './Modals/TransferSaleModal';
// import RestartSaleModal from './Modals/RestartSaleModal';

export default function ManageCartButtons({ cart, setData }) {
  const location = useLocation();

  const { isFinancier } = useRoles();

  const isTransferable = useMemo(() => {
    if (
      location.pathname.includes('venda') &&
      cart?.status !== 6 &&
      !isFinancier
    ) {
      return true;
    }
    return false;
  }, [cart, isFinancier, location.pathname]);

  // const showSeeOnStockBtn = useMemo(() => {
  //   if (
  //     location.pathname.includes('venda') &&
  //     isStockist &&
  //     cart?.status > 1 &&
  //     cart?.status !== 6
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [cart, isStockist, location.pathname]);

  // TODO: CIELO: Botão Reprocessar/Estorno, transação recusada (anti-fraude) -> GERENTE/ADMINISTRADOR

  const exceptionMoneyAndCart = useMemo(() => {
    if (
      (cart.payment_type === 201 ||
        cart.payment_type === 202 ||
        cart.payment_type === 203 ||
        cart.payment_type === 204 ||
        cart.payment_type === 401) &&
      cart?.status <= 2
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const isEditable = useMemo(() => {
    if (
      cart.status_text === 'Cancelado' ||
      cart.status_text === 'Pagamento rejeitado' ||
      cart.status_text === 'Pagamento rejeitado pelo anti-fraude' ||
      cart.status_text === 'Análise de Fraude' ||
      cart.status_text === 'Pedido em Processamento' ||
      cart.status_text === 'Análise Reprovada' ||
      cart.status_text === 'Análise Aprovada'
    )
      return false;

    if (
      cart.is_suitcase_delivery &&
      (!cart.delivery || [SEARCHING, CANCELLED].includes(cart.delivery?.status))
    )
      return true;

    if (
      !cart.is_suitcase_delivery &&
      !isFinancier &&
      location.pathname.includes('venda') &&
      (cart?.status < 2 || exceptionMoneyAndCart)
    )
      return true;

    return false;
  }, [cart, isFinancier, location.pathname, exceptionMoneyAndCart]);

  // const isRestartable = useMemo(() => {
  //   if (
  //     !isFinancier &&
  //     location.pathname.includes('venda') &&
  //     (cart?.status === 1 || (cart?.status === 2 && cart.payment_type !== 101))
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [cart, isFinancier, location.pathname]);

  const isCancelable = useMemo(() => {
    if (!isFinancier && cart?.status !== 6) {
      if (cart.is_for_delivery && ![4, 9, 10, 11].includes(cart?.status)) {
        return true;
      }
      if (!cart.is_for_delivery && cart?.status <= 3) {
        return true;
      }
    }
    return false;
  }, [cart, isFinancier]);

  return (
    <Row className="w-100 mx-0" align="end">
      <Col
        className="px-0 d-flex flex-column flex-lg-row justify-content-end"
        align="end"
      >
        {isTransferable && <TransferSaleModal sale={cart} />}

        {/* {showSeeOnStockBtn && (
          <Button
            // color="info"
            className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0"
            onClick={() => history.push(`/pedido/${cart.id}`)}
            disabled={cart?.status < 2}
            outline
          >
            <i className="las la-dolly pl-1 mr-2 font-size-14" />
            Ver no Estoque
          </Button>
        )} */}

        {isEditable && (
          <Button
            // color="primary"
            className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0"
            onClick={e => {
              e.stopPropagation();
              history.push(`/registrar/${cart.id}`);
            }}
            outline
          >
            <i className="las la-pen pl-1 mr-2 font-size-14" />
            Editar
          </Button>
        )}

        {/* {isRestartable && (
          <RestartSaleModal
            sale={cart}
            setData={setData}
            setProductsData={setProductsData}
          />
        )} */}

        {isCancelable && <CancelSaleModal sale={cart} setData={setData} />}
      </Col>
    </Row>
  );
}
