export const SUBSCRIBER_USER_REQUEST = '@chat/SUBSCRIBER_USER_REQUEST';
export const SUBSCRIBER_USER_SUCCESS = '@chat/SUBSCRIBER_USER_SUCCESS';
export const UNSUBSCRIBER_USER = '@chat/UNSUBSCRIBER_USER';

export const CONNECT = '@chat/CONNECT';
export const RECONNECT = '@chat/RECONNECT';

export const CONNECTION_LOST = '@chat/CONNECTION_LOST';

export const CHAT_INITIAL_LOAD_REQUEST = '@chat/INITIAL_LOAD_REQUEST';
export const CHAT_INITIAL_LOAD_SUCCESS = '@chat/INITIAL_LOAD_SUCCESS';

export const LOAD_CHATS_ON_LOGIN = '@chat/LOAD_CHATS_ON_LOGIN';
export const POPULATE_CHATS_ON_LOGIN = '@chat/POPULATE_CHATS_ON_LOGIN';

export const NEW_MESSAGE = '@chat/NEW_MESSAGE';
export const NEW_MESSAGE_RECEIVED = '@chat/NEW_MESSAGE_RECEIVED';
export const PRINT_MSG_ON_FEED = '@chat/PRINT_MESSAGE_ON_FEED'; // PRINT NEW ESSAGE ON FEED
export const PUT_MSG_ON_QUEUE = '@chat/PUT_MSG_ON_QUEUE'; // PUT MESSAGE ON QUEUE
export const SEND_MSG_TO_SERVER = '@chat/SEND_MSG_TO_SERVER'; // SEND MESSAGE TO SERVER AND INSERT IT INTO sentMessages ARRAY WITHIN THE STATE
export const LIST_MSG_AS_SENT = '@chat/LIST_MSG_AS_SENT';
export const SET_QUEUE_BUSY = '@chat/SET_QUEUE_BUSY';
export const SET_QUEUE_NOT_BUSY = '@chat/SET_QUEUE_NOT_BUSY';
export const SET_HANDLING_QUEUE = '@chat/SET_HANDLING_QUEUE';

export const NEW_CHAT = '@chat/NEW_CHAT';
export const OPEN_CHAT_REQUEST = '@chat/OPEN_CHAT_REQUEST';
export const OPEN_CHAT_SUCCESS = '@chat/OPEN_CHAT_SUCCESS';
export const LOAD_CHATS_REQUEST = '@chat/LOAD_CHATS_REQUEST';
export const LOAD_CHATS_SUCCESS = '@chat/LOAD_CHATS_SUCCESS';
export const UPDATE_CHAT_LIST = '@chat/UPDATE_CHAT_LIST';
export const REORDER_CHAT_LIST = '@chat/REORDER_CHAT_LIST';
export const RELOAD_CHAT_LIST = '@chat/RELOAD_CHAT_LIST';
export const CREATE_CHAT_REQUEST = '@chat/CREATE_CHAT_REQUEST';
export const DELETE_CHAT = '@chat/DELETE_CHAT';

export const RESET_CHAT_STATE = '@chat/RESET_CHAT_STATE';
export const SET_LOCATION = '@chat/SET_LOCATION';
export const CLEAN_CURRENT_CHAT = '@chat/CLEAN_CURRENT_CHAT';

export const SEARCH_USER = '@chat/SEARCH_USER';
export const SET_LOADING_USERS = '@chat/SET_LOADING_USERS';
export const SET_NOT_LOADING_USERS = '@chat/SET_NOT_LOADING_USERS';
export const MODIFY_USERS_ARRAY = '@chat/MODIFY_USERS_ARRAY';
export const MODIFY_USERS_PAGINATION = '@chat/MODIFY_USERS_PAGINATION';

export const LOAD_OLD_MESSAGES = '@chat/LOAD_OLD_MESSAGES';
export const PRINT_OLD_MESSAGES = '@chat/PRINT_OLD_MESSAGES';

export const LOAD_OLD_CHATS = '@chat/LOAD_OLD_CHATS';
export const PRINT_OLD_CHATS = '@chat/PRINT_OLD_CHATS';

export const LOAD_MORE_USERS = '@chat/LOAD_MORE_USERS';
export const PRINT_MORE_USERS = '@chat/PRINT_MORE_USERS';

export const SET_LOADING_OLD_MSGS = '@chat/SET_LOADING_OLD_MSGS';
export const SET_LOADING_OLD_CHATS = '@chat/SET_LOADING_OLD_CHATS';
export const SET_LOADING_MORE_USERS = '@chat/SET_LOADING_MORE_USERS';

export const CREATE_NEW_GROUP_CHAT = '@chat/CREATE_NEW_GROUP_CHAT';
export const REMOVE_ALL_SELECTED_USERS = '@chat/REMOVE_ALL_SELECTED_USERS';

export const ADD_SELECTED_USER = '@chat/ADD_SELECTED_USER';
export const REMOVE_SELECTED_USER = '@chat/REMOVE_SELECTED_USER';

export const EDIT_CHAT = '@chat/EDIT_CHAT';
export const REPLACE_EDITED_CHAT = '@chat/REPLACE_EDITED_CHAT';

// export const HANDLE_CHAT_NOTIFICATION = '@chat/HANDLE_CHAT_NOTIFICATION';
export const MARK_AS_READ = '@chat/MARK_AS_READ';
export const MARK_CHAT_AS_READ_IN_CHATS_ARRAY =
  '@chat/MARK_CHAT_AS_READ_IN_CHATS_ARRAY';

export const SET_ACTIVE_TAB = '@chat/SET_ACTIVE_TAB';
export const SET_SELECT_MODE_ON = '@chat/SET_SELECT_MODE_ON';
export const SET_CHOSEN_CHAT_TO_MANAGE = '@chat/SET_CHOSEN_CHAT_TO_MANAGE';

export const SET_SHOW_EDIT_CHAT_MODAL = '@chat/SET_SHOW_EDIT_CHAT_MODAL';
