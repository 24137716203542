import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import history from '~/services/history';

// Import Images
import error from '~/assets/images/error-img.png';

const Pages404 = () => {
  return (
    <>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4
                  <i className="bx bx-buoy bx-spin text-primary display-3" />4
                </h1>
                <h4 className="text-uppercase">
                  Desculpa, página não encontrada
                </h4>
                <div className="mt-5 text-center">
                  <Button
                    color="secondary"
                    className="waves-effect waves-light"
                    onClick={() => history.goBack()}
                  >
                    Voltar para página anterior
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Pages404;
