import {
  REFRESH_TOKEN,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  API_ERROR,
  SET_LOADING,
  UPDATE_USER,
} from './actionsTypes';

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER,
  };
};

export const saveUserRequest = () => {
  return {
    type: SAVE_USER,
  };
};

export const saveUserData = user => {
  return {
    type: SAVE_USER_SUCCESS,
    payload: user,
  };
};

export const refreshToken = token => {
  return {
    type: REFRESH_TOKEN,
    payload: token,
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const SetLoading = flag => {
  return {
    type: SET_LOADING,
    payload: flag,
  };
};
