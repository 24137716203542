import React from 'react';

import { Col, Row } from 'reactstrap';
import QRCodeLib from 'react-qr-code';

export default function DeliveryQrcode({ qrCode, children }) {
  return (
    <>
      <Row className="mx-0 mt-2">
        <Col xs={12} lg={7} className="px-0">
          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end justify-content-between">
            <Col className="px-0">
              Este é o QR Code para validação de entrega Uber!
            </Col>
            <Col xs={12} className="px-0 mt-2">
              Peça para o entregador validar fazendo o scan do QR Code!
            </Col>
            <Col xs={12} className="px-0 mt-2">
              <QRCodeLib value={qrCode} size={140} />
            </Col>
          </Row>
          {children}
        </Col>
      </Row>
    </>
  );
}
