import { Card } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Button,
  CardBody,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

export default function FaqCard({ faq, onEdit, onDelete, canManage }) {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <Card
      className="card new-layout list-card alt"
      id={`collapse-btn-${faq.id}`}
      role="button"
      onClick={() => {
        setShowAnswer(!showAnswer);
      }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col xs={11} className="card-title">
            {faq.question}
          </Col>
          <Col xs={1} align="end">
            <i
              className={`las la-${
                showAnswer ? 'minus' : 'plus'
              } text-secondary font-size-24`}
              id={`expand-${faq.id}`}
            />
            <UncontrolledTooltip target={`expand-${faq.id}`}>
              {showAnswer ? 'Mostrar Menos' : 'Mostrar Mais'}
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Collapse isOpen={showAnswer} toggler={`collapse-btn-${faq.id}`}>
          <Row className="w-100 mx-0 my-4">
            <Col className="card-value">{faq.formatted_answer}</Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col align="end">
              {canManage && (
                <>
                  <Button
                    className="new-layout-btn main"
                    color="primary"
                    onClick={() => onEdit(faq)}
                  >
                    <i className="las la-pen mr-2 font-size-18" />
                    Editar FAQ
                  </Button>
                  <Button
                    className="new-layout-btn main mt-2 mt-lg-0 mx-0 ml-lg-2"
                    color="danger"
                    onClick={() => onDelete(faq)}
                  >
                    <i className="las la-trash mr-2 font-size-18" />
                    Excluir FAQ
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
