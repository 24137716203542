export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

/**
 * Function to format currency BRL to number.
 *
 * Usage:
 *
 * ```js
 * const value = 'R$ 1.000,00';
 * formatPriceToNumber(value); // 1000.00
 * ```
 * @param {string} money Value to format
 */
export const formatPriceToNumber = money => {
  const parsedPrice = Number(money.replace(/[^0-9,-]+/g, '').replace(',', '.'));
  return Number(parsedPrice);
};

export const capitalize = words => {
  if (!words) return '';

  return words
    .split(' ')
    .map(word =>
      word.length <= 2
        ? word.toLowerCase()
        : word[0].toUpperCase() + word.substring(1).toLowerCase()
    )
    .join(' ');
};

export const normalizedText = text => {
  const emailNormalized = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return emailNormalized;
};

export const formatPhone = phone => {
  if (!/^\d+$/.test(phone)) return phone;
  const ddd = phone.slice(0, 2);
  let first;
  let last;
  if (phone[2] === '9' && phone.length === 11) {
    first = phone.slice(2, 7);
    last = phone.slice(7, 11);
  } else {
    first = phone.slice(2, 6);
    last = phone.slice(6, 10);
  }
  return `(${ddd}) ${first}-${last}`;
};

export const formatWhatsappPhoneNumber = phoneNumber => {
  if (phoneNumber == null) return '';

  // Remova todos os caracteres não numéricos do número de telefone
  let cleanedNumber = phoneNumber.replace(/\D/g, '');

  // Verifique se o número começa com o prefixo do país (por exemplo, +55) e remova-o
  if (cleanedNumber.startsWith('55')) {
    cleanedNumber = cleanedNumber.substring(2);
  }

  if (cleanedNumber.startsWith('0')) {
    cleanedNumber = cleanedNumber.substring(1);
  }

  return `55${cleanedNumber}`;
};
