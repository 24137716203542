import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import RichTextEditor from 'react-rte';

import { toast } from 'react-toastify';
import ReactHtmlParser from 'react-html-parser';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';
import history from '~/services/history';
import { toolbarConfig } from '~/util/textToolbarConfig';
import useRoles from '~/hooks/useRoles';

const AddFAQ = ({ addModalOpen, setAddModalOpen, setFAQS }) => {
  const formRef = useRef(null);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const { isAdmin } = useRoles();

  useEffect(
    () =>
      addModalOpen
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open'),
    [addModalOpen]
  );

  const toggle = () => setAddModalOpen(!addModalOpen);

  const onSubmitHandler = async faq => {
    try {
      formRef.current.setErrors({});
      const answer = value.toString('html');

      const schema = Yup.object().shape({
        question: Yup.string().required('Digite a pergunta'),
      });

      await schema.validate(faq, {
        abortEarly: false,
      });

      faq = { ...faq, answer };

      await api.post(`/administrator/faqs`, faq);

      const { data } = await api.get(`faqs`);

      setFAQS(
        data.frequently_asked_questions.map(faqIndex => ({
          ...faqIndex,
          formatted_answer: ReactHtmlParser(faqIndex.answer),
        }))
      );

      toast.success('FAQ criada com sucesso!');
      toggle();
      document.body.style.cssText = null;

      history.push(`/faq`);
      setValue(RichTextEditor.createEmptyValue());
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  return (
    <div className="align-self-end">
      {isAdmin && (
        <Button
          className="new-layout-btn main"
          onClick={toggle}
          color="primary"
        >
          <i className="fas fa-question font-size-16 align-middle mr-2" />
          Cadastrar FAQ
        </Button>
      )}
      <Modal isOpen={addModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Nova FAQ</ModalHeader>
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Pergunta</Label>
                  <Input
                    name="question"
                    type="text"
                    placeholder="Qual o título da pergunta?"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Resposta</Label>
                  <RichTextEditor
                    name="answer"
                    value={value}
                    onChange={a => setValue(a)}
                    toolbarConfig={toolbarConfig}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="new-layout-btn main">
              Criar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddFAQ;
