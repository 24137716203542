export const SYSTEM_ROLES = [
  { flag: 'isMaster', slug: 'master' },
  { flag: 'isAdmin', slug: 'administrator' },
  { flag: 'isFinancier', slug: 'financier' },
  { flag: 'isStoreManager', slug: 'store-manager' },
  { flag: 'isSalesman', slug: 'salesman' },
  { flag: 'isConsultant', slug: 'digital-consultant' },
  { flag: 'isCrossManager', slug: 'cross-manager' },
  { flag: 'isStockist', slug: 'stockist' },
  { flag: 'isSupport', slug: 'support' },
];
