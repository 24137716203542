export const SEARCHING = 'SEARCHING';
export const GOING_TO_STORE = 'GOING_TO_STORE';
export const ARRIVED_AT_STORE = 'ARRIVED_AT_STORE';
export const ON_DELIVERY = 'ON_DELIVERY';
export const ARRIVED_AT_DESTINATION = 'ARRIVED_AT_DESTINATION';
export const RETURNING_TO_STORE = 'RETURNING_TO_STORE';
export const DELIVERED = 'DELIVERED';
export const CANCELLED = 'CANCELED';
export const SCHEDULED = 'SCHEDULED';
export const GOING_TO_CLIENT = 'GOING_TO_CLIENT';
export const ARRIVED_AT_CLIENT = 'ARRIVED_AT_CLIENT';
