import {
  ADD_UNREAD_MSG,
  ADD_NOTIFICATION,
  HANDLE_NOTIFICATION,
  HANDLE_UNREAD_MSG,
  LOAD_UNREAD_MSGS_REQUEST,
  LOAD_NOTIFICATIONS_REQUEST,
  LOAD_UNREAD_MSGS_SUCCESS,
  LOAD_NOTIFICATIONS_SUCCESS,
  REMOVE_UNREAD_MSG,
  SET_LOADING_NOTIFICATIONS,
  SET_LOADING_UNREAD_MSGS,
  SUBSCRIBE_USER_REQUEST,
  REMOVE_NOTIFICATION,
  SET_NOTIFICATIONS_ARRAY,
  LOAD_OLDER_NOTIFICATIONS,
  INCREMENT_UNREAD_NOTIFICATIONS_COUNT,
  DECREMENT_UNREAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATIONS_PAGE_ARRAY,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  SET_COUNTER_TO_ZERO,
} from './actionsTypes';

export const subscribeUserRequest = () => {
  return {
    type: SUBSCRIBE_USER_REQUEST,
  };
};

export const setNotificationsArray = notifications => {
  return {
    type: SET_NOTIFICATIONS_ARRAY,
    payload: notifications,
  };
};

export const setNotificationsPageArray = notifications => {
  return {
    type: SET_NOTIFICATIONS_PAGE_ARRAY,
    payload: notifications,
  };
};

export const loadOlderNotifications = data => {
  return {
    type: LOAD_OLDER_NOTIFICATIONS,
    payload: data,
  };
};

export const incrementUnreadNotificationsCount = () => {
  return {
    type: INCREMENT_UNREAD_NOTIFICATIONS_COUNT,
  };
};

export const decrementUnreadNotificationsCount = () => {
  return {
    type: DECREMENT_UNREAD_NOTIFICATIONS_COUNT,
  };
};

export const HandleNotification = notification => {
  return {
    type: HANDLE_NOTIFICATION,
    payload: notification,
  };
};

export const SetCounterToZero = () => {
  return {
    type: SET_COUNTER_TO_ZERO,
  };
};

export const MarkAllAsRead = () => {
  return {
    type: MARK_ALL_NOTIFICATIONS_AS_READ,
  };
};

export const HandleChatNotification = newMessage => {
  return {
    type: HANDLE_UNREAD_MSG,
    payload: newMessage,
  };
};

export const AddNotification = notification => {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
};

export const AddUnreadMsg = notification => {
  return {
    type: ADD_UNREAD_MSG,
    payload: notification,
  };
};

export const RemoveNotification = notification => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: notification,
  };
};

export const RemoveChatNotification = notification => {
  return {
    type: REMOVE_UNREAD_MSG,
    payload: notification,
  };
};

export const LoadNotificationsRequest = () => {
  return {
    type: LOAD_NOTIFICATIONS_REQUEST,
  };
};

export const LoadUnreadMsgsRequest = () => {
  return {
    type: LOAD_UNREAD_MSGS_REQUEST,
  };
};

export const LoadUnreadMsgsSuccess = notifications => {
  return {
    type: LOAD_UNREAD_MSGS_SUCCESS,
    payload: notifications,
  };
};

export const LoadNotificationsSuccess = notifications => {
  return {
    type: LOAD_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const SetLoadingNotifications = flag => {
  return {
    type: SET_LOADING_NOTIFICATIONS,
    payload: flag,
  };
};

export const SetLoadingUnreadMsgs = flag => {
  return {
    type: SET_LOADING_UNREAD_MSGS,
    payload: flag,
  };
};
