import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatPrice } from '~/util/format';
// import { getRole } from '~/Util/getRole';
import { breakString, defineTableItemBorders } from '~/util/newLayoutHelpers';

const formatUsesString = uses => {
  if (uses <= 1) {
    return `${uses === 0 ? 'Nenhum' : uses} Uso`;
  }
  return `${uses} Usos`;
};

// const PAGES = roles => [
const PAGES = () => [
  {
    pathname: '/clientes',
    get_url: '/clients',
    title: 'Clientes',
    object_name: 'clients',
    formatData: clients => {
      return clients.map(client => ({
        ...client,
        broken_name: breakString(client.name, ' ', 12),
        broken_email: breakString(client.email, '@', 25),
      }));
    },
  },
  {
    pathname: '/produtos',
    get_url: '/products',
    title: 'Produtos',
    object_name: 'products',
    formatData: products => {
      return products.map(product => ({
        ...product,
        broken_name: breakString(product.name, ' ', 12),
        formatted_created_at: format(
          parseISO(product.created_at),
          "dd MMM yy '-' HH'h'mm",
          {
            locale: ptBR,
          }
        ),
      }));
    },
  },
  {
    pathname: '/usuarios',
    get_url: '/users',
    title: 'Usuários',
    object_name: 'users',
    formatData: users => {
      return users.map(user => ({
        ...user,
        broken_name: breakString(user.name, ' ', 12),
        broken_email: breakString(user.email, '@', 25),
        formatted_updated_at: format(
          parseISO(user.updated_at),
          "HH'h'mm '-' dd/MMM/yy",
          {
            locale: ptBR,
          }
        ),
      }));
    },
  },
  {
    pathname: '/suportes',
    get_url: '/master/support-users',
    title: 'Suporte',
    object_name: 'users',
    formatData: users => {
      return users.map(user => ({
        ...user,
        broken_name: breakString(user.name, ' ', 12),
        broken_email: breakString(user.email, '@', 25),
        formatted_updated_at: format(
          parseISO(user.updated_at),
          "HH'h'mm '-' dd/MMM/yy",
          {
            locale: ptBR,
          }
        ),
      }));
    },
  },
  {
    pathname: '/codigos-de-indicacao',
    url: `/administrator/referral_codes`,
    title: 'Códigos de Indicação',
    object_name: 'referral_codes',
    formatData: referral_codes => {
      return referral_codes.map((code, index) => ({
        ...code,
        formatted_carts_amount: formatPrice(code.carts_amount),
        formatted_carts_count: formatUsesString(code.carts_count),
        formatted_carts_month_amount: formatPrice(code.carts_month_amount),
        formatted_carts_month_count: formatUsesString(code.carts_month_count),
        border_class: defineTableItemBorders(index, referral_codes.length),
      }));
    },
  },
];

export default PAGES;
