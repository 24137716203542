import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, CustomInput, Button } from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Pagination from '~/components/Pagination';

import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import Loading from '~/components/Loading';
// import DropdownFilter from '~/components/DropdownFilter';
import history from '~/services/history';
import api from '~/services/api';
import DeleteModal from '~/components/Modals/DeleteModal';
import SubscriberProfileCard from './SubscriberCard';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [allSubscribers, setAllSubscribers] = useState(false);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [searchQuery, setSearchQuery] = useState(null);

  const [subscriberToBeDeleted, setSubscriberToBeDeleted] = useState(null);
  const [showDeleteSubscriberModal, setShowDeleteSubscriberModal] =
    useState(false);

  const getURL = '/master/subscribers';

  const deleteURL = useMemo(() => {
    return `${getURL}/${subscriberToBeDeleted?.id}`;
  }, [getURL, subscriberToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    setSubscribers(
      data.subscribers.map(s => {
        return {
          ...s,
          formatted_created_at: format(parseISO(s.created_at), 'dd/MMMM/yyyy', {
            locale: ptBR,
          }),
        };
      })
    );
    setPagination(data.meta);
  };

  useEffect(() => {
    document.title = 'Assinantes | Viddy';
    const loadSubscribers = async () => {
      const { data } = await api.get(getURL);
      setData(data);

      setLoading(false);
    };
    loadSubscribers();
  }, [getURL]);

  const handleSelectOrUnselectSubscriber = (subscriber, isSelected) => {
    if (isSelected) {
      setSelectedSubscribers(
        selectedSubscribers.filter(u => u.id !== subscriber.id)
      );
    } else {
      setSelectedSubscribers([...selectedSubscribers, subscriber]);
    }
  };

  useEffect(() => {
    if (allSubscribers) {
      setSelectedSubscribers(subscribers);
    } else {
      setSelectedSubscribers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubscribers]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="LISTA DE ASSINANTES"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            <Button
              color="primary"
              className="new-layout-btn main"
              onClick={() => history.push('/cadastrar-assinante')}
            >
              <i className="las la-plus font-size-16 mr-2" />
              Cadastrar Assinante
            </Button>
          </Col>
        </Row>
        {/* <Row className="w-100 mx-0 mb-4">
          <Col className="px-0" lg={6} align="start">
            <div className="d-flex flex-row align-items-baseline">
              <span className="text-left mt-0 d-inline text-muted font-size-14 mr-2">
                Filtros
              </span>
              <DropdownFilter
              name="inactive-subscribers"
              id="inactive-subscribers"
              defaultOption={showOnlyInactiveSubscribers}
              options={activeSubscribersFilterOptions}
              url={getURL}
              setData={setData}
              setLoading={setLoading}
              filter={showOnlyInactiveSubscribers}
              setFilter={setShowOnlyInactiveSubscribers}
              params={params}
              query={searchQuery}
              setQuery={setSearchQuery}
              additionalClassName="mx-0"
              width={165}
              />
            </div>
          </Col>
        </Row> */}
        {loading ? (
          <Loading />
        ) : (
          <>
            {subscribers?.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                  <Col xs={1} align="center">
                    <CustomInput
                      type="checkbox"
                      id="allSubscribers"
                      className="text-primary z-index-0 d-none"
                      checked={allSubscribers}
                      defaultValue={allSubscribers}
                      onChange={() => setAllSubscribers(!allSubscribers)}
                    />
                  </Col>

                  <Col
                    xs={4}
                    align="start"
                    // style={{ paddingLeft: '1.25rem' }}
                  >
                    <div className="new-layout-table-column-label">
                      Nome Fantasia
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">CNPJ</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">
                      Cadastro
                    </div>
                  </Col>
                  {/* <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Status</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Próximo Pagamento
                    </div>
                  </Col> */}
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {subscribers?.map(subscriber => {
                  const isSelected = selectedSubscribers.some(
                    s => s.id === subscriber.id
                  );

                  return (
                    <SubscriberProfileCard
                      key={subscriber.id}
                      subscriber={subscriber}
                      isSelected={isSelected}
                      onSelect={handleSelectOrUnselectSubscriber}
                      onDelete={incomingSubscriber => {
                        setSubscriberToBeDeleted(incomingSubscriber);
                        setShowDeleteSubscriberModal(true);
                      }}
                    />
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum assinante encontrado.</h3>
            )}
          </>
        )}
      </Container>
      <DeleteModal
        show={showDeleteSubscriberModal}
        setShow={setShowDeleteSubscriberModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={subscribers}
        setItemToBeDeleted={setSubscriberToBeDeleted}
        headerText="Deseja mesmo excluir o assinante?"
        bodyText="Essa ação irá excluir o assinante"
        itemName={subscriberToBeDeleted?.name}
        successMsg="Assinante excluído com sucesso!"
      />
    </div>
  );
};

export default Subscribers;
