import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import api from '~/services/api';
import { LG } from '~/util/breakpointWidths';

export default function BatchActions({
  defaultOption,
  setData,
  params,
  successMsg,
  name,
  id,
  placeholder,
  additionalClassName,
  width,
  clearable,
  keepPage,
  setReqRunning,
  getURL,
  itemsArray,
  deleteURL,
  selectedItems,
  setSelectedItems,
  pagination,
  tip,
  tipPlacement,
  allItems,
  setAllItems,
  objName,
  selectModeOn,
  setSelectModeOn,
  setLoading,
}) {
  const [chosenOption, setChosenOption] = useState(null);

  const indicatorWidth = 21;
  const controlPadding = 8;

  const controlWidth = useMemo(() => {
    if (width) {
      return width;
    }
    return 150;
  }, [width]);

  const valuelWidth = useMemo(() => {
    const multiplier = clearable ? 2 : 1;
    return controlWidth - multiplier * indicatorWidth - 2 * controlPadding;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlWidth]);

  const placeholderWidth = useMemo(() => {
    return controlWidth - indicatorWidth - 2 * controlPadding;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlWidth]);

  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const items = useMemo(() => {
    const { [objName]: arr } = selectedItems;
    return arr;
  }, [selectedItems, objName]);

  const customStyles = {
    control: (_, state) => ({
      height: '37px',
      minWidth: 'auto',
      width: controlWidth,
      padding: controlPadding,
      display: 'flex',
      flexDirextion: 'row',
      backgroundColor: `${
        state.isDisabled ? 'rgba(37, 68, 177, 0.15)' : 'white'
      } !important`,
    }),
    menu: (provided, _) => ({
      ...provided,
      width: controlWidth,
    }),
    option: (prov, _) => ({
      ...prov,
      textAlign: 'start',
      fontWeight: '500 !important',
      fontColor: 'black !important',
    }),
    valueContainer: () => ({
      height: '21px',
      display: 'inline',
      padding: 0,
      cursor: 'default',
    }),
    singleValue: () => ({
      height: '21px',
      width: valuelWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'start',
    }),
    indicatorsContainer: () => ({
      height: '21px',
      display: 'flex',
      flexDirextion: 'row',
      justifyContent: 'flex-end',
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      height: '21px',
      padding: 0,
    }),
    clearIndicator: () => ({
      height: '21px',
      padding: 0,
    }),

    placeholder: (_, state) => ({
      fontSize: '14px',
      fontWeight: `${state.isDisabled ? 400 : 600} !important`,
      color: `${state.isDisabled ? 'rgba(37, 68, 177)' : 'black'} !important`,
      width: placeholderWidth,
      textAlign: 'start',
    }),
  };

  const page = useMemo(() => {
    if (keepPage) {
      if (pagination?.current_page === 1) {
        return null;
      }
      if (itemsArray?.length < pagination?.per_page) {
        return params?.page - 1;
      }
      return params?.page;
    }
    return null;
  }, [itemsArray, pagination, params, keepPage]);

  const batchDelete = useCallback(async () => {
    try {
      if (setReqRunning) {
        setReqRunning(true);
      }

      setLoading(true);

      await api.delete(deleteURL, {
        data: { ...selectedItems },
      });

      const { data } = await api.get(getURL, {
        params: {
          ...params,
          page,
        },
      });

      setData(data);
      setSelectedItems([]);

      if (selectModeOn) {
        setSelectModeOn(false);
      }

      if (setReqRunning) {
        setReqRunning(false);
      }

      setChosenOption(null);

      setLoading(false);

      toast.success(successMsg || 'Itens removidos com sucesso!');
    } catch (err) {
      toast.error('Ocorreu um erro');

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteURL, selectedItems, setReqRunning, itemsArray, params]);

  const options = useMemo(() => {
    return [
      {
        value: 'delete',
        label: 'Deletar',
        action: batchDelete,
        available: windowSize >= LG ? !!deleteURL : items?.length > 1,
      },
      {
        value: 'select-all',
        label: 'Selecionar Todos',
        action: () => {
          setAllItems(true);
          setChosenOption(null);
        },
        available: windowSize < LG && !allItems,
      },
      {
        value: 'clear-selection',
        label: 'Limpar Seleção',
        action: () => {
          setSelectedItems([]);
          setChosenOption(null);
          if (allItems) {
            setAllItems(false);
          }
        },
        available: windowSize < LG && items.length,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteURL, batchDelete, windowSize, items]);

  const defaultValue = useMemo(() => {
    if (options && defaultOption !== undefined) {
      return options.find(option => option.value === defaultOption);
    }
    return null;
  }, [options, defaultOption]);

  const canBatch = useMemo(() => {
    if (windowSize >= LG) {
      if (items?.length > 1) {
        return true;
      }
      return false;
    }
    if (selectModeOn) {
      return true;
    }
    return false;
  }, [items, windowSize, selectModeOn]);

  useEffect(() => {
    if (windowSize < LG && !selectModeOn && items.length) {
      setSelectedItems([]);
      if (allItems) {
        setAllItems(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, selectModeOn, items, allItems]);

  // useEffect(() => {
  // if(allItems && items.length<pagination.per_page){
  //   setAllItems(false)
  // }
  // if (items?.length === pagination?.per_page && !allItems) {
  //   setAllItems(true);
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pagination, allItems, items]);

  return (
    <Row className="w-100 mx-0">
      <Col className="px-0 d-lg-none">
        <Button
          color="primary"
          className="new-layout-btn main"
          onClick={() => {
            setSelectModeOn(!selectModeOn);
          }}
        >
          Modo de Seleção
        </Button>
      </Col>
      <Col className="px-0">
        <div id="batch-actions-wrapper">
          <Select
            name={`${name}-batch-actions`}
            id={`${id}-batch-actions`}
            options={options.filter(o => o.available)}
            isClearable={clearable}
            isSearchable={false}
            classNamePrefix="select"
            className={`new-layout-batch-actions ${additionalClassName} w-fit-content float-right`}
            placeholder={placeholder || 'Ações em Lote'}
            defaultValue={defaultValue}
            value={chosenOption}
            onChange={option => {
              setChosenOption(option);
              option.action();
            }}
            styles={customStyles}
            isDisabled={!canBatch}
            // menuIsOpen
          />
        </div>
        {!canBatch && (
          <UncontrolledTooltip
            target="batch-actions-wrapper"
            placement={tipPlacement || 'left'}
          >
            {tip ||
              'Para executar uma ação em lote, selecione, pelo menos, dois itens.'}
          </UncontrolledTooltip>
        )}
      </Col>
    </Row>
  );
}
