/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { searchSale } from '~/store/modules/sales/actions';
import Loading from '~/components/Loading';
import PageHeader from '~/components/Common/PageHeader';
import SaleInfoCard from '../Sales/SaleInfoCard';

const Search = () => {
  const dispatch = useDispatch();
  const { search_id } = useParams();
  const sales = useSelector(state => state.sales.sales);
  const loading = useSelector(state => state.sales.loading);

  useEffect(() => {
    dispatch(searchSale(search_id));
  }, [search_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="RESULTADOS DA PESQUISA"
          responsivePosition="start"
          desktopPosition="start"
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            {sales.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Pedido</div>
                  </Col>
                  <Col xs={1} align="start">
                    <div className="new-layout-table-column-label">Valor</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Cliente</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Loja</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Últ. Atualização
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Situação
                    </div>
                  </Col>
                  <Col xs={1} align="start" />
                </Row>

                {sales?.map(sale => {
                  return <SaleInfoCard key={sale.id} sale={sale} />;
                })}
              </>
            ) : (
              <h3>Nenhuma resultado para a busca.</h3>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Search;
