import React from 'react';
import { Row, Col, Alert, Card, CardBody } from 'reactstrap';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// availity-reactstrap-validation-safe
import { AvForm, AvField } from 'availity-reactstrap-validation-safe';

// action
import { userForgetPassword } from '~/store/modules/auth/forgetpwd/actions';

// import images
// import profile from '~/assets/images/profile-img.png';
import logoBlue from '~/assets/images/logo-blue.svg';

// import logo from '~/assets/images/logo.svg';

const ForgetPasswordPage = () => {
  // Redux state
  const forgetError = useSelector(
    ({ forgetPassword }) => forgetPassword.forgetError
  );
  const forgetSuccessMsg = useSelector(
    ({ forgetPassword }) => forgetPassword.forgetSuccessMsg
  );

  // Dispatch redux instance
  const dispatch = useDispatch();

  document.title = 'Recuperar senha | Viddy';

  const handleValidSubmit = (_, { email }) => {
    dispatch(userForgetPassword(email));
  };

  return (
    <div className="new-layout-auth account-pages">
      <Row className="w-100 mx-0 my-4">
        <Col
          className="new-layout-logo-col justify-content-center"
          align="center"
        >
          <img className="new-layout-auth-logo" src={logoBlue} alt="" />
        </Col>
      </Row>
      <Row className="w-100 mx-0 my-4">
        <Col>
          <Card className="new-layout-login-card">
            <CardBody>
              <Row className="w-100 mx-0 my-4">
                <Col align="center">
                  <div className="new-layout-auth-welcome-msg">
                    <div className="d-block">
                      Insira seu <strong>e-mail</strong> para
                      <strong> recuperar</strong> sua <strong>senha</strong>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="w-100 mx-0 px-4 py-4">
                <Col align="center">
                  {forgetError && forgetError && (
                    <Row className="w-100">
                      <Col className="px-0">
                        <Alert color="success">
                          Se o e-mail informado existir, um link para
                          recuperação da senha será enviado.
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  {forgetSuccessMsg && (
                    <Row className="w-100">
                      <Col className="px-0">
                        <Alert color="success">{forgetSuccessMsg}</Alert>
                      </Col>
                    </Row>
                  )}

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleValidSubmit}
                  >
                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <AvField
                          name="email"
                          label="E-mail"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          errorMessage="E-mail no formato inválido"
                          type="email"
                          required
                        />
                      </Col>
                    </Row>

                    <Row className="my-4">
                      <Col className="new-layout-forgot-password-btn-div">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light w-100"
                          type="submit"
                        >
                          Recuperar
                        </button>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="new-layout-auth-register-msg mt-2 text-center">
                        <span className="d-inline mr-1">
                          Voltar para a página de
                        </span>
                        <Link className="d-inline" to="/login">
                          Login
                        </Link>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ForgetPasswordPage;
