import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loading from '~/components/Loading';
import api from '~/services/api';
import PageHeader from '~/components/Common/PageHeader';
import UserHeaderInfo from '~/components/Common/UserHeaderInfo';

const UserProfile = () => {
  const [user, setUser] = useState();
  const history = useHistory();
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);

  const roles = useSelector(({ user: loggedUser }) => loggedUser.profile.roles);

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const isSupport = useMemo(
    () => roles.find(role => role.slug === 'support'),
    [roles]
  );

  useEffect(() => {
    const loadUser = async () => {
      try {
        const { data } = await api.get(
          `/${
            isAdmin || isSupport ? 'administrator' : 'store-manager'
          }/users/${user_id}`
        );

        document.title = `${data.user.name} | Viddy`;

        setUser(data.user);
        setLoading(false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro inesperado.');
        }
      }
    };
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="DETALHES DO USUÁRIO"
              backTo="/usuarios"
              responsivePosition="start"
              desktopPosition="center"
            />

            <Card className="card new-layout">
              <CardBody className="card-body new-layout">
                <UserHeaderInfo user={user} />
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0 px-lg-4">
                        <Label className="mb-0">Nome</Label>
                        <div className="new-layout-page-value">
                          {user?.name}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0 px-lg-4">
                        <Label className="mb-0">E-mail</Label>
                        <div className="new-layout-page-value">
                          {user?.email}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0 px-lg-4">
                        <Label>Telefone</Label>
                        <div className="new-layout-page-value">
                          {user?.telephone || 'Não cadastrado'}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0 px-lg-4">
                        <Label>Última Atualização</Label>
                        <div className="new-layout-page-value">
                          {user &&
                            format(
                              parseISO(user?.updated_at),
                              "dd MMM yy '-' HH'h'mm",
                              {
                                locale: ptBR,
                              }
                            )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} className="px-0">
                    <Label>Loja(s)</Label>
                    {user?.managed_stores?.length ? (
                      <PerfectScrollbar className="new-layout-managed-stores-scrollbar details">
                        {user.managed_stores.map(store => (
                          <div className="store-ticket my-2">
                            <Row className="w-100">
                              <Col className="text-truncate">
                                {store.fantasy_name}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </PerfectScrollbar>
                    ) : (
                      <div className="new-layout-page-value">
                        Nenhuma loja cadastrada
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row className="w-100 mx-0 my-4">
              <Col className="px-0" align="end">
                <Button
                  className="new-layout-btn main"
                  color="primary"
                  size="lg"
                  onClick={() => history.push(`${user_id}/editar`)}
                >
                  Editar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default UserProfile;
