import * as Yup from 'yup';

const schema = (maskTelephoneLength, maskFinancierTelephoneLength) =>
  Yup.object().shape({
    name: Yup.string().required('Insira a razão social da loja'),
    fantasy_name: Yup.string().required('Insira o nome fantasia da loja'),
    responsible_name: Yup.string().required(
      'Insira o nome do responsável da loja'
    ),
    email: Yup.string()
      .email('E-mail no formato inválido')
      .required('Insira o e-mail do responsável da loja'),
    cnpj: Yup.string()
      .min(18, 'Insira todos os dígitos do CNPJ')
      .required('Digite o CNPJ da loja'),
    telephone: Yup.string()
      .min(maskTelephoneLength, 'Insira todos os dígitos do número do telefone')
      .required('Insira o telefone da loja'),
    address: Yup.object().shape({
      cep: Yup.string()
        .required('Insira o CEP corretamente')
        .min(9, 'Insira todos os dígitos do CEP'),
      street_name: Yup.string().required('Insira o nome da rua'),
      number: Yup.string().required('Insira o número'),
      neighborhood: Yup.string().required('Insira o nome do bairro'),
      state: Yup.string().required('Insira o nome do estado'),
      city: Yup.string().required('Insira o nome da cidade'),
      complement: Yup.string()
        .notRequired()
        .max(128, 'Máximo de 128 caracteres'),
      additional_information: Yup.string()
        .notRequired()
        .max(
          128,
          'As informações adicionais devem ter no máximo 128 caracteres'
        ),
    }),
    financier_email: Yup.string().email('E-mail no formato inválido'),
    financier_telephone: Yup.string().min(
      maskFinancierTelephoneLength,
      'Insira todos os dígitos do número do telefone'
    ),
    store_group_id: Yup.string().notRequired(),
    customize: Yup.object().shape({
      theme_color: Yup.string().notRequired().nullable(),
      brand_logo: Yup.mixed().notRequired().nullable(),
    }),
  });

export default schema;
