import React from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import history from '~/services/history';

export default function StoreInfoCard({
  store,
  registerToDelivery,
  isDisabled,
  onDelete,
  onRestore,
}) {
  return (
    <Card className="card new-layout list-card store grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col xs={10}>
            <div className="font-size-16 font-weight-600 text-gray-700">
              {store.fantasy_name}
            </div>
          </Col>
          <Col xs={1} align="center">
            <IconButton
              icon="las la-pen"
              color="secondary"
              id={`edit-btn-${store.id}`}
              tip="Editar"
              forceTip
              click={() => {
                history.push(`/lojas/${store.id}/editar`);
              }}
              fontSize={22}
            />
          </Col>
          <Col xs={1} align="end">
            {isDisabled ? (
              <IconButton
                icon="las la-undo"
                color="success"
                id={`restore-btn-${store.id}`}
                tip="Reativar"
                forceTip
                click={() => onRestore(store)}
                fontSize={22}
              />
            ) : (
              <IconButton
                icon="las la-ban"
                color="danger"
                id={`disable-btn-${store.id}`}
                tip="Desativar"
                forceTip
                click={() => onDelete(store)}
                fontSize={22}
              />
            )}
          </Col>
        </Row>
        <hr className="my-2 solid" />
        <Row className="w-100 mx-0 mb-3">
          <Col md={6}>
            <div className="block-card label d-block">Responsável</div>
            <div className="block-card value d-block text-truncate">
              {store?.responsible_name || 'Não informado'}
            </div>
          </Col>
          <Col md={6} className="pr-0 mt-2 my-md-0">
            <div className="block-card label">Serviço de Entrega</div>
            <Badge
              className={`store-delivery-status ${store?.status_on_delivery_service?.toLowerCase()}`}
            >
              {store?.status_on_delivery_service === 'NOT_REGISTERED' &&
                'Não Registrado'}
              {store?.status_on_delivery_service === 'NOT_READY' &&
                'Pendente de Confirmação'}
              {store?.status_on_delivery_service === 'READY' &&
                'Realiza Entrega'}
            </Badge>
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col xs={6}>
            <a className="block-card details-link" href={`/lojas/${store.id}`}>
              VER DETALHES
            </a>
          </Col>
          <Col xs={6} align="end">
            {store?.status_on_delivery_service !== 'READY' && (
              <IconButton
                icon="las la-motorcycle"
                color="info"
                id={`register-delivery-service-btn-${store.id}`}
                tip="Cadastrar loja no serviço de entrega"
                forceTip
                click={() => {
                  registerToDelivery(store.id);
                }}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
