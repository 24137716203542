import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Badge,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  Fade,
  Modal,
  ModalBody,
  Label,
  Popover,
  PopoverBody,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  openChatRequest,
  LoadOldChats,
  SetLoadingOldChats,
  AddSelectedUser,
  RemoveAllSelectedUsers,
  deleteChat,
  CleanCurrentChat,
  editChat,
  setSelectModeOn,
  setActiveTab,
  setChosenChatToManage,
  setShowEditChatModal,
} from '~/store/modules/chat/actions';

import { RemoveChatNotification } from '~/store/modules/notifications/actions';

import stdProfilePicture from '~/assets/images/user.png';
import stdGroupPicture from '~/assets/images/group.png';
import IconButton from '~/components/IconButton';
import SearchBox from '~/components/SearchBox';
import { saveChatsSearch, setLoadingChats } from '~/store/modules/cart/actions';

export default function ChatsList() {
  const dispatch = useDispatch();

  const chatsScrollbarRef = useRef();

  const currentChat = useSelector(({ chat }) => chat.currentChat);
  const activeTab = useSelector(({ chat }) => chat.activeTab);
  const showEditChatModal = useSelector(({ chat }) => chat.showEditChatModal);

  const chats = useSelector(({ chat }) => chat.chats);

  const [editModeOn, setEditModeOn] = useState(false);

  const chatsPagination = useSelector(({ chat }) => chat.chatsPagination);

  const loadingOldChats = useSelector(({ chat }) => chat.loadingOldChats);

  const selectedUsers = useSelector(({ chat }) => chat.selectedUsers);
  const chosenChatToManage = useSelector(({ chat }) => chat.chosenChatToManage);

  const [searchQuery, setSearchQuery] = useState(null);

  const thereIsStillOldChatsToBeLoaded = useMemo(() => {
    return (
      chatsPagination?.last_page > 1 &&
      chatsPagination?.current_page < chatsPagination?.last_page
    );
  }, [chatsPagination]);

  const [showNoOldChatsMsg, setShowNoOldChatsMsg] = useState(false);
  const [showChatOptions, setShowChatOptions] = useState(false);
  const [showConfirmChatDeletionModal, setShowConfirmChatDeletionModal] =
    useState(false);
  const [msgsScrollBarOldPosition, setMsgsScrollBarOldPosition] = useState(0);

  const [msgsScrollbarFirstRollDone, setMsgsScrollbarFirstRollDone] =
    useState(false);
  const [newGroupName, setNewGroupName] = useState('');

  const editChatInput = document.getElementById('editChatInput');

  const defineChatPic = chat => {
    if (chat.is_group_chat) {
      return stdGroupPicture;
    }
    if (chat.interlocutor.profile_picture) {
      return chat.interlocutor.profile_picture.url;
    }
    return stdProfilePicture;
  };

  const handleLoadOldChats = async () => {
    if (!loadingOldChats && thereIsStillOldChatsToBeLoaded) {
      dispatch(SetLoadingOldChats(true));
      // setChatsScrollBarPosition(msgsScrollbarRef.current.scrollHeight);
      const params = {
        page: chatsPagination.current_page + 1,
      };
      const data = {
        params,
      };

      dispatch(LoadOldChats(data));
    }
  };

  const handleOpenChat = chat => {
    if (msgsScrollbarFirstRollDone) setMsgsScrollbarFirstRollDone(false);

    if (msgsScrollBarOldPosition !== 0) setMsgsScrollBarOldPosition(0);

    dispatch(openChatRequest({ chat }));

    // setCurrentChat(chat);
  };

  const handleEditChat = () => {
    const newUsersArr = !selectedUsers.length
      ? chosenChatToManage.members
      : selectedUsers;

    const newNameToSend =
      newGroupName === '' ? chosenChatToManage.name : newGroupName;

    const params = {
      chat: chosenChatToManage,
      newName: newNameToSend,
      users: newUsersArr,
    };

    dispatch(editChat(params));
    dispatch(RemoveAllSelectedUsers());
    dispatch(setActiveTab('1'));
    setNewGroupName('');
    dispatch(setSelectModeOn(false));
    dispatch(setShowEditChatModal(false));
    setEditModeOn(false);
  };

  useEffect(() => {
    if (showEditChatModal && editChatInput) {
      editChatInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editChatInput, showEditChatModal]);

  useEffect(() => {
    if (
      !editModeOn &&
      !showChatOptions &&
      !showEditChatModal &&
      !showConfirmChatDeletionModal
    ) {
      dispatch(setChosenChatToManage(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editModeOn,
    showChatOptions,
    showEditChatModal,
    showConfirmChatDeletionModal,
  ]);

  useEffect(() => {
    if (!editModeOn) {
      if (showEditChatModal) {
        setEditModeOn(true);
      }
    }
  }, [editModeOn, showEditChatModal]);

  window.addEventListener(
    'click',
    () => {
      if (showChatOptions) {
        setShowChatOptions(false);
      }
    }
    // eslint-disable-next-line func-names
  );

  return (
    <>
      <Row className="w-100 mx-0 mb-4 d-none">
        <Col>
          <SearchBox
            url="/chats"
            query={searchQuery}
            setQuery={setSearchQuery}
            setData={data => {
              const {
                chats: searchedChats,
                pagination: searchedChatsPagination,
              } = data;
              saveChatsSearch(searchedChats, searchedChatsPagination);
            }}
            setLoading={flag => {
              dispatch(setLoadingChats(flag));
            }}
            params={{ query: searchQuery }}
          />
        </Col>
      </Row>
      {chats?.length > 0 ? (
        <PerfectScrollbar
          className="chats-list-scrollbar"
          // eslint-disable-next-line no-return-assign
          containerRef={el => (chatsScrollbarRef.current = el)}
          onScrollUp={() => {
            if (showNoOldChatsMsg) {
              setShowNoOldChatsMsg(false);
            }
          }}
          onYReachEnd={() => {
            if (activeTab === '1' && thereIsStillOldChatsToBeLoaded)
              handleLoadOldChats();
            if (!thereIsStillOldChatsToBeLoaded) setShowNoOldChatsMsg(true);
          }}
        >
          {chats.map(chat => {
            return (
              <div
                key={chat.id}
                className={`cursor-pointer highlight-on-hover outline-none ${
                  chat.id === currentChat?.id ? 'highlight' : ''
                }`}
                role="button"
                tabIndex={0}
                onClick={() => handleOpenChat(chat)}
              >
                <Row className="w-100 mx-0 py-3">
                  <Col
                    xs={3}
                    sm={2}
                    lg={3}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img
                      alt="Foto do perfil"
                      className="chat-profile-picture"
                      src={defineChatPic(chat)}
                    />
                  </Col>

                  <Col xs={7} sm={8} lg={7}>
                    <Row className="w-100 mx-0 mb-1">
                      {chat?.is_group_chat ? (
                        <Badge className="group-chat-badge">
                          Conversa em Grupo
                        </Badge>
                      ) : (
                        <>
                          {chat?.interlocutor?.deleted_at ? (
                            <Badge className="gray-badge">
                              Usuário Desativado
                            </Badge>
                          ) : (
                            <>
                              {chat.interlocutor.roles.length ? (
                                chat.interlocutor.roles.map(r => (
                                  <Badge
                                    key={`${chat.interlocutor.id}-${r.slug}`}
                                    className={`role-badge ${r.slug} p-1 mr-2`}
                                  >
                                    {r.name}
                                  </Badge>
                                ))
                              ) : (
                                <Badge className="gray-badge p-1 no-pointer-events width-fit-content">
                                  Nenhum cargo atribuído
                                </Badge>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Row>
                    <Row className="w-100 mx-0 mb-1">
                      <Col className="px-0 font-size-16 font-weight-600 text-gray-700 d-block text-truncate">
                        {chat?.is_group_chat
                          ? chat?.name
                          : chat?.interlocutor?.name}
                      </Col>
                    </Row>
                    {chat?.has_unread_messages ? (
                      <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-600">
                        <i className="las la-exclamation-circle text-info font-size-14 d-flex flex-column justify-content-center mr-2 " />
                        Nova mensagem
                      </Row>
                    ) : (
                      <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-600">
                        Nenhuma nova mensagem
                      </Row>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    {chat.is_group_chat ? (
                      <IconButton
                        icon="las la-angle-down"
                        id={`dropdown-options-${chat.id || 0}`}
                        click={event => {
                          if (!chosenChatToManage) {
                            dispatch(setChosenChatToManage(chat));
                          }
                          if (!showChatOptions) {
                            setShowChatOptions(true);
                          }
                          event.stopPropagation();
                        }}
                      />
                    ) : (
                      <IconButton
                        icon="las la-trash"
                        id={`delete-chat-btn-${chat.id || 0}`}
                        click={async event => {
                          setShowChatOptions(false);

                          dispatch(setChosenChatToManage(chat));

                          setShowConfirmChatDeletionModal(true);
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}

          {!thereIsStillOldChatsToBeLoaded &&
            chats.length >= chatsPagination.per_page && (
              <Fade in={showNoOldChatsMsg} tag="h5" className="mt-3">
                <div
                  key="no-more-old-chats"
                  className="chat-conversation-list d-flex flex-direction-row"
                >
                  <div
                    className="overflow-hidden"
                    // body
                  >
                    <h5 className="no-more-old-chats justify-content-center font-size-14 mb-1">
                      Não há mais conversas para serem exibidas.
                    </h5>
                  </div>
                </div>
              </Fade>
            )}
        </PerfectScrollbar>
      ) : (
        <Row className="w-100 mx-0 pl-2">
          <Col className="font-size-16 font-weight-600 text-gray-600">
            Nenhuma conversa
          </Col>
        </Row>
      )}
      <Modal isOpen={showConfirmChatDeletionModal}>
        <ModalHeader
          toggle={() => {
            setShowConfirmChatDeletionModal(false);
          }}
        >
          Tem certeza que deseja excluir a conversa?
        </ModalHeader>
        <ModalBody className="font-size-16">
          A conversa {chosenChatToManage?.is_group_chat ? 'em grupo ' : 'com '}
          <strong>
            {chosenChatToManage?.is_group_chat
              ? chosenChatToManage?.name
              : chosenChatToManage?.interlocutor?.name}
          </strong>{' '}
          será excluída!
        </ModalBody>
        <ModalFooter>
          <Button
            className="new-layout-btn main"
            color="danger"
            onClick={() => {
              dispatch(deleteChat(chosenChatToManage));
              if (chosenChatToManage.has_unread_messages) {
                dispatch(RemoveChatNotification(chosenChatToManage));
              }
              if (currentChat?.id === chosenChatToManage.id) {
                dispatch(CleanCurrentChat());
              }

              setShowConfirmChatDeletionModal(false);
            }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showEditChatModal}>
        <ModalHeader
          toggle={() => {
            dispatch(RemoveAllSelectedUsers());
            setNewGroupName('');
            dispatch(setShowEditChatModal(false));
          }}
        >
          Editar Chat
        </ModalHeader>
        <ModalBody>
          <Row className="w-100 mx-0 mb-3">
            <Label for="groupName">Defina um novo nome para seu grupo</Label>
            <Input
              type="text"
              // disabled={loadingMessages}
              name="group-name"
              id="editChatInput"
              placeholder="Digite aqui..."
              // autoFocus
              value={newGroupName}
              onChange={e => {
                setNewGroupName(e.target.value);
              }}
              onKeyDown={e =>
                newGroupName.length >= 3 && e.keyCode === 13 && handleEditChat()
              }
            />
          </Row>
          <Row className="w-100 mx-0">
            <Col className="px-0" align="end">
              <Button
                className="new-layout-btn main"
                color="primary"
                value={newGroupName}
                onClick={() => {
                  chosenChatToManage.members.forEach(member => {
                    dispatch(AddSelectedUser(member));
                  });
                  dispatch(setShowEditChatModal(false));
                  dispatch(setActiveTab('2'));
                  dispatch(setSelectModeOn(true));
                }}
              >
                Editar lista de Usuários
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="new-layout-btn main"
            // disabled={newGroupName.length < 3}
            onClick={handleEditChat}
          >
            Salvar alterações
          </Button>{' '}
        </ModalFooter>
      </Modal>
      {chosenChatToManage && chosenChatToManage.is_group_chat && (
        <Popover
          placement="bottom"
          isOpen={showChatOptions}
          target={
            `dropdown-options-${chosenChatToManage?.id}` || 'dropdown-options'
          }
          trigger="click"
          toggle={() => {
            setShowChatOptions(!showChatOptions);
          }}
          style={{ width: '125px' }}
        >
          <PopoverBody className="p-0">
            <div
              role="button"
              disabled={chosenChatToManage?.has_unread_messages}
              onClick={() => {
                setShowChatOptions(false);
                dispatch(setShowEditChatModal(true));
              }}
              style={{ borderBottom: '1px solid #ced4da' }}
            >
              <Row className="w-100 mx-0 font-size-18 p-3">
                <i className="las la-pen mr-2" />
                Editar
              </Row>
            </div>
            <div
              role="button"
              disabled={chosenChatToManage?.has_unread_messages}
              onClick={async () => {
                setShowChatOptions(false);

                setShowConfirmChatDeletionModal(true);
              }}
            >
              <Row className="w-100 mx-0 font-size-18 p-3">
                <i className="las la-trash mr-2" />
                Excluir
              </Row>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
}
