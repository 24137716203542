import * as Yup from 'yup';

const schema = Yup.object().shape({
  cep: Yup.string()
    .required('Insira o CEP corretamente')
    .min(9, 'Insira todos os dígitos do CEP'),
  street_name: Yup.string().required('Insira o nome da rua'),
  number: Yup.string().required('Insira o número'),
  neighborhood: Yup.string().required('Insira o nome do bairro'),
  state: Yup.string().required('Insira o nome do estado'),
  city: Yup.string().required('Insira o nome da cidade'),
  complement: Yup.string().notRequired(),
  additional_information: Yup.string()
    .notRequired()
    .max(128, 'As informações adicionais devem ter no máximo 128 caracteres'),
});

export default schema;
