import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core';

const InputMask = ({
  autofocus,
  name,
  classes,
  isCheckout = false,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    if (!autofocus) return;

    inputRef.current?.getInputDOMNode().focus();
  }, [autofocus]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
        clearError();
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [clearError, fieldName, registerField]);

  return (
    <>
      <ReactInputMask
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={`form-control input-color ${classes} ${
          error && 'border-danger'
        }`}
        onFocus={clearError}
        {...rest}
      />

      {error && (
        <span
          className={`${error && 'text-danger'}`}
          style={{
            position: isCheckout ? 'absolute' : 'unset',
          }}
        >
          {error}
        </span>
      )}
    </>
  );
};

export default InputMask;
