/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
// import { debounce } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  setDOMLoaded,
  setWindowSize,
  toggleRightSidebar,
} from '~/store/modules/layout/actions';

import { CleanCurrentChat, SetLocation } from '~/store/modules/chat/actions';
import { LG } from '~/util/breakpointWidths';
// import DcHeader from '../DigitalConsultant/DcHeader';

import { checkDOMLoaded } from '~/util/applyLayoutClasses';

import { defineWindowSize } from '~/util/newLayoutHelpers';

import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
// import useRoles from '~/hooks/useRoles';

const Header = () => {
  const dispatch = useDispatch();
  const currentChat = useSelector(({ chat }) => chat.currentChat);
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const DOMLoaded = useSelector(({ layout }) => layout.DOMLoaded);

  const location = useLocation();

  // const { isConsultant } = useRoles();

  window.addEventListener('resize', () => {
    const newWidth = defineWindowSize(windowSize);
    if (newWidth) {
      dispatch(setWindowSize(newWidth));
    }
  });

  useEffect(() => {
    const waitDOMLoads = () => {
      const loaded = checkDOMLoaded();

      if (loaded) {
        dispatch(setDOMLoaded(true));
      } else {
        setTimeout(() => {
          waitDOMLoads();
        }, [100]);
      }
    };

    waitDOMLoads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (DOMLoaded) {
      // applyLayoutClasses();
      dispatch(setDOMLoaded(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, DOMLoaded]);

  useEffect(() => {
    dispatch(SetLocation(location));
    if (location.pathname !== '/chat' && currentChat) {
      dispatch(CleanCurrentChat());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  return (
    <header
      id="page-topbar"
      className={
        windowSize < LG &&
        location.pathname.startsWith('/venda/') &&
        location.pathname.length === 43
          ? 'd-none'
          : 'w-100'
      }
    >
      <div className="navbar-header w-100 h-100">
        <MobileHeader />
        <DesktopHeader />
      </div>
    </header>
  );
};

const mapStatetoProps = state => {
  const { layoutType } = state.layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(Header);
