import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  Row,
  Col,
  Alert,
  CardBody,
  // Button
} from 'reactstrap';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// availity-reactstrap-validation-safe
import { AvForm, AvField } from 'availity-reactstrap-validation-safe';

// actions
import { loginUser, apiError } from '~/store/modules/auth/login/actions';

// import images
import logoBlue from '~/assets/images/logo-blue.svg';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
// import logo from '~/assets/images/logo.svg';

import jsonPackage from '../../../package.json';

const LoginPage = () => {
  // Redux state
  const { version } = jsonPackage;
  const error = useSelector(({ login }) => login.error);
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const [showPassword, setShowPassword] = useState(false);
  const [showEyeBtn, setShowEyeBtn] = useState(true);

  // Dispatch redux instance
  const dispatch = useDispatch();

  const handleValidSubmit = (_, { email, password }) => {
    dispatch(loginUser(email, password));
  };

  useEffect(() => {
    document.title = 'Login | Viddy';
    dispatch(apiError(''));
  }, [dispatch]);

  const pwdInputHandler = useCallback(
    mutationsList => {
      const input = document.getElementById('av-field-pwrd');
      Array.from(mutationsList).forEach(mutation => {
        if (mutation.type === 'attributes') {
          if (
            Array.from(input.classList).some(cn => cn === 'av-invalid') &&
            Array.from(input.classList).some(cn => cn === 'is-touched') &&
            showEyeBtn
          ) {
            setShowEyeBtn(false);
          }
          if (
            Array.from(input.classList).some(cn => cn === 'av-valid') &&
            !showEyeBtn
          ) {
            setShowEyeBtn(true);
          }
        }
      });
    },
    [showEyeBtn]
  );

  useEffect(() => {
    let els_found = false;
    const pwdInputObserver = new MutationObserver(pwdInputHandler);
    while (!els_found) {
      const formGroups = document.getElementsByClassName('form-group');
      if (formGroups.length) {
        els_found = true;
        Array.from(formGroups).forEach(fg => {
          fg.style.minWidth = '100%';
        });
        const input = document.getElementById('av-field-pwrd');

        pwdInputObserver.observe(input, { attributes: true });
      }
    }

    return () => {
      pwdInputObserver.disconnect();
    };
  }, [pwdInputHandler]);

  return (
    <div className="new-layout-auth account-pages">
      <Row className="w-100 mx-0 my-4">
        <Col
          className="new-layout-logo-col justify-content-center"
          align="center"
        >
          <img className="new-layout-auth-logo" src={logoBlue} alt="" />
        </Col>
      </Row>
      <Row className="w-100 mx-0 my-4">
        <Col>
          <Card className="new-layout-login-card">
            <CardBody>
              <Row className="w-100 mx-0 my-4">
                <Col align="center">
                  <div className="new-layout-auth-welcome-msg">
                    <div className="d-block">
                      Faça seu <strong>login</strong>
                    </div>
                    <div className="d-block">
                      para <strong>continuar no Viddy</strong>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="w-100 mx-0 px-4 py-4">
                <Col align="center">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleValidSubmit}
                  >
                    {error && (
                      <Row className="w-100">
                        <Col className="px-0">
                          <Alert color="danger">{error}</Alert>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <AvField
                          name="email"
                          label="E-mail"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                          type="email"
                          errorMessage="E-mail no formato inválido"
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <Row className="w-100 mx-0" id="pwrd-row">
                          <AvField
                            id="av-field-pwrd"
                            name="password"
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            errorMessage="Digite sua senha"
                            required
                            placeholder="Digite sua senha"
                          />
                          {showEyeBtn && (
                            <IconButton
                              icon={`las ${
                                showPassword ? 'la-eye' : 'la-eye-slash'
                              }`}
                              color={showPassword ? '' : 'secondary'}
                              tip={
                                showPassword
                                  ? 'Esconder senha'
                                  : 'Mostrar senha'
                              }
                              forceTipe
                              click={() => setShowPassword(!showPassword)}
                              fontSize={25}
                              style={{
                                position: 'absolute',
                                right: windowSize >= LG ? '5%' : '10%',
                                top: '45%',
                              }}
                            />
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link
                          to="/forget-password"
                          className="new-layout-forgot-password"
                        >
                          Esqueci minha senha
                        </Link>
                      </Col>
                    </Row>

                    <Row className="my-4">
                      <Col>
                        <button
                          className="new-layout-auth-btn btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Fazer login
                        </button>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col className="new-layout-auth-register-msg mt-2 text-center">
                        <span className="d-inline mr-1">É Vendedor?</span>
                        <Link className="d-inline" to="/register">
                          Cadastre-se
                        </Link>
                      </Col>
                    </Row> */}
                  </AvForm>
                  <Row>
                    <Col className="new-layout-auth-register-msg mt-4 text-center">
                      {new Date().getFullYear()} © Viddy - B{version}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
