import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PAGES from './pagesConfig';

const usePageConfigs = () => {
  const location = useLocation();

  const roles = useSelector(({ user }) => user.profile.roles);

  const configs = PAGES(roles).find(p => p.pathname === location.pathname);

  return configs;
};

export default usePageConfigs;
