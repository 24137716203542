// Receive a color hex ('#ffffff') and test if the sum of
// colors is darker or lighter than average color
export default function isDarkColor(color) {
  // parse the color string to integers
  // when we parse something that begins with "0x"
  // it parses like hexadecimal to decimal
  // then we can know if is a darker color (near to 0x00)
  // or if its a lighter color (near to 0xff)
  const r = Number(`0x${color[1]}${color[2]}`);
  const g = Number(`0x${color[3]}${color[4]}`);
  const b = Number(`0x${color[5]}${color[6]}`);

  // validate if is a darker color
  // taking the average between red, green and blue
  // and them comparing with the
  // average between #ffffff and #000000
  const colorTheme = (r + g + b) / 3 < 255 / 2;

  return colorTheme;
}
