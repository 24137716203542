import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Button,
  Col,
  Container,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Select from 'react-select';
import PageHeader from '~/components/Common/PageHeader';
import { formatPrice } from '~/util/format';
import InputSelect from '~/components/Inputs/InputSelect';
import Loading from '~/components/Loading';
import { breakString } from '~/util/newLayoutHelpers';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import { paymentTypes } from '~/util/translatePaymentWay';
import DropdownFilter from '~/components/DropdownFilter';
import { deliveryStatuses, salesStatuses } from '~/util/filters';
import InputMaskFilter from '~/components/InputMaskFilter';
import TextFilter from '~/components/TextFilter';
import MonthPicker from '~/components/MonthPicker';
import DatePicker from '~/components/DatePicker';
import { fileTypes } from '~/util/fileTypes';
import useRoles from '~/hooks/useRoles';
import SaleInfoCard from '../Sales/SaleInfoCard';
import RevenueCard from '../Dashboard/RevenueCard';

export default function FinancierDashboard() {
  const formRef = useRef(null);
  const userStores = useSelector(({ user }) =>
    user.profile.managed_stores.map(s => ({
      value: s.id,
      label: s.fantasy_name,
    }))
  );
  const userStore = useSelector(({ user }) => ({
    value: user?.profile?.stock?.id,
    label: user?.profile?.stock?.fantasy_name,
  }));

  const subscriber = useSelector(
    ({ user }) => user?.profile?.subscriber?.fantasy_name
  );

  const { isSalesman, isConsultant, isAdmin } = useRoles();

  const showSellerInfo = (!isSalesman && !isConsultant) || isAdmin;

  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const timeNow = format(new Date(), "dd MMM yy '-' HH'h'mm", {
    locale: ptBR,
  });
  const dateNow = format(new Date(), 'dd/MM/yyyy');
  const [situationFilter, setSituationFilter] = useState(null);
  const [filters, setFilters] = useState(null);
  const [paymentFilter, setPaymentFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [clientFilter, setClientFilter] = useState(null);
  const [storeFilter, setStoreFilter] = useState(null);
  const [createdAtFilter, setCreatedAtFilter] = useState(null);
  const [deliveryFilter, setDeliveryFilter] = useState(null);
  const [deliveryFiltered, setDeliveryFiltered] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [sales, setSales] = useState(null);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [reportOptions, setReportOptions] = useState({
    payment_type: null,
    store_id: null,
    end_date: dateNow,
    file_type: null,
  });

  useEffect(() => {
    setSelectedStore(userStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [storeQuery, setStoreQuery] = useState('');

  const width = window.innerWidth;

  const getURL = '/financier/pages/dashboard';

  const getSalesURL = '/financier/carts';

  const dashboardParams = useMemo(
    () => ({
      store_id: selectedStore?.value === null ? null : selectedStore?.value,
      month: selectedMonth?.value,
    }),
    [selectedStore, selectedMonth]
  );

  const statusText = useMemo(() => {
    const statusObj = salesStatuses.find(s => s.value === situationFilter);
    return statusObj?.value === 4 || statusObj?.value === 6
      ? // ? statusObj?.label
        null
      : statusObj?.name;
  }, [situationFilter]);

  const salesParams = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      payment_type: paymentTypeFilter,
      status: situationFilter === 13 ? 1 : situationFilter,
      status_text: statusText,
      client:
        (clientFilter !== null && clientFilter.startsWith('não')
          ? 'não informado'
          : clientFilter) || null,
      store: storeFilter || null,
      created_at: createdAtFilter,
      delivery_method: deliveryFilter,
      // updated_at: updatedAtFilter,
    };
  }, [
    statusText,
    pagination,
    paymentTypeFilter,
    situationFilter,
    storeFilter,
    clientFilter,
    createdAtFilter,
    deliveryFilter,
    // updatedAtFilter,
  ]);

  const dailyAmount = useMemo(() => {
    return formatPrice(dashboardData?.daily_amount);
  }, [dashboardData]);
  const monthlyAmount = useMemo(() => {
    return formatPrice(dashboardData?.monthly_amount);
  }, [dashboardData]);
  const yearlyAmount = useMemo(() => {
    return formatPrice(dashboardData?.yearly_amount);
  }, [dashboardData]);

  const setData = data => {
    if (data) {
      const carts = data.carts.map(cart => ({
        ...cart,
        formatted_updated_at: format(
          parseISO(cart.updated_at),
          "HH'h'mm '-' dd/MMM/yy",
          {
            locale: ptBR,
          }
        ),
        client: {
          ...cart.client,
          broken_name: cart.client
            ? breakString(cart.client?.name, ' ', 18)
            : null,
        },
        seller: {
          ...cart.created_by,
          broken_name: cart.created_by
            ? breakString(cart.created_by?.name, ' ', 18)
            : null,
        },
        store: {
          ...cart.store,
          broken_fantasy_name: breakString(
            cart.store?.fantasy_name,
            ' ',
            18,
            true
          ),
        },
      }));
      setSales(carts);
      setPagination(data.meta);
      setFilters(data.filters);
    }

    // setLetInterval(false);
  };

  const loadSales = async () => {
    const { data } = await api.get(getSalesURL);

    setData(data);
    setLoading(false);
  };

  const loadDashboard = async () => {
    try {
      const { data: dashboard } = await api.get(getURL, {
        params: { store_id: userStore.value },
      });
      setDashboardData(dashboard);
      setLoadingDashboard(false);
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Erro ao carregar dados do dashboard');
      }
    }
  };

  useEffect(() => {
    loadSales();
    loadDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerateReport = async () => {
    try {
      toast.info('Estamos gerando seu relatório...');

      setDownloadingReport(true);

      await api
        .get(`/financier/carts/report`, {
          params: reportOptions,
          responseType: 'blob',
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: `application/${reportOptions.file_type}`,
          });
          const url = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute(
            'download',
            `Relatório de Vendas.${reportOptions.file_type}`
          );
          document.body.appendChild(tempLink);
          tempLink.click();
        });

      setReportOptions({
        payment_type: null,
        store_id: null,
      });
      setShowReportModal(false);
      setDownloadingReport(false);
      toast.success('Relatório gerado, baixando arquivo.');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Erro ao gerar relatório');
      }
    }
  };

  const applyFilterOnDashboard = useCallback(
    async filter => {
      try {
        setLoadingDashboard(true);

        const { data: dashboard } = await api.get(getURL, {
          params: { ...dashboardParams, ...filter },
        });

        setDashboardData(dashboard);
        setLoadingDashboard(false);
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Houve um erro ao aplicar o filtro');
        }
      }
    },
    [dashboardParams]
  );

  useEffect(() => {
    if (filters !== null) {
      const getPaymentTypes = paymentTypes.filter(types =>
        filters.payment_types.includes(types.value)
      );
      const getSituationTypes = salesStatuses.filter(types =>
        filters?.status.includes(types.value)
      );

      const getDeliveryTypes = deliveryStatuses.filter(types =>
        filters.delivery_methods.includes(types.value)
      );

      const getDeliveryTypesWithSubscriber = getDeliveryTypes.map(type =>
        type.value === 'PROPRIA' ? { ...type, label: `Alternativa` } : type
      );

      setDeliveryFiltered(getDeliveryTypesWithSubscriber);
      setPaymentFilter(getPaymentTypes);
      setStatusFilter(getSituationTypes);
    }
  }, [filters, subscriber]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="DASHBOARD"
          responsivePosition="start"
          desktopPosition="start"
          subtitle={`Dados coletados em ${timeNow}`}
        />
        <Row className="w-100 mx-0 mb-4">
          <Col lg={9} className="px-0 mb-4 mb-lg-0">
            <Form
              ref={formRef}
              onSubmit={data =>
                applyFilterOnDashboard(data.store_id ? data : null)
              }
            >
              <Row className="w-100 mx-0 my-4 my-lg-0">
                <Col lg={3} className="col-lg-auto px-0 pr-lg-2 mb-2 mb-lg-0">
                  <InputSelect
                    name="store_id"
                    id="store_id"
                    options={userStores}
                    value={selectedStore}
                    onChange={option => {
                      setSelectedStore(option);
                      applyFilterOnDashboard({
                        store_id: option?.value || userStore.value,
                      });
                    }}
                    isClearable
                  />
                </Col>
                <Col className="col-lg-auto px-0 pl-lg-2">
                  <MonthPicker
                    value={selectedMonth}
                    onChange={date => {
                      setSelectedMonth(date);
                      // if (!date) return;
                      applyFilterOnDashboard({
                        month: date ? format(date, 'yyyy-MM') : null,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col lg={3} className="px-0" align="end">
            <Button
              color="primary"
              className="new-layout-btn main"
              onClick={() => setShowReportModal(true)}
            >
              Gerar Relatório
            </Button>
          </Col>
        </Row>
        {loadingDashboard ? (
          <Loading />
        ) : (
          <>
            <Row className="w-100 mx-0">
              <Col lg={4} className="px-0 pr-lg-2 mb-4 mb-lg-0">
                <RevenueCard label="Faturamento do Ano" value={yearlyAmount} />
              </Col>
              <Col lg={4} className="px-0 px-lg-2 mb-4 mb-lg-0">
                <RevenueCard label="Faturamento do Mês" value={monthlyAmount} />
              </Col>
              <Col lg={4} className="px-0 pl-lg-2">
                <RevenueCard label="Faturamento do Dia" value={dailyAmount} />
              </Col>
            </Row>
            <PageHeader
              pageTitle="VENDAS"
              responsivePosition="start"
              desktopPosition="start"
            />
            <span className="text-left mt-0 mb-2 mb-lg-0 text-muted font-size-14 mr-2 filter-text-mobile">
              Filtros
            </span>
            <Row className="w-100 mx-0 mb-2 mb-lg-4 d-flex flex-row align-items-center">
              <span className="text-left mt-0 mb-2 mb-lg-0 text-muted font-size-14 mr-2 filter-text-desktop">
                Filtros
              </span>
              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <DropdownFilter
                  name="sales-status-filter"
                  id="sales-status-filter"
                  placeholder="Situação"
                  options={statusFilter}
                  url={getSalesURL}
                  params={salesParams}
                  setData={setData}
                  setLoading={setLoading}
                  filter={situationFilter}
                  setFilter={setSituationFilter}
                  clearable
                  width={width > 399 ? 180 : 340}
                  menuListWidth={width > 399 ? 250 : 340}
                />
              </Col>

              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <DropdownFilter
                  name="payment-type-filter"
                  id="payment-type-filter"
                  placeholder="Tipo de Pagamento"
                  options={paymentFilter}
                  url={getSalesURL}
                  params={salesParams}
                  setData={setData}
                  setLoading={setLoading}
                  filter={paymentTypeFilter}
                  setFilter={setPaymentTypeFilter}
                  clearable
                  width={width > 399 ? 180 : 340}
                  menuListWidth={width > 399 ? 250 : 340}
                />
              </Col>

              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <DropdownFilter
                  name="payment-type-filter"
                  id="payment-type-filter"
                  placeholder="Tipo de Entrega"
                  options={deliveryFiltered}
                  url={getURL}
                  params={salesParams}
                  setData={setData}
                  setLoading={setLoading}
                  filter={deliveryFilter}
                  setFilter={setDeliveryFilter}
                  clearable
                  width={width > 399 ? 180 : 340}
                  menuListWidth={width > 399 ? 250 : 340}
                />
              </Col>

              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <TextFilter
                  name="client-filter"
                  id="client-filter"
                  itemsname="clients"
                  placeholder="Cliente"
                  url={getSalesURL}
                  params={salesParams}
                  query={clientFilter}
                  setQuery={setClientFilter}
                  setLoading={setLoading}
                  setData={setData}
                />
              </Col>

              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <TextFilter
                  name="sales-store-filter"
                  id="sales-store-filter"
                  itemsname="stores"
                  placeholder="Loja"
                  url={getSalesURL}
                  params={salesParams}
                  query={storeFilter}
                  setQuery={setStoreFilter}
                  setLoading={setLoading}
                  setData={setData}
                />
              </Col>

              <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
                <InputMaskFilter
                  name="created_at-filter"
                  id="created_at-filter"
                  isDateType
                  placeholder="Data"
                  mask="99/99/9999"
                  generateFilterParams={date => ({ created_at: date })}
                  params={salesParams}
                  url={getSalesURL}
                  setLoading={setLoading}
                  setData={setData}
                  setFilter={setCreatedAtFilter}
                  filter={createdAtFilter}
                  style={width > 399 ? { width: '180px' } : { width: '340px' }}
                />
              </Col>
            </Row>

            {loading ? (
              <Loading />
            ) : (
              <>
                {sales?.length ? (
                  <>
                    <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                      <Col xs={1} align="center" className="d-none">
                        <CustomInput
                          type="checkbox"
                          id="allSales"
                          className="text-primary z-index-0"
                          // checked={allSales}
                          // defaultValue={allSales}
                          // onChange={() => setAllSales(!allSales)}
                        />
                      </Col>
                      <Col xs={showSellerInfo ? 1 : 2} align="start">
                        <div className="new-layout-table-column-label">
                          Pedido
                        </div>
                      </Col>
                      {showSellerInfo && (
                        <Col
                          xs={2}
                          align="start"
                          // style={{ paddingLeft: '1.25rem' }}
                        >
                          <div className="new-layout-table-column-label">
                            Vendedor
                          </div>
                        </Col>
                      )}
                      <Col
                        xs={2}
                        align="start"
                        // style={{ paddingLeft: '1.25rem' }}
                      >
                        <div className="new-layout-table-column-label">
                          Cliente
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Loja
                        </div>
                      </Col>
                      <Col xs={1} align="start">
                        <div className="new-layout-table-column-label">
                          Valor
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label d-flex flex-row flex-wrap w-100">
                          Últ. Atualização
                        </div>
                      </Col>
                      <Col xs={showSellerInfo ? 1 : 2} align="start">
                        <div className="new-layout-table-column-label">
                          Situação
                        </div>
                      </Col>
                      <Col xs={1} align="center">
                        <div className="new-layout-table-column-label">
                          Ações
                        </div>
                      </Col>
                    </Row>
                    {sales
                      ?.filter(
                        sale => sale?.store.fantasy_name.toLowerCase()
                        // .includes(storeQuery.toLowerCase())
                      )
                      .map(sale => {
                        // const isSelected = selectedSales.some(s => s.id === sale.id);
                        return (
                          <SaleInfoCard
                            key={sale.id}
                            sale={sale}
                            // isSelected={isSelected}
                            // onSelect={handleSelectOrUnselectSale}
                            // onDelete={incomingSale => {
                            //   setSaleToBeDeleted(incomingSale);
                            //   setShowDeleteSaleModal(true);
                            // }}
                            showInfoSeller={showSellerInfo}
                          />
                        );
                      })}
                    <Row className="w-100 mx-0 mb-4">
                      <Col className="px-0" align="end">
                        {pagination && (
                          <div className="mb-3">
                            <Pagination
                              paginationMeta={pagination}
                              url={getSalesURL}
                              setData={setData}
                              setLoading={setLoadingDashboard}
                              params={salesParams}
                              // setReqRunning={setComponentReqRunning}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <h3 style={{ minHeight: '700px' }}>
                    Nenhuma venda encontrada.
                  </h3>
                )}
              </>
            )}
          </>
        )}
      </Container>
      <Modal isOpen={showReportModal}>
        <ModalHeader toggle={() => setShowReportModal(false)}>
          Gerar Relatório de Vendas
        </ModalHeader>
        <ModalBody>
          {downloadingReport ? (
            <Loading
              message="Gerando relatório..."
              customClassName="loading h-fit-content"
            />
          ) : (
            <>
              <Row className="w-100 mx-0 mb-4">
                <Col className="px-0">
                  <Label>Deseja filtrar por uma loja específica?</Label>
                  <Select
                    name="store-financier-report"
                    id="store-financier-report"
                    options={userStores}
                    classNamePrefix="select"
                    placeholder="Loja"
                    isClearable
                    value={userStores.find(
                      s => s.value === reportOptions.store_id
                    )}
                    onChange={option => {
                      setReportOptions({
                        ...reportOptions,
                        store_id: option?.value,
                      });
                    }}
                    noOptionsMessage={() =>
                      'Não há lojas cadastradas para o seu usuário'
                    }
                  />
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-4">
                <Col className="px-0">
                  <Label>
                    Deseja filtrar por uma forma de pagamento específica?
                  </Label>
                  <Select
                    name="payment-financier-report"
                    id="payment-financier-report"
                    options={paymentFilter}
                    classNamePrefix="select"
                    placeholder="Forma de Pagamento"
                    isClearable
                    value={paymentTypes.find(
                      p => p.value === reportOptions.payment_type
                    )}
                    onChange={option => {
                      setReportOptions({
                        ...reportOptions,
                        payment_type: option?.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-4">
                <Col className="px-0">
                  <Label>
                    Deseja filtrar por um intervalo de datas específico?
                  </Label>
                  <Row className="w-100 mx-0 mb-2 mb-lg-2 d-flex flex-row align-items-center">
                    <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                      <DatePicker
                        value={startDate}
                        onChange={selectedDate => {
                          const date = format(selectedDate, 'dd/MM/yyyy');
                          setReportOptions({
                            ...reportOptions,
                            start_date: date,
                          });
                          setStartDate(selectedDate);
                        }}
                        mask="__/__/___"
                        placeholder="Dia Inicial"
                      />
                    </Col>
                    <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                      <DatePicker
                        value={endDate}
                        onChange={selectedDate => {
                          const date = format(selectedDate, 'dd/MM/yyyy');
                          setReportOptions({
                            ...reportOptions,
                            end_date: date,
                          });
                          setEndDate(selectedDate);
                        }}
                        mask="__/__/___"
                        placeholder="Dia Final"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-4">
                <Col className="px-0">
                  <Label>
                    Qual formato de arquivo que deseja no relatório?
                  </Label>
                  <Select
                    name="filetype-financier-report"
                    id="filetype-financier-report"
                    options={fileTypes}
                    classNamePrefix="select"
                    placeholder="Formato de arquivo"
                    isClearable
                    value={fileTypes.find(
                      p => p.value === reportOptions.file_type
                    )}
                    onChange={option => {
                      setReportOptions({
                        ...reportOptions,
                        file_type: option?.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="new-layout-btn main"
            onClick={() => handleGenerateReport()}
          >
            Gerar Relatório
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
