export const XS_MAX_WIDTH = 575;

export const SM_MIN_WIDTH = 576;
export const SM_MAX_WIDTH = 767;

export const MD_MIN_WIDTH = 768;
export const MD_MAX_WIDTH = 991;

export const LG_MIN_WIDTH = 992;
export const LG_MAX_WIDTH = 1199;

export const XL_MIN_WIDTH = 1200;
export const XL_MAX_WIDTH = 1399;

export const XXL_MIN_WIDTH = 1400;

export const XS = 1;
export const SM = 2;
export const MD = 3;
export const LG = 4;
export const XL = 5;
export const XXL = 6;
