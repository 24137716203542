/* Cart */
export const RESET_CART = '@cart/RESET_CART';

export const GENERATE_ORDER_NUMBER = '@cart/GENERATE_ORDER_NUMBER';
export const GENERATE_ORDER_NUMBER_SUCCESS =
  '@cart/GENERATE_ORDER_NUMBER_SUCCESS';

export const LOAD_CART = '@cart/LOAD_CART';
export const LOAD_CART_SUCCESS = '@cart/LOAD_CART_SUCCESS';

export const SAVE_INFORMATIONS = '@cart/SAVE_INFORMATIONS';
export const SAVE_INFORMATIONS_USER = '@cart/SAVE_INFORMATIONS_USER';

export const SAVE_CART_STORE = '@cart/SAVE_CART_STORE';

export const CREATE_CART = '@cart/CREATE_CART';
export const CREATE_CART_SUCCESS = '@cart/CREATE_CART_SUCCESS';
export const CREATE_CART_FAILURE = '@cart/CREATE_CART_FAILURE';

export const UPDATE_CART_INFORMATIONS = '@cart/UPDATE_CART_INFORMATIONS';
export const UPDATE_CART_INFORMATIONS_SUCCESS =
  '@cart/UPDATE_CART_INFORMATIONS_SUCCESS';

export const UPDATE_DELIVERY = '@cart/UPDATE_DELIVERY';
export const UPDATE_DELIVERY_FEE = '@cart/UPDATE_DELIVERY_FEE';

export const UPDATE_SCHEDULED_TO = '@cart/UPDATE_SCHEDULED_TO';
export const UPDATE_IS_SCHEDULED = '@cart/UPDATE_IS_SCHEDULED';

export const UPDATE_USE_CUSTOM_DELIVERY_ADDRESS =
  '@cart/UPDATE_USE_CUSTOM_DELIVERY_ADDRESS';
export const UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS =
  '@cart/UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS';

export const SAVE_CUSTOM_DELIVERY_ADDRESS =
  '@cart/SAVE_CUSTOM_DELIVERY_ADDRESS';
export const SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS =
  '@cart/SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS';

export const UPDATE_DELIVERY_SUCCESS = '@cart/UPDATE_DELIVERY_SUCCESS';
export const SET_PRODUCTS = '@cart/SET_PRODUCTS';

/* Tabs and Step */
export const NEXT_STEP = '@cart/NEXT_STEP';
export const SET_TAB = '@cart/SET_TAB';
export const SET_LOADING = '@cart/SET_LOADING';
export const SET_LOADING_CHATS = '@cart/SET_LOADING_CHATS';

/* Store */
export const LOAD_STORES = '@cart/LOAD_STORES';
export const LOAD_STORES_SUCCESS = '@cart/LOAD_STORES_SUCCESS';
export const SAVE_CHATS_SEARCH = '@cart/SAVE_CHATS_SEARCH';

/* Client */
export const SAVE_CLIENT = '@cart/SAVE_CLIENT';

export const CREATE_CLIENT = '@cart/CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = '@cart/CREATE_CLIENT_SUCCESS';

export const SET_CLIENT = '@cart/SET_CLIENT';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';

export const SEARCH_CLIENT = '@cart/SEARCH_CLIENT';
export const SEARCH_CLIENT_SUCCESS = '@cart/SEARCH_CLIENT_SUCCESS';

export const SEARCH_ADDRESS = '@cart/SEARCH_ADDRESS';
export const SEARCH_ADDRESS_SUCCESS = '@cart/SEARCH_ADDRESS_SUCCESS';

export const SAVE_CLIENT_ADDRESS = '@cart/SAVE_CLIENT_ADDRESS';
export const SAVE_CLIENT_ADDRESS_SUCCESS = '@cart/SAVE_CLIENT_ADDRESS_SUCCESS';

export const UPDATE_CLIENT_ADDRESS = '@cart/UPDATE_CLIENT_ADDRESS';
export const UPDATE_CLIENT_ADDRESS_SUCCESS =
  '@cart/UPDATE_CLIENT_ADDRESS_SUCCESS';

/* Product */
export const ADD_PRODUCT = '@cart/ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = '@cart/ADD_PRODUCT_SUCCESS';

export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const REMOVE_PRODUCT_SUCCESS = '@cart/REMOVE_PRODUCT_SUCCESS';

export const REMOVE_PRODUCT_API = '@cart/REMOVE_PRODUCT_API';

export const UPDATE_PRODUCT = '@cart/UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = '@cart/UPDATE_PRODUCT_SUCCESS';

export const SEARCH_PRODUCT = '@cart/SEARCH_PRODUCT';
export const SEARCH_PRODUCT_SUCCESS = '@cart/SEARCH_PRODUCT_SUCCESS';

export const API_ERROR = '@cart/API_ERROR';

export const HANDLE_ADDRESS_NOT_FOUND = '@cart/HANDLE_ADDRESS_NOT_FOUND';
export const SUBMIT_REGION = '@cart/SUBMIT_REGION';
export const SHOW_REGIONS_MODAL = '@cart/SHOW_REGIONS_MODAL';
export const SET_SHOW_CONFIRM_REGION_MODAL =
  '@cart/SET_SHOW_CONFIRM_REGION_MODAL';
export const SET_LOADING_REGIONS = '@cart/SET_LOADING_REGIONS';
export const SET_REGION_SUCCESS = '@cart/SET_REGION_SUCCESS';
export const SET_TEMPORARY_STORE = '@cart/SET_TEMPORARY_STORE';
export const SET_STORE_REGIONS = '@cart/SET_STORE_REGIONS';
export const SET_CHOSEN_REGION = '@cart/SET_CHOSEN_REGION';

/* Finish Cart */
export const FINISH_CART = '@cart/FINISH_CART';
export const FINISH_CART_LINK = '@cart/FINISH_CART_LINK';
export const FINISH_CART_CIELO = '@cart/FINISH_CART_CIELO';
export const FINISH_CART_LINK_SUCCESS = '@cart/FINISH_CART_LINK_SUCCESS';
export const FINISH_CART_CIELO_SUCCESS = '@cart/FINISH_CART_CIELO_SUCCESS';
export const FINISH_CART_PAGARME = '@cart/FINISH_CART_PAGARME';
export const FINISH_CART_PAGARME_SUCCESS = '@cart/FINISH_CART_PAGARME_SUCCESS';
export const FINISH_CART_MERCADOPAGO = '@cart/FINISH_CART_MERCADOPAGO';
export const FINISH_CART_MERCADOPAGO_SUCCESS =
  '@cart/FINISH_CART_MERCADOPAGO_SUCCESS';
export const FINISH_CART_REDE = '@cart/FINISH_CART_REDE';
export const FINISH_CART_REDE_SUCCESS = '@cart/FINISH_CART_REDE_SUCCESS';
export const FINISH_CART_PAGSEGURO = '@cart/FINISH_CART_PAGSEGURO';
export const FINISH_CART_PAGSEGURO_SUCCESS =
  '@cart/FINISH_CART_PAGSEGURO_SUCCESS';

export const FINISH_CART_DELIVERY = '@cart/FINISH_CART_DELIVERY';
export const FINISH_CART_DELIVERY_SUCCESS =
  '@cart/FINISH_CART_DELIVERY_SUCCESS';

export const FINISH_CART_PIX_SUCCESS = '@cart/FINISH_CART_PIX_SUCCESS';

export const SET_STOCK = '@cart/SET_STOCK';
export const MODIFY_STOCK_ADDRESS = '@cart/MODIFY_STOCK_ADDRESS';
export const MODIFY_STOCK_FLEET = '@cart/MODIFY_STOCK_FLEET';

export const SET_DELIVERY_FLEET_REQUEST = '@cart/SET_DELIVERY_FLEET_REQUEST';
export const SET_DELIVERY_FLEET_SUCCESS = '@cart/SET_DELIVERY_FLEET_SUCCESS';

export const SET_IGNORE_CLIENT_ADDRESS = '@cart/SET_IGNORE_CLIENT_ADDRESS';

export const SET_SHAREABLE_CART = '@cart/SET_SHAREABLE_CART';
export const SET_SHAREABLE_CART_TOKEN = '@cart/SET_SHAREABLE_CART_TOKEN';
export const CREATE_SHAREABLE_CART = '@cart/CREATE_SHAREABLE_CART';
export const SAVE_SHAREABLE_CART = '@cart/SAVE_SHAREABLE_CART';
export const SAVE_SHAREABLE_CART_SUCCESS = '@cart/SAVE_SHAREABLE_CART_SUCCESS';
export const SHOW_SHAREABLE_CART_MODAL = '@cart/SHOW_SHAREABLE_CART_MODAL';

export const PATCH_CART = '@cart/PATCH_CART';
export const PATCH_CART_SUCCESS = '@cart/PATCH_CART_SUCCESS';
export const SET_SALEMAN_STORE = '@cart/SET_SALEMAN_STORE';
export const UPLOAD_TAX_COUPONS = '@cart/UPLOAD_TAX_COUPONS';
export const UPDATE_FREIGHT_VEHICLE = '@cart/UPDATE_FREIGHT_VEHICLE';

export const SET_VEHICLE_FLEET = '@cart/SET_VEHICLE_FLEET';
export const UPDATE_VEHICLE_FOR_DELIVERY = '@cart/UPDATE_VEHICLE_FOR_DELIVERY';

export const SET_IS_SUITCASE_DELIVERY = '@cart/SET_IS_SUITCASE_DELIVERY';
