/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { InputGroup } from 'reactstrap';

export default function Input({
  name,
  iconAction,
  classRest,
  isCheckout = false,
  multiline = false,
  tip,
  ...rest
}) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        if (value !== undefined) {
          ref.value = value;
          clearError();
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {multiline ? (
        <textarea
          id={fieldName}
          ref={inputRef}
          className={`form-control input-color multiline-input ${
            error && 'border-danger'
          } ${classRest}`}
          defaultValue={defaultValue}
          {...rest}
        />
      ) : (
        <>
          {iconAction ? (
            <InputGroup>
              <input
                id={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                className={`form-control input-color ${
                  error && 'border-danger'
                } ${classRest}`}
                onFocus={clearError}
                {...rest}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={iconAction}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </InputGroup>
          ) : (
            <input
              id={fieldName}
              ref={inputRef}
              defaultValue={defaultValue}
              className={`form-control input-color ${
                error && 'border-danger'
              } ${classRest}`}
              onFocus={clearError}
              {...rest}
            />
          )}
        </>
      )}
      {error && (
        <span
          className={`${error && 'text-danger'}`}
          style={{
            position: isCheckout ? 'absolute' : 'unset',
            whiteSpace: isCheckout ? 'nowrap' : 'unset',
          }}
        >
          {error}
        </span>
      )}
      {tip && <span className="d-block text-muted">{tip}</span>}
    </>
  );
}
