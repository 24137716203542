import { FormGroup } from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Label,
  Row,
} from 'reactstrap';
import { Flip, toast } from 'react-toastify';
import * as Yup from 'yup';
import { shade } from 'polished';
import api from '~/services/api';
import {
  setClient,
  setActiveTab,
} from '~/store/modules/shareable_cart/actions';
import SchemaValidator from '~/validators/form/shareable_delivery_form';
import InputSelect from '~/components/Inputs/InputSelect';
import InputMask from '~/components/Inputs/InputMask';
import Input from '~/components/Inputs/Input';
import { stateList } from '~/util/stateList';

const AddressLiteForm = () => {
  const formRef = useRef(null);

  const Client = useSelector(({ shareable_cart }) => shareable_cart.client);

  const dispatch = useDispatch();

  const Cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  const token = useSelector(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  const paymentGateway = useSelector(
    ({ shareable_cart }) => shareable_cart.cart.store.payment_gateway
  );

  const toastId = useRef(null);

  const [searchedCEP, setSearchedCEP] = useState('');

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityList, setCityList] = useState([]);

  const [noNumber, setNoNumber] = useState(false);
  const [addressNumber, setAddressNumber] = useState();

  useEffect(() => {
    if (noNumber) {
      setAddressNumber('sn');
    } else {
      setAddressNumber();
    }
  }, [noNumber]);

  const useUserAddress = useSelector(({ cart }) => cart.useUserAddress);
  const chosenRegion = useSelector(({ cart }) => cart.chosenRegion);
  const showRegionsModal = useSelector(({ cart }) => cart.showRegionsModal);
  const showConfirmRegionModal = useSelector(
    ({ cart }) => cart.showConfirmRegionModal
  );

  const handleSubmitAddress = async data => {
    try {
      formRef.current.setErrors({});

      if (selectedState && selectedCity) {
        data = {
          ...data,
          state: selectedState.value,
          city: selectedCity.value,
        };
      }

      if (data.complement.length > 14 && paymentGateway === 'cielo') {
        toast.error('O complemento não pode ter mais que 14 caracteres');
        formRef.current.setFieldError(
          'complement',
          'O complemento não pode ter mais que 14 caracteres'
        );
        return;
      }

      await SchemaValidator.validate(data, {
        abortEarly: false,
      });

      const {
        data: { client },
      } = await api.patch(
        `/shareable-carts/clients/${Client.id}/address`,
        data,
        {
          headers: { 'X-Shareable-Token': token },
        }
      );

      dispatch(setClient(client));

      dispatch(setActiveTab(3));
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err?.response) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const showRegionInput = useMemo(() => {
    if (
      chosenRegion &&
      useUserAddress === true &&
      !showRegionsModal &&
      !showConfirmRegionModal
    ) {
      return true;
    }
    return false;
  }, [chosenRegion, useUserAddress, showRegionsModal, showConfirmRegionModal]);

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);

    try {
      // toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
      //   autoClose: false,
      // });
      const { data } = await api.get(`cep/${cep}`);

      // toast.update(toastId.current, {
      //   render: 'Endereço encontrado!',
      //   type: toast.TYPE.SUCCESS,
      //   autoClose: 5000,
      // });

      formRef.current.setData(data);

      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  return (
    <>
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-3">
            Endereço de entrega
          </Row>
          <Row className="w-100 mx-0 mt-2">
            <Form
              onSubmit={handleSubmitAddress}
              ref={formRef}
              className="w-100"
            >
              <Row className="w-100 mx-0">
                <Col className="px-0">
                  <FormGroup>
                    <Label>CEP</Label>
                    <InputMask
                      name="cep"
                      placeholder="CEP"
                      mask="99999-999"
                      maskChar={null}
                      onChange={e => e.target.value.length === 9 && searchCEP()}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-lg-2">
                <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Logradouro</Label>
                    <Input
                      name="street_name"
                      placeholder="Logradouro"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Col className="px-0">
                      <CustomInput
                        type="switch"
                        id="noNumberSwitch"
                        name="noNumber"
                        label="Sem Número"
                        checked={noNumber}
                        className="text-primary float-right"
                        style={{
                          color: Cart?.store?.customize?.theme_color,
                        }}
                        onChange={() => setNoNumber(!noNumber)}
                      />
                      <Label>Número</Label>
                      <Input
                        name="number"
                        placeholder="Número"
                        type={noNumber ? 'text' : 'number'}
                        disabled={noNumber}
                        onChange={({ target: { value } }) =>
                          setAddressNumber(value)
                        }
                        value={addressNumber}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-lg-2">
                <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Bairro</Label>
                    <Input
                      name="neighborhood"
                      placeholder="Bairro"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Complemento</Label>
                    <Input
                      name="complement"
                      placeholder="Complemento"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-lg-2">
                <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Estado</Label>
                    <InputSelect
                      name="state"
                      options={stateList}
                      onChange={selectedOption =>
                        setSelectedState(selectedOption)
                      }
                      value={selectedState}
                      placeholder="Estado"
                      singlePath
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Cidade</Label>
                    <InputSelect
                      name="city"
                      options={cityList}
                      onChange={selectedOption =>
                        setSelectedCity(selectedOption)
                      }
                      value={selectedCity}
                      placeholder="Cidade"
                      isDisabled={!selectedState}
                      singlePath
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-lg-2">
                <Col className="px-0 mt-2 mt-lg-0">
                  <FormGroup>
                    <Label>Informações Adicionais</Label>
                    <Input
                      name="additional_information"
                      placeholder="Informações Adicionais"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {showRegionInput && (
                <Row className="w-100 mx-0 mb-3">
                  <Col className="px-0 ">
                    <FormGroup>
                      <Label>Região de entrega</Label>
                      <Input
                        name="delivery_additional_data"
                        placeholder="Região de entrega (OPCIONAL)"
                        classRest="input-disabled text-muted"
                        type="text"
                        value={
                          !showRegionsModal &&
                          !showConfirmRegionModal &&
                          chosenRegion !== null &&
                          chosenRegion.name
                        }
                        loadingMessage={() => 'Buscando região...'}
                        onBlur={() => {}}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Form>
          </Row>
        </CardBody>
      </Card>
      <Row className="w-100 mx-0 mt-4 mt-lg-2">
        <Col className="px-0 mt-2 mt-lg-0" align="end">
          <Button
            color="primary"
            className="new-layout-btn main"
            size="lg"
            style={{
              backgroundColor: Cart?.store?.customize?.theme_color,
              borderColor: Cart?.store?.customize?.theme_color,
              '&:hover': {
                backgroundColor: shade(
                  0.2,
                  Cart?.store?.customize?.theme_color
                ),
                borderColor: shade(0.2, Cart?.store?.customize?.theme_color),
              },
            }}
            type="submit"
            onClick={() => formRef.current.submitForm()}
          >
            Avançar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddressLiteForm;
