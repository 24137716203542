import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CustomIcon from './CustomIcon';

export default function RevenueCard({ label, value, icon }) {
  return (
    <Card className="card new-layout dashboard-card-border h-80">
      <CardBody className="card-body new-layout px-3 py-3 h-100">
        <Row className="w-100 mx-0 h-100">
          <Col xs={9} className="px-0 col-xxl-10">
            <Row className="w-100 mx-0 font-size-14 font-weight-500 text-gray-600">
              {label}
            </Row>
            <Row className="w-100 mx-0 font-size-18 font-weight-500 text-gray-700">
              {value}
            </Row>
          </Col>
          <Col
            xs={3}
            className="px-0 d-flex flex-column justify-content-center align-items-end col-xxl-2"
            align="end"
          >
            <CustomIcon icon={icon} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
