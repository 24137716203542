import {
  FINALIZE,
  LOAD_CART,
  SAVE_CLIENT,
  SET_CLIENT,
  SET_ACTIVE_TAB,
  SET_CART,
  SET_IS_FOR_DELIVERY,
  SET_OBSERVATION,
  SET_PAYMENT_TYPE,
  SET_STEP,
  UPDATE_CART,
  SET_FINISHED_SUCCESSFULLY,
  PATCH_CART,
  SET_PAYMENT_DATA,
  SET_SHOW_CIELO_MODAL,
  SET_CIELO_AUTH_URL,
  SET_IP,
  UPDATE_CLIENT,
  SET_PIX_DATA,
  SET_PIX_DATA_SUCCESS,
} from './actionTypes';

export const loadCart = (id, token) => ({
  type: LOAD_CART,
  payload: { id, token },
});

export const setCart = cart => ({
  type: SET_CART,
  payload: cart,
});

export const setIp = ip => ({
  type: SET_IP,
  payload: ip,
});

export const updateCart = data => ({
  type: UPDATE_CART,
  payload: data,
});

export const patchCart = (data, nextTab) => ({
  type: PATCH_CART,
  payload: { data, nextTab },
});

export const setStep = step => ({
  type: SET_STEP,
  payload: step,
});

export const setActiveTab = tab => ({
  type: SET_ACTIVE_TAB,
  payload: tab,
});

export const setFinishedSuccessfully = flag => ({
  type: SET_FINISHED_SUCCESSFULLY,
  payload: flag,
});

export const setIsForDelivery = flag => ({
  type: SET_IS_FOR_DELIVERY,
  payload: flag,
});

export const setPaymentType = flag => ({
  type: SET_PAYMENT_TYPE,
  payload: flag,
});

export const finalize = data => ({
  type: FINALIZE,
  payload: data,
});

export const saveClient = ({ data, formRef }) => ({
  type: SAVE_CLIENT,
  payload: { data, formRef },
});

export const updateClient = ({ data, formRef }) => ({
  type: UPDATE_CLIENT,
  payload: { data, formRef },
});

export const setClient = client => ({
  type: SET_CLIENT,
  payload: client,
});

export const setPaymentData = paymentInfo => ({
  type: SET_PAYMENT_DATA,
  payload: paymentInfo,
});

export const setPixData = data => ({
  type: SET_PIX_DATA,
  payload: data,
});

export const setPixDataSuccess = ({ qrcode }) => ({
  type: SET_PIX_DATA_SUCCESS,
  payload: { qrcode },
});

export const setObservation = observation => ({
  type: SET_OBSERVATION,
  payload: observation,
});

export const setShowCieloModal = flag => ({
  type: SET_SHOW_CIELO_MODAL,
  payload: flag,
});

export const setCieloAuthURL = url => ({
  type: SET_CIELO_AUTH_URL,
  payload: url,
});
