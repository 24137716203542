import React from 'react';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { formatPrice } from '~/util/format';

export default function InvoiceInfoCard({ invoice }) {
  return (
    <>
      <Card
        className={`card new-layout table-card ${invoice.border_class} d-none d-lg-block`}
      >
        <CardBody className="card-body new-layout px-3 py-3">
          <Row className="w-100 h-100 mx-0 d-none d-lg-flex">
            <Col xs={1} align="center" className="h-100">
              <div
                className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap d-none outline-none"
                onClick={e => {
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                aria-label="checkbox-wrapper-div"
              >
                <CustomInput
                  type="checkbox"
                  // id={`${invoice.id}-checkbox`}
                  className="text-primary z-index-0 d-none"
                  // checked={isSelected}
                  // defaultValue={isSelected}
                  // onChange={() => onSelect()}
                />
              </div>
            </Col>
            <Col xs={10} className="px-0">
              <Row className="w-100 mx-0">
                <Col xs={3} className="new-layout-card-list-strong-value">
                  #{invoice.code}
                </Col>
                <Col xs={3} className="new-layout-card-list-half-strong-value">
                  {invoice.month}
                </Col>
                <Col xs={3} className="new-layout-table-column-value">
                  {formatPrice(invoice.value)}
                </Col>
                <Col
                  xs={3}
                  className={`new-layout-table-column-value ${
                    invoice?.status === 'paid' ? 'text-success' : 'text-danger'
                  }`}
                >
                  {invoice?.status === 'paid' ? 'Paga' : 'Não Paga'}
                </Col>
              </Row>
            </Col>
            <Col xs={1} align="start">
              <Row className="w-100 mx-0">
                <Col xs={6} className="px-0">
                  <IconButton
                    icon="las la-book"
                    color="info"
                    id={`report-btn-${invoice.id}`}
                    tip="Relatório do dia"
                    // click={() => {
                    //   onGenerateReport();
                    // }}
                  />
                </Col>
                <Col xs={6} className="px-0">
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    // id={`delete-btn-${invoice.id}`}
                    tip="Excluir"
                    // click={() => onDelete()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card new-layout list-card store d-lg-none">
        <CardBody className="card-body new-layout px-3 py-3">
          <Row className="w-100 mx-0">
            <Col xs={9} className="code-mobile-card-title">
              #{invoice.code}
            </Col>
            <Col xs={3}>
              <Row className="w-100 mx-0">
                <Col xs={6} className="px-0" align="center">
                  <IconButton
                    icon="las la-book"
                    color="info"
                    id={`report-mobile-btn-${invoice.id}`}
                    tip="Relatório do dia"
                    click={() => {
                      // onGenerateReport();
                    }}
                  />
                </Col>
                <Col xs={6} className="px-0" align="center">
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`delete-mobile-btn-${invoice.id}`}
                    tip="Excluir"
                    // click={() => onDelete()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Mês de Referência:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {invoice.month}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Valor Total:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {formatPrice(invoice.value)}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Status:
                </div>
                <div
                  className={`new-layout-card-mobile value d-inline text-truncate  ${
                    invoice?.status === 'paid' ? 'text-success' : 'text-danger'
                  }`}
                >
                  {invoice?.status === 'paid' ? 'Paga' : 'Não Paga'}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
