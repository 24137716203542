import React, { useRef, useState } from 'react';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Input from '~/components/Inputs/Input';
import api from '~/services/api';

export default function NewDeliveryGroup({ setData, getURL }) {
  const formRef = useRef();
  const [show, setShow] = useState(false);

  const onSubmitHandler = async formData => {
    try {
      await api.post('/administrator/store-groups', formData);

      const { data } = await api.get(getURL);

      setData(data);

      setShow(false);

      toast.success('Grupo de entrega criado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao tentar cadastrar o grupo de entrega. Contate um administrador.'
        );
      }

      // toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Button
        className="new-layout-btn main"
        onClick={() => setShow(true)}
        color="primary"
      >
        <i className="fas fa-plus font-size-16 align-middle mr-2" /> Novo Grupo
        de Entrega
      </Button>
      <Modal isOpen={show}>
        <ModalHeader
          toggle={() => {
            setShow(false);
          }}
        >
          Novo Grupo de Entrega
        </ModalHeader>
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                name="name"
                placeholder="Qual o Nome do Grupo?"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="new-layout-btn main"
              color="primary"
              type="submit"
            >
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
