import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';

const CollapsibleGiftBox = ({ pkg, index }) => {
  const [show, setShow] = useState(false);

  return (
    <Row className="w-100 mx-0 mb-2">
      <Row className="w-100 mx-0 mb-2" key={pkg.gift_box_index}>
        <Col xs={8} className="px-0">
          <Row className="w-100 mx-0 fs-18 fw-600 text-black">
            {pkg.gift_box_index
              ? `Pacote ${index + 1}`
              : 'Não são para presente'}
          </Row>
          <Row className="w-100 mx-0 fs-14 fw-400 text-gray-700">
            {pkg.products.length > 1
              ? `${pkg.products.length} itens`
              : `${pkg.products.length} item`}
          </Row>
        </Col>
        <Col xs={4} className="px-0">
          <Button color="primary" onClick={() => setShow(!show)}>
            Ver Pacote
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={show}>
        {pkg.products.map(p => (
          <Row className="w-100 mx-0 fs-12 f2-400 text-gray-700">
            <li>{p.name}</li>
          </Row>
        ))}
      </Collapse>
    </Row>
  );
};

export default function GiftBoxesCard({ products }) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const [packages, setPackages] = useState(null);

  useEffect(() => {
    const emptyGiftBoxes = Array.from(
      new Set(products.map(p => p.pivot.gift_box_index))
    );

    const filledGiftBoxes = emptyGiftBoxes.map(giftBox => ({
      gift_box_index: giftBox,
      products: products.filter(p => p.pivot.gift_box_index === giftBox),
    }));

    const validGiftBoxes = filledGiftBoxes
      .filter(gb => gb.gift_box_index)
      .sort((a, b) => a.gift_box_index - b.gift_box_index);

    const unpackedProducts = filledGiftBoxes.some(gb => !gb.gift_box_index)
      ? [filledGiftBoxes.find(gb => !gb.gift_box_index)]
      : [];

    setPackages([...validGiftBoxes, ...unpackedProducts]);
  }, [products]);

  return (
    <>
      <Card className="new-layout card">
        <CardBody className="new-layout card-body px-3 py-3">
          <Row
            className="w-100 mx-0 mb-4 cursor-pointer"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Col className="font-size-16 font-weight-600 text-gray-600 px-0">
              <Row className="w-100 mx-0">
                <Col xs={10} className="px-0 mb-0">
                  PACOTES DE PRESENTE
                </Col>
                <Col xs={2} className="px-0 mb-0 d-lg-none" align="end">
                  <IconButton
                    icon={`las la-${expanded ? 'minus' : 'plus'}`}
                    click={() => {
                      setExpanded(!expanded);
                    }}
                    color="gray-700"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Collapse isOpen={expanded}>
            <Row className="w-100 mx-0">
              {packages?.map((pkg, index) => (
                <CollapsibleGiftBox pkg={pkg} index={index} />
              ))}
            </Row>
          </Collapse>
        </CardBody>
      </Card>
    </>
  );
}
