import produce from 'immer';
// import { removeLayoutClasses } from '~/Util/applyLayoutClasses';

import {
  REFRESH_TOKEN,
  SAVE_USER_SUCCESS,
  SAVE_USER,
  API_ERROR,
  SET_LOADING,
} from './actionsTypes';

const initialState = {
  profile: null,
  loading: false,
  error: false,
  is_all_stores: false,
};

export default function user(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SAVE_USER: {
        draft.loading = true;
        break;
      }
      case SAVE_USER_SUCCESS: {
        draft.profile = action.payload;
        draft.loading = false;
        break;
      }
      case REFRESH_TOKEN: {
        const { token } = action.payload;
        draft.token = token;
        break;
      }
      case '@auth/LOGOUT_USER': {
        // removeLayoutClasses();

        draft.profile = null;

        const persistentAfterClear = localStorage.getItem(
          'shouldHideNotification'
        );

        localStorage.clear();

        localStorage.setItem('shouldHideNotification', persistentAfterClear);
        break;
      }
      case API_ERROR: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }
      case SET_LOADING: {
        draft.loading = action.payload;
        break;
      }

      default:
    }
  });
}
