import React from 'react';
import {
  ButtonGroup,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';

export default function UnifyDeliveriesModal({
  showModal,
  toggle,
  alerts,
  alertsLength,
  unifiableCarts,
  cartsToUnify,
  addCart,
  removeCart,
  unifyDeliveries,
}) {
  const handleAddToUnifiedCarts = cartId => {
    addCart(cartId);
  };
  const handleRemoveFromUnifiedCarts = cartId => {
    removeCart(cartId);
  };

  const handleUnifyDeliveries = () => {
    unifyDeliveries();
  };

  return (
    <>
      {alertsLength !== 0 && (
        <Modal isOpen={showModal} toggle={toggle} scrollable>
          <ModalHeader toggle={toggle}>Alertas</ModalHeader>
          <ModalBody>
            {unifiableCarts && (
              <>
                <Col>
                  <Row>
                    <Col>
                      <h5>
                        Existem outros pedidos que possuem entrega próxima a
                        este, você pode unificá-los utilizando apenas um
                        entregador para agilizar a entrega e fazer assim apenas
                        uma viagem.
                      </h5>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <span>
                        Marque como sim, caso queira unificar a entrega.
                      </span>
                      <i
                        id="info"
                        className="las la-info-circle font-size-18 ml-1"
                      />
                      <UncontrolledTooltip placement="top" target="info">
                        Selecione pelo menos 1 pedido para unificar as entregas.
                        Você pode selecionar até 6 pedidos!
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <span>
                        A lista abaixo pode conter uma barra de rolagem.
                      </span>
                    </Col>
                  </Row>
                  <PerfectScrollbar
                    style={{ height: 'fit-content', maxHeight: '150px' }}
                  >
                    {unifiableCarts.map(cart => (
                      <Row
                        className="w-100 mx-0 align-items-center my-2"
                        key={cart.id}
                      >
                        <Col>
                          <b>{`Pedido: ${cart.order_number}`}</b>
                        </Col>
                        <Col align="end">
                          <a
                            href={`${cart.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="h5"
                            id="cartLink"
                          >
                            <Button size="sm" color="info" className="mr-2">
                              Ver
                            </Button>
                          </a>
                          <ButtonGroup size="sm">
                            <Button
                              color="primary"
                              outline={!cart.checked}
                              onClick={() => handleAddToUnifiedCarts(cart.id)}
                              disabled={cartsToUnify.length >= 6}
                            >
                              Sim
                            </Button>
                            <Button
                              color="primary"
                              outline={cart.checked}
                              onClick={() =>
                                handleRemoveFromUnifiedCarts(cart.id)
                              }
                            >
                              Não
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    ))}
                  </PerfectScrollbar>
                </Col>
              </>
            )}

            {alerts.map(alert => (
              <React.Fragment key={alert.type}>
                {alert.type !== 'CARTS_WITH_CLOSE_DESTINATION' && (
                  <Row className="w-100 mx-0 mt-4">
                    <Col key={alert.type}>
                      <PerfectScrollbar
                        style={{ height: 'fit-content', maxHeight: '150px' }}
                      >
                        <h5>{alert.text}</h5>
                        {alert.carts.map(cart => (
                          <Row
                            className="w-100 mx-0 align-items-center my-2"
                            key={cart.id}
                          >
                            <Col>
                              <b>{`Pedido: ${cart.order_number}`}</b>
                            </Col>
                            <Col align="end">
                              <Button
                                size="sm"
                                color="info"
                                onClick={() => {
                                  window.open(
                                    `${window.location.origin}/pedido/${cart.id}`,
                                    '_blank'
                                  );
                                }}
                              >
                                Visualizar pedido
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </PerfectScrollbar>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button
              className="new-layout-btn main"
              color="success"
              onClick={() => handleUnifyDeliveries()}
              disabled={cartsToUnify.length < 1 || cartsToUnify.length > 6}
            >
              Unificar entregas
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
