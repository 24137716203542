import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
// import history from '~/services/history';
import pinned from '~/assets/icons/pinned.svg';
import pinnedGreen from '~/assets/icons/pinned-green.svg';
import cardLine from '~/assets/images/details/card-line.svg';
import cardLineGreen from '~/assets/images/details/card-line-green.svg';
import api from '~/services/api';
import { formatWhatsappPhoneNumber } from '~/util/format';

function CustomerCard({ customer, onClick, loadCustomers, fixed, readed }) {
  const profile_id = useSelector(({ user }) => user.profile.id);

  const markCustomerAsRead = async () => {
    await api.patch(`/crm/customers/${customer.id}/mark-as-read`);
  };

  const customerSwitchFixed = async () => {
    await Promise.all([
      markCustomerAsRead(),
      await api.patch(
        `/crm/customers/${customer.id}/mark-as-${fixed ? 'unfix' : 'fixed'}`
      ),
    ]);
    loadCustomers();
  };

  const userTag = useMemo(
    () => customer.users.find(({ id }) => id === profile_id),
    [profile_id, customer.users]
  );

  return (
    <Link
      to={`/carteira-de-clientes/${customer.id}`}
      onClick={() => markCustomerAsRead()}
    >
      <Card
        className="card new-layout grow-on-hover customer-card"
        style={{
          transition: '0.5s ease',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <CardBody className="card-body new-layout px-3 py-3">
          <Row
            className="w-100 h-100 mx-0 d-flex justify-content-between"
            style={{ flexWrap: 'nowrap' }}
          >
            <img
              src={!readed || fixed ? cardLineGreen : cardLine}
              alt=""
              style={{
                display: readed === null && 'none',
                position: 'absolute',
                left: '0',
                top: '0',
                bottom: '0',
                margin: 'auto 0',
                height: '50%',
                width: '6px',
              }}
            />
            <Col
              className="mx-0 px-0 d-flex flex-column"
              style={{ maxWidth: 'calc(100% - 100px)' }}
            >
              <p className="mb-1 text-dark font-size-15">{customer.name}</p>
              <p className="mb-0 text-secondary">{userTag.tag}</p>
            </Col>
            <Col
              style={{ gap: '12px' }}
              className="mx-0 px-0 d-flex justify-content-end font-size-10"
            >
              <button
                className="my-auto font-size-24 border-none bg-white"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  customerSwitchFixed();
                }}
                type="button"
              >
                <img src={fixed ? pinnedGreen : pinned} alt="" />
              </button>
              <a
                className="my-auto font-size-24"
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                  customer.whatsapp
                )}`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  markCustomerAsRead();
                  window.open(
                    `https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                      customer.whatsapp
                    )}`
                  );
                }}
              >
                {customer.whatsapp && (
                  <i className="fab fa-whatsapp text-success mt-1 pt-2" />
                )}
              </a>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
}

export default CustomerCard;
