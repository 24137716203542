import * as Yup from 'yup';

const schema = maskTelephoneLength =>
  Yup.object().shape({
    fantasy_name: Yup.string().required('Insira o Nome Fantasia'),
    name: Yup.string().required('Insira a Razão Social'),
    cnpj: Yup.string()
      .min(18, 'Insira todos os dígitos do CNPJ')
      .required('Digite o CNPJ'),
    telephone: Yup.string()
      .required('Insira o telefone')
      .min(maskTelephoneLength, 'Insira todos os dígitos'),
    email: Yup.string()
      .email('E-mail no formato inválido')
      .required('Insira o e-mail'),
    responsible_name: Yup.string().required('Insira o nome do responsável'),
    keys: Yup.object().shape({
      tracksale: Yup.string(),

      googleplaces: Yup.string(),

      beedelivery: Yup.string(),
    }),
    configurations: Yup.object().shape({
      tracksale_campaign_code: Yup.string(),

      signup_code: Yup.string(),
    }),

    address: Yup.object().shape({
      cep: Yup.string()
        .required('Insira o CEP corretamente')
        .min(9, 'Insira todos os dígitos do CEP'),
      street_name: Yup.string().required('Insira o nome da rua'),
      number: Yup.string()
        .matches(/^(\d+|sn)$/i, 'Informe apenas números neste campo ou SN')
        .max(10, 'Número deve ter no máximo 10 caracteres')
        .required('Insira o número ou use sn'),
      neighborhood: Yup.string().required('Insira o nome do bairro'),
      state: Yup.string().required('Selecione o estado'),
      city: Yup.string().required('Insira o nome da cidade'),
      complement: Yup.string()
        .notRequired()
        .max(128, 'Máximo de 128 caracteres'),
      additional_information: Yup.string()
        .notRequired()
        .max(
          128,
          'As informações adicionais devem ter no máximo 128 caracteres'
        ),
    }),
  });

export default schema;
