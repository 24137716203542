import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';

const trackingId = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.pageview(location.pathname);
});

export default history;
