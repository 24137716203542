import React from 'react';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
// import history from '~/services/history';

export default function SubscriberCard({ subscriber, handleEnterSubscriber }) {
  return (
    <Card className="card new-layout list-card grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 h-100 mx-0 d-none d-md-flex">
          <Col xs={1} align="center" className="h-100">
            <div
              className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap d-none"
              onClick={e => {
                e.stopPropagation();
              }}
              role="button"
              tabIndex={0}
              aria-label="checkbox-wrapper-div"
            >
              <CustomInput
                type="checkbox"
                id={`${subscriber.id}-checkbox`}
                className="text-primary d-none"
              />
            </div>
          </Col>
          <Col
            xs={4}
            align="start"
            className="h-100 d-flex flex-column justify-content-center font-weight-600 text-gray-700 text-truncate"
          >
            {subscriber.fantasy_name}
          </Col>
          <Col
            xs={2}
            align="start"
            className="h-100 d-flex flex-column justify-content-center"
          >
            {subscriber.cnpj}
          </Col>
          <Col xs={3} align="center" className="h-100">
            <div className="new-layout-table-column-value h-100 text-truncate">
              {subscriber.formatted_created_at}
            </div>
          </Col>
          <Col xs={1} align="end" className="h-100">
            <div className="h-100 d-flex flex-row align-items-center justify-content-around">
              <IconButton
                className="pl-2"
                icon="fas fa-chevron-circle-right"
                color="info"
                id={`enter-btn-${subscriber.id}`}
                tip="Logar"
                click={() => handleEnterSubscriber(subscriber.id)}
              />
            </div>
          </Col>
        </Row>
        <Row className="w-100 h-100 mx-0 d-md-none">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col xs={9} align="start" className="px-0">
                <Row className="w-100 mx-0">
                  <div className="d-flex flex-row align-items-baseline w-100">
                    <div className="new-layout-card-mobile label d-inline">
                      Nome Fantasia:
                    </div>
                    <div
                      className="new-layout-card-list-strong-value ml-1 d-inline text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {subscriber.fantasy_name}
                    </div>
                  </div>
                </Row>
              </Col>
              <Col xs={3} className="px-0" align="end">
                <div className="h-100 d-flex flex-row justify-content-around">
                  <IconButton
                    className="pl-2"
                    icon="fas fa-chevron-circle-right"
                    color="info"
                    id={`enter-btn-${subscriber.id}`}
                    tip="Logar"
                    click={() => handleEnterSubscriber(subscriber.id)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    CNPJ:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {subscriber.cnpj}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Cadastro:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {subscriber.formatted_created_at}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
