import * as Yup from 'yup';

const schema = maskTelephoneLength =>
  Yup.object().shape({
    name: Yup.string().required('Insira seu nome'),
    telephone: Yup.string()
      .required('Insira seu telefone')
      .min(maskTelephoneLength, 'Insira todos os dígitos'),
    email: Yup.string()
      .required('Insira seu e-mail')
      .email('E-mail no formato inválido'),
    cpf: Yup.string().min(14, 'Insira todos os dígitos do CPF'),
  });

export default schema;
