import { useEffect, useState } from 'react';

const useBatchSelect = items => {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (item, isSelected) => {
    if (isSelected) {
      setSelectedItems(selectedItems.filter(i => i.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    if (allSelected) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected]);

  return {
    allSelected,
    setAllSelected,
    selectedItems,
    setSelectedItems,
    handleSelect,
  };
};

export default useBatchSelect;
