/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  UncontrolledTooltip,
  CustomInput,
} from 'reactstrap';

import { toast } from 'react-toastify';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';

import { setAsRescheduled } from '~/store/modules/sales/actions';

import UnifyDeliveriesModal from '~/components/Modals/UnifyDeliveriesModal.js';
import { setStock, setTemporaryStore } from '~/store/modules/cart/actions';

import SaleStatusBadge from '~/components/SaleStatusBadge';
import CartMainInfoCard from '~/components/CartDetailsComponents/Cards/CartMainInfoCard';
import ProductsListCard from '~/components/CartDetailsComponents/Cards/ProductsListCard';
import PaymentSituationCard from '~/components/CartDetailsComponents/Cards/PaymentSituationCard';
import FinalValueCard from '~/components/CartDetailsComponents/Cards/FinalValueCard';
import DeliveryInfoCard from '~/components/CartDetailsComponents/Cards/DeliveryInfoCard';
import CartManagementButtons from '~/components/CartDetailsComponents/CartManagementButtons';
import { LG } from '~/util/breakpointWidths';
import {
  formatCustomer,
  formatPayment,
  formatProducts,
  formatCart,
  formatStore,
} from '~/objectFormatters/Sale';
import PageHeader from '~/components/Common/PageHeader';
import StockManagementButtons from '~/components/CartDetailsComponents/StockManagementButtons';
import Loading from '~/components/Loading';
import TimePicker from '~/components/TimePicker';
import DatePicker from '~/components/DatePicker';
import GiftBoxesCard from '~/components/CartDetailsComponents/Cards/GiftBoxesCard';
import TaxCuponsCard from '~/components/CartDetailsComponents/Cards/TaxCuponsCard';
import { getRole } from '~/util/getRole';

const Stock = () => {
  const dispatch = useDispatch();

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
        'stockist',
      ]),
    [roles]
  );

  const history = useHistory();

  const mountedRef = useRef(true);

  const formRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [loadingInformation, setLoadingInformation] = useState(true);
  const [showUnifyDeliveriesModal, setShowUnifyDeliveriesModal] =
    useState(false);
  const [isCancelDeliveryModalOpen, setIsCancelDeliveryModalOpen] =
    useState(false);
  const [isFinishCancelledOrderModalOpen, setIsFinishCancelledOrderModalOpen] =
    useState(false);
  const [isRetryDeliveryModalOpen, setIsRetryDeliveryModalOpen] =
    useState(false);

  const [deliveryNow, setDeliveryNow] = useState(true);
  const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState(null);
  const [scheduledDeliveryTime, setScheduledDeliveryTime] = useState(null);

  const [prevStatus, setPrevStatus] = useState();

  const stock = useSelector(({ cart }) => cart.stock);
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState([]);
  const [unifiableCarts, setUnifiableCarts] = useState([]);
  const [delivery, setDelivery] = useState();
  const [alerts, setAlerts] = useState([]);
  const [cartsToUnify, setCartsToUnify] = useState([]);

  const [separated, setSeparated] = useState(false);
  const [done, setDone] = useState(false);
  const [fee, setFee] = useState(null);
  const [freight, setFreight] = useState(undefined);
  // const [shareableURL, setShareableURL] = useState(null);

  const [storeInfo, setStoreInfo] = useState();

  const showGiftBoxesCard = useMemo(
    () => products?.some(p => p.pivot.gift),
    [products]
  );

  const [pageFirstLoadDone, setPageFirstLoadDone] = useState(false);
  // const [scheduledDateTime, setScheduledDateTime] = useState('');

  const { id: stock_id } = useParams();

  // const useStyles = makeStyles(() => ({
  //   container: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  //   },
  //   textField: {
  //     width: '100%',
  //     marginTop: 12,
  //   },
  // }));

  // const classes = useStyles();

  const isAllChecked = useCallback(array => {
    const allChecked = array.filter(p => p.checked !== true);

    if (!allChecked.length) return true;
    return false;
  }, []);

  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const showSidebar = useSelector(({ layout }) => layout.windowSize);

  const showButtons = useMemo(() => {
    if (windowSize < LG) {
      return true;
    }
    return false;
  }, [windowSize]);

  const setData = cart => {
    const formattedStock = formatCart(cart);
    dispatch(setStock(formattedStock));
  };

  const setProductsData = productsArr => {
    const formattedProducts = formatProducts(productsArr);
    setProducts(formattedProducts);
  };

  const handleUploadTaxCoupon = async data => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append('tax_coupon', data);

      await api.post(`${role}/carts/${stock_id}/attach-tax-coupon`, formData);

      const {
        data: { cart },
      } = await api.get(`${role}/carts/${stock_id}`);

      setData(cart);

      setLoading(false);

      toast.success('Cupom fiscal anexado');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  useEffect(() => {
    if (!pageFirstLoadDone && stock) {
      dispatch(setStock(null));
      setPageFirstLoadDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadDelivery = async () => {
      try {
        const { data } = await api.get(`stockist/carts/${stock_id}/delivery`);

        if (data) {
          setDelivery(data.delivery);
          setPrevStatus(data.delivery?.status);
        }
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const loadStock = async () => {
      try {
        const {
          data: { cart },
        } = await api.get(`/stockist/carts/${stock_id}`);

        if (!mountedRef.current) return null;

        if (
          ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
            cart.delivery_method
          )
        ) {
          const {
            data: { alerts: alertsList },
          } = await api.get(`stockist/carts/${stock_id}/alerts`);

          setAlerts(alertsList);
          const unifiableAlert = alertsList.filter(
            a => a.type === 'CARTS_WITH_CLOSE_DESTINATION'
          );

          const unifiableCartsList = unifiableAlert[0]?.carts.map(c => ({
            ...c,
            checked: false,
          }));

          setUnifiableCarts(unifiableCartsList);
        }

        const productsCheck = cart.products.map(p => ({
          ...p,
          checked: !!p.pivot.availability_confirmed_at,
        }));

        const checkedProducts = productsCheck.filter(p => p.checked !== true);

        if (
          cart?.status !== 6 &&
          cart?.status >= 3 &&
          cart.is_for_delivery &&
          ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
            cart.delivery_method
          )
        ) {
          await loadDelivery();
          setDone(true);
          setSeparated(true);
        }

        if (cart?.status !== 6 && cart?.status >= 1 && cart.is_for_delivery) {
          const { data } = await api.get(
            `stockist/carts/${stock_id}/delivery/freight`
          );
          setFreight(data.freight);
        }

        if (
          cart?.status >= 2 &&
          checkedProducts.length === cart.products.length
        ) {
          setDone(true);
        }
        if (cart?.status > 0 && cart.payment_process) {
          const { data: paymentInfo } = await api.get(
            `stockist/carts/${stock_id}/payment`
          );

          const formattedFee = formatPayment(paymentInfo);
          setFee(formattedFee);

          if (
            cart.payment_type === 101 ||
            cart.payment_type === 102 ||
            ((cart.payment_type === 203 || cart.payment_type === 303) &&
              Number(
                paymentInfo.second_payment?.change || paymentInfo.change
              ) ===
                Number(
                  paymentInfo.second_payment?.amount || paymentInfo.amount
                ))
          ) {
            setSeparated(true);
          }
        }

        if (!cart.is_for_delivery || cart.payment_type === 102) {
          const {
            data: { store },
          } = await api.get(`stores/${cart.store.id}`);

          const formattedStore = formatStore(store);

          setStoreInfo(formattedStore);
        }

        if (!isAllChecked(productsCheck)) {
          setDone(false);
          setSeparated(false);
        }

        if (cart) {
          const {
            data: { client: customer },
          } = await api.get(`clients/${cart.client.id}`);

          const formattedCustomer = formatCustomer(customer);

          setClient(formattedCustomer);

          setData(cart);
          dispatch(
            setTemporaryStore({
              value: cart.store.id,
            })
          );
          setProductsData(productsCheck);
          // if (cart.is_shareable) {
          //   const rawShareableURL = `${window.location.origin}/meu-carrinho/?id=${cart?.id}&token=${cart.shareable_token}`;
          //   tinyurl.shorten(rawShareableURL, (res, err) => {
          //     if (!err) {
          //       setShareableURL(res);
          //     } else {
          //       toast.error('Houve um erro ao gerar o link do carrinho');
          //     }
          //   });
          // }
          setLoading(false);
        }

        // setLoadingInformation(false);
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            'Houve um erro ao tentar carregar as informações do pedido!'
          );
        }

        history.push('/estoque');
      }
    };

    loadStock();

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllChecked, products.length, stock_id]);

  useEffect(() => {
    if (isAllChecked(products)) {
      setDone(true);
      if (
        stock &&
        (stock.payment_type === 101 ||
          stock.payment_type === 102 ||
          ((stock.payment_type === 203 || stock.payment_type === 303) &&
            fee &&
            Number(fee.second_payment?.change || fee.change) ===
              Number(fee.second_payment?.amount || fee.amount)))
      ) {
        setSeparated(true);
      }
    } else {
      setDone(false);
      setSeparated(false);
    }
  }, [fee, isAllChecked, products, stock]);

  useEffect(() => {
    let interval;
    document.title = 'Pedido | Viddy';

    if (
      stock &&
      stock.is_for_delivery &&
      stock?.status >= 3 &&
      stock?.status !== 6 &&
      stock.delivery_method === 'BEE'
    )
      interval = setInterval(async () => {
        const { data } = await api.get(`/stockist/carts/${stock_id}/delivery`);
        setDelivery(data.delivery);
        const stockDraft = {
          ...stock,
          delivery: data.delivery,
        };
        setData(stockDraft);
        setPrevStatus(data.delivery?.status);
        if (prevStatus !== data.delivery?.status) {
          toast.success('Status de entrega atualizada!', { autoClose: 5000 });
        }
      }, 5 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevStatus, stock_id, stock]);

  const handleFinishCancelledOrder = async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        finalization_reason: Yup.string().required(
          'Insira o método da entrega'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        data: { cart },
      } = await api.patch(`stockist/carts/${stock_id}/finalize`, data);

      setData(cart);
      setIsFinishCancelledOrderModalOpen(false);
      toast.success('Venda manualmente marcada como entregue com sucesso!');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const checkDoneAndChange = () => {
    if (!isAllChecked(products)) {
      toast.error('Confirme todos os produtos');
    } else {
      setDone(true);
      setSeparated(true);
      toast.success('Tudo certo! Pode finalizar.');
    }
  };

  const handleAddToUnifiedCarts = cartId => {
    const alreadyAdded = unifiableCarts.find(cart => cart === cartId);
    if (alreadyAdded) return;

    const draft = unifiableCarts.map(cart =>
      cart.id === cartId ? { ...cart, checked: true } : cart
    );

    setUnifiableCarts(draft);

    setCartsToUnify(
      draft.filter(cart => {
        return cart.checked;
      })
    );
  };

  const handleRemoveFromUnifiedCarts = cartId => {
    const draft = unifiableCarts.map(cart =>
      cart.id === cartId ? { ...cart, checked: false } : cart
    );

    setUnifiableCarts(draft);

    setCartsToUnify(
      draft.filter(cart => {
        return cart.checked;
      })
    );
  };

  const handleUnifyDeliveries = async () => {
    const parentCard = [
      { id: stock_id, order_number: stock.order_number, checked: true },
    ];

    const unifiableCartsList = parentCard
      .concat(unifiableCarts)
      .filter(c => c.checked === true)
      .map(c => c.id);

    if (unifiableCartsList.length === 1) {
      toast.error(
        'Para unificar entregas, se faz necessário dois ou mais carrinhos, incluindo o atual.'
      );
    } else {
      history.push('/unificar-entregas', {
        carts: unifiableCartsList,
      });
    }
  };

  const handleRetryDelivery = async () => {
    try {
      setLoading(true);

      const date = format(scheduledDeliveryDate, 'dd MMMM yyyy');
      const time = format(scheduledDeliveryTime, "HH':'mm'");

      const deliveryMoment = new Date(`${date} ${time} UTC`);

      const {
        data: { delivery: stockRetryDelivery },
      } = await api.post(`stockist/carts/${stock_id}/delivery/retry`, {
        scheduled_to: deliveryMoment,
      });

      setDelivery(stockRetryDelivery);

      setIsRetryDeliveryModalOpen(false);

      dispatch(setAsRescheduled());

      setLoading(false);

      toast.success('Nova tentativa de entrega processada!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const handleRetryDeliveryNow = async () => {
    try {
      setLoading(true);

      // const now = new Date();
      // now.setMinutes(now.getMinutes() + 1);
      // const nowToISO = now.toISOString();

      const {
        data: { delivery: stockRetryDelivery },
      } = await api.post(`stockist/carts/${stock_id}/delivery/retry`);
      // const {
      //   data: { delivery: stockRetryDelivery },
      // } = await api.post(`stockist/carts/${stock_id}/delivery/retry`, {
      //   scheduled_to: nowToISO,
      // });

      setDelivery(stockRetryDelivery);

      setIsRetryDeliveryModalOpen(false);

      dispatch(setAsRescheduled());

      setLoading(false);

      toast.success('Nova tentativa de entrega processada!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const handleResetDelivery = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.post(
        `stockist/carts/${stock_id}/delivery/reset`
      );

      const stockDraft = {
        ...stock,
        delivery: data.delivery,
      };

      setData(stockDraft);

      setDelivery(data.delivery);

      setLoading(false);
      toast.success('Entrega reiniciada, buscando novo entregador!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock_id, stock]);

  const handleCancelDelivery = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          reason: Yup.string().required('Insira o motivo do cancelamento'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const {
          data: { delivery: stockDelivery },
        } = await api.patch(`stockist/carts/${stock_id}/delivery/cancel`, data);

        const stockDraft = {
          ...stock,
          delivery: stockDelivery,
        };

        setData(stockDraft);

        setDelivery(stockDelivery);

        setLoading(false);

        setIsCancelDeliveryModalOpen(false);

        toast.success('Entrega cancelada com sucesso!');
      } catch (err) {
        const validationErrors = {};

        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(e => {
            validationErrors[e.path] = e.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        if (err.response && err?.response?.data?.message)
          toast.error(err?.response?.data?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stock_id, stock]
  );

  const toggle = () => setShowUnifyDeliveriesModal(!showUnifyDeliveriesModal);

  const toggleCancelDelivery = () =>
    setIsCancelDeliveryModalOpen(!isCancelDeliveryModalOpen);

  const toggleFinishCancelledOrder = () =>
    setIsFinishCancelledOrderModalOpen(!isFinishCancelledOrderModalOpen);

  const toggleRetryDelivery = () =>
    setIsRetryDeliveryModalOpen(!isRetryDeliveryModalOpen);

  const alertsLength = useMemo(() => alerts.length, [alerts]);

  useEffect(() => {
    const isFreightValid =
      !stock?.is_for_delivery || (freight !== null && freight !== undefined);
    if (loadingInformation && stock && fee && isFreightValid && client) {
      setLoadingInformation(false);
    }
  }, [fee, stock, freight, client, loadingInformation]);

  useEffect(() => {
    return () => {
      dispatch(setStock(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0">
          <Col xs={10} className="px-0">
            <PageHeader
              pageTitle="DETALHES DO PEDIDO"
              responsivePosition="start"
              desktopPosition="start"
              backTo="/estoque"
            />
          </Col>
          <Col xs={2} className="px-0" align="end">
            {stock?.delivery_method === 'BEE' &&
              stock?.status <= 2 &&
              alertsLength !== 0 && (
                <>
                  <UncontrolledTooltip placement="top" target="alertIcon">
                    Alertas
                  </UncontrolledTooltip>
                  <i
                    id="alertIcon"
                    className="las la-exclamation-triangle bx-tada alert-icon my-4 font-size-60"
                    onClick={() =>
                      setShowUnifyDeliveriesModal(!showUnifyDeliveriesModal)
                    }
                    role="button"
                    tabIndex="0"
                    aria-label="Open alert modal"
                  />
                </>
              )}
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className="w-100 mx-0">
              <Col lg={showSidebar ? 8 : 7} className="px-0 pr-lg-2">
                <Row className="w-100 mx-0 d-lg-none">
                  <Col className="px-0">
                    <SaleStatusBadge cart={stock} cartDetails />
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <CartMainInfoCard
                      cart={stock}
                      customer={client}
                      setData={setData}
                      setProductsData={setProductsData}
                      // shareableURL={shareableURL}
                    />
                  </Col>
                </Row>
                {stock.products.length > 0 && (
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <ProductsListCard
                        products={products}
                        cart={stock}
                        setProductsData={setProductsData}
                        setDone={setDone}
                        setSeparated={setSeparated}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <TaxCuponsCard
                      cart={stock}
                      handleUploadTaxCoupon={handleUploadTaxCoupon}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={showSidebar ? 4 : 5} className="px-0 pl-lg-2">
                <FinalValueCard cart={stock} />

                <PaymentSituationCard
                  payment={fee}
                  cart={stock}
                  store={storeInfo}
                  done={done}
                  checkDoneAndChange={checkDoneAndChange}
                  separated={separated}
                  setSeparated={setSeparated}
                />
                <DeliveryInfoCard
                  delivery={delivery}
                  cart={stock}
                  store={storeInfo}
                  handleResetDelivery={handleResetDelivery}
                  toggleCancelDelivery={toggleCancelDelivery}
                  toggleRetryDelivery={toggleRetryDelivery}
                />
                {showGiftBoxesCard && <GiftBoxesCard products={products} />}
              </Col>
            </Row>
            {showButtons && (
              <CartManagementButtons
                cart={stock}
                setData={setData}
                setProductsData={setProductsData}
              />
            )}
            <StockManagementButtons
              stock={stock}
              delivery={delivery}
              separated
              toggleFinishCancelledOrder={toggleFinishCancelledOrder}
            />
          </>
        )}
      </Container>

      {delivery && (
        <Modal
          isOpen={isRetryDeliveryModalOpen}
          toggle={toggleRetryDelivery}
          scrollable
        >
          <ModalHeader toggle={toggleRetryDelivery}>
            Nova tentativa de Entrega
          </ModalHeader>

          <ModalBody>
            <h5>
              Deseja realizar uma nova tentativa de entrega para o pedido #
              <strong>{stock?.order_number}</strong>?
            </h5>
            <h5>Nova tentativa agora?</h5>
            <CustomInput
              type="switch"
              id="deliveryNow"
              name="deliveryNow"
              label="Nova tentativa de entrega agora"
              checked={deliveryNow}
              className="text-primary z-index-0"
              onChange={() => setDeliveryNow(!deliveryNow)}
            />

            {!deliveryNow && (
              <Row className="w-100 mx-0 mt-2">
                Para nova tentativa, tempo mínimo de 35 minutos a partir de
                agora.
                <Col lg={6} className="px-0 pr-lg-2 mb-2 mb-lg-0">
                  <DatePicker
                    value={scheduledDeliveryDate}
                    onChange={selectedDate =>
                      setScheduledDeliveryDate(selectedDate)
                    }
                    mask="__/__/___"
                    placeholder="Dia"
                    disablePast
                  />
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <TimePicker
                    value={scheduledDeliveryTime}
                    onChange={selectedTime =>
                      setScheduledDeliveryTime(selectedTime)
                    }
                    mask="__:__ _M"
                    placeholder="Horário"
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                deliveryNow ? handleRetryDeliveryNow() : handleRetryDelivery()
              }
            >
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {delivery && (
        <Modal
          isOpen={isCancelDeliveryModalOpen}
          toggle={toggleCancelDelivery}
          scrollable
        >
          <ModalHeader toggle={toggleCancelDelivery}>
            Cancelar Entrega
          </ModalHeader>

          <Form onSubmit={handleCancelDelivery} ref={formRef}>
            <ModalBody>
              <h5>
                A entrega do pedido #<strong>{stock?.order_number}</strong> será
                cancelada.
              </h5>
              <FormGroup className="mt-3 mb-0">
                <Input
                  name="reason"
                  placeholder="Motivo do cancelamento"
                  type="text"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="new-layout-btn main"
                type="submit"
                color="danger"
              >
                Confirmar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}

      {delivery && (
        <Modal
          isOpen={isFinishCancelledOrderModalOpen}
          toggle={toggleFinishCancelledOrder}
          scrollable
        >
          <ModalHeader toggle={toggleFinishCancelledOrder}>
            Marcar como entregue pedido cancelado
          </ModalHeader>

          <Form onSubmit={handleFinishCancelledOrder} ref={formRef}>
            <ModalBody>
              <h6>
                <strong>Número do pedido: {stock?.order_number}.</strong>
              </h6>
              <h5>
                O pedido de <strong>{stock?.client.name}</strong> será marcado
                como finalizado mesmo tendo sua entrega cancelada.
              </h5>

              <FormGroup className="mt-3 mb-0">
                <Input
                  name="finalization_reason"
                  placeholder="Método de entrega"
                  type="text"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => setIsFinishCancelledOrderModalOpen(false)}
                color="secondary"
              >
                Sair
              </Button>
              <Button type="submit" color="success">
                Confirmar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}

      <UnifyDeliveriesModal
        showModal={showUnifyDeliveriesModal}
        toggle={toggle}
        alerts={alerts}
        alertsLength={alertsLength}
        unifiableCarts={unifiableCarts}
        cartsToUnify={cartsToUnify}
        addCart={handleAddToUnifiedCarts}
        removeCart={handleRemoveFromUnifiedCarts}
        unifyDeliveries={handleUnifyDeliveries}
      />
    </div>
  );
};

export default Stock;
