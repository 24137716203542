import React from 'react';
import { Button, Modal, ModalBody, Row } from 'reactstrap';

const DefaultStoreAlert = ({ show, setShow, submit }) => (
  <Modal isOpen={show}>
    <ModalBody className="d-flex flex-column align-items-center">
      <h3 className="mx-3 my-2 text-center text-bold">Atenção</h3>
      <h5 className="mx-3 my-2 text-center">
        Você está fazendo uma venda para uma loja que não é a sua loja padrão.
        Você tem certeza que gostaria de prosseguir com essa venda?
      </h5>
      <Row className="mx-0">
        <Button
          className="d-none d-lg-block new-layout-btn main mt-4 px-5 mr-lg-2"
          color="danger"
          size="lg"
          onClick={() => setShow(false)}
        >
          Cancelar
        </Button>
        <Button
          className="new-layout-btn main mt-4 mt-lg-4 px-5 ml-lg-2"
          color="success"
          size="lg"
          onClick={() => {
            submit();
            setShow(false);
          }}
        >
          Confirmar
        </Button>
        <Button
          className="d-block d-lg-none new-layout-btn main mt-2 px-5 mr-lg-2"
          color="danger"
          size="lg"
          onClick={() => setShow(false)}
        >
          Cancelar
        </Button>
      </Row>
    </ModalBody>
  </Modal>
);

export default DefaultStoreAlert;
