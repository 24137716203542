import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import api from '~/services/api';

export default function GenerateMonthlyReport() {
  const handleGenerateMonthlyCodesReport = async () => {
    try {
      toast.info('Estamos gerando seu relatório...');

      await api
        .get(`administrator/referral-codes/report`, {
          responseType: 'blob',
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute(
            'download',
            `Relatório de Códigos de Indicação.pdf`
          );
          document.body.appendChild(tempLink);
          tempLink.click();
        });

      toast.success('Relatório gerado, baixando arquivo.');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Ocorreu um erro ao gerar relatório. Tente novamente.');
      }
    }
  };
  return (
    <Button
      className="new-layout-btn main"
      onClick={() => handleGenerateMonthlyCodesReport()}
      color="primary"
    >
      <i className="fas fa-book font-size-14 align-middle mr-2" />
      Relatório Mensal
    </Button>
  );
}
