import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';
// import useRoles from '~/hooks/useRoles';

export default function TaxCuponsCard({ cart, handleUploadTaxCoupon }) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const inputRef = useRef(null);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 mb-lg-4 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 text-gray-600 px-0">
            <Row className="w-100 mx-0 d-flex align-items-center">
              <Col className="px-0 text-uppercase" xs={10}>
                Cupom Fiscal
              </Col>
              <Col className="px-0 d-lg-none" xs={2} align="end">
                <IconButton
                  icon={`las la-${expanded ? 'minus' : 'plus'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          {cart.receipt ? (
            <Row className="w-100 mx-0 header py-2 d-lg-flex">
              <Row className="w-100 mx-0 mb-3">
                <Col
                  lg={6}
                  className="d-flex justify-content-between bg-layout-lite py-4 align-items-center"
                >
                  <Button
                    className="border-0 bg-transparent color-blue-lite"
                    href={cart.receipt?.url}
                    target="_blank"
                    download
                  >{`${cart.receipt?.name}`}</Button>
                  {cart?.status < 4 && (
                    <IconButton
                      lg={1}
                      icon="las la-trash"
                      className="px-0 pl-lg-2"
                      color="red"
                      onClick={_ => {
                        handleUploadTaxCoupon(null);
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Row>
          ) : (
            <>
              {cart?.status >= 4 && (
                <Row className="w-100 mx-0 header py-2 d-lg-flex">
                  Não existe nenhum cupom fiscal anexado ao pedido.
                </Row>
              )}
            </>
          )}
          {cart?.status < 4 && (
            <Row className="w-100 mx-0 py-3 py-md-0">
              <Col lg={12} className="px-0 pr-lg-2 d-flex">
                <Button
                  className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                  color="primary"
                  onClick={() => inputRef.current?.click()}
                >
                  <i className="las la-paperclip mr-2" />
                  Adicionar
                </Button>
                <input
                  type="file"
                  id="tax_coupon"
                  accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .svg"
                  ref={inputRef}
                  className="d-none"
                  multiple
                  onChange={e => {
                    const { files } = e.target;

                    handleUploadTaxCoupon(files[0]);
                  }}
                />
              </Col>
            </Row>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
}
