import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import {
  Container,
  Card,
  CardBody,
  FormGroup,
  Col,
  Row,
  Button,
  Label,
} from 'reactstrap';

import { toast } from 'react-toastify';
import Input from '~/components/Inputs/Input';
import InputCurrency from '~/components/Inputs/InputCurrency';
import Dropzone from '~/components/Inputs/Dropzone';

import SchemaValidator from '~/validators/form/add_product';

import api from '~/services/api';
import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';

const AddProduct = () => {
  const formRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState();

  const SubmitHandler = async data => {
    try {
      formRef.current.setErrors({});

      await SchemaValidator.validate(data, {
        abortEarly: false,
      });

      const {
        data: { product },
      } = await api.post(`administrator/products`, data);

      if (selectedFile) {
        const formData = new FormData();

        formData.append('image', selectedFile);

        await api.post(
          `administrator/products/${product.id}/set-image`,
          formData
        );
      }

      history.push('/produtos');
      toast.success('Produto criado com sucesso!');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="CADASTRAR PRODUTO"
          backTo="/produtos"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form onSubmit={SubmitHandler} ref={formRef}>
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 my-4">
                <Col className="px-0 px-lg-4">
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-2">
                    Informações do Produto
                  </Row>
                  <h6>Insira todas as informações do produto abaixo.</h6>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0">
                  <Row className="w-100 mx-0">
                    <Col className="px-0 px-lg-4">
                      <FormGroup>
                        <Label htmlFor="name">Nome do Produto</Label>
                        <Input name="name" placeholder="Nome do Produto" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0 px-lg-4">
                      <FormGroup>
                        <Label htmlFor="code">Referência do Produto</Label>
                        <Input name="code" placeholder="Ex: ABCD123" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0 px-lg-4">
                      <FormGroup>
                        <Label htmlFor="bar_code">Código de Barras</Label>
                        <Input
                          name="bar_code"
                          placeholder="Ex: 01273409273023"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0 px-lg-4">
                      <FormGroup>
                        <Label htmlFor="value">Preço</Label>
                        <InputCurrency
                          name="value"
                          placeholder="Preço do produto"
                          currency
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="px-0">
                  <Row className="w-100 mx-0">
                    <Col className="px-0 px-lg-4">
                      <Label>Imagem do Produto</Label>
                      <Dropzone
                        onFileUploaded={setSelectedFile}
                        text="Arraste a foto do produto para cá ou clique para enviar."
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
              >
                Cadastrar Produto
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddProduct;
