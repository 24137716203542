import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import './styles.css';

const Dropzone = ({ onFileUploaded, initialUrlImage, accept, text }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(initialUrlImage || '');
  const [fileType, setFileType] = useState('');

  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      const fileUrl = URL.createObjectURL(file);
      const uploadedFileType = file.name.split(/#|\?/g)[0].split('.').pop();

      // console.log(file);
      setSelectedFileUrl(fileUrl);
      onFileUploaded(file);
      setFileType(uploadedFileType);
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept || 'image/*',
  });

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept={accept || 'image/*'} />

      {selectedFileUrl ? (
        <>
          {fileType === 'pdf' ? (
            <h5>Comprovante salvo com sucesso</h5>
          ) : (
            <img src={selectedFileUrl} alt="Thumbnail" />
          )}
        </>
      ) : (
        <div className="dz-message needsclick">
          <div className="mb-3">
            <i className="display-4 text-muted bx bxs-cloud-upload" />
          </div>
          <h4>{text}</h4>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
