import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useField } from '@unform/core';
import ptBR from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ name, triggerSubmit, ...rest }) => {
  const datepickerRef = useRef(null);

  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [date, setDate] = useState(defaultValue || undefined);
  const [prevDate, setPrevDate] = useState(null);

  registerLocale('pt-BR', ptBR);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: ref => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => clearError(), [clearError, date]);

  useEffect(() => {
    if (date === null) {
      triggerSubmit({ month: null });
    } else if (date && date !== prevDate) {
      triggerSubmit({ month: date });
      setPrevDate(date);
    }
  }, [date, triggerSubmit, prevDate]);

  return (
    <>
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        className={`form-control input-color ${
          error && 'border-danger'
        } cursor-pointer h-100`}
        locale="pt-BR"
        {...rest}
      />
      {error && <span className={error && 'text-danger'}>{error}</span>}
    </>
  );
};

export default DatePicker;
