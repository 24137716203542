import { Cancel } from '@mui/icons-material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
// import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CropIcon from '@mui/icons-material/Crop';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
  Dialog,
} from '@mui/material';
import { minZoom, getCroppedImg } from './utils';

const zoomPercent = value => {
  return `${Math.round(value * 100)}%`;
};

const CropModal = ({ logo, setOpenCrop, openCrop, setLogo, setFile }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, imageCroppedArea) => {
    setCroppedAreaPixels(imageCroppedArea);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        logo,
        croppedAreaPixels,
        rotation
      );
      setLogo(url);
      setFile(file);

      setOpenCrop(false);
    } catch (error) {
      toast.error('Houve um error ao recortar a imagem');
      console.log(error);
    }
  };

  return (
    // <Modal isOpen={openCrop}>
    //   <ModalHeader
    //     toggle={() => {
    //       setOpenCrop(false);
    //     }}
    //   >
    //     Redimensionar sua Logo
    //   </ModalHeader>

    //   <ModalBody>
    //     <Cropper
    //       image={logo}
    //       crop={crop}
    //       zoom={zoom}
    //       rotation={rotation}
    //       aspect={1}
    //       onZoomChange={setZoom}
    //       onRotationChange={setRotation}
    //       onCropChange={setCrop}
    //       onCropComplete={cropComplete}
    //     />
    //   </ModalBody>
    //   <ModalFooter>
    //     <Button
    //       className="new-layout-btn main"
    //       onClick={() => setOpenCrop(false)}
    //       color="secondary"
    //     >
    //       Cancelar
    //     </Button>
    //     <Button
    //       className="new-layout-btn main"
    //       onClick={cropImage}
    //       color="primary"
    //     >
    //       Confirmar
    //     </Button>
    //   </ModalFooter>
    // </Modal>
    <Dialog open={openCrop} close={!openCrop}>
      <DialogContent
        dividers
        sx={{
          background: '#333',
          position: 'relative',
          height: 400,
          width: 'auto',
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={logo}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={8 / 3}
          minZoom={minZoom}
          restrictPosition={false}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
        <Box sx={{ width: '100%', mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={minZoom}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoomU) => setZoom(zoomU)}
            />
          </Box>
          <Box>
            <Typography>Rotação: {`${rotation}°`}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotationA) => setRotation(rotationA)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Cortar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CropModal;
