export default (location, place) => {
  return new Promise((resolve, reject) => {
    const matrix = new window.google.maps.DistanceMatrixService();
    matrix.getDistanceMatrix(
      {
        origins: [location],
        destinations: [place],
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status !== 'OK') {
          reject();
        }
        resolve((response.rows[0].elements[0].distance?.value || 0) / 1000);
      }
    );
  });
};
