import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { useLocation } from 'react-router-dom';
import {
  InitialLoadRequest,
  openChatRequest,
  setActiveTab,
} from '~/store/modules/chat/actions';

import PageHeader from '~/components/Common/PageHeader';
import Loading from '~/components/Loading';
import UserHeaderInfo from '~/components/Common/UserHeaderInfo';
import Feed from './Feed';
import ChatsList from './ChatsList';
import UsersList from './UsersList';

function Chat() {
  const { state } = useLocation();

  const dispatch = useDispatch();

  const currentUser = useSelector(({ user }) => user.profile);

  const loading = useSelector(({ chat }) => chat.loading);
  const loadingChatsOnLogin = useSelector(
    ({ chat }) => chat.loadingChatsOnLogin
  );
  const currentChat = useSelector(({ chat }) => chat.currentChat);

  const activeTab = useSelector(({ chat }) => chat.activeTab);
  const selectModeOn = useSelector(({ chat }) => chat.selectModeOn);

  const [showLoadOldMsgsBtn, setShowLoadOldMsgsBtn] = useState(false);
  const [showChatOptions, setShowChatOptions] = useState(false);

  const [redirected, setRedirected] = useState(state?.redirected || false);

  const [msgsScrollBarOldPosition, setMsgsScrollBarOldPosition] = useState(0);

  const [msgsScrollbarFirstRollDone, setMsgsScrollbarFirstRollDone] =
    useState(false);
  const msgInput = document.getElementById('messageInput');

  const toggle = tab => {
    if (activeTab !== tab) dispatch(setActiveTab(tab));
  };

  // eslint-disable-next-line consistent-return
  const hideChats = useMemo(() => {
    if (currentChat) {
      return 'd-none d-lg-block';
    }
  }, [currentChat]);

  // eslint-disable-next-line consistent-return
  const hideFeed = useMemo(() => {
    if (!currentChat) {
      return 'd-none d-lg-block';
    }
  }, [currentChat]);

  const handleOpenChat = chat => {
    if (msgsScrollbarFirstRollDone) setMsgsScrollbarFirstRollDone(false);

    if (showLoadOldMsgsBtn) setShowLoadOldMsgsBtn(false);

    if (msgsScrollBarOldPosition !== 0) setMsgsScrollBarOldPosition(0);

    dispatch(openChatRequest({ chat }));

    // setCurrentChat(chat);
  };

  useEffect(() => {
    // defines the title of the page
    document.title = 'Chat | Viddy';

    dispatch(InitialLoadRequest());
  }, [dispatch]);

  useEffect(() => {
    if (state && redirected) {
      handleOpenChat(state.chat);

      if (msgInput) {
        msgInput.focus();
        setRedirected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, msgInput]);

  window.addEventListener(
    'click',
    () => {
      if (showChatOptions) {
        setShowChatOptions(false);
      }
    }
    // eslint-disable-next-line func-names
  );

  return (
    <div className="page-content pb-0 pb-lg-4 mb-0 mb-lg-4">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="CHAT"
          responsivePosition="start"
          desktopPosition="start"
          customClassName="d-none d-lg-flex"
        />
        {loading || loadingChatsOnLogin ? (
          <Loading />
        ) : (
          <Row className="w-100 mx-0 my-4 mt-lg-0">
            <Col className={`px-0 mb-5 pr-lg-4 ${hideChats}`} lg={4}>
              <Card className="card new-layout chat-main-cards-borders mb-0 mb-lg-4">
                <Row
                  className="w-100 mx-0 p-3 background-color-f2f2f2"
                  style={{ borderRadius: '3.25px' }}
                >
                  <UserHeaderInfo
                    user={currentUser}
                    onChat
                    scale={0.7143}
                    rolesOnTop
                  />
                </Row>
                <Row
                  className="w-100 mx-0 px-4"
                  style={{
                    borderBottom: '2px solid #f2f2f2',
                  }}
                >
                  <Nav
                    pills
                    className="w-100 border-none d-flex flex-row justify-content-center justify-content-md-start"
                    style={{ marginBottom: '-2px' }}
                  >
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: 'pointer',
                        }}
                        className={`py-3 pl-3 font-size-16 font-weight-600 text-gray-700 cursor-pointer no-border-radius ${
                          activeTab === '1' ? 'tab-active' : 'border-none'
                        }`}
                        onClick={() => {
                          toggle('1');
                        }}
                        disabled={selectModeOn}
                      >
                        Conversas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`py-3 font-size-16 font-weight-600 text-gray-700 cursor-pointer no-border-radius ${
                          activeTab === '2' ? 'tab-active' : 'border-none'
                        }`}
                        onClick={() => {
                          toggle('2');
                        }}
                        disabled={selectModeOn}
                      >
                        Usuários
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Row>
                <CardBody className="card-body new-layout px-0 py-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ChatsList />
                    </TabPane>
                    <TabPane tabId="2">
                      <UsersList />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col className={`px-0 mb-5 ${hideFeed} pl-lg-4`} lg={8}>
              <Feed state={state} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Chat;
