import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Card, CardBody, Col, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import InputSelect from '~/components/Inputs/InputSelect';
import Input from '~/components/Inputs/Input';
import { servicesTypes } from '~/util/servicesTypes';

export default function ExternalServiceCard({ service, index, handleSubmit }) {
  const formRef = useRef(null);
  const [editModeOn, setEditModeOn] = useState(false);

  return (
    <>
      <Card
        className={`card new-layout table-card ${service.border_class} d-none d-lg-block`}
        style={{ minHeight: '90px' }}
      >
        <CardBody
          className="card-body new-layout h-100 px-3 py-4 d-flex flex-row align-items-center"
          style={{ minHeight: 'inherit' }}
        >
          <Row className="w-100 h-100 mx-0 d-none d-lg-flex">
            <Col
              xs={2}
              align="start"
              className="d-flex flex-column justify-content-center"
            >
              <div className="new-layout-card-list-strong-value d-flex flex-wrap word-break-break-all">
                {service.name}
              </div>
            </Col>
            <Col
              xs={3}
              align="start"
              className="d-flex flex-column justify-content-center"
            >
              <div
                className={`new-layout-card-list-value h-100 d-flex ${
                  service.description.includes(' ')
                    ? 'flex-wrap'
                    : 'word-break-break-all'
                }`}
              >
                {service.description}
              </div>
            </Col>

            <Col xs={6} align="start" className="px-0">
              <Form
                onSubmit={data => {
                  setEditModeOn(false);
                  handleSubmit(data, service, index);
                }}
                ref={formRef}
                initialData={service}
                className="w-100 h-100"
              >
                <Row className="w-100 h-100 mx-0">
                  <Col
                    xs={4}
                    align="start"
                    className="d-flex flex-column justify-content-center"
                  >
                    {editModeOn ? (
                      <Input name="key" placeholder="Chave do serviço" />
                    ) : (
                      <div className="new-layout-card-list-value w-100 word-break-break-all">
                        {service.key}
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={4}
                    align="start"
                    className="d-flex flex-column justify-content-center"
                  >
                    {editModeOn ? (
                      <Input name="uri" placeholder="URL do serviço" />
                    ) : (
                      <div className="new-layout-card-list-value w-100 word-break-break-all">
                        {service.uri}
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={4}
                    align="center"
                    className="d-flex flex-column justify-content-center"
                  >
                    {editModeOn ? (
                      <InputSelect
                        name="service_type"
                        options={servicesTypes}
                        placeholder="Tipo de serviço"
                      />
                    ) : (
                      <div className="new-layout-card-list-value w-100 word-break-break-all">
                        {service.service_type || 'Não informado'}
                      </div>
                    )}
                  </Col>
                </Row>
                {/* The button below is a workaround that makes the form to be submitted when pressing enter */}
                <button
                  type="submit"
                  className="d-none"
                  aria-label="mock-btn"
                />
              </Form>
            </Col>

            <Col
              xs={1}
              align="center"
              className="d-flex flex-column justify-content-center"
            >
              {!editModeOn ? (
                <IconButton
                  icon="las la-pen"
                  color="secondary"
                  id={`edit-btn-${service.id}`}
                  tip="Editar"
                  click={() => {
                    setEditModeOn(true);
                  }}
                />
              ) : (
                <Row className="w-100 mx-0">
                  <Col xs={6} className="px-0">
                    <IconButton
                      icon="las la-times"
                      color="danger"
                      id={`cancelar-btn-${service?.id}`}
                      tip="Cancelar Edição"
                      click={() => {
                        setEditModeOn(false);
                      }}
                    />
                  </Col>
                  <Col xs={6} className="px-0">
                    <IconButton
                      icon="las la-save"
                      color="primary"
                      id={`save-btn-${service?.id}`}
                      tip="Salvar"
                      click={() => {
                        formRef.current.submitForm();
                      }}
                      type="submit"
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="card new-layout list-card store d-lg-none">
        <CardBody className="card-body new-layout px-3 py-3">
          <Row className="w-100 mx-0 mb-2">
            <Col xs={10} className="px-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="font-size-12 font-weight-600 text-black d-inline mr-2">
                    Nome:
                  </div>
                  <div className="font-size-12 font-weight-700 text-gray-700">
                    {service.name}
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs={2} className="px-0 d-flex flex-column align-items-center">
              <>
                {!editModeOn ? (
                  <IconButton
                    icon="las la-pen"
                    color="secondary"
                    id={`edit-btn-${service.id}`}
                    tip="Editar"
                    click={() => {
                      setEditModeOn(true);
                    }}
                  />
                ) : (
                  <Row className="w-100 mx-0">
                    <Col
                      xs={6}
                      className="h-100 d-flex flex-column justify-content-center px-0"
                    >
                      <IconButton
                        icon="las la-times"
                        color="danger"
                        id={`cancelar-btn-${service?.id}`}
                        tip="Cancelar Edição"
                        click={() => {
                          setEditModeOn(false);
                        }}
                      />
                    </Col>
                    <Col
                      xs={6}
                      className="h-100 d-flex flex-column justify-content-center px-0"
                    >
                      <IconButton
                        icon="las la-save"
                        color="primary"
                        id={`save-btn-${service?.id}`}
                        tip="Salvar"
                        click={() => {
                          formRef.current.submitForm();
                        }}
                        type="submit"
                      />
                    </Col>
                  </Row>
                )}
              </>
            </Col>
          </Row>
          <Row className="w-100 mx-0 mb-2">
            <Col className="px-0">
              <div className="font-size-12 font-weight-600 text-black d-inline mr-2 mb-1">
                Descrição:
              </div>
              <div className="font-size-12 font-weight-400 text-gray-700">
                {service.description}
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col className="px-0">
              <Form
                onSubmit={data => {
                  setEditModeOn(false);
                  handleSubmit(data, service, index);
                }}
                ref={formRef}
                initialData={service}
              >
                <Row className="w-100 h-100 mx-0 mb-2">
                  <Col align="start" className="px-0">
                    <div className="font-size-12 font-weight-600 text-black d-inline mr-2 mb-1">
                      Chave:
                    </div>
                    {editModeOn ? (
                      <Input name="key" placeholder="Chave do serviço" />
                    ) : (
                      <div className="font-size-12 font-weight-400 text-gray-700 text-truncate">
                        {service.key}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="w-100 h-100 mx-0 mb-2">
                  <Col align="start" className="px-0">
                    <div className="font-size-12 font-weight-600 text-black d-inline mr-2 mb-1">
                      URL:
                    </div>
                    {editModeOn ? (
                      <Input name="uri" placeholder="URL do serviço" />
                    ) : (
                      <div className="font-size-12 font-weight-400 text-gray-700 text-truncate">
                        {service.uri}
                      </div>
                    )}
                  </Col>
                </Row>
                {/* The button below is a workaround that makes the form to be submitted when pressing enter */}
                <button
                  type="submit"
                  className="d-none"
                  aria-label="mock-btn"
                />
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
