import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import {
  // Button,
  // ButtonGroup,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
} from 'reactstrap';
import IconButton from '~/components/IconButton';
// import api from '~/services/api';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';
// import useRoles from '~/hooks/useRoles';
import { formatPrice } from '~/util/format';

export default function ProductsListCard({
  products,
  // cart,
  // setProductsData,
  // setDone,
  // setSeparated,
}) {
  // const location = useLocation();
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  // const { isConsultant } = useRoles();

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const calculateTotal = () => {
    return products.reduce(
      (total, product) =>
        total + product.pivot.value * product.pivot.prod_amount,
      0
    );
  };

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);
  /*
  const confirm = async productId => {
    await api.patch(`/stockist/carts/${cart.id}/products/${productId}/confirm`);
    setProductsData(
      products.map(product =>
        product.id === productId ? { ...product, checked: true } : product
      )
    );
  };
  
  const disconfirm = async productId => {
    setDone(false);
    setSeparated(false);
    await api.patch(
      `/stockist/carts/${cart.id}/products/${productId}/disconfirm`
    );
    setProductsData(
      products.map(product =>
        product.id === productId ? { ...product, checked: false } : product
      )
    );
  };
  */

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row className="w-100 mx-0 justify-content-between">
          <Col
            className="w-100 font-size-16 font-weight-600 d-flex align-items-start px-0"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Row className="w-100 ml-0 ml-lg-2 mr-0 cursor-pointer justify-content-between align-items-center">
              <div className="px-0 mb-0">Produtos</div>
            </Row>
            <IconButton
              icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
              click={() => {
                setExpanded(!expanded);
              }}
              color="gray-700"
            />
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 mt-4 products-table-row header py-2 d-none d-lg-flex">
            <Col xs={3} className="font-size-13 font-weight-600 text-gray-700">
              Produto ou referência
            </Col>
            <Col xs={2} className="px-0 font-weight-600 text-gray-700">
              Tamanho
            </Col>
            <Col xs={2} className="px-0 font-weight-600 text-gray-700">
              Cor
            </Col>
            <Col xs={1} className="px-0 font-weight-600 text-gray-700">
              Qtd
            </Col>
            <Col xs={2} className="font-size-13 font-weight-600 text-gray-700">
              Valor Unit.
            </Col>
            <Col
              lg={2}
              className="font-size-13 font-weight-600 d-none d-lg-flex text-gray-700"
            >
              Valor Total
            </Col>
          </Row>
          {products.map(product => (
            <div key={product.id}>
              <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                <Col
                  xs={3}
                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                >
                  {product.name}
                </Col>
                <Col xs={2} className="px-0">
                  {product.pivot.size || 'Não informado!'}
                </Col>
                <Col xs={2} className="px-0">
                  {product.pivot.color || 'Não informado!'}
                </Col>
                <Col xs={1} className="px-0">
                  {product.pivot.prod_amount}
                </Col>
                <Col
                  xs={2}
                  className="font-size-14 font-weight-400 text-gray-700"
                >
                  {formatPrice(product.pivot.value)}
                </Col>
                <Col
                  lg={2}
                  className="font-size-14 font-weight-400 d-none d-lg-flex text-gray-700"
                >
                  {formatPrice(
                    product.pivot.value * Number(product.pivot.prod_amount)
                  )}
                </Col>
              </Row>
              <Card className="cart-details-product-mobile-card d-lg-none mt-4">
                <Col>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Produto:
                    </div>
                    <div
                      className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {product.name}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Tamanho:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.pivot.size}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Cor:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.pivot.color}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Quantidade:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.pivot.prod_amount}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Unitário:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {formatPrice(product.pivot.value)}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Total:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {formatPrice(
                        product.pivot.value * Number(product.pivot.prod_amount)
                      )}
                    </div>
                  </Row>
                </Col>
              </Card>
            </div>
          ))}
          {/*
          <Row className="w-100 mx-0 products-table-row header py-2 d-none d-lg-flex">
            <Col xs={1} className="font-size-13 font-weight-600 text-gray-700">
              #
            </Col>
            <Col xs={5} className="px-0">
              <Row className="w-100 mx-0">
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Referência
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Produto
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Presente
                </Col>
              </Row>
            </Col>
            <Col xs={1} className="font-size-13 font-weight-600 text-gray-700">
              Qtd
            </Col>
            <Col xs={5} className="px-0">
              <Row className="w-100 mx-0">
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Valor Unit.
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Valor Total
                </Col>
                {!isConsultant && (
                  <Col className="font-size-13 font-weight-600 text-gray-700">
                    Separado
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {products.map(product => (
            <div key={product.id} className="mt-4 mt-lg-0">
              <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                <Col
                  xs={1}
                  className="font-size-14 font-weight-400 text-gray-700"
                >
                  {product.formatted_index}
                </Col>
                <Col xs={5} className="px-0 ">
                  <Row className="w-100 mx-0">
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      #{product.code}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700 text-truncate">
                      {product.name}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      {product.is_for_gift}
                      (Cx. {product.pivot.gift_box_index})
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={1}
                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                >
                  {product.formatted_qtd}
                </Col>
                <Col xs={5} className="px-0 ">
                  <Row className="w-100 mx-0">
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_unit_value}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700 text-truncate">
                      {product.formatted_total_value}
                    </Col>
                      <Col className="font-size-14 font-weight-400 text-gray-700">
                        {location.pathname.includes('venda') ? (
                          product.separated
                        ) : (
                          <ButtonGroup className="z-index-0" size="sm">
                            <Button
                              disabled={cart?.status >= 3}
                              outline={!product.checked}
                              color="primary"
                              onClick={() => confirm(product.id)}
                            >
                              Sim
                            </Button>
                            <Button
                              disabled={cart?.status >= 3}
                              outline={product.checked}
                              color="primary"
                              onClick={() => disconfirm(product.id)}
                            >
                              Não
                            </Button>
                          </ButtonGroup>
                        )}
                      </Col>
                  </Row>
                </Col>
              </Row>
              <Card className="cart-details-product-mobile-card d-lg-none">
                <Col>
                  <Row className="font-size-12 font-weight-600 text-gray-700 mr-2">
                    #{product.formatted_index}
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Referência:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.code}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Produto:
                    </div>
                    <div
                      className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {product.name}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Presente:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.pivot.gift ? 'Sim' : 'Não'}
                      (Cx. {product.pivot.gift_box_index})
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Qtd.:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_qtd}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Unit.:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_unit_value}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Total:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_total_value}
                    </div>
                  </Row>
                  <Row
                    className={`d-flex flex-row align-items-baseline ${
                      !location.pathname.includes('venda')
                        ? 'justify-content-between'
                        : ''
                    }`}
                  >
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Separado:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {location.pathname.includes('venda') ? (
                        product.separated
                      ) : (
                        <ButtonGroup className="z-index-0" size="sm">
                          <Button
                            disabled={cart?.status >= 3}
                            outline={!product.checked}
                            color="primary"
                            onClick={() => confirm(product.id)}
                          >
                            Sim
                          </Button>
                          <Button
                            disabled={cart?.status >= 3}
                            outline={product.checked}
                            color="primary"
                            onClick={() => disconfirm(product.id)}
                          >
                            Não
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </Row>
                </Col>
              </Card>
            </div>
          ))}
          */}
          <Row className="w-100 mx-0 my-4">
            <Col
              align="end"
              className="font-size-14 font-weight-400 text-gray-700"
            >
              <span className="font-size-14">Valor total dos produtos:</span>{' '}
              <span className="font-size-14 font-weight-600">
                {formatPrice(calculateTotal())}
              </span>
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
