import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '~/services/api';
import PageHeader from '~/components/Common/PageHeader';
import Loading from '~/components/Loading';
import { getFullAddress } from '~/util/newLayoutHelpers';

const SubscriberProfile = () => {
  const history = useHistory();
  const { subscriber_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [subscriber, setSubscriber] = useState(null);

  useEffect(() => {
    const loadSubscriber = async () => {
      try {
        const { data } = await api.get(`/master/subscribers/${subscriber_id}`);

        document.title = `${data.subscriber.name} | Viddy`;

        const formattedSubscriber = {
          ...data.subscriber,
          full_address: getFullAddress(data.subscriber.address),
        };

        setSubscriber(formattedSubscriber);
        setLoading(false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro inesperado.');
        }
      }
    };
    loadSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriber_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="DETALHES DO ASSINANTE"
              backTo="/assinantes"
              responsivePosition="start"
              desktopPosition="center"
            />

            <Card className="card new-layout">
              <CardBody className="card-body new-layout">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Dados do Assinante
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0">
                    <Label className="mb-0">Razão Social</Label>
                    <div className="new-layout-page-value">
                      {subscriber.name}
                    </div>
                  </Col>
                  <Col className="px-0">
                    <Label className="mb-0">CNPJ</Label>
                    <div className="new-layout-page-value">
                      {subscriber.cnpj || 'Não Informado'}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0">
                    <Label className="mb-0">E-mail</Label>
                    <div className="new-layout-page-value">
                      {subscriber.email}
                    </div>
                  </Col>
                  <Col className="px-0">
                    <Label className="mb-0">Telefone</Label>
                    <div className="new-layout-page-value">
                      {subscriber.telephone || 'Não Informado'}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0">
                    <Label className="mb-0">Nome do Responsável</Label>
                    <div className="new-layout-page-value">
                      {subscriber.responsible_name}
                    </div>
                  </Col>
                  {subscriber.address && (
                    <Col className="px-0">
                      <Label className="mb-0">Endereço</Label>
                      <div className="new-layout-page-value">
                        {subscriber.full_address}
                      </div>
                    </Col>
                  )}
                </Row>
                {/* <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Configurações do banco
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col xs={6} className="px-0 pr-lg-2">
                    <Label className="mb-0">Driver</Label>
                    <div className="new-layout-page-value">
                      {subscriber.database_settings.driver}
                    </div>
                  </Col>
                  <Col xs={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Host</Label>
                    <div className="new-layout-page-value">
                      {subscriber.database_settings.host}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0  mb-4">
                  <Col xs={6} className="px-0 pr-lg-2">
                    <Label className="mb-0">Porta</Label>
                    <div className="new-layout-page-value">
                      {subscriber.database_settings.port}
                    </div>
                  </Col>
                  <Col xs={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Database</Label>
                    <div className="new-layout-page-value">
                      {subscriber.database_settings.database}
                    </div>
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
            <Row className="w-100 mx-0 my-4 ">
              <Col align="end" className="px-0">
                <Button
                  className="new-layout-btn main"
                  color="primary"
                  size="lg"
                  onClick={() => history.push(`${subscriber_id}/editar`)}
                >
                  Editar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default SubscriberProfile;
