import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Card, CardBody, Col, Row, Container, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import cardLineBlue from '~/assets/icons/pinned-blue.svg';
import SearchBox from '~/components/SearchBox';
import { resetSearch, setSearch } from '~/store/modules/search/actions';
import history from '~/services/history';
import CustomerCard from './CustomerCard';

const useNotificationPersistent = profileId => {
  const [shouldHideNotification, setShouldHideNotification] = useState(
    localStorage.getItem('shouldHideNotification')
      ? JSON.parse(localStorage.getItem('shouldHideNotification'))
      : {}
  );

  useEffect(() => {
    localStorage.setItem(
      'shouldHideNotification',
      JSON.stringify(shouldHideNotification)
    );
  }, [shouldHideNotification]);

  const updateShouldHideNotification = value =>
    setShouldHideNotification(state => ({
      ...state,
      [profileId]: value,
    }));

  return [
    shouldHideNotification ? shouldHideNotification[profileId] : false,
    updateShouldHideNotification,
  ];
};

function CustomersWallet() {
  const dispatch = useDispatch();
  const profile = useSelector(({ user }) => user.profile);
  const [shouldHideNotification, setShouldHideNotification] =
    useNotificationPersistent(profile.id);

  const [customersQuantities, setCustomersQuantities] = useState({});

  const [activeTab, setActiveTab] = useState(null);

  const [customers, setCustomers] = useState({});

  const { state } = useLocation();
  const [loading, setLoading] = useState(true);

  const searchQuery = useSelector(({ search }) => search.query);
  const [pagination, setPagination] = useState(
    { current_page: state?.page } || null
  );

  useEffect(() => {
    setPagination(oldState => ({ ...oldState, current_page: 1 }));
  }, [activeTab]);

  const params = useMemo(
    () => ({
      page: pagination?.current_page > 1 ? pagination?.current_page : 1,
      query: searchQuery || null,
    }),
    [pagination, searchQuery]
  );

  const getCustomersUrl = useCallback(
    tab => {
      switch (tab) {
        case 'newCustomers':
          return `/crm/customers/find-by-user/?userId=${profile.id}&isFixed=false&isRead=false`;
        case 'fixedCustomers':
          return `/crm/customers/find-by-user/?userId=${profile.id}&isFixed=true`;
        case 'allCustomers':
        default:
          return `/crm/customers/find-by-user/?userId=${profile.id}&isFixed=false&isRead=true`;
      }
    },
    [profile.id]
  );

  const loadCustomers = useCallback(
    (data, tab) => {
      if (!data) return;
      setCustomers(oldState => ({
        ...oldState,
        [tab]: data.clients,
      }));
      setPagination(data.meta);
      setCustomersQuantities(oldState => ({
        ...oldState,
        [tab]: data.meta.total,
      }));
    },
    [setCustomersQuantities]
  );

  const fetchCustomers = useCallback(
    async tab => {
      const { data } = await api.get(getCustomersUrl(tab));
      loadCustomers(data, tab);
    },
    [loadCustomers, getCustomersUrl]
  );

  useEffect(() => {
    setLoading(true);
    if (!activeTab) {
      Promise.all(['fixedCustomers', 'allCustomers'].map(fetchCustomers));
    } else {
      fetchCustomers(activeTab).then(() => setLoading(false));
    }
  }, [fetchCustomers, activeTab]);

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return (
    <Container className="container new-layout wide mx-0">
      {shouldHideNotification ? null : (
        <Card
          className="card new-layout mt-3"
          style={{
            transition: '0.5s ease',
            color: '#2544B1',
            backgroundColor: '#CDD8FF',
            borderRadius: '6px',
          }}
        >
          <CardBody className="card-body new-layout d-flex px-3 py-3">
            <Col className="py-2 font-size-16">
              Tocando no ícone{' '}
              <span>
                {' '}
                <img src={cardLineBlue} alt="ícone de pino azul" />{' '}
              </span>
              você fixa o cliente na aba destaque e tocando novamente ele
              retorna para aba lista.
            </Col>
            <button
              type="button"
              style={{
                fontSize: '1.5rem',
                color: 'rgb(37, 68, 177)',
                background: 'none',
                border: 'none',
                height: '2.5rem',
                padding: '0',
              }}
              onClick={() => {
                localStorage.setItem('shouldHideNotification', true);
                setShouldHideNotification(true);
              }}
            >
              ×
            </button>
          </CardBody>
        </Card>
      )}
      <Row className="my-3 mx-0">
        <Col
          className="d-flex justify-content-center justify-content-md-start mb-3 px-0"
          xs={12}
          md={5}
          lg={8}
          xl={9}
        >
          <Badge
            style={{
              fontSize: '1rem',
              color:
                !activeTab || activeTab === 'newCustomers'
                  ? '#2544B1'
                  : '#56575B',
              backgroundColor:
                !activeTab || activeTab === 'newCustomers'
                  ? '#CDD8FF'
                  : '#CDD8FF00',
              borderRadius: '6px',
              padding: '0.7rem',
              cursor: 'pointer',
            }}
            className="mr-1"
            onClick={() => {
              dispatch(resetSearch());
              setActiveTab('newCustomers');
            }}
          >
            Novos ({customersQuantities.newCustomers})
          </Badge>
          <Badge
            style={{
              fontSize: '1rem',
              color: activeTab === 'allCustomers' ? '#2544B1' : '#56575B',
              backgroundColor:
                activeTab === 'allCustomers' ? '#CDD8FF' : '#CDD8FF00',
              borderRadius: '6px',
              padding: '0.7rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(resetSearch());
              setActiveTab('allCustomers');
            }}
          >
            Lista ({customersQuantities.allCustomers})
          </Badge>
          <Badge
            style={{
              fontSize: '1rem',
              color: activeTab === 'fixedCustomers' ? '#2544B1' : '#56575B',
              backgroundColor:
                activeTab === 'fixedCustomers' ? '#CDD8FF' : '#CDD8FF00',
              borderRadius: '6px',
              padding: '0.7rem',
              cursor: 'pointer',
            }}
            className="ml-1"
            onClick={() => {
              dispatch(resetSearch());
              setActiveTab('fixedCustomers');
            }}
          >
            Destaque ({customersQuantities.fixedCustomers})
          </Badge>
        </Col>
        <Col className="px-2 mx-auto d-lg-none" xs={12} sm={8} md={3}>
          <SearchBox
            paginationMeta={pagination}
            params={params}
            query={searchQuery}
            setQuery={q => dispatch(setSearch({ query: q }))}
            url={getCustomersUrl(activeTab || 'newCustomers')}
            setData={data => loadCustomers(data, activeTab || 'newCustomers')}
            setLoading={setLoading}
          />
        </Col>
        <Col className="px-2 mx-auto" xs={12} sm={5} md={4} xl={3}>
          <Button
            color="primary"
            className="new-layout-btn main mt-3 mt-md-0 w-100"
            size="lg"
            type="submit"
            onClick={() => history.push('/carteira-de-clientes/novo-cliente')}
          >
            Adicionar Novo Contato
          </Button>
        </Col>
      </Row>

      <hr />

      <Row className="px-2 h-100">
        {loading ? (
          <Row className="w-100 justify-content-center">
            <Loading />
          </Row>
        ) : (
          <Col>
            <Row className="customer-card-list">
              {customers[activeTab || 'newCustomers']?.map(customer => (
                <Col sm={6} md={4} lg={3} key={customer.id}>
                  <CustomerCard
                    customer={customer}
                    loadCustomers={() => {
                      setLoading(true);
                      dispatch(resetSearch());
                      Promise.all(
                        ['fixedCustomers', 'allCustomers', 'newCustomers'].map(
                          fetchCustomers
                        )
                      ).then(() => setLoading(false));
                      setActiveTab(activeTab || 'newCustomers');
                    }}
                    style={{
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                      borderRadius: '8px',
                    }}
                    readed={activeTab === 'allCustomers'}
                    fixed={activeTab === 'fixedCustomers'}
                  />
                </Col>
              ))}
            </Row>
            <Row className="justify-content-end mb-4 mr-0">
              <Pagination
                paginationMeta={pagination}
                url={getCustomersUrl(activeTab || 'newCustomers')}
                setData={data =>
                  loadCustomers(data, activeTab || 'newCustomers')
                }
                setLoading={setLoading}
                params={params}
              />
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default CustomersWallet;
