import { Row, Col } from 'reactstrap';
import checkedBlue from '~/assets/icons/checked-blue.svg';
import DoubleCheckedBlue from '~/assets/icons/double-checked-blue.svg';

function CustomerAcceptedAdvice({ acceptNewsletter, acceptAudioWhatsapp }) {
  if (acceptAudioWhatsapp && acceptNewsletter)
    return (
      <Row>
        <Col className="font-size-16 text-primary mb-4">
          <img src={DoubleCheckedBlue} alt="" className="mr-1" />
          Aceitou participar de grupos e listas e receber áudios do consultor.
        </Col>
      </Row>
    );
  if (acceptAudioWhatsapp)
    return (
      <Row>
        <Col className="font-size-16 text-primary mb-4">
          <img src={checkedBlue} alt="" className="mr-1" />
          Aceitou receber áudios do consultor
        </Col>
      </Row>
    );
  if (acceptNewsletter)
    return (
      <Row>
        <Col className="font-size-16 text-primary mb-4">
          <img src={checkedBlue} alt="" className="mr-1" />
          Aceitou participar de grupos e listas
        </Col>
      </Row>
    );
  return null;
}

export default CustomerAcceptedAdvice;
