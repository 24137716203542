import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Container, Button, CustomInput } from 'reactstrap';

import history from '~/services/history';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DeleteModal from '~/components/Modals/DeleteModal';
import Loading from '~/components/Loading';
import BatchActions from '~/components/BatchActions';
import useGetData from '~/hooks/useGetData';
import useBatchSelect from '~/hooks/useBatchSelect';
import ProductInfoCard from './ProductInfoCard';
import ImportProducts from './ImportProducts';

const Products = () => {
  const {
    items: products,
    pagination,
    loading,
    setLoading,
    setData,
  } = useGetData();

  const {
    allSelected: allProducts,
    setAllSelected: setAllProducts,
    selectedItems: selectedProducts,
    setSelectedItems: setSelectedProducts,
    handleSelect,
  } = useBatchSelect(products);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isStoreManager = useMemo(
    () => roles.find(r => r.slug === 'store-manager'),
    [roles]
  );

  const isAdmin = roles.find(r => r.slug === 'administrator');

  const canCreateProduct = useMemo(() => {
    if (!!isStoreManager && !isAdmin) {
      return false;
    }

    return true;
  }, [isAdmin, isStoreManager]);

  const [searchQuery, setSearchQuery] = useState(null);
  const [productToBeDeleted, setProductToBeDeleted] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [selectModeOn, setSelectModeOn] = useState(false);

  const getURL = useMemo(() => {
    return '/products';
  }, []);

  const deleteURL = useMemo(() => {
    return `/administrator/products/${productToBeDeleted?.id}`;
  }, [productToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const isAllowedToBatch = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    return false;
  }, [isAdmin]);

  const batchDeleteURL = useMemo(() => {
    return `/administrator/products/`;
  }, []);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="LISTA DE PRODUTOS"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-2 mb-lg-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            {isAdmin && <ImportProducts />}
            {canCreateProduct && (
              <Button
                className="new-layout-btn main btn ml-lg-2 mt-2 mt-lg-0 btn-primary waves-effect waves-light"
                onClick={() => history.push('/produtos/adicionar')}
                color="primary"
              >
                <i className="fas fa-tag font-size-16 align-middle mr-2" /> Novo
                Produto
              </Button>
            )}
          </Col>
        </Row>
        {isAllowedToBatch && (
          <Row className="w-100 mx-0 mb-4">
            <BatchActions
              getURL={getURL}
              deleteURL={batchDeleteURL}
              selectedItems={{
                products: selectedProducts.map(p => p.id),
              }}
              setData={setData}
              setSelectedItems={setSelectedProducts}
              itemsArray={products}
              params={params}
              keepPage
              pagination={pagination}
              setAllItems={setAllProducts}
              allItems={allProducts}
              tip="Para executar uma ação em lote, selecione, pelo menos, dois produtos."
              id="clients"
              objName="products"
              successMsg="Produtos removidos com sucesso!"
              selectModeOn={selectModeOn}
              setSelectModeOn={setSelectModeOn}
              setLoading={setLoading}
            />
          </Row>
        )}
        {loading ? (
          <Loading />
        ) : (
          <>
            {products?.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                  <Col xs={1} align="center">
                    {isAllowedToBatch && (
                      <CustomInput
                        type="checkbox"
                        id="allProducts"
                        className="text-primary z-index-0"
                        checked={allProducts}
                        defaultValue={allProducts}
                        onChange={() => setAllProducts(!allProducts)}
                      />
                    )}
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Produto</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Referência
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Cód. de Barras
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Preço</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Criação</div>
                  </Col>
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {products?.map(product => {
                  const isSelected = selectedProducts.some(
                    p => p.id === product.id
                  );

                  return (
                    <Row className="w-100 mx-0">
                      {selectModeOn && (
                        <Col
                          xs={1}
                          className="px-0 d-flex flex-column justify-content-center"
                        >
                          <CustomInput
                            type="checkbox"
                            id={`mobile-select-${product.id}`}
                            className="text-primary z-index-0"
                            checked={isSelected}
                            defaultValue={isSelected}
                            onChange={() => handleSelect(product, isSelected)}
                          />
                        </Col>
                      )}
                      <Col className="px-0" xs={selectModeOn ? 11 : 12}>
                        <ProductInfoCard
                          key={product.id}
                          product={product}
                          isSelected={isSelected}
                          onSelect={handleSelect}
                          onDelete={incomingProduct => {
                            setProductToBeDeleted(incomingProduct);
                            setOpenModal(true);
                          }}
                          canManage={isAdmin}
                          isAllowedToBach={isAllowedToBatch}
                        />
                      </Col>
                    </Row>
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum produto encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <DeleteModal
        show={openModal}
        setShow={setOpenModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={products}
        setItemToBeDeleted={setProductToBeDeleted}
        headerText="Deseja mesmo excluir o produto?"
        bodyText="Essa ação irá excluir o produto"
        extraText={`Referência do Produto: #${productToBeDeleted?.code}`}
        itemName={productToBeDeleted?.name}
        successMsg="Produto removido com sucesso!"
      />
    </div>
  );
};

export default Products;
