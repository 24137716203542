export const SUBSCRIBE_USER_REQUEST = '@notifications/SUBSCRIBE_USER_REQUEST';
export const SUBSCRIBE_USER_SUCCESS = '@notifications/SUBSCRIBE_USER_SUCCESS';
export const UNSUBSCRIBE_USER = '@notifications/UNSUBSCRIBE_USER';

export const SET_NOTIFICATIONS_ARRAY = '@notifications/SET_NOTIFICATIONS_ARRAY';
export const SET_NOTIFICATIONS_PAGE_ARRAY =
  '@notifications/SET_NOTIFICATIONS_PAGE_ARRAY';
export const INCREMENT_UNREAD_NOTIFICATIONS_COUNT =
  '@notifications/INCREMENT_UNREAD_NOTIFICATIONS_COUNT';
export const DECREMENT_UNREAD_NOTIFICATIONS_COUNT =
  '@notifications/DECREMENT_UNREAD_NOTIFICATIONS_COUNT';
export const SET_COUNTER_TO_ZERO = '@notifications/SET_COUNTER_TO_ZERO';
export const MARK_ALL_NOTIFICATIONS_AS_READ =
  '@notifications/MARK_ALL_NOTIFICATIONS_AS_READ';
export const LOAD_OLDER_NOTIFICATIONS =
  '@notifications/LOAD_OLDER_NOTIFICATIONS';
export const HANDLE_NOTIFICATION = '@notifications/HANDLE_NOTIFICATION';
export const ADD_NOTIFICATION = '@notifications/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = '@notifications/REMOVE_NOTIFICATION';
export const SET_LOADING_NOTIFICATIONS =
  '@notifications/SET_LOADING_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS_REQUEST =
  '@notifications/LOAD_NOTIFICATIONS_REQUEST';
export const LOAD_NOTIFICATIONS_SUCCESS =
  '@notifications/LOAD_NOTIFICATIONS_SUCCESS';

export const NEW_MESSAGE_RECEIVED = '@notifications/NEW_MESSAGE_RECEIVED';
export const HANDLE_UNREAD_MSG = '@notifications/HANDLE_UNREAD_MSG';
export const ADD_UNREAD_MSG = '@notifications/ADD_UNREAD_MSG';
export const REMOVE_UNREAD_MSG = '@notifications/REMOVE_UNREAD_MSG';
export const SET_LOADING_UNREAD_MSGS = '@notifications/SET_LOADING_UNREAD_MSGS';
export const LOAD_UNREAD_MSGS_REQUEST =
  '@notifications/LOAD_UNREAD_MSGS_REQUEST';
export const LOAD_UNREAD_MSGS_SUCCESS =
  '@notifications/LOAD_UNREAD_MSGS_SUCCESS';
