import { useCallback, useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardBody, Card, Col, Row, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import PageHeader from '~/components/Common/PageHeader';
import whatsappIcon from '~/assets/icons/whatsapp-rounded.svg';
import instagramIcon from '~/assets/icons/instagram-rounded.svg';
import facebookIcon from '~/assets/icons/facebook-rounded.svg';
import history from '~/services/history';
import api from '~/services/api';
import { formatWhatsappPhoneNumber } from '~/util/format';
import CustomerAcceptedAdvice from './CustomerAcceptedAdvice';

function CustomerDetails() {
  const profile_id = useSelector(({ user }) => user.profile.id);

  const { customer_id } = useParams();

  const [customer, setCustomer] = useState({});

  const loadCustomer = useCallback(async () => {
    const { data } = await api.get(`/crm/customers/${customer_id}`);

    setCustomer(data.data);
  }, [customer_id]);

  useEffect(loadCustomer, [loadCustomer]);

  const userData = useMemo(
    () => customer?.users?.find(({ id }) => id === profile_id),
    [profile_id, customer.users]
  );

  return (
    <div className="page-content px-4">
      <PageHeader
        responsivePosition="start"
        desktopPosition="start"
        customArrowClassName="w-100 mx-0 my-3 my-lg-4"
        backTo={() => history.push('/carteira-de-clientes')}
      />

      <Row className="mb-0">
        <Col align="center">
          <h2 className="mb-0">{customer.name}</h2>
          {customer.birth_date && <h4>Anivesário: {customer.birth_date}</h4>}
        </Col>
      </Row>

      <Row
        style={{ fontSize: '2rem', gap: '2rem' }}
        className="d-flex justify-content-center my-4"
      >
        {customer.whatsapp && (
          <a
            href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
              customer.whatsapp
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsappIcon} alt="" style={{ width: '3rem' }} />
          </a>
        )}
        {customer.instagram && (
          <a
            href={customer.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="" style={{ width: '3rem' }} />
          </a>
        )}
        {customer.facebook && (
          <a href={customer.facebook} target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="" style={{ width: '3rem' }} />
          </a>
        )}
      </Row>

      <Card className="card new-layout mx-12 rounded">
        <CardBody className="card-body new-layout">
          <Row>
            <Col>
              <p className="m-0">
                <b>Etiqueta:</b> <br />
                {userData?.tag}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="card new-layout mx-12 rounded">
        <CardBody className="card-body new-layout">
          <Row>
            <Col>
              <p className="m-0">
                <b>Anotações:</b> <br />
                {userData?.seller_notes}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <CustomerAcceptedAdvice
        acceptNewsletter={customer.accept_newsletter}
        acceptAudioWhatsapp={customer.accept_audio_whatsapp}
      />

      <Row>
        <Col xs={12} md={6} className="d-flex justify-content-end">
          <Button
            color="primary"
            className="new-layout-btn main mt-2 w-lg-75"
            size="lg"
            type="submit"
            onClick={() => {
              history.push(`/registrar?clientId=${customer.id}`);
            }}
          >
            Adicionar Venda
          </Button>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <Button
            color="light"
            className="new-layout-btn main mt-2 w-lg-75"
            size="lg"
            type="submit"
            style={{
              color: 'var(--primary)',
              border: '1px solid var(--primary)',
              backgroundColor: 'transparent',
            }}
            onClick={() => {
              history.push(`/carteira-de-clientes/${customer.id}/editar`);
            }}
          >
            Editar Cliente
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerDetails;
