import React, { useRef, useEffect, useState } from 'react';
import MaskedInput from 'react-number-format';
import { useField } from '@unform/core';

const InputCurrency = ({ name, classRest, currency, cbChange, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name);

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return value;
      },
    });
  }, [fieldName, registerField, value]);

  const percentageProps = {
    thousandSeparator: '.',
    decimalSeparator: ',',
    allowDecimal: true,
    decimalScale: 2,
    suffix: ' %',
  };

  const currencyMask = {
    prefix: 'R$ ',
    thousandSeparator: '.',
    allowDecimal: true,
    decimalSeparator: ',',
    decimalScale: 2,
    thousandsGroupStyle: 'thousand',
    allowNegative: false,
    allowLeadingZeroes: false,
    fixedDecimalScale: true,
  };

  return (
    <>
      {currency ? (
        <MaskedInput
          value={value}
          onValueChange={values => {
            const { floatValue } = values;
            setValue(floatValue);
            if (cbChange) {
              cbChange(floatValue);
            }
          }}
          id={fieldName}
          getInputRef={inputRef}
          inputMode="numeric"
          className={`form-control ${
            error && 'border-danger'
          } ${classRest} input-color`}
          onFocus={clearError}
          {...currencyMask}
          {...rest}
        />
      ) : (
        <MaskedInput
          value={value}
          onValueChange={values => {
            const { floatValue } = values;
            setValue(floatValue);
            if (cbChange) {
              cbChange(floatValue);
            }
          }}
          id={fieldName}
          getInputRef={inputRef}
          className={`form-control ${
            error && 'border-danger'
          } ${classRest} input-color`}
          onFocus={clearError}
          {...percentageProps}
          {...rest}
        />
      )}
      {error && <span className={error && 'text-danger'}>{error}</span>}
    </>
  );
};

export default InputCurrency;
