import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import jsonPackage from '../../../package.json';

const Footer = () => {
  const { version } = jsonPackage;
  const showSidebar = useSelector(({ layout }) => layout.showSidebar);
  // const whatsapp_number = useSelector(
  //   ({ user }) => user.profile?.whatsapp_number
  // );

  const width = window.innerWidth;

  return (
    <>
      <footer className={`footer ${showSidebar ? 'sidebar-open' : ''}`}>
        <div className="w-100 mx-0 mb-2">
          <Row
            className={`w-100 ${width < 768 ? 'text-center' : ''} ${
              width <= 414 ? 'my-2' : ''
            }
          `}
          >
            {/* <Col
              md={6}
              className={`mobile-footer ${
                width <= 767 ? 'my-2' : 'text-right'
              }`}
            >
              <a
                href={`https://api.whatsapp.com/send?phone=55${whatsapp_number}&text=Olá! Estou com dúvida em relação ao Viddy, você pode me ajudar?`}
                target="_blank"
                className={`text-decoration-none text-reset ${
                  !whatsapp_number && 'd-none'
                }`}
                rel="noopener noreferrer"
                id="receiptLink"
              >
                <span className="font-weight-bold">Precisa de ajuda? </span> 
                Entre em contato com nosso suporte via WhatsApp.
              </a>
            </Col> */}
            <Col
              className={`
                mobile-footer w-100
                pl-md-3 pl-xl-4 ml-xl-2
                ${width <= 767 ? 'pb-3' : ''}
              `}
            >
              {new Date().getFullYear()} © Viddy - B{version}
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default Footer;
