const focusErrorInputs = () => {
  const form = document.querySelector('form');

  const inputs = form.querySelectorAll('input');
  let focused = false;
  // eslint-disable-next-line no-unused-expressions
  inputs.length &&
    inputs.forEach(input => {
      if (!focused && input.value === '') {
        focused = true;
        input.focus();
      }
    });
};

export default focusErrorInputs;
