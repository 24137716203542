/* eslint-disable consistent-return */
import produce from 'immer';

import { parseISO } from 'date-fns';
import { formatPrice } from '~/util/format';
import {
  SAVE_INFORMATIONS,
  CREATE_CART_SUCCESS,
  CREATE_CLIENT_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  NEXT_STEP,
  SET_TAB,
  FINISH_CART_CIELO_SUCCESS,
  FINISH_CART_PAGARME_SUCCESS,
  FINISH_CART_MERCADOPAGO_SUCCESS,
  FINISH_CART_REDE_SUCCESS,
  FINISH_CART_PAGSEGURO_SUCCESS,
  FINISH_CART_LINK_SUCCESS,
  FINISH_CART_DELIVERY_SUCCESS,
  FINISH_CART_PIX_SUCCESS,
  UPDATE_DELIVERY_SUCCESS,
  UPDATE_DELIVERY_FEE,
  RESET_CART,
  SAVE_CLIENT,
  SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  UPDATE_CART_INFORMATIONS_SUCCESS,
  LOAD_CART_SUCCESS,
  GENERATE_ORDER_NUMBER_SUCCESS,
  UPDATE_SCHEDULED_TO,
  UPDATE_IS_SCHEDULED,
  SHOW_REGIONS_MODAL,
  SET_SHOW_CONFIRM_REGION_MODAL,
  SET_LOADING_REGIONS,
  SET_TEMPORARY_STORE,
  SET_STORE_REGIONS,
  SET_CHOSEN_REGION,
  SET_STOCK,
  UPLOAD_TAX_COUPONS,
  MODIFY_STOCK_ADDRESS,
  MODIFY_STOCK_FLEET,
  SET_DELIVERY_FLEET_SUCCESS,
  SET_LOADING,
  SAVE_CUSTOM_DELIVERY_ADDRESS,
  SET_DISCOUNT,
  SET_LOADING_CHATS,
  SAVE_CHATS_SEARCH,
  SET_IGNORE_CLIENT_ADDRESS,
  SET_SHAREABLE_CART,
  CREATE_SHAREABLE_CART,
  SET_SALEMAN_STORE,
  SET_SHAREABLE_CART_TOKEN,
  SHOW_SHAREABLE_CART_MODAL,
  SAVE_SHAREABLE_CART_SUCCESS,
  SET_PRODUCTS,
  PATCH_CART_SUCCESS,
  SAVE_CART_STORE,
  SET_REGION_SUCCESS,
  SAVE_CLIENT_ADDRESS_SUCCESS,
  UPDATE_CLIENT_ADDRESS_SUCCESS,
  UPDATE_FREIGHT_VEHICLE,
  UPDATE_DELIVERY,
  UPDATE_VEHICLE_FOR_DELIVERY,
} from './actionsTypes';

const initialState = {
  cart: null,
  products: [],
  stores: [],
  files: [],
  address: null,
  store_id: null,
  store: null,
  order_number: null,
  observation: null,
  referral_code: null,
  percentage_discount: false,
  client: null,
  discount: 0,
  discountAmount: 0,
  total: 'R$ 00,00',
  totalAcumulator: 0,
  useUserAddress: true,
  deliveryFee: 0,
  delivery: true,
  step: 1,
  orderTab: 1,
  done: false,
  freeFreight: false,
  customFreight: null,
  link_cielo: undefined,
  link_pagarme: undefined,
  link_mercadopago: undefined,
  link_rede: undefined,
  link_pagseguro: undefined,
  link: undefined,
  isSalemanStore: false,
  is_for_delivery: false,
  loading: true,
  error: false,
  delivery_scheduled_to: null,
  is_scheduled: false,
  showRegionsModal: false,
  showConfirmRegionModal: false,
  loadingRegions: false,
  selectedStore: null,
  storeRegions: null,
  chosenRegion: null,
  stock: null,
  deliveryFleet: null,
  loadingChats: false,
  qrcode: null,
  shared: false,
  showShareableCartModal: false,
  shareableCartToken: null,
  deliveryObservation: null,
  ignoreClientAddress: false,
  receiptNumber: null,
  freightVehicle: null,
  vehicle: 'M',
  is_suitcase_delivery: null,
};

export default function cartReducer(state = initialState, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case RESET_CART: {
        return initialState;
      }
      case PATCH_CART_SUCCESS: {
        const { data } = payload;
        draft.cart = {
          ...draft.cart,
          ...data,
        };
        draft.step = 3;
        draft.orderTab = 3;
        break;
      }
      case GENERATE_ORDER_NUMBER_SUCCESS: {
        const { order_number } = payload;
        draft.order_number = order_number;
        break;
      }
      case SET_LOADING: {
        draft.loading = payload;
        break;
      }

      case SAVE_CART_STORE: {
        draft.store = payload;
        break;
      }

      case SET_SALEMAN_STORE: {
        draft.isSalemanStore = payload;
        break;
      }

      case SET_SHAREABLE_CART_TOKEN: {
        const { link, qrcode } = payload;
        if (link) draft.shareableCartToken = link;
        if (qrcode) draft.qrcode = qrcode;
        break;
      }
      case SAVE_CHATS_SEARCH: {
        const { chats, pagination } = payload;
        draft.chats = chats;
        draft.chatsPagination = pagination;
        break;
      }
      case SAVE_SHAREABLE_CART_SUCCESS: {
        const { cart } = payload;
        draft.cart = cart;
        draft.store_id = cart.store.id;
        draft.selectedStore = {
          value: cart.store.id,
          label: cart.store.fantasy_name,
        };
        draft.order_number = cart.order_number;
        draft.observation = cart.observation;
        draft.referral_code = cart.referral_code?.code;
        draft.useUserAddress = !cart.use_custom_delivery_address;

        draft.link_cielo = cart.payment_process?.url;
        draft.link_pagarme = cart.payment_process?.url;
        draft.link_mercadopago = cart.payment_process?.url;
        draft.link_rede = cart.payment_process?.url;
        draft.link_pagseguro = cart.payment_process?.url;
        draft.percentage_discount = cart.percentage_discount;

        draft.freeFreight = cart.free_freight;
        draft.deliveryFleet = cart.delivery_method;
        draft.customFreight = cart.has_custom_freight;
        draft.delivery = cart.is_for_delivery;
        draft.delivery_scheduled_to = cart.delivery_scheduled_to
          ? parseISO(cart.delivery_scheduled_to)
          : null;
        draft.is_scheduled = !!cart.delivery_scheduled_to;

        if (cart.use_custom_delivery_address)
          draft.address = cart.delivery_address;

        draft.discount = cart.discount;

        draft.discountAmount = cart.percentage_discount
          ? (cart.discount / cart.amount) * 100
          : cart.discount;

        draft.receiptNumber = cart.nfce?.number;
        break;
      }
      case SET_LOADING_CHATS: {
        draft.loadingChats = payload;
        break;
      }

      case SET_SHAREABLE_CART: {
        draft.shared = payload;
        if (payload && draft.ignoreClientAddress) {
          draft.ignoreClientAddress = false;
        }
        break;
      }

      case SET_IGNORE_CLIENT_ADDRESS: {
        draft.ignoreClientAddress = payload;
        if (payload && draft.shared) {
          draft.shared = false;
        }
        break;
      }

      case SHOW_SHAREABLE_CART_MODAL: {
        draft.showShareableCartModal = payload;
        break;
      }
      case SAVE_CUSTOM_DELIVERY_ADDRESS: {
        draft.cart = {
          ...draft.cart,
          delivery_address: payload.address,
        };
        // draft.loading = payload;
        break;
      }

      case LOAD_CART_SUCCESS: {
        const { cart, products, client } = payload;

        /* Cart information */
        draft.cart = cart;
        draft.store_id = cart.store.id;
        draft.selectedStore = {
          value: cart.store.id,
          label: cart.store.fantasy_name,
        };
        draft.order_number = cart.order_number;
        draft.observation = cart.observation;
        draft.referral_code = cart.referral_code?.code;
        draft.useUserAddress = !cart.use_custom_delivery_address;

        draft.link_cielo = cart.payment_process?.url;
        draft.link_pagarme = cart.payment_process?.url;
        draft.link_mercadopago = cart.payment_process?.url;
        draft.link_rede = cart.payment_process?.url;
        draft.link_pagseguro = cart.payment_process?.url;
        draft.percentage_discount = cart.percentage_discount;

        draft.vehicle = cart.delivery_vehicle;
        draft.is_for_delivery = cart.is_for_delivery;
        draft.receiptNumber = cart.nfce?.number || null;
        draft.freeFreight = cart.free_freight;
        draft.deliveryFleet = cart.delivery_method;
        draft.customFreight = cart.has_custom_freight;
        draft.delivery = cart.is_for_delivery;
        draft.delivery_scheduled_to = cart.delivery_scheduled_to
          ? parseISO(cart.delivery_scheduled_to)
          : null;
        draft.is_scheduled = !!cart.delivery_scheduled_to;

        if (cart.use_custom_delivery_address)
          draft.address = cart.delivery_address;

        draft.totalAcumulator = cart.amount;
        draft.discount = cart.discount;

        draft.discountAmount = cart.percentage_discount
          ? (cart.discount / cart.amount) * 100
          : cart.discount;

        draft.total = formatPrice(cart.amount);

        /* Products list */
        draft.products = products;

        /* Client informations */
        draft.client = client;

        draft.shared = cart.is_shareable;
        draft.shareableCartToken = cart.shareable_token;

        draft.loading = false;
        draft.receiptNumber = cart.nfce?.number;

        break;
      }

      case CREATE_CART_SUCCESS: {
        const { cart, discount, totalPercentage } = payload;
        draft.discount = cart.percentage_discount ? totalPercentage : discount;
        draft.discountAmount = discount;
        draft.observation = cart.observation;
        draft.percentage_discount = cart.percentage_discount;
        draft.totalAcumulator = cart.amount;
        draft.total = formatPrice(cart.amount);
        draft.cart = cart;
        draft.deliveryFee = Number(cart.freight);
        draft.deliveryObservation = cart.delivery_observation;
        draft.receiptNumber = cart.nfce?.number;
        break;
      }

      case CREATE_SHAREABLE_CART: {
        const { order_number, store_id } = payload;

        draft.store_id = store_id;
        draft.order_number = order_number;
        draft.step = 2;
        draft.orderTab = 2;
        break;
      }

      case UPLOAD_TAX_COUPONS: {
        draft.files = payload;
        break;
      }

      case UPDATE_CART_INFORMATIONS_SUCCESS: {
        const {
          cart,
          amount,
          discount,
          percentage_discount,
          observation,
          totalPercentage,
          delivery_observation,
          receipt_number,
        } = payload;
        draft.cart = cart;
        draft.totalAcumulator = amount;
        draft.total = formatPrice(amount);
        draft.discount = percentage_discount ? totalPercentage : discount;
        draft.discountAmount = discount;
        draft.observation = observation;
        draft.percentage_discount = percentage_discount;
        draft.deliveryObservation = delivery_observation;
        draft.receiptNumber = receipt_number;
        break;
      }

      case SAVE_INFORMATIONS: {
        const { store_id, order_number, address, store } = payload;
        draft.store_id = store_id;
        draft.order_number = order_number;
        if (address) draft.address = address;
        if (store !== undefined)
          draft.cart = {
            ...draft.cart,
            store,
          };
        break;
      }
      case SET_DISCOUNT: {
        draft.discount = payload;
        break;
      }

      case SAVE_CLIENT: {
        const { client } = payload;
        draft.client = client;
        break;
      }

      case UPDATE_CLIENT_ADDRESS_SUCCESS: {
        const { address } = payload;
        draft.cart = {
          ...draft.cart,
          delivery_address: address,
        };
        break;
      }

      case SAVE_CLIENT_ADDRESS_SUCCESS: {
        const { address, freight } = payload;
        draft.deliveryFee = freight;
        draft.cart = {
          ...draft.cart,
          delivery_address: address,
        };
        draft.client = {
          ...draft.client,
          address,
        };
        draft.address = address;
        break;
      }

      case CREATE_CLIENT_SUCCESS: {
        const { client } = payload;
        draft.client = client;
        break;
      }

      case NEXT_STEP: {
        draft.step += 1;
        if (draft.step > 4) {
          draft.step = 4;
        }

        draft.orderTab += 1;
        if (draft.orderTab > 4) {
          draft.orderTab = 4;
        }

        break;
      }

      case SET_TAB: {
        const { orderTab } = payload;
        draft.orderTab = orderTab;
        break;
      }

      case REMOVE_PRODUCT_SUCCESS: {
        const { product } = payload;

        const products = draft.products.filter(p => p.code !== product.code);
        const totalValue =
          draft.totalAcumulator - product.value * product.prod_amount;

        draft.products = products;
        draft.total = formatPrice(totalValue);
        draft.totalAcumulator = totalValue;

        break;
      }

      case ADD_PRODUCT_SUCCESS: {
        const { product } = payload;
        const totalValue =
          draft.totalAcumulator + product.value * product.prod_amount;
        draft.products = [...draft.products, product];
        draft.total = formatPrice(totalValue);
        draft.totalAcumulator = totalValue;
        break;
      }

      case UPDATE_PRODUCT_SUCCESS: {
        const { product } = payload;

        const productOnDraft = draft.products.find(
          p => p.product_id === product.product_id
        );

        const isAdding = product.prod_amount > productOnDraft.prod_amount;

        const totalValue = isAdding
          ? draft.totalAcumulator + Number(product.value)
          : draft.totalAcumulator - Number(product.value);

        draft.products = draft.products.map(p =>
          p.product_id === product.product_id ? product : p
        );

        draft.total = formatPrice(totalValue);
        draft.totalAcumulator = totalValue;

        break;
      }
      case SET_PRODUCTS: {
        draft.products = payload;
        break;
      }

      case FINISH_CART_PIX_SUCCESS: {
        const { qrcode } = payload;
        if (qrcode) draft.qrcode = qrcode;
        break;
      }

      case FINISH_CART_LINK_SUCCESS: {
        const { link, qrcode } = payload;
        if (link) draft.link = link;
        if (qrcode) draft.qrcode = qrcode;
        // draft.done = true;
        break;
      }

      case FINISH_CART_CIELO_SUCCESS: {
        const { link_cielo } = payload;
        if (link_cielo) draft.link_cielo = link_cielo;
        // draft.done = true;
        break;
      }

      case FINISH_CART_PAGARME_SUCCESS: {
        const { link_pagarme } = payload;
        if (link_pagarme) draft.link_pagarme = link_pagarme;
        // draft.done = true;
        break;
      }

      case FINISH_CART_MERCADOPAGO_SUCCESS: {
        const { link_mercadopago } = payload;
        if (link_mercadopago) draft.link_mercadopago = link_mercadopago;
        // draft.done = true;
        break;
      }

      case FINISH_CART_REDE_SUCCESS: {
        const { link_rede } = payload;
        if (link_rede) draft.link_rede = link_rede;
        // draft.done = true;
        break;
      }

      case FINISH_CART_PAGSEGURO_SUCCESS: {
        const { link_pagseguro } = payload;
        if (link_pagseguro) draft.link_rede = link_pagseguro;
        // draft.done = true;
        break;
      }

      case FINISH_CART_DELIVERY_SUCCESS: {
        draft.done = true;
        break;
      }

      case UPDATE_DELIVERY_SUCCESS: {
        const { productDelivery, useCustomDeliveryAddress, freight } = payload;
        draft.delivery = productDelivery;
        draft.useUserAddress = !useCustomDeliveryAddress;
        draft.cart = {
          ...draft.cart,
          is_for_delivery: productDelivery,
        };
        draft.deliveryFee = freight;
        break;
      }

      case UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS: {
        const { useUserAddress } = payload;
        draft.useUserAddress = useUserAddress;
        break;
      }

      case SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS: {
        const { address } = payload;
        draft.cart = {
          ...draft.cart,
          delivery_address: address,
        };
        draft.address = address;
        break;
      }

      case UPDATE_DELIVERY: {
        const { tag, value, is_suitcase_delivery } = payload;
        draft.is_for_delivery = value;
        draft.deliveryFleet = tag;
        draft.is_suitcase_delivery =
          is_suitcase_delivery ?? draft.is_suitcase_delivery;
        break;
      }

      case UPDATE_VEHICLE_FOR_DELIVERY: {
        const { vehicle } = payload;
        draft.vehicle = vehicle;
        break;
      }

      case UPDATE_DELIVERY_FEE: {
        const { deliveryFee } = payload;
        draft.deliveryFee = deliveryFee;
        break;
      }

      case UPDATE_FREIGHT_VEHICLE: {
        const { freightVehicle, cart } = payload;
        draft.cart = cart;
        draft.deliveryFee = cart.freight;
        draft.freightVehicle = freightVehicle;
        break;
      }

      case UPDATE_SCHEDULED_TO: {
        const { scheduledTo } = payload;
        draft.delivery_scheduled_to = scheduledTo;
        break;
      }

      case UPDATE_IS_SCHEDULED: {
        const { isScheduled } = payload;
        draft.is_scheduled = isScheduled;
        break;
      }

      case SHOW_REGIONS_MODAL: {
        draft.showRegionsModal = payload;
        break;
      }

      case SET_SHOW_CONFIRM_REGION_MODAL: {
        draft.showConfirmRegionModal = payload;
        break;
      }

      case SET_LOADING_REGIONS: {
        draft.loadingRegions = payload;
        break;
      }

      case SET_TEMPORARY_STORE: {
        draft.selectedStore = payload;
        break;
      }

      case SET_STORE_REGIONS: {
        draft.storeRegions = payload;
        break;
      }

      case SET_CHOSEN_REGION: {
        draft.chosenRegion = payload;
        break;
      }

      case SET_REGION_SUCCESS: {
        draft.step = 3;
        draft.orderTab = 3;
        break;
      }

      case SET_STOCK: {
        draft.stock = payload;
        break;
      }

      case MODIFY_STOCK_ADDRESS: {
        draft.stock = {
          ...draft.stock,
          delivery_address: {
            ...payload,
          },
        };
        break;
      }

      case MODIFY_STOCK_FLEET: {
        draft.stock = {
          ...draft.stock,
          delivery_method: payload,
        };
        break;
      }

      case SET_DELIVERY_FLEET_SUCCESS: {
        const { cart, fleet } = payload;
        draft.cart = cart;
        draft.deliveryFleet = fleet;
        break;
      }

      default:
        return state;
    }
  });
}
