import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export default function IconButton({
  icon,
  iconClass,
  iconStyle,
  color,
  fontSize,
  id,
  click,
  tip,
  label,
  labelClass,
  labelOnLeft,
  containerClass,
  type,
  tipPlacement,
  forceTip,
  ...rest
}) {
  const target = useMemo(() => {
    return document.getElementById(id);
  }, [id]);

  const showTip = useMemo(() => {
    if (tip && (target || forceTip)) {
      return true;
    }
    return false;
  }, [tip, target, forceTip]);

  return (
    <div className={containerClass} {...rest} id={id}>
      {label && labelOnLeft && <label className={labelClass}>{label}</label>}
      <i
        className={`${icon} text-${color} font-size-${
          fontSize || 18
        } ${iconClass} appearence-none outline-none`}
        role="button"
        aria-label={`${label || 'icon'}-btn`}
        tabIndex={0}
        onClick={click}
        type={type || 'button'}
        style={iconStyle}
      />
      {!labelOnLeft && label && <label className={labelClass}>{label}</label>}
      {showTip && (
        <UncontrolledTooltip placement={tipPlacement || 'top'} target={id}>
          {tip}
        </UncontrolledTooltip>
      )}
    </div>
  );
}
