import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

export default function AntiFraud() {
  const provider_id = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store.cielo_provider_id
  );
  const cart_id = useSelector(({ shareable_cart }) => shareable_cart.cart?.id);

  const session_id = useMemo(() => {
    return `${provider_id}${cart_id}`;
  }, [provider_id, cart_id]);

  const org_id = useMemo(() => {
    if (process.env.REACT_APP_CIELO_ORGID === 'development') {
      return '1snn5n9w';
    }
    return 'k8vif92e';
  }, []);

  const url = useMemo(() => {
    const base = 'https://h.online-metrix.net/fp/tags.js';
    return `${base}?org_id=${org_id}&session_id=${session_id}`;
  }, [org_id, session_id]);

  return (
    <>
      <Helmet>
        <script type="text/javascript" src={url} />
      </Helmet>
      <noscript>
        <iframe
          title="fingerprint"
          src={url}
          style={{
            width: 100,
            height: 100,
            border: 0,
            position: 'absolute',
            top: -5000,
          }}
        />
      </noscript>
    </>
  );
}
