import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import history from '~/services/history';
import { defineAlignment } from '~/util/newLayoutHelpers';
import { LG } from '~/util/breakpointWidths';
import { setTab } from '~/store/modules/cart/actions';

export default function PageHeader({
  pageTitle,
  backTo,
  responsivePosition,
  desktopPosition,
  subtitle,
  customClassName,
  customArrowClassName,
  params,
  id,
}) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const location = useLocation();

  const dispatch = useDispatch();

  const orderTab = useSelector(({ cart }) => cart.orderTab);

  const toggleTab = tab => {
    if (orderTab !== tab) dispatch(setTab(tab));
  };

  return (
    <>
      {(pageTitle || subtitle) && (
        <Row className={`w-100 my-lg-4 mx-0 ${customClassName}`}>
          <Col
            className="px-0"
            align={defineAlignment(
              windowSize,
              LG,
              responsivePosition,
              desktopPosition
            )}
          >
            {pageTitle && <div className="page-header">{pageTitle}</div>}
            {subtitle && (
              <div className="font-size-14 font-weight-400 text-gray-600 mt-2">
                {subtitle}
              </div>
            )}
          </Col>
        </Row>
      )}
      {backTo && orderTab <= 1 && (
        <Row className={customArrowClassName || 'w-100 mx-0 mb-4 my-lg-4'}>
          <Col className="px-0">
            <button
              type="button"
              className="return-button pl-0"
              onClick={() => {
                if (typeof backTo === 'function') {
                  return backTo();
                }

                if (location.pathname.includes('/registrar') && id) {
                  return history.push(`/`);
                }

                return history.push({
                  pathname: backTo,
                  state: { ...params },
                });
              }}
            >
              <i className="las la-arrow-left mr-2 font-size-24" />
              <span>Voltar</span>
            </button>
          </Col>
        </Row>
      )}
      {location.pathname.includes('registrar') && orderTab > 1 && (
        <Row className="w-100 mx-0 mb-4 my-lg-4">
          <Col className="px-0">
            <button
              type="button"
              className="return-button pl-0"
              onClick={() => toggleTab(orderTab - 1)}
            >
              <i className="las la-arrow-left mr-2 font-size-24" />
              <span>Voltar</span>
            </button>
          </Col>
        </Row>
      )}
    </>
  );
}
