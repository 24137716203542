// eslint-disable-next-line import/no-extraneous-dependencies
import { isNaN } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function InputMaskFilter({
  setLoading,
  setData,
  setReqRunning,
  url,
  setFilter,
  isDateType,
  generateFilterParams,
  params,
  blurOnLoad,
  filter,
  ...rest
}) {
  const [error, setError] = useState(null);

  const loadData = async customParams => {
    try {
      if (setReqRunning) {
        setReqRunning(true);
      }

      setLoading(true);

      const { data } = await api.get(url, {
        params: customParams,
      });

      setData(data);

      if (setReqRunning) {
        setReqRunning(false);
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Houve um erro ao aplicar o filtro');
      }
      console.log(err);
    }
    setLoading(false);
  };

  const getDateFromString = (str, customFormat) => {
    const splitDate = str.split('/');

    const day = `${Number(splitDate[0])}`.padStart(2, '0');
    const month = `${Number(splitDate[1]) - 1}`.padStart(2, '0');
    // const day = `${Number(splitDate[0])}`.padStart(2, '0');
    // const month = `${Number(splitDate[1])}`.padStart(2, '0');
    const year = splitDate[2];

    // console.log(`Dia: ${day}`);
    // console.log(`Mês: ${month}`);
    // console.log(`Ano: ${year}`);

    const isoDate = new Date(year, month, day);
    if (customFormat) {
      const formattedDate = customFormat(isoDate);
      return formattedDate;
    }
    return isoDate;
  };

  const checkDateValid = date_query => {
    const limit = new Date('01/01/2020').getTime();
    const rawToday = new Date();
    rawToday.setHours(23, 59, 59);
    const today = rawToday.getTime();

    const rawDate = getDateFromString(date_query);
    const date = rawDate.getTime();

    // const today = new Date().toLocaleDateString('pt-BR');
    // const date = date_query;

    // console.log(date > today);
    // console.log(date_query);
    // console.log(new Date().toLocaleDateString('pt-BR'));
    // console.log(`Hoje: ${today}`);
    // console.log(`Limite: ${limit}`);
    // console.log(`rawToday: ${rawToday}`);
    // console.log(`rawDate: ${rawDate}`);
    // console.log(`Date: ${date}`);

    if (date >= limit && date <= today) {
      return true;
    }
    if (isNaN(date) || date < 0) {
      setError('Insira uma data válida');
      return false;
    }
    if (date > today) {
      setError('Insira uma data menor que a atual');
      return false;
    }

    if (date < limit) {
      setError('Insira uma data maior que 01/01/2020');
      return false;
    }

    return null;
  };

  const handleChange = useCallback(
    async query => {
      if (error) {
        setError(null);
      }

      if (!query) {
        setFilter(null);
        // return;
      }

      if (query && isDateType) {
        if (query.includes('_')) return;
        const dateValid = checkDateValid(query);

        if (!dateValid) return;
      }

      const value =
        query && isDateType
          ? getDateFromString(query, date => {
              date.setHours(0, 0, 0);
              return date.toISOString().substring(0, 10);
            })
          : query;

      // if (query) {
      setFilter(value);
      // }

      const filterParams = generateFilterParams(query ? value : null);

      loadData({ ...params, ...filterParams });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params]
  );

  useEffect(() => {
    if (blurOnLoad) {
      const input = document.getElementById(rest.id);
      input.focus();
      input.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blurOnLoad]);

  return (
    <>
      <ReactInputMask
        className={`form-control input-color mask-filter ${
          error ? 'border-danger' : ''
        }`}
        defaultValue="__/__/____"
        onChange={({ target }) => {
          const { value } = target;
          if (value === '') return;
          if (!filter && value === '__/__/____') return;
          if (value?.includes('_') && value !== '__/__/____') return;

          if (filter && value === '__/__/___') {
            target.focus();
            target.blur();
          }

          handleChange(value === '__/__/____' ? '' : value);
        }}
        onBlur={() => null}
        {...rest}
      />
      {error && <span className="text-danger">{error}</span>}
    </>
  );
}
