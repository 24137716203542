/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { connect } from 'react-redux';

// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

import AppRoute from '~/routes/route';

import SearchProvider from '~/components/HeaderComponents/SearchBar/context/provider';

import HorizontalLayout from '~/components/HorizontalLayout';
import NonAuthLayout from '~/components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

// History service
import history from '~/services/history';
import MaintenancePage from '~/pages/Utility/pages-maintenance';
import Pages404 from './pages/Utility/pages-404';
import { authProtectedRoutes, publicRoutes, universalRoutes } from './routes';

const App = () => {
  // Sentry.init({
  //   dsn: process.env.REACT_APP_SENTRY_DNS_URL,
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
  /**
   * Returns the layout
   */
  const getLayout = () => {
    return HorizontalLayout;
  };

  const Layout = getLayout();

  return (
    <>
      <Router history={history}>
        <SearchProvider>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                exact
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected
                needSalesmanRole={route.needSalesmanRole}
                needStockistRole={route.needStockistRole}
                needFinancierRole={route.needFinancierRole}
                onlyConsultant={route.onlyConsultant}
                onlyFinancier={route.onlyFinancier}
                onlyAdmin={route.onlyAdmin}
                onlyMaster={route.onlyMaster}
                adminAndManagerRoute={route.adminAndManagerRoute}
              />
            ))}

            {universalRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isUniversal
              />
            ))}
            <Route path="/manutencao" component={MaintenancePage} />
            <Route path="*" component={Pages404} />
          </Switch>
        </SearchProvider>
      </Router>
    </>
  );
};

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps, null)(App);
