import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Input } from 'reactstrap';
import Notifications from '~/components/HeaderComponents/Notifications';
// import StorePicker from '~/components/HeaderComponents/StorePicker';
// import UnreadMsgs from '~/components/HeaderComponents/UnreadMsgs';
import UserOptions from '~/components/HeaderComponents/UserOptions';
// import HeaderSearchBar from '~/components/HeaderComponents/SearchBar';
import useRoles from '~/hooks/useRoles';
import IconButton from '~/components/IconButton';
import history from '~/services/history';
import api from '~/services/api';
import { updateUserRequest } from '~/store/modules/user/actions';
import { setSearch } from '~/store/modules/search/actions';

export default function DesktopHeader() {
  const location = useLocation();

  // const showSidebar = useSelector(({ layout }) => layout.showSidebar);
  const user = useSelector(({ user: User }) => User.profile);
  const searchQuery = useSelector(({ search }) => search.query);

  const dispatch = useDispatch();

  const xServer = localStorage.getItem('@VIDDY:X-Server');

  // const xServer = localStorage.getItem('@VIDDY:X-Server');

  const {
    isAdmin,
    isStoreManager,
    isSalesman,
    isStockist,
    isConsultant,
    isFinancier,
    isMaster,
    isSupport,
  } = useRoles();

  const notMaster = useMemo(
    () =>
      isStoreManager ||
      isStockist ||
      isSalesman ||
      isConsultant ||
      isAdmin ||
      isFinancier ||
      isSupport,
    [
      isStoreManager,
      isStockist,
      isSalesman,
      isConsultant,
      isAdmin,
      isFinancier,
      isSupport,
    ]
  );

  const handleExitSubscriber = () => {
    const oldToken = localStorage.getItem('@VIDDY:SupportToken');
    localStorage.removeItem('@VIDDY:SupportToken');

    localStorage.setItem('@VIDDY:X-Server', '');
    localStorage.setItem('@VIDDY:Token', oldToken);
    api.defaults.headers['X-Server'] = '';
    api.defaults.headers.authorization = `Bearer ${oldToken}`;

    dispatch(updateUserRequest());

    history.push('/suporte');
  };

  // const canSearch = useMemo(
  //   () =>
  //     (isStoreManager || isStockist || isSalesman || isConsultant || isAdmin) &&
  //     user.managed_stores.length >= 1,
  //   [isStoreManager, isStockist, isSalesman, isConsultant, isAdmin, user]
  // );

  const hasSearchBox = useMemo(() => {
    return [
      '/',
      '/usuarios',
      '/lojas',
      '/clientes',
      '/carteira-de-clientes',
    ].includes(location.pathname);
  }, [location.pathname]);

  return (
    <Row className="header-row w-100 h-100 mx-0 pr-xl-4 d-none d-lg-flex justify-content-end align-items-center">
      {/* <Col
        lg={5}
        className={`h-100 d-flex flex-column justify-content-center search-bar-wrapper ${
          showSidebar ? 'sidebar-open' : ''
        }`}
      >
        {notMaster && <StorePicker />}
      </Col> */}
      <Col lg={6} style={{ paddingLeft: '156px' }}>
        {hasSearchBox && (
          <div className="search-box">
            <div className="position-relative">
              <Input
                name="header-search-input"
                type="text"
                className="form-control border-0 shadow-sm"
                placeholder="Buscar..."
                onChange={e => {
                  dispatch(setSearch({ query: e.target.value }));
                }}
                value={searchQuery || ''}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        )}
      </Col>
      <Col className="px-0" lg={6}>
        <Row className="btns-group-row">
          <Col
            className="px-0 d-flex flex-column justify-content-center align-items-center"
            lg={12}
          >
            {notMaster ? (
              <Row className="w-100 h-100 mx-0 d-flex flex-row justify-content-end align-items-center">
                {/* <div>
                  <UnreadMsgs />
                </div> */}
                <div className="ml-2 mr-2">
                  <Notifications />
                </div>
                <div className={isAdmin ? 'ml-4' : 'mx-4'}>
                  <UserOptions user={user} />
                </div>
                {(isAdmin || isMaster) && (
                  <div className="ml-4">
                    <IconButton
                      icon="las la-cog"
                      click={() => history.push('/configuracoes')}
                      fontSize={25}
                    />
                  </div>
                )}

                {isSupport && xServer && (
                  <div className="mr-4">
                    <IconButton
                      icon="las fa-exchange-alt"
                      click={() => handleExitSubscriber()}
                      fontSize={25}
                    />
                  </div>
                )}
              </Row>
            ) : (
              <Row className="py-3 w-100 h-100 mx-0 d-flex flex-row justify-content-end align-items-center">
                {/* <div>
                  <UnreadMsgs />
                </div> */}
                <div className="ml-2 mr-2">
                  <Notifications />
                </div>
                <div className={isAdmin ? 'ml-4' : 'mx-4'}>
                  <UserOptions user={user} />
                </div>
                {(isAdmin || isMaster || isSupport) && (
                  <IconButton
                    icon="las la-cog"
                    click={() => history.push('/configuracoes')}
                    fontSize={25}
                  />
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
