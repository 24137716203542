// @flow
import { defineFirstWindowSize } from '~/util/newLayoutHelpers';
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  TOGGLE_SIDEBAR,
  HIDE_SIDEBAR,
  TOGGLE_SEARCH,
  SET_DOM_LOADED,
  SET_WINDOW_SIZE,
  SET_SHOW_SEARCH,
} from './actionTypes';

const INIT_STATE = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'default',
  topbarTheme: 'dark',
  isPreloader: false,
  showRightSidebar: false,
  showSidebar: false,
  isMobile: false,
  showSearch: false,
  DOMLoaded: false,
  windowSize: defineFirstWindowSize(),
  showSearchBar: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };
    case SET_SHOW_SEARCH:
      return {
        ...state,
        showSearchBar: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        showSidebar: false,
      };
    case TOGGLE_SEARCH:
      return {
        ...state,
        showSearch: !state.showSearch,
      };
    case SET_DOM_LOADED:
      return {
        ...state,
        DOMLoaded: action.payload,
      };
    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
