import React from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '~/services/api';

export default function BroadcastAnnouncement({
  show,
  setShow,
  announcementToBeBroadcasted,
  setAnnouncementToBeBroadcasted,
}) {
  const handleBroadcastAnnouncement = async () => {
    try {
      await api.post(
        `/administrator/announcements/${announcementToBeBroadcasted.id}/broadcast`
      );

      toast.success(
        'Uma notificação desse anúncio foi enviada a todos os usuários'
      );
      setShow(false);
      setAnnouncementToBeBroadcasted(null);
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao enviar a notificação aos usuários. Contate um administrador ou tente novamente mais tarde.'
        );
      }
      setShow(false);
      setAnnouncementToBeBroadcasted(null);
    }
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader
        toggle={() => {
          setAnnouncementToBeBroadcasted(null);
          setShow(false);
        }}
      >
        Divulgar Anúncio
      </ModalHeader>
      <ModalBody>
        Essa ação divulgará o anúncio{' '}
        <strong>{announcementToBeBroadcasted?.title}</strong> para todos os
        usuários.
      </ModalBody>
      <ModalFooter>
        <Button
          className="new-layout-btn main"
          color="primary"
          onClick={() => {
            handleBroadcastAnnouncement();
          }}
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
