import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Container,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';
import { defineTableItemBorders } from '~/util/newLayoutHelpers';
import Loading from '~/components/Loading';
import ConfigurationCard from './ConfigurationCard';
import ExternalServiceCard from './ExternalServiceCard';
// import MaskConfigurationCard from './MaskConfigurationCard';

const Configurations = () => {
  const signedUser = useSelector(({ user }) => user.profile);

  const formRefsConfigs = useRef([]);
  const formRefsServices = useRef([]);

  const isAdmin =
    signedUser && signedUser.roles.find(role => role.slug === 'administrator');

  const [loadingConfigs, setLoadingConfigs] = useState(true);
  const [loadingServices, setLoadingServices] = useState(true);
  // const [loadingMasks, setLoadingMasks] = useState(true);

  const [configs, setConfigs] = useState(null);
  const [services, setServices] = useState(null);
  // const [masks, setMasks] = useState(null);

  // const [masksFields, setMasksFields] = useState(null);

  const [configsPagination, setConfigsPagination] = useState(null);
  const [servicesPagination, setServicesPagination] = useState(null);
  // const [masksPagination, setMasksPagination] = useState(null);

  const [activeTab, setActiveTab] = useState(1);

  const getConfigsURL = useMemo(() => {
    return `${isAdmin ? 'administrator' : 'master'}/configurations`;
  }, [isAdmin]);
  const getServicesURL = useMemo(() => {
    return `${isAdmin ? 'administrator' : 'master'}/external_services`;
  }, [isAdmin]);
  // const getMaskURL = useMemo(() => {
  //   return 'administrator/masks';
  // }, []);

  const configParams = useMemo(() => {
    return {
      page:
        configsPagination?.current_page > 1
          ? configsPagination?.current_page
          : null,
    };
  }, [configsPagination]);

  const servicesParams = useMemo(() => {
    return {
      page:
        servicesPagination?.current_page > 1
          ? servicesPagination?.current_page
          : null,
    };
  }, [servicesPagination]);

  // const masksParams = useMemo(() => {
  //   return {
  //     page:
  //       masksPagination?.current_page > 1
  //         ? masksPagination?.current_page
  //         : null,
  //   };
  // }, [masksPagination]);

  const setConfigsData = data => {
    const formattedConfigs = data.configurations.map((config, index) => ({
      ...config,
      border_class: defineTableItemBorders(index, data.configurations.length),
      value: config.type === 'array' ? config.value?.toString() : config.value,
    }));

    setConfigs(formattedConfigs);
    setConfigsPagination(data.meta);
  };

  const setServicesData = data => {
    const formattedServices = data.external_services.map((service, index) => ({
      ...service,
      border_class: defineTableItemBorders(
        index,
        data.external_services.length
      ),
    }));

    setServices(formattedServices);
    setServicesPagination(data.meta);
  };

  // const setMasksData = data => {
  //   const formattedMasks = data.masks[0].fields.map((mask, index) => ({
  //     id: data.masks[0].id,
  //     maskable_type: data.masks[0].maskable_type,
  //     ...mask,
  //     border_class: defineTableItemBorders(index, data.length),
  //   }));

  //   setMasks(formattedMasks);
  //   setMasksPagination(data.meta);
  // };

  useEffect(() => {
    document.title = 'Configurações | Viddy';

    const loadAll = async () => {
      try {
        const [
          { data: configsData },
          { data: servicesData },
          // { data: masksData },
        ] = await Promise.all([
          api.get(getConfigsURL),
          api.get(getServicesURL),
          // api.get(getMaskURL),
        ]);

        // const maskFieldsData = {
        //   id: masksData.masks[0].id,
        //   maskable_type: masksData.masks[0].maskable_type,
        //   fields: masksData.masks[0].fields,
        // };

        setConfigsData(configsData);
        setServicesData(servicesData);
        // setMasksData(masksData);
        // setMasksFields(maskFieldsData);

        setLoadingConfigs(false);
        setLoadingServices(false);
        // setLoadingMasks(false);
      } catch (err) {
        console.log(err);
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Acesso negado.');
        }
      }
    };

    // loadMasks();
    loadAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveConfig = async (data, configuration, index) => {
    try {
      if (configuration.type === 'array') {
        data = {
          value: JSON.parse(`[${data.value.replace(/,\s*$/, '')}]`),
        };
      }

      const configsDraft = configs.map(config =>
        config.id === configuration.id
          ? {
              ...configuration,
              value:
                configuration.type === 'array'
                  ? data.value.toString()
                  : data.value,
              // configuration.name !== 'payment_gateway' ? data.value.option : data.value
            }
          : config
      );
      setConfigs(configsDraft);

      await api.put(
        `${isAdmin ? 'administrator' : 'master'}/configurations/${
          configuration.id
        }`,
        data
      );

      toast.success('Configuração editada com sucesso!');
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRefsConfigs.current[index].setErrors(validationErrors);
      } else {
        if (configuration.type === 'array') {
          toast.error('Separe os valores por vírgulas corretamente ');
          return;
        }
        toast.error('Erro inesperado!');
      }
    }
  };

  const handleSaveService = async (data, incomingService, index) => {
    try {
      const servicesDraft = services.map(service =>
        service.id === incomingService.id
          ? {
              ...incomingService,
              key: data.key,
              uri: data.uri,
              service_type: data.service_type,
            }
          : service
      );

      setServices(servicesDraft);

      await api.put(
        `${isAdmin ? 'administrator' : 'master'}/external_services/${
          incomingService.id
        }`,
        data
      );

      toast.success('Credencial alterada com sucesso!');
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRefsServices.current[index].setErrors(validationErrors);
      } else {
        toast.error('Erro inesperado!');
      }
    }
  };

  // const handleMaskConfig = async (data, incomingMask, index) => {
  //   try {
  //     const masksDraft = masks.map(mask =>
  //       mask.id === incomingMask.id
  //         ? {
  //             ...incomingMask,
  //             value: data.value,
  //           }
  //         : mask
  //     );

  //     const dataFormatted = {
  //       maskable_type: incomingMask.maskable_type,
  //       fields: [
  //         // ...masksFields.fields,
  //         {
  //           name: incomingMask.name,
  //           should_apply_mask: data.value,
  //         },
  //       ],
  //     };
  //     console.log(dataFormatted);

  //     setMasks(masksDraft);

  //     await api.put(`administrator/masks/${incomingMask.id}`, dataFormatted);

  //     toast.success('Configuração editada com sucesso!');
  //   } catch (err) {
  //     const validationErrors = {};
  //     if (err instanceof Yup.ValidationError) {
  //       err.inner.forEach(e => {
  //         validationErrors[e.path] = e.message;
  //       });
  //       formRefsConfigs.current[index].setErrors(validationErrors);
  //     } else {
  //       toast.error('Erro inesperado!');
  //     }
  //   }
  // };

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="CONFIGURAÇÕES"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col lg={6} className="px-0">
            <Nav tabs className="w-100 border-none">
              <Row className="w-100 mx-0 mb-4">
                <Col xs={4} className="px-0">
                  <NavItem className="d-none d-lg-block">
                    <NavLink
                      className={`pl-0 font-size-16 font-weight-600 text-gray-700 ${
                        activeTab === 1 ? 'tab-active' : 'border-none'
                      }`}
                      onClick={() => {
                        if (activeTab !== 1) {
                          setActiveTab(1);
                        }
                      }}
                    >
                      Ajustes do Sistema
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-lg-none">
                    <NavLink
                      className={`pl-0 font-size-16 font-weight-600 text-gray-700 ${
                        activeTab === 1 ? 'tab-active' : 'border-none'
                      }`}
                      onClick={() => {
                        if (activeTab !== 1) {
                          setActiveTab(1);
                        }
                      }}
                    >
                      Ajustes
                    </NavLink>
                  </NavItem>
                </Col>
                <Col xs={4} className="px-0">
                  <NavItem>
                    <NavLink
                      className={`pl-0 font-size-16 font-weight-600 text-gray-700 ${
                        activeTab === 2 ? 'tab-active' : 'border-none'
                      }`}
                      onClick={() => {
                        if (activeTab !== 2) {
                          setActiveTab(2);
                        }
                      }}
                    >
                      Credenciais
                    </NavLink>
                  </NavItem>
                </Col>
                {/* <Col xs={4} className="px-0">
                  <NavItem>
                    <NavLink
                      className={`pl-0 font-size-16 font-weight-600 text-gray-700 ${
                        activeTab === 3 ? 'tab-active' : 'border-none'
                      }`}
                      onClick={() => {
                        if (activeTab !== 3) {
                          setActiveTab(3);
                        }
                      }}
                    >
                      Máscaras de cliente
                    </NavLink>
                  </NavItem>
                </Col> */}
              </Row>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <>
              {loadingConfigs ? (
                <Loading />
              ) : (
                <>
                  {configs.length ? (
                    <>
                      <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Nome
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="new-layout-table-column-label">
                            Descrição
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="new-layout-table-column-label">
                            Valor
                          </div>
                        </Col>
                        <Col xs={1} align="center">
                          <div className="new-layout-table-column-label">
                            Ações
                          </div>
                        </Col>
                      </Row>
                      {configs.map((config, index) => {
                        if (config.name === 'maintenance_mode') {
                          return (
                            <ConfigurationCard
                              key={`config-${config.id}`}
                              config={config}
                              index={index}
                              className="mb-4"
                              handleSubmit={handleSaveConfig}
                            />
                          );
                        }
                        return (
                          <ConfigurationCard
                            key={`config-${config.id}`}
                            config={config}
                            index={index}
                            handleSubmit={handleSaveConfig}
                          />
                        );
                      })}

                      <Row className="w-100 mx-0 my-4">
                        <Col className="px-0" align="end">
                          {configsPagination && (
                            <div className="mb-3">
                              <Pagination
                                paginationMeta={configsPagination}
                                url={getConfigsURL}
                                setData={setConfigsData}
                                setLoading={setLoadingConfigs}
                                params={configParams}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <h3>Nenhuma configuração encontrada.</h3>
                  )}
                </>
              )}
            </>
          </TabPane>
          <TabPane tabId={2}>
            <>
              {loadingServices ? (
                <Loading />
              ) : (
                <>
                  {services.length ? (
                    <>
                      <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                        <Col xs={2}>
                          <div className="new-layout-table-column-label">
                            Nome
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Descrição
                          </div>
                        </Col>
                        <Col xs={2}>
                          <div className="new-layout-table-column-label">
                            Chave
                          </div>
                        </Col>
                        <Col xs={2}>
                          <div className="new-layout-table-column-label">
                            URL
                          </div>
                        </Col>
                        <Col xs={2} align="center">
                          <div className="new-layout-table-column-label">
                            Tipo de Serviço
                          </div>
                        </Col>
                        <Col xs={1} align="center">
                          <div className="new-layout-table-column-label">
                            Ações
                          </div>
                        </Col>
                      </Row>
                      {services.map((service, index) => (
                        <ExternalServiceCard
                          key={`service-${service.id}`}
                          service={service}
                          index={index}
                          handleSubmit={handleSaveService}
                        />
                      ))}

                      <Row className="w-100 mx-0 my-4">
                        <Col className="px-0" align="end">
                          {configsPagination && (
                            <div className="mb-3">
                              <Pagination
                                paginationMeta={servicesPagination}
                                url={getServicesURL}
                                setData={setServicesData}
                                setLoading={setLoadingServices}
                                params={servicesParams}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <h3>Nenhuma credencial encontrada.</h3>
                  )}
                </>
              )}
            </>
          </TabPane>
          {/* <TabPane tabId={3}>
            <>
              {loadingMasks ? (
                <Loading />
              ) : (
                <>
                  {masks !== null && masks.length ? (
                    <>
                      <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Dados pessoais
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Tipo de Máscara
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Mascarar
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="new-layout-table-column-label">
                            Exemplo
                          </div>
                        </Col>
                      </Row>

                      {masks.map((mask, index) => {
                        return (
                          <MaskConfigurationCard
                            key={`mask-${mask.id}`}
                            mask={mask}
                            index={index}
                            handleSubmit={handleMaskConfig}
                          />
                        );
                      })}

                      <Row className="w-100 mx-0 my-4">
                        <Col className="px-0" align="end">
                          {masksPagination && (
                            <div className="mb-3">
                              <Pagination
                                paginationMeta={masksPagination}
                                url={getMaskURL}
                                setData={setMasksData}
                                setLoading={setLoadingMasks}
                                params={masksParams}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <h3>Nenhuma mascara encontrada.</h3>
                  )}
                </>
              )}
            </>
          </TabPane> */}
        </TabContent>
      </Container>
    </div>
  );
};

export default Configurations;
