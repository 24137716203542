import { createContext } from 'react';

const SearchContext = createContext({
  isSearching: false,
  setIsSearching: () => {},
  search: '',
  setSearchValue: () => {},
});

export default SearchContext;
