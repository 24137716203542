import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';

import Loading from '~/components/Loading';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';

import PushCard from './PushCard';
import AddNewPush from './AddNewPush';

const Pushs = () => {
  const [pushNotifications, setPushNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const getURL = useMemo(() => {
    return '/administrator/push-notifications';
  }, []);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    const formattedPushs = data.push_notifications.map(push => ({
      ...push,
      formatted_description: ReactHtmlParser(push.message),
    }));

    setPushNotifications(formattedPushs);
    setPagination(data.meta);
  };

  useEffect(() => {
    document.title = 'Push Notifications | Viddy';

    const loadAll = async () => {
      const { data } = await api.get(getURL);

      setData(data);
      setLoading(false);
    };

    loadAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="Notificações de Push"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            {isAdmin && (
              <AddNewPush
                setAddModalOpen={setShowAddModal}
                addModalOpen={showAddModal}
                setNotifications={setData}
              />
            )}
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {pushNotifications.length ? (
              <>
                {pushNotifications?.map(push => {
                  return (
                    <PushCard key={push.id} push={push} canManage={isAdmin} />
                  );
                })}
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum Push Notification encontrado.</h3>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Pushs;
