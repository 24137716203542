/* eslint-disable consistent-return */
import produce from 'immer';
import { OPEN_CHAT_SUCCESS } from '../chat/actionsTypes';

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_LOADING_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_SUCCESS,
  ADD_UNREAD_MSG,
  REMOVE_UNREAD_MSG,
  LOAD_UNREAD_MSGS_SUCCESS,
  SET_LOADING_UNREAD_MSGS,
  SET_NOTIFICATIONS_ARRAY,
  LOAD_OLDER_NOTIFICATIONS,
  INCREMENT_UNREAD_NOTIFICATIONS_COUNT,
  DECREMENT_UNREAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATIONS_PAGE_ARRAY,
  SET_COUNTER_TO_ZERO,
} from './actionsTypes';
// import { orderBy } from 'lodash';

const initialState = {
  unreadChatMsgs: [],
  loadingUnreadMsgs: true,
  loading: true,
  notifications: [],
  notificationsPageArray: [],
  notificationsPagination: null,
  unreadNotificationsCount: null,
};

export default function Chat(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_NOTIFICATION: {
        if (draft.notifications.length) {
          const isInNotifications = draft.notifications.some(
            notification => notification.uuid === action.payload.uuid
          );
          if (isInNotifications) {
            const myDraft = draft.notifications.filter(
              notification => notification.uuid !== action.payload.uuid
            );
            draft.notifications = [action.payload, ...myDraft];
          } else {
            draft.notifications = [action.payload, ...draft.notifications];
          }
        } else {
          draft.notifications = [action.payload];
        }
        break;
      }

      case REMOVE_NOTIFICATION: {
        draft.notifications = draft.notifications.filter(notificaton => {
          return notificaton.uuid !== action.payload.uuid;
        });
        break;
      }

      case SET_NOTIFICATIONS_ARRAY: {
        draft.notifications = action.payload;
        break;
      }

      case SET_NOTIFICATIONS_PAGE_ARRAY: {
        draft.notificationsPageArray = action.payload;
        break;
      }

      case INCREMENT_UNREAD_NOTIFICATIONS_COUNT: {
        draft.unreadNotificationsCount += 1;
        break;
      }

      case DECREMENT_UNREAD_NOTIFICATIONS_COUNT: {
        draft.unreadNotificationsCount += -1;
        break;
      }

      case SET_COUNTER_TO_ZERO: {
        draft.unreadNotificationsCount = 0;
        break;
      }

      case LOAD_NOTIFICATIONS_SUCCESS: {
        const { notifications, meta, count } = action.payload;

        draft.notifications = notifications;
        draft.notificationsPagination = meta;
        draft.unreadNotificationsCount = count;
        draft.loading = false;
        break;
      }

      case LOAD_OLDER_NOTIFICATIONS: {
        const { notifications, meta } = action.payload;

        draft.notifications = [...draft.notifications, ...notifications];
        draft.notificationsPagination = meta;
        break;
      }

      case SET_LOADING_NOTIFICATIONS: {
        draft.loading = action.payload;
        break;
      }

      case ADD_UNREAD_MSG: {
        if (draft.unreadChatMsgs.length !== 0) {
          const isInNotifications = draft.unreadChatMsgs.some(
            notification => notification.id === action.payload.id
          );
          if (isInNotifications) {
            const myDraft = draft.unreadChatMsgs.filter(
              notification => notification.id !== action.payload.id
            );

            draft.unreadChatMsgs = [action.payload, ...myDraft];
          } else {
            draft.unreadChatMsgs = [action.payload, ...draft.unreadChatMsgs];
          }
        } else {
          draft.unreadChatMsgs = [action.payload];
        }
        break;
      }

      case REMOVE_UNREAD_MSG: {
        draft.unreadChatMsgs = draft.unreadChatMsgs.filter(notificaton => {
          return notificaton.id !== action.payload.id;
        });
        break;
      }

      case OPEN_CHAT_SUCCESS: {
        const { chat } = action.payload;

        if (draft.unreadChatMsgs.length) {
          draft.unreadChatMsgs = draft.unreadChatMsgs.filter(notificaton => {
            return notificaton.id !== chat.id;
          });
        }

        break;
      }

      case LOAD_UNREAD_MSGS_SUCCESS: {
        draft.unreadChatMsgs = action.payload;
        draft.loadingUnreadMsgs = false;
        break;
      }

      case SET_LOADING_UNREAD_MSGS: {
        draft.loadingUnreadMsgs = action.payload;
        break;
      }

      default:
        return state;
    }
  });
}
