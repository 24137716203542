import { useCallback, useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createVCardLink } from '~/util/vcard';
import logoWhite from '~/assets/images/logo-white.svg';
import api from '~/services/api';
import isDarkColor from '~/util/isDarkColor';
import Loading from '~/components/Loading';
import SellerImage from '../BusinessCard/SellerImage';
import NewClientForm from './NewClientForm';

const useQuery = () => new URLSearchParams(useLocation().search);

function NewWalletCustomer() {
  const query = useQuery();
  const xserver = query.get('token');
  const userId = query.get('id');

  const [success, setSuccess] = useState(false);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    const { data } = await api.get(`/crm/seller-data/${userId}`, {
      headers: localStorage.getItem('@VIDDY:X-Server')
        ? undefined
        : {
            'x-server': xserver,
          },
    });

    setProfile(data.data);

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(loadData, [loadData]);

  const themeIsWhite = profile?.stock?.customize?.theme_color === '#ffffff';
  const isDarkTheme = profile?.stock?.customize?.theme_color
    ? isDarkColor(profile?.stock?.customize?.theme_color)
    : false;

  if (loading) return <Loading />;

  if (!success)
    return (
      <NewClientForm
        profile={profile}
        setSuccess={setSuccess}
        xserver={xserver}
        userId={userId}
      />
    );

  return (
    <div
      className="new-layout wide crm-new-customer-form"
      style={{ minHeight: '100vh' }}
    >
      <Row
        className="w-100 mx-0 mb-md-4"
        style={{
          height: '65px',
          backgroundColor: profile?.stock?.customize?.theme_color,
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <img
            src={profile?.stock?.customize?.brand_logo?.url || logoWhite}
            alt="logo"
            id="logo"
            style={{
              height: '60px',
              width: '160px',
            }}
          />
        </Container>
      </Row>

      <Col lg={6} className="px-4 px-sm-5 px-lg-0 mx-auto px-lg-0">
        <Card className="card new-layout mt-5">
          <CardBody
            className="card-body new-layout d-flex p-0"
            style={{
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            }}
          >
            <Row className="m-0">
              <Col xs={12} align="center" className="p-3 bg-secondary">
                <Row className="seller-card-header">
                  <SellerImage
                    className="seller-image"
                    src={profile?.seller_image}
                  />
                  <h4 className="text-dark seller-title">
                    {profile?.name} - {profile?.stock?.fantasy_name}
                  </h4>
                </Row>
              </Col>
              <Col xs={12} className="p-4">
                <p className="mb-0">
                  Seja bem vindo a minha comunidade! Obrigado pelo seu tempo ao
                  se cadastrar ;)
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Col align="center">
          <p className="mb-5 font-size-16">
            Salve meu contato na sua agenda e me chame sempre que quiser!
          </p>
          <button
            type="button"
            className="new-layout-btn mx-auto"
            style={{
              backgroundColor: profile?.stock?.customize?.theme_color,
              color: isDarkTheme ? 'white' : 'black',
              border: themeIsWhite ? '1px solid #000000' : 'none',
              padding: '0.5rem 1rem',
            }}
            onClick={() => {
              createVCardLink({
                name: `${profile?.name} - ${profile?.stock?.fantasy_name}`,
                telephone: profile?.telephone,
              });
            }}
          >
            Salvar
          </button>
        </Col>
      </Col>
    </div>
  );
}

export default NewWalletCustomer;
