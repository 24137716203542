import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { formatPrice } from '~/util/format';
import api from '~/services/api';
import Loading from '~/components/Loading';
import RevenueCard from './RevenueCard';
import SalesForMonthChart from './SalesForMonth';
import RatingCard from './RatingCard';
import Table from './Table';

const getMonthName = month => {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return monthNames[month - 1];
};

function GeneralTab({
  isAdmin,
  store,
  selectedStore,
  startDateParameter,
  endDateParameter,
  child,
}) {
  const [loading, setLoading] = useState(true);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [totalUniqueClients, setTotalUniqueClients] = useState(null);
  const [deliveryWeight, setDeliveryWeight] = useState(null);
  const [rating, setRating] = useState(null);
  const [salesForMonth, setSalesForMonth] = useState(null);
  const [kpiFinishedOrders, setKpiFinishedOrders] = useState(null);
  const [kpiDeliveries, setKpiDeliveries] = useState(null);
  const [kpiActiveSellers, setKpiActiveSellers] = useState(null);
  const [kpiAmountSales, setKpiAmountSales] = useState(null);
  const [kpiAverageTicket, setKpiAverageTicket] = useState(null);
  const [kpiDigitalPayment, setKpiDigitalPayment] = useState(null);
  const [kpiTotalCustomers, setKpiTotalCustomers] = useState(null);

  const load = useCallback(() => {
    setLoading(true);
    Promise.all([
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/totalRevenue?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setTotalRevenue(data))
        .catch(() =>
          setTotalRevenue({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/averageDeliveryScore?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setRating(data.average_delivery_score))
        .catch(() => setRating({ error: 'Erro ao carregar informações' })),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/totalUniqueClients?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setTotalUniqueClients(data))
        .catch(() =>
          setTotalUniqueClients({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/deliveryWeight?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setDeliveryWeight(data))
        .catch(() =>
          setDeliveryWeight({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/salesForMonth?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setSalesForMonth(data))
        .catch(() =>
          setSalesForMonth({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiFinishedOrders?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiFinishedOrders(data))
        .catch(() =>
          setKpiFinishedOrders({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiDeliveries?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiDeliveries(data))
        .catch(() =>
          setKpiDeliveries({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiAmountSales?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiAmountSales(data))
        .catch(() =>
          setKpiAmountSales({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiActiveSellers?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiActiveSellers(data))
        .catch(() =>
          setKpiActiveSellers({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiAverageTicket?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiAverageTicket(data))
        .catch(() =>
          setKpiAverageTicket({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiDigitalPayments?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiDigitalPayment(data))
        .catch(() =>
          setKpiDigitalPayment({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/kpiTotalCustomers?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setKpiTotalCustomers(data))
        .catch(() =>
          setKpiTotalCustomers({ error: 'Erro ao carregar informações' })
        ),
    ]).then(() => setLoading(false));
  }, [isAdmin, selectedStore, startDateParameter, endDateParameter]);

  useEffect(load, [load]);

  if (loading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loading />
      </div>
    );

  return (
    <>
      <Row className="w-100 mx-0 py-0">
        <Col lg={3} className="px-0 pr-lg-2">
          <RevenueCard
            label="Receita total"
            value={totalRevenue?.error || formatPrice(totalRevenue)}
            icon="las la-coins"
          />
        </Col>
        <Col lg={3} className="px-0 px-lg-2">
          <RevenueCard
            label="Quantidade de clientes"
            value={totalUniqueClients.error || totalUniqueClients}
            icon="las la-user"
          />
        </Col>
        <Col lg={3} className="px-0 px-lg-2">
          <RevenueCard
            label="Peso entrega"
            value={deliveryWeight.error || `${Math.floor(deliveryWeight)}%`}
            icon="las la-truck"
          />
        </Col>
        <Col lg={3} className="px-0 pl-lg-2">
          <RevenueCard
            label="Avaliações de entrega"
            value={
              rating?.error || <RatingCard rating={rating} myStore={store} />
            }
            icon="las la-truck"
          />
        </Col>
      </Row>

      <Row className="w-100 mx-0">
        <Col className="px-0">
          <SalesForMonthChart
            data={salesForMonth}
            getMonthName={getMonthName}
          />
        </Col>
      </Row>
      <Row className="w-100 mx-0">
        <Table
          columns={[
            'KPI',
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ]}
          rows={[
            ['Pedido finalizado (%)'].concat(
              kpiFinishedOrders.error ||
                Object.values(kpiFinishedOrders).map(
                  value => `${Math.floor(Number(value.total))}%`
                )
            ),
            ['Delivery (%)'].concat(
              kpiDeliveries.error ||
                Object.values(kpiDeliveries).map(
                  value => `${Math.floor(Number(value.total))}%`
                )
            ),
            ['Pagamentos digitais (%)'].concat(
              kpiDigitalPayment.error ||
                Object.values(kpiDigitalPayment).map(
                  value => `${Math.floor(Number(value.total))}%`
                )
            ),
            ['Quantidade de Vendas (#)'].concat(
              kpiAmountSales.error || Object.values(kpiAmountSales)
            ),
            ['Vendedores ativos (#)'].concat(
              kpiActiveSellers.error ||
                Object.keys(kpiActiveSellers)
                  .sort((a, b) => Number(a) - Number(b))
                  .map(key => kpiActiveSellers[key].total)
            ),
            ['Clientes Fidelizados (#)'].concat(
              kpiTotalCustomers.error || Object.values(kpiTotalCustomers)
            ),
            ['Ticket médio (R$)'].concat(
              kpiAverageTicket.error ||
                Object.values(kpiAverageTicket).map(value =>
                  formatPrice(value.total || 0)
                )
            ),
          ]}
        />
      </Row>
      {child}
    </>
  );
}

export default GeneralTab;
