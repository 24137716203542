import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Sales Redux States
import Axios from 'axios';

// Custom services
import api from '~/services/api';
import { breakString } from '~/util/newLayoutHelpers';
import { getRole } from '~/util/getRole';
import {
  updateSaleListSuccess,
  removeSaleSuccess,
  searchSaleSuccess,
  apiSalesError,
} from './actions';
import { UPDATE_SALE_LIST, REMOVE_SALE, SEARCH_SALE } from './actionTypes';

function* updateSaleList({ payload: { page = 1 } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const { data } = yield call(
      api.get,
      `${getRole(roles)}/carts?page=${page}`
    );

    const carts = data.carts.map(cart => ({
      ...cart,
      updated_at: format(parseISO(cart.updated_at), "HH'h'mm '|' dd/MMM/yy", {
        locale: ptBR,
      }),
    }));
    yield put(
      updateSaleListSuccess({ sales: carts, paginationMeta: data.meta })
    );
  } catch (err) {
    yield put(apiSalesError(err?.response?.data?.message));
  }
}

function* searchSale({ payload: search }) {
  try {
    const cancelToken = Axios.CancelToken.source().token;
    const roles = yield select(({ user }) => user.profile.roles);

    const { data } = yield call(api.get, `${getRole(roles)}/carts`, {
      params: { query: search },
      cancelToken,
    });

    if (search) {
      const formattedCarts = data.carts.map(cart => ({
        ...cart,
        formatted_updated_at: format(
          parseISO(cart.updated_at),
          "HH'h'mm '-' dd/MMM/yy",
          {
            locale: ptBR,
          }
        ),
        client: {
          ...cart.client,
          broken_name: cart.client
            ? breakString(cart.client.name, ' ', 18)
            : null,
        },
        store: {
          ...cart.store,
          broken_fantasy_name: breakString(
            cart.store.fantasy_name,
            ' ',
            18,
            true
          ),
        },
      }));
      yield put(searchSaleSuccess(formattedCarts));
    } else {
      const carts = data.carts.map(cart => ({
        ...cart,
        updated_at: format(parseISO(cart.updated_at), "HH'h'mm '|' dd/MMM/yy", {
          locale: ptBR,
        }),
      }));
      yield put(updateSaleListSuccess(carts));
    }
  } catch (err) {
    console.log(err);
    yield put(apiSalesError(err?.response?.data?.message));
  }
}

function* removeSale({ payload: id }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    yield call(api.delete, `${getRole(roles)}/carts/${id}`);

    yield put(removeSaleSuccess(id));
  } catch (err) {
    yield put(apiSalesError(err?.response?.data?.message));
  }
}

export default all([
  takeLatest(UPDATE_SALE_LIST, updateSaleList),
  takeLatest(SEARCH_SALE, searchSale),
  takeLatest(REMOVE_SALE, removeSale),
]);
