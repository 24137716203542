export const formatDate = str => {
  if (typeof str === 'string') {
    const year = str.substring(0, 4);
    const month = str.substring(5, 7);
    const days = str.substring(8, 10);

    const hour = str.substring(11, 13);
    const minutes = str.substring(14, 16);

    if (hour !== '') {
      return `${days}/${month}/${year} às ${hour}:${minutes}`;
    }
    return `${days}/${month}/${year}`;
  }

  return 'Houve um problema';
};
