import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import history from '~/services/history';
import Loading from '../Loading';
import PixDataCard from './PixDataCard';

export default function PixQrCodeModal({ show, loading, qrCode, onFinish }) {
  return (
    <Modal isOpen={show}>
      <ModalHeader>
        {loading ? 'Aguarde, o QR Code está sendo gerado' : 'Pagamento via PIX'}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loading />
        ) : (
          <>
            <PixDataCard qrCode={qrCode} />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="new-layout-btn main"
          onClick={() => {
            if (onFinish) {
              onFinish();
            } else {
              history.push('/');
            }
          }}
        >
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
