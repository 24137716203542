import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ApexCharts from 'react-apexcharts';
import { formatPrice } from '~/util/format';

export default function DailyRevenue() {
  const series = [
    {
      name: 'Valor',
      data: [
        2400, 2400, 800, 1200, 1200, 2000, 2000, 2000, 2000, 2000, 2000, 2400,
      ],
    },
  ];
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const lineChart = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: 'false',
      dropShadow: {
        enabled: !0,
        color: '#000',
        top: 5,
        left: 7,
        blur: 6,
        opacity: 0.2,
      },
    },
    xaxis: {
      // type: 'datetime',
      months,
      title: {
        text: 'Mês',
      },
      // labels: {
      //   format: 'MM',
      // },
    },
    yaxis: {
      labels: {
        formatter: (value, index) => {
          return `${index?.seriesIndex === 1 ? value : formatPrice(value)}`;
        },
      },
    },
    colors: ['#1c99d7'],
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    tooltip: {
      x: {
        show: true,
        formatter: value => {
          return `Mês ${value}`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
            title: { text: undefined },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState(lineChart);

  return (
    <Card className="card new-layout dashboard-card-border">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0 mb-4 font-size-16 font-weight-600 text-gray-700">
          Faturamento Mensal com Assinaturas
        </Row>

        <Row className="w-100 mx-0">
          <Col className="px-0">
            <ApexCharts
              options={options}
              series={series}
              height={400}
              width="93%"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
