import React from 'react';
import {
  DatePicker as MUIDatePicker,
  MuiPickersUtilsProvider,
  // TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import ptBR from 'date-fns/locale/pt-BR';

export default function DatePicker({ ...rest }) {
  const inputProps = {
    classes: {
      input: 'cursor-pointer',
    },
    endAdornment: (
      <IconButton aria-label="Select locale">
        <i className="las la-calendar" />
      </IconButton>
    ),
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <MUIDatePicker
        InputProps={inputProps}
        cancelLabel="Cancelar"
        clearable
        showTodayButton
        todayLabel="Hoje"
        clearLabel="Limpar"
        format="dd/MM/yyyy"
        classes={{
          root: 'w-100 w-lg-fit-content',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
