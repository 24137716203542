import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import RichTextEditor from 'react-rte';

import { toast } from 'react-toastify';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';
// import history from '~/services/history';
import { toolbarConfig } from '~/util/textToolbarConfig';
import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import history from '~/services/history';

const AddNewPush = ({
  addModalOpen,
  setAddModalOpen,
  setNotifications,
  className,
}) => {
  const formRef = useRef(null);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [users, setUsers] = useState([]);

  useEffect(
    () =>
      addModalOpen
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open'),
    [addModalOpen]
  );

  const loadOptions = async (inputValue, cb) => {
    if (inputValue.length < 2) return;

    const { data } = await api.get(`/administrator/users`, {
      params: { query: inputValue },
    });

    if (data.users.length === 0) {
      return;
    }

    const usersData = data.users.map(user => ({
      value: user.id,
      label: user.name,
    }));

    setUsers(data.users);

    cb(usersData);
  };

  const [selectedUsers, setSelectedUsers] = useState();

  const addToSelectedUsers = option => {
    if (option) {
      const user = users.find(p => p.id === option.value);

      if (!user) return;

      const selectUser = {
        value: user.id,
        label: user.fantasy_name,
      };

      setSelectedUsers([...selectedUsers, selectUser]);
    }
  };

  const toggle = () => setAddModalOpen(!addModalOpen);

  const onSubmitHandler = async push => {
    try {
      formRef.current.setErrors({});

      const message = value.toString('html');

      push = {
        ...push,
        message,
      };

      const schema = Yup.object().shape({
        title: Yup.string().required('Digite um título para o Anúncio'),
        message: Yup.string().required('Campo obrigatório'),
      });

      await schema.validate(push, {
        abortEarly: false,
      });

      await api.post(`/administrator/push-notifications`, push);

      const { data: pushs } = await api.get(
        `/administrator/push-notifications`
      );

      setNotifications(pushs);

      toast.success('Push-Notification registrado com sucesso!');
      toggle();
      document.body.style.cssText = null;

      history.push(`/push-notifications`);
      setValue(RichTextEditor.createEmptyValue());
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  return (
    <div className={`align-self-end ${className}`}>
      <Button className="new-layout-btn main" onClick={toggle} color="primary">
        <i className="fas fa-plus font-size-16 align-middle mr-2" /> Novo Push
        Notification
      </Button>
      <Modal isOpen={addModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Nova Push Notification</ModalHeader>
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Título</Label>
                  <Input
                    name="title"
                    type="text"
                    placeholder="Qual o título da Push?"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Texto</Label>
                  <RichTextEditor
                    name="message"
                    value={value}
                    onChange={a => setValue(a)}
                    toolbarConfig={toolbarConfig}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Usuários</Label>
                  <InputSelectAsync
                    loadOptions={loadOptions}
                    id="recipients"
                    name="recipients"
                    placeholder="Usuários que receberão a Push"
                    type="text"
                    onChange={addToSelectedUsers}
                    loadingMessage={() => 'Buscando usuários...'}
                    onBlur={() => {}}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="px-5">
              Criar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewPush;
