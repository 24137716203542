import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

import { useDispatch } from 'react-redux';

import { logoutUser } from '~/store/modules/auth/login/actions';

// Import Cards
import maintenance from '~/assets/images/maintenance.png';
import CardMaintenance from './card-maintenance';

// Import Images

function PagesMaintenance() {
  const dispatch = useDispatch();

  return (
    <>
      <section className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col xs="12" className="text-center">
              <div className="home-wrapper">
                <div className="mb-5">
                  <span className="font-weight-bold font-size-24">Viddy</span>
                </div>

                <Row className="justify-content-center">
                  <Col sm={4}>
                    <div className="maintenance-img">
                      <img
                        src={maintenance}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h3 className="mt-5">Sistema em manutenção!</h3>
                <p>Por favor, tente acessar novamente mais tarde.</p>

                <Row>
                  <CardMaintenance>
                    <i className="bx bx-broadcast mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Por que o sistema está em manutenção?
                    </h5>
                    <p className="text-muted mb-0">
                      Estamos fazendo melhorias para aprimorar a experiência.
                    </p>
                  </CardMaintenance>

                  <CardMaintenance>
                    <i className="bx bx-time-five mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Por quanto tempo?
                    </h5>
                    <p className="text-muted mb-0">2 horas.</p>
                  </CardMaintenance>

                  <CardMaintenance>
                    <i className="bx bx-stopwatch mb-4 h1 text-primary" />
                    <h5 className="font-size-15 text-uppercase">
                      Como proceder?
                    </h5>
                    <p className="text-muted mb-0">
                      Aguarde o retorno do sistema.
                    </p>
                  </CardMaintenance>
                </Row>
                <Button
                  color="secondary"
                  size="md"
                  className="d-flex"
                  style={{ margin: 'auto 0 auto auto' }}
                  onClick={() => dispatch(logoutUser())}
                >
                  Sair do sistema
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default PagesMaintenance;
