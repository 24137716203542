export const SET_LOADING = '@shareable_cart/SET_LOADING';

export const LOAD_CART = '@shareable_cart/LOAD_CART';
export const SET_CART = '@shareable_cart/SET_CART';
export const UPDATE_CART = '@shareable_cart/UPDATE_CART';

export const PATCH_CART = '@shareable_cart/PATCH_CART';
export const SET_IS_FOR_DELIVERY = '@shareable_cart/SET_IS_FOR_DELIVERY';

export const SET_PAYMENT_TYPE = '@shareable_cart/SET_PAYMENT_TYPE';
export const SET_PAYMENT_DATA = '@shareable_cart/SET_PAYMENT_DATA';
export const SET_PIX_DATA = '@shareable_cart/SET_PIX_DATA';
export const SET_PIX_DATA_SUCCESS = '@shareable_cart/SET_PIX_DATA_SUCCESS';

export const SET_STEP = '@shareable_cart/SET_STEP';
export const SET_ACTIVE_TAB = '@shareable_cart/SET_ACTIVE_TAB';

export const SET_FINISHED_SUCCESSFULLY =
  '@shareable_cart/SET_FINISHED_SUCCESSFULLY';

export const SAVE_CLIENT = '@shareable_cart/SAVE_CLIENT';
export const UPDATE_CLIENT = '@shareable_cart/UPDATE_CLIENT';
export const SET_CLIENT = '@shareable_cart/SET_CLIENT';

export const FINALIZE = '@shareable_cart/FINALIZE';

export const SET_OBSERVATION = '@shareable_cart/SET_OBSERVATION';
export const SET_SHOW_CIELO_MODAL = '@shareable_cart/SET_SHOW_CIELO_MODAL';
export const SET_CIELO_AUTH_URL = '@shareable_cart/SET_CIELO_AUTH_URL';
export const SET_IP = '@shareable_cart/SET_IP';
