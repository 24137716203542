import React from 'react';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';

export default function ReferralCodeCard({
  code,
  isSelected,
  onSelect,
  onDelete,
  onGenerateReport,
  isAllowedToBatch,
}) {
  return (
    <>
      <Card
        className={`card new-layout table-card ${code.border_class} d-none d-lg-block`}
      >
        <CardBody className="card-body new-layout px-3 py-3">
          <Row className="w-100 h-100 mx-0 d-none d-lg-flex">
            <Col xs={1} align="center" className="h-100">
              {isAllowedToBatch && (
                <div
                  className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap outline-none"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label="checkbox-wrapper-div"
                >
                  <CustomInput
                    type="checkbox"
                    id={`${code.id}-checkbox`}
                    className="text-primary z-index-0"
                    checked={isSelected}
                    defaultValue={isSelected}
                    onChange={() => onSelect()}
                  />
                </div>
              )}
            </Col>
            <Col xs={10} className="px-0">
              <Row className="w-100 mx-0">
                <Col xs={2} align="start">
                  <div className="new-layout-card-list-strong-value h-100 text-truncate">
                    {code.code}
                  </div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-card-list-half-strong-value h-100 text-truncate">
                    {code.name}
                  </div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-value h-100 d-flex text-truncate">
                    {code.formatted_carts_count}
                  </div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-value h-100 d-flex text-truncate">
                    {code.formatted_carts_amount}
                  </div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-value h-100 d-flex text-truncate">
                    {code.formatted_carts_month_count}
                  </div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-value h-100 d-flex text-truncate">
                    {code.formatted_carts_month_amount}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={1} align="start">
              <Row className="w-100 mx-0 d-flex flex-row justify-content-around">
                <IconButton
                  icon="las la-book"
                  color="info"
                  id={`report-btn-${code.id}`}
                  tip="Relatório do dia"
                  forceTip
                  click={() => {
                    onGenerateReport();
                  }}
                />
                <IconButton
                  icon="las la-trash"
                  color="danger"
                  id={`delete-btn-${code.id}`}
                  tip="Excluir"
                  forceTip
                  click={() => onDelete()}
                />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card new-layout list-card store d-lg-none">
        <CardBody className="card-body new-layout px-3 py-3">
          <Row className="w-100 mx-0">
            <Col xs={9} className="code-mobile-card-title">
              {code.code}
            </Col>
            <Col xs={3}>
              <Row className="w-100 mx-0">
                <Col xs={6} className="px-0" align="center">
                  <IconButton
                    icon="las la-book"
                    color="info"
                    id={`report-mobile-btn-${code.id}`}
                    tip="Relatório do dia"
                    click={() => {
                      onGenerateReport();
                    }}
                  />
                </Col>
                <Col xs={6} className="px-0" align="center">
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`delete-mobile-btn-${code.id}`}
                    tip="Excluir"
                    click={() => onDelete()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Nome:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {code.name}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Usos Totais:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {code.formatted_carts_count}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Valor Total:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {code.formatted_carts_amount}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Usos no Mês:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {code.formatted_carts_month_count}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col>
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label d-inline">
                  Valor no Mês:
                </div>
                <div className="new-layout-card-mobile value d-inline text-truncate">
                  {code.formatted_carts_month_amount}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
