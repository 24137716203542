import React from 'react';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import history from '~/services/history';

export default function SupportProfileCard({
  support,
  isSelected,
  onSelect,
  onDelete,
  isAllowedToBatch,
}) {
  return (
    <Card className="card new-layout list-card grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 h-100 mx-0 d-none d-md-flex">
          <Col xs={1} align="center" className="h-100">
            {isAllowedToBatch && (
              <div
                className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap d-none outline-none"
                onClick={e => {
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                aria-label="checkbox-wrapper-div"
              >
                <CustomInput
                  type="checkbox"
                  id={`${support.id}-checkbox`}
                  className="text-primary"
                  checked={isSelected}
                  defaultValue={isSelected}
                  onChange={() => onSelect(support, isSelected)}
                />
              </div>
            )}
          </Col>
          <Col
            xs={3}
            align="start"
            className="h-100 d-flex flex-column justify-content-center"
          >
            {support.broken_name.map((word, index) => {
              return (
                <div
                  key={`${word}-${support.id}`}
                  className={`new-layout-card-list-strong-value w-100 ${
                    index > 0 && 'text-truncate'
                  }`}
                >
                  {word}
                </div>
              );
            })}
          </Col>
          <Col
            xs={3}
            align="start"
            className="h-100 d-flex flex-column justify-content-center"
          >
            {support.email ? (
              <>
                {support.broken_email.map((word, index) => {
                  return (
                    <div
                      key={`${word}-${support.id}`}
                      className={`new-layout-table-column-value ${
                        index > 0 && 'text-truncate'
                      }`}
                    >
                      {`${index === 1 ? '@' : ''}${word}`}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="new-layout-table-column-value h-100 text-truncate">
                Não informado
              </div>
            )}
          </Col>
          <Col xs={3} align="center" className="h-100">
            <div className="new-layout-table-column-value h-100 text-truncate">
              {support.telephone || 'Não informado'}
            </div>
          </Col>
          <Col xs={2} align="center" className="h-100">
            <div className="h-100 d-flex flex-row align-items-center justify-content-around">
              <IconButton
                icon="las la-search"
                color="info"
                id={`details-btn-${support.id}`}
                tip="Detalhes"
                forceTip
                click={() => {
                  history.push(`/suportes/perfil/${support.id}`);
                }}
              />

              <IconButton
                icon="las la-pen"
                color="primary"
                id={`edit-btn-${support.id}`}
                tip="Editar"
                forceTip
                click={() => {
                  history.push(`/suportes/perfil/${support.id}/editar`);
                }}
              />
              <IconButton
                icon="las la-trash"
                color="danger"
                id={`delete-btn-${support.id}`}
                tip="Excluir"
                forceTip
                click={() => onDelete(support)}
              />
            </div>
          </Col>
        </Row>
        <Row className="w-100 h-100 mx-0 d-md-none">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col xs={9} align="start" className="px-0">
                <Row className="w-100 mx-0">
                  <div className="d-flex flex-row align-items-baseline w-100">
                    <div className="new-layout-card-mobile label d-inline">
                      Cliente:
                    </div>
                    <div
                      className="new-layout-card-list-strong-value ml-1 d-inline text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {support.name}
                    </div>
                  </div>
                </Row>
              </Col>
              <Col xs={3} className="px-0" align="end">
                <div className="h-100 d-flex flex-row justify-content-around">
                  <IconButton
                    icon="las la-search"
                    color="info"
                    id={`details-mobile-btn-${support.id}`}
                    tip="Detalhes"
                    click={() => {
                      history.push(`/suportes/perfil/${support.id}`);
                    }}
                  />

                  <IconButton
                    icon="las la-pen"
                    color="primary"
                    id={`edit-mobile-btn-${support.id}`}
                    tip="Editar"
                    click={() => {
                      history.push(`/suportes/perfil/${support.id}/editar`);
                    }}
                  />
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`delete-mobile-btn-${support.id}`}
                    tip="Excluir"
                    click={() => onDelete(support)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    E-mail:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {support.email || 'Não informado'}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Telefone:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {support.telephone || 'Não informado'}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Nascimento:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {support.birth_date || 'Não informado'}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
