import React, { useEffect, useState } from 'react';
import { Col, CustomInput, Label } from 'reactstrap';
import Input from '../Inputs/Input';

export default function NumberInput({ hideLabel, number, formRef, onChange }) {
  const [noNumber, setNoNumber] = useState(false);

  useEffect(() => {
    if (number === 'sn') {
      setNoNumber(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    if (noNumber) {
      formRef?.current?.setFieldValue('number', 'sn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noNumber]);

  return (
    <Col className="px-0">
      <CustomInput
        type="switch"
        id="noNumberSwitch"
        name="noNumber"
        label="Sem Número"
        checked={noNumber}
        className="text-primary float-right"
        onChange={() => setNoNumber(!noNumber)}
      />
      {!hideLabel && <Label>Número</Label>}
      <Input
        name="number"
        placeholder="Número"
        type={noNumber ? 'text' : 'number'}
        disabled={noNumber}
        onChange={onChange}
        value={noNumber ? 'sn' : number}
      />
    </Col>
  );
}
