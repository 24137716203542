import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';

const CustomerDataCard = ({ cart, customer }) => {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const maskVerify = false;

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row className="w-100 mx-0 justify-content-between">
          <Col
            className="w-100 font-size-16 font-weight-600 d-flex align-items-start px-0"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Row className="w-100 ml-0 ml-lg-2 mr-0 cursor-pointer justify-content-between align-items-center">
              <div className="px-0 mb-0">Cliente</div>
            </Row>
            <IconButton
              icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
              click={() => {
                setExpanded(!expanded);
              }}
              color="gray-700"
            />
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 ml-lg-2 mr-4 mb-lg-4 mt-3">
            <Col className="pl-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Nome
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {maskVerify
                  ? '********'
                  : customer?.name || cart.client?.name || '-'}
              </div>
            </Col>
            <Col className="px-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Telefone
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {maskVerify
                  ? '********'
                  : customer?.telephone || cart.client?.telephone || '-'}
              </div>
            </Col>
            <Col className="px-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                E-mail
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block pr-2">
                {maskVerify
                  ? '********'
                  : customer?.email || cart.client?.email || '-'}
              </div>
            </Col>
            <Col className="px-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                CPF
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {maskVerify
                  ? '********'
                  : customer?.cpf || cart.client?.cpf || '-'}
              </div>
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default CustomerDataCard;
