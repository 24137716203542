import React, { useRef } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import { toolbarConfig } from '~/util/textToolbarConfig';
import InputExternal from '~/components/Inputs/Input';
import api from '~/services/api';

export default function EditAnnouncement({
  show,
  setShow,
  setData,
  announcementToBeEdited,
  setAnnouncementToBeEdited,
  value,
  setValue,
  getURL,
}) {
  const formRef = useRef(null);

  const handleEditAnnouncement = async formData => {
    try {
      formRef.current.setErrors({});
      const description = value.toString('html');

      const schema = Yup.object().shape({
        title: Yup.string().required('Digite o título do Anúncio'),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      formData = {
        id: announcementToBeEdited.id,
        description,
        ...formData,
      };

      await api.put(
        `administrator/announcements/${announcementToBeEdited?.id}`,
        formData
      );

      const { data } = await api.get(getURL);

      setData(data);
      setShow(false);
      setValue(RichTextEditor.createEmptyValue());

      setAnnouncementToBeEdited(null);

      toast.success('Anúncio editado com sucesso!');
    } catch (err) {
      setShow(false);
      setAnnouncementToBeEdited(null);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Ocorreu um erro. Tente novamente.');
      }
    }
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader
        toggle={() => {
          setShow(false);
          setAnnouncementToBeEdited(null);
        }}
      >
        Editar Anúncio
      </ModalHeader>
      <Form
        ref={formRef}
        onSubmit={handleEditAnnouncement}
        initialData={announcementToBeEdited}
      >
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Título</Label>
                <InputExternal
                  name="title"
                  type="text"
                  placeholder="Qual o título do Anúncio?"
                />
              </FormGroup>
              <FormGroup>
                <Label>Descrição</Label>
                <RichTextEditor
                  name="description"
                  value={value}
                  onChange={a => setValue(a)}
                  toolbarConfig={toolbarConfig}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="new-layout-btn main" color="primary">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
