import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { CustomInput } from 'reactstrap';

const InputCheckbox = ({ name, configurationCard, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (ref, value) => {
        if (value !== undefined) {
          ref.checked = value;
          clearError();
        }
      },
    });
  }, [clearError, fieldName, registerField]);

  return (
    <>
      <CustomInput
        innerRef={inputRef}
        id={fieldName}
        type="switch"
        checked={defaultValue}
        className={`text-primary ${!configurationCard ? 'mb-2' : ''}`}
        {...rest}
      />
      {error && <span className={error && 'text-danger'}>{error}</span>}
    </>
  );
};

export default InputCheckbox;
