/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
  CustomInput,
} from 'reactstrap';

import { Scope } from '@unform/core';
import { ValidationError } from 'yup';
import { toast, Flip } from 'react-toastify';
import { isDate, parse, isAfter } from 'date-fns';
import api from '~/services/api';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
import SchemaValidator from '~/validators/form/register_customer';
import { maskBuilder } from '~/util/maskTelephone';
import { stateList } from '~/util/stateList';
import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';

const NewCustomer = () => {
  const formRef = useRef(null);
  const toastId = useRef(null);
  const [cityList, setCityList] = useState([]);

  const [searchedCEP, setSearchedCEP] = useState('');
  const [telephoneV, setTelephone] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [ignoreClientAddress, setIgnoreClientAddress] = useState(false);
  const [noNumber, setNoNumber] = useState(false);
  const [addressNumber, setAddressNumber] = useState(null);

  useEffect(() => {
    document.title = 'Registro de Clientes | Viddy';
  }, []);

  const isBirthDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'birth_date',
        'Data de nascimento não pode ser no futuro'
      );
      return false;
    }

    return true;
  };

  const handleValidSubmit = async data => {
    try {
      formRef.current.setErrors({});

      if (data.birth_date) {
        if (!isBirthDateValid(data.birth_date)) return;
      } else {
        delete data.birth_date;
      }

      data = {
        ...data,
        address: {
          ...data.address,
          state: selectedState?.value,
          city: selectedCity?.value,
        },
      };

      if (ignoreClientAddress) {
        delete data.address;
      }

      const maskTelephoneLength = maskBuilder(telephoneV).length;

      await SchemaValidator(
        maskTelephoneLength,
        ignoreClientAddress,
        data.birth_date
      ).validate(data, {
        abortEarly: false,
      });

      await api.post(`administrator/clients`, data);

      toast.success('Cliente cadastrado com sucesso!');

      history.push(`/clientes`);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
        autoClose: false,
      });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();

      toast.update(toastId.current, {
        render: 'Endereço encontrado!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });
      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  useEffect(() => {
    if (noNumber) {
      setAddressNumber('sn');
    }
  }, [noNumber]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="CADASTRAR CLIENTE"
          backTo="/clientes"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form
          id="client-form"
          ref={formRef}
          onSubmit={handleValidSubmit}
          noValidate
        >
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Dados do Cliente
              </Row>
              <Row className="w-100 mx-0">
                <Col className="px-0 ">
                  <Label>Nome</Label>
                  <FormGroup>
                    <Input
                      name="name"
                      placeholder="Nome"
                      type="text"
                      tabIndex={1}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>CPF</Label>
                  <FormGroup>
                    <InputMask
                      name="cpf"
                      placeholder="CPF"
                      mask="999.999.999-99"
                      maskChar={null}
                      type="text"
                      tabIndex={3}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <Label>Data de Nascimento</Label>
                  <FormGroup>
                    <InputMask
                      name="birth_date"
                      placeholder="Data de Nascimento"
                      mask="99/99/9999"
                      maskChar={null}
                      onBlur={e => isBirthDateValid(e.target.value)}
                      type="text"
                      tabIndex={5}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-3">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>E-mail</Label>
                  <FormGroup>
                    <Input
                      onBlur={() => {}}
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      tabIndex={2}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <Label>Telefone</Label>
                  <FormGroup>
                    <InputMask
                      mask={maskBuilder(telephoneV)}
                      maskChar={null}
                      defaultValue={telephoneV}
                      name="telephone"
                      placeholder="Telefone"
                      type="text"
                      onChange={e => setTelephone(e.target.value)}
                      tabIndex={4}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="w-100 mx-0 mb-4">
                <Col lg="auto" className="px-0 pr-lg-2 mb-2 mb-lg-0">
                  <CustomInput
                    type="switch"
                    id="ignore_client_address"
                    name="ignore_client_address"
                    label="Dispensar endereço do cliente"
                    className="text-primary z-index-0 mb-2 mb-lg-0"
                    checked={ignoreClientAddress}
                    onChange={() =>
                      setIgnoreClientAddress(!ignoreClientAddress)
                    }
                  />
                </Col>
              </Row>

              {!ignoreClientAddress && (
                <Scope path="address">
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Endereço
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup>
                        <Label>CEP</Label>
                        <InputMask
                          name="cep"
                          placeholder="CEP"
                          mask="99999-999"
                          maskChar={null}
                          onChange={e =>
                            e.target.value.length === 9 && searchCEP()
                          }
                          tabIndex={6}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Logradouro</Label>
                        <Input
                          name="street_name"
                          placeholder="Logradouro"
                          type="text"
                          tabIndex={7}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <Label>Número</Label>

                      <CustomInput
                        type="switch"
                        id="noNumberSwitch"
                        name="noNumber"
                        label="Sem Número"
                        checked={noNumber}
                        className="text-primary float-right"
                        onChange={() => setNoNumber(!noNumber)}
                      />
                      <FormGroup>
                        <Input
                          name="number"
                          placeholder="Número"
                          type={noNumber ? 'text' : 'number'}
                          disabled={noNumber}
                          onChange={({ target: { value } }) =>
                            setAddressNumber(value)
                          }
                          value={addressNumber}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Número</Label>
                        <Input
                          name="number"
                          placeholder="Número"
                          type="text"
                          tabIndex={8}
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Bairro</Label>
                        <Input
                          name="neighborhood"
                          placeholder="Bairro"
                          type="text"
                          tabIndex={9}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <TextAreaWithCounter
                        name="complement"
                        placeholder="Complemento"
                        title="Complemento"
                        maxSize={128}
                        tabIndex={10}
                      />
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Estado</Label>
                        <InputSelect
                          name="state"
                          options={stateList}
                          onChange={selectedOption =>
                            setSelectedState(selectedOption)
                          }
                          value={selectedState}
                          placeholder="Estado"
                          singlePath
                          tabIndex={11}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Cidade</Label>
                        <InputSelect
                          name="city"
                          options={cityList}
                          onChange={selectedOption =>
                            setSelectedCity(selectedOption)
                          }
                          value={selectedCity}
                          placeholder="Cidade"
                          isDisabled={!selectedState}
                          singlePath
                          tabIndex={12}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <TextAreaWithCounter
                        name="additional_information"
                        placeholder="Informações Adicionais"
                        title="Informações Adicionais"
                        maxSize={128}
                        tabIndex={13}
                      />
                    </Col>
                  </Row>
                </Scope>
              )}
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
                tabIndex={14}
              >
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default NewCustomer;
