import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '~/services/api';
import PageHeader from '~/components/Common/PageHeader';
import Loading from '~/components/Loading';

const SupportProfile = () => {
  const history = useHistory();
  const { support_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [support, setSupport] = useState(null);

  useEffect(() => {
    const loadSupport = async () => {
      try {
        const { data } = await api.get(`/master/support-users/${support_id}`);

        document.title = `${data.user.name} | Viddy`;

        const formattedSupport = {
          ...data.user,
        };
        setSupport(formattedSupport);

        setLoading(false);
      } catch (err) {
        history.goBack();
        console.log(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro inesperado.');
        }
      }
    };
    loadSupport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [support_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="DETALHES DO SUPORTE"
              backTo="/suportes"
              responsivePosition="start"
              desktopPosition="center"
            />

            <Card className="card new-layout">
              <CardBody className="card-body new-layout">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Dados do Suporte
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col lg={6} className="px-0 pl-lg-4 pr-lg-2">
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">Nome</Label>
                        <div className="new-layout-page-value">
                          {support.name}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">Telefone</Label>
                        <div className="new-layout-page-value">
                          {support.telephone || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2 pr-lg-4">
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">E-mail</Label>
                        <div className="new-layout-page-value">
                          {support.email || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row className="w-100 mx-0 my-4 ">
              <Col align="end" className="px-0">
                <Button
                  className="new-layout-btn main"
                  color="primary"
                  size="lg"
                  onClick={() => history.push(`${support_id}/editar`)}
                >
                  Editar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default SupportProfile;
