// import React, { useMemo, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, NavLink, Row } from 'reactstrap';
import logo from '~/assets/images/logo-white.svg';
// import Notifications from '~/components/HeaderComponents/Notifications';
// import HeaderSearchBar from '~/components/HeaderComponents/SearchBar';
// import StorePicker from '~/components/HeaderComponents/StorePicker';
// import UnreadMsgs from '~/components/HeaderComponents/UnreadMsgs';
// import IconButton from '~/components/IconButton';
// import useRoles from '~/hooks/useRoles';
import history from '~/services/history';
import { toggleSidebar } from '~/store/modules/layout/actions';
import IconButton from '~/components/IconButton';

export default function MobileHeader({ saleDetails, arrowAction, pageTitle }) {
  const dispatch = useDispatch();

  // const user = useSelector(({ user: User }) => User.profile);

  // const showMobileSearchBar = useSelector(({ layout }) => layout.showSearchBar);

  // const {
  //   isSalesman,
  //   isStockist,
  //   isStoreManager,
  //   isConsultant,
  //   isAdmin,
  // } = useRoles();

  // const [showMobileStorePicker, setShowMobileStorePicker] = useState(false);

  // const storePickerAvailable = useMemo(
  //   () =>
  //     (isStoreManager || isStockist || isSalesman || isConsultant) &&
  //     user.managed_stores.length > 1,

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isStoreManager, isStockist, isSalesman, isConsultant, user]
  // );

  // const canSearch = useMemo(
  //   () =>
  //     (isAdmin || isSalesman || isStockist || isStoreManager || isConsultant) &&
  //     user.managed_stores.length >= 1,
  //   [isSalesman, isStockist, isStoreManager, isConsultant, isAdmin, user]
  // );

  const toggleMenu = () => {
    dispatch(toggleSidebar());
  };

  if (saleDetails) {
    return (
      <Row
        className="relative header-row w-100 mx-0 pr-xl-4 d-xl-none bg-primary"
        style={{ height: '4rem' }}
      >
        <Col
          xs={5}
          className="d-xl-none"
          onClick={() => {
            if (!arrowAction) {
              history.push('/');
              return;
            }
            arrowAction();
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              left: '3rem',
              top: '0.75rem',
              zIndex: '1000000',
            }}
            icon="las la-angle-left"
            iconStyle={{
              transform: 'scale(1.75)',
              margin: '0.5rem',
            }}
            onClick={() => {
              if (!arrowAction) {
                history.push('/');
                return;
              }
              arrowAction();
            }}
            color="white"
          />
        </Col>
        <h2
          className="py-2 mb-0 w-100 text-center text-white font-size-18 font-weight-600 d-flex align-items-center justify-content-center"
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
          }}
        >
          {pageTitle}
        </h2>
      </Row>
    );
  }

  return (
    <Row className="header-row w-100 mx-0 pr-xl-4 d-xl-none bg-primary">
      <Col xs={4} className="d-xl-none pt-3">
        <NavLink className="p-0" onClick={() => history.push('/')}>
          <img src={logo} alt="logo" className="h-30px" />
        </NavLink>
      </Col>
      <Col className="px-0" xs={8}>
        <Row className="w-100 h-100 mx-0 justify-content-end">
          {/* <Col
            className="d-flex flex-column justify-content-center align-items-end pr-4"
            xs={10}
          >
            <StorePicker mobile />
          </Col> */}
          <Col xs={2}>
            <Row className="w-100 mx-0 justify-content-end">
              {/* {canSearch && (
                <Col
                  xs={canSearch ? 3 : 6}
                  className="px-0 w-fit-content d-flex flex-column justify-content-center"
                >
                  {storePickerAvailable && (
                    <IconButton
                      id="header-store-picker-btn"
                      icon={`las la-${
                        showMobileStorePicker ? 'times' : 'store'
                      }`}
                      color="gray-300"
                      click={() => {
                        if (!showMobileStorePicker && showMobileSearchBar) {
                          dispatch(setShowSearch(false));
                        }
                        setShowMobileStorePicker(!showMobileStorePicker);
                      }}
                      fontSize={30}
                      tip={`${
                        showMobileStorePicker ? 'Esconder' : 'Mostrar'
                      } seletor de loja`}
                      forceTip
                      // iconClass='cursor-default'
                    />
                  )}
                </Col>
              )} */}
              {/* <Col
                xs={3}
                className="px-0 w-fit-content d-flex flex-column justify-content-center"
              >
                <IconButton
                  id="header-search-btn"
                  icon={`las la-${showMobileSearchBar ? 'times' : 'search'}`}
                  color="gray-300"
                  click={() => {
                    if (showMobileStorePicker) {
                      setShowMobileStorePicker(false);
                    }
                    dispatch(setShowSearch(!showMobileSearchBar));
                  }}
                  fontSize={30}
                  tip={`${
                    showMobileSearchBar ? 'Esconder' : 'Mostrar'
                  } barra de pesquisa`}
                  forceTip
                  // iconClass='cursor-default'
                />
                <IconButton
                  id="header-search-btn"
                  icon="las la-comments"
                  color="gray-300"
                  click={() => {
                    if (!showMobileSearchBar && showMobileStorePicker) {
                      setShowMobileStorePicker(false);
                    }
                    dispatch(setShowSearch(!showMobileSearchBar));
                  }}
                  fontSize={30}
                  tip={`${
                    showMobileSearchBar ? 'Esconder' : 'Mostrar'
                  } barra de pesquisa`}
                  forceTip
                  // iconClass='cursor-default'
                />
              </Col> */}
              {/* <Col
                xs={3}
                className="px-0 w-fit-content d-flex flex-column justify-content-center"
              >
                <Notifications
                  size={30}
                  color="gray-300"
                  badgeMarginTop={-35}
                />
                <UnreadMsgs
                  mobile
                  size={30}
                  color="gray-300"
                  badgeMarginTop={-35}
                />
              </Col> */}
              <Col
                className="px-0 w-fit-content d-flex flex-column justify-content-center align-items-end"
                align="end"
                xs={3}
              >
                <button
                  type="button"
                  className="btn btn-sm font-size-24 px-0 d-xl-none header-item waves-effect waves-light"
                  data-toggle="collapse"
                  onClick={toggleMenu}
                  data-target="#topnav-menu-content"
                >
                  <i className="fa fa-fw fa-bars text-gray-300" />
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
