// General Auth Pages
import Sales from '~/pages/Sales';
import Sale from '~/pages/Sale';
import Stock from '~/pages/Stock';
// import Stocks from '~/pages/Stocks';
import Search from '~/pages/Search';
import SalesRegister from '~/pages/SalesRegister';
import ManagerStore from '~/pages/ManagerStore';
import MultipleStock from '~/pages/Stock/MultipleStock';
import MyProfile from '~/pages/MyProfile';
import Chat from '~/pages/Chat';

// Digital Consultant

// import DigitalConsultantSales from '~/pages/Sales/DigitalConsultant';
// import DigitalConsultantSale from '~/pages/Sale/DigitalConsultant';
// import DigitalConsultantSalesRegister from '~/pages/SalesRegister/DigitalConsultant';

// import DcMultipleStock from '~/pages/Stock/DigitalConsultant/MultipleStock';

// import DigitalConsultantStocks from '~/pages/Stocks/DigitalConsultant';
// import DigitalConsultantStock from '~/pages/Stock/DigitalConsultant';
// import DcEditData from '~/pages/MyProfile/DigitalConsultant';

// import DcSearch from '~/pages/Search/DigitalConsultant';

// Administrator
import Dashboard from '~/pages/Dashboard';
import Users from '~/pages/Users';
import Customers from '~/pages/Customers';
import NewCustomer from '~/pages/Customers/NewCustomer';
import CustomerProfile from '~/pages/Customers/CustomerProfile';
import EditCustomerProfile from '~/pages/Customers/EditCustomerProfile';
import NewUser from '~/pages/Users/NewUser';
import UserProfile from '~/pages/Users/UserProfile';
import EditUserProfile from '~/pages/Users/EditUserProfile';
import Products from '~/pages/Products';
import AddProduct from '~/pages/Products/AddProduct';
import EditProduct from '~/pages/Products/EditProduct';
import Stores from '~/pages/Stores';
import Store from '~/pages/Stores/StoreDetails';
import AddStore from '~/pages/Stores/AddStore';
import EditStore from '~/pages/Stores/EditStore';
import ReferralCodes from '~/pages/ReferralCodes';
import FAQ from '~/pages/Faq';
import Announcements from '~/pages/Announcements';
import Notifications from '~/pages/Notifications';
import Configurations from '~/pages/Configurations';
import DeliveryGroups from '~/pages/DeliveryGroups';

import SharedCart from '~/pages/SharedCart';

// Authentication related pages
import Login from '~/pages/Authentication/Login';
import Logout from '~/pages/Authentication/Logout';
import ForgetPwd from '~/pages/Authentication/ForgetPassword';
import ResetPassword from '~/pages/Authentication/ResetPassword';
import Register from '~/pages/Authentication/Register';

import Subscription from '~/pages/Subscription';
import FinancierDashboard from '~/pages/FinancierDashboard';
import MasterDashboard from '~/pages/MasterDashboard';
import Subscribers from '~/pages/Subscribers';
import NewSubscriber from '~/pages/Subscribers/NewSubscriber';
import SubscriberProfile from '~/pages/Subscribers/SubscriberProfile';
import EditSubscriber from '~/pages/Subscribers/EditSubscriber';
// import PixDetails from '~/pages/SharedCart/PixDetails';
import Pushs from '~/pages/PushNotifications';
import Supports from '~/pages/Support';
import NewSupport from '~/pages/Support/NewSupport';
import SupportProfile from '~/pages/Support/SupportProfile';
import EditSupportProfile from '~/pages/Support/EditSupportProfile';
import SupportPage from '~/pages/SupportPage';
// import ActionLogs from '~/pages/ActionLogs';
import BusinessCard from '~/pages/BusinessCard';
// import CustomersWallet from '~/pages/CustomersWallet';
import CustomerWallet from '~/pages/CustomerWallet';
import NewWalletCustomer from '~/pages/NewWalletCustomer';
import EditCustomer from '~/pages/CustomersWallet/EditCustomerForm';
import CustomersWalletReport from '~/pages/CustomersWalletReport';

import SplitReceivers from '~/pages/SplitReceivers';
import EditSplitReceivers from '~/pages/SplitReceivers/EditSplitReceivers';
import NewSplitReceivers from '~/pages/SplitReceivers/NewSplitReceivers';
import AddCustomerContact from '~/pages/NewWalletCustomer/AddCustomerContact';
import ConfirmCustomer from '~/pages/NewWalletCustomer/ConfirmCustomer';

const authProtectedRoutes = [
  {
    path: '/',
    component: Sales,
    needSalesmanRole: true,
  },
  {
    path: '/carteira-de-clientes/novo-cliente',
    component: AddCustomerContact,
  },
  {
    path: '/carteira-de-clientes',
    component: Sales,
    needSalesmanRole: true,
  },
  {
    path: '/venda/:id',
    component: Sale,
    needSalesmanRole: true,
    needFinancierRole: true,
  },
  {
    path: '/registrar/:id?',
    component: SalesRegister,
    needSalesmanRole: true,
  },

  // { path: '/estoque', component: Stocks, needStockistRole: true },
  { path: '/pedido/:id', component: Stock, needStockistRole: true },
  {
    path: '/unificar-entregas',
    component: MultipleStock,
    needStockistRole: true,
  },
  // {
  //   path: '/consultor/unificar-entregas',
  //   component: DcMultipleStock,
  //   onlyConsultant: true,
  // },
  { path: '/busca/:search_id', component: Search },
  { path: '/cartao-de-visitas', component: BusinessCard },
  { path: '/relatorio-de-clientes', component: CustomersWalletReport },
  {
    path: '/carteira-de-clientes/:customer_id/editar',
    component: EditCustomer,
  },
  { path: '/carteira-de-clientes/:customer_id', component: CustomerWallet },
  // { path: '/carteira-de-clientes', component: CustomersWallet },

  { path: '/recebedores-split', component: SplitReceivers },
  {
    path: '/recebedores-split/:receiver_id/editar',
    component: EditSplitReceivers,
  },
  { path: '/recebedores-split/registrar', component: NewSplitReceivers },
  // {
  //   path: '/consultor/busca/:search_id',
  //   component: DcSearch,
  //   onlyConsultant: true,
  // },
  { path: '/editar-dados', component: MyProfile },
  { path: '/chat', component: Chat },

  // digital consultant
  // {
  //   path: '/consultor/',
  //   component: DigitalConsultantSales,
  //   onlyConsultant: true,
  // },
  // {
  //   path: '/consultor/venda/:id',
  //   component: DigitalConsultantSale,
  //   onlyConsultant: true,
  // },
  // {
  //   path: '/consultor/registrar/:id?',
  //   component: DigitalConsultantSalesRegister,
  //   onlyConsultant: true,
  // },
  // {
  //   path: '/consultor/estoque',
  //   component: DigitalConsultantStocks,
  //   onlyConsultant: true,
  // },
  // {
  //   path: '/consultor/pedido/:id',
  //   component: DigitalConsultantStock,
  //   onlyConsultant: true,
  // },
  // {
  //   path: '/consultor/editar-dados',
  //   component: DcEditData,
  //   onlyConsultant: true,
  // },

  // clients
  { path: '/clientes', component: Customers, onlyAdmin: true },
  { path: '/clientes/registrar', component: NewCustomer, onlyAdmin: true },
  {
    path: '/clientes/perfil/:customer_id',
    component: CustomerProfile,
    onlyAdmin: true,
  },
  {
    path: '/clientes/perfil/:customer_id/editar',
    component: EditCustomerProfile,
    onlyAdmin: true,
  },

  // administrator
  {
    path: '/usuarios',
    component: Users,
    onlyAdmin: true,
    adminAndManagerRoute: true,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    onlyAdmin: true,
  },
  {
    path: '/usuarios/registrar',
    component: NewUser,
    adminAndManagerRoute: true,
  },
  {
    path: '/usuarios/perfil/:user_id',
    component: UserProfile,
    adminAndManagerRoute: true,
  },
  {
    path: '/usuarios/perfil/:user_id/editar',
    component: EditUserProfile,
    adminAndManagerRoute: true,
  },

  {
    path: '/produtos',
    component: Products,
    onlyAdmin: true,
    adminAndManagerRoute: true,
  },
  { path: '/produtos/adicionar', component: AddProduct, onlyAdmin: true },
  {
    path: '/produtos/:product_id/editar',
    component: EditProduct,
    onlyAdmin: true,
  },

  { path: '/lojas', component: Stores, onlyAdmin: true },
  { path: '/lojas/adicionar', component: AddStore, onlyAdmin: true },
  { path: '/lojas/:store_id', component: Store, onlyAdmin: true },
  { path: '/lojas/:store_id/editar', component: EditStore, onlyAdmin: true },
  { path: '/grupos-de-entrega', component: DeliveryGroups, onlyAdmin: true },
  { path: '/codigos-de-indicacao', component: ReferralCodes, onlyAdmin: true },
  { path: '/faq', component: FAQ, onlyAdmin: false },
  { path: '/anuncios', component: Announcements, onlyAdmin: false },
  { path: '/push-notifications', component: Pushs, onlyAdmin: true },
  { path: '/notificacoes', component: Notifications, onlyAdmin: false },
  { path: '/sua-loja', component: ManagerStore, adminAndManagerRoute: true },

  { path: '/configuracoes', component: Configurations, onlyAdmin: true },

  { path: '/financeiro', component: FinancierDashboard, onlyFinancier: true },
  { path: '/master', component: MasterDashboard, onlyMaster: true },
  { path: '/assinantes', component: Subscribers, onlyMaster: true },
  { path: '/cadastrar-assinante', component: NewSubscriber, onlyMaster: true },

  { path: '/suportes', component: Supports, onlyMaster: true },
  { path: '/suportes/registrar', component: NewSupport, onlyMaster: true },
  {
    path: '/suportes/perfil/:support_id',
    component: SupportProfile,
    onlyMaster: true,
  },
  {
    path: '/suportes/perfil/:support_id/editar',
    component: EditSupportProfile,
    onlyMaster: true,
  },

  {
    path: '/suporte',
    component: SupportPage,
    onlySupport: true,
  },

  // {
  //   path: '/logs',
  //   component: ActionLogs,
  //   onlyMaster: true,
  // },

  {
    path: '/assinantes/perfil/:subscriber_id',
    component: SubscriberProfile,
    onlyMaster: true,
  },
  {
    path: '/assinantes/perfil/:subscriber_id/editar',
    component: EditSubscriber,
    onlyMaster: true,
  },
  { path: '/assinatura', component: Subscription },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forget-password', component: ForgetPwd },
  { path: '/reset-password', component: ResetPassword },
  { path: '/register', component: Register },
];

const universalRoutes = [
  { path: '/meu-carrinho', component: SharedCart },
  { path: '/cadastro-novo-cliente', component: NewWalletCustomer },
  {
    path: '/carteira-de-clientes/:customer_id/confirmar',
    component: ConfirmCustomer,
  },
];

export { authProtectedRoutes, publicRoutes, universalRoutes };
