import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Input from '~/components/Inputs/Input';
import IconButton from '~/components/IconButton';
import InputCheckbox from '~/components/Inputs/InputCheckbox';
import InputSelect from '~/components/Inputs/InputSelect';
import { gatewayOptions } from '~/util/filters';

export default function ConfigurationCard({
  config,
  index,
  handleSubmit,
  className,
}) {
  const formRef = useRef(null);
  const formRefMobile = useRef(null);
  const [editModeOn, setEditModeOn] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(
    config.name === 'payment_gateway' &&
      gatewayOptions.filter(e => e.value === config.value)
  );

  const width = window.innerWidth;

  return (
    <>
      {width >= 992 ? ( // lg bootstrap breakpoint
        <Card
          className={`card new-layout table-card ${config.border_class} d-none d-lg-block ${className}`}
          style={{ minHeight: '90px' }}
        >
          <CardBody
            className="card-body new-layout h-100 px-3 py-4 d-flex flex-row align-items-center"
            style={{ minHeight: 'inherit' }}
          >
            <Row className="w-100 h-100 mx-0 d-none d-lg-flex align-items-center">
              <Col
                xs={3}
                align="start"
                className="d-flex flex-column justify-content-center"
              >
                <div className="new-layout-card-list-strong-value text-truncate">
                  {config.name}
                </div>
              </Col>
              <Col
                xs={4}
                align="start"
                className="d-flex flex-column justify-content-center"
              >
                <div
                  className={`new-layout-card-list-value h-100 d-flex ${
                    config.description.includes(' ')
                      ? 'flex-wrap'
                      : 'word-break-break-all'
                  }`}
                >
                  {config.description}
                </div>
              </Col>
              <Col
                xs={4}
                align="start"
                className="d-flex flex-column justify-content-center"
              >
                <Form
                  onSubmit={data => {
                    if (config.name === 'payment_gateway') {
                      handleSubmit(data, config, index);
                    } else {
                      setEditModeOn(false);
                      handleSubmit(data, config, index);
                    }
                  }}
                  ref={formRef}
                  initialData={config}
                >
                  {(config.type === 'string' || config.type === 'float') &&
                    config.name !== 'payment_gateway' && (
                      <>
                        {editModeOn ? (
                          <Input
                            name="value"
                            placeholder="Valor da configuração"
                          />
                        ) : (
                          <div className="new-layout-table-column-value h-100 d-flex flex-wrap">
                            {config.value}
                          </div>
                        )}
                      </>
                    )}
                  {config.type === 'array' && (
                    <>
                      {editModeOn ? (
                        <Input
                          name="value"
                          placeholder="Valor da configuração"
                        />
                      ) : (
                        <div className="new-layout-table-column-value h-100 d-flex flex-wrap">
                          {config.value}
                        </div>
                      )}
                    </>
                  )}
                  {config.type === 'boolean' && (
                    <InputCheckbox
                      name="value"
                      checked={config.value}
                      configurationCard
                      onChange={_ => {
                        formRef.current.submitForm();
                      }}
                    />
                  )}
                  {config.name === 'payment_gateway' && (
                    <InputSelect
                      name="option"
                      className="common-select"
                      options={gatewayOptions}
                      onChange={selectedOption => {
                        setSelectedGateway(selectedOption);
                        formRef.current.submitForm();
                      }}
                      value={selectedGateway}
                      placeholder="Gateway switch"
                      singlePath
                    />
                  )}
                </Form>
              </Col>

              <Col
                xs={1}
                align="center"
                className="d-flex flex-column justify-content-center"
              >
                {config.name !== 'maintenance_mode' &&
                config.name !== 'payment_gateway' ? (
                  <>
                    {!editModeOn ? (
                      <Row className="w-100 mx-0 d-flex flex-row justify-content-around">
                        {config.name === 'tracksale_campaign_code' && (
                          <>
                            <i
                              id={`config-${config.id}-comma-tip`}
                              className="las la-info-circle font-size-18"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`config-${config.id}-comma-tip`}
                            >
                              Essa configuração deve ser preenchida com números
                              separados por vírgulas. Exemplo: 8,2,4
                            </UncontrolledTooltip>
                          </>
                        )}
                        <IconButton
                          icon="las la-pen"
                          color="secondary"
                          id={`edit-btn-${config.id}`}
                          tip="Editar"
                          click={() => {
                            setEditModeOn(true);
                          }}
                        />
                      </Row>
                    ) : (
                      <Row className="w-100 mx-0">
                        <Col
                          xs={6}
                          className="h-100 d-flex flex-column justify-content-center px-0"
                        >
                          <IconButton
                            icon="las la-times"
                            color="danger"
                            id={`cancelar-btn-${config?.id}`}
                            tip="Cancelar Edição"
                            click={() => {
                              setEditModeOn(false);
                            }}
                          />
                        </Col>
                        <Col
                          xs={6}
                          className="h-100 d-flex flex-column justify-content-center px-0"
                        >
                          <IconButton
                            icon="las la-save"
                            color="primary"
                            id={`save-btn-${config?.id}`}
                            tip="Salvar"
                            click={() => {
                              formRef.current.submitForm();
                            }}
                            type="submit"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <>
                    <i
                      id={`config-${config.id}-no-actions`}
                      className="las la-info-circle font-size-18"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`config-${config.id}-no-actions`}
                    >
                      Essa configuração não é editável da mesma forma que as
                      demais.
                    </UncontrolledTooltip>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card className="card new-layout list-card store d-lg-none">
          <CardBody className="card-body new-layout px-3 py-3">
            <Row className="w-100 mx-0 mb-2">
              <Col xs={10} className="px-0">
                <Col className="px-0">
                  <div className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-black d-inline mr-2">
                      Nome:
                    </div>
                    <div className="font-size-12 font-weight-700 text-gray-700">
                      {config.name}
                    </div>
                  </div>
                </Col>
              </Col>
              <Col
                xs={2}
                className="px-0 d-flex flex-column align-items-center"
              >
                {config.name !== 'maintenance_mode' ? (
                  <>
                    {!editModeOn ? (
                      <IconButton
                        icon="las la-pen"
                        color="secondary"
                        id={`edit-btn-${config.id}`}
                        tip="Editar"
                        click={() => {
                          setEditModeOn(true);
                        }}
                      />
                    ) : (
                      <Row className="w-100 mx-0">
                        <Col
                          xs={6}
                          className="h-100 d-flex flex-column justify-content-center px-0"
                        >
                          <IconButton
                            icon="las la-times"
                            color="danger"
                            id={`cancelar-btn-${config?.id}`}
                            tip="Cancelar Edição"
                            click={() => {
                              setEditModeOn(false);
                            }}
                          />
                        </Col>
                        <Col
                          xs={6}
                          className="h-100 d-flex flex-column justify-content-center px-0"
                        >
                          <IconButton
                            icon="las la-save"
                            color="primary"
                            id={`save-btn-${config?.id}`}
                            tip="Salvar"
                            click={() => {
                              formRefMobile.current.submitForm();
                            }}
                            type="submit"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <>
                    <i
                      id={`config-${config.id}-no-actions`}
                      className="las la-info-circle font-size-18"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`config-${config.id}-no-actions`}
                    >
                      Essa configuração não é editável da mesma forma que as
                      demais.
                    </UncontrolledTooltip>
                  </>
                )}
              </Col>
            </Row>
            <Row className="w-100 mx-0 mb-2">
              <Col className="px-0">
                <div className="font-size-12 font-weight-600 text-black d-inline mr-2 mb-1">
                  Descrição:
                </div>
                <div className="font-size-12 font-weight-400 text-gray-700">
                  {config.description}
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0 mb-2">
              <Col className="px-0">
                <div className="font-size-12 font-weight-600 text-black mr-2 mb-1">
                  Valor:
                </div>

                <Form
                  onSubmit={data => {
                    setEditModeOn(false);
                    handleSubmit(data, config, index);
                  }}
                  ref={formRefMobile}
                  initialData={config}
                >
                  {(config.type === 'string' || config.type === 'float') &&
                    config.name !== 'payment_gateway' && (
                      <>
                        {editModeOn ? (
                          <Input
                            name="value"
                            placeholder="Valor da configuração"
                          />
                        ) : (
                          <div className="font-size-12 font-weight-300 text-gray-700  text-truncate">
                            {config.value}
                          </div>
                        )}
                      </>
                    )}
                  {config.type === 'array' && (
                    <>
                      {editModeOn ? (
                        <Input
                          name="value"
                          placeholder="Valor da configuração"
                        />
                      ) : (
                        <div className="font-size-12 font-weight-300 text-gray-700 text-truncate">
                          {config.value}
                        </div>
                      )}
                    </>
                  )}
                  {config.type === 'boolean' && (
                    <InputCheckbox
                      name="value"
                      checked={config.value}
                      configurationCard
                      onChange={_ => {
                        formRefMobile.current.submitForm();
                      }}
                    />
                  )}
                  {config.name === 'payment_gateway' && (
                    <InputSelect
                      name="option"
                      className="common-select"
                      options={gatewayOptions}
                      onChange={selectedOption => {
                        setSelectedGateway(selectedOption);
                        formRef.current.submitForm();
                      }}
                      value={selectedGateway}
                      placeholder="Gateway switch"
                      singlePath
                    />
                  )}
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
}
