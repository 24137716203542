import { useSelector } from 'react-redux';
import { formatWhatsappPhoneNumber } from '~/util/format';

export default function WhatsAppSupportButton({ whatsapp, message }) {
  const whatsapp_number = useSelector(
    ({ user }) => whatsapp || user?.profile?.whatsapp_number
  );

  const messageText =
    message ||
    'Olá! Estou com dúvida em relação ao Viddy, você pode me ajudar?';

  return (
    <div
      className={`container-float border-none w-auto ${
        whatsapp_number ? 'd-block' : 'd-none'
      }`}
    >
      {/* <p className="font-size-14 py-2 pl-3 pr-4 text-left">
        Dúvidas? A gente <br />
        te ajuda :)
      </p> */}
      <a
        href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
          whatsapp_number
        )}&text=${messageText}`}
        target="_blank"
        rel="noopener noreferrer"
        className="float"
        id="receiptLink"
      >
        <i className="fab fa-whatsapp my-float" />
      </a>
    </div>
  );
}
