import { combineReducers } from 'redux';

// Auth Reducers
import login from './auth/login/reducer';
import forgetPassword from './auth/forgetpwd/reducer';
import user from './user/reducer';

// Sales Reducer
import sales from './sales/reducer';

// Layout Reducer
import layout from './layout/reducer';

// Cart Reducer
import cart from './cart/reducer';

import chat from './chat/reducer';

import notifications from './notifications/reducer';
import shareable_cart from './shareable_cart/reducer';

import logging from './logging/reducer';
import search from './search/reducer';

export default combineReducers({
  user,
  login,
  forgetPassword,
  sales,
  layout,
  cart,
  chat,
  notifications,
  shareable_cart,
  logging,
  search,
});
