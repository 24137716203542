/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
} from 'reactstrap';

import { ValidationError } from 'yup';
import { toast } from 'react-toastify';
import api from '~/services/api';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';

import SchemaValidator from '~/validators/form/register_support';
import { maskBuilder } from '~/util/maskTelephone';

import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';

const NewSupport = () => {
  const formRef = useRef(null);

  const [telephoneV, setTelephone] = useState('');

  useEffect(() => {
    document.title = 'Registro de Suporte | Viddy';
  }, []);

  const handleValidSubmit = async data => {
    try {
      formRef.current.setErrors({});

      const maskTelephoneLength = maskBuilder(telephoneV).length;

      await SchemaValidator(maskTelephoneLength).validate(data, {
        abortEarly: false,
      });

      await api.post(`master/support-users`, data);

      toast.success('Suporte cadastrado com sucesso!');

      history.push(`/suportes`);
    } catch (err) {
      console.log(err);
      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      if (err?.response?.data?.email) {
        formRef.current.setFieldError('email', err.response.data.email[0]);
      }
    }
  };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="CADASTRAR SUPORTE"
          backTo="/suportes"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form
          id="support-form"
          ref={formRef}
          onSubmit={handleValidSubmit}
          noValidate
        >
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Dados do Suporte
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>Nome</Label>
                  <FormGroup>
                    <Input
                      name="name"
                      placeholder="Nome"
                      type="text"
                      tabIndex={1}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2 ">
                  <Label>Senha</Label>
                  <FormGroup>
                    <Input
                      name="password"
                      placeholder="*********"
                      type="password"
                      tabIndex={2}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-3">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>E-mail</Label>
                  <FormGroup>
                    <Input
                      onBlur={() => {}}
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      tabIndex={3}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <Label>Telefone</Label>
                  <FormGroup>
                    <InputMask
                      mask={maskBuilder(telephoneV)}
                      maskChar={null}
                      defaultValue={telephoneV}
                      name="telephone"
                      placeholder="Telefone"
                      type="text"
                      onChange={e => setTelephone(e.target.value)}
                      tabIndex={4}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
                tabIndex={14}
              >
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default NewSupport;
