import { useEffect, useRef } from 'react';
import { Row } from 'reactstrap';
import * as echarts from 'echarts';
import { formatPrice } from '~/util/format';

// const translateName = name => {
//   const translated = {
//     success: 'Sucesso',
//     wanted: 'Desejado',
//     acceptable: 'Aceitável',
//   };

//   return translated[name] || name;
// };

const SalesForMonth = ({ data, getMonthName }) => {
  const echartRef = useRef(null);

  useEffect(() => {
    if (data.error) return;
    // const maxValueY = Math.max(
    //   // data.parameters.wanted,
    //   ...Object.values(data.data).map(({ total }) => total)
    // );
    // let tick = 10000;
    // if (maxValueY > 100000) tick = 25000;
    // else if (maxValueY > 400000) tick = 100000;
    // let max = Math.floor(maxValueY / tick) * tick;

    // if (maxValueY % tick) max += tick;

    // console.log(max, tick);

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: keys =>
          keys.reduce(
            (str, key) =>
              `${str}${getMonthName(key.axisValue)}: ${formatPrice(key.data)}`,
            '<p class="font-weight-600 text-gray-70">Vendas por mês:</p>'
          ),
      },
      calculable: true,
      grid: {
        top: '10%',
        left: '3%',
        right: '3%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: Object.keys(data.data),
          axisLabel: {
            formatter: getMonthName,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: value => formatPrice(value),
          },
        },
      ],
      series: [
        {
          name: 'Vendas por mês',
          type: 'bar',
          data: Object.values(data.data).map(({ total }) => total),
          label: {
            show: true,
            position: 'top',
            color: 'black',
            fontSize: 10,
            formatter: value => formatPrice(value.data),
          },
          // markLine: {
          //   data: [
          //     {
          //       name: 'Desejado',
          //       yAxis: data.parameters.wanted,
          //       symbol: 'none',
          //       label: {
          //         formatter: d => translateName(d.name),
          //         show: true,
          //         position: 'insideEndTop',
          //         symbol: 'none',
          //       },
          //       lineStyle: {
          //         normal: {
          //           color: '#444444',
          //           width: 2,
          //         },
          //       },
          //     },
          //     {
          //       name: 'Aceitável',
          //       yAxis: data.parameters.acceptable,
          //       symbol: 'none',
          //       label: {
          //         formatter: d => translateName(d.name),
          //         show: true,
          //         position: 'insideEndTop',
          //         symbol: 'none',
          //       },
          //       lineStyle: {
          //         normal: {
          //           color: '#444444',
          //           width: 2,
          //         },
          //       },
          //     },
          //   ],
          // },
        },
      ],
    };

    echarts
      .init(echartRef.current, 'default', { height: 450, renderer: 'svg' })
      .setOption(option);
  }, [data, getMonthName]);

  return (
    <div className="card new-layout dashboard-card-border overflow-auto">
      {data.error ? (
        <Row className="m-2 font-size-18 font-weight-500 text-gray-700">
          {data.error}
        </Row>
      ) : (
        <div style={{ minWidth: '900px' }} ref={echartRef} />
      )}
    </div>
  );
};

export default SalesForMonth;
