import { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import axios from 'axios';
import { formatPrice } from '~/util/format';
import api from '~/services/api';
import Koin from '~/assets/icons/Koin.svg';
import Loading from '~/components/Loading';
import { loadCart } from '~/store/modules/shareable_cart/actions';

export const BnplRejected = ({ onClick, isDarkTheme, themeIsWhite }) => {
  const cart = useSelector(({ shareable_cart }) => shareable_cart?.cart);

  return (
    <>
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3 justify-content-center">
            <img src={Koin} alt="ícone da koin" width="64" />
          </Row>
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700">
            <Col className="px-0">
              <h2 className="font-size-18 text-center">
                Seu parcelamento não foi aprovado!
              </h2>
              <p className="font-size-16 font-weight-500 text-gray-600 mt-3 mb-0 text-center">
                Clique no botão abaixo e continue sua compra por pix à vista ou
                cartão de crédito.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row className="w-100 mx-0">
        <Col className="px-0" align="end">
          <Button
            color="white"
            size="lg"
            type="button"
            style={{
              backgroundColor: cart?.store?.customize?.theme_color,
              color: isDarkTheme ? 'white' : 'black',
              border: themeIsWhite && '1px solid #000000',
            }}
            className="float-right w-lg-25 new-layout-btn main"
            onClick={onClick}
          >
            Confirmar para pagamento
          </Button>
        </Col>
      </Row>
    </>
  );
};

const BnplConfirmCard = ({
  cart,
  bnplData,
  getAvailability,
  isDarkTheme,
  themeIsWhite,
}) => (
  <>
    <Card className="card new-layout">
      <CardBody className="card-body new-layout">
        <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
          <img src={Koin} alt="ícone da koin" width="64" />
        </Row>
        <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
          <h2 className="font-size-20">Dados de Cobrança</h2>
        </Row>
        <Row className="w-100 mx-0">
          <Col xs={12} className="px-0">
            <Row className="w-100 mx-0 font-size-16 mb-2">Cliente</Row>
            <Row className="w-100 mx-0">
              <Col xs={12} lg={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 text-gray-700">Nome</Row>
                <Row className="mx-0 font-size-14">{bnplData.name}</Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Whatsapp
                </Row>
                <Row className="mx-0 font-size-14">{bnplData.telephone}</Row>
              </Col>
              {bnplData.cpf && (
                <Col xs={6} className="px-0 mb-2">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Cpf
                  </Row>
                  <Row className="mx-0 font-size-14">{bnplData.cpf}</Row>
                </Col>
              )}
              {bnplData.birth_date && (
                <Col xs={6} className="px-0 mb-2">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Data de Nascimento
                  </Row>
                  <Row className="mx-0 font-size-14">{bnplData.birth_date}</Row>
                </Col>
              )}
              {bnplData.cpf && (
                <Col xs={6} className="px-0 mb-2">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    E-Mail
                  </Row>
                  <Row className="mx-0 font-size-14">{bnplData.email}</Row>
                </Col>
              )}
            </Row>
            <Row className="w-100 mx-0 font-size-16 mb-2 mt-3">
              Endereço de Cobrança
            </Row>
            <Row className="w-100 mx-0">
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">Cep</Row>
                <Row className="mx-0 font-size-14">{bnplData.address.cep}</Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Logradouro
                </Row>
                <Row className="mx-0 font-size-14">
                  {bnplData.address.street_name}
                </Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Bairro
                </Row>
                <Row className="mx-0 font-size-14">
                  {bnplData.address.neighborhood}
                </Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Complemento
                </Row>
                <Row className="mx-0 font-size-14">
                  {bnplData.address.complement || '-'}
                </Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Estado
                </Row>
                <Row className="mx-0 font-size-14">
                  {bnplData.address.state}
                </Row>
              </Col>
              <Col xs={6} className="px-0 mb-2">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Cidade
                </Row>
                <Row className="mx-0 font-size-14">{bnplData.address.city}</Row>
              </Col>
              <Col xs={6} className="px-0">
                <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                  Informações Adicionais
                </Row>
                <Row className="mx-0 font-size-14">
                  {bnplData.address.aditional_informations || '-'}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Row className="w-100 mx-0">
      <Col className="px-0" align="end">
        <Button
          color="white"
          size="lg"
          type="button"
          style={{
            backgroundColor: cart?.store?.customize?.theme_color,
            color: isDarkTheme ? 'white' : 'black',
            border: themeIsWhite && '1px solid #000000',
          }}
          className="float-right w-lg-25 new-layout-btn main"
          onClick={getAvailability}
        >
          Confirmar
        </Button>
      </Col>
    </Row>
  </>
);

export const BnplConfirmedComponent = ({ cart }) => {
  if (cart?.payment_process?.reason === 'ProviderReview')
    return (
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
            <img src={Koin} alt="ícone da koin" width="64" />
          </Row>
          <Row className="w-100 mx-0 font-weight-700 text-gray-700">
            <Col className="px-0">
              <h2 className="font-size-18 mb-1">Estamos quase lá!</h2>
              <h2 className="font-size-18">
                Para efetuar a compra, precisamos que você aguarde a aprovação
                do provedor.
              </h2>
              <p className="font-size-14 font-weight-500 text-gray-600 mt-2 mb-0">
                Enviamos uma solicitação de aprovação do seu pagamento. Agora é
                necessário aguardar a revisão do provedor.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );

  return (
    <Card className="card new-layout">
      <CardBody className="card-body new-layout">
        <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
          <img src={Koin} alt="ícone da koin" width="64" />
        </Row>
        <Row className="w-100 mx-0 font-weight-700 text-gray-700">
          <Col className="px-0">
            <h2 className="font-size-18 mb-1">Estamos quase lá!</h2>
            <h2 className="font-size-18">
              Para efetuar a compra, precisamos que você confirme o seu e-mail
              ou Whatsapp.
            </h2>
            <p className="font-size-14 font-weight-500 text-gray-600 mt-2 mb-0">
              Enviamos um e-mail e uma mensagem via Whats app. Para validar é só
              clicar no botão do e-mail ou responder a mensagem no Whatsapp.
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const BnplComponent = ({ cart, bnplData }) => {
  const [confirmed, setConfirmed] = useState(false);

  if (confirmed) return <BnplConfirmedComponent cart={cart} />;

  return (
    <BnplConfirmCard
      cart={cart}
      bnplData={bnplData}
      setSuccess={setConfirmed}
    />
  );
};

export const BnplAccepted = ({
  setShowBnplComponent,
  bnplData,
  isDarkTheme,
  themeIsWhite,
}) => {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const dispatch = useDispatch();
  const cart = useSelector(({ shareable_cart }) => shareable_cart?.cart);
  const shareableCartLoading = useSelector(
    ({ shareable_cart }) => shareable_cart.loading
  );

  const first_due_date = new Date(
    cart?.payment_process?.installment_option?.first_due_date
  );
  // const a = "00020101021126950014BR.GOV.BCB.PIX2573spi.dev.cloud.itau.com.br/documentos/198e49c5-2330-4ad7-9d0b-967c7b5371225204000053039865802BR5923PMD Gotham NegA cios ME6009SAO PAULO62410503***50300017BR.GOV.BCB.BRCODE01051.0.063040866"

  const [installments, setInstallments] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [rejected, setRejected] = useState(false);
  const [loadingState, setLoading] = useState(false);
  const loading = shareableCartLoading || loadingState;

  const token = useQuery().get('token');
  const id = useQuery().get('id');

  useEffect(() => {
    const interval = setInterval(() => {
      if (cart?.payment_process?.payment_method !== 'bnpl') return;
      if (cart?.payment_process?.status !== 'pending') return;
      if (cart?.payment_process?.reason === 'Availability') return;
      if (cart?.payment_process?.reason === 'FirstPayment') return;

      dispatch(loadCart(id, token));
    }, 40 * 1000);

    return () => clearInterval(interval);
  }, [dispatch, cart, id, token]);

  const getAvailability = useCallback(() => {
    if (installments) return;

    setLoading(true);
    const loadInstallments = async sessionId => {
      const { data: ipLookupData } = await axios.get(
        'https://extreme-ip-lookup.com/json/'
      );

      const { data } = await api.post(
        `shareable-carts/carts/${cart.id}/availability`,
        {
          ...bnplData,
          ip: ipLookupData.query,
          browser: {
            browserFingerprint: sessionId,
          },
        },
        {
          headers: { 'x-shareable-token': cart.shareable_token },
        }
      );

      if (data?.reason === 'Rejected' || !data?.applicable) {
        setRejected(true);
      }

      setMetadata({
        ip: ipLookupData.query,
        sessionId,
      });
      setInstallments(data);
    };

    const r = document.createElement('script');
    const s = document.getElementsByTagName('body')[0];
    r.type = 'text/javascript';
    r.src =
      'https://securegtm.despegar.com/risk/fingerprint/statics/track-min.js';
    r.id = 'deviceId_fp';
    r.setAttribute('org_id', 'czFsxcFYR7');
    r.onload = () => {
      // eslint-disable-next-line
      getSessionID(sessionId =>
        loadInstallments(sessionId)
          .then(() => setLoading(false))
          .catch(err => {
            if (err?.response && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message, {
                autoClose: err?.response?.data?.duration || 5000,
              });
            } else {
              toast.error(
                'Houve um erro carregar opções de parcelas. Tente novamente mais tarde.'
              );
            }
            setLoading(false);
          })
      );
    };
    s.appendChild(r);
  }, [bnplData, cart.id, cart.shareable_token, installments]);

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await api.post(
      `shareable-carts/carts/${cart.id}/finalize`,
      {
        ...bnplData,
        ip: metadata.ip,
        browser: {
          browserFingerprint: metadata.sessionId,
        },
        payment_type: 116,
        option_id: selectedInstallment.option_id,
      },
      {
        headers: { 'x-shareable-token': cart.shareable_token },
      }
    );
    setResultData(data);
    dispatch(loadCart(id, token));
    setLoading(false);
  };

  if (loading)
    return (
      <Loading
        customClassName="shareable-cart-loading"
        customColor={cart?.store?.customize?.theme_color}
        color="custom"
      />
    );

  if (rejected)
    return (
      <BnplRejected
        onClick={() => setShowBnplComponent(false)}
        isDarkTheme={isDarkTheme}
        themeIsWhite={themeIsWhite}
      />
    );

  if (
    cart?.payment_process?.status === 'pending' &&
    cart?.payment_process?.reason === 'FirstPayment'
  )
    return (
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
            <img src={Koin} alt="ícone da koin" width="64" />
          </Row>
          <Row className="w-100 mx-0 font-size-16 font-weight-500 text-gray-800 mb-3">
            <Col className="px-0">
              <p className="mb-1">
                Olá, <b>{cart?.client?.name}</b>!
              </p>
              <p className="mb-1">
                Seu pedido foi aprovado e agora você pode realizar o pagamento
                da fatura até{' '}
                <b>
                  {first_due_date.getDate()}/{first_due_date.getMonth() + 1}/
                  {first_due_date.getFullYear()}
                </b>
                .
              </p>
              <p className="mb-2">
                Caso queira fazer o seu pagamento por PIX, copie a linha a
                seguir:
              </p>
            </Col>
          </Row>
          <Row className="mx-0 justify-content-center align-items-center">
            <Col className="px-0 mx-auto text-center">
              <QRCode value={cart?.payment_process?.emv || ''} />
              <p className="font-size-12 text-gray-600 mb-0 mt-3">
                Pix copia e cola
              </p>
              <p className="font-size-14 text-gray-600 mb-2 mt-0">
                {cart?.payment_process?.emv}
              </p>
              {/* <p className="mb-3 font-size-16 font-weight-500 text-gray-800">
                Se desejar pagar por meio de boleto, é só gerar no botão abaixo
                ou copiar a linha a seguir:
              </p>
              <Button
                type="button"
                color="white"
                style={{
                  backgroundColor: cart?.store?.customize?.theme_color,
                  color: isDarkTheme ? 'white' : 'black',
                  border: themeIsWhite && '1px solid #000000',
                }}
                className="new-layout-btn main"
              >
                Gerar Boleto
              </Button>
              <p className="font-size-14 text-gray-600 my-2">
                Copie e use os números abaixo para pagar o seu boleto.
              </p> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );

  if (
    resultData ||
    (cart?.payment_process?.payment_method === 'bnpl' &&
      cart?.payment_process?.status === 'pending' &&
      cart?.payment_process?.reason !== 'Availability' &&
      cart?.payment_process?.reason !== 'FirstPayment')
  )
    return <BnplConfirmedComponent cart={cart} />;

  if (installments)
    return (
      <>
        <Card className="card new-layout">
          <CardBody className="card-body new-layout">
            <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3 justify-content-center">
              <img src={Koin} alt="ícone da koin" width="64" />
            </Row>
            <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3 justify-content-center">
              <h2 className="font-size-18">Seu parcelamento foi aprovado!</h2>
            </Row>
            <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700">
              <p className="font-size-16 font-weight-500 text-gray-600">
                Agora basta selecionar o número de parcelas e pagar a primeira
                parcela.
              </p>
            </Row>
            <Row className="w-100 mx-0 font-weight-700 text-gray-700 mb-3">
              <h2 className="font-size-18">Selecione o número de parcelas</h2>
            </Row>
            <Row className="flex-column w-100 mx-0 font-size-18 font-weight-700 text-gray-700 ml-2">
              {installments.installment_options.map((option, index) => (
                <Row
                  key={option.option_id}
                  className={`mx-0 ${
                    index !== 0 ? 'pt-3 mt-3 border-top' : ''
                  }`}
                >
                  <input
                    type="radio"
                    name="installment-checkbox"
                    id={`installment-checkbox-${index}`}
                    className="text-primary mr-4"
                    style={{
                      transform: 'scale(1.4)',
                      accentColor: cart?.store?.customize?.theme_color,
                    }}
                    onChange={() => setSelectedInstallment(option)}
                  />
                  <Col className="px-0">
                    <label
                      htmlFor={`installment-checkbox-${index}`}
                      className="mb-0 w-100"
                    >
                      <h2 className="font-size-18 mb-1">
                        {option.installments === 1 ? (
                          'À vista'
                        ) : (
                          <>
                            {option.installments}x de{' '}
                            {formatPrice(option.installment_amount)}
                          </>
                        )}
                      </h2>
                      <p className="font-size-14 font-weight-500 text-gray-600 mb-0">
                        {option.installments !== 1 && (
                          <>{option.incoming_percent_value}% de juros </>
                        )}
                        <span className="text-bold">
                          Total {formatPrice(option.total_amount)}
                        </span>
                      </p>
                    </label>
                  </Col>
                </Row>
              ))}
            </Row>
          </CardBody>
        </Card>
        <Row className="w-100 mx-0">
          <Col className="px-0" align="end">
            <Button
              color="white"
              size="lg"
              type="button"
              style={{
                backgroundColor: cart?.store?.customize?.theme_color,
                color: isDarkTheme ? 'white' : 'black',
                border: themeIsWhite && '1px solid #000000',
              }}
              className="float-right w-lg-25 new-layout-btn main"
              onClick={onSubmit}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </>
    );

  return (
    <BnplConfirmCard
      cart={cart}
      bnplData={bnplData}
      getAvailability={getAvailability}
      isDarkTheme={isDarkTheme}
      themeIsWhite={themeIsWhite}
    />
  );
};
