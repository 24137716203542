import { takeLatest, put, all, call } from 'redux-saga/effects';

// Login Redux States
import api from '~/services/api';
import { FORGET_PASSWORD } from './actionTypes';
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions';

// If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email } }) {
  try {
    const response = yield call(api.post, '/auth/forgot-password', { email });
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          'O link de redefinição foi enviado para seu e-mail, verifique-o'
        )
      );
    }
  } catch (err) {
    yield put(userForgetPasswordError(err.message));
  }
}

export default all([takeLatest(FORGET_PASSWORD, forgetUser)]);
