/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
} from 'reactstrap';

import { Scope } from '@unform/core';
import { ValidationError } from 'yup';
import { toast, Flip } from 'react-toastify';
import { useSelector } from 'react-redux';
import api from '~/services/api';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
import { maskBuilder } from '~/util/maskTelephone';
import { stateList } from '~/util/stateList';
import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';
import { defineAlignment } from '~/util/newLayoutHelpers';
import { LG } from '~/util/breakpointWidths';
import Loading from '~/components/Loading';
import SchemaValidator from '~/validators/form/subscriber';
import focusErrorInputs from '~/util/focusErrorInputs';
import IconButton from '~/components/IconButton';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import InputCheckbox from '~/components/Inputs/InputCheckbox';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';

const EditSubscriberProfile = () => {
  const formRef = useRef(null);
  const toastId = useRef(null);

  const { subscriber_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [cityList, setCityList] = useState([]);

  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [generatingNewPassword, setGeneratingNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const [noNumber, setNoNumber] = useState(false);
  const [addressNumber, setAddressNumber] = useState(null);

  const [subscriber, setSubscriber] = useState(null);
  const [searchedCEP, setSearchedCEP] = useState('');
  const [telephoneV, setTelephone] = useState(subscriber?.telephone || '');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [maintenanceMode, setMaintenanceMode] = useState();
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  useEffect(() => {
    document.title = 'Editar Assinante | Viddy';

    const loadSubscriber = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/master/subscribers/${subscriber_id}`);

        setTelephone(data.subscriber.telephone);

        if (data.subscriber.address.number === 'sn') {
          setNoNumber(true);
        } else {
          setAddressNumber(data.subscriber.address.number);
        }
        setSubscriber(data.subscriber);
        setLoading(false);
        setMaintenanceMode(data?.subscriber?.maintenance_mode || false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            'Houve um problema ao carregar os dados do assinante. Tente novamente mais tarde!'
          );
        }
      }
    };

    loadSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriber) {
      if (subscriber) {
        const { address } = subscriber;
        const state = stateList.find(s => s.value === address.state);
        setSelectedState(state);

        const city = {
          label: address.city,
          value: address.city,
        };
        setSelectedCity(city);
      }
    }
  }, [subscriber]);

  useEffect(() => {
    const getNewPassword = async () => {
      try {
        const {
          data: { password },
        } = await api.patch(
          `/master/subscribers/${subscriber_id}/password/new`
        );
        if (password) {
          setNewPassword(password);
          setGeneratingNewPassword(false);
          toast.success('Nova senha gerada com sucesso');
        }
      } catch (err) {
        setShowNewPasswordModal(false);
        setGeneratingNewPassword(false);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            'Ocorreu um erro ao tentar gerar uma nova senha. Tente mais tarde ou contate um administrador!'
          );
        }
      }
    };
    if (showNewPasswordModal && generatingNewPassword) {
      getNewPassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNewPasswordModal, generatingNewPassword]);

  const handleValidSubmit = async data => {
    try {
      formRef.current.setErrors({});

      data = {
        ...data,
        address: {
          ...data.address,
          number: addressNumber,
          state: selectedState?.value,
          city: selectedCity?.value,
        },
      };

      const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

      await SchemaValidator(maskTelephoneLength).validate(data, {
        abortEarly: false,
      });

      await api.put(`/master/subscribers/${subscriber_id}`, data);

      toast.success('Alterações salvas com sucesso!');

      history.push(`/assinantes`);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);

        focusErrorInputs();
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
        autoClose: false,
      });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();

      toast.update(toastId.current, {
        render: 'Endereço encontrado!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });
      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    if (noNumber) {
      setAddressNumber('sn');
    }
  }, [noNumber]);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="EDITAR ASSINANTE"
              backTo="/assinantes"
              responsivePosition="start"
              desktopPosition="center"
            />
            <Form
              id="subscriber-form"
              ref={formRef}
              onSubmit={handleValidSubmit}
              initialData={{ ...subscriber }}
              noValidate
            >
              <Card className="card new-layout">
                <CardBody className="card-body new-layout">
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Dados do Assinante
                  </Row>

                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2 ">
                      <Label>Nome Fantasia</Label>
                      <FormGroup>
                        <Input
                          name="fantasy_name"
                          placeholder="Nome fantasia"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <Label>CNPJ</Label>
                      <FormGroup>
                        <InputMask
                          name="cnpj"
                          placeholder="CNPJ"
                          mask="99.999.999/9999-99"
                          maskChar={null}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <Label>Razão Social</Label>
                      <FormGroup>
                        <Input
                          name="name"
                          placeholder="Razão Social"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Dados do administrador
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2 ">
                      <Label>Nome do Responsável</Label>
                      <FormGroup>
                        <Input
                          onBlur={() => {}}
                          name="responsible_name"
                          placeholder="Nome do Responsável"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2 ">
                      <Label>E-mail</Label>
                      <FormGroup>
                        <Input
                          onBlur={() => {}}
                          name="email"
                          placeholder="E-mail"
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 mb-3">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <Label>Telefone</Label>
                      <FormGroup>
                        <InputMask
                          mask={maskBuilder(telephoneV)}
                          maskChar={null}
                          defaultValue={telephoneV}
                          name="telephone"
                          placeholder="Telefone"
                          type="text"
                          onChange={e => {
                            if (
                              e.target.value.length < 15 ||
                              e.target.value[5] === '9'
                            ) {
                              setTelephone(e.target.value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-0 pl-lg-2" lg={6}>
                      <Button
                        color="primary"
                        className="new-layout-btn generate-new-password-btn"
                        onClick={() => {
                          setGeneratingNewPassword(true);
                          setShowNewPasswordModal(true);
                        }}
                      >
                        Redefinir senha do administrador
                      </Button>
                    </Col>
                  </Row>

                  {/* <Scope path="database_settings">
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      Configurações do banco
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Driver</Label>
                          <Input
                            name="driver"
                            placeholder="Driver"
                            type="text"
                            disabled
                            value="pgsql"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Host</Label>
                          <Input name="host" placeholder="Host" type="text" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Port</Label>
                          <Input
                            name="port"
                            placeholder="Port"
                            type="text"
                            disabled
                            value="5432"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Database</Label>
                          <Input
                            name="database"
                            placeholder="Database"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Nome de Usuário</Label>
                          <Input
                            name="username"
                            placeholder="Nome de Usuário"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Senha</Label>
                          <Input
                            name="password"
                            placeholder="Senha"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Scope> */}
                  <Scope path="address">
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      Endereço
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col className="px-0">
                        <FormGroup>
                          <Label>CEP</Label>
                          <InputMask
                            name="cep"
                            placeholder="CEP"
                            mask="99999-999"
                            maskChar={null}
                            onChange={e =>
                              e.target.value.length === 9 && searchCEP()
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Logradouro</Label>
                          <Input
                            name="street_name"
                            placeholder="Logradouro"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <Label>Número</Label>
                        <CustomInput
                          type="switch"
                          id="noNumberSwitch"
                          name="noNumber"
                          label="Sem Número"
                          checked={noNumber}
                          className="text-primary float-right"
                          onChange={() => setNoNumber(!noNumber)}
                        />
                        <FormGroup>
                          <Input
                            name="number"
                            placeholder="Número"
                            type={noNumber ? 'text' : 'number'}
                            disabled={noNumber}
                            onChange={({ target: { value } }) =>
                              setAddressNumber(value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Bairro</Label>
                          <Input
                            name="neighborhood"
                            placeholder="Bairro"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <TextAreaWithCounter
                          name="complement"
                          placeholder="Complemento"
                          title="Complemento"
                          maxSize={128}
                          tabIndex={10}
                        />
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Estado</Label>
                          <InputSelect
                            name="state"
                            options={stateList}
                            onChange={selectedOption =>
                              setSelectedState(selectedOption)
                            }
                            value={selectedState}
                            placeholder="Estado"
                            singlePath
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Cidade</Label>
                          <InputSelect
                            name="city"
                            options={cityList}
                            onChange={selectedOption =>
                              setSelectedCity(selectedOption)
                            }
                            value={selectedCity}
                            placeholder="Cidade"
                            isDisabled={!selectedState}
                            singlePath
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col className="px-0">
                        <TextAreaWithCounter
                          name="additional_information"
                          placeholder="Informações Adicionais"
                          title="Informações Adicionais"
                          maxSize={128}
                          tabIndex={10}
                        />
                      </Col>
                    </Row>
                  </Scope>
                  <Row className="w-100 mx-0">
                    <Col
                      className="mt-2 px-0 d-flex align-items-end justify-content-end"
                      xs={12}
                    >
                      <Label className="mb-0 mr-3">Manutenção</Label>
                      <InputCheckbox
                        name="maintenance_mode"
                        checked={maintenanceMode}
                        configurationCard
                        onChange={_ => {
                          setMaintenanceMode(!maintenanceMode);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row className="w-100 mx-0 my-4 ">
                <Col
                  align={defineAlignment(windowSize, LG, 'center', 'end')}
                  className="px-0"
                >
                  <Button
                    className="new-layout-btn main"
                    color="primary"
                    size="lg"
                    type="submit"
                    tabIndex={14}
                  >
                    Salvar alterações
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
      <Modal isOpen={showNewPasswordModal}>
        <ModalHeader>
          {generatingNewPassword ? 'Aguarde...' : 'Nova senha gerada'}
        </ModalHeader>
        <ModalBody>
          {generatingNewPassword ? (
            <Loading message="Aguarde enquanto uma nova senha é gerada!" />
          ) : (
            <>
              <span>{`Esta é a nova senha de ${subscriber?.responsible_name}, você já pode copiá-la!`}</span>
              <Row className="align-items-center justify-content-center">
                <Col xs={10}>
                  <input
                    type="text"
                    // name="new_password"
                    id="new-password"
                    className="my-4 w-100 form-control disabled"
                    readOnly
                    value={newPassword}
                  />
                </Col>
                <Col className="px-0" xs={2}>
                  <IconButton
                    id="copy-new-password"
                    icon="las la-copy"
                    containerClass="ml-2"
                    fontSize={32}
                    color="info"
                    click={() => {
                      copyStringToClipboard(newPassword, 'Senha copiada!');
                    }}
                    tip="Copiar"
                  />
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className={` new-layout-btn main float-right ${
              generatingNewPassword && 'd-none'
            }`}
            color="primary"
            onClick={() => {
              setShowNewPasswordModal(false);
            }}
          >
            Pronto
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditSubscriberProfile;
