import React from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '~/services/api';

export default function DeleteModal({
  show,
  setShow,
  deleteUrl,
  getUrl,
  setData,
  params,
  itemsArray,
  setItemToBeDeleted,
  headerText,
  bodyText,
  extraText,
  itemName,
  successMsg,
  btnText,
  btnColor,
  setReqRunning,
}) {
  const handleDelete = async () => {
    try {
      if (setReqRunning) {
        setReqRunning(true);
      }
      await api.delete(deleteUrl);

      const { data } = await api.get(getUrl, {
        params: {
          ...params,
          page: itemsArray.length === 1 ? params.page - 1 : params.page,
        },
      });

      setData(data);
      setShow(false);
      setItemToBeDeleted(null);

      if (setReqRunning) {
        setReqRunning(false);
      }

      toast.success(successMsg || 'Item removido com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(err.message);
      }
    }
  };
  const toggle = () => {
    setShow(false);
    setItemToBeDeleted(null);
  };

  return (
    <Modal isOpen={show}>
      <ModalHeader toggle={toggle}>
        {headerText || 'Deseja mesmo excluir o item?'}
      </ModalHeader>
      <ModalBody>
        <h5 className="mb-2">
          {bodyText || 'Essa ação irá excluir o item'}
          <strong>{itemName ? ` ${itemName}` : ''}</strong>.
        </h5>
        {extraText && <h6 className="text-muted">{extraText}</h6>}
      </ModalBody>
      <ModalFooter>
        <Button
          className="new-layout-btn main"
          size="lg"
          color={btnColor || 'danger'}
          onClick={() => handleDelete()}
        >
          {btnText || 'Excluir'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
