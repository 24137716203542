import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ApexCharts from 'react-apexcharts';

export default function DonutChart() {
  const series = [40, 11];

  const donutChart = {
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'Nenhum dado sobre as assinaturas',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#f0000',
        fontSize: '14px',
        fontFamily: 'Roboto',
      },
    },
    labels: ['Ativas', 'Inativas'],
    colors: ['#60b05d', '#eb5757'],
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
  };

  const hasSeries = true;

  return (
    <Card
      className="card new-layout dashboard-card-border"
      // style={{ minHeight: windowSize >= MD && height ? `${height}px` : 'auto' }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0 mb-4">
          <Col
            xs={10}
            className="px-0 font-size-16 font-weight-600 text-gray-700"
          >
            Status das Assinaturas
          </Col>
          <Col xs={2} className="px-0" align="end">
            <i className="las la-signature font-size-30 text-primary" />
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          {hasSeries ? (
            <>
              <Col
                md={6}
                className="px-0 mb-4 mb-md-0 d-flex flex-column justify-content-center"
              >
                <ApexCharts
                  options={donutChart}
                  series={series}
                  height={200}
                  type="donut"
                />
              </Col>
              <Col
                md={6}
                className="px-0 d-flex flex-column justify-content-center align-items-center"
              >
                <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
                  <div className="donut-card-item-pointer success" />
                  <div className="font-size-16 font-weight-500 text-gray-700 mr-3">
                    78%
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    Ativas ({series[0]})
                  </div>
                </Row>
                <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
                  <div className="donut-card-item-pointer danger" />
                  <div className="font-size-16 font-weight-500 text-gray-700 mr-3">
                    22%
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-700">
                    Inativas ({series[1]})
                  </div>
                </Row>
              </Col>
            </>
          ) : (
            <div className="font-size-16 font-weight-400 text-gray-700">
              Desculpe, não há informações sobre as assinaturas.
            </div>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
