/* eslint-disable no-plusplus */
const PAGE_ELEMENTS = [
  {
    name: 'body',
    new_layout_specific_classes: null,
  },
  {
    name: 'root',
    new_layout_specific_classes: null,
  },
  {
    name: 'page-topbar',
    new_layout_specific_classes: null,
  },
  {
    name: 'footer',
    new_layout_specific_classes: null,
  },
  {
    name: 'main-content',
    new_layout_specific_classes: null,
    // new_layout_specific_classes: ['pl-2'],
  },
  {
    name: 'page-content',
    new_layout_specific_classes: null,
  },
  {
    name: 'pro-sidebar-header',
    new_layout_specific_classes: null,
  },
];

const getElements = () => {
  const elements = PAGE_ELEMENTS.map(element => {
    const el_qs = document.querySelector(element.name);
    if (el_qs) {
      return {
        core: el_qs,
        main_class: 'new-layout',
        specific_classes: element.new_layout_specific_classes,
      };
    }

    const el_id = document.getElementById(element.name);
    if (el_id) {
      return {
        core: el_id,
        main_class: 'new-layout',
        specific_classes: element.new_layout_specific_classes,
      };
    }

    const el_cn = document.getElementsByClassName(element.name);
    if (el_cn) {
      return {
        core: el_cn[0],
        main_class: 'new-layout',
        specific_classes: element.new_layout_specific_classes,
      };
    }

    return null;
  });

  return elements;
};

export const checkDOMLoaded = () => {
  const elements = getElements();

  for (let i = 0; i < elements.length; i++) {
    if (!elements[i]) {
      return false;
    }
  }

  return true;
};

export const applyLayoutClasses = () => {
  const elements = getElements();

  elements.forEach(element => {
    element.core.classList.add(element.main_class);
    if (element.specific_classes) {
      element.specific_classes.forEach(c => {
        element.core.classList.add(c);
      });
    }
  });
};

export const removeLayoutClasses = () => {
  const elements = getElements();

  elements.forEach(element => {
    element.core.classList.remove(element.main_class);
    if (element.specific_classes) {
      element.specific_classes.forEach(c => {
        element.core.classList.remove(c);
      });
    }
  });
};
