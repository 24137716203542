import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '~/services/api';
import PageHeader from '~/components/Common/PageHeader';
import { getFullAddress } from '~/util/newLayoutHelpers';
import Loading from '~/components/Loading';

const CustomerProfile = () => {
  const history = useHistory();
  const { customer_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const { data } = await api.get(`/clients/${customer_id}`);

        document.title = `${data.client.name} | Viddy`;

        if (data.client.address) {
          const formattedCustomer = {
            ...data.client,
            address: {
              ...data.client.address,
              full_address: getFullAddress(data.client.address),
            },
          };

          setCustomer(formattedCustomer);
        } else {
          const formattedCustomer = {
            ...data.client,
          };

          setCustomer(formattedCustomer);
        }

        setLoading(false);
      } catch (err) {
        history.goBack();
        console.log(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro inesperado.');
        }
      }
    };
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="DETALHES DO CLIENTE"
              backTo="/clientes"
              responsivePosition="start"
              desktopPosition="center"
            />

            <Card className="card new-layout">
              <CardBody className="card-body new-layout">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Dados do Cliente
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col lg={6} className="px-0 pl-lg-4 pr-lg-2">
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">Nome</Label>
                        <div className="new-layout-page-value">
                          {customer.name}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">CPF</Label>
                        <div className="new-layout-page-value">
                          {customer.cpf || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>

                    <Row className="w-100 mx-0">
                      <Col className="px-0">
                        <Label className="mb-0">Data de Nascimento</Label>
                        <div className="new-layout-page-value">
                          {customer.birth_date || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2 pr-lg-4">
                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">E-mail</Label>
                        <div className="new-layout-page-value">
                          {customer.email || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>

                    <Row className="w-100 mx-0 mb-3">
                      <Col className="px-0">
                        <Label className="mb-0">Telefone</Label>
                        <div className="new-layout-page-value">
                          {customer.telephone || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col className="px-0">
                        <Label className="mb-0">Endereço</Label>
                        <div className="new-layout-page-value">
                          {customer.address?.full_address || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row className="w-100 mx-0 my-4 ">
              <Col align="end" className="px-0">
                <Button
                  className="new-layout-btn main"
                  color="primary"
                  size="lg"
                  onClick={() => history.push(`${customer_id}/editar`)}
                >
                  Editar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomerProfile;
