import {
  LG,
  LG_MIN_WIDTH,
  MD,
  MD_MIN_WIDTH,
  SM,
  SM_MIN_WIDTH,
  XL,
  XXL,
  XL_MIN_WIDTH,
  XXL_MIN_WIDTH,
  XS,
} from '~/util/breakpointWidths';

export const getRegisterDate = rawDate => {
  if (rawDate) {
    const date = new Date(rawDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `Cadastrado ${day}.${month}.${year}`;
  }
  return null;
};

export const defineAlignment = (
  width,
  breakpointWidth,
  responsivePosition,
  desktopPosition
) => {
  if (width <= breakpointWidth) {
    return responsivePosition;
  }
  if (width > breakpointWidth) {
    return desktopPosition;
  }
  return null;
};

export const defineWindowSize = stateSize => {
  if (window.innerWidth < SM_MIN_WIDTH && stateSize !== XS) {
    return XS;
  }

  if (
    window.innerWidth >= SM_MIN_WIDTH &&
    window.innerWidth < MD_MIN_WIDTH &&
    stateSize !== SM
  ) {
    return SM;
  }

  if (
    window.innerWidth >= MD_MIN_WIDTH &&
    window.innerWidth < LG_MIN_WIDTH &&
    stateSize !== MD
  ) {
    return MD;
  }

  if (
    window.innerWidth >= LG_MIN_WIDTH &&
    window.innerWidth < XL_MIN_WIDTH &&
    stateSize !== LG
  ) {
    return LG;
  }

  if (
    window.innerWidth >= XL_MIN_WIDTH &&
    window.innerWidth < XXL_MIN_WIDTH &&
    stateSize !== XL
  ) {
    return XL;
  }

  if (window.innerWidth >= XXL_MIN_WIDTH && stateSize !== XXL) {
    return XXL;
  }

  return null;
};

export const defineFirstWindowSize = () => {
  if (window.innerWidth < SM_MIN_WIDTH) {
    return XS;
  }

  if (window.innerWidth >= SM_MIN_WIDTH && window.innerWidth < MD_MIN_WIDTH) {
    return SM;
  }

  if (window.innerWidth >= MD_MIN_WIDTH && window.innerWidth < LG_MIN_WIDTH) {
    return MD;
  }

  if (window.innerWidth >= LG_MIN_WIDTH && window.innerWidth < XL_MIN_WIDTH) {
    return LG;
  }

  if (window.innerWidth >= XL_MIN_WIDTH && window.innerWidth < XXL_MIN_WIDTH) {
    return XL;
  }
  if (window.innerWidth >= XXL_MIN_WIDTH) {
    return XXL;
  }
  return null;
};

export const breakString = (str, breaker, limit, threeWordsOnTheFirstLine) => {
  if (str === null) return str;

  if (str.length <= limit) {
    return [str];
  }

  const words = str.split(breaker).filter(word => {
    return word !== ' ';
  });

  if (words.length === 1) {
    return words;
  }

  if (words.length === 2) {
    if (words[0].length + words[1].length + 1 <= limit) {
      return [`${words[0]} ${words[1]}`];
    }

    return words;
  }
  if (words.length === 3) {
    if (words[0].length + words[1].length + 1 <= limit) {
      return [`${words[0]} ${words[1]}`, words[2]];
    }

    return [words[0], `${words[1]} ${words[2]}`];
  }
  if (words.length > 3) {
    if (threeWordsOnTheFirstLine) {
      if (words[0].length + words[1].length + words[1].length + 2 <= limit) {
        const str2 = `${words
          .map((word, index) => {
            if (index > 2) {
              return `${word}${index < words.length - 1 ? ' ' : ''}`;
            }
            return '';
          })
          .join('')}`;

        return [`${words[0]} ${words[1]} ${words[2]}`, str2];
      }
    }
    if (words[0].length + words[1].length + 1 <= limit) {
      const str2 = `${words
        .map((word, index) => {
          if (index > 1) {
            return `${word}${index < words.length - 1 ? ' ' : ''}`;
          }
          return '';
        })
        .join('')}`;

      return [`${words[0]} ${words[1]}`, str2];
    }
    const str2 = `${words
      .map((word, index) => {
        if (index > 0) {
          return `${word}${index < words.length - 1 ? ' ' : ''}`;
        }
        return '';
      })
      .join('')}`;

    return [words[0], str2];
  }
  return null;
};

export const defineTableItemBorders = (index, length) => {
  if (length === 1) return 'single-row';
  if (index === 0) return 'first-row';
  if (index === length - 1) return 'last-row';
  return 'middle-row';
};

export const getFullAddress = address => {
  if (address) {
    return `${address.street_name}, ${address.number}${
      address.complement ? ` - ${address.complement}` : ''
    } - ${address.neighborhood}, ${address.city} - ${address.state}, CEP ${
      address.cep
    }${
      address.additional_information
        ? `. ${address.additional_information}`
        : ''
    }.`;
  }
  return null;
};

export const defineExpandedState = (size, breakpoint) => {
  if (size < breakpoint) {
    return false;
  }
  return true;
};

export const formatNumberToPercentage = number => {
  const rawStrNum = `${number}`;

  const strNum = parseFloat(rawStrNum).toFixed(2);

  return `${strNum}%`;
};
