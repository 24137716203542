import React, { useEffect, useMemo } from 'react';
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import logoWhite from '~/assets/images/logo-white.svg';
import security100 from '~/assets/images/Security100.svg';
import moocaShopping from '~/assets/images/logo_mooca_shopping.png';
// import poweredBy from '~/assets/images/PoweredBy.svg';
// import OrderConfirmation from './OrderConfirmation';
// import Payment from './Payment';
import history from '~/services/history';
import Loading from '~/components/Loading';
import { loadCart } from '~/store/modules/shareable_cart/actions';
import RegionsModal from '~/components/Modals/RegionsModal';
// import api from '~/services/api';
// import stdProfilePicture from '~/assets/images/user.png';
import WhatsAppSupportButton from '~/components/HorizontalLayout/WhatsAppSupportButton';
import { getSubscriberIdFromToken } from '~/util/checkout';

import SharedCartNav from './SharedCartNav';
import FinishedOrderInfo from './FinishedOrderInfo';
import CieloModal from './CieloModal';
import AntiFraud from './AntiFraud';
import ClientLiteForm from './Lite/Client';
// import AddressLiteForm from './Lite/Address';
// import PaymentLiteForm from './Lite/Payment';
// import CheckoutPayment from './Link/Payment';
// import FinishedOrderInfoPix from './FinishedOrderInfoPix';
import LinkPix from './NewLayout';
import AddressLiteForm from './Lite/Address';
import jsonPackage from '../../../package.json';

const { version } = jsonPackage;

export default function SharedCart() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  const loading = useSelector(({ shareable_cart }) => shareable_cart.loading);

  const token = useQuery().get('token');
  const id = useQuery().get('id');

  const cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  const isAllosSubscriber = useMemo(
    () =>
      getSubscriberIdFromToken(token) ===
      'a12e2061-baab-4096-a51a-6485acc8b361',
    [token]
  );

  const selectedGateway = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.store?.payment_gateway
  );
  const activeTab = useSelector(
    ({ shareable_cart }) => shareable_cart.activeTab
  );

  const storeOnlinePaymentLimit = Number(
    useSelector(
      ({ shareable_cart }) =>
        shareable_cart.cart?.store?.online_payments_value_limit
    )
  );

  const freight = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.freight
  );
  const amount = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.amount
  );
  const total = Number(amount) + Number(freight);

  const cardAvailable = useMemo(() => {
    if (storeOnlinePaymentLimit === 0 || total <= storeOnlinePaymentLimit) {
      return true;
    }
    return false;
  }, [storeOnlinePaymentLimit, total]);

  // const cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  // const [whatsapp, setWhatsapp] = useState('www');

  useEffect(() => {
    if (token && id) {
      document.title = 'Meu Carrinho | Viddy';

      const root = document.getElementById('root');
      const body = document.querySelector('body');

      root.classList.add('shared-cart-layout');
      root.style.minHeight = '100vh';

      body.classList.add('shared-cart-layout');

      dispatch(loadCart(id, token));
    } else {
      toast.error('Houve um problema na identificação do carrinho!');
      history.push('/');
    }

    // const load_whatsapp = async () => {
    //   const { data } = await api.get('/utils/whatsapp-support-number', {
    //     headers: {
    //       'authorization': `Bearer ${token}`,
    //       'X-Server': id,
    //     }
    //   })
    //   console.log(data)
    //   setWhatsapp(data.whatsapp_number)
    // }
    // load_whatsapp();

    return () => {
      const root = document.getElementById('root');
      const body = document.querySelector('body');

      root.classList.remove('shared-cart-layout');
      root.style.minHeight = 'unset';

      body.classList.remove('shared-cart-layout');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Loading customClassName="shareable-cart-loading" />
      ) : (
        <>
          <Row
            className="w-100 mx-0 background-color-primary pl-3 mb-md-4 flex justify-content-between align-content-center"
            style={{
              height: '65px',
              backgroundColor: cart?.store?.customize?.theme_color,
            }}
          >
            <Container className="justify-content-between d-flex align-items-center px-3">
              <div>
                <img
                  src={cart?.store?.customize?.brand_logo?.url || logoWhite}
                  alt="logo"
                  id="Viddy-logo"
                  style={{
                    height: '60px',
                    width: '160px',
                  }}
                />
                {isAllosSubscriber && (
                  <img
                    src={moocaShopping}
                    alt="logo shopping mooca"
                    id="mooca-logo"
                    style={{
                      height: '60px',
                      marginLeft: '0.5rem',
                    }}
                  />
                )}
              </div>
              <div>
                <img
                  src={security100}
                  alt="security"
                  id="security-logo"
                  className="h-40px"
                />
              </div>
            </Container>
          </Row>

          <div className="page-content new-layout pt-0 checkout-mobile-adjust">
            <>
              {selectedGateway === 'cielo' && <AntiFraud />}
              {cart?.payment_process?.status === 'Paid' ||
              cart?.status_text === 'Pagamento rejeitado pelo anti-fraude' ||
              (!cart.is_suitcase_delivery && cart?.status >= 2) ||
              Date.parse(cart?.url_shortener?.date_expiration) < Date.now() ? (
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <Row className="w-100 mx-0">
                      <div className="w-100 container">
                        <FinishedOrderInfo />
                      </div>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <>
                  {!cart.is_payment_checkout ? (
                    <>
                      {activeTab < 5 && <SharedCartNav />}
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <Row className="w-100 mx-0">
                            <div className="w-100 container">
                              {activeTab === 1 && <ClientLiteForm />}
                              {activeTab === (cardAvailable ? 0 : 2) && (
                                <AddressLiteForm />
                              )}
                              {activeTab === (cardAvailable ? 2 : 3) && (
                                <LinkPix />
                              )}
                              {/* {activeTab === 4 && <OrderConfirmation />} */}
                              {activeTab === 5 && <FinishedOrderInfo />}
                            </div>

                            {/* <TabContent
                            activeTab={activeTab}
                            className="w-100 container"
                          >
                            <TabPane tabId={1} />
                            <TabPane tabId={2} />
                            <TabPane tabId={3} />
                            <TabPane tabId={4} />
                            <TabPane tabId={5}>{}</TabPane>
                          </TabContent> */}
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="w-100 mx-0 mb-4">
                        <Col className="px-0">
                          <Row className="w-100 mx-0">
                            <TabContent
                              activeTab={activeTab}
                              className="w-100 container"
                            >
                              <TabPane tabId={1}>
                                <LinkPix />
                              </TabPane>
                              <TabPane tabId={5}>
                                <FinishedOrderInfo />
                              </TabPane>
                            </TabContent>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
            <CieloModal />
            <RegionsModal />
          </div>
          {cart?.handled_by?.telephone && (
            <WhatsAppSupportButton
              whatsapp={cart?.handled_by?.telephone}
              message="Olá! Estou com um problema em meu pedido, você pode me ajudar?"
            />
          )}
          <footer className="footer-shareable mx-0">
            <div className="container d-flex justify-content-center align-items-center h-100 w-100">
              <Row className="w-100 mx-3 justify-content-center justify-content-lg-start">
                {new Date().getFullYear()} @ Viddy - B{version}
              </Row>
            </div>
          </footer>
        </>
      )}
    </>
  );
}
