import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  TOGGLE_SIDEBAR,
  HIDE_SIDEBAR,
  TOGGLE_SEARCH,
  SET_DOM_LOADED,
  SET_WINDOW_SIZE,
  SET_SHOW_SEARCH,
} from './actionTypes';

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const setShowSearch = flag => ({
  type: SET_SHOW_SEARCH,
  payload: flag,
});

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = width => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const hideSidebar = () => ({
  type: HIDE_SIDEBAR,
});

export const toggleSearch = () => ({
  type: TOGGLE_SEARCH,
});

export const setDOMLoaded = flag => ({
  type: SET_DOM_LOADED,
  payload: flag,
});

export const setWindowSize = width => ({
  type: SET_WINDOW_SIZE,
  payload: width,
});
