import { useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';

function CustomTable({ title, data, formatData }) {
  const [storesDetailsVisibility, setStoresDetailsVisibility] = useState({});

  const getVisibility = store => !!storesDetailsVisibility[store];

  const toggleVisibility = store =>
    setStoresDetailsVisibility(state => ({
      ...state,
      [store]: !getVisibility(store),
    }));

  const mapOverMonths = callback =>
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(callback);

  const formatValue = value => {
    if (!value) return <span className="text-center">-</span>;
    if (value === 'Deactivate')
      return <span className="text-center">Desativado</span>;
    return formatData(value);
  };

  return (
    <Card className="card new-layout w-100 dashboard-card-border p-3">
      <CardBody className="card-body new-layout px-3 py-3 overflow-auto">
        <p className="px-0 font-size-16 font-weight-600 text-gray-700">
          {title}
        </p>
        {data.error && (
          <Row className="m-2 font-size-18 font-weight-500 text-gray-700">
            {data.error}
          </Row>
        )}
        {!data.error && data.length === 0 && (
          <p className="px-0 text-gray-700">Nenhum dado encontrado.</p>
        )}
        {!data.error && data.length !== 0 && (
          <table className="w-100">
            <thead>
              <tr>
                <th className="dashboard-card-border p-1">Loja/Vendedor</th>
                <th className="dashboard-card-border p-1">Janeiro</th>
                <th className="dashboard-card-border p-1">Fevereiro</th>
                <th className="dashboard-card-border p-1">Março</th>
                <th className="dashboard-card-border p-1">Abril</th>
                <th className="dashboard-card-border p-1">Maio</th>
                <th className="dashboard-card-border p-1">Junho</th>
                <th className="dashboard-card-border p-1">Julho</th>
                <th className="dashboard-card-border p-1">Agosto</th>
                <th className="dashboard-card-border p-1">Setembro</th>
                <th className="dashboard-card-border p-1">Outubro</th>
                <th className="dashboard-card-border p-1">Novembro</th>
                <th className="dashboard-card-border p-1">Dezembro</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(data).map(([store, storeData]) => (
                <>
                  <tr>
                    <td key={store} className="dashboard-card-border">
                      <button
                        type="button"
                        className="btn btn-white border-0 w-100"
                        onClick={() => toggleVisibility(store)}
                      >
                        {getVisibility(store) ? '-' : '+'} {store}
                      </button>
                    </td>
                    {mapOverMonths(month => (
                      <td
                        key={month}
                        className="dashboard-card-border text-center"
                      >
                        {formatValue(storeData[month]?.total?.amount)}
                      </td>
                    ))}
                  </tr>
                  {getVisibility(store) &&
                    Object.entries(storeData.sellers).map(
                      ([seller, sellerData]) => (
                        <tr key={seller} className="dashboard-card-border">
                          <td
                            className="dashboard-card-border text-center"
                            style={{ backgroundColor: '#F0F0F0' }}
                          >
                            {seller}
                          </td>
                          {mapOverMonths(month => (
                            <td
                              className="dashboard-card-border text-center"
                              style={{ backgroundColor: '#F0F0F0' }}
                            >
                              {formatValue(sellerData[month])}
                            </td>
                          ))}
                        </tr>
                      )
                    )}
                </>
              ))}
            </tbody>
          </table>
        )}
      </CardBody>
    </Card>
  );
}

export default CustomTable;
