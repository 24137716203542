import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavLink, NavItem, Row } from 'reactstrap';
import { setActiveTab, setStep } from '~/store/modules/shareable_cart/actions';

export default function SharedCartNav() {
  const dispatch = useDispatch();
  const step = useSelector(({ shareable_cart }) => shareable_cart.step);

  const cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  const activeTab = useSelector(
    ({ shareable_cart }) => shareable_cart.activeTab
  );

  const storeOnlinePaymentLimit = Number(
    useSelector(
      ({ shareable_cart }) =>
        shareable_cart.cart?.store?.online_payments_value_limit
    )
  );

  const freight = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.freight
  );
  const amount = useSelector(
    ({ shareable_cart }) => shareable_cart.cart?.amount
  );
  const total = Number(amount) + Number(freight);

  const cardAvailable = useMemo(() => {
    if (storeOnlinePaymentLimit === 0 || total <= storeOnlinePaymentLimit) {
      return true;
    }
    return false;
  }, [storeOnlinePaymentLimit, total]);

  const tabCard = useMemo(() => (cardAvailable ? 2 : 3), [cardAvailable]);

  const STEPS = useMemo(
    () => [
      {
        tab: 1,
        label: 'Seus dados',
        clickable: step >= 1,
        width: 3,
        available: true,
      },
      {
        tab: 2,
        label: 'Endereço',
        clickable: step >= 2,
        width: 3,
        available: !cardAvailable,
      },
      {
        tab: tabCard,
        label: 'Pagamento',
        clickable: step >= tabCard,
        width: 2,
        available: true,
      },
    ],
    [step, cardAvailable, tabCard]
  );

  useEffect(() => {
    if (activeTab > step) {
      dispatch(setStep(activeTab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const toggleTab = tab => {
    dispatch(setActiveTab(tab));
  };

  return (
    <Nav pills className="w-100 border-none">
      <Row className="w-100 mx-0 mb-4 justify-content-center mt-4 mt-md-0">
        {STEPS.filter(s => s.available).map((s, i) => (
          // <Col xs={s.width} className="px-0">
          <NavItem
            className="h-100 d-flex flex-column justify-content-center"
            key={s.tab}
          >
            <NavLink
              className={`pl-0 font-size-14 font-weight-400 ${
                s.clickable
                  ? 'text-gray-700'
                  : 'text-gray-600 pointer-events-none'
              } h-100 d-flex flex-column justify-content-center text-center align-items-center`}
              onClick={() => s.clickable && toggleTab(s.tab)}
            >
              <span
                className={`step-circle ${
                  activeTab === s.tab ? 'active' : ''
                } d-flex justify-content-center align-items-center mb-2`}
                style={{
                  backgroundColor:
                    activeTab === s.tab && cart?.store?.customize?.theme_color,
                }}
              >
                {i + 1}
              </span>
              <span>{activeTab === s.tab ? s.label : ''}</span>
            </NavLink>
          </NavItem>
          // </Col>
        ))}
      </Row>
    </Nav>
  );
}
