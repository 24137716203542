import React, { useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useField } from '@unform/core';

const MyAsyncSelect = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const customStyles = {
    control: (base, _) => ({
      ...base,
      height: `${rest.height || 46}px !important`,
    }),
    valueContainer: (base, _) => ({
      ...base,
      height: `${rest.height || 46}px !important`,
    }),
    placeholder: (base, _) => ({
      ...base,
      fontWeight: `500 !important`,
      color: `black !important`,
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'select.state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(option => option.value);
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <AsyncSelect
        defaultValue={defaultValue}
        ref={selectRef}
        style={customStyles}
        cacheOptions
        classNamePrefix="react-select input-select-async"
        className={error && 'border-danger'}
        onFocus={clearError}
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
      {error && <span className={error && 'text-danger'}>{error}</span>}
    </>
  );
};

export default MyAsyncSelect;
