import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, CustomInput, Button } from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DropdownFilter from '~/components/DropdownFilter';
import DeleteModal from '~/components/Modals/DeleteModal';
import RestoreUserModal from '~/pages/Users/RestoreUserModal';
import { activeUsersFilterOptions } from '~/util/filters';
import { breakString } from '~/util/newLayoutHelpers';
import Loading from '~/components/Loading';
import { getRole } from '~/util/getRole';
import BatchActions from '~/components/BatchActions';
import useBatchSelect from '~/hooks/useBatchSelect';
import history from '~/services/history';
import { resetSearch, setSearch } from '~/store/modules/search/actions';
import UserProfileCard from './UserProfileCard';
import ImportUsersModal from './ImportUsersModal';

const Users = () => {
  const dispatch = useDispatch();
  const roles = useSelector(({ user }) => user.profile.roles);

  const role = getRole(roles, ['administrator', 'store-manager', 'master']);

  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);

  const {
    allSelected: allUsers,
    setAllSelected: setAllUsers,
    selectedItems: selectedUsers,
    setSelectedItems: setSelectedUsers,
    handleSelect,
  } = useBatchSelect(users);

  const isStoreManager = useMemo(
    () => roles.find(r => r.slug === 'store-manager'),
    [roles]
  );

  const isAdmin = useMemo(
    () => roles.find(r => r.slug === 'administrator'),
    [roles]
  );

  const isSupport = useMemo(
    () => roles.find(r => r.slug === 'support'),
    [roles]
  );

  const searchQuery = useSelector(({ search }) => search.query);
  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const [userToBeRestored, setUserToBeRestored] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showRestoreUserModal, setShowRestoreUserModal] = useState(false);
  const [showImportUserModal, setShowImportUserModal] = useState(false);

  const [selectModeOn, setSelectModeOn] = useState(false);

  const [showOnlyInactiveUsers, setShowOnlyInactiveUsers] = useState(false);

  const getURL = useMemo(() => {
    return `${
      isStoreManager && (!isAdmin || !isSupport)
        ? 'store-manager'
        : 'administrator'
    }/users`;
  }, [isStoreManager, isAdmin, isSupport]);

  const deleteURL = useMemo(() => {
    return `${getURL}/${userToBeDeleted?.id}`;
  }, [getURL, userToBeDeleted]);

  const batchDeleteURL = useMemo(() => {
    return `/${role}/users/`;
  }, [role]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
      'only-trashed': showOnlyInactiveUsers === true ? true : null,
    };
  }, [pagination, searchQuery, showOnlyInactiveUsers]);

  const isAllowedToBatch = useMemo(() => {
    if (!showOnlyInactiveUsers && (isAdmin || isStoreManager)) {
      return true;
    }
    return false;
  }, [showOnlyInactiveUsers, isAdmin, isStoreManager]);

  const setData = data => {
    const userList = data.users.map(user => ({
      ...user,
      broken_name: breakString(user.name, ' ', 12),
      broken_email: breakString(user.email, '@', 25),
      formatted_updated_at: format(
        parseISO(user.updated_at),
        "HH'h'mm '-' dd/MMM/yy",
        {
          locale: ptBR,
        }
      ),
    }));

    setUsers(userList);
    setPagination(data.meta);
  };

  useEffect(() => {
    if (showOnlyInactiveUsers && selectModeOn) {
      setSelectModeOn(false);
    }
  }, [showOnlyInactiveUsers, selectModeOn]);

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="LISTA DE USUÁRIOS"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-2 mb-lg-4">
          <Col lg={12} className="px-0 mb-4 my-lg-0 d-lg-none">
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={q => dispatch(setSearch({ query: q }))}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
        </Row>
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 order-2 order-lg-1" lg={4} align="start">
            <div className="d-flex flex-row align-items-baseline">
              <span className="text-left mt-4 mt-lg-0 d-inline text-muted font-size-14 mr-2">
                Filtros
              </span>
              <DropdownFilter
                name="inactive-users"
                id="inactive-users"
                defaultOption={showOnlyInactiveUsers}
                options={activeUsersFilterOptions}
                url={getURL}
                setData={setData}
                setLoading={setLoading}
                filter={showOnlyInactiveUsers}
                setFilter={setShowOnlyInactiveUsers}
                params={params}
                query={searchQuery}
                setQuery={q => dispatch(setSearch({ query: q }))}
                additionalClassName="mx-0"
                width={165}
              />
            </div>
          </Col>
          <Col
            className="px-0 order-1 order-lg-2 mb-2 mb-lg-0"
            lg={4}
            align="end"
          >
            {isAllowedToBatch && (
              <BatchActions
                getURL={getURL}
                deleteURL={batchDeleteURL}
                selectedItems={{ users: selectedUsers.map(u => u.id) }}
                width={150}
                setData={setData}
                setSelectedItems={setSelectedUsers}
                itemsArray={users}
                params={params}
                keepPage
                pagination={pagination}
                setAllItems={setAllUsers}
                allItems={allUsers}
                tip="Para executar uma ação em lote, selecione, pelo menos, dois usuários."
                id="users"
                objName="users"
                successMsg="Usuários removidos com sucesso!"
                selectModeOn={selectModeOn}
                setSelectModeOn={setSelectModeOn}
                setLoading={setLoading}
              />
            )}
          </Col>
          <Col
            className="px-0 order-1 order-lg-2 mb-2 mt-2 mt-lg-0 mb-lg-0 pl-lg-3"
            lg={2}
            align="end"
          >
            <Button
              className="new-layout-btn main btn btn-primary waves-effect waves-light w-100 text-nowrap"
              color="primary"
              onClick={() => history.push(`usuarios/registrar`)}
            >
              <i className="fas fa-user-plus font-size-16 align-middle mr-2" />
              Novo Usuário
            </Button>
          </Col>
          <Col
            className="px-0 order-1 order-lg-2 mb-2 mt-2 mb-lg-0 mt-lg-0 pl-lg-3"
            lg={2}
            align="end"
          >
            <Button
              className="new-layout-btn main btn btn-primary waves-effect waves-light w-100 text-nowrap"
              color="primary"
              onClick={() => setShowImportUserModal(true)}
            >
              <i className="fas fa-user font-size-16 align-middle mr-2" />
              Importar
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {users.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                  <Col xs={1} align="center">
                    {isAllowedToBatch && (
                      <CustomInput
                        type="checkbox"
                        id="allUsers"
                        className="text-primary z-index-0"
                        checked={allUsers}
                        defaultValue={allUsers}
                        onChange={() => setAllUsers(!allUsers)}
                      />
                    )}
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Usuário</div>
                  </Col>
                  <Col
                    xs={3}
                    align="start"
                    // style={{ paddingLeft: '1.25rem' }}
                  >
                    <div className="new-layout-table-column-label">E-mail</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">
                      Cargo(s)
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Última Atualização
                    </div>
                  </Col>
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {users.map(user => {
                  const isSelected = selectedUsers.some(u => u.id === user.id);

                  return (
                    <Row className="w-100 mx-0" key={user.id}>
                      {selectModeOn && (
                        <Col
                          xs={1}
                          className="px-0 d-flex flex-column justify-content-center"
                        >
                          <CustomInput
                            type="checkbox"
                            id={`mobile-select-${user.id}`}
                            className="text-primary z-index-0"
                            checked={isSelected}
                            defaultValue={isSelected}
                            onChange={() => handleSelect(user, isSelected)}
                          />
                        </Col>
                      )}

                      <Col className="px-0" xs={selectModeOn ? 11 : 12}>
                        <UserProfileCard
                          key={user.id}
                          user={user}
                          isSelected={isSelected}
                          onSelect={handleSelect}
                          onDelete={incomingUser => {
                            setUserToBeDeleted(incomingUser);
                            setShowDeleteUserModal(true);
                          }}
                          onRestore={incomingUser => {
                            setUserToBeRestored(incomingUser);
                            setShowRestoreUserModal(true);
                          }}
                          isAllowedToBatch={isAllowedToBatch}
                        />
                      </Col>
                    </Row>
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum usuário encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <ImportUsersModal
        show={showImportUserModal}
        setShow={setShowImportUserModal}
      />

      <DeleteModal
        show={showDeleteUserModal}
        setShow={setShowDeleteUserModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={users}
        setItemToBeDeleted={setUserToBeDeleted}
        headerText="Deseja mesmo desativar o usuário?"
        bodyText="Essa ação irá desativar o usuário"
        itemName={userToBeDeleted?.name}
        successMsg="Usuário desativado com sucesso!"
        btnText="Desativar"
      />

      <RestoreUserModal
        show={showRestoreUserModal}
        setShow={setShowRestoreUserModal}
        user={userToBeRestored}
        setData={setData}
        users={users}
        params={params}
        setFilter={setShowOnlyInactiveUsers}
        setUserToBeRestored={setUserToBeRestored}
      />
    </div>
  );
};

export default Users;
