import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatPrice } from '~/util/format';
import { breakString, getFullAddress } from '~/util/newLayoutHelpers';
import {
  getPaymentValue,
  translatePaymentWay,
} from '~/util/translatePaymentWay';

export const formatCart = cart => {
  return {
    ...cart,
    delivery_address: {
      ...cart.delivery_address,
      full_address: cart.delivery_address
        ? getFullAddress(cart.delivery_address)
        : null,
    },
    formatted_created_at: format(
      parseISO(cart.created_at),
      "dd/MM/yyyy 'às' HH':'mm",
      {
        locale: ptBR,
      }
    ),
    formatted_updated_at: format(
      parseISO(cart.updated_at),
      "dd/MM/yyyy 'às' HH':'mm",
      {
        locale: ptBR,
      }
    ),
    formatted_amount: formatPrice(cart.amount),
    formatted_freight: formatPrice(cart.freight),
    formatted_discount: formatPrice(cart.discount),
    formatted_final_value: formatPrice(
      Number(cart.amount) +
        Number(cart.freight_user) -
        (cart?.giftback?.amount && cart.giftback.amount > 0
          ? Number(cart.giftback.amount)
          : 0)
    ),
    payment_way: translatePaymentWay(
      cart.payment_type,
      cart.is_shareable,
      cart.is_payment_checkout,
      !!cart.payment_process
    ),
  };
};

export const formatProducts = products => {
  return products.map((product, index) => ({
    ...product,
    formatted_index: `${index + 1}`.padStart(2, '0'),
    is_for_gift: product.pivot.gift ? 'Sim' : 'Não',
    formatted_unit_value: formatPrice(product.pivot.value),
    formatted_total_value: formatPrice(
      product.pivot.prod_amount * Number(product.pivot.value)
    ),
    formatted_qtd: `${product.pivot.prod_amount}`.padStart(2, '0'),
    separated: product.pivot.availability_confirmed_at ? 'Sim' : 'Não',
  }));
};

export const formatPayment = payment => {
  return {
    ...payment,
    formatted_installments: getPaymentValue(payment),
  };
};

export const formatStore = store => {
  return {
    ...store,
    address: {
      ...store.address,
      full_address: getFullAddress(store.address),
    },
  };
};

export const formatCustomer = customer => {
  return {
    ...customer,
    broken_email: breakString(customer.email, '@', 20),
    address: {
      ...customer.address,
      full_address: customer.address ? getFullAddress(customer.address) : null,
    },
  };
};
