import React, { useState } from 'react';
import { Row, Col, FormGroup, Alert, Card, CardBody } from 'reactstrap';

import { Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation-safe';

// import images
import { toast } from 'react-toastify';
// import profileImg from '../../assets/images/profile-img.png';
// import logoImg from '../../assets/images/logo.svg';
import api from '~/services/api';
import history from '~/services/history';
import logoBlue from '~/assets/images/logo-blue.svg';

const Register = () => {
  const [error, setError] = useState(null);

  document.title = 'Registrar | Viddy';

  const handleValidSubmit = async (
    _,
    { name, email, password, signup_code }
  ) => {
    try {
      setError(null);
      await api.post('auth/signup', {
        name,
        email,
        password,
        signup_code,
      });
      toast.success('Cadastro realizado com sucesso!');
      history.push('/login');
    } catch (err) {
      const { data } = err.response;
      if (
        data.email &&
        data.email[0] === 'O campo endereço de e-mail já está sendo utilizado.'
      )
        setError('O e-mail informado já está sendo utilizado.');
      toast.error('O e-mail informado já está sendo utilizado.');
      if (
        data.signup_code &&
        data.signup_code[0] === 'O campo signup code selecionado é inválido.'
      )
        setError('O código de vendedor informado é inválido.');
      toast.error('O código de vendedor informado é inválido.');
      if (
        data.password &&
        data.password[0] === 'O campo senha deve ter pelo menos 6 caracteres.'
      )
        setError('O campo senha deve ter pelo menos 6 caracteres.');
      toast.error('O campo senha deve ter pelo menos 6 caracteres.');
    }
  };

  return (
    <div className="new-layout-auth">
      <Row className="w-100 mx-0 my-4">
        <Col
          className="new-layout-logo-col justify-content-center"
          align="center"
        >
          <img className="new-layout-auth-logo" src={logoBlue} alt="" />
        </Col>
      </Row>
      <Row className="w-100 mx-0 my-4">
        <Col>
          <Card className="new-layout-register-card">
            <CardBody>
              <Row className="w-100 mx-0 my-4">
                <Col align="center">
                  <div className="new-layout-auth-welcome-msg">
                    <div className="d-block">
                      Faça seu <strong>cadastro</strong>
                    </div>
                    <div className="d-block">
                      para <strong>continuar no Viddy</strong>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="w-100 mx-0 px-4 py-4">
                <Col align="center">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleValidSubmit}
                  >
                    {error && (
                      <Row className="w-100">
                        <Col className="px-0">
                          <Alert color="danger">{error}</Alert>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <FormGroup>
                          <AvField
                            name="name"
                            label="Nome"
                            type="text"
                            required
                            placeholder="Digite seu nome"
                            errorMessage="Digite seu nome"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <FormGroup>
                          <AvField
                            name="email"
                            label="E-mail"
                            className="form-control"
                            placeholder="Digite seu e-mail"
                            type="email"
                            errorMessage="E-mail no formato inválido"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <FormGroup>
                          <AvField
                            name="password"
                            label="Senha"
                            type="password"
                            errorMessage="Digite sua senha"
                            required
                            placeholder="Digite sua senha"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="new-layout-auth-form-group">
                        <FormGroup>
                          <AvField
                            name="signup_code"
                            label="Código de vendedor"
                            type="text"
                            errorMessage="Digite o código de vendedor"
                            required
                            placeholder="Digite o código de vendedor"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="my-4">
                      <Col>
                        <button
                          className="new-layout-auth-btn register btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Cadastrar
                        </button>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="new-layout-register-login-msg mt-2 text-center">
                        <span className="d-inline mr-1">Já tem cadastro?</span>
                        <Link className="d-inline" to="/login">
                          Fazer Login
                        </Link>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
