import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  finishCartDelivery,
  finishCartLink,
} from '~/store/modules/cart/actions';
import { formatPrice, formatWhatsappPhoneNumber } from '~/util/format';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { getRole } from '~/util/getRole';
import { CreateShortenUrl } from '~/util/shortenUrl';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import { SaleMessageBadge } from '~/components/SaleStatusBadge/SaleMessageBadge';

const ConfirmationForm = ({ finishCartState }) => {
  const dispatch = useDispatch();

  const cartState = useSelector(({ cart }) => cart.cart);
  const roles = useSelector(({ user }) => user.profile.roles);
  const shareableToken = useSelector(({ cart }) => cart?.shareableCartToken);

  const subscriberId = localStorage.getItem('@VIDDY:X-Server');
  const subscriberViddyComercial = '504c2f33-f3af-411c-b6f4-c9c483f8b745';
  const viddyTax = 1.99;

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const [cartData, setCartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareableURL, setShareableURL] = useState(null);
  const [submited, setSubmited] = useState(false);

  const loadCartData = useCallback(async () => {
    const {
      data: { cart },
    } = await api.get(`${role}/carts/${finishCartState?.cart_id}`);

    setCartData(cart);
  }, [role, finishCartState]);

  useEffect(() => {
    setLoading(true);
    loadCartData().then(() => setLoading(false));
  }, [finishCartState, loadCartData]);

  const getPaymentTypeLabel = () => {
    if (finishCartState.payment_type === 0)
      return `${cartData?.store?.payment_gateway ? 'Cartão / Pix' : ''}${
        cartData?.store?.bnpl_payment_gateway ? ' / Pix Parcelado' : ''
      }`;

    if (finishCartState.data?.payment_type === 201) return 'Crédito';

    if (finishCartState.data?.payment_type === 202) return 'Débito';

    if (finishCartState.data?.payment_type === 203) return 'Dinheiro';

    if (finishCartState.data?.payment_type === 204) return 'Pix (POS)';

    if (finishCartState.data?.payment_type === 401) return 'Pago';

    return 'Não definido';
  };

  const rawShareableURL = useMemo(
    () =>
      `${window.location.origin}/meu-carrinho/?id=${cartState?.id}&token=${shareableToken}`,
    [shareableToken, cartState]
  );

  const calculateTotalProducts = () => {
    return finishCartState.products.reduce(
      (total, product) => total + product.value * product.prod_amount,
      0
    );
  };

  const confirmSaleRegister = async () => {
    if (cartState.is_for_delivery)
      api
        .get(`${role}/carts/${cartState?.id}/delivery/check-store-balance`)
        .catch(() => null);

    if (finishCartState.payment_type === 0) {
      dispatch(finishCartLink(finishCartState));
    } else {
      dispatch(finishCartDelivery(finishCartState));
    }

    setSubmited(true);
  };

  useEffect(() => {
    if (!submited) return;

    const onSubmited = async () => {
      if (finishCartState.payment_type !== 0) return;

      if (!(cartState?.id && shareableToken)) return;

      setShareableURL(
        await CreateShortenUrl(role, rawShareableURL, cartState?.id)
      );
    };

    onSubmited();
  }, [
    submited,
    shareableToken,
    cartState?.id,
    finishCartState.payment_type,
    rawShareableURL,
    role,
  ]);

  if (loading || (submited && !shareableURL))
    return (
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
            Resumo do Pedido
          </Row>
          <Row className="mb-4">
            <Loading customClassName="loading mx-auto" />
          </Row>
        </CardBody>
      </Card>
    );

  if (submited)
    return (
      <Container className="container new-layout wide">
        <Card
          className="card new-layout w-lg-75 w-xl-50 mx-auto"
          style={{ maxWidth: '650px' }}
        >
          <div className="relative w-100">
            <Link
              to="/"
              style={{
                position: 'absolute',
                top: '1rem',
                right: '0.8rem',
                zIndex: '9999',
              }}
            >
              <i className="las la-times" />
            </Link>
          </div>
          <CardBody className="card-body new-layout w-100">
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <h1 className="font-weight-700 font-size-20">
                  Link gerado com sucesso
                </h1>

                <p className="text-gray-700 font-size-14">
                  Pronto! Você já pode compartilhar o link de pagamento com o
                  cliente.
                </p>

                <p className="mb-0">Link:</p>
                <p className="mb-4 text-gray-700">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={shareableURL}
                    className="text-black"
                  >
                    {shareableURL}
                  </a>
                </p>

                <Row className="w-100 mx-0 flex-lg-nowrap flex-column">
                  <Button
                    color="primary"
                    size="lg"
                    type="button"
                    className="w-100 new-layout-btn new-layout-btn-transparent px-2 main d-flex justify-content-center align-items-center"
                    onClick={() => {
                      copyStringToClipboard(shareableURL, 'Link copiado!');
                    }}
                  >
                    <i className="las la-copy mr-2" />
                    Copiar link
                  </Button>
                  <a
                    className="mt-1 w-100 border border-primary px-2 new-layout-btn btn btn-primary main d-flex justify-content-center align-items-center"
                    target="_blank"
                    rel="noreferrer"
                    href={`
                  https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                    (cartData || cartState)?.client?.telephone
                  )}&text=Segue link para pagamento da sua compra: ${shareableURL}
                `}
                  >
                    <i className="las la-share mr-2" />
                    Compartilhar
                  </a>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );

  const totalAmount = Number(finishCartState.amount);
  const freight = Number(finishCartState.userFreight || 0);
  const discountGiftback = Number(
    finishCartState.useGiftback ? finishCartState.giftback_amount : 0
  );
  const finalTotal = Number(totalAmount + freight - discountGiftback);

  return (
    <>
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
            <h2 className="font-size-20">Resumo do Pedido</h2>
          </Row>
          <Row className="w-100 mx-0 mb-4">
            <Col
              xs={12}
              lg={6}
              className="border-lg-right pb-0 pb-lg-4 pb-lg-0 px-0 pr-lg-2"
            >
              <Row className="w-100 mx-0 font-size-16 mb-1">Cliente</Row>
              <Row className="w-100 mx-0">
                <Col className="px-0">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Nome
                  </Row>
                  <Row className="mx-0 font-size-14">
                    {cartData.client.name}
                  </Row>
                </Col>
                <Col className="px-0">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Whatsapp
                  </Row>
                  <Row className="mx-0 font-size-14">
                    {cartData.client.telephone}
                  </Row>
                </Col>
              </Row>
              <Row className="w-100 mx-0 font-size-16 mb-1 mt-3">Entrega</Row>
              <Row className="w-100 mx-0">
                {cartState.is_for_delivery && (
                  <Col className="px-0">
                    <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                      Endereço de coleta
                    </Row>
                    <Row className="mx-0 font-size-14">
                      {cartData.store.fantasy_name}
                    </Row>
                  </Col>
                )}
                <Col className="px-0">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    {cartState.is_for_delivery
                      ? 'Endereço de entrega'
                      : 'Endereço de retirada'}
                  </Row>
                  {cartState.is_for_delivery ? (
                    <Row className="mx-0 font-size-14">
                      {cartData.delivery_address.street_name},{' '}
                      {cartData.delivery_address.number} -{' '}
                      {cartData.delivery_address.city} -{' '}
                      {cartData.delivery_address.state}
                    </Row>
                  ) : (
                    <Row className="mx-0 font-size-14 text-break">
                      {cartState.store.fantasy_name}
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
            {cartData.is_cross && finishCartState.products.length > 0 && (
              <>
                <Row className="w-100 mx-0 font-size-16 mb-lg-4 mb-0 mt-4 new-layout-table-border-top pt-4 d-lg-none">
                  Produtos
                </Row>
                {finishCartState.products.map(product => (
                  <div key={product.id} className="mt-4 mt-lg-0">
                    {/*  mobile version  */}
                    <Row className="w-100 mx-0 d-lg-none mb-2">
                      <Col className="px-0">
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Nome:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {product.name}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Tamanho:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {product.size}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Cor:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {product.color}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Quantidade:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {`${product.prod_amount}`.padStart(2, 0)}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Preço Unitário:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {formatPrice(product.value.toFixed(2))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <div className="d-flex flex-row align-items-baseline">
                              <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                Valor Total:
                              </div>
                              <div className="new-layout-card-mobile value d-inline text-truncate">
                                {formatPrice(
                                  (product.value * product.prod_amount).toFixed(
                                    2
                                  )
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row className="w-100 mx-0 d-lg-none">
                  <div className="w-100 mt-2 mt-lg-4">
                    <div className="float-right">
                      <span className="font-size-14">
                        Valor total dos produtos:
                      </span>{' '}
                      <span className="font-size-14 font-weight-600">
                        {formatPrice(calculateTotalProducts())}
                      </span>
                    </div>
                  </div>
                </Row>
              </>
            )}
            <Col
              xs={12}
              lg={6}
              className="px-0 px-lg-4 border-top border-lg-top-0 pt-4 pt-lg-0 font-size-14"
            >
              {cartState.is_for_delivery && (
                <>
                  <Row className="w-100 mx-0 font-size-16 mb-1">Frete</Row>
                  <Row className="mx-0 font-size-12 my-2 text-gray-700">
                    Precificação do frete
                  </Row>
                  <Row className="mx-0">
                    <span className="text-gray-700">Custo da entrega</span>
                    <Col align="right">
                      {formatPrice(finishCartState.freight)}
                    </Col>
                  </Row>
                  {subscriberId === subscriberViddyComercial && (
                    <Row className="mx-0">
                      <span className="text-gray-700">Imposto da entrega</span>
                      <Col align="right">
                        {formatPrice(finishCartState.freight * 0.16)}
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <Row
                className={`w-100 mx-0 ${
                  cartState.is_for_delivery && 'border-top border-lg-top-0'
                } font-size-16 mb-1 ${
                  cartState.is_for_delivery && 'pt-4 mt-4'
                }`}
              >
                Pagamento
              </Row>
              <Row className="mx-0">
                <span className="text-gray-700">Forma de pagamento</span>
                <Col align="right">{getPaymentTypeLabel()}</Col>
              </Row>
              {(finishCartState.max_installments ||
                finishCartState.data?.installments) && (
                <Row className="mx-0">
                  <span className="text-gray-700">Parcelamento</span>
                  <Col align="right">
                    Em{' '}
                    {finishCartState.max_installments ||
                      finishCartState.data?.installments}
                    x
                  </Col>
                </Row>
              )}
              <Row className="mx-0">
                <span className="text-gray-700">Valor da compra</span>
                <Col align="right">
                  {subscriberId === subscriberViddyComercial
                    ? formatPrice(finishCartState.amount - viddyTax)
                    : formatPrice(finishCartState.amount)}
                </Col>
              </Row>

              {subscriberId === subscriberViddyComercial && (
                <Row className="mx-0">
                  <span className="text-gray-700">Taxa de serviço</span>
                  <Col align="right">{formatPrice(1.99)}</Col>
                </Row>
              )}

              {cartState?.is_for_delivery && (
                <Row className="mx-0">
                  <span className="text-gray-700">Frete a ser cobrado</span>
                  <Col align="right">
                    {formatPrice(finishCartState.userFreight)}
                  </Col>
                </Row>
              )}
              {finishCartState.useGiftback && (
                <Row className="mx-0">
                  <span className="text-gray-700">Desconto giftback</span>
                  <Col align="right">
                    <div className="font-size-14 font-weight-400 text-red">
                      -{formatPrice(finishCartState.giftback_amount)}
                    </div>
                  </Col>
                </Row>
              )}
              {finishCartState.data?.change ? (
                <>
                  <Row className="w-100 mx-0 font-size-16 mb-1 mt-3">Troco</Row>
                  <Row className="mx-0">
                    <span className="text-gray-700">Troco para</span>
                    <Col align="right">
                      {formatPrice(finishCartState.data.change)}
                    </Col>
                  </Row>
                </>
              ) : null}
              {!cartData.is_cross && (
                <Row className="mx-0 mt-3">
                  {' '}
                  <b>Total</b>
                  <Col align="right">
                    <b>{formatPrice(finalTotal)}</b>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {cartData.is_cross && finishCartState.products.length > 0 && (
            <>
              <Row className="w-100 mx-0 font-size-16 mb-4 mt-4 new-layout-table-border-top pt-4 d-none d-lg-flex">
                Produtos
              </Row>
              <Row className="w-100 mx-0 products-table-row header py-2 d-none d-lg-flex">
                <Col xs={1} className="font-size-12 font-weight-600">
                  #
                </Col>
                <Col xs={3} className="font-size-12 font-weight-600">
                  Produto ou referência
                </Col>
                <Col xs={2} className="font-size-12 font-weight-600">
                  Tamanho
                </Col>
                <Col xs={2} className="font-size-12 font-weight-600">
                  Cor
                </Col>
                <Col xs={2} className="font-size-12 font-weight-600">
                  Quantidade
                </Col>
                <Col xs={2} className="font-size-12 font-weight-600">
                  Preço unitário
                </Col>
              </Row>
              {finishCartState.products.map((product, index) => (
                <div key={product.id} className="mt-4 mt-lg-0">
                  <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                    <Col xs={1} className="font-size-12 font-weight-400">
                      {index + 1}
                    </Col>
                    <Col
                      xs={3}
                      className="font-size-14 font-weight-400 text-truncate"
                    >
                      {product.name}
                    </Col>
                    <Col
                      xs={2}
                      className="font-size-14 font-weight-400 text-truncate"
                    >
                      {product.size || 'Não informado!'}
                    </Col>
                    <Col
                      xs={2}
                      className="font-size-14 font-weight-400 text-truncate"
                    >
                      {product.color || 'Não informado!'}
                    </Col>
                    <Col xs={2} className="font-size-14 font-weight-400">
                      {product.prod_amount}
                    </Col>
                    <Col xs={2} className="font-size-14 font-weight-400">
                      {formatPrice(product.value.toFixed(2))}
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
          {Number(finishCartState.amount) +
            (Number(finishCartState.userFreight) || 0) >=
            1500 &&
            cartState?.is_for_delivery && (
              <SaleMessageBadge
                color="yellow"
                backgroundColor="rgba(236, 161, 32, 0.15)"
                title="Atenção!"
                style={{
                  marginTop: '20px',
                  marginBottom: '0',
                  textAlign: 'justify',
                }}
              >
                Este pedido tem um valor superior a R$ 1.500,00. Reforce sua
                atenção em confirmar a identidade do entregador que irá coletar
                o seu pedido. Qualquer dúvida, comunique o suporte logístico do
                Viddy.
              </SaleMessageBadge>
            )}
          {cartData.is_cross && (
            <>
              <Row className="w-100 mx-0 d-none d-lg-flex">
                <div className="w-100 mt-2 mt-lg-4">
                  <div className="float-right">
                    <span className="font-size-14">
                      Valor total dos produtos:
                    </span>{' '}
                    <span className="font-size-14 font-weight-600">
                      {formatPrice(calculateTotalProducts())}
                    </span>
                  </div>
                </div>
              </Row>
              <Row className="w-100 mx-0">
                <div className="w-100 mt-2">
                  <div className="float-right">
                    <span className="font-size-14">Valor total do pedido:</span>{' '}
                    <span className="font-size-14 font-weight-600">
                      {formatPrice(finalTotal)}
                    </span>
                  </div>
                </div>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <Row className="w-100 mx-0">
        <Col className="px-0" align="end">
          <Button
            color="primary"
            size="lg"
            type="button"
            className="float-right w-lg-25 new-layout-btn main"
            onClick={confirmSaleRegister}
          >
            Confirmar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmationForm;
