import React, { useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';

const AutoComplete = ({
  onChange,
  autofocus,
  defaultValue,
  location,
  setAddressComponents,
  error,
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (autofocus && inputRef.current?.focus) inputRef.current.focus();
  }, [autofocus]);

  useEffect(() => {
    if (!value?.value) return;

    const geoCode = geocodeByPlaceId(value.value.place_id);

    geoCode.then(res => {
      setAddressComponents(null);

      res[0].address_components.forEach(item => {
        if (item.types.includes('postal_code')) {
          setAddressComponents(prevState => ({
            ...prevState,
            postalCode: item.long_name,
          }));
        }

        if (item.types.includes('route')) {
          setAddressComponents(prevState => ({
            ...prevState,
            street: item.long_name,
          }));
        }

        if (item.types.includes('street_number')) {
          setAddressComponents(prevState => ({
            ...prevState,
            number: item.long_name,
          }));
        }

        if (item.types.includes('country')) {
          setAddressComponents(prevState => ({
            ...prevState,
            country: item.short_name,
          }));
        }

        if (item.types.includes('administrative_area_level_1')) {
          setAddressComponents(prevState => ({
            ...prevState,
            state: item.short_name,
          }));
        }

        if (item.types.includes('administrative_area_level_2')) {
          setAddressComponents(prevState => ({
            ...prevState,
            city: item.short_name,
          }));
        }

        if (item.types.includes('sublocality_level_1')) {
          setAddressComponents(prevState => ({
            ...prevState,
            neighborhood: item.short_name,
          }));
        }
      });
    });
  }, [value, setAddressComponents]);

  return (
    <div>
      <GooglePlacesAutocomplete
        selectProps={{
          autoFocus: true,
          defaultInputValue: defaultValue,
          value,
          onKeyDown: event => {
            if (onChange) onChange(event);
          },
          onChange: setValue,
          placeholder: 'Informe o endereço completo aqui',
          class: `form-control input-color ${error && 'border-danger'}`,
          noOptionsMessage: () => 'Nenhum endereço encontrado',
          loadingMessage: () => 'Procurando por endereços...',
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['br'],
          },
          location,
          radius: 50000,
        }}
        apiOptions={{
          language: 'pt',
          region: 'br',
        }}
        debounce={500}
      />
      {error && (
        <span
          className={error && 'text-danger'}
          style={{
            position: 'unset',
            whiteSpace: 'unset',
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default AutoComplete;
