import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  UncontrolledTooltip,
  CustomInput,
  Alert,
  Container,
  Card,
  CardBody,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import IconButton from '~/components/IconButton';
import { capitalize } from '~/util/format';
import { maskBuilder } from '~/util/maskTelephone';
import InputSelect from '~/components/Inputs/InputSelect';
import useRoles from '~/hooks/useRoles';
import PageHeader from '~/components/Common/PageHeader';
import { defineAlignment } from '~/util/newLayoutHelpers';
import { LG } from '~/util/breakpointWidths';

const NewUser = () => {
  const formRef = useRef(null);

  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [telephoneV, setTelephone] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const [storesSearched, setStoresSearched] = useState([]);
  const [selectedStore, setSelectedStore] = useState();

  const [userRoles, setUserRoles] = useState([]);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const userIsSalesman = useMemo(
    () => userRoles?.some(role => role?.slug === 'salesman'),
    [userRoles]
  );

  const userIsStockist = useMemo(
    () => userRoles?.some(role => role?.slug === 'stockist'),
    [userRoles]
  );

  const userIsAdmin = useMemo(
    () => userRoles?.some(role => role?.slug === 'administrator'),
    [userRoles]
  );

  const userIsStoreManager = useMemo(
    () => userRoles?.some(role => role?.slug === 'store-manager'),
    [userRoles]
  );

  const userIsConsultant = useMemo(
    () => userRoles?.some(role => role?.slug === 'digital-consultant'),
    [userRoles]
  );

  const userIsCrossManager = useMemo(
    () => userRoles?.some(role => role?.slug === 'cross-manager'),
    [userRoles]
  );

  const userIsFinancier = useMemo(
    () => userRoles?.some(role => role?.slug === 'financier'),
    [userRoles]
  );

  const userIsOnlyAdmin = useMemo(() => {
    if (userIsAdmin && !(userIsSalesman || userIsStockist)) {
      return true;
    }

    return false;
  }, [userIsAdmin, userIsSalesman, userIsStockist]);

  const [selectedStores, setSelectedStores] = useState([]);

  const [showStorePicker, setShowStorePicker] = useState(false);

  const [allStores, setAllStores] = useState(false);

  const [rolesError, setRolesError] = useState(null);

  const { isStoreManager, isSupport } = useRoles();

  const onSubmitHandler = async user => {
    try {
      user = {
        ...user,
        email: user.email.trim().toLowerCase(),
        name: capitalize(user.name.trim()),
      };

      setPasswordError(false);
      setPasswordConfirmError(false);

      const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

      const noStartingWhitespace = value => {
        if (value && value.startsWith(' ')) {
          return false;
        }
        return true;
      };

      const onlyLowerCase = value => {
        if (value && value !== value.toLowerCase()) {
          return false;
        }
        return true;
      };

      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Digite o nome do usuário')
          .test(
            'noStartingWhitespace',
            'O nome não pode começar com um espaço!',
            noStartingWhitespace
          ),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('Digite o e-mail do usuário')
          .test(
            'onlyLowerCase',
            'O e-mail deve conter apenas letras minúsculas!',
            onlyLowerCase
          ),
        telephone: Yup.string()
          .min(
            maskTelephoneLength,
            'Insira todos os dígitos do número do telefone'
          )
          .required('Digite o telefone do usuário'),
        password: Yup.string()
          .min(6, 'Digite no mínimo 6 caracteres para senha')
          .required('Senha é obrigatória'),
        confirm_password: Yup.string()
          .min(6, 'Digite no mínimo 6 caracteres para senha')
          .required('Confirmação de senha é obrigatória'),
      });

      await schema.validate(user, {
        abortEarly: false,
      });

      if (user.password !== user.confirm_password) {
        formRef.current.setFieldError(
          'confirm_password',
          'Senhas não são iguais'
        );
        setPasswordConfirmError(true);
        return;
      }

      if (!userRoles.length) {
        setRolesError('Cargo é obrigatório');
        return;
      }

      delete user.confirm_password;

      const indexRoles = userRoles.length ? userRoles.map(role => role.id) : [];

      const indexStores = selectedStores.map(({ value }) => value);

      user = {
        ...user,
        roles: indexRoles,
        managed_stores: indexStores,
        manages_all_stores: Boolean(userIsAdmin || allStores),
      };

      await api.post(
        `/${isAdmin || isSupport ? 'administrator' : 'store-manager'}/users`,
        user
      );

      toast.success('Usuário criado com sucesso!');

      history.push(`/usuarios`);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          if (e.path === 'password') setPasswordError(true);
          if (e.path === 'confirm_password') setPasswordConfirmError(true);
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err.response.data.email) {
        formRef.current.setFieldError('email', err.response.data.email[0]);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const [user] = useState();

  const ROLES = useMemo(() => {
    return [
      {
        id: 1,
        name: 'Vendedor',
        slug: 'salesman',
        checked: userIsSalesman,
        show: userRoles?.some(role => role?.slug === 'salesman'),
        available: true,
      },
      {
        id: 2,
        name: 'Estoquista',
        slug: 'stockist',
        checked: userRoles?.some(role => role?.slug === 'stockist'),
        show: userRoles?.some(role => role?.slug === 'stockist'),
        available: true,
      },
      {
        id: 3,
        name: 'Administrador',
        slug: 'administrator',
        checked: userRoles?.some(role => role?.slug === 'administrator'),
        show: true,
        available:
          (isAdmin || isSupport) &&
          !(
            userIsConsultant ||
            userIsCrossManager ||
            userIsFinancier ||
            userIsStoreManager ||
            userIsSalesman
          ),
      },
      {
        id: 4,
        name: 'Gerente',
        slug: 'store-manager',
        checked: userRoles?.some(role => role?.slug === 'store-manager'),
        show: true,
        available:
          !isStoreManager &&
          !(
            userIsConsultant ||
            userIsCrossManager ||
            userIsFinancier ||
            userIsSalesman ||
            userIsAdmin
          ),
      },
      {
        id: 5,
        name: 'Vendedor Digital',
        slug: 'digital-consultant',
        checked: userRoles?.some(role => role?.slug === 'digital-consultant'),
        show: true,
        available: !userRoles?.some(
          role => role?.slug !== 'digital-consultant'
        ),
      },
      {
        id: 9,
        name: 'Vendedor Cross',
        slug: 'cross-manager',
        checked: userRoles?.some(role => role?.slug === 'cross-manager'),
        show: true,
        available: !userRoles?.some(role => role?.slug !== 'cross-manager'),
      },
    ];
  }, [
    userRoles,
    isAdmin,
    isSupport,
    isStoreManager,
    userIsAdmin,
    userIsStoreManager,
    userIsFinancier,
    userIsConsultant,
    userIsCrossManager,
    userIsSalesman,
  ]);

  const [listOfRoles, setListOfRoles] = useState(ROLES);

  useEffect(() => {
    if (user?.managed_stores) {
      const stores = user.managed_stores.map(s => ({
        value: s.id,
        label: s.fantasy_name,
      }));

      setSelectedStores(stores);
    }
  }, [user]);

  useEffect(() => {
    if (allStores) {
      const loadStores = async () => {
        try {
          const { data } = await api.get(
            `/${
              isAdmin || isSupport ? 'administrator' : 'store-manager'
            }/stores/all`
          );

          const stores = data.map(s => ({
            value: s.id,
            label: s.fantasy_name,
          }));

          setSelectedStores(stores);
        } catch (err) {
          console.log(err);
        }
      };
      loadStores();
    }
  }, [allStores, isAdmin, isSupport]);

  const addRole = id => {
    const newRole = listOfRoles.find(role => role.id === id);
    const removePrev = listOfRoles.filter(role => role.id !== id);
    setListOfRoles(removePrev);
    setUserRoles([...userRoles, newRole]);
  };

  const removeRole = id => {
    const remove = userRoles.filter(role => role.id !== id);
    const addPrev = ROLES.find(role => role.id === id);

    setUserRoles(remove);
    setListOfRoles([...listOfRoles, addPrev]);
  };

  const handleRole = id => {
    if (userRoles.some(role => role.id === id)) {
      removeRole(id);
    } else {
      if (id === 5) {
        // if is consultant (bug fix)
        if (selectedStores.length) {
          if (!selectedStore) {
            setSelectedStore(selectedStores[0]);
          }

          setSelectedStores([selectedStores[0]]);
        }
      }
      addRole(id);
    }
  };

  const loadOptions = async (inputValue, cb) => {
    if (inputValue.length < 2) return;
    const { data } = await api.get('/stores', {
      params: { query: inputValue },
    });

    if (data.stores.length === 0) {
      return;
    }

    const filteredStores = data.stores.filter(s => {
      const isSelected = selectedStores.some(store => store.value === s.id);
      return isSelected === false;
    });

    const storesSearch = filteredStores.map(store => ({
      value: store.id,
      label: store.fantasy_name,
    }));

    setStoresSearched(prev => [...prev, ...data.stores]);

    cb(storesSearch);
  };

  const addToSelectedStores = option => {
    if (option) {
      const store = storesSearched.find(p => p.id === option.value);

      if (!store) return;

      const selectStore = {
        value: store.id,
        label: store.fantasy_name,
      };

      setSelectedStores([...selectedStores, selectStore]);
      setShowStorePicker(false);
    }
  };

  const removeFromSelectedStores = option => {
    const selectedStoresDraft = selectedStores.filter(s => {
      return s.value !== option.value;
    });

    setSelectedStores(selectedStoresDraft);
  };

  useEffect(() => {
    if (!userRoles.length && allStores) {
      setAllStores(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  const setStoreSelectable = async option => {
    const store = selectedStores.find(p => p.value === option.value);

    if (!store) return;

    const selectStore = {
      value: store.value,
      label: store.label,
    };

    setSelectedStore(selectStore);
  };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="NOVO USUÁRIO"
          backTo="/usuarios"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <Card className="card new-layout">
            <CardBody className="card-body new-layout px-4 px-lg-5">
              <Row>
                <Col lg={6} className="px-0 px-lg-4">
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup>
                        <Label>Nome</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Nome do usuário"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup>
                        <Label>Telefone</Label>
                        <InputMask
                          mask={maskBuilder(telephoneV)}
                          maskChar={null}
                          name="telephone"
                          value={telephoneV}
                          placeholder="Telefone do usuário"
                          type="text"
                          onChange={e => {
                            if (
                              e.target.value.length < 15 ||
                              e.target.value[5] === '9'
                            ) {
                              setTelephone(e.target.value);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="E-mail do usuário"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup className="mb-0">
                        <Label>Senha</Label>
                        <Input
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          onFocus={() => {
                            formRef.current.setFieldError('password', '');
                            setPasswordError(false);
                          }}
                          placeholder="Senha do usuário"
                        />
                        <div
                          style={{
                            position: 'relative',
                            left: 'calc(100% - 35px)',
                            bottom: passwordError ? '50px' : '32.5px',
                          }}
                        >
                          <IconButton
                            icon={`las ${
                              showPassword ? 'la-eye' : 'la-eye-slash'
                            }`}
                            color={showPassword ? '' : 'secondary'}
                            tip={
                              showPassword ? 'Esconder senha' : 'Mostrar senha'
                            }
                            forceTipe
                            click={() => setShowPassword(!showPassword)}
                            fontSize={25}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <FormGroup className="mb-0">
                        <Label>Confirmar senha</Label>
                        <Input
                          name="confirm_password"
                          type={showConfirmPassword ? 'text' : 'password'}
                          onFocus={() => {
                            formRef.current.setFieldError(
                              'confirm_password',
                              ''
                            );
                            setPasswordConfirmError(false);
                          }}
                          placeholder="Senha do usuário"
                        />
                        <div
                          style={{
                            position: 'relative',
                            left: 'calc(100% - 35px)',
                            bottom: passwordConfirmError ? '50px' : '32.5px',
                          }}
                        >
                          <IconButton
                            icon={`las ${
                              showConfirmPassword ? 'la-eye' : 'la-eye-slash'
                            }`}
                            color={showConfirmPassword ? '' : 'secondary'}
                            tip={
                              showConfirmPassword
                                ? 'Esconder senha'
                                : 'Mostrar senha'
                            }
                            forceTipe
                            click={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            fontSize={25}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} className="px-0 px-lg-4">
                  <Row className="w-100 mx-0">
                    <Col xs={6} className="px-0">
                      <Label>Cargo(s)</Label>
                      <i
                        id="rolesTooltip"
                        className="las la-info-circle font-size-18 ml-1"
                      />
                      {rolesError && (
                        <p className="text-danger">{rolesError}</p>
                      )}
                      <UncontrolledTooltip
                        placement="top"
                        target="rolesTooltip"
                      >
                        Um administrador não pode ser um vendedor. Um gerente
                        não pode ser administrador e/ou vendedor e vice-versa.
                        Um Consultor não pode ter nenhum outro cargo.
                      </UncontrolledTooltip>
                      {ROLES.map(role => (
                        <CustomInput
                          type="checkbox"
                          key={`${role.slug}-checkbox`}
                          id={`${role.slug}-checkbox`}
                          name={role.slug}
                          label={role.name}
                          checked={role.checked}
                          className={`text-primary mb-2 ${
                            !role.available
                              ? 'text-decoration-line-through'
                              : ''
                          } ${!role.show && 'd-none'}`}
                          invalid={rolesError}
                          disabled={!role.available}
                          onChange={() => {
                            setRolesError(null);
                            handleRole(role.id);
                          }}
                        />
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} className="my-2 my-lg-0 px-0 mt-4 px-lg-4">
                  <Row className="w-100 mx-0" style={{ height: '28px' }}>
                    <Col xs={6} className="px-0">
                      <Label>Loja(s)</Label>
                    </Col>
                    <Col xs={6} align="end" className="px-0">
                      <i
                        className={`las la-${
                          showStorePicker ? 'times' : 'plus'
                        } text-muted font-size-18 outline-none ${
                          allStores || userIsOnlyAdmin || !userRoles.length
                            ? 'd-none'
                            : ''
                        }`}
                        role="button"
                        tabIndex={0}
                        aria-label="add-store"
                        onClick={() => {
                          setShowStorePicker(!showStorePicker);
                        }}
                      />
                    </Col>
                  </Row>
                  {(isAdmin || isStoreManager || isSupport) &&
                    (userIsStockist ||
                      userIsStoreManager ||
                      userIsSalesman ||
                      userIsFinancier ||
                      userIsCrossManager ||
                      userIsConsultant) && (
                      <Row
                        style={{
                          height: '46px',
                        }}
                        className="w-100 mx-0 d-flex flex-row align-items-center"
                      >
                        <Col className="px-0">
                          <FormGroup>
                            {!showStorePicker ? (
                              <CustomInput
                                type="checkbox"
                                id="setAllStores"
                                name="setAllStores"
                                label="Todas as Lojas"
                                checked={allStores}
                                defaultValue={allStores}
                                className="text-primary mt-3 mb-4"
                                onChange={() => setAllStores(state => !state)}
                              />
                            ) : (
                              <InputSelectAsync
                                loadOptions={loadOptions}
                                name="ids"
                                onChange={addToSelectedStores}
                                placeholder="Nome da loja"
                                type="text"
                                isClearable
                                loadingMessage={() => 'Buscando lojas...'}
                                onBlur={() => {}}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      {!allStores && !userIsAdmin && userRoles.length > 0 ? (
                        <>
                          {selectedStores.length > 0 ? (
                            <>
                              {userRoles.length > 0 && (
                                <PerfectScrollbar className="new-layout-managed-stores-scrollbar edit">
                                  {selectedStores.map(store => (
                                    <div
                                      key={store.value}
                                      className="store-ticket my-2"
                                    >
                                      <Row className="w-100">
                                        <Col
                                          xs={11}
                                          className="text-truncate pr-0"
                                          id={`storeTooltip-${store.value}`}
                                        >
                                          {store.label}
                                        </Col>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={`storeTooltip-${store.value}`}
                                        >
                                          {store.label}
                                        </UncontrolledTooltip>
                                        <Col
                                          xs={1}
                                          className="px-0"
                                          align="end"
                                        >
                                          <i
                                            className="las la-times text-muted font-size-16 outline-none"
                                            role="button"
                                            tabIndex={0}
                                            aria-label="remove-store"
                                            onClick={() => {
                                              removeFromSelectedStores(store);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </PerfectScrollbar>
                              )}
                            </>
                          ) : (
                            <Alert color="danger">
                              Selecione pelo menos uma loja para prosseguir!
                            </Alert>
                          )}
                        </>
                      ) : (
                        !userIsAdmin &&
                        allStores && (
                          <Alert color="info">
                            Todas as lojas estão selecionadas.
                          </Alert>
                        )
                      )}
                      {!userRoles.length && (
                        <Alert color="info">
                          Selecione o cargo para exibir as lojas!
                        </Alert>
                      )}
                      {userIsOnlyAdmin && (
                        <Alert color="info">
                          Enquanto apenas o cargo de administrador for atribuído
                          ao usuário, não se faz necessário que se atribua uma
                          loja ao mesmo.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 mt-4">
                    <Col className="px-0">
                      <Label>Loja Matriz</Label>
                      <InputSelect
                        options={selectedStores}
                        name="stock_id"
                        onChange={setStoreSelectable}
                        isSearchable
                        menuShouldBlockScroll
                        placeholder="Selecione a loja"
                        type="text"
                        value={selectedStore}
                        loadingMessage={() => 'Buscando lojas...'}
                        onBlur={() => {}}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col
              align={defineAlignment(windowSize, LG, 'center', 'end')}
              className="px-0"
            >
              <Button
                color="primary"
                className="new-layout-btn main px-4 my-4"
                size="lg"
                disabled={!(userIsAdmin || selectedStores.length || allStores)}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default NewUser;
