import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import logoWhite from '~/assets/images/logo-white.svg';
import isDarkColor from '~/util/isDarkColor';
import Loading from '~/components/Loading';
// import SellerImage from '../BusinessCard/SellerImage';
import stdProfilePicture from '~/assets/images/user.png';
import { getRole } from '~/util/getRole';
import api from '~/services/api';
import { createVCardLink } from '~/util/vcard';

const useQuery = () => new URLSearchParams(useLocation().search);

function NewClientForm() {
  const query = useQuery();
  const xserver = query.get('token');
  const userId = query.get('user_id');
  const { customer_id: customerId } = useParams();

  const formRef = useRef(null);

  const [confirmation, setConfirmation] = useState(false);

  const [success, setSuccess] = useState(false);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    const { data } = await api.get(`/crm/seller-data/${userId}`, {
      headers: localStorage.getItem('@VIDDY:X-Server')
        ? undefined
        : {
            'x-server': xserver,
          },
    });

    setProfile(data.data);

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--primary',
      profile?.stock?.customize?.theme_color
    );
  }, [profile?.stock?.customize?.theme_color]);

  const submitHandler = async _rawFormData => {
    if (success) return;

    if (!confirmation) {
      toast.error('É necessário aceitar para prosseguir.');
      return;
    }

    try {
      getRole(profile.roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]);

      await api.put(
        `/crm/customers/${customerId}`,
        {
          draft: false,
        },
        {
          headers: localStorage.getItem('@VIDDY:X-Server')
            ? undefined
            : {
                'x-server': xserver,
              },
        }
      );

      setSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) return <Loading />;

  const themeIsWhite = profile?.stock?.customize?.theme_color === '#ffffff';
  const isDarkTheme = profile?.stock?.customize?.theme_color
    ? isDarkColor(profile?.stock?.customize?.theme_color)
    : false;

  return (
    <div className="crm-new-customer-form">
      <Row
        className="w-100 mx-0 mb-md-4"
        style={{
          height: '65px',
          backgroundColor: 'var(--primary)',
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <img
            src={profile?.stock?.customize?.brand_logo?.url || logoWhite}
            alt="logo"
            id="logo"
            style={{
              height: '60px',
              width: '160px',
            }}
          />
        </Container>
      </Row>

      <Col lg={6} className="mx-auto px-4 px-sm-5 px-lg-0 text-center">
        <Row className="m-0">
          <img
            className="mx-auto mt-5 mb-4"
            style={{ width: '10rem', height: '10rem' }}
            src={profile?.seller_image?.url || stdProfilePicture}
            alt="Foto do vendedor"
          />
        </Row>
        <h1 className="mb-0 font-size-18">{profile.name}</h1>
        <p className="mb-0 font-size-14">{profile.stock.fantasy_name}</p>
      </Col>

      <Form
        ref={formRef}
        onSubmit={submitHandler}
        className="px-4 px-sm-5 px-lg-0 crm-new-customer-form"
      >
        <Col
          lg={6}
          className="mt-0 mx-auto px-0 d-flex flex-column justify-content-center"
        >
          <FormGroup className="my-0">
            {success ? (
              <Row className="bg-white mx-1 my-5 p-4 text-center">
                <Col xs={12}>
                  <h1 className="mb-2 font-size-18">Cadastro confirmado!</h1>
                  <p className="m-0">
                    Agora você faz parte da minha comunidade e receberá ofertas
                    exclusivas.
                  </p>
                  <p className="m-0">
                    Salve meu contato na sua agenda e me chame sempre que
                    quiser!
                  </p>
                </Col>
              </Row>
            ) : (
              <Row className="bg-white mx-1 my-5 p-4">
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div
                    className="text-primary"
                    style={{ transform: 'scale(1.6)' }}
                  >
                    <input
                      type="checkbox"
                      id="confirmation"
                      name="confirmation"
                      className="text-primary z-index-0"
                      checked={confirmation}
                      onChange={e => setConfirmation(e.target.checked)}
                    />
                  </div>
                </Col>
                <Col xs={10}>
                  <label htmlFor="confirmation" className="m-0">
                    Aceito fazer parte do grupo de clientes do vendedor e
                    receber ofertas exclusivas por Whatsapp.
                  </label>
                </Col>
              </Row>
            )}
          </FormGroup>

          {!success ? (
            <button
              className="new-layout-btn main mt-0 mb-5 mx-auto w-100"
              type="submit"
              style={{
                backgroundColor: 'var(--primary)',
                color: isDarkTheme ? 'white' : 'black',
                border: themeIsWhite ? '1px solid #000000' : 'none',
                padding: '0.5rem 1rem',
              }}
              onClick={() => {
                if (!confirmation) return;

                createVCardLink({
                  name: `${profile?.name} - ${profile?.stock?.fantasy_name}`,
                  telephone: profile?.telephone,
                });
              }}
            >
              Confirmar e salvar contato
            </button>
          ) : null}
        </Col>
      </Form>
    </div>
  );
}

export default NewClientForm;
