import { takeLatest, put, all, call } from 'redux-saga/effects';

// Login Redux States
import history from '~/services/history';
import api from '~/services/api';
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

// User Redux States
import { saveUserRequest } from '../../user/actions';

// import { LoadChatsOnLogin, resetChatState } from '../../chat/actions';

import {
  LoadNotificationsRequest,
  // LoadUnreadMsgsRequest,
} from '../../notifications/actions';

// History service

// Api instance

function* loginUser({ payload: { email, password } }) {
  try {
    const {
      data: { token, server },
    } = yield call(api.post, 'auth/signin', {
      email,
      password,
    });

    localStorage.setItem('@VIDDY:Token', token);
    localStorage.setItem('@VIDDY:X-Server', server);

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers['X-Server'] = server;

    yield put(saveUserRequest());
    yield put(loginSuccess());
    // yield put(LoadChatsOnLogin());
    yield put(LoadNotificationsRequest());
    // yield put(LoadUnreadMsgsRequest());
  } catch (err) {
    yield put(apiError('Credenciais inválidas'));
    // if (err.response?.data.message === 'Unauthorized') {
    //   yield put(apiError('Credenciais inválidas'));
    // }
  }
}

function* logoutUser() {
  try {
    // const response = yield call(api.post, 'logout user in api', {});
    yield put(logoutUserSuccess());
    // yield put(resetChatState());
    delete api.defaults.headers.authorization;
    delete api.defaults.headers['X-Server'];

    const persistentAfterClear = localStorage.getItem('shouldHideNotification');

    localStorage.clear();

    localStorage.setItem('shouldHideNotification', persistentAfterClear);

    history.push('/login');
  } catch (err) {
    yield put(apiError(err.message));
  }
}

export function* watchUserLogout() {
  yield takeLatest(LOGOUT_USER, logoutUser);
}

export default all([
  takeLatest(LOGIN_USER, loginUser),
  takeLatest(LOGOUT_USER, logoutUser),
]);
