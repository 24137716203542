import React from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, CustomInput, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import api from '~/services/api';
import history from '~/services/history';
// import history from '~/services/history';

export default function SubscriberProfileCard({
  subscriber,
  isSelected,
  onSelect,
  onDelete,
}) {
  const disableAccount = async () => {
    try {
      await api.put(`/master/subscribers/${subscriber.id}/enable`);
      toast.success('Usuário habilitado/desabilitado com sucesso');
    } catch (err) {
      console.error(err);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Ocorreu um erro na sua requisição');
      }
    }
  };

  return (
    <Card className="card new-layout list-card grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 h-100 mx-0 d-none d-md-flex">
          <Col xs={1} align="center" className="h-100">
            <div
              className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap d-none"
              onClick={e => {
                e.stopPropagation();
              }}
              role="button"
              tabIndex={0}
              aria-label="checkbox-wrapper-div"
            >
              <CustomInput
                type="checkbox"
                id={`${subscriber.id}-checkbox`}
                className="text-primary d-none"
                checked={isSelected}
                defaultValue={isSelected}
                onChange={() => onSelect(subscriber, isSelected)}
              />
            </div>
          </Col>
          <Col
            xs={4}
            align="start"
            className="h-100 d-flex flex-column justify-content-center font-weight-600 text-gray-700 text-truncate"
          >
            {subscriber.fantasy_name}
          </Col>
          <Col
            xs={2}
            align="start"
            className="h-100 d-flex flex-column justify-content-center"
          >
            {subscriber.cnpj}
          </Col>
          <Col xs={3} align="center" className="h-100">
            <div className="new-layout-table-column-value h-100 text-truncate">
              {subscriber.formatted_created_at}
            </div>
          </Col>
          {/* <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div
              className={`new-layout-table-column-value h-100 font-weight-500 text-truncate ${
                subscriber?.status === 'active' ? 'text-success' : 'text-danger'
              }`}
            >
              {subscriber?.status === 'active' ? 'Em dia' : 'Inadimplente'}
            </div>
          </Col>
          <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div className="new-layout-table-column-value h-100 text-truncate">
              {subscriber.next_payment}
            </div>
          </Col> */}
          <Col xs={1} align="end" className="h-100">
            <div className="h-100 d-flex flex-row align-items-center justify-content-around">
              <IconButton
                className="pl-2"
                icon="las la-search"
                color="info"
                id={`details-btn-${subscriber.id}`}
                tip="Detalhes"
                click={() => {
                  history.push(`/assinantes/perfil/${subscriber.id}`);
                }}
              />
              <IconButton
                icon="fas fa-ban"
                className="pl-2"
                color="danger"
                id={`disable-btn-${subscriber.id}`}
                tip="Desativar"
                click={disableAccount}
              />
              <IconButton
                icon="las la-pen"
                className="pl-2"
                color="primary"
                id={`edit-btn-${subscriber.id}`}
                tip="Editar"
                click={() => {
                  history.push(`/assinantes/perfil/${subscriber.id}/editar`);
                }}
              />
              <IconButton
                icon="las la-trash"
                className="pl-2"
                color="danger"
                id={`delete-btn-${subscriber.id}`}
                tip="Excluir"
                click={() => onDelete(subscriber)}
              />
            </div>
          </Col>
        </Row>
        <Row className="w-100 h-100 mx-0 d-md-none">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col xs={9} align="start" className="px-0">
                <Row className="w-100 mx-0">
                  <div className="d-flex flex-row align-items-baseline w-100">
                    <div className="new-layout-card-mobile label d-inline">
                      Nome Fantasia:
                    </div>
                    <div
                      className="new-layout-card-list-strong-value ml-1 d-inline text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {subscriber.name}
                    </div>
                  </div>
                </Row>
              </Col>
              <Col xs={3} className="px-0" align="end">
                <div className="h-100 d-flex flex-row justify-content-around">
                  <IconButton
                    icon="las la-search"
                    color="info"
                    id={`details-mobile-btn-${subscriber.id}`}
                    tip="Detalhes"
                    click={() => {
                      history.push(`/assinantes/perfil/${subscriber.id}`);
                    }}
                  />
                  <IconButton
                    icon="fas fa-ban"
                    color="danger"
                    id={`disable-btn-${subscriber.id}`}
                    tip="Desativar"
                    click={disableAccount}
                  />
                  <IconButton
                    icon="las la-pen"
                    color="primary"
                    id={`edit-mobile-btn-${subscriber.id}`}
                    tip="Editar"
                    click={() => {
                      history.push(
                        `/assinantes/perfil/${subscriber.id}/editar`
                      );
                    }}
                  />
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`delete-mobile-btn-${subscriber.id}`}
                    tip="Excluir"
                    click={() => onDelete(subscriber)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    CNPJ:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {subscriber.cnpj}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Cadastro:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {subscriber.formatted_created_at}
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Status:
                  </div>
                  <div
                    className={`new-layout-card-mobile value d-inline ${
                      subscriber?.status === 'active'
                        ? 'text-success'
                        : 'text-danger'
                    }`}
                  >
                    {subscriber?.status === 'active' ? 'Em dia' : 'Inadimplente'}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Próximo Pagamento:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {subscriber.next_payment}
                  </div>
                </div>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
