import { all } from 'redux-saga/effects';

// Auth Sagas
import user from './user/saga';
import login from './auth/login/saga';
import forgetPassword from './auth/forgetpwd/saga';

// Sales Sagas
import sales from './sales/sagas';

// Layout Sagas
import layout from './layout/saga';

// Cart Sagas
import cart from './cart/sagas';

import chat from './chat/saga';
import notifications from './notifications/saga';
import shareable_cart from './shareable_cart/saga';
import search from './search/saga';

export default function* rootSaga() {
  return yield all([
    user,
    login,
    forgetPassword,
    sales,
    layout,
    cart,
    chat,
    notifications,
    shareable_cart,
    search,
  ]);
}
