import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Button,
  Fade,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Badge,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { toast } from 'react-toastify';
import SearchBox from '~/components/SearchBox';

import {
  openChatRequest,
  createChatRequest,
  SetLoadingUsers,
  LoadMoreUsers,
  SetLoadingMoreUsers,
  AddSelectedUser,
  RemoveSelectedUser,
  CreateNewGroupChat,
  RemoveAllSelectedUsers,
  setActiveTab,
  setSelectModeOn,
  setShowEditChatModal,
  ModifyUsersArray,
  SetUsersPagination,
} from '~/store/modules/chat/actions';

import stdProfilePicture from '~/assets/images/user.png';
import Loading from '~/components/Loading';

export default function UsersList() {
  const usersScrollbarRef = useRef();

  const dispatch = useDispatch();

  const chats = useSelector(({ chat }) => chat.chats);
  const users = useSelector(({ chat }) => chat.users);
  const selectedUsers = useSelector(({ chat }) => chat.selectedUsers);
  const chosenChatToManage = useSelector(({ chat }) => chat.chosenChatToManage);

  const usersPagination = useSelector(({ chat }) => chat.usersPagination);

  const loadingUsers = useSelector(({ chat }) => chat.usersLoading);

  const loadingMoreUsers = useSelector(({ chat }) => chat.loadingMoreUsers);
  const activeTab = useSelector(({ chat }) => chat.activeTab);
  const selectModeOn = useSelector(({ chat }) => chat.selectModeOn);

  const thereIsStillMoreUsersToBeLoaded = useMemo(() => {
    return (
      usersPagination?.last_page > 1 &&
      usersPagination?.current_page < usersPagination?.last_page
    );
  }, [usersPagination]);

  const [showLoadOldMsgsBtn, setShowLoadOldMsgsBtn] = useState(false);
  const [showNoMoreUsersMsg, setShowNoMoreUsersMsg] = useState(false);
  const [showGroupNameModal, setShowGroupNameModal] = useState(false);

  const [msgsScrollBarOldPosition, setMsgsScrollBarOldPosition] = useState(0);
  const [newGroupName, setNewGroupName] = useState('');

  const [msgsScrollbarFirstRollDone, setMsgsScrollbarFirstRollDone] =
    useState(false);

  const [searchQuery, setSearchQuery] = useState(null);

  const newGroupNameInput = document.getElementById('newGroupNameInput');

  const toggle = tab => {
    if (activeTab !== tab) dispatch(setActiveTab(tab));
  };

  const handleLoadMoreUsers = async () => {
    if (!loadingMoreUsers && !loadingUsers && thereIsStillMoreUsersToBeLoaded) {
      dispatch(SetLoadingMoreUsers(true));
      // setUsersScrollBarPosition(scrollbarRef.current.scrollHeight);
      const params = {
        page: usersPagination.current_page + 1,
      };
      const data = {
        params,
      };
      dispatch(LoadMoreUsers(data));
    }
  };

  const handleOpenChat = chat => {
    if (msgsScrollbarFirstRollDone) setMsgsScrollbarFirstRollDone(false);

    if (showLoadOldMsgsBtn) setShowLoadOldMsgsBtn(false);

    if (msgsScrollBarOldPosition !== 0) setMsgsScrollBarOldPosition(0);

    dispatch(openChatRequest({ chat }));

    // setCurrentChat(chat);
  };

  const handleCreateNewChat = async user => {
    try {
      const chat = chats.find(
        c => c.interlocutor && c.interlocutor?.id === user.id
      );

      if (!chat) {
        dispatch(createChatRequest(user));
        toggle('1');
      } else {
        toggle('1');
        handleOpenChat(chat);
      }
    } catch (err) {
      // console.log(err);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao tentar criar um novo chat. Tente novamente mais tarde.'
        );
      }
    }
  };

  const handleCreateNewGroupChat = () => {
    dispatch(CreateNewGroupChat(selectedUsers, newGroupName));
    dispatch(RemoveAllSelectedUsers());
    dispatch(setActiveTab('1'));
    setNewGroupName('');
    dispatch(setSelectModeOn(false));
    setShowGroupNameModal(false);
  };

  useEffect(() => {
    if (showGroupNameModal && newGroupNameInput) {
      newGroupNameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGroupNameInput, showGroupNameModal]);

  return (
    <>
      {users.length ? (
        <>
          <Row className="w-100 mx-0 mb-4">
            <Col>
              <SearchBox
                url="/users"
                query={searchQuery}
                setQuery={setSearchQuery}
                setData={data => {
                  dispatch(ModifyUsersArray(data.users));
                  dispatch(SetUsersPagination(data.meta));
                }}
                setLoading={flag => {
                  dispatch(SetLoadingUsers(flag));
                }}
                params={{ query: searchQuery }}
              />
            </Col>
          </Row>
          <Row className="w-100 mx-0 mb-4">
            {selectModeOn ? (
              <>
                <Col md={6} className="mb-2 mb-md-0">
                  <Button
                    className="new-layout-btn main w-100"
                    color="danger"
                    onClick={() => {
                      dispatch(setSelectModeOn(false));
                      dispatch(RemoveAllSelectedUsers());
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    id="continue-create-group-btn"
                    className="new-layout-btn main w-100"
                    color="success"
                    disabled={selectedUsers.length < 2}
                    onClick={() => {
                      if (chosenChatToManage) {
                        dispatch(setActiveTab('1'));
                        dispatch(setShowEditChatModal(true));
                      } else {
                        setShowGroupNameModal(true);
                      }
                    }}
                  >
                    Continuar
                  </Button>
                </Col>
              </>
            ) : (
              <Col>
                <Button
                  color="primary"
                  className="new-layout-btn main w-100"
                  onClick={() => {
                    dispatch(setSelectModeOn(true));
                  }}
                >
                  <i className="las la-plus font-size-16 mr-2" />
                  Novo Grupo
                </Button>
              </Col>
            )}
          </Row>

          <PerfectScrollbar
            className="user-list-scrollbar"
            // eslint-disable-next-line no-return-assign
            containerRef={el => (usersScrollbarRef.current = el)}
            onScrollUp={() => {
              if (showNoMoreUsersMsg) {
                setShowNoMoreUsersMsg(false);
              }
            }}
            onYReachEnd={() => {
              if (activeTab === '2' && thereIsStillMoreUsersToBeLoaded)
                handleLoadMoreUsers();
              if (!thereIsStillMoreUsersToBeLoaded) setShowNoMoreUsersMsg(true);
            }}
          >
            {loadingUsers ? (
              <Loading />
            ) : (
              <>
                {users.map(user => {
                  const isSelected = selectedUsers.some(
                    selectedUser => selectedUser.id === user.id
                  );

                  return (
                    <div
                      key={user.id}
                      className={`cursor-pointer highlight-on-hover outline-none ${
                        selectModeOn && isSelected ? 'highlight' : ''
                      }`}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (!selectModeOn) {
                          handleCreateNewChat(user);
                        } else if (isSelected) {
                          dispatch(RemoveSelectedUser(user));
                        } else {
                          dispatch(AddSelectedUser(user));
                        }
                      }}
                    >
                      <Row className="w-100 mx-0 py-3">
                        <Col
                          xs={3}
                          className="d-flex flex-column justify-content-center align-items-center"
                        >
                          <img
                            alt="Foto do perfil"
                            className="chat-profile-picture"
                            src={
                              user?.profile_picture?.url
                                ? user.profile_picture.url
                                : stdProfilePicture
                            }
                          />
                        </Col>
                        <Col
                          xs={7}
                          className="d-flex flex-column justify-content-center"
                        >
                          <Row className="w-100 mx-0 mb-1">
                            {user.roles.length ? (
                              user.roles.map(r => (
                                <Badge
                                  key={`${user.id}-${r.slug}`}
                                  className={`role-badge ${r.slug} p-1 mr-2`}
                                >
                                  {r.name}
                                </Badge>
                              ))
                            ) : (
                              <Badge className="gray-badge p-1 no-pointer-events">
                                Nenhum cargo atribuído
                              </Badge>
                            )}
                          </Row>
                          <Row className="w-100 mx-0 font-size-16 font-weight-600 text-gray-700 d-block text-truncate">
                            {user.name}
                          </Row>
                        </Col>
                        {selectModeOn && (
                          <Col
                            xs={2}
                            className="d-flex flex-column justify-content-center"
                          >
                            {isSelected ? (
                              <i className="bx bx-check-circle font-size-18 text-success" />
                            ) : (
                              <i className="bx bx-circle font-size-18 text-secondary" />
                            )}
                          </Col>
                        )}
                      </Row>
                    </div>
                  );
                })}
              </>
            )}

            {!thereIsStillMoreUsersToBeLoaded &&
              users.length >= usersPagination.per_page && (
                <Fade in={showNoMoreUsersMsg} tag="h5" className="mt-3">
                  <div
                    key="no-more-users"
                    className="chat-conversation-list d-flex flex-direction-row"
                  >
                    <div
                      className="overflow-hidden"
                      // body
                    >
                      <h5 className="no-more-users justify-content-center font-size-14 mb-1">
                        Não há mais usuários para serem exibidos.
                      </h5>
                    </div>
                  </div>
                </Fade>
              )}
          </PerfectScrollbar>
        </>
      ) : (
        <Row className="w-100 mx-0 pl-2">
          <Col className="font-size-16 font-weight-600 text-gray-600">
            Nenhum usuário
          </Col>
        </Row>
      )}

      <Modal isOpen={showGroupNameModal} className="">
        <ModalHeader
          toggle={() => {
            dispatch(RemoveAllSelectedUsers());
            setNewGroupName('');
            setShowGroupNameModal(false);
          }}
        >
          Quase lá...
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="groupName">Defina o nome do seu grupo</Label>
            <Input
              type="text"
              // disabled={loadingMessages}
              name="group-name"
              id="newGroupNameInput"
              placeholder="Digite aqui..."
              // autoFocus
              value={newGroupName}
              onChange={e => {
                setNewGroupName(e.target.value);
              }}
              onKeyDown={e =>
                newGroupName.length >= 3 &&
                e.keyCode === 13 &&
                handleCreateNewGroupChat()
              }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="new-layout-btn main"
            disabled={newGroupName.length < 3}
            onClick={handleCreateNewGroupChat}
          >
            Criar Grupo
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
