import React, { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getFullAddress } from '~/util/newLayoutHelpers';
import { formatPrice } from '~/util/format';
import CustomIcon from './CustomIcon';

export default function MyStoreCard({ myStore, balanceInformation }) {
  const createdAt = useMemo(() => {
    return format(parseISO(myStore.created_at), "dd/MM/yyyy 'às' HH':'mm", {
      locale: ptBR,
    });
  }, [myStore]);
  const fullAddress = useMemo(() => {
    return getFullAddress(myStore.address);
  }, [myStore]);

  return (
    <Card
      id="my-store-card"
      className="card new-layout dashboard-card-border w-100"
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0 mb-4">
          <Col
            xs={3}
            className="px-0 font-size-16 font-weight-600 text-gray-700 d-flex flex-column justify-content-end"
          >
            Sua Loja
          </Col>
          <Col
            xs={9}
            className="px-0 font-size-14 font-weight-400 text-gray-600 d-flex flex-column justify-content-end"
            align="end"
          >
            Criada em {createdAt}
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mt-5 mt-lg-0">
            <Row className="w-100 mx-0 font-size-16 font-weight-700 text-gray-700 mb-2 d-flex flex-row justify-content-center">
              {myStore.fantasy_name}
            </Row>
            <Row className="w-100 mx-0 font-size-12 font-weight-400 text-gray-700 mb-2 d-flex flex-row justify-content-center">
              Endereço: {fullAddress}
            </Row>
            <Row className="w-100 mx-0 font-size-12 font-weight-400 text-gray-700 mb-4 d-flex flex-row justify-content-center">
              Telefone: {myStore.telephone}
            </Row>
          </Col>
          <Col lg={6}>
            <Row className="w-100 mx-0">
              <Col xs={6} md={4} align="center" className="px-0">
                <CustomIcon icon="las la-user-tie" />
                <Row className="d-flex flex-row justify-content-center w-100 mx-0 mt-2 mb-1 font-size-14 font-weight-400 text-gray-600">
                  Responsável
                </Row>
                <Row className="d-flex flex-row justify-content-center w-100 mx-0 font-size-12 font-weight-600 text-gray-700">
                  {myStore.responsible_name}
                </Row>
              </Col>
              <Col xs={6} md={4} align="center" className="px-0 mb-2 mb-md-0">
                <CustomIcon icon="las la-store" />
                <Row className="d-flex flex-row justify-content-center w-100 mx-0 mt-2 mb-1 font-size-14 font-weight-400 text-gray-600">
                  CNPJ
                </Row>
                <Row className="d-flex flex-row justify-content-center w-100 mx-0 font-size-12 font-weight-600 text-gray-700">
                  {myStore.cnpj}
                </Row>
              </Col>
              <Col md={4} align="center" className="px-0">
                <CustomIcon icon="las la-truck" />
                <Row className="d-flex flex-row justify-content-center w-100 mx-0 mt-2 mb-1 font-size-14 font-weight-400 text-gray-600">
                  Serviço de Entrega
                </Row>
                <Row
                  className={`d-flex flex-row justify-content-center w-100 mx-0 font-size-12 font-weight-600 ${
                    myStore.status_on_delivery_service === 'READY'
                      ? 'text-gray-700'
                      : 'text-danger'
                  }`}
                >
                  {myStore.status_on_delivery_service === 'READY'
                    ? 'Realiza Entrega'
                    : 'Não realiza Entrega'}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {myStore.status_on_delivery_service === 'READY' && (
          <>
            <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-700 mt-4">
              Saldo no Serviço de Entrega
            </Row>
            <Row className="w-100 mx-0 font-size-16 font-weight-700 text-gray-700">
              {formatPrice(balanceInformation?.balance || 0)}
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
}
