import React, { useRef, useCallback, useState } from 'react';
// import { useField } from '@unform/core';
import { Button, Row } from 'reactstrap';
import CropModal from './ImageCrop/CropModal';

export default function ImageInput({
  // name,
  logo,
  setLogo,
  setFile,
  setRemoveLogo,
  ...rest
}) {
  const inputRef = useRef(null);
  // const { fieldName, registerField, defaultValue, error } = useField(name);
  // const [logo, setLogo] = useState(oldPreviewUrl);

  const [openCrop, setOpenCrop] = useState(false);

  const handleChange = useCallback(e => {
    const file = e.target.files?.[0];
    if (!file) {
      setLogo(null);
      return;
    }
    if (file) {
      setLogo(URL.createObjectURL(file));
      setOpenCrop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: 'files[0]',
  //     clearValue(ref) {
  //       ref.value = '';
  //       setLogo(null);
  //     },
  //     setValue(_, value) {
  //       setLogo(value);
  //     },
  //   });
  // }, [fieldName, registerField, inputRef]);

  return (
    <>
      <div className="d-block my-2">
        {logo && (
          <>
            <Row className="w-100 mx-0">
              <img src={logo} alt="Preview" width="100" />
            </Row>
            <Button
              className="new-layout-btn main d-flex align-items-center justify-content-center mb-2 font-size-12 my-2"
              color="danger"
              onClick={() => {
                inputRef.current.value = null;
                setLogo(null);
                setRemoveLogo(true);
              }}
            >
              Remover
            </Button>
          </>
        )}
      </div>
      {!logo && (
        <>
          <Button
            className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center justify-content-center mb-2 font-size-12"
            color="primary"
            onClick={() => {
              inputRef.current?.click();
              setRemoveLogo(false);
            }}
          >
            Upload
          </Button>
        </>
      )}
      <input
        type="file"
        ref={inputRef}
        className="d-none"
        onChange={handleChange}
        {...rest}
      />

      {/* {error && <span className={error && 'text-danger'}>{error}</span>} */}
      {openCrop && (
        <CropModal
          {...{ logo, setOpenCrop, setLogo, openCrop, inputRef, setFile }}
        />
      )}
    </>
  );
}
