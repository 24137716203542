import Echo from 'laravel-echo';
import { client } from 'pusher-js';

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: false,
  authEndpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
  enabledTransports: ['ws'],
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('@VIDDY:Token')}`,
      'X-Server':
        localStorage.getItem('@VIDDY:X-Server') &&
        `${localStorage.getItem('@VIDDY:X-Server')}`,
    },
  },
  client,
});
