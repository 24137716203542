import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import api from '~/services/api';

import usePageConfigs from './usePageConfigs';

const useGetData = role => {
  const { get_url, title, object_name, formatData } = usePageConfigs();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState(null);

  const setData = data => {
    const { [object_name]: rawItems, meta } = data;

    if (formatData) {
      const formattedData = formatData(rawItems);
      setItems(formattedData);
    } else {
      setItems(rawItems);
    }

    setPagination(meta);
  };

  useEffect(() => {
    document.title = `${title} | Viddy`;

    const loadData = async () => {
      if (get_url) {
        try {
          const { data } = await api.get(
            `${role ? `${role}${get_url}` : get_url}`
          );
          setData(data);
        } catch (err) {
          setError(err);
          if (err.response && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(err.message);
          }
        }
      }

      setLoading(false);
    };

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    items,
    setItems,
    pagination,
    setPagination,
    loading,
    setLoading,
    error,
    setError,
    setData,
    get_url,
  };
};

export default useGetData;
