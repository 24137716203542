import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  FormGroup,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  CustomInput,
  Alert,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import IntlCurrencyInput from 'react-intl-currency-input';
import { Link } from 'react-router-dom';
import PageHeader from '~/components/Common/PageHeader';
import { formatPrice, formatWhatsappPhoneNumber } from '~/util/format';
import { Form } from '@unform/web';
import { finishCartLink } from '~/store/modules/cart/actions';
import InputSelect from '~/components/Inputs/InputSelect';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import MobileHeader from '~/components/HorizontalLayout/Header/MobileHeader';
import ShowMoreText from '~/components/ShowMoreText';
import { getRole } from '~/util/getRole';
import api from '~/services/api';
import GiftbackCard from '~/components/GiftbackCard';
import ConfirmationSuitcaseForm from '../SalesRegister/form/ConfirmSuitcase';

const PaymentTab = ({ sale, onSubmit, paymentData, setPaymentData }) => {
  const dispatch = useDispatch();

  const roles = useSelector(({ user }) => user.profile.roles);

  const storeOnlinePaymentLimit = Number(
    sale.store.online_payments_value_limit
  );

  const { total, freight } = paymentData;

  const [maxInstallments, setMaxInstallments] = useState(1);
  const [installmentsOptions, setInstallmentsOptions] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);

  const initializeCheckoutMethods = useCallback(() => {
    return [true, true, !!sale?.store?.bnpl_payment_gateway];
  }, [sale?.store?.bnpl_payment_gateway]);

  const [checkoutMethods, setCheckoutMethods] = useState(
    initializeCheckoutMethods
  );
  useEffect(() => {
    setCheckoutMethods(initializeCheckoutMethods());
  }, [initializeCheckoutMethods]);
  const [checkoutMethodsError, setCheckoutMethodsError] = useState('');
  const [totalValueError, setTotalValueError] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isGiftbackActive, setIsGiftbackActive] = useState(false);

  const [saldoDisponivel, setSaldoDisponivel] = useState(0.0);
  const [saldoTotal, setSaldoTotal] = useState(0.0);
  const [valorMinimoResgate, setValorMinimoResgate] = useState(0.0);
  const [dataExpiracao, setDataExpiracao] = useState('');
  const [isLoadingGiftback, setIsLoadingGiftback] = useState(false);
  const isSuitcase = true;

  const handleGiftbackSwitchChange = isActive => {
    setIsGiftbackActive(isActive);
  };

  useEffect(() => {
    if (total > 0) {
      const getGiftbackData = async () => {
        if (!sale.id) return;

        setIsLoadingGiftback(true);

        try {
          const {
            data: { giftback },
          } = await api.get(
            `${getRole(roles)}/carts/${sale.id}/giftback/${total}`
          );
          setSaldoDisponivel(parseFloat(giftback.amount));
          setSaldoTotal(parseFloat(giftback.total_amount));
          setValorMinimoResgate(parseFloat(giftback.min_purchase));
          setDataExpiracao(giftback.expiration_date);
        } catch (error) {
          console.error('Erro ao buscar dados do Giftback:', error);
        } finally {
          setIsLoadingGiftback(false);
        }
      };

      getGiftbackData();
    }
  }, [total, sale, roles]);

  const loadShareableLink = useCallback(async () => {
    let display_payment = '';

    if (!checkoutMethods[0]) display_payment += 'hide-card';
    if (!checkoutMethods[1]) display_payment += 'hide-pix';
    if (!checkoutMethods[2]) display_payment += 'hide-bnpl';

    const giftbackData = isGiftbackActive
      ? {
          useGiftback: true,
          giftback_amount: saldoDisponivel,
        }
      : {};

    setPaymentData({
      ...paymentData,
      ...giftbackData,
    });

    dispatch(
      finishCartLink({
        max_installments: selectedInstallment?.value || 1,
        is_shareable: true,
        is_payment_checkout: true,
        amount: total,
        payment_type: 0,
        userFreight: freight,
        display_payment,
        cart_id: sale?.id,
        ...giftbackData,
      })
    );
  }, [
    dispatch,
    checkoutMethods,
    selectedInstallment,
    freight,
    total,
    sale,
    isGiftbackActive,
    saldoDisponivel,
    paymentData,
    setPaymentData,
  ]);

  const formatCurrency = value => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  useEffect(() => {
    // Resetar os valores ao montar o componente
    setPaymentData(prev => ({
      ...prev,
      total: 0,
    }));
    setSelectedInstallment(null);
    setMaxInstallments(1);
    setInstallmentsOptions([]);
  }, [setPaymentData]);

  useEffect(() => {
    let maxInstallments2 = 1;
    const totalWithFreight = parseFloat(total || 0) + parseFloat(freight || 0);

    if (sale?.store?.installment_limit_amount > 0) {
      if (totalWithFreight > 0) {
        maxInstallments2 = Math.floor(
          totalWithFreight / sale.store.installment_limit_amount
        );
      } else {
        maxInstallments2 = 1; // Caso o total seja 0 e apenas o frete seja considerado
      }
    } else {
      maxInstallments2 = 12; // Exibir até 12 parcelas se não houver installment_limit_amount
    }

    setMaxInstallments(Math.min(maxInstallments2, 12)); // Limitar a 12 parcelas
  }, [sale, total, freight]);

  useEffect(() => {
    const totalWithFreight = parseFloat(total || 0) + parseFloat(freight || 0);
    const options = [];

    if (totalWithFreight > 0) {
      const maxInstallmentsToUse = maxInstallments > 0 ? maxInstallments : 1;

      for (let i = 1; i <= maxInstallmentsToUse; i += 1) {
        const installmentValue = (totalWithFreight / i).toFixed(2);
        options.push({
          value: i,
          label: `${i}x de ${formatCurrency(installmentValue)}`,
        });
      }
    } else {
      // Se o valor total for zero ou negativo, mostrar apenas 1x com o valor inserido
      const inputValue = parseFloat(total || 0) + parseFloat(freight || 0);
      options.push({
        value: 1,
        label: `1x de ${formatCurrency(inputValue.toFixed(2))}`,
      });
    }

    setInstallmentsOptions(options);
    setSelectedInstallment(options[0]); // Selecionar automaticamente a primeira parcela
  }, [total, freight, maxInstallments]);

  useEffect(() => {
    // Verifica se o valor total é menor que installment_limit_amount
    const totalWithFreight = parseFloat(total || 0) + parseFloat(freight || 0);

    if (totalWithFreight < sale.store.installment_limit_amount) {
      const options = [
        {
          value: 1,
          label: `1x de ${formatCurrency(totalWithFreight.toFixed(2))}`,
        },
      ];
      setInstallmentsOptions(options);
      setSelectedInstallment(options[0]); // Seleciona automaticamente a única opção disponível
    }
  }, [total, freight, sale]);

  // Função para verificar se pelo menos um método está selecionado
  const atLeastOneMethodSelected = methods => methods.some(method => method);

  // Função para atualizar métodos de checkout
  const updateCheckoutMethods = index => {
    const updatedMethods = [...checkoutMethods];
    updatedMethods[index] = !updatedMethods[index];

    if (atLeastOneMethodSelected(updatedMethods)) {
      setCheckoutMethods(updatedMethods);
      setCheckoutMethodsError(null);
    } else {
      setCheckoutMethodsError('Ao menos um método deve estar selecionado.');
    }
  };

  return (
    <>
      <Row className="w-100 mx-0">
        <Row className="w-100 mx-0">
          <Col lg={6} className="d-flex flex-column px-0 pr-lg-4">
            <Card className="card new-layout">
              <CardBody className="card-body new-layout py-4 px-lg-5">
                <h2 className="font-size-20">Malinha</h2>
                <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-3">
                  <Label className="mb-0">
                    Descrição dos produtos enviados
                  </Label>
                </Row>
                <Row className="mx-0">
                  <ShowMoreText
                    content={sale.delivery_observation}
                    limit={200}
                  />
                </Row>
              </CardBody>
            </Card>

            <Card className="card new-layout h-100">
              <CardBody className="card-body new-layout pb-0 pt-4 px-lg-5">
                <h2 className="font-size-20">
                  {sale?.store?.payment_gateway && 'Cartão / Pix'}
                  {sale?.store?.bnpl_payment_gateway && ' / Pix Parcelado'}
                </h2>
                <Form>
                  <Row className="w-100 mx-0 d-flex flex-row align-items-center">
                    <Col xs={12} className="px-0 pr-md-2">
                      <FormGroup className="w-100 d-flex mb-1 flex-column">
                        <Label>Valor total da compra</Label>
                        <IntlCurrencyInput
                          inputmode="decimal"
                          currency="BRL"
                          name="finalValue"
                          id="finalValue"
                          onChange={(event, floatValue) => {
                            if (floatValue === 0) {
                              setErrorMessage(
                                'Para acrescentar o valor do giftback, insira um valor de compra.'
                              );
                            } else {
                              setErrorMessage('');
                            }

                            const totalWithFreight =
                              floatValue + parseFloat(freight || 0);

                            if (sale?.store?.installment_limit_amount > 0) {
                              let maxInstallments2 = 1;

                              if (
                                totalWithFreight >
                                sale.store.installment_limit_amount
                              ) {
                                maxInstallments2 = Math.floor(
                                  totalWithFreight /
                                    sale.store.installment_limit_amount
                                );
                              }

                              const finalMaxInstallments = Math.min(
                                maxInstallments2,
                                12
                              );
                              setMaxInstallments(finalMaxInstallments);
                            } else {
                              setMaxInstallments(12); // Exibir até 12 parcelas se não houver installment_limit_amount
                            }

                            setPaymentData({
                              ...paymentData,
                              total: floatValue,
                            });
                          }}
                          placeholder="Valor Unitário"
                          className={`dc-discount-input input-color form-control ${
                            totalValueError ? 'border-danger' : ''
                          }`}
                          value={parseFloat(total)}
                          onFocus={() => setTotalValueError(null)}
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                        {totalValueError ? (
                          <span className="text-danger">{totalValueError}</span>
                        ) : null}
                        {storeOnlinePaymentLimit !== 0 &&
                          total > storeOnlinePaymentLimit && (
                            <Alert color="info" className="mt-4 mb-0">
                              O valor desta venda ultrapassa o limite para
                              pagamento on-line, desse modo, apenas a venda
                              através do PIX será liberada para o cliente, entre
                              em contato com o gerente para aumentar este
                              limite.
                            </Alert>
                          )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} className="px-0 mb-1 px-0 mt-3 pr-md-2">
                      {(sale?.store?.payment_gateway ||
                        sale?.store?.bnpl_payment_gateway) &&
                        !(
                          storeOnlinePaymentLimit !== 0 &&
                          total > storeOnlinePaymentLimit
                        ) &&
                        (checkoutMethods[0] || checkoutMethods[2]) && (
                          <>
                            <Label>Quantidade de Parcelas</Label>

                            <i
                              id="max-installments-tooltip"
                              className="las la-info-circle font-size-18 font-size-16 text-secondary mb-2 ml-1 h-fit-content"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="max-installments-tooltip"
                            >
                              Esse é o número máximo de parcelas em que o
                              pagamento via Link de Pagamento poderá ser
                              efetuado.
                            </UncontrolledTooltip>
                            <InputSelect
                              name="installments"
                              className="common-select"
                              options={installmentsOptions}
                              value={selectedInstallment}
                              onChange={selectedOption => {
                                setSelectedInstallment(selectedOption);
                                setPaymentData({
                                  ...paymentData,
                                  installments: selectedOption.value,
                                });
                              }}
                              placeholder="Quantidade de parcelas"
                              singlePath
                            />
                          </>
                        )}
                    </Col>
                  </Row>
                  {(sale?.store?.payment_gateway ||
                    sale?.store?.bnpl_payment_gateway) &&
                    !(
                      storeOnlinePaymentLimit !== 0 &&
                      total > storeOnlinePaymentLimit
                    ) && (
                      <>
                        <Row className="mt-3 mb-3 mx-0">
                          <Col xs={12} className="px-0">
                            <Label>Link de Pagamento</Label>
                          </Col>

                          <Row
                            className="mx-0 px-1 mb-1 d-flex"
                            style={{ gap: '1.5rem' }}
                          >
                            {sale?.store?.payment_gateway && (
                              <CustomInput
                                type="checkbox"
                                id="credit-checkout-method"
                                name="creditCheckoutMethod"
                                className="text-primary z-index-0"
                                label="Crédito"
                                checked={checkoutMethods[0]}
                                onChange={() => updateCheckoutMethods(0)}
                              />
                            )}
                            {sale?.store?.payment_gateway && (
                              <CustomInput
                                type="checkbox"
                                id="pix-checkout-method"
                                name="pixCheckoutMethod"
                                className="text-primary z-index-0"
                                label="Pix"
                                checked={checkoutMethods[1]}
                                onChange={() => updateCheckoutMethods(1)}
                              />
                            )}
                            {sale?.store?.bnpl_payment_gateway && (
                              <CustomInput
                                type="checkbox"
                                id="bnpl-checkout-method"
                                name="bnplCheckoutMethod"
                                className="text-primary z-index-0"
                                label="Pix Parcelado"
                                checked={checkoutMethods[2]}
                                onChange={() => updateCheckoutMethods(2)}
                              />
                            )}
                          </Row>
                        </Row>
                      </>
                    )}
                  {checkoutMethodsError && (
                    <Row className="mx-0 mb-3 text-danger">
                      <Alert color="danger" className="mt-0 mb-3">
                        {checkoutMethodsError}
                      </Alert>
                    </Row>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} className="px-0 pb-4">
            <Card className="card new-layout h-100">
              <CardBody className="card-body new-layout pb-0 pt-4 px-lg-5">
                <h2 className="font-size-20">Frete</h2>
                <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-2">
                  <Label className="mb-0">Custo da entrega</Label>
                  <b className="ml-2 font-size-14">
                    {sale?.delivery?.freight !== null
                      ? formatPrice(sale?.delivery?.freight)
                      : 'Calculando...'}
                  </b>
                </Row>
                <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-2">
                  <Label className="mb-0">Custo da coleta</Label>
                  <b className="ml-2 font-size-14">
                    {sale?.return_delivery?.freight !== null
                      ? formatPrice(sale?.return_delivery?.freight)
                      : 'Calculando...'}
                  </b>
                </Row>
                <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-4">
                  <Label className="mb-0">Custo total</Label>
                  <b className="ml-2 font-size-14">
                    {sale?.return_delivery?.freight !== null
                      ? formatPrice(
                          Number(sale?.delivery?.freight) +
                            Number(sale?.return_delivery?.freight) || 0
                        )
                      : 'Calculando...'}
                  </b>
                </Row>
                {/* <Row className="w-100 mx-0 d-flex flex-row align-items-center mb-4">
                  <Label className="mb-0">Valor Total de Frete</Label>
                  <b className="ml-2 font-size-14">
                    {sale?.return_delivery?.freight !== null &&
                      sale?.delivery?.freight !== null
                      ? formatPrice(
                        Number(sale?.delivery?.freight) +
                        Number(sale?.return_delivery?.freight)
                      )
                      : 'Calculando...'}
                  </b>
                </Row> */}
                <Row className="w-100 mx-0 d-flex flex-row align-items-center">
                  <Label>Frete a ser cobrado do cliente</Label>
                  <IntlCurrencyInput
                    inputmode="decimal"
                    currency="BRL"
                    name="freightValue"
                    id="freightValue"
                    onChange={(event, floatValue) => {
                      setPaymentData({ ...paymentData, freight: floatValue });
                    }}
                    placeholder="Valor Unitário"
                    className="dc-discount-input input-color form-control"
                    value={parseFloat(freight)}
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      },
                    }}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>

          {sale?.store?.has_crmbonus_key && (
            <Col lg={6} className="d-flex flex-column px-0 pr-lg-4">
              <Card className="card new-layout h-100">
                <CardBody className="card-body new-layout pb-0 pt-4 px-lg-5">
                  <GiftbackCard
                    saldoDisponivel={saldoDisponivel}
                    saldoTotal={saldoTotal}
                    dataExpiracao={dataExpiracao}
                    valorMinimoResgate={valorMinimoResgate}
                    valorCompra={Number(total)}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    isLoadingGiftback={isLoadingGiftback}
                    isGiftbackActive={isGiftbackActive}
                    onSwitchChange={handleGiftbackSwitchChange}
                    isSuitcase={isSuitcase}
                  />
                </CardBody>
              </Card>
            </Col>
          )}

          <Col xs={12} className="px-0">
            <Card className="card new-layout">
              <CardBody
                className="card-body new-layout py-4 px-lg-4"
                style={{ backgroundColor: '#E8E8E8' }}
              >
                <Col>
                  <Row className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center flex-nowrap">
                    <Label className="ml-3 mb-0">Valor total do pedido</Label>
                    <b className="ml-2 font-size-14">
                      {formatPrice(
                        Number(freight || 0) +
                          Number(total || 0) -
                          (isGiftbackActive ? saldoDisponivel : 0)
                      )}
                    </b>
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Row>
      <Row className="w-100 mx-0 mb-4">
        <Col lg={2} className="w-100 px-0 ml-lg-auto">
          <Button
            color="primary"
            size="lg"
            type="submit"
            className="w-100 new-layout-btn main"
            onClick={() => {
              if (!Number(total) && !Number(freight || 0)) {
                setTotalValueError('Esse valor é inválido');
                return;
              }

              if (
                (!Number(total || 0) && !Number(freight || 0)) ||
                Number(total || 0) + Number(freight || 0) <
                  Number(sale.freight || 0)
              ) {
                toast.error(
                  'O valor total da venda não pode ser menor que o custo do frete para loja.'
                );
                return;
              }

              loadShareableLink();
              onSubmit();
            }}
          >
            Avançar
          </Button>
        </Col>
      </Row>
    </>
  );
};

const PaymentSuitcaseForm = ({
  setShouldReloadCart,
  setShouldShowPaymentSuitcaseform,
  sale,
}) => {
  const cartState = useSelector(({ cart }) => cart.cart);
  const [orderTab, setOrderTab] = useState(1);
  const [paymentData, setPaymentData] = useState({ installments: 1 });
  const [shareableURL, setShareableURL] = useState();

  const [submited, setSubmited] = useState(false);

  if (submited)
    return (
      <>
        <MobileHeader
          saleDetails
          arrowAction={() => setShouldShowPaymentSuitcaseform(false)}
          pageTitle="Link de pagamento"
        />
        <div className="page-content">
          <Container className="container new-layout wide">
            <Row className="w-100 mx-0 d-none d-lg-flex">
              <Col xs={10} className="px-0">
                <PageHeader
                  responsivePosition="start"
                  desktopPosition="start"
                  backTo={() => {
                    setShouldShowPaymentSuitcaseform(false);
                    setShouldReloadCart(true);
                  }}
                />
              </Col>
            </Row>

            <Card
              className="card new-layout w-lg-50 mx-auto"
              style={{ maxWidth: '650px' }}
            >
              <div className="relative w-100">
                <Link
                  to="/"
                  style={{
                    position: 'absolute',
                    top: '1rem',
                    right: '0.8rem',
                    zIndex: '9999',
                  }}
                >
                  <i className="las la-times" />
                </Link>
              </div>
              <CardBody className="card-body new-layout w-100">
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <h1 className="font-weight-700 font-size-20">
                      Link gerado com sucesso
                    </h1>

                    <p className="text-gray-700 font-size-14">
                      Pronto! Você já pode compartilhar o link de pagamento com
                      o cliente.
                    </p>

                    <p className="mb-0">Link:</p>
                    <p className="mb-4 text-gray-700">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={shareableURL}
                        className="text-black"
                      >
                        {shareableURL}
                      </a>
                    </p>

                    <Row className="w-100 mx-0 flex-lg-nowrap flex-column">
                      <Button
                        color="primary"
                        size="lg"
                        type="button"
                        className="w-100 new-layout-btn new-layout-btn-transparent px-2 main d-flex justify-content-center align-items-center"
                        onClick={() => {
                          copyStringToClipboard(shareableURL, 'Link copiado!');
                        }}
                      >
                        <i className="las la-copy mr-2" />
                        Copiar link
                      </Button>
                      <a
                        className="mt-1 w-100 border border-primary px-2 new-layout-btn btn btn-primary main d-flex justify-content-center align-items-center"
                        target="_blank"
                        rel="noreferrer"
                        href={`
                  https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                    (sale || cartState)?.client?.telephone
                  )}&text=Segue link para pagamento da sua compra: ${shareableURL}
                `}
                      >
                        <i className="las la-share mr-2" />
                        Compartilhar
                      </a>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    );

  return (
    <>
      <MobileHeader
        saleDetails
        arrowAction={() => setShouldShowPaymentSuitcaseform(false)}
        pageTitle={orderTab === 1 ? 'Dados de pagamento' : 'Resumo do pedido'}
      />
      <div className="page-content pt-4">
        <Container className="container new-layout wide">
          <Row className="w-100 mx-0 d-none d-lg-flex">
            <Col xs={10} className="px-0">
              <PageHeader
                responsivePosition="start"
                desktopPosition="start"
                backTo={() => setShouldShowPaymentSuitcaseform(false)}
              />
            </Col>
          </Row>

          <TabContent activeTab={orderTab}>
            <TabPane tabId={1}>
              {orderTab === 1 && (
                <PaymentTab
                  sale={sale}
                  onSubmit={() => setOrderTab(2)}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                />
              )}
            </TabPane>
            <TabPane tabId={2}>
              {orderTab === 2 && (
                <ConfirmationSuitcaseForm
                  cartData={sale}
                  sale={sale}
                  onSubmit={() => {
                    setSubmited(true);
                    setShouldReloadCart(true);
                  }}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                  setShareableURL={setShareableURL}
                />
              )}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  );
};

export default PaymentSuitcaseForm;
