/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { toast, Flip } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import api from '~/services/api';

import { setStock } from '~/store/modules/cart/actions';
import {
  formatCart,
  formatCustomer,
  formatPayment,
  formatProducts,
  formatStore,
} from '~/objectFormatters/Sale';
import PageHeader from '~/components/Common/PageHeader';
import SaleStatusBadge from '~/components/SaleStatusBadge';
import CartMainInfoCard from '~/components/CartDetailsComponents/Cards/CartMainInfoCard';
// import ProductsListCard from '~/components/CartDetailsComponents/Cards/ProductsListCard';
import FinalValueCard from '~/components/CartDetailsComponents/Cards/FinalValueCard';
import PaymentSituationCard from '~/components/CartDetailsComponents/Cards/PaymentSituationCard';
import DeliveryInfoCard from '~/components/CartDetailsComponents/Cards/DeliveryInfoCard';
import StockManagementButtons from '~/components/CartDetailsComponents/StockManagementButtons';
import Loading from '~/components/Loading';
import { getRole } from '~/util/getRole';

const MultipleStock = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const mountedRef = useRef(true);
  const toastID = useRef(null);

  const stock = useSelector(({ cart }) => cart.stock);
  const [client, setClient] = useState(null);
  const [products, setProducts] = useState([]);

  const [separated, setSeparated] = useState(false);
  const [done, setDone] = useState(false);
  const [fee, setFee] = useState(null);

  const [loading, setLoading] = useState(true);
  const [loadingCart, setLoadingCart] = useState(true);

  const [storeInfo, setStoreInfo] = useState();

  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState();

  const setData = cart => {
    const formattedStock = formatCart(cart);
    dispatch(setStock(formattedStock));
  };

  const setProductsData = productsArr => {
    const formattedProducts = formatProducts(productsArr);
    setProducts(formattedProducts);
  };

  const isAllChecked = useCallback(array => {
    const allChecked = array.filter(p => p.checked !== true);

    if (!allChecked.length) return true;
    return false;
  }, []);

  const loadCart = useCallback(
    async nextCart => {
      try {
        setLoadingCart(true);
        const [
          {
            data: { cart },
          },
          // {
          //   data: { products: productList },
          // },
        ] = await Promise.all([
          api.get(`/${role}/carts/${nextCart}`),
          // api.get(`/${role}/carts/${nextCart}/products`),
        ]);

        if (!mountedRef.current) return null;

        // const productsCheck = productList.map(p => ({
        //   ...p,
        //   checked: !!p.pivot.availability_confirmed_at,
        // }));

        // const checkedProducts = productsCheck.filter(p => p.checked !== true);

        if (cart?.status !== 6 && cart?.status >= 3 && cart.is_for_delivery) {
          setDone(true);
          setSeparated(true);
        }

        // if (cart?.status >= 2 && checkedProducts.length === products.length) {
        //   setDone(true);
        // }

        if (cart?.status > 0 && cart.payment_process) {
          const { data: paymentInfo } = await api.get(
            `${role}/carts/${nextCart}/payment`
          );

          const formattedFee = formatPayment(paymentInfo);
          setFee(formattedFee);

          if (
            cart.payment_type === 101 ||
            cart.payment_type === 102 ||
            ((cart.payment_type === 203 || cart.payment_type === 303) &&
              Number(
                paymentInfo.second_payment?.change || paymentInfo.change
              ) ===
                Number(
                  paymentInfo.second_payment?.amount || paymentInfo.amount
                ))
          ) {
            setSeparated(true);
          }
        }

        if (!cart.is_for_delivery || cart.payment_type === 102) {
          const {
            data: { store },
          } = await api.get(`stores/${cart.store.id}`);

          const formattedStore = formatStore(store);

          setStoreInfo(formattedStore);
        }

        // if (!isAllChecked(productsCheck)) {
        //   setDone(false);
        //   setSeparated(false);
        // }

        if (cart) {
          const {
            data: { client: clientInformation },
          } = await api.get(`clients/${cart.client.id}`);

          const formattedCustomer = formatCustomer(clientInformation);

          setClient(formattedCustomer);
        }

        setData(cart);
        // setProductsData(productsCheck);
        if (loading) {
          setLoading(false);
        }
        setLoadingCart(false);
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllChecked, products.length]
  );

  const handleFinishAndNext = useCallback(
    i => {
      setActiveTab(state.carts[i + 1]);

      loadCart(state.carts[i + 1]);
    },
    [loadCart, state.carts]
  );

  const handleFinishUnify = async () => {
    try {
      toastID.current = toast.info('Registrando chamada...', {
        autoClose: false,
      });

      await api.post(`${role}/carts/request-unified-delivery`, {
        carts: state.carts,
      });

      toast.update(toastID.current, {
        render:
          'Chamada registrada com sucesso para todos os carrinhos! Redirecionando...',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      history.push('/estoque');
    } catch (err) {
      toast.update(toastID.current, {
        render: err?.response?.data?.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    document.title = `Unificação de entregas | Viddy`;

    if (!state) {
      toast.error('Não permitido');
      history.push('/');

      return;
    }

    setActiveTab(state.carts[0]);

    loadCart(state.carts[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isAllChecked(products)) {
      setDone(true);
      if (
        stock &&
        (stock.payment_type === 101 ||
          stock.payment_type === 102 ||
          ((stock.payment_type === 203 || stock.payment_type === 303) &&
            fee &&
            Number(fee.second_payment?.change || fee.change) ===
              Number(fee.second_payment?.amount || fee.amount)))
      ) {
        setSeparated(true);
      }
    } else {
      setDone(false);
      setSeparated(false);
    }
  }, [fee, isAllChecked, products, stock]);

  const checkDoneAndChange = () => {
    if (!isAllChecked(products)) {
      toast.error('Confirme todos os produtos');
    } else {
      setDone(true);
      setSeparated(true);
      toast.success('Tudo certo! Pode finalizar.');
    }
  };

  useEffect(() => {
    if (stock?.status === 6) {
      const i = state.carts.indexOf(stock.id);

      if (i + 1 === state.carts.length || i === state.carts.length) {
        handleFinishUnify();
      } else {
        handleFinishAndNext(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="UNIFICAÇÃO DE ENTREGAS"
          responsivePosition="start"
          desktopPosition="start"
          backTo="/estoque"
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            <Nav
              className="w-100 d-flex flex-row justify-content-center justify-content-lg-start border-none mb-4"
              tabs
            >
              {state.carts.map((cart, index) => (
                <NavItem key={cart}>
                  <NavLink
                    className={`circle-nav-tab ${
                      index !== state.carts.length - 1 ? 'mr-4' : ''
                    } ${activeTab === cart ? 'active' : ''}`}
                  >
                    {index + 1}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {loadingCart ? (
              <Loading />
            ) : (
              <>
                <TabContent activeTab={activeTab}>
                  {state.carts.map((cart, index) => (
                    <TabPane tabId={cart} key={cart}>
                      <Row className="w-100 mx-0">
                        <Col lg={7} className="px-0 pr-lg-2">
                          <Row className="w-100 mx-0 d-lg-none">
                            <Col className="px-0">
                              <SaleStatusBadge cart={stock} cartDetails />
                            </Col>
                          </Row>
                          <Row className="w-100 mx-0">
                            <Col className="px-0">
                              <CartMainInfoCard
                                cart={stock}
                                customer={client}
                                setData={setData}
                                setProductsData={setProductsData}
                              />
                            </Col>
                          </Row>
                          {/* <Row className="w-100 mx-0">
                            <Col className="px-0">
                              <ProductsListCard
                                products={products}
                                cart={stock}
                                setProductsData={setProductsData}
                                setDone={setDone}
                                setSeparated={setSeparated}
                              />
                            </Col>
                          </Row> */}
                        </Col>
                        <Col lg={5} className="px-0 pl-lg-2">
                          <FinalValueCard cart={stock} />

                          <PaymentSituationCard
                            payment={fee}
                            cart={stock}
                            store={storeInfo}
                            done={done}
                            checkDoneAndChange={checkDoneAndChange}
                            separated={separated}
                            setSeparated={setSeparated}
                          />
                          <DeliveryInfoCard cart={stock} store={storeInfo} />
                        </Col>
                      </Row>
                      <StockManagementButtons
                        stock={stock}
                        separated={separated}
                        cartIndex={index}
                        cartsArrLength={state.carts.length}
                        handleFinishAndNext={handleFinishAndNext}
                        handleFinishUnify={handleFinishUnify}
                      />
                    </TabPane>
                  ))}
                </TabContent>{' '}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default MultipleStock;
