import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import RichTextEditor from 'react-rte';

import { toast } from 'react-toastify';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';
import history from '~/services/history';
import { toolbarConfig } from '~/util/textToolbarConfig';

const AddAnnouncement = ({
  addModalOpen,
  setAddModalOpen,
  setAnnouncements,
  className,
}) => {
  const formRef = useRef(null);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  useEffect(
    () =>
      addModalOpen
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open'),
    [addModalOpen]
  );

  const toggle = () => setAddModalOpen(!addModalOpen);

  const onSubmitHandler = async annoucement => {
    try {
      formRef.current.setErrors({});
      const description = value.toString('html');

      const schema = Yup.object().shape({
        title: Yup.string().required('Digite um título para o Anúncio'),
      });

      await schema.validate(annoucement, {
        abortEarly: false,
      });

      annoucement = { ...annoucement, description };

      await api.post(`/administrator/announcements`, annoucement);

      const {
        data: { announcements },
      } = await api.get(`/announcements`);

      setAnnouncements(announcements);

      toast.success('Anúncio registrado com sucesso!');
      toggle();
      document.body.style.cssText = null;

      history.push(`/anuncios`);
      setValue(RichTextEditor.createEmptyValue());
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  return (
    <div className={`align-self-end ${className}`}>
      <Button className="new-layout-btn main" onClick={toggle} color="primary">
        <i className="fas fa-plus font-size-16 align-middle mr-2" /> Novo
        Anúncio
      </Button>
      <Modal isOpen={addModalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Novo Anúncio</ModalHeader>
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Título</Label>
                  <Input
                    name="title"
                    type="text"
                    placeholder="Qual o título do Anúncio?"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Descrição</Label>
                  <RichTextEditor
                    name="description"
                    value={value}
                    onChange={a => setValue(a)}
                    toolbarConfig={toolbarConfig}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="px-5">
              Criar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAnnouncement;
