import { toast } from 'react-toastify';
import api from '~/services/api';

export const CreateShortenUrl = async (role, url, cart_id) => {
  try {
    const { data } = await api.post(`${role}/url-shortener/create`, {
      url,
      cart_id,
    });
    return `${data[0].url}`;
  } catch (err) {
    console.error(err);
    if (err?.response?.data?.message) toast.error(err?.response?.data?.message);
    else toast.error('Ocorreu um erro ao encurtar o link!');
  }
  return null;
};
