import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const TargetUserModal = ({ show, setShow }) => (
  <Modal isOpen={show}>
    <ModalBody className="d-flex flex-column align-items-center">
      <h5 className="mx-3 my-2 text-center">
        A venda vai ser contabilizada nos relatórios para o responsável pela
        venda.
      </h5>
      <Button
        className="new-layout-btn main mt-4 px-5"
        color="info"
        size="lg"
        onClick={() => setShow(false)}
      >
        Ok
      </Button>
    </ModalBody>
  </Modal>
);

export default TargetUserModal;
