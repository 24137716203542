import React, { useState } from 'react';
import {
  // Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

export default function PushCard({
  push,
  // canManage,
  // onEdit,
  // onDelete,
  // onBroadcast,
}) {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <Card
      className="card new-layout list-card alt"
      id={`collapse-btn-${push.id}`}
      role="button"
      onClick={() => {
        setShowDescription(!showDescription);
      }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col xs={11} className="card-title">
            {push.title}
          </Col>
          <Col xs={1} align="end">
            <i
              className={`las la-${
                showDescription ? 'minus' : 'plus'
              } text-secondary font-size-24`}
              id={`expand-${push.id}`}
            />
            <UncontrolledTooltip target={`expand-${push.id}`}>
              {showDescription ? 'Mostrar Menos' : 'Mostrar Mais'}
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Collapse isOpen={showDescription} toggler={`collapse-btn-${push.id}`}>
          <Row className="w-100 mx-0 my-4">
            <Col className="card-value">{push.formatted_description}</Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col align="end">
              <div>
                Total de usuários notificados :{' '}
                {push.recipients.length || 'todos'}
              </div>
              {/* {canManage && (
                <>
                  <Button
                    className="new-layout-btn main"
                    color="info"
                    onClick={() => onBroadcast(push)}
                  >
                    <i className="las la-paper-plane mr-2 font-size-18" />
                    Divulgar Anúncio
                  </Button>
                  <Button
                    className="new-layout-btn main mt-2 mt-lg-0 mx-0 ml-lg-2"
                    color="primary"
                    onClick={() => onEdit(push)}
                  >
                    <i className="las la-pen mr-2 font-size-18" />
                    Editar Anúncio
                  </Button>
                  <Button
                    className="new-layout-btn main mt-2 mt-lg-0 mx-0 ml-lg-2"
                    color="danger"
                    onClick={() => onDelete(push)}
                  >
                    <i className="las la-trash mr-2 font-size-18" />
                    Excluir Anúncio
                  </Button>
                </>
              )} */}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
