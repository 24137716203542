/* eslint-disable consistent-return */
import produce from 'immer';

import { RESET_SEARCH, SET_SEARCH } from './actionsTypes';

const initialState = {
  url: '',
  query: '',
  params: { page: 1 },
};

export default function Chat(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_SEARCH: {
        return initialState;
      }

      case SET_SEARCH: {
        draft.query = action.payload.query;
        break;
      }

      default:
        return state;
    }
  });
}
