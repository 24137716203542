import { formatPrice } from '~/util/format';

export const lineChart = {
  dataLabels: {
    enabled: false,
  },
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: 'false',
    dropShadow: {
      enabled: !0,
      color: '#000',
      top: 5,
      left: 7,
      blur: 6,
      opacity: 0.2,
    },
  },
  xaxis: {
    days: [],
    title: {
      text: 'Dia',
    },
  },
  yaxis: {
    labels: {
      formatter: (value, index) => {
        return `${index?.seriesIndex === 1 ? value : formatPrice(value)}`;
      },
    },
  },
  colors: ['#1c99d7'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    x: {
      show: true,
      formatter: value => {
        return `Dia ${value}`;
      },
    },
  },
  responsive: [
    {
      breakpoint: 1000,
      options: {
        xaxis: {
          labels: {
            show: false,
          },
          title: { text: undefined },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    },
  ],
};

export const donutChart = {
  dataLabels: {
    enabled: false,
  },
  noData: {
    text: 'Nenhuma venda registrada',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#f0000',
      fontSize: '14px',
      fontFamily: 'Roboto',
    },
  },
  labels: ['Finalizadas', 'Com Código', 'Pendentes', 'Canceladas'],
  colors: ['#60b05d', '#2544b1', '#eca120', '#eb5757'],
  legend: { show: false },
  plotOptions: {
    pie: {
      donut: {
        size: '70%',
      },
    },
  },
};

export const npsChart = {
  dataLabels: {
    enabled: false,
  },
  noData: {
    text: 'Dados não Disponíveis',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#f0000',
      fontSize: '14px',
      fontFamily: 'Roboto',
    },
  },
  labels: ['Promotores', 'Neutros', 'Detratores'],
  colors: ['#60b05d', '#eca120', '#eb5757'],
  legend: { show: false },
  plotOptions: {
    pie: {
      donut: {
        size: '70%',
      },
    },
  },
  // tooltip: {
  //   enabled: true,
  //   custom({ series, seriesIndex, _, w }) {
  //     let total = 0;
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const x of series) {
  //       total += x;
  //     }

  //     const selected = series[seriesIndex];

  //     return `${w.config.labels[seriesIndex]}: ${selected} (${(
  //       (selected / total) *
  //       100
  //     ).toFixed(2)}%)`;
  //   },
  // },
};
