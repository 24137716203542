import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';

function CardMaintenance({ children }) {
  return (
    <Col md="4" className="d-flex">
      <Card className="mt-4 maintenance-box w-100">
        <CardBody>{children}</CardBody>
      </Card>
    </Col>
  );
}

export default CardMaintenance;
