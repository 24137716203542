import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Card, Col, Container, Modal, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import PageHeader from '~/components/Common/PageHeader';
import IconButton from '~/components/IconButton';
import { formatPrice } from '~/util/format';
import { getRole } from '~/util/getRole';
import api from '~/services/api';
import DatePicker from '~/components/DatePicker';

const getMonthLabel = month => {
  switch (month) {
    case '01':
      return 'Janeiro';
    case '02':
      return 'Fevereiro';
    case '03':
      return 'Março';
    case '04':
      return 'Abril';
    case '05':
      return 'Maio';
    case '06':
      return 'Junho';
    case '07':
      return 'Julho';
    case '08':
      return 'Agosto';
    case '09':
      return 'Setembro';
    case '10':
      return 'Outubro';
    case '11':
      return 'Novembro';
    case '12':
      return 'Dezembro';
    default:
      return null;
  }
};

const getPercentage = (value, maxValue) => Math.floor(value / maxValue) * 100;

const getMonthFirstAndLastDay = () => {
  const initialStartDate = new Date();
  const finishStartDate = new Date(
    initialStartDate.getFullYear(),
    initialStartDate.getMonth() + 1,
    0
  );
  initialStartDate.setDate(1);

  return {
    dateStart: initialStartDate,
    dateFinish: finishStartDate,
  };
};

function CustomersWalletReport() {
  const echartRef = useRef();

  const userId = useSelector(({ user }) => user?.profile?.id);
  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(() => getRole(roles), [roles]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalNewCustomers, setTotalNewCustomers] = useState(0);
  const [visualization, setVisualization] = useState(0);
  const [shouldDisplayRanking, setShouldDisplayRanking] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [optionsReport, setOptionsReport] = useState(getMonthFirstAndLastDay());
  const [salesFromYear, setSalesFromYear] = useState({
    sales: [],
    maxSalesAmount: null,
  });

  const getRankings = useCallback(async () => {
    const { data } = await api.get(`/${role}/reports`, {
      params: {
        dateStart: optionsReport?.dateStart
          ? format(optionsReport?.dateStart, 'yyyy-MM-dd', {
              locale: ptBR,
            })
          : undefined,
        dateFinish: optionsReport?.dateFinish
          ? format(optionsReport?.dateFinish, 'yyyy-MM-dd', {
              locale: ptBR,
            })
          : undefined,
      },
    });

    const reduceRankingFromMonth = acc => (innerAcc, ranking) => ({
      ...innerAcc,
      [ranking.id]: {
        id: ranking.id,
        name: ranking.name,
        carts: ranking.carts + acc[ranking.id]?.carts || 0,
        amount: ranking.amount + (acc[ranking.id]?.amount || 0),
      },
    });

    setRankings(
      Object.values(
        data.ranking_sales.reduce(
          (acc, { salesmans_ranking }) => ({
            ...acc,
            ...salesmans_ranking.reduce(reduceRankingFromMonth(acc), {}),
          }),
          {}
        )
      ) || []
    );

    const maxSalesAmount = data.sales_from_year.reduce(
      (acc, monthSales) => Math.max(acc, monthSales.sales.amount_total),
      0
    );

    setSalesFromYear({
      sales: data.sales_from_year,
      maxSalesAmount,
    });

    setTotalAmount(
      data.sales_from_year.reduce(
        (acc, monthSales) => acc + monthSales.sales.amount_total,
        0
      )
    );

    setTotalCount(
      data.sales_from_year.reduce(
        (acc, monthSales) => acc + monthSales.sales.count_total,
        0
      )
    );
  }, [role, optionsReport]);

  const getTotalCustomers = useCallback(async () => {
    const { data } = await api.get(`/crm/reports/totalCustomers/${userId}`, {
      params: {
        dateStart: optionsReport?.dateStart
          ? format(optionsReport?.dateStart, 'yyyy-MM-dd', {
              locale: ptBR,
            })
          : undefined,
        dateFinish: optionsReport?.dateFinish
          ? format(optionsReport?.dateFinish, 'yyyy-MM-dd', {
              locale: ptBR,
            })
          : undefined,
      },
    });

    setTotalCustomers(data.totalCustomers);
    setTotalNewCustomers(data.totalNewCustomers);
  }, [userId, optionsReport]);

  useEffect(getTotalCustomers, [getTotalCustomers]);

  useEffect(getRankings, [getRankings]);

  useEffect(() => {
    if (shouldDisplayRanking) return;

    const totalAmountPerMonth = new Array(12);
    salesFromYear.sales.forEach(saleFromYear => {
      totalAmountPerMonth[Number(saleFromYear.month) - 1] =
        saleFromYear.sales.amount_total || 0;
    });

    echarts
      .init(echartRef.current, 'default', { height: 250, renderer: 'svg' })
      .setOption({
        grid: {
          top: '5%',
          left: '2%',
          right: '3%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: [
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ],
          },
        ],
        yAxis: {},
        series: [
          {
            type: 'bar',
            data: totalAmountPerMonth,
          },
        ],
      });
  }, [shouldDisplayRanking, getRankings, salesFromYear]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0 mb-2 mb-lg-2 d-flex flex-row align-items-center justify-content-end">
          <Col xs="6" lg="3" className="pl-0 pr-2 mb-2 mb-lg-0">
            <DatePicker
              value={optionsReport?.dateStart || null}
              onChange={selectedDate => {
                setOptionsReport(state => ({
                  ...state,
                  dateStart: selectedDate,
                }));
              }}
              mask="__/__/___"
              placeholder="Dia Inicial"
            />
          </Col>
          <Col xs="6" lg="3" className="pl-2 pr-0 mb-2 mb-lg-0">
            <DatePicker
              value={optionsReport?.dateFinish || null}
              onChange={selectedDate => {
                setOptionsReport(state => ({
                  ...state,
                  dateFinish: selectedDate,
                }));
              }}
              mask="__/__/___"
              placeholder="Dia Final"
            />
          </Col>
        </Row>
        <div className="reports-layout">
          <Card className="new-layout reports-layout-summary p-3">
            <h3 className="text-dark font-size-20">Resumo de Vendas</h3>
            <p className="mb-0 mx-2 mt-2 font-size-14">Valor Total Vendido</p>
            <h2 className="text-dark mx-3 font-size-16">
              {formatPrice(totalAmount)}
            </h2>
            <p className="mb-0 mx-2 mt-2 font-size-14">Número De Vendas</p>
            <h2 className="text-dark mx-3 font-size-16">{totalCount} vendas</h2>
            <p className="mb-0 mx-2 mt-2 font-size-14">Valor Médio Por Venda</p>
            <h2 className="text-dark mx-3 font-size-16">
              {formatPrice(totalCount ? totalAmount / totalCount : 0)}
            </h2>
          </Card>

          <Card className="new-layout reports-layout-ranking p-3">
            <h3 className="text-dark mb-4">Ranking de Vendas</h3>
            <Col align="center">
              {rankings.slice(0, 7).map((ranking, index) => (
                <Row
                  key={ranking.name}
                  className={`${
                    ranking.id === userId
                      ? 'ranking-first font-size-16'
                      : ' font-size-15'
                  } py-2`}
                >
                  <Col className="text-left" xs={7} sm={7}>
                    {index + 1}° {ranking.name}
                  </Col>
                  <Col className="text-right" xs={5} sm={5}>
                    {ranking.id === userId
                      ? formatPrice(ranking.amount)
                      : 'R$ ****'}
                  </Col>
                </Row>
              ))}
              {rankings.length > 0 && (
                <button
                  type="button"
                  onClick={() => setShouldDisplayRanking(true)}
                >
                  Ver Ranking Completo
                </button>
              )}
              {rankings.length === 0 && (
                <p className="px-0 text-gray-700 font-size-16">
                  Nenhuma venda encontrada!
                </p>
              )}
            </Col>
          </Card>

          <Card className="new-layout reports-layout-total-customers p-3">
            <h3 className="text-dark text-right text-md-left mb-4">
              Todos Clientes
            </h3>
            <h2 className="text-dark text-right text-md-center">
              {totalCustomers}
            </h2>
          </Card>

          <Card className="new-layout reports-layout-new-customers p-3">
            <h3 className="text-dark text-right text-md-left mb-4">
              Clientes Novos
            </h3>
            <h2 className="text-dark text-right text-md-center">
              {totalNewCustomers}
            </h2>
          </Card>

          <Card className="new-layout reports-layout-graphic p-3">
            <Row className="mb-4">
              <Col xs={10}>
                <h3 className="text-dark">Resumo de Vendas</h3>
              </Col>
              <Col xs={2} className="d-flex justify-content-end">
                <IconButton
                  icon="las la-signal"
                  color="dark"
                  fontSize={32}
                  className="mr-2"
                  iconStyle={
                    visualization === 0 ? { backgroundColor: '#E8E7E7' } : {}
                  }
                  iconClass="rounded"
                  click={() => setVisualization(0)}
                />
                <IconButton
                  icon="las la-signal"
                  color="dark"
                  fontSize={32}
                  iconStyle={
                    visualization === 1 ? { backgroundColor: '#E8E7E7' } : {}
                  }
                  iconClass="bx-rotate-90 rounded"
                  click={() => setVisualization(1)}
                />
              </Col>
            </Row>
            <div
              ref={echartRef}
              className={`${visualization !== 0 ? 'd-none' : 'px-5'}`}
            />
            <div className={`${visualization !== 1 ? 'd-none' : 'px-5'}`}>
              {salesFromYear.sales.map(saleFromYear => (
                <div key={saleFromYear.month}>
                  <Row className="mb-2">
                    <Col xs={10}>
                      <h3 className="font-size-16">
                        {getMonthLabel(saleFromYear.month)} -{' '}
                        {saleFromYear.year}
                      </h3>
                    </Col>
                    <Col xs={2} align="right">
                      <h3 className="text-primary font-size-16">
                        {saleFromYear.sales.count_total}
                      </h3>
                    </Col>
                  </Row>
                  <div
                    className="overflow-hidden mb-4"
                    style={{
                      borderRadius: '999px',
                      backgroundColor: '#CDD8FF',
                    }}
                  >
                    <div
                      className="bg-primary"
                      style={{
                        height: '0.5rem',
                        width: `${getPercentage(
                          saleFromYear.sales.amount_total,
                          salesFromYear.maxSalesAmount
                        )}%`,
                        borderRadius: '999px',
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Card>

          <Modal
            isOpen={shouldDisplayRanking}
            wrapClassName="crm-reports-modal"
          >
            <Container className="container new-layout wide px-4 overflow-scroll">
              <PageHeader
                pageTitle="Ranking de Vendas"
                responsivePosition="start"
                desktopPosition="start"
                backTo={() => setShouldDisplayRanking(false)}
              />

              <Col className="ranking-list mb-5">
                {rankings.map((ranking, index) => (
                  <Row
                    key={`${index}-${ranking.name}`} // eslint-disable-line
                    style={{
                      fontSize: ranking.id === userId ? '1.1rem' : '1rem',
                      borderRadius: '8px',
                    }}
                    className={`${
                      ranking.id === userId ? 'text-white bg-primary ' : ''
                    }p-2 mb-1`}
                  >
                    <Col className="text-left" xs={12} md={8}>
                      {index + 1}° {ranking.name}
                    </Col>
                    <Col className="text-right" xs={12} md={4}>
                      {ranking.id === userId
                        ? formatPrice(ranking.amount)
                        : 'R$ ****'}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Container>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default CustomersWalletReport;
