import { useState } from 'react';
import Input from './Input';

const { FormGroup } = require('reactstrap');

const TextAreaWithCounter = ({
  formRef,
  title,
  name,
  placeholder,
  defaultValue,
  onChange,
  maxSize,
  multiline,
  classNames,
  ...rest
}) => {
  const [characterCounter, setCharacterCounter] = useState(
    defaultValue?.length || 0
  );

  return (
    <FormGroup
      style={{ position: 'relative', marginBottom: '0' }}
      className={classNames?.formGroup}
    >
      {title && <label className={classNames?.label}>{title}</label>}
      <Input
        name={name}
        placeholder={placeholder}
        multiline={multiline}
        style={multiline ? { maxHeight: 'none', borderRadius: '8px' } : {}}
        defaultValue={defaultValue}
        onChange={e => {
          setCharacterCounter(e.target.value.length);
          if (formRef?.current)
            formRef.current.setFieldError(
              'tag',
              e.target.value.length > maxSize
                ? `Etiqueta não pode ultrapassar ${maxSize} caracteres.`
                : null
            );
          if (onChange) onChange(e);
        }}
        {...rest}
      />
      <span
        style={
          multiline
            ? {
                position: 'absolute',
                bottom: '0.25rem',
                right: '0.7rem',
                fontSize: '0.6rem',
                color: characterCounter > maxSize ? 'red' : '#b9bbc6',
              }
            : {
                position: 'absolute',
                top: '3.1rem',
                right: '0.7rem',
                fontSize: '0.6rem',
                color: characterCounter > maxSize ? 'red' : '#b9bbc6',
              }
        }
        className={classNames?.counter}
      >
        {characterCounter}/{maxSize}
      </span>
    </FormGroup>
  );
};

export default TextAreaWithCounter;
