import React from 'react';
import { Spinner } from 'reactstrap';

export default function Loading({
  remDiameter,
  message,
  customClassName,
  customColor,
  color,
  textStyle,
}) {
  const diameter = remDiameter || 3;
  return (
    <div className={customClassName || 'loading'}>
      <Spinner
        color={color || 'primary'}
        style={{
          height: `${diameter}rem`,
          width: `${diameter}rem`,
          color: customColor || '#2544b1',
        }}
        className="mb-3"
      />
      <div className={textStyle || 'font-weight-600 text-gray-700'}>
        {message || 'Carregando...'}
      </div>
    </div>
  );
}
