import React from 'react';
import { Card, CardBody, Col, Container, CustomInput, Row } from 'reactstrap';
import PageHeader from '~/components/Common/PageHeader';
import Loading from '~/components/Loading';
import SearchBox from '~/components/SearchBox';
import { defineTableItemBorders } from '~/util/newLayoutHelpers';
import InvoiceInfoCard from './InvoiceInfoCard';

export default function Subscription() {
  const loading = false;

  const rawInvoices = [
    {
      id: 1,
      code: 'ABCD1234',
      month: 'Janeiro',
      value: 549,
      status: 'paid',
    },
    {
      id: 2,
      code: 'ABCD1234',
      month: 'Fevereiro',
      value: 549,
      status: 'unpaid',
    },
    {
      id: 3,
      code: 'ABCD1234',
      month: 'Fevereiro',
      value: 549,
      status: 'unpaid',
    },
    {
      id: 4,
      code: 'ABCD1234',
      month: 'Fevereiro',
      value: 549,
      status: 'unpaid',
    },
    {
      id: 5,
      code: 'ABCD1234',
      month: 'Fevereiro',
      value: 549,
      status: 'unpaid',
    },
  ];

  const invoices = rawInvoices.map((invoice, index) => {
    return {
      ...invoice,
      border_class: defineTableItemBorders(index, rawInvoices.length),
    };
  });

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="ASSINATURA"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Card className="card new-layout">
          <CardBody className="card-body new-layout px-4 py-4">
            <Row className="w-100 mx-0 font-size-18 font-weight-600 text-gray-700 ">
              Gentil Negócios
            </Row>
            <Row className="w-100 mx-0 mb-4 font-size-14 font-weight-400 text-success ">
              Assinatura Ativa
            </Row>
            <Row className="w-100 mx-0">
              <Col
                xs={6}
                lg={3}
                className="pl-0 pr-2 pr-lg-4 mb-4 mb-lg-0 inner-border right"
              >
                <Row className="w-100 mx-0 mb-2 font-size-12 font-weight-600 text-gray-700">
                  Valor da Assinatura
                </Row>
                <Row className="w-100 mx-0 font-size-16 font-weight-400 text-gray-700">
                  R$ 249,90
                </Row>
              </Col>
              <Col xs={6} lg={3} className="px-4 inner-border lg-right">
                <Row className="w-100 mx-0 mb-2 font-size-12 font-weight-600 text-gray-700">
                  Última Renovação
                </Row>
                <Row className="w-100 mx-0 font-size-16 font-weight-400 text-gray-700">
                  09/03/2021
                </Row>
              </Col>
              <Col xs={6} lg={3} className="px-0 px-lg-4 inner-border right">
                <Row className="w-100 mx-0 mb-2 font-size-12 font-weight-600 text-gray-700">
                  Dados do Pagamento
                </Row>
                <Row className="w-100 mx-0 mb-2 font-size-12 font-weight-400 text-gray-700 text-truncate">
                  FULANO DO CARTÃO SEM LIMITE
                </Row>
                <Row className="w-100 mx-0 font-size-12 font-weight-400 text-gray-700">
                  **** **** **** 1234
                </Row>
              </Col>
              <Col xs={6} lg={3} className="pl-4 pr-0">
                <Row className="w-100 mx-0 mb-2 font-size-12 font-weight-600 text-gray-700">
                  Próximo Vencimento
                </Row>
                <Row className="w-100 mx-0 font-size-16 font-weight-400 text-gray-700">
                  09/04/2021
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <PageHeader
          pageTitle="FATURAS"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 w-lg-50 mx-0 mb-4">
          <Col className="px-0">
            <SearchBox
            // url={getURL}
            // query={searchQuery}
            // setQuery={setSearchQuery}
            // params={params}
            // setData={setData}
            // setLoading={setLoading}
            />
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
              <Col xs={1} align="center">
                <CustomInput
                  type="checkbox"
                  id="allInvoices"
                  className="text-primary z-index-0 d-none"
                  // checked={allCodes}
                  // defaultValue={allCodes}
                  // onChange={() => setAllCodes(!allCodes)}
                />
              </Col>
              <Col xs={10} className="px-0">
                <Row className="w-100 mx-0">
                  <Col xs={3} className="new-layout-table-column-label">
                    Código
                  </Col>
                  <Col xs={3} className="new-layout-table-column-label">
                    Mês de Referência
                  </Col>
                  <Col xs={3} className="new-layout-table-column-label">
                    Valor
                  </Col>
                  <Col xs={3} className="new-layout-table-column-label">
                    Situação
                  </Col>
                </Row>
              </Col>

              <Col xs={1} align="start">
                <div className="new-layout-table-column-label">Ações</div>
              </Col>
            </Row>

            {invoices.map(invoice => (
              <InvoiceInfoCard invoice={invoice} />
            ))}
          </>
        )}
      </Container>
    </div>
  );
}
