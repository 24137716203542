/* eslint-disable consistent-return */
import produce from 'immer';

import {
  SET_ACTIVE_TAB,
  SET_CART,
  SET_CLIENT,
  SET_FINISHED_SUCCESSFULLY,
  SET_OBSERVATION,
  SET_PAYMENT_DATA,
  SET_STEP,
  UPDATE_CART,
  SET_SHOW_CIELO_MODAL,
  SET_CIELO_AUTH_URL,
  SET_IP,
  SET_PIX_DATA_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: true,
  cart: null,
  activeTab: 1,
  step: 1,
  finished: false,
  finishedSuccessfully: true,
  observation: null,
  client: null,
  payment_process: null,
  showCieloModal: false,
  cieloAuthUrl: null,
  ip: null,
  qrcode: null,
};

export default function sharedCartReducer(
  state = initialState,
  { type, payload }
) {
  // if (type.includes('shareable')) {
  //   console.log('type', type);
  //   console.log('payload', payload);
  // }
  return produce(state, draft => {
    switch (type) {
      case SET_CART: {
        draft.cart = payload;
        draft.loading = false;
        break;
      }

      case UPDATE_CART: {
        draft.cart = {
          ...draft.cart,
          ...payload,
        };
        break;
      }

      case SET_ACTIVE_TAB: {
        draft.activeTab = payload;
        break;
      }

      case SET_STEP: {
        draft.step = payload;
        break;
      }

      case SET_IP: {
        draft.ip = payload;
        break;
      }

      case SET_FINISHED_SUCCESSFULLY: {
        draft.finishedSuccessfully = payload;
        break;
      }

      case SET_CLIENT: {
        draft.client = payload;
        draft.cart = {
          ...draft.cart,
          delivery_address: { ...payload?.address },
        };
        break;
      }

      case SET_OBSERVATION: {
        draft.observation = payload;
        break;
      }

      case SET_PAYMENT_DATA: {
        draft.payment_process = payload;
        break;
      }

      case SET_PIX_DATA_SUCCESS: {
        const { qrcode } = payload;
        if (qrcode) draft.qrcode = qrcode;
        break;
      }

      case SET_SHOW_CIELO_MODAL: {
        draft.showCieloModal = payload;
        break;
      }

      case SET_CIELO_AUTH_URL: {
        draft.cieloAuthUrl = payload;
        break;
      }

      default:
        return state;
    }
  });
}
