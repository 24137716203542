// @flow
import { all, call, takeLatest, put } from 'redux-saga/effects';

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
} from './actionTypes';

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
} from './actions';

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  if (layout === 'horizontal') {
    yield put(changeTopbarThemeAction('dark'));
    document.body.removeAttribute('data-sidebar');
    document.body.removeAttribute('data-sidebar-size');
  } else {
    yield put(changeTopbarThemeAction('light'));
  }
  yield call(changeBodyAttribute, 'data-layout', layout);
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: width }) {
  if (width === 'boxed') {
    yield put(changeSidebarTypeAction('icon'));
  } else {
    yield put(changeSidebarTypeAction('default'));
  }
  yield call(changeBodyAttribute, 'data-layout-size', width);
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: theme }) {
  yield call(changeBodyAttribute, 'data-sidebar', theme);
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  yield call(changeBodyAttribute, 'data-topbar', theme);
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  switch (sidebarType) {
    case 'compact':
      yield call(changeBodyAttribute, 'data-sidebar-size', 'small');
      yield call(manageBodyClass, 'sidebar-enable', 'remove');
      yield call(manageBodyClass, 'vertical-collpsed', 'remove');
      break;
    case 'icon':
      yield call(changeBodyAttribute, 'data-keep-enlarged', 'true');
      yield call(manageBodyClass, 'vertical-collpsed', 'add');
      break;
    case 'condensed':
      yield call(manageBodyClass, 'sidebar-enable', 'add');
      if (!isMobile) yield call(manageBodyClass, 'vertical-collpsed', 'add');
      break;
    default:
      yield call(changeBodyAttribute, 'data-sidebar-size', '');
      yield call(manageBodyClass, 'sidebar-enable', 'remove');
      if (!isMobile) yield call(manageBodyClass, 'vertical-collpsed', 'remove');
      break;
  }
}

/**
 * Toggles the rightsidebar
 */
function* toggleRightSidebar() {
  yield call(manageBodyClass, 'right-bar-enabled');
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  yield call(manageBodyClass, 'right-bar-enabled', 'add');
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  yield call(manageBodyClass, 'right-bar-enabled', 'remove');
}

export default all([
  takeLatest(CHANGE_LAYOUT, changeLayout),
  takeLatest(CHANGE_LAYOUT_WIDTH, changeLayoutWidth),
  takeLatest(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme),
  takeLatest(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType),
  takeLatest(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar),
  takeLatest(SHOW_RIGHT_SIDEBAR, showRightSidebar),
  takeLatest(HIDE_RIGHT_SIDEBAR, hideRightSidebar),
  takeLatest(CHANGE_TOPBAR_THEME, changeTopbarTheme),
]);
