import React from 'react';

import stdProfilePicture from '~/assets/images/user.png';

function SellerImage({ src, style, className }) {
  return (
    <img
      className={`seller-image ${className}`}
      style={{
        aspectRatio: '1',
        objectFit: 'cover',
        maxWidth: '12rem',
        width: '40%',
        borderRadius: '999999px',
        ...style,
      }}
      src={src?.url || stdProfilePicture}
      alt="Foto do vendedor"
    />
  );
}

export default SellerImage;
