/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import stdProfilePicture from '~/assets/images/user.png';
import useRoles from '~/hooks/useRoles';
import api from '~/services/api';
import { logoutUser } from '~/store/modules/auth/login/actions';

export default function UserOptions({ user }) {
  const dispatch = useDispatch();

  const { isSupport } = useRoles();

  const [subscriberName, setSubscriberName] = useState('');

  const hasXServer = !!localStorage.getItem('@VIDDY:X-Server');

  useEffect(() => {
    try {
      const getSubscriberName = async () => {
        const {
          data: { subscriber_name },
        } = await api.get(`/utils/available-delivery-services`);

        setSubscriberName(subscriber_name);
      };

      if (isSupport && hasXServer) {
        getSubscriberName();
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um problema ao determinar o nome do subscriber. Contate o administrador do sistema!'
        );
      }
    }
  }, [hasXServer, isSupport]);

  const [show, setShow] = useState(false);

  const onLogout = () => {
    dispatch(logoutUser());
  };
  return (
    <>
      <div
        className="p-0 header-profile-menu nav-item dropdown"
        id="user-options"
      >
        <div className="p-0 header-profile-navlink nav-link waves-effect cursor-pointer">
          <img
            className="pointer-events-none"
            alt="Foto do Perfil"
            src={
              user.profile_picture?.url
                ? user.profile_picture.url
                : stdProfilePicture
            }
          />
          <div>
            <span className="pointer-events-none">
              {user.name} <br />
              {isSupport && hasXServer && (
                <span className="text-muted fs-10 font-weight-bold">
                  {subscriberName}
                </span>
              )}
            </span>
          </div>

          {(!isSupport || !hasXServer) && (
            <i
              role="button"
              className="dripicons-chevron-down cursor-pointer pointer-events-none"
              onClick={e => e.stopPropagation}
            />
          )}
        </div>
      </div>
      {(!isSupport || !hasXServer) && (
        <UncontrolledPopover
          isOpen={show}
          target="user-options"
          className="user-options"
          trigger="click"
          toggle={() => {
            setShow(!show);
          }}
          placement="bottom-end"
          hideArrow
        >
          <PopoverBody className="user-popover-body">
            <NavLink to="/editar-dados">
              <div className="user-popover-option my-profile">Meu Perfil</div>
            </NavLink>

            <hr />
            <NavLink to="#" onClick={onLogout}>
              <div className="user-popover-option logout">Sair</div>
            </NavLink>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
}
