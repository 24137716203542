import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';

export default function DeliveryGroupCard({ group, onEdit, onDelete }) {
  return (
    <Card className="card new-layout list-card store">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col className="block-card card-title text-truncate ">
            <i className="las la-motorcycle font-size-16 mr-2 text-info" />
            {group.name}
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col className="pl-0" xs={11} align="end">
            <IconButton
              icon="las la-pen"
              color="info"
              id={`edit-delivery-group-${group.uuid}`}
              tip="Editar"
              click={() => {
                onEdit();
              }}
            />
          </Col>
          <Col className="pl-0" xs={1} align="end">
            <IconButton
              icon="las la-trash"
              color="danger"
              id={`delete-delivery-group-${group.uuid}`}
              tip="Excluir"
              click={() => {
                onDelete();
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
