import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import api from '~/services/api';

export default function SearchBox({
  url,
  query,
  setQuery,
  setData,
  setLoading,
  params,
  keepPageOnFilter,
  setReqRunning,
}) {
  const onSearch = async searchQuery => {
    if (searchQuery.length === 0 || searchQuery.length >= 3) {
      if (setReqRunning) {
        setReqRunning(true);
      }

      setLoading(true);

      const { data } = await api.get(url, {
        params: {
          ...params,
          page: keepPageOnFilter ? params.page : null,
          query,
        },
      });

      setData(data);
      setLoading(false);

      if (setReqRunning) {
        setReqRunning(false);
      }
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (query !== null && query !== undefined) {
        onSearch(query);
      }
    }, 500);

    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div className="search-box">
      <div className="position-relative">
        <Input
          type="text"
          className="form-control border-0 shadow-sm"
          placeholder="Buscar..."
          onChange={e => {
            setQuery(e.target.value);
          }}
          value={query || ''}
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  );
}
