import { Card, CardBody } from 'reactstrap';

function Table({ title, columns, rows }) {
  return (
    <Card className="card new-layout w-100 dashboard-card-border p-3">
      <CardBody className="card-body new-layout px-3 py-3 overflow-auto">
        {title && (
          <p className="px-0 font-size-16 font-weight-600 text-gray-700">
            {title}
          </p>
        )}
        {rows.error && <p className="px-0 text-gray-700">{rows.error}</p>}
        {!rows.error && !rows.length && (
          <p className="px-0 text-gray-700">Nenhum dado encontrado.</p>
        )}
        {!!rows.length && (
          <table className="w-100">
            <thead>
              <tr>
                {columns.map(column => (
                  <th className="dashboard-card-border">{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map(
                row =>
                  row?.length && (
                    <tr>
                      {row.map(data => (
                        <td className="dashboard-card-border">{data}</td>
                      ))}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </CardBody>
    </Card>
  );
}

export default Table;
