import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import history from '~/services/history';

export default function NotificationCard({ notification, onMarkAsRead }) {
  return (
    <Card
      className={`card new-layout list-card alt ${
        notification.link !== '#' ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (notification.link) {
          history.push(notification.link);
        }
      }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col xs={10} className="card-title">
            <i
              className={`las la-exclamation-circle ${notification.icon_color} font-size-18 mr-2`}
            />
            {notification.message}
          </Col>
          <Col xs={2} align="end">
            <IconButton
              icon={notification.unread ? 'las la-circle' : 'fas fa-circle'}
              color={notification.unread ? 'secondary' : 'success'}
              id={`mark-as-read-btn-${notification.uuid}`}
              iconClass={!notification.unread ? 'pointer-events-none' : ''}
              click={e => {
                onMarkAsRead(notification);
                e.stopPropagation();
              }}
              fontSize={14}
              label={notification.unread ? 'Não lida' : 'Lida'}
              labelClass={`mr-2 ${
                notification.unread ? 'text-secondary' : 'text-success'
              } d-none d-lg-inline`}
              labelOnLeft
            />
          </Col>
        </Row>
        <Row className="w-100 mx-0 mt-2">
          <Col className="card-value">{notification.formatted_created_at}</Col>
        </Row>
      </CardBody>
    </Card>
  );
}
