import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Container,
  Col,
  Row,
  UncontrolledTooltip,
  CustomInput,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { Form } from '@unform/web';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import DeleteModal from '~/components/Modals/DeleteModal';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DropdownFilter from '~/components/DropdownFilter';
import { referralCodesOrdenations } from '~/util/filters';
import DatePicker from '~/components/Inputs/DatePicker';
import Loading from '~/components/Loading';
import BatchActions from '~/components/BatchActions';
import useGetData from '~/hooks/useGetData';
import useBatchSelect from '~/hooks/useBatchSelect';
import GenerateMonthlyReport from './GenerateMonthlyReport';
import ReferralCodeCard from './ReferralCodeCard';
import AddReferralCode from './AddReferralCode';

const ReferralCodes = () => {
  const formMonthRef = useRef(null);

  const {
    items: codes,
    pagination,
    loading,
    setLoading,
    setData,
  } = useGetData();

  const {
    allSelected: allCodes,
    setAllSelected: setAllCodes,
    selectedItems: selectedCodes,
    setSelectedItems: setSelectedCodes,
    handleSelect,
  } = useBatchSelect(codes);

  const [searchQuery, setSearchQuery] = useState(null);
  const [codeToBeDeleted, setCodeToBeDeleted] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [sortReferralCodes, setSortReferralCodes] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectModeOn, setSelectModeOn] = useState(false);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isAdmin = roles.some(r => r.slug === 'administrator');

  const getURL = useMemo(() => {
    return 'administrator/referral-codes';
  }, []);

  const deleteURL = useMemo(() => {
    return `administrator/referral-codes/${codeToBeDeleted?.id}`;
  }, [codeToBeDeleted]);

  const batchDeleteURL = `/administrator/referral-codes/`;

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
      sort_by: sortReferralCodes ? 'carts_count' : null,
      month: selectedPeriod ? format(selectedPeriod, 'yyyy-MM') : null,
    };
  }, [pagination, searchQuery, sortReferralCodes, selectedPeriod]);

  const isAllowedToBatch = useMemo(() => {
    if (isAdmin) {
      return true;
    }
    return false;
  }, [isAdmin]);

  const handleGenerateDailyCodeReport = async code => {
    try {
      const selectedCode = code;

      toast.info('Estamos gerando seu relatório...');

      await api
        .get(`administrator/referral-codes/${selectedCode.id}/report`, {
          responseType: 'blob',
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = url;
          tempLink.setAttribute(
            'download',
            `Relatório de Código | ${selectedCode.name}.pdf`
          );
          document.body.appendChild(tempLink);
          tempLink.click();
        });

      toast.success('Relatório gerado, baixando arquivo.');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Não foi possível gerar o relatório.');
      }
    }
  };

  const handleSearchByMonth = async () => {
    setLoading(true);

    const { data } = await api.get('administrator/referral-codes', {
      params,
    });

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    handleSearchByMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="CÓDIGOS DE INDICAÇÃO"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-2 mb-lg-4">
          <Col className="px-0 col-xxl-5 mb-3 mb-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0 col-xxl-7" lg={6}>
            <Row className="w-100 mx-0 d-flex flex-row justify-content-end">
              <Col
                className="px-0 order-2 order-lg-1 d-flex flex-column align-items-end"
                lg={8}
              >
                <GenerateMonthlyReport />
              </Col>
              <Col
                className="col-xxl-2 px-0 mb-2 mb-lg-0 ml-lg-2 order-1 order-lg-2 d-flex flex-column align-items-end"
                lg={3}
              >
                <AddReferralCode
                  show={showAddModal}
                  setShow={setShowAddModal}
                  setData={setData}
                  getURL={getURL}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 pr-lg-2 order-2 order-lg-1" lg={4}>
            <Row className="w-100 mx-0">
              <Col className="px-0 pr-lg-2" xs={6}>
                <Form
                  ref={formMonthRef}
                  onSubmit={handleSearchByMonth}
                  className="d-flex flex-row align-items baseline h-37px"
                >
                  <span className="text-left mt-0 d-inline text-muted font-size-14 mr-2 h-50 mt-2">
                    Filtros
                  </span>
                  <DatePicker
                    name="month"
                    placeholderText="Mês"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    triggerSubmit={date => {
                      setSelectedPeriod(date.month);
                    }}
                    isClearable
                    maxDate={new Date()}
                    showDisabledMonthNavigation
                    height={37}
                  />
                </Form>
              </Col>
              <Col className="px-0 pl-lg-2" xs={6}>
                <DropdownFilter
                  additionalClassName="float-right float-lg-left"
                  url={getURL}
                  params={params}
                  options={referralCodesOrdenations}
                  filter={sortReferralCodes}
                  setFilter={setSortReferralCodes}
                  setLoading={setLoading}
                  setData={setData}
                  placeholder="Ordernar por..."
                  clearable
                  width={150}
                />
              </Col>
            </Row>
          </Col>
          <Col
            className="px-0 order-1 order-lg-2 mb-2 mb-lg-0"
            lg={8}
            align="end"
          >
            <Row className="w-100 mx-0 d-flex flex-row justify-content-end">
              <Col
                className="px-0 order-3 order-lg-1 d-flex flex-column align-items-start align-items-lg-end"
                lg={4}
              >
                {isAllowedToBatch && (
                  <BatchActions
                    getURL={getURL}
                    deleteURL={batchDeleteURL}
                    selectedItems={{
                      referral_codes: selectedCodes.map(rc => rc.id),
                    }}
                    setData={setData}
                    setSelectedItems={setSelectedCodes}
                    itemsArray={codes}
                    params={params}
                    keepPage
                    pagination={pagination}
                    setAllItems={setAllCodes}
                    allItems={allCodes}
                    tip="Para executar uma ação em lote, selecione, pelo menos, dois códigos."
                    id="referral_codes"
                    objName="referral_codes"
                    successMsg="Códigos de Indicação removidos com sucesso!"
                    selectModeOn={selectModeOn}
                    setSelectModeOn={setSelectModeOn}
                    setLoading={setLoading}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {codes.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                  <Col xs={1} align="center">
                    {isAllowedToBatch && (
                      <CustomInput
                        type="checkbox"
                        id="allCodes"
                        className="text-primary z-index-0"
                        checked={allCodes}
                        defaultValue={allCodes}
                        onChange={() => setAllCodes(!allCodes)}
                      />
                    )}
                  </Col>
                  <Col xs={10} className="px-0">
                    <Row className="w-100 mx-0">
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Código
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Nome do Código
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Usos Totais
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Valor Total
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Usos no Mês
                          <i
                            id="monthlyUse"
                            className="las la-info-circle font-size-16 ml-2"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="monthlyUse"
                          >
                            Variável pelo filtro por mês
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                      <Col xs={2} align="start">
                        <div className="new-layout-table-column-label">
                          Valor no Mês
                          <i
                            id="monthlyValue"
                            className="las la-info-circle font-size-16 ml-2"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="monthlyValue"
                          >
                            Variável pelo filtro por mês
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {codes?.map(code => {
                  const isSelected = selectedCodes.some(c => c.id === code.id);
                  return (
                    <Row className="w-100 mx-0">
                      {selectModeOn && (
                        <Col
                          xs={1}
                          className="px-0 d-flex flex-column justify-content-center"
                        >
                          <CustomInput
                            type="checkbox"
                            id={`mobile-select-${code.id}`}
                            className="text-primary z-index-0"
                            checked={isSelected}
                            defaultValue={isSelected}
                            onChange={() => handleSelect(code, isSelected)}
                          />
                        </Col>
                      )}
                      <Col className="px-0" xs={selectModeOn ? 11 : 12}>
                        <ReferralCodeCard
                          key={code.id}
                          code={code}
                          isSelected={isSelected}
                          onDelete={() => {
                            setCodeToBeDeleted(code);
                            setShowDeleteModal(true);
                          }}
                          onSelect={() => {
                            handleSelect(code, isSelected);
                          }}
                          onGenerateReport={() => {
                            handleGenerateDailyCodeReport(code);
                          }}
                          isAllowedToBatch={isAllowedToBatch}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Row className="w-100 mx-0 my-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum código encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={codes}
        setItemToBeDeleted={setCodeToBeDeleted}
        headerText="Deseja mesmo excluir o Código de Indicação?"
        bodyText="Essa ação irá excluir o código de indicação"
        itemName={codeToBeDeleted?.name}
        successMsg="Código de Indicação excluído com sucesso!"
      />
    </div>
  );
};

export default ReferralCodes;
