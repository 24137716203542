/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useRef } from 'react';
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getRegisterDate } from '~/util/newLayoutHelpers';
import stdProfilePicture from '~/assets/images/user.png';
import api from '~/services/api';

export default function UserHeaderInfo({ user, onChat, scale, rolesOnTop }) {
  const inputRef = useRef(null);
  const location = useLocation();

  const canChangeProfilePicture = useMemo(() => {
    if (location.pathname === '/editar-dados') {
      return true;
    }
    return false;
  }, [location.pathname]);

  const styles = {
    transform: `scale(${onChat && scale ? scale : 1})`,
  };

  const roles = useSelector(({ user: loggedUser }) => loggedUser.profile.roles);

  const isConsultant = useMemo(() => {
    return roles.some(r => r.slug === 'digital-consultant');
  }, [roles]);

  const getRoleName = name => {
    switch (name) {
      case 'Administrador':
        return 'Admin';
      case 'Consultor Digital':
        return 'Vendedor Digital';
      default:
        return name;
    }
  };

  const picColWidth = useMemo(() => {
    if (location.pathname === '/chat' && isConsultant) {
      return 3;
    }
    if (
      location.pathname.includes('editar-dados') ||
      location.pathname.includes('usuarios/perfil')
    ) {
      return 1;
    }
    return 2;
  }, [isConsultant, location]);

  return (
    <Row className={`w-100 mx-0 ${onChat ? '' : 'my-4'}`}>
      <Col
        xs={location.pathname === '/chat' ? 2 : 3}
        md={1}
        lg={picColWidth}
        // md={1}
        align="start"
        className={`d-flex flex-column align-items-center justify-content-center `}
        // className="mb-4 mb-xl-0"
      >
        <img
          alt="Foto do perfil"
          className={`profile-picture ${
            canChangeProfilePicture && 'edit-profile'
          }`}
          id="profile-picture"
          src={
            user?.profile_picture?.url
              ? user?.profile_picture.url
              : stdProfilePicture
          }
          onClick={() => canChangeProfilePicture && inputRef.current?.click()}
          style={styles}
        />
        {canChangeProfilePicture && (
          <UncontrolledTooltip placement="top" target="profile-picture">
            Clique para {user.profile_picture ? 'alterar' : 'adicionar'} sua
            foto do perfil
          </UncontrolledTooltip>
        )}

        <input
          type="file"
          id="attachment"
          accept=".jpg,.jpeg, .png, .gif"
          // value={profilePicture}
          onChange={async e => {
            const { files } = e.target;
            const formData = new FormData();

            formData.append('profile_picture', files[0]);

            await api.post(`auth/me/set-profile-picture`, formData);

            toast.success('Foto atualizada com sucesso!');
            window.location.reload(false);

            // e.stopImmediatePropagation();
          }}
          ref={inputRef}
          className="d-none"
        />
      </Col>
      <Col
        xs={location.pathname === '/chat' ? 10 : 9}
        md={11}
        lg={location.pathname === '/chat' && isConsultant ? 9 : 10}
        align="start"
        className={`d-flex flex-column justify-content-center ${
          onChat ? '' : 'pl-md-4'
        }`}
      >
        {rolesOnTop && (
          <Row className="w-100 mx-0 mb-2">
            <Col xs={12} className="px-0">
              {user?.roles.map(r => (
                <Badge
                  key={r.slug}
                  className={`role-badge mb-2 mb-lg-0 ${r.slug} d-inline mr-2 p-1`}
                >
                  {getRoleName(r.name)}
                </Badge>
              ))}
            </Col>
          </Row>
        )}
        <Row className="w-100 mx-0 mb-2 mb-lg-0">
          <Col
            xs={12}
            className="px-0 font-size-16 font-weight-600 text-gray-700"
          >
            {user?.name}
          </Col>
        </Row>
        {!rolesOnTop && (
          <Row className="w-100 mx-0 mb-2">
            <Col xs={12} className="px-0">
              {user?.roles.map(r => (
                <Badge
                  key={r.slug}
                  className={`role-badge mb-2 mb-lg-0 ${r.slug} d-inline mr-2 p-1`}
                >
                  {getRoleName(r.name)}
                </Badge>
              ))}
            </Col>
          </Row>
        )}
        {!onChat && (
          <Row className="w-100 mx-0">
            <Col xs={12} className="px-0" align="start">
              {getRegisterDate(user?.created_at)}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
