import React, { useState, useEffect, useMemo } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';
// import { truncateName } from '~/Util/truncatename';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import { breakString } from '~/util/newLayoutHelpers';
import { resetSearch, setSearch } from '~/store/modules/search/actions';
import { activeStoresFilterOptions } from '~/util/filters';
import DropdownFilter from '~/components/DropdownFilter';
import DeleteModal from '~/components/Modals/DeleteModal';

import RestoreStoreModal from './RestoreStoreModal';
import StoreInfoCard from './StoreInfoCard';

const Stores = () => {
  const dispatch = useDispatch();

  const [stores, setStores] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [showOnlyInactiveStores, setShowOnlyInactiveStores] = useState(false);
  const [storeToBeDeleted, setStoreToBeDeleted] = useState(null);
  const [storeToBeRestored, setStoreToBeRestored] = useState(null);
  const [showDeleteStoreModal, setShowDeleteStoreModal] = useState(false);
  const [showRestoreStoreModal, setShowRestoreStoreModal] = useState(false);
  const searchQuery = useSelector(({ search }) => search.query || '');

  const getURL = useMemo(() => {
    return '/stores';
  }, []);

  const deleteURL = useMemo(() => {
    return `/administrator/stores/${storeToBeDeleted?.id}`;
  }, [storeToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
      'only-trashed': showOnlyInactiveStores,
    };
  }, [pagination, searchQuery, showOnlyInactiveStores]);

  const setData = data => {
    const formattedStores = data.stores.map((store, index) => ({
      ...store,
      class_name: `px-0 pr-md-${(index + 1) % 2 !== 0 ? 2 : 0} pl-md-${
        (index + 1) % 2 === 0 ? 2 : 0
      }
      pl-lg-${index % 3 === 0 ? 0 : 2} pr-lg-${(index + 1) % 3 === 0 ? 0 : 2}
      `,
      broken_fantasy_name: breakString(store.fantasy_name, ' ', 25, true),
      // broken_fantasy_name_2: truncateName(store.fantasy_name, 0, 4),
    }));

    setStores(formattedStores);
    setPagination(data.meta);
  };

  const handleRegisterToDelivery = async id => {
    try {
      await api.post(`administrator/stores/${id}/delivery/register`);

      toast.success('Loja cadastrada com sucesso no serviço de entrega!');
      setLoading(true);

      const { data } = await api.get(`stores?page=${pagination.current_page}`);

      setData(data);

      setLoading(false);
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const totalStores = pagination?.total ?? 'N/A';

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0 mb-4 d-flex flex-row justify-content-between">
          <Col xs={12} md={6} lg={6} className="px-0 mb-4 my-lg-0">
            <PageHeader
              pageTitle="LISTA DE LOJAS"
              responsivePosition="start"
              desktopPosition="start"
            />
          </Col>
          <Col className="px-0 mb-4 my-lg-0 d-lg-none">
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={q => dispatch(setSearch({ query: q }))}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          {/* <Col lg={8} className="px-0 col-xxl-9">
            <Button
              className="new-layout-btn main"
              color="primary"
              onClick={() => history.push('/grupos-de-entrega')}
            >
              Grupos de Entrega
            </Button>
          </Col> */}
        </Row>
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 d-flex align-items-center" xs={6} lg={2}>
            <div className="d-flex flex-row align-items-baseline">
              <span className="text-left mt-0 d-inline text-muted font-size-14 mr-2">
                Filtros
              </span>
              <DropdownFilter
                name="inactive-stores"
                id="inactive-stores"
                defaultOption={showOnlyInactiveStores}
                options={activeStoresFilterOptions}
                url={getURL}
                setData={setData}
                setLoading={setLoading}
                filter={showOnlyInactiveStores}
                setFilter={setShowOnlyInactiveStores}
                params={params}
                query={searchQuery}
                setQuery={q => dispatch(setSearch({ query: q }))}
                additionalClassName="mx-0"
                width={165}
              />
            </div>
          </Col>
          <Col className="px-0 d-flex align-items-center" xs={6} lg={2}>
            <div className="d-flex flex-row align-items-baseline ml-4">
              <span className="text-left mt-0 d-inline text-muted font-size-14 mr-2">
                Total de lojas:
              </span>
              <div className="font-size-14 font-weight-600 text-gray-700">
                {totalStores}
              </div>
            </div>
          </Col>
          <Col
            className="px-0 d-flex align-items-center justify-content-end mt-4 mt-lg-0"
            lg={8}
          >
            <Button
              className="new-layout-btn main"
              onClick={() => history.push('/lojas/adicionar')}
              color="primary"
            >
              <i className="fas fa-plus font-size-14 align-middle mr-2" /> Nova
              Loja
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {stores.length ? (
              <>
                <Row className="w-100 mx-0">
                  {stores?.map(store => {
                    return (
                      <Col
                        key={store.id}
                        className={store.class_name}
                        md={6}
                        lg={4}
                      >
                        <StoreInfoCard
                          store={store}
                          registerToDelivery={handleRegisterToDelivery}
                          isDisabled={showOnlyInactiveStores}
                          onDelete={_store => {
                            setStoreToBeDeleted(_store);
                            setShowDeleteStoreModal(true);
                          }}
                          onRestore={_store => {
                            setStoreToBeRestored(_store);
                            setShowRestoreStoreModal(true);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhuma loja encontrada.</h3>
            )}
          </>
        )}
      </Container>
      <DeleteModal
        show={showDeleteStoreModal}
        setShow={setShowDeleteStoreModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={stores}
        setItemToBeDeleted={setStoreToBeDeleted}
        headerText="Deseja mesmo desativar a loja?"
        bodyText="Essa ação irá desativar a loja, não será possível criar vendas para ela."
        itemName={storeToBeDeleted?.name}
        successMsg="Loja desativada com sucesso!"
        btnText="Desativar"
      />

      <RestoreStoreModal
        show={showRestoreStoreModal}
        setShow={setShowRestoreStoreModal}
        store={storeToBeRestored}
        setData={setData}
        stores={stores}
        params={params}
        setFilter={setShowOnlyInactiveStores}
        setUserToBeRestored={setStoreToBeRestored}
      />
    </div>
  );
};

export default Stores;
