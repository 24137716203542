import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import api from '~/services/api';
import history from '~/services/history';
import {
  CANCELLED,
  DELIVERED,
  RETURNING_TO_STORE,
} from '~/util/deliveryStatuses';

export default function StockManagementButtons({
  stock,
  delivery,
  separated,
  toggleFinishCancelledOrder,
  cartIndex,
  cartsArrLength,
  handleFinishUnify,
  handleFinishAndNext,
}) {
  const location = useLocation();
  const toastID = useRef(null);
  const handleFinishOrder = async () => {
    await api.patch(`stockist/carts/${stock.id}/finalize`);
    history.push('/estoque');
    toast.success('Venda finalizada!');
  };
  const handleMarkAsReady = async () => {
    if (stock.is_for_delivery) {
      try {
        toastID.current = toast.info('Registrando chamada...', {
          autoClose: false,
        });
        await api.patch(`stockist/carts/${stock.id}/mark-as-ready`);

        toast.update(toastID.current, {
          render: 'Chamada registrada com sucesso! Redirecionando...',
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          transition: Flip,
        });
        history.push('/estoque');
      } catch (err) {
        toast.update(toastID.current, {
          render: err?.response?.data?.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          transition: Flip,
        });
      }
    } else {
      toastID.current = toast.info('Marcando venda como separada...');
      await api.patch(`stockist/carts/${stock.id}/mark-as-ready`);

      toast.update(toastID.current, {
        render: 'Venda marcada como separada com sucesso! Redirecionando...',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });
      history.push('/estoque');
    }
  };
  return (
    <Row className="w-100 mx-0 mb-4">
      <Col className="px-0" align="end">
        {location.pathname.includes('unificar-entregas') ? (
          <>
            {cartIndex + 1 === cartsArrLength ||
            cartIndex === cartsArrLength ? (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main long-label font-size-16"
                onClick={() => handleFinishUnify()}
                disabled={!separated}
              >
                Chamar entregador
              </Button>
            ) : (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main long-label"
                onClick={() => handleFinishAndNext(cartIndex)}
                disabled={!separated}
              >
                Marcar como separado e ir para o próximo
              </Button>
            )}
          </>
        ) : (
          <>
            {stock?.status === 2 && (
              <Button
                className={`new-layout-btn main ${
                  stock.is_for_delivery ? 'long-label' : ''
                }`}
                color="primary"
                size="lg"
                onClick={handleMarkAsReady}
                disabled={!separated}
              >
                {stock.is_for_delivery ? (
                  <>
                    {stock.delivery_scheduled_to
                      ? 'Marcar como pronto e aguardar entrega'
                      : 'Chamar entregador'}
                  </>
                ) : (
                  'Marcar como separado'
                )}
              </Button>
            )}
            {['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
              stock.delivery_method
            ) &&
              stock?.status === 3 &&
              stock.is_for_delivery &&
              (stock.payment_type === 101 || stock.payment_type === 102) && (
                <Button
                  color="primary"
                  className="new-layout-btn main"
                  size="lg"
                  onClick={() =>
                    delivery?.status !== CANCELLED
                      ? handleFinishOrder()
                      : toggleFinishCancelledOrder()
                  }
                  disabled={
                    !(
                      delivery?.status === DELIVERED ||
                      delivery?.status === CANCELLED
                    )
                  }
                >
                  Marcar como entregue
                </Button>
              )}
            {['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
              stock.delivery_method
            ) &&
              stock?.status === 3 &&
              stock.is_for_delivery &&
              stock.payment_type !== 101 &&
              stock.payment_type !== 102 && (
                <Button
                  color="primary"
                  className="new-layout-btn main"
                  size="lg"
                  onClick={() =>
                    delivery?.status !== CANCELLED
                      ? handleFinishOrder()
                      : toggleFinishCancelledOrder()
                  }
                  disabled={
                    !(
                      delivery?.status === RETURNING_TO_STORE ||
                      delivery?.status === DELIVERED ||
                      delivery?.status === CANCELLED
                    )
                  }
                >
                  Marcar como entregue
                </Button>
              )}
            {stock.delivery_method === 'PROPRIA' &&
              stock?.status === 3 &&
              stock.is_for_delivery && (
                <Button
                  color="primary"
                  className="new-layout-btn main"
                  size="lg"
                  onClick={handleFinishOrder}
                  // disabled={!separated}
                >
                  Marcar como entregue
                </Button>
              )}
            {stock.delivery_method === 'IBOLT' &&
              stock?.status === 3 &&
              stock.is_for_delivery && (
                <Button
                  color="primary"
                  className="new-layout-btn main"
                  size="lg"
                  onClick={handleFinishOrder}
                  // disabled={!separated}
                >
                  Marcar como entregue
                </Button>
              )}
            {stock?.status === 3 && !stock.is_for_delivery && (
              <Button
                color="primary"
                className="new-layout-btn main"
                size="lg"
                onClick={handleFinishOrder}
                disabled={!separated}
              >
                Marcar como entregue
              </Button>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
