import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Fade,
  Badge,
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import ImgsViewer from 'react-images-viewer';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  openChatRequest,
  PrintMsgOnFeed,
  PutMsgOnQueue,
  SendMsgToServer,
  SetHandlingQueue,
  LoadOldMessages,
  SetLoadingOldMsgs,
  CleanCurrentChat,
} from '~/store/modules/chat/actions';

import stdProfilePicture from '~/assets/images/user.png';
import stdGroupPicture from '~/assets/images/group.png';
import Loading from '~/components/Loading';
import IconButton from '~/components/IconButton';

export default function Feed({ state }) {
  const msgsScrollbarRef = useRef();
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const currentUser = useSelector(({ user }) => user.profile);
  const currentChat = useSelector(({ chat }) => chat.currentChat);

  const handlingQueue = useSelector(({ chat }) => chat.handlingQueue);
  const queuedMsgs = useSelector(({ chat }) => chat.queuedMsgs);

  const messages = useSelector(({ chat }) => chat.messages);
  const chats = useSelector(({ chat }) => chat.chats);

  const msgsPagination = useSelector(({ chat }) => chat.msgsPagination);

  const loadingMessages = useSelector(({ chat }) => chat.loadingMessages);

  const loadingOldMsgs = useSelector(({ chat }) => chat.loadingOldMsgs);

  const thereIsStillOldMsgsToBeLoaded = useMemo(() => {
    return (
      msgsPagination?.last_page > 1 &&
      msgsPagination?.current_page < msgsPagination?.last_page
    );
  }, [msgsPagination]);

  const [attachmentFilePath, setAttachmentFilePath] = useState('');
  const [showImgViwer, setShowImgViwer] = useState(false);
  const [currImgIndex, setCurrImgIndex] = useState(0);
  const [showLoadOldMsgsBtn, setShowLoadOldMsgsBtn] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [redirected, setRedirected] = useState(state?.redirected || false);
  const [msgsScrollBarOldPosition, setMsgsScrollBarOldPosition] = useState(0);
  const [currentMessage, setCurrentMessage] = useState('');
  const [chatImgs, setChatImgs] = useState([]);

  const [msgsScrollbarFirstRollDone, setMsgsScrollbarFirstRollDone] =
    useState(false);

  const msgInput = document.getElementById('messageInput');

  // const msgsScrollbarStdHeight = 470;
  const dragEvents = [
    'dragenter',
    'dragover',
    'dragleave',
    'dragstart',
    'dragend',
    'dragexit',
    'drop',
  ];

  const defineChatPic = chat => {
    if (chat.is_group_chat) {
      return stdGroupPicture;
    }
    if (chat.interlocutor.profile_picture) {
      return chat.interlocutor.profile_picture.url;
    }
    return stdProfilePicture;
  };

  const handleLoadOldMessages = async () => {
    dispatch(SetLoadingOldMsgs(true));
    setMsgsScrollBarOldPosition(msgsScrollbarRef.current.scrollHeight);

    const params = {
      page: msgsPagination.current_page + 1,
    };
    const data = {
      params,
      chat: currentChat,
    };

    dispatch(LoadOldMessages(data));
  };

  const handleShowLoadOldMsgsBtn = () => {
    if (!msgsScrollbarFirstRollDone) {
      setMsgsScrollbarFirstRollDone(true);
    } else {
      setShowLoadOldMsgsBtn(true);
    }
  };

  const handleOpenChat = chat => {
    if (msgsScrollbarFirstRollDone) setMsgsScrollbarFirstRollDone(false);

    if (showLoadOldMsgsBtn) setShowLoadOldMsgsBtn(false);

    if (msgsScrollBarOldPosition !== 0) setMsgsScrollBarOldPosition(0);

    dispatch(openChatRequest({ chat }));

    // setCurrentChat(chat);
  };

  const postAttachmentOnFeed = files => {
    if (files.length) {
      const timeRef = new Date().getTime();

      const newAttachmentObj = {
        attachment: files[0],
        ref: timeRef,
        chat: currentChat,
      };

      if (attachmentFilePath !== '') {
        setAttachmentFilePath('');
      }
      dispatch(PrintMsgOnFeed(newAttachmentObj));
      dispatch(PutMsgOnQueue(newAttachmentObj));

      msgsScrollbarRef.current.scrollTop =
        msgsScrollbarRef.current.scrollHeight;
    }
  };

  const postMessageOnFeed = () => {
    setMsgsScrollBarOldPosition(msgsScrollbarRef.current.scrollHeight);
    if (currentMessage.length < 1) {
      return;
    }

    const timeRef = new Date().getTime();
    const newMsgObj = {
      content: currentMessage,
      ref: timeRef,
      chat: currentChat,
    };

    setCurrentMessage('');
    dispatch(PrintMsgOnFeed(newMsgObj));
    dispatch(PutMsgOnQueue(newMsgObj));

    msgsScrollbarRef.current.scrollTop = msgsScrollbarRef.current.scrollHeight;
  };

  const sendMsgToServer = async msg => {
    try {
      dispatch(SendMsgToServer(msg));

      msgsScrollbarRef.current.scrollTop =
        msgsScrollbarRef.current.scrollHeight;
    } catch (err) {
      // console.log(err);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao enviar a mensagem. Tente novamente mais tarde.'
        );
      }
    }
  };

  const checkQueuedMsgs = () => {
    if (!handlingQueue) {
      // eslint-disable-next-line
      const nextMsg = queuedMsgs.find(queuedMsg => {
        const isSent = messages.some(printedMsg => {
          if (printedMsg.ref === queuedMsg.ref && printedMsg.id) {
            return true;
          }
          return false;
        });

        if (!isSent) {
          return queuedMsg;
        }

        // return null;
      });

      if (nextMsg) {
        dispatch(SetHandlingQueue(true));
        sendMsgToServer(nextMsg);
      }
    }
  };

  const handleDrop = e => {
    const { files } = e.dataTransfer;
    postAttachmentOnFeed(files);
  };

  const getGroupChatMembersString = () => {
    return `Você, ${currentChat.members
      .map((member, index) => {
        if (index === currentChat.members.length - 1) {
          return ` ${member.name}.`;
        }
        if (index === currentChat.members.length - 2) {
          return `${member.name} e `;
        }
        return `${member.name}, `;
      })
      .join('')}`;
  };

  const isImg = message => {
    if (
      message.attachment &&
      (message.attachment.extension === 'jpg' ||
        message.attachment.extension === 'jpeg' ||
        message.attachment.extension === 'gif' ||
        message.attachment.extension === 'png' ||
        message.attachment.extension === 'bmp')
    ) {
      return true;
    }
    return false;
  };

  const showDayRow = (message, index) => {
    if (index === 0) {
      return true;
    }
    if (
      new Date(message.created_at).getDate() >
      new Date(messages[index - 1].created_at).getDate()
    ) {
      return true;
    }
    if (
      new Date(message.created_at).getMonth() >
      new Date(messages[index - 1].created_at).getMonth()
    ) {
      return true;
    }
    if (
      new Date(message.created_at).getFullYear() >
      new Date(messages[index - 1].created_at).getFullYear()
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const messageInput = document.getElementById('messageInput');
    if (messageInput) {
      messageInput.focus();
    }
    if (msgsScrollbarRef.current) {
      msgsScrollbarRef.current.scrollTop =
        msgsScrollbarRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat?.id, redirected]);

  useEffect(() => {
    if (state && redirected) {
      handleOpenChat(state.chat);

      if (msgInput) {
        msgInput.focus();
        setRedirected(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, msgInput]);

  useEffect(() => {
    if (msgsScrollbarRef.current) {
      if (!loadingOldMsgs) {
        msgsScrollbarRef.current.scrollTop =
          msgsScrollbarRef.current.scrollHeight;
      } else {
        msgsScrollbarRef.current.scrollTop =
          msgsScrollbarRef.current.scrollHeight - msgsScrollBarOldPosition;
        setShowLoadOldMsgsBtn(false);
        dispatch(SetLoadingOldMsgs(false));
      }
    }

    const rawChatImgs = messages
      .filter(msg => {
        if (isImg(msg)) {
          return msg;
        }
        return null;
      })
      .map(img => {
        return { src: img.attachment.url };
      });

    setChatImgs(rawChatImgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    checkQueuedMsgs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedMsgs, handlingQueue]);

  useEffect(() => {
    if (!showDropzone && msgsScrollbarRef.current) {
      msgsScrollbarRef.current.scrollTop =
        msgsScrollbarRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropzone, msgsScrollbarRef.current]);

  useEffect(() => {
    if (showLoadOldMsgsBtn) setShowLoadOldMsgsBtn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  dragEvents.forEach(dragEvent => {
    window.addEventListener(
      dragEvent,
      e => {
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );
  });

  window.addEventListener(
    'keydown',
    e => {
      if (e.keyCode === 27 && showDropzone) {
        setShowDropzone(false);
      }
    },
    false
  );

  return (
    <>
      {currentChat ? (
        <Card className="card new-layout chat-main-cards-borders mb-0 mb-lg-4">
          <Row
            className="w-100 mx-0 p-3 background-color-f2f2f2"
            style={{ borderRadius: '3.25px' }}
          >
            <Col
              xs={2}
              sm={1}
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: '70px' }}
            >
              <img
                alt="Foto do perfil"
                className="chat-profile-picture"
                src={defineChatPic(currentChat)}
              />
            </Col>
            <Col
              xs={8}
              sm={10}
              className="d-flex flex-column justify-content-center"
            >
              {!currentChat.is_group_chat && (
                <Row className="w-100 mx-0 mb-2">
                  {currentChat.interlocutor.roles.length ? (
                    currentChat.interlocutor.roles.map(r => (
                      <Badge
                        key={`${currentChat.interlocutor.id}-${r.slug}`}
                        className={`role-badge ${r.slug} p-1 mr-2`}
                      >
                        {r.name}
                      </Badge>
                    ))
                  ) : (
                    <Badge className="gray-badge p-1 no-pointer-events w-fit-content">
                      Nenhum cargo atribuído
                    </Badge>
                  )}
                </Row>
              )}
              <Row className="w-100 mx-0">
                <Col className="px-0 font-size-16 font-weight-600 text-gray-700 d-block text-truncate">
                  {currentChat.is_group_chat
                    ? currentChat.name
                    : currentChat.interlocutor?.name}
                </Col>
              </Row>
              {currentChat.is_group_chat && (
                <Row className="w-100 mx-0 mt-1 font-size-14 font-weight-400 text-gray-700">
                  {getGroupChatMembersString()}
                </Row>
              )}
            </Col>
            <Col
              xs={2}
              sm={1}
              className="pr-0 d-flex flex-column justify-content-center"
            >
              <IconButton
                color="secondary"
                id="close-chat-btn"
                icon="las la-times"
                fontSize={25}
                tip="Fechar conversa"
                forceTip
                click={() => {
                  dispatch(CleanCurrentChat());
                }}
              />
            </Col>
          </Row>
          <CardBody className="card-body new-layout px-4 py-4">
            <div
              className="chat-conversation"
              aria-hidden="true"
              id="msgsFeed"
              onDragOver={() => {
                if (!showDropzone) {
                  setShowDropzone(true);
                }
              }}
              onDrop={e => {
                if (showDropzone) {
                  handleDrop(e);
                  setShowDropzone(false);
                }
              }}
              onDragLeave={() => {
                if (showDropzone) {
                  setShowDropzone(false);
                }
              }}
            >
              <PerfectScrollbar
                // style={{ height: `${msgsScrollbarStdHeight}px` }}
                className="feed-scrollbar"
                // eslint-disable-next-line no-return-assign
                containerRef={el => {
                  msgsScrollbarRef.current = el;
                }}
                onYReachStart={handleShowLoadOldMsgsBtn}
                onScrollDown={() => {
                  if (showLoadOldMsgsBtn) {
                    setShowLoadOldMsgsBtn(false);
                  }
                }}
              >
                {showDropzone ? (
                  <div className="pointer-events-none">
                    <Col className="col" sm="12" md="12">
                      <Row className="d-flex flex-direction-row justify-content-center align-items-center">
                        <h4>Arraste e solte seu arquivo aqui</h4>
                      </Row>
                      <Row className="d-flex flex-direction-row justify-content-center align-items-center">
                        <img
                          alt=""
                          src="https://icons-for-free.com/iconfiles/png/512/cloud+upload+file+storage+upload+icon-1320190558968694328.png"
                          style={{
                            maxHeight: '30vh',
                            maxWidth: '30vw',
                            opacity: '50%',
                          }}
                        />
                      </Row>
                      <Row className="d-flex flex-direction-row justify-content-center align-items-center">
                        <h4>
                          Para cancelar, arraste seu arquivo para fora ou
                          pressione a tecla ESC.
                        </h4>
                      </Row>
                    </Col>
                  </div>
                ) : (
                  <>
                    {loadingMessages ? (
                      <Loading />
                    ) : (
                      <>
                        {messages.length >= msgsPagination.per_page && (
                          <Fade
                            in={showLoadOldMsgsBtn}
                            tag="h5"
                            className="mt-3"
                          >
                            {thereIsStillOldMsgsToBeLoaded ? (
                              <div className="d-flex justify-content-center">
                                <Button
                                  color="primary"
                                  type="button"
                                  id="loadOldMsgs"
                                  onClick={handleLoadOldMessages}
                                  className="load-old-msgs btn-rounded waves-effect waves-light"
                                >
                                  <span className="d-none d-sm-inline-block mr-2">
                                    Mais
                                  </span>{' '}
                                  <i className="fas fa-arrow-circle-up" />
                                </Button>
                              </div>
                            ) : (
                              <div className="no-more-old-msgs d-flex justify-content-center">
                                Não há mais mensagens para serem exibidas.
                              </div>
                            )}
                          </Fade>
                        )}
                        {messages.map((message, index) => {
                          return (
                            <div key={message.created_at || message.ref}>
                              {message.id ? (
                                <>
                                  {showDayRow(message, index) && (
                                    <Row className="w-100 mx-0 my-4 d-flex flex-row justify-content-center font-size-12 font-weight-700 text-gray-500">
                                      {format(
                                        parseISO(message.created_at),
                                        'dd/MMM/yyyy',
                                        {
                                          locale: ptBR,
                                        }
                                      )}
                                    </Row>
                                  )}
                                  <Row
                                    className={`w-100 mx-0 mb-4 px-4 d-flex flex-row ${
                                      message.created_by.id === currentUser.id
                                        ? 'justify-content-end'
                                        : 'justify-content-start'
                                    }`}
                                    key={message.id}
                                  >
                                    <div className="mw-90 mw-lg-40">
                                      <div
                                        className={`w-fit-content p-3 ${
                                          message.created_by.id ===
                                          currentUser.id
                                            ? 'background-color-primary rounded-border-radius right'
                                            : 'background-color-f2f2f2 rounded-border-radius left'
                                        }`}
                                      >
                                        <Row
                                          className={`w-fit-content mx-0 mb-2 font-size-14 font-weight-600 ${
                                            message.created_by.id ===
                                            currentUser.id
                                              ? 'text-light'
                                              : 'text-gray-700'
                                          }`}
                                        >
                                          {message.created_by.id ===
                                          currentUser.id
                                            ? 'Você'
                                            : message.created_by.name}
                                        </Row>
                                        <Row
                                          className={`w-fit-content mx-0 text-wrap text-break font-size-14 font-weight-400 ${
                                            message.created_by.id ===
                                            currentUser.id
                                              ? 'text-white'
                                              : 'text-gray-900'
                                          }`}
                                        >
                                          {message.attachment ? (
                                            <>
                                              {isImg(message) ? (
                                                <div
                                                  role="button"
                                                  tabIndex={0}
                                                  onClick={() => {
                                                    const myImgIndex =
                                                      chatImgs.findIndex(
                                                        img => {
                                                          return (
                                                            img.src ===
                                                            message.attachment
                                                              .url
                                                          );
                                                        }
                                                      );

                                                    setCurrImgIndex(myImgIndex);
                                                    setShowImgViwer(true);
                                                  }}
                                                  className="cursor-pointer"
                                                  style={{ outline: 0 }}
                                                >
                                                  <img
                                                    src={message.attachment.url}
                                                    alt=""
                                                    height="110"
                                                    className="img-fluid rounded"
                                                  />
                                                </div>
                                              ) : (
                                                <a
                                                  href={message.attachment.url}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className={`text-decoration-underline font-size-14 font-weight-700 ${
                                                    message.created_by.id ===
                                                    currentUser.id
                                                      ? 'text-white'
                                                      : 'text-primary'
                                                  }`}
                                                >
                                                  {message.attachment.name}
                                                </a>
                                              )}
                                            </>
                                          ) : (
                                            message.content
                                          )}
                                        </Row>
                                      </div>
                                      <Row
                                        className={`w-100 mx-0 mt-1 font-size-12 font-weight-700 text-gray-500 d-flex flex-row ${
                                          message.created_by.id ===
                                          currentUser.id
                                            ? 'justify-content-end pr-3'
                                            : 'justify-content-start pl-3'
                                        }`}
                                      >
                                        {format(
                                          parseISO(message.created_at),
                                          "HH':'mm",
                                          {
                                            locale: ptBR,
                                          }
                                        )}
                                      </Row>
                                    </div>
                                  </Row>
                                </>
                              ) : (
                                <Row
                                  className="w-100 mx-0 mb-4 px-4 d-flex flex-row justify-content-end"
                                  key={message.ref}
                                >
                                  <div className="mw-40">
                                    <div className="p-3 w-fit-content background-color-primary rounded-border-radius right">
                                      <Row className="w-fit-content mx-0 text-wrap text-break font-size-14 font-weight-400 text-white">
                                        {message.attachment ? (
                                          <Loading
                                            customClassName="w-fit-content h-fit-content d-flex flex-column align-items-center mt-2"
                                            message="Fazendo Upload..."
                                            color="white"
                                            textStyle="font-size-14 font-weight-400 text-white"
                                          />
                                        ) : (
                                          message.content
                                        )}
                                      </Row>
                                    </div>
                                    {message.id && (
                                      <Row className="w-100 mx-0 mt-1 pr-3 d-flex flex-row justify-content-end font-size-12 font-weight-700 text-gray-500">
                                        {format(
                                          parseISO(message.created_at),
                                          "HH':'mm",
                                          {
                                            locale: ptBR,
                                          }
                                        )}
                                      </Row>
                                    )}
                                  </div>
                                </Row>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </PerfectScrollbar>
            </div>
          </CardBody>
          <Row className="w-100 mx-0 py-4 background-color-f2f2f2">
            <Col xs={8} className="pr-2" align="center">
              <Input
                type="text"
                value={currentMessage}
                onChange={e => {
                  setCurrentMessage(e.target.value);
                }}
                onKeyDown={e => e.keyCode === 13 && postMessageOnFeed()}
                className="form-control chat-input"
                id="messageInput"
                placeholder={
                  currentChat.interlocutor?.deleted_at
                    ? 'Usuário desativado. Interação indisponível.'
                    : 'Digite aqui sua mensagem...'
                }
                disabled={currentChat.interlocutor?.deleted_at}
                // classRest={
                //   currentChat.interlocutor.deleted_at &&
                //   'input-disabled text-muted'
                // }
              />
            </Col>
            <Col xs={2} className="px-2" align="center">
              <input
                type="file"
                id="attachment"
                value={attachmentFilePath}
                onChange={e => {
                  const { files } = e.target;
                  setAttachmentFilePath(e.target.value);
                  postAttachmentOnFeed(files);

                  // e.stopImmediatePropagation();
                }}
                ref={inputRef}
                className="d-none"
              />

              <Button
                type="button"
                outline
                color="primary"
                id="addAttachmentButton"
                onClick={() => inputRef.current?.click()}
                className="new-layout-btn main w-100 background-color-white p-0 p-lg-3 d-flex flex-row justify-content-center align-items-center"
                disabled={currentChat.interlocutor?.deleted_at}
              >
                <i className="las la-paperclip d-lg-none" />
                <div className="d-none d-lg-block">Anexo</div>
              </Button>
            </Col>
            <Col xs={2} className="pl-2">
              <Button
                type="button"
                color="primary"
                id="sendNewMessageButton"
                onClick={postMessageOnFeed}
                className="new-layout-btn main w-100 p-0 p-lg-3 d-flex flex-row justify-content-center align-items-center"
                disabled={currentChat.interlocutor?.deleted_at}
              >
                <i className="las la-paper-plane d-lg-none" />
                <div className="d-none d-lg-block">Enviar</div>
              </Button>
            </Col>
          </Row>
        </Card>
      ) : (
        <Row className="w-100 h-100 mx-0 my-4 font-size-16 font-weight-600 text-gray-600">
          <Col className="w-100 h-50 d-flex flex-column justify-content-center align-items-center">
            <i className="las la-comments font-size-40 d-block mb-2" />
            Selecione {chats.length ? 'uma conversa' : 'um usuário'} para
            começar!
          </Col>
        </Row>
      )}

      <ImgsViewer
        imgs={chatImgs}
        currImg={currImgIndex}
        isOpen={showImgViwer}
        onClickPrev={() => {
          const prevIndex = currImgIndex - 1;
          setCurrImgIndex(prevIndex);
        }}
        onClickNext={() => {
          const nextIndex = currImgIndex + 1;
          setCurrImgIndex(nextIndex);
        }}
        onClose={() => {
          setShowImgViwer(false);
          setCurrImgIndex(0);
        }}
        backdropCloseable
      />
    </>
  );
}
