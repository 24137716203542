import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

export default function AnnouncementCard({
  announcement,
  canManage,
  onEdit,
  onDelete,
  onBroadcast,
}) {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <Card
      className="card new-layout list-card alt"
      id={`collapse-btn-${announcement.id}`}
      role="button"
      onClick={() => {
        setShowDescription(!showDescription);
      }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col xs={11} className="card-title">
            {announcement.title}
          </Col>
          <Col xs={1} align="end">
            <i
              className={`las la-${
                showDescription ? 'minus' : 'plus'
              } text-secondary font-size-24`}
              id={`expand-${announcement.id}`}
            />
            <UncontrolledTooltip target={`expand-${announcement.id}`}>
              {showDescription ? 'Mostrar Menos' : 'Mostrar Mais'}
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Collapse
          isOpen={showDescription}
          toggler={`collapse-btn-${announcement.id}`}
        >
          <Row className="w-100 mx-0 my-4">
            <Col className="card-value">
              {announcement.formatted_description}
            </Col>
          </Row>
          <Row className="w-100 mx-0">
            <Col align="end">
              {canManage && (
                <>
                  <Button
                    className="new-layout-btn main"
                    color="info"
                    onClick={() => onBroadcast(announcement)}
                  >
                    <i className="las la-paper-plane mr-2 font-size-18" />
                    Divulgar Anúncio
                  </Button>
                  <Button
                    className="new-layout-btn main mt-2 mt-lg-0 mx-0 ml-lg-2"
                    color="primary"
                    onClick={() => onEdit(announcement)}
                  >
                    <i className="las la-pen mr-2 font-size-18" />
                    Editar Anúncio
                  </Button>
                  <Button
                    className="new-layout-btn main mt-2 mt-lg-0 mx-0 ml-lg-2"
                    color="danger"
                    onClick={() => onDelete(announcement)}
                  >
                    <i className="las la-trash mr-2 font-size-18" />
                    Excluir Anúncio
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
