import React, { useState, useEffect } from 'react'; // useMemo
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  UncontrolledTooltip,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import api from '~/services/api';
import PageHeader from '~/components/Common/PageHeader';
import { formatPrice } from '~/util/format';
import { getFullAddress } from '~/util/newLayoutHelpers';
// import { stateList } from '~/Util/stateList';

const Store = () => {
  const { store_id } = useParams();
  const history = useHistory();
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);

  // const isCaixa = useMemo(() => {
  //   return store?.bank_data?.institution.includes('CAIXA');
  // }, [store]);

  useEffect(() => {
    const loadStore = async () => {
      try {
        const { data } = await api.get(`/stores/${store_id}`);

        document.title = `${data.store.fantasy_name} | Viddy`;

        // const state = stateList.find(s => s.value === data.store.address.state);

        const formattedStore = {
          ...data.store,
          address: {
            ...data.store.address,
            full_address: getFullAddress(data.store.address),
          },

          formatted_minimum_delivery_balance: formatPrice(
            data.store.minimum_delivery_balance
          ),

          formatted_online_payments_value_limit: formatPrice(
            data.store.online_payments_value_limit
          ),
        };

        setStore(formattedStore);
        setLoading(false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Loja não encontrada.');
        }
      }
    };
    loadStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store_id]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="DETALHES DA LOJA"
              backTo="/lojas"
              responsivePosition="start"
              desktopPosition="center"
            />

            <Card className="card new-layout">
              <CardBody className="card-body new-layout">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  <Col className="px-0">Dados da Loja</Col>
                </Row>
                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">Razão Social</Label>
                    <div className="new-layout-page-value">{store.name}</div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Nome Fantasia</Label>
                    <div className="new-layout-page-value">
                      {store.fantasy_name}
                    </div>
                  </Col>
                </Row>

                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">CNPJ</Label>
                    <div className="new-layout-page-value">{store.cnpj}</div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Nome do Responsável</Label>
                    <div className="new-layout-page-value">
                      {store.responsible_name}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">E-mail</Label>
                    <div className="new-layout-page-value">{store.email}</div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Telefone</Label>
                    <div className="new-layout-page-value">
                      {store.telephone}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">Endereço</Label>
                    <div className="new-layout-page-value">
                      {store.address.full_address
                        ? store.address.full_address
                        : 'Não informado'}
                    </div>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  <Col className="px-0">Informações Técnicas</Col>
                </Row>
                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">
                      Limite de valor para pagamentos online
                      <i
                        id="onlinePaymentsLimit"
                        className="las la-info-circle font-size-18 ml-1"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="onlinePaymentsLimit"
                      >
                        Valor máximo de um pagamaneto online para essa loja.
                      </UncontrolledTooltip>
                    </Label>
                    <div className="new-layout-page-value">
                      {store.formatted_online_payments_value_limit}
                    </div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">
                      Saldo mínimo no serviço de entrega
                    </Label>
                    <div className="new-layout-page-value">
                      {store.formatted_minimum_delivery_balance}
                    </div>
                  </Col>
                </Row>

                <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <Label className="mb-0">Gateway de pagamento</Label>
                    <div className="new-layout-page-value">
                      {store.payment_gateway || 'Não Selecionado'}
                    </div>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="mb-0">Código da unidade</Label>
                    <div className="new-layout-page-value">
                      {store.mottu_unit_code || 'Não Informado'}
                    </div>
                  </Col>
                </Row>
                {/* <Row className="w-100 mx-0 mb-3">
                  <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                    <Label className="mb-0">Grupo de Entrega</Label>
                    <div className="new-layout-page-value">
                      {store.store_group?.name || 'Não Cadastrado'}
                    </div>
                  </Col>
                </Row> */}

                {store.has_cielo_key && (
                  <>
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      <Col className="px-0">Chaves da Cielo</Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Cielo Merchant ID</Label>
                        <div className="new-layout-page-value">
                          {store.cielo_merchant_id || 'Não Cadastrado'}
                        </div>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Cielo Merchant KEY</Label>
                        <div className="new-layout-page-value">
                          {store.cielo_merchant_key || 'Não Cadastrado'}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {store?.gerencianet_client_id && (
                  <>
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      <Col className="px-0">Chaves da Gerencianet</Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Client ID</Label>
                        <div className="new-layout-page-value">
                          {store.gerencianet_client_id || 'Não Cadastrado'}
                        </div>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <Label className="mb-0">Client Secret</Label>
                        <div className="new-layout-page-value">
                          {store.gerencianet_client_secret || 'Não Cadastrado'}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <Label className="mb-0">Certificado PEM</Label>
                        <div className="new-layout-page-value">
                          {store.gerencianet_certificate?.name ||
                            'Não Cadastrado'}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {store.has_pagarme_key && (
                  <>
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      <Col className="px-0">Chave da Pagar.me</Col>
                    </Row>

                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Pagar.me Key</Label>
                        <div className="new-layout-page-value">
                          {store.pagarme_api_key || 'Não Cadastrado'}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {/* {store.has_bank_data && (
                  <>
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      <Col className="px-0">Informações Bancárias</Col>
                    </Row>

                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Instituição</Label>
                        <div className="new-layout-page-value">
                          {store.bank_data.institution}
                        </div>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <Label className="mb-0">
                          {store.bank_data.document_type}
                        </Label>
                        <div className="new-layout-page-value">
                          {store.bank_data.document}
                        </div>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-3">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">Conta</Label>
                        <div className="new-layout-page-value">
                          {store.bank_data.account_number}
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className={`px-0 ${
                          isCaixa ? 'px-lg-2' : 'px-0 pl-lg-2'
                        }`}
                      >
                        <Label className="mb-0">Agência</Label>
                        <div className="new-layout-page-value">
                          {store.bank_data.agency}
                        </div>
                      </Col>
                    </Row>
                    {isCaixa && (
                      <Row className="w-100 mx-0 mb-3">
                        <Col lg={6} className="px-0 px-lg-4">
                          <Label className="mb-0">Operação</Label>
                          <div className="new-layout-page-value">
                            {store.bank_data.operation}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2 mb-3 mb-lg-0">
                        <Label className="mb-0">E-mail do Financeiro</Label>
                        <div className="new-layout-page-value">
                          {store.financier_email || 'Não Informado'}
                        </div>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <Label className="mb-0">Telefone do Financeiro</Label>
                        <div className="new-layout-page-value">
                          {store.financier_telephone || 'Não Informado'}
                        </div>
                      </Col>
                    </Row>
                  </>
                )} */}
              </CardBody>
            </Card>
            <Row className="w-100 mx-0 mt-4 mb-lg-4">
              <Col align="end" className="px-0">
                <Button
                  className="new-layout-btn main"
                  color="primary"
                  size="lg"
                  onClick={() => history.push(`/lojas/${store_id}/editar`)}
                >
                  Editar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default Store;
