/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Form } from '@unform/web';
import React, { useState } from 'react';

import { FaRegStar, FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '~/services/api';
import { getRole } from '~/util/getRole';

export default function RatingDeliveryModal({
  setShouldReloadCart,
  showModal,
  toggle,
  cart,
}) {
  const ratingRef = React.useRef(null);

  const roles = useSelector(({ user }) => user.profile.roles);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [quantityOfStars, setQuantityOfStars] = useState(0);
  const [rating, setRating] = useState(0);

  const stars = [];

  for (let i = 1; i < 6; i += 1) {
    stars.push(
      <div key={i} className="d-flex flex-column align-items-center px-2">
        <div
          role="button"
          onClick={() => setRating(i)}
          onMouseEnter={() => setQuantityOfStars(i)}
          onMouseLeave={() => setQuantityOfStars(0)}
        >
          {rating >= i ? (
            <FaStar color="#ffd600" size={40} />
          ) : (
            <>
              <FaRegStar
                color="#2544b1"
                style={quantityOfStars >= i && { display: 'none' }}
                size={40}
              />
              <FaStar
                color="#ffd600"
                style={quantityOfStars < i && { display: 'none' }}
                size={40}
              />
            </>
          )}
        </div>
        {i === 1 && <span className="pt-2">Péssimo</span>}
        {i === 5 && <span className="pt-2">Excelente</span>}
      </div>
    );
  }

  const handleRateDelivery = async () => {
    setButtonLoading(true);
    try {
      await api.post(`${getRole(roles)}/carts/${cart.id}/score`, {
        score: rating,
      });

      toast.success('A avaliação foi salva com sucesso!');
      setShouldReloadCart(true);
      toggle();
    } catch (error) {
      toast.error('Houve um erro ao salvar a avaliação!');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={showModal} toggle={toggle} className="rating-modal">
      <ModalHeader toggle={toggle}>
        Avalie como foi a {!cart?.return_delivery ? 'entrega' : 'coleta'} desse
        pedido!
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleRateDelivery} ref={ratingRef}>
          <div className="rating">{stars.map(star => star)}</div>
        </Form>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          className="new-layout-btn main px-5"
          color="primary"
          onClick={() => ratingRef.current.submitForm()}
          disabled={buttonLoading}
        >
          Enviar Avaliação
        </Button>
      </ModalFooter>
    </Modal>
  );
}
