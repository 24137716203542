import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, CustomInput } from 'reactstrap';

import history from '~/services/history';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DeleteModal from '~/components/Modals/DeleteModal';
import Loading from '~/components/Loading';
import BatchActions from '~/components/BatchActions';
import { getRole } from '~/util/getRole';
import useBatchSelect from '~/hooks/useBatchSelect';
import useGetData from '~/hooks/useGetData';
import { setSearch } from '~/store/modules/search/actions';
import CustomerProfileCard from './CustomerProfileCard';

const Customers = () => {
  const dispatch = useDispatch();
  const {
    items: customers,
    pagination,
    loading,
    setLoading,
    setData,
  } = useGetData();

  const {
    allSelected: allCustomers,
    setAllSelected: setAllCustomers,
    selectedItems: selectedCustomers,
    setSelectedItems: setSelectedCustomers,
    handleSelect,
  } = useBatchSelect(customers);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isStoreManager = useMemo(
    () => roles.find(role => role.slug === 'store-manager'),
    [roles]
  );

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const canCreateCustomer = useMemo(() => {
    if (!!isStoreManager && !isAdmin) {
      return false;
    }

    return true;
  }, [isAdmin, isStoreManager]);

  const searchQuery = useSelector(({ search }) => search.query);
  const [customerToBeDeleted, setCustomerToBeDeleted] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [selectModeOn, setSelectModeOn] = useState(false);

  const role = getRole(roles);

  const getURL = useMemo(() => {
    return '/clients';
  }, []);

  const deleteURL = useMemo(() => {
    return `/administrator/clients/${customerToBeDeleted?.id}`;
  }, [customerToBeDeleted]);

  const batchDeleteURL = useMemo(() => {
    return `/${role}/clients/`;
  }, [role]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const isAllowedToBatch = useMemo(() => {
    if (roles.some(r => r.slug !== 'stockist')) {
      return true;
    }
    return false;
  }, [roles]);

  useEffect(() => {
    dispatch(setSearch(''));
  }, [dispatch]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0 mb-4 d-flex flex-row justify-content-between">
          <Col xs={12} md={6} lg={3} className="px-0 mb-4 my-lg-0">
            <PageHeader
              pageTitle="LISTA DE CLIENTES"
              responsivePosition="start"
              desktopPosition="start"
            />
          </Col>
          <Col className="px-0 mb-4 my-lg-0 d-lg-none">
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={q => dispatch(setSearch({ query: q }))}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          {isAllowedToBatch && (
            <Col
              className="px-0 d-flex justify-content-end align-items-center"
              lg={canCreateCustomer ? 6 : 9}
              align="end"
            >
              <BatchActions
                getURL={getURL}
                deleteURL={batchDeleteURL}
                selectedItems={{
                  clients: selectedCustomers.map(c => c.id),
                }}
                width={150}
                setData={setData}
                setSelectedItems={setSelectedCustomers}
                itemsArray={customers}
                params={params}
                keepPage
                pagination={pagination}
                setAllItems={setAllCustomers}
                allItems={allCustomers}
                tip="Para executar uma ação em lote, selecione, pelo menos, dois clientes."
                id="clients"
                objName="clients"
                successMsg="Clientes removidos com sucesso!"
                selectModeOn={selectModeOn}
                setSelectModeOn={setSelectModeOn}
                setLoading={setLoading}
              />
            </Col>
          )}
          {canCreateCustomer && (
            <Col
              className="pr-0 pl-0 pl-lg-2 mt-4 mt-lg-0 d-flex justify-content-end align-items-center"
              lg={canCreateCustomer ? 3 : 12}
            >
              <Button
                className={`new-layout-btn btn main btn-primary waves-effect waves-light ${
                  isAllowedToBatch ? 'w-100' : ''
                }`}
                onClick={() => {
                  history.push('/clientes/registrar');
                }}
                color="primary"
              >
                <i className="fas fa-user-plus font-size-16 align-middle mr-2" />
                Novo Cliente
              </Button>
            </Col>
          )}
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {customers.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                  <Col xs={1} align="center">
                    {isAllowedToBatch && (
                      <CustomInput
                        type="checkbox"
                        id="allClients"
                        className="text-primary z-index-0"
                        checked={allCustomers}
                        defaultValue={allCustomers}
                        onChange={() => setAllCustomers(!allCustomers)}
                      />
                    )}
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Cliente</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">E-mail</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">
                      Telefone
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Nascimento
                    </div>
                  </Col>
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {customers?.map(customer => {
                  const isSelected = selectedCustomers.some(
                    c => c.id === customer.id
                  );

                  return (
                    <Row className="w-100 mx-0" key={customer.id}>
                      {selectModeOn && (
                        <Col
                          xs={1}
                          className="px-0 d-flex flex-column justify-content-center"
                        >
                          <CustomInput
                            type="checkbox"
                            id={`mobile-select-${customer.id}`}
                            className="text-primary z-index-0"
                            checked={isSelected}
                            defaultValue={isSelected}
                            onChange={() => handleSelect(customer, isSelected)}
                          />
                        </Col>
                      )}
                      <Col className="px-0" xs={selectModeOn ? 11 : 12}>
                        <CustomerProfileCard
                          key={customer.id}
                          customer={customer}
                          isSelected={isSelected}
                          onSelect={handleSelect}
                          onDelete={incomingCustomer => {
                            setCustomerToBeDeleted(incomingCustomer);
                            setOpenModal(true);
                          }}
                          isAllowedToBatch={isAllowedToBatch}
                        />
                      </Col>
                    </Row>
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum cliente encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <DeleteModal
        show={openModal}
        setShow={setOpenModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={customers}
        setItemToBeDeleted={setCustomerToBeDeleted}
        headerText="Deseja mesmo excluir o cliente?"
        bodyText="Essa ação irá excluir o cliente"
        itemName={customerToBeDeleted?.name}
        successMsg="Cliente excluído com sucesso!"
      />
    </div>
  );
};

export default Customers;
