import { FormGroup } from '@material-ui/core';
import { Form } from '@unform/web';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, Label, Row } from 'reactstrap';
import InputSelect from '~/components/Inputs/InputSelect';
import api from '~/services/api';
import { setChosenRegion } from '~/store/modules/cart/actions';
import searchByCep from '~/util/searchByCep';
import { stateList } from '~/util/stateList';
import getDistance from '~/util/getDistance';
import Input from '../Inputs/Input';
import InputMask from '../Inputs/InputMask';
import AutoComplete from '../AutoComplete';

import NumberInput from './NumberInput';
import TextAreaWithCounter from '../Inputs/TextAreaWithCounter';

export default function AddressForm({
  formRef,
  handleSubmit,
  initialData,
  showSubmitBtn,
  submitBtnDisabled,
  setSubmitBtnDisabled,
  iDontKnowCEP,
  setIDontKnowCEP,
  hideBeforeCEP,
}) {
  const dispatch = useDispatch();

  const toastId = useRef(null);

  const [loadingByCep, setLoadingByCep] = useState(false);

  const [addressComponents, setAddressComponents] = useState(null);
  const [selectedPostalCode, setSelectedPostalCode] = useState(
    initialData?.cep
  );
  const [postalCodeInputValue, setPostalCodeInputValue] = useState(
    initialData?.cep
  );
  const [selectedState, setSelectedState] = useState(initialData?.state);
  const [selectedCity, setSelectedCity] = useState(initialData?.city);
  const [cityList, setCityList] = useState([]);

  const [numberInfo, setNumberInfo] = useState(initialData?.number);
  const [streetInfo, setStreetInfo] = useState(initialData?.street_name);
  const [autoCompleteError, setAutoCompleteError] = useState(null);

  const useUserAddress = useSelector(({ cart }) => cart.useUserAddress);
  const chosenRegion = useSelector(({ cart }) => cart.chosenRegion);
  const paymentGateway = useSelector(({ cart }) => cart.store?.payment_gateway);
  const showRegionsModal = useSelector(({ cart }) => cart.showRegionsModal);
  const showConfirmRegionModal = useSelector(
    ({ cart }) => cart.showConfirmRegionModal
  );
  const selectedStore = useSelector(({ cart }) => cart.selectedStore);
  const userStores = useSelector(({ user }) => user?.profile?.managed_stores);

  const storeData = useMemo(() => {
    const data = userStores.find(store => store.id === selectedStore?.value);

    if (!data) return null;

    return {
      ...data,
      location: new window.google.maps.LatLng(data.lat, data.lng),
    };
  }, [selectedStore, userStores]);

  const handleSubmitAddressForm = async info => {
    const data = {
      ...info,
      city: selectedCity?.value || info.city,
      state: selectedState?.value || info.state,
      cep: selectedPostalCode || info.postalCode,
      number: numberInfo || info.number,
      street_name: streetInfo || info.street_name,
    };

    if (!data.cep) {
      formRef.current.setFieldError('cep', 'Não foi possível localizar o CEP');
    }

    let validState = true;
    if (!data.state || !data.city || !data.cep || !data.street_name) {
      toast.error('Preencha todos os dados obrigatórios antes de continuar!');
      setSubmitBtnDisabled(false);
      validState = false;
    }

    if (iDontKnowCEP) {
      setAutoCompleteError(
        data.street_name ? null : 'Não foi possível reconhecer o logradouro'
      );
    }

    if (paymentGateway === 'cielo' && data.complement.length > 14) {
      toast.error('O complemento não pode ter mais que 14 caracteres.');
      formRef.current.setFieldError(
        'complement',
        'O complemento não pode ter mais que 14 caracteres'
      );
      return;
    }

    if (validState && storeData?.maximum_delivery_kilometer) {
      const response = await fetch(
        `https://maps.google.com/maps/api/geocode/json?address=${data.street_name.replaceAll(
          ' ',
          '+'
        )}+${data.number}+${data.city.replaceAll(
          ' ',
          '+'
        )}+${data.state.replaceAll(' ', '+')}+${
          data.cep
        }&sensor=false&key=AIzaSyCUv-umYwoJNub2LKK0tAnhQABu6DLkLnY`
      );

      const geocodedAddress = await response.json();
      const { location } = geocodedAddress.results[0]?.geometry;

      const addressLatLng = new window.google.maps.LatLng(
        location.lat,
        location.lng
      );
      const storeLatLng = new window.google.maps.LatLng(
        storeData.address.lat,
        storeData.address.long
      );

      const distance = await getDistance(addressLatLng, storeLatLng);

      if (distance > storeData?.maximum_delivery_kilometer) {
        toast.error(
          `O endereço informado ultrapassa o limite do raio de entrega, distância
           de ${distance.toFixed(
             0
           )}km, por favor altere o endereço de entrega!`,
          { autoClose: 8000 }
        );
        setSubmitBtnDisabled(false);
        return;
      }
    }
    handleSubmit(data);
  };

  useEffect(() => {
    // console.log(
    //   { iDontKnowCEP },
    //   selectedPostalCode,
    //   selectedState,
    //   selectedCity,
    //   seachedCEP
    // );

    formRef.current.reset();
    setSelectedCity(null);
    setSelectedState(null);
    setStreetInfo(null);
    setNumberInfo(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iDontKnowCEP]);

  const showRegionInput = useMemo(() => {
    if (
      chosenRegion &&
      useUserAddress === true &&
      !showRegionsModal &&
      !showConfirmRegionModal
    ) {
      return true;
    }
    return false;
  }, [chosenRegion, useUserAddress, showRegionsModal, showConfirmRegionModal]);

  useEffect(() => {
    const updateAddressDebounce = setTimeout(() => {
      if (!addressComponents) return;
      formRef.current.reset();

      const { state, city, neighborhood, street, number, postalCode } =
        addressComponents;

      if (selectedPostalCode !== postalCode) setSelectedPostalCode(postalCode);

      const newState = stateList.find(s => s.value === state);
      if (selectedState !== newState) setSelectedState(newState);

      if (selectedCity?.value !== city) {
        setSelectedCity({
          label: city,
          value: city,
        });
      }

      if (streetInfo !== street) setStreetInfo(street);
      if (numberInfo !== number) setNumberInfo(number);
      formRef.current.setFieldValue('neighborhood', neighborhood);

      if (postalCode) {
        setPostalCodeInputValue(
          postalCode.length === 5 ? `${postalCode}-000` : postalCode
        );
      }
    }, 500);

    return () => clearTimeout(updateAddressDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressComponents]);

  useEffect(() => {
    const updateNumberDebounce = setTimeout(async () => {
      const postalCode = formRef.current.getFieldValue('cep');
      if (!postalCode && streetInfo && selectedCity && selectedState) {
        // console.log(
        //   streetInfo,
        //   selectedCity.value,
        //   selectedState.value
        // );

        const viacep = await fetch(
          `https://viacep.com.br/ws/${selectedState.value}/${selectedCity.value}/${streetInfo}/json/`
        );

        const viacepData = await viacep.json();

        // console.log({viacepData})

        if (viacepData.length > 0) {
          setAddressComponents(address => ({
            ...address,
            postalCode: viacepData[0].cep,
          }));

          return;
        }

        try {
          const cepla = await fetch(
            `http://cep.la/${streetInfo.replaceAll(
              ' ',
              '-'
            )}-${selectedCity.value.replaceAll(' ', '-')}-${
              selectedState.value
            }`,
            {
              headers: { Accept: 'application/json' },
            }
          );

          const ceplaData = await cepla.json();

          // console.log({ceplaData});

          if (ceplaData.length > 0) {
            setAddressComponents(address => ({
              ...address,
              postalCode: ceplaData[0].cep,
            }));

            return;
          }
        } catch (e) {
          console.error(e);
        }

        const fallback = await fetch(
          `https://viacep.com.br/ws/${selectedState.value}/${selectedCity.value}/Centro/json/`
        );

        const fallbackData = await fallback.json();

        // console.log({fallbackData})

        if (fallbackData.length > 0) {
          setAddressComponents(address => ({
            ...address,
            postalCode: fallbackData[0].cep,
          }));
        }
      }
    }, 500);
    return () => clearTimeout(updateNumberDebounce);
  }, [formRef, streetInfo, numberInfo, selectedCity, selectedState]);

  useEffect(() => {
    if (!initialData) return;

    formRef.current.setFieldValue('cep', initialData.cep || '');
    formRef.current.setFieldValue('street_name', initialData.street_name || '');
    formRef.current.setFieldValue('number', initialData.number || '');
    formRef.current.setFieldValue(
      'neighborhood',
      initialData.neighborhood || ''
    );
    formRef.current.setFieldValue('complement', initialData.complement || '');
    formRef.current.setFieldValue(
      'additional_information',
      initialData.additional_information || ''
    );

    if (initialData.state) {
      const state = stateList.find(s => s.value === initialData.state);
      setSelectedState(state);

      const city = {
        label: initialData.city,
        value: initialData.city,
      };
      setSelectedCity(city);
    }
  }, [initialData, formRef]);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  return (
    <Row className="w-100 mx-0">
      <Form
        onSubmit={handleSubmitAddressForm}
        ref={formRef}
        initialData={initialData}
        className="w-100"
      >
        <Row className="w-100 mx-0 align-items-end">
          <Col className="px-0 mt-2 mt-lg-0">
            <FormGroup>
              <div className="d-flex d-lg-block justify-content-between">
                <Label>CEP ou Endereço</Label>
                {loadingByCep && (
                  <span className="text-end ml-lg-4 text-info">
                    Buscando...
                  </span>
                )}
              </div>
              {!iDontKnowCEP ? (
                <InputMask
                  disabled={iDontKnowCEP || loadingByCep}
                  autofocus={iDontKnowCEP === false}
                  name="cep"
                  placeholder="Digite o CEP ou endereço"
                  mask="*9999-999"
                  maskChar={null}
                  onFocus={() => formRef.current.setFieldError('cep', null)}
                  value={postalCodeInputValue}
                  onChange={e => {
                    setPostalCodeInputValue(e.target.value);

                    if (Number.isNaN(Number(e.target.value[0]))) {
                      setIDontKnowCEP(true);
                    }

                    formRef.current.setFieldError('cep', null);

                    if (
                      e.target.value.length === 9 &&
                      e.target.value !== selectedPostalCode
                    ) {
                      setLoadingByCep(true);
                      setSelectedPostalCode(e.target.value);
                      searchByCep({
                        formRef,
                        setSelectedState,
                        setSelectedCity,
                        setStreetInfo,
                        // setFinder,
                        toastId,
                        setLoadingByCep,
                      });
                      dispatch(setChosenRegion(null));
                    }
                  }}
                />
              ) : (
                <AutoComplete
                  onChange={e => {
                    const { value } = e.target;

                    if (Number.isNaN(Number(e.key))) return;

                    if (value.length > 0) return;

                    setPostalCodeInputValue(e.key);
                    setIDontKnowCEP(false);
                  }}
                  autofocus
                  defaultValue={postalCodeInputValue}
                  location={storeData?.location}
                  error={autoCompleteError}
                  addressComponents={addressComponents}
                  setAddressComponents={(...args) => {
                    setIDontKnowCEP(false);
                    setPostalCodeInputValue('');
                    setAddressComponents(...args);
                  }}
                />
              )}
            </FormGroup>
          </Col>

          {/* <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
            <Button
              type="button"
              onClick={() => {
                formRef.current.setFieldError('cep', null);
                setIDontKnowCEP(state => !state);
              }}
              className="btn btn-primary bg-transparent text-primary border-none"
            >
              <i className="fas fa-search" />{' '}
              {iDontKnowCEP ? 'Sei o CEP' : 'Não sei o CEP'}
            </Button>
          </Col> */}
        </Row>

        {!hideBeforeCEP || selectedPostalCode ? (
          <>
            <Row className="w-100 mx-0 mt-lg-2 align-items-end">
              <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                <FormGroup>
                  <Label>Logradouro</Label>
                  <Input
                    name="street_name"
                    placeholder="Logradouro"
                    type="text"
                    onChange={e => setStreetInfo(e.target.value)}
                    value={streetInfo}
                  />
                </FormGroup>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                <FormGroup>
                  <NumberInput
                    number={numberInfo}
                    formRef={formRef}
                    onChange={e => setNumberInfo(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-lg-2">
              <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                <FormGroup>
                  <Label>Bairro</Label>
                  <Input name="neighborhood" placeholder="Bairro" type="text" />
                </FormGroup>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                <TextAreaWithCounter
                  name="complement"
                  placeholder="Complemento"
                  title="Complemento"
                  defaultValue={initialData?.complement}
                  maxSize={128}
                />
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-lg-2">
              <Col lg={6} className="px-0 pr-lg-2 mt-2 mt-lg-0">
                <FormGroup>
                  <Label>Estado</Label>
                  <InputSelect
                    name="state"
                    options={stateList}
                    onChange={selectedOption =>
                      setSelectedState(selectedOption)
                    }
                    value={selectedState || ''}
                    placeholder="Estado"
                    singlePath
                  />
                </FormGroup>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2 mt-2 mt-lg-0">
                <FormGroup>
                  <Label>Cidade</Label>
                  <InputSelect
                    name="city"
                    options={cityList}
                    onChange={selectedOption => setSelectedCity(selectedOption)}
                    value={selectedCity || ''}
                    placeholder="Cidade"
                    isDisabled={!selectedState}
                    singlePath
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="w-100 mx-0 mt-lg-2">
              <Col className="px-0 mt-2 mt-lg-0">
                <TextAreaWithCounter
                  name="additional_information"
                  placeholder="Informações Adicionais"
                  title="Informações Adicionais"
                  defaultValue={initialData?.additional_information}
                  maxSize={128}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {showRegionInput && (
          <Row className="w-100 mx-0 mb-3">
            <Col className="px-0 ">
              <FormGroup>
                <Label>Região de entrega</Label>
                <Input
                  name="delivery_additional_data"
                  placeholder="Região de entrega (OPCIONAL)"
                  classRest="input-disabled text-muted"
                  type="text"
                  value={
                    !showRegionsModal &&
                    !showConfirmRegionModal &&
                    chosenRegion !== null &&
                    chosenRegion.name
                  }
                  loadingMessage={() => 'Buscando região...'}
                  onBlur={() => {}}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {showSubmitBtn && (
          <Row className="w-100 mx-0 mt-4 mt-lg-2">
            <Col className="px-0 mt-2 mt-lg-0" align="end">
              <Button
                color="primary"
                className="new-layout-btn main"
                size="lg"
                type="submit"
                disabled={submitBtnDisabled || false}
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Row>
  );
}
