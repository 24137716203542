/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import {
  // Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setTab,
  resetCart,
  loadCart,
  setLoading,
  nextStep,
} from '~/store/modules/cart/actions';

import Loading from '~/components/Loading';
import RegionsModal from '~/components/Modals/RegionsModal';
import ClientForm from './form/client';
import PaymentForm from './form/Payment';
import DeliveryForm from './form/delivery';
import ConfirmationForm from './form/Confirmation';
import ConfirmationSuitcaseForm from './form/ConfirmSuitcase';

// import PageHeader from '~/components/Common/PageHeader';

const SalesRegister = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const orderTab = useSelector(({ cart }) => cart.orderTab);
  const step = useSelector(({ cart }) => cart.step);
  const loading = useSelector(({ cart }) => cart.loading);
  const shared = useSelector(({ cart }) => cart.shared);
  const cartState = useSelector(({ cart }) => cart.cart);
  const is_suitcase_delivery = useSelector(({ cart }) =>
    id ? cart.cart?.is_suitcase_delivery : cart.is_suitcase_delivery
  );

  const [finishCartState, setFinishCartState] = useState(null);
  const [targetUserId, setTargetUserId] = useState('');

  const STEPS = useMemo(
    () =>
      !is_suitcase_delivery
        ? [
            {
              tab: 1,
              label: shared ? 'Informações' : 'Dados do Cliente',
              clickable: step >= 1,
              width: 3,
              available: true,
            },
            {
              tab: 2,
              label: 'Entrega',
              clickable: step >= 2,
              width: 3,
              available: true,
            },
            {
              tab: 3,
              label: 'Pagamento',
              clickable: step >= 3,
              width: 3,
              available: true,
            },
            {
              tab: 4,
              label: 'Resumo do Pedido',
              clickable: step >= 4,
              width: 3,
              available: true,
            },
          ]
        : [
            {
              tab: 1,
              label: shared ? 'Informações' : 'Dados do Cliente',
              clickable: step >= 1,
              width: 3,
              available: true,
            },
            {
              tab: 2,
              label: 'Entrega',
              clickable: step >= 2,
              width: 3,
              available: true,
            },
            {
              tab: 3,
              label: 'Resumo do Pedido',
              clickable: step >= 3,
              width: 3,
              available: true,
            },
          ],
    [step, shared, is_suitcase_delivery]
  );

  useEffect(() => {
    dispatch(resetCart());
    if (id) {
      dispatch(loadCart({ cart_id: id }));
      document.title = 'Editar Pedido | Viddy';
    } else {
      dispatch(setLoading(false));
      document.title = 'Inserir Venda | Viddy';
    }

    return () => dispatch(resetCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTab = tab => {
    if (orderTab !== tab) dispatch(setTab(tab));
  };

  return (
    <div className="page-content">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container className="container new-layout">
            <Row className="w-100 mx-0 mb-0 mb-md-4">
              {/* <Col lg={9} className="px-0"> */}
              <Nav pills className="w-100 border-none mb-4">
                <Row className="w-100 mx-0 mb-0 mb-md-4 justify-content-center mt-4 mt-md-0">
                  {STEPS.filter(s => s.available).map((s, i) => (
                    // <Col xs={s.width} className="px-0">
                    <NavItem
                      className="h-100 d-flex flex-column justify-content-center"
                      key={s.tab}
                    >
                      <NavLink
                        className={`pl-0 font-size-14 font-weight-400 ${
                          s.clickable
                            ? 'text-gray-700'
                            : 'text-gray-600 pointer-events-none'
                        } h-100 d-flex flex-column justify-content-center text-center align-items-center`}
                        onClick={() => s.clickable && toggleTab(s.tab)}
                      >
                        <span
                          className={`step-circle ${
                            orderTab === s.tab ? 'active' : ''
                          } d-flex justify-content-center align-items-center mb-2`}
                        >
                          {i + 1}
                        </span>
                        {/* <span>{orderTab === s.tab ? s.label : ''}</span> */}
                      </NavLink>
                    </NavItem>
                    // </Col>
                  ))}
                </Row>
              </Nav>
              {/* </Col> */}
            </Row>
            {/* <PageHeader
              pageTitle={id ? 'Editar pedido' : 'Inserir venda'}
              responsivePosition="center"
              desktopPosition="center"
              backTo="vendas"
              id={id || null}
            /> */}
            <TabContent activeTab={orderTab}>
              <TabPane tabId={1}>
                {orderTab === 1 && (
                  <ClientForm setTargetUserId={setTargetUserId} />
                )}
              </TabPane>
              <TabPane tabId={2}>
                {orderTab === 2 && (
                  <DeliveryForm isEdit={id} targetUserId={targetUserId} />
                )}
              </TabPane>
              {!is_suitcase_delivery ? (
                <TabPane tabId={3}>
                  {orderTab === 3 && (
                    <PaymentForm
                      setFinishCartState={(...args) => {
                        setFinishCartState(...args);
                        dispatch(nextStep());
                      }}
                      finishCartState={finishCartState}
                    />
                  )}
                </TabPane>
              ) : null}
              <TabPane tabId={!is_suitcase_delivery ? 4 : 3}>
                {is_suitcase_delivery && orderTab === 3 ? (
                  <ConfirmationSuitcaseForm finishCartState={cartState} />
                ) : null}
                {!is_suitcase_delivery && orderTab === 4 ? (
                  <ConfirmationForm finishCartState={finishCartState} />
                ) : null}
              </TabPane>
            </TabContent>
          </Container>
        </>
      )}
      <RegionsModal />
    </div>
  );
};

export default SalesRegister;
