import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { logoutUser } from '~/store/modules/auth/login/actions';

const Logout = () => {
  /**
   * Redirect to login
   */

  // Dispatch redux instance
  const dispatch = useDispatch();

  useEffect(() => {
    // emit the event
    dispatch(logoutUser());
  }, [dispatch]);

  return <></>;
};

export default Logout;
