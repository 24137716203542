import React, { useState, useEffect } from 'react';
import { CustomInput, Label, Alert } from 'reactstrap';

const styles = {
  card: {
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
  },
  cardSuitcase: {
    width: '100%',
    margin: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  info: {
    marginTop: '16px',
  },
  alertRed: {
    color: 'red',
    marginTop: '10px',
    marginBottom: '5px',
  },
  alertGreen: {
    color: 'green',
    marginTop: '10px',
    marginBottom: '5px',
  },
  progressBar: {
    height: '15px',
    width: '100%',
    backgroundColor: '#eee',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '20px',
  },
  progress: {
    height: '100%',
    borderRadius: '10px',
    transition: 'width 0.3s',
  },
  saldoDisponivelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  infoLabel: {
    marginRight: '8px',
  },
};

const GiftbackCard = ({
  saldoDisponivel,
  // saldoTotal,
  // dataExpiracao,
  // valorMinimoResgate,
  valorCompra,
  errorMessage,
  setErrorMessage,
  isGiftbackActive,
  onSwitchChange,
  isLoadingGiftback,
  isSuitcase,
}) => {
  const [giftbackAtivo, setGiftbackAtivo] = useState(isGiftbackActive);
  const isGiftbackValid = saldoDisponivel || valorCompra !== 0.0;

  /*
  const valorFaltante = valorMinimoResgate - valorCompra;
  const progresso = valorMinimoResgate
    ? (valorCompra / valorMinimoResgate) * 100
    : 0;
  */

  /*
  const getProgressBarColor = () => {
    if (!isGiftbackValid) {
      return 'default';
    }
    return valorCompra >= valorMinimoResgate ? 'green' : 'red';
  };
  */

  // const corBarra = getProgressBarColor();

  useEffect(() => {
    setGiftbackAtivo(isGiftbackActive);
  }, [isGiftbackActive]);

  useEffect(() => {
    if (!isGiftbackValid && isGiftbackActive) {
      setGiftbackAtivo(false);
    }
  }, [isGiftbackValid, isGiftbackActive, setGiftbackAtivo]);

  const handleSwitchChange = event => {
    const isActive = event.target.checked;

    if (!isGiftbackValid) {
      setErrorMessage(
        'Para acrescentar o valor do giftback, insira um valor de compra.'
        // 'Não foi possível utilizar o bônus porque não tem valor disponível para uso.'
      );
      return;
    }

    setErrorMessage('');
    setGiftbackAtivo(isActive);
    onSwitchChange(isActive);
  };

  /*
  const formattedDate = dataExpiracao
    ? new Date(dataExpiracao).toLocaleDateString('pt-BR')
    : 'Não informada!';

  let alertMessage;

  if (!isGiftbackValid) {
    alertMessage = <p style={styles.alertRed}> </p>;
  } else if (valorCompra < valorMinimoResgate) {
    alertMessage = (
      <p style={styles.alertRed}>
        Faltam{' '}
        {valorFaltante.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}{' '}
        para resgatar o valor total
      </p>
    );
  } else {
    alertMessage = (
      <p style={styles.alertGreen}>Você atingiu o valor para resgate total!</p>
    );
  }
  */

  return (
    <div style={isSuitcase ? styles.cardSuitcase : styles.card}>
      <div style={styles.header}>
        <h2 className="font-size-20">Giftback</h2>
        <CustomInput
          type="switch"
          id="giftback"
          name="giftback"
          checked={giftbackAtivo}
          className="text-primary switch-giftback"
          onChange={handleSwitchChange}
        />
      </div>
      <div style={styles.info}>
        <div style={styles.saldoDisponivelContainer}>
          <Label>Saldo disponível:</Label>
          <div style={styles.switchContainer} className="switch-container">
            <b className="ml-2 font-size-14">
              {isLoadingGiftback
                ? 'Carregando...'
                : saldoDisponivel.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
            </b>
          </div>
        </div>
        {/*
        <h2 className="font-size-16 mb-3 mt-2">Informações Adicionais</h2>
        <div>
          <Label style={styles.infoLabel}>Saldo total:</Label>
          <b className="font-size-14">
            {isLoadingGiftback
              ? 'Carregando...'
              : saldoTotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </b>
        </div>
        <div>
          <Label style={styles.infoLabel}>Data de expiração:</Label>
          <b className="font-size-14">
            {isLoadingGiftback ? 'Carregando...' : formattedDate}
          </b>
        </div>
        <div>
          <Label style={styles.infoLabel}>
            Valor mínimo para resgate total:
          </Label>
          <b className="font-size-14">
            {isLoadingGiftback
              ? 'Carregando...'
              : valorMinimoResgate.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </b>
        </div>
        {alertMessage}
        <div style={styles.progressBar}>
          <div
            style={{
              ...styles.progress,
              width: `${progresso}%`,
              backgroundColor: corBarra,
            }}
          />
        </div>
        */}
      </div>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
    </div>
  );
};

export default GiftbackCard;
