import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  needStockistRole,
  needSalesmanRole,
  needFinancierRole,
  onlyAdmin,
  onlyConsultant,
  adminAndManagerRoute,
  isUniversal,
  onlyFinancier,
  onlyMaster,
  onlySupport,
  ...rest
}) => {
  const signedUser = useSelector(({ user }) => user.profile);

  // If the route is auth protected and there's no signed in user
  if (!isUniversal && isAuthProtected && !signedUser) {
    return <Redirect to="/login" />;
  }

  const isAdmin =
    signedUser &&
    signedUser.roles.find(
      role => role.slug === 'administrator' || role.slug === 'support'
    );

  const isStockist =
    signedUser && signedUser.roles.find(role => role.slug === 'stockist');

  const isSalesman =
    signedUser && signedUser.roles.find(role => role.slug === 'salesman');

  const isStoreManager =
    signedUser && signedUser.roles.find(role => role.slug === 'store-manager');

  const isConsultant =
    signedUser &&
    signedUser.roles.find(role => role.slug === 'digital-consultant');

  const isCrossManager =
    signedUser && signedUser.roles.find(role => role.slug === 'cross-manager');

  const isMaster =
    signedUser && signedUser.roles.find(role => role.slug === 'master');

  const isFinancier =
    signedUser && signedUser.roles.find(role => role.slug === 'financier');

  const isSupport =
    signedUser && signedUser.roles.find(role => role.slug === 'support');

  // const isFinancier =
  //   signedUser &&
  //   signedUser.roles.find(role => role.slug === 'financer');

  // If the user is stockist and tries to access a salesman route (except if it is admin)

  // if (
  //   !isUniversal &&
  //   needSalesmanRole &&
  //   !isSalesman &&
  //   isStockist &&
  //   !isAdmin &&
  //   !isStoreManager
  // ) {
  //   return <Redirect to="/estoque" />;
  // }

  // If the user is salesman or manager and tries to access a stockist route
  if (
    !isUniversal &&
    needStockistRole &&
    !isStockist &&
    (isSalesman || isStoreManager)
  ) {
    return <Redirect to="/" />;
  }

  // If the user is manager and tries to access a salesman route
  // if (!isUniversal && needSalesmanRole && !isSalesman && (isAdmin || isStoreManager))
  if (
    !isUniversal &&
    needSalesmanRole &&
    !isSalesman &&
    !isAdmin &&
    !isStoreManager &&
    !isConsultant &&
    !isCrossManager &&
    !isFinancier
  ) {
    return <Redirect to="/usuarios" />;
  }

  // If the user is admin or manager and tries to access a stockist route
  if (
    !isUniversal &&
    needStockistRole &&
    !isStockist &&
    (isAdmin || isStoreManager)
  ) {
    return <Redirect to="/usuarios" />;
  }

  // If the user is manager and tries to access a admin route
  if (
    !isUniversal &&
    onlyAdmin &&
    !isAdmin &&
    !adminAndManagerRoute &&
    isStoreManager
  ) {
    return <Redirect to="/sua-loja" />;
  }

  // If the user is stockist and tries to access a admin route
  // if (!isUniversal && onlyAdmin && !isAdmin && isStockist && !isStoreManager) {
  //   return <Redirect to="/estoque" />;
  // }

  // If the user is salesman and tries to access a admin route
  if (!isUniversal && onlyAdmin && !isAdmin && isSalesman && !isStoreManager) {
    return <Redirect to="/" />;
  }

  // redirect Login routes on User sagas

  // Administrator initial route if already logged
  if (!isUniversal && signedUser && !isAuthProtected && isAdmin) {
    return <Redirect to="/dashboard" />;
  }

  // Store Manager initial route if already logged
  if (!isUniversal && signedUser && !isAuthProtected && isStoreManager) {
    return <Redirect to="/sua-loja" />;
  }

  // Stockist initial route if already logged
  // if (!isUniversal && signedUser && !isAuthProtected && isStockist) {
  //   return <Redirect to="/estoque" />;
  // }

  // Salesman initial route if already logged
  if (!isUniversal && signedUser && !isAuthProtected && isSalesman) {
    return <Redirect to="/" />;
  }

  // Digital consultant routes

  // Login
  // if (!isUniversal && signedUser && !isAuthProtected && isConsultant) {
  //   return <Redirect to="/consultor/" />;
  // }

  if (!isUniversal && signedUser && !isAuthProtected && isMaster) {
    return <Redirect to="/master" />;
  }
  if (!isUniversal && signedUser && !isAuthProtected && isFinancier) {
    return <Redirect to="/financeiro" />;
  }

  // Sales
  // if (
  //   !isUniversal &&
  //   needSalesmanRole &&
  //   !isSalesman &&
  //   !isStoreManager &&
  //   !isStockist &&
  //   isConsultant
  // ) {
  //   return <Redirect to="/consultor/" />;
  // }

  if (
    !isUniversal &&
    needSalesmanRole &&
    !isSalesman &&
    !isStoreManager &&
    !isStockist &&
    isFinancier
  ) {
    if (!needFinancierRole) {
      return <Redirect to="/financeiro" />;
    }
  }
  if (
    !isUniversal &&
    needSalesmanRole &&
    !isSalesman &&
    !isStoreManager &&
    !isStockist &&
    isMaster
  ) {
    return <Redirect to="/master" />;
  }

  if (
    !isUniversal &&
    onlyConsultant &&
    // !isConsultant &&
    (isSalesman || isStoreManager)
  ) {
    return <Redirect to="/" />;
  }

  if (!isUniversal && onlyFinancier && !isFinancier) {
    return <Redirect to="/" />;
  }

  // Stocks
  // if (!isUniversal && needStockistRole && !isStockist && isConsultant) {
  //   return <Redirect to="/consultor/estoque" />;
  // }

  // if (!isUniversal && onlyConsultant && !isConsultant) {
  //   return <Redirect to="/" />;
  // }

  if (!isUniversal && onlyMaster && !isMaster) {
    return <Redirect to="/" />;
  }

  if (!isUniversal && onlySupport && !isSupport) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
