import { toast } from 'react-toastify';

// export const getBankDataStr = store => {
//   const isCaixa = store?.bank_data?.institution?.includes('CAIXA');

//   return `Banco: ${store.bank_data.institution}\nAgência: ${
//     store.bank_data.agency
//   }\nConta: ${store.bank_data.account_number}\n${
//     store.bank_data.document_type === 'CNPJ'
//       ? `Razão Social: ${store.name}`
//       : `Titular: ${store.responsible_name}`
//   }\n${store.bank_data.document_type}: ${store.bank_data.document}${
//     isCaixa ? ` \nOperação: ${store.bank_data.operation}` : ''
//   }`;
// };

export const copyStringToClipboard = (str, successMsg) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.style = { display: 'none' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  toast.success(successMsg || 'Copiado!');
};
