import React from 'react';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '../IconButton';

export const SaleAlertBadge = ({ children, backgroundColor, color }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Card className="new-layout card" style={{ backgroundColor }}>
      <CardBody
        className="new-layout card-body"
        style={{
          padding: '1rem 1rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: 'all 2s ease-in',
        }}
      >
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Collapse
            isOpen={expanded}
            style={{
              minHeight: '21px',
              height: 'unset !important',
              display: 'inline',
              width: '100%',
              transition: 'height 0.35s ease-in-out',
            }}
          >
            <Col className="font-size-14 font-weight-500 px-0">
              <Row className="w-100 mx-0 justify-content-between">
                <div
                  className={`px-0 mb-0 text-${color}`}
                  style={{
                    minHeight: '1rem',
                    width: '80%',
                    whiteSpace: !expanded && 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {children}
                </div>
                <div className="px-0 mb-0">
                  <IconButton
                    icon={`las la-${expanded ? 'minus' : 'plus'}`}
                    click={() => {
                      setExpanded(!expanded);
                    }}
                    color={color}
                  />
                </div>
              </Row>
            </Col>
          </Collapse>
        </Row>
      </CardBody>
    </Card>
  );
};

export const SaleMessageBadge = ({
  title,
  children,
  backgroundColor,
  color,
  style,
}) => (
  <Card
    className="new-layout card w-100"
    style={style ? { backgroundColor, ...style } : { backgroundColor }}
  >
    <CardBody
      className="new-layout card-body"
      style={{ padding: '0.7rem 1rem' }}
    >
      <Row className="w-100 mx-0">
        <Col className="font-size-12 font-weight-500 px-0">
          <Row className="w-100 mx-0 justify-content-between">
            <div
              className={`px-0 mb-0 text-${color}`}
              style={{ minHeight: '1rem', width: '100%' }}
            >
              {title && <p className="font-size-16 mb-0">{title}</p>}
              {children}
            </div>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
