import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';
import RichTextEditor from 'react-rte';
import ReactHtmlParser from 'react-html-parser';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import DeleteModal from '~/components/Modals/DeleteModal';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import Loading from '~/components/Loading';
import FaqCard from './FaqCard';
import EditFAQ from './EditFAQ';
import AddFAQ from './AddFAQ';

const FAQ = () => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [loading, setLoading] = useState(true);
  const [faqs, setFAQS] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const [faqToBeEdited, setFAQToBeEdited] = useState();
  const [faqToBeDeleted, setFAQToBeDeleted] = useState();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const getURL = useMemo(() => {
    return '/faqs';
  }, []);

  const deleteURL = useMemo(() => {
    return `administrator/faqs/${faqToBeDeleted?.id}`;
  }, [faqToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    const formattedFaqs = data.frequently_asked_questions.map(faq => ({
      ...faq,
      formatted_answer: ReactHtmlParser(faq.answer),
    }));
    setFAQS(formattedFaqs);
    setPagination(data.meta);
  };

  useEffect(() => {}, [faqs]);

  useEffect(() => {
    document.title = 'FAQ | Viddy';

    const loadFAQS = async () => {
      const { data } = await api.get(getURL);

      setData(data);
      setLoading(false);
    };
    loadFAQS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="FAQ - PERGUNTAS FREQUENTES"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            <AddFAQ
              setAddModalOpen={setShowAddModal}
              addModalOpen={showAddModal}
              setFAQS={setFAQS}
            />
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {faqs.length ? (
              <>
                {faqs?.map(faq => {
                  return (
                    <FaqCard
                      key={faq.id}
                      faq={faq}
                      onEdit={incomingFaq => {
                        setValue(
                          RichTextEditor.createValueFromString(
                            incomingFaq.answer,
                            'html'
                          )
                        );
                        setFAQToBeEdited(incomingFaq);
                        setShowEditModal(true);
                      }}
                      onDelete={incomingFaq => {
                        setFAQToBeDeleted(incomingFaq);
                        setShowDeleteModal(true);
                      }}
                      canManage={isAdmin}
                    />
                  );
                })}
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhuma pergunta frequente encontrada.</h3>
            )}
          </>
        )}
      </Container>

      <EditFAQ
        show={showEditModal}
        setShow={setShowEditModal}
        faqToBeEdited={faqToBeEdited}
        setData={setData}
        getURL={getURL}
        value={value}
        setValue={setValue}
      />

      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={faqs}
        pagination={pagination}
        query={searchQuery}
        setItemToBeDeleted={setFAQToBeDeleted}
        headerText="Deseja mesmo excluir a pergunta?"
        bodyText="Essa ação irá excluir a pergunta"
        itemName={`"${faqToBeDeleted?.question}"`}
        successMsg="FAQ excluída com sucesso!"
      />
    </div>
  );
};

export default FAQ;
