/* VCF file content parser - RFC 6350 */

export function createVCardLink({
  name,
  lastname,
  store,
  role,
  email,
  telephone,
  description,
  website,
}) {
  const data = `BEGIN:VCARD
VERSION:3.0
N:${lastname || ''};${name};;;
FN:${name} ${lastname || ''}
${store ? `ORG:${store};` : ''}
${role ? `TITLE:${role}` : ''}
${email ? `EMAIL;type=INTERNET;type=WORK;type=pref:${email}` : ''}
${telephone ? `TEL;type=CELL;type=pref:+55 ${telephone}` : ''}
${description ? `NOTE: ${description}` : ''}
${website ? `item1.URL;type=pref:${website}` : ''}
END:VCARD
`;

  const vcf = new Blob([data], { type: 'text/vcard' });

  const vcfUrl = URL.createObjectURL(vcf);

  const link = document.createElement('a');
  link.download = `${name}`;
  link.href = vcfUrl;
  link.click();
}
