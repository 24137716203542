import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import ptBR from 'date-fns/locale/pt-BR';

export default function MonthPicker({ ...rest }) {
  const inputProps = {
    classes: {
      input: 'cursor-pointer',
    },
    endAdornment: (
      <IconButton aria-label="Select locale">
        <i className="las la-calendar" />
      </IconButton>
    ),
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <DatePicker
        views={['year', 'month']}
        minDate={new Date('2020-01-01')}
        maxDate={new Date()}
        initialFocusedDate={new Date()}
        openTo="month"
        placeholder="Mês"
        cancelLabel="Cancelar"
        clearable
        clearLabel="Limpar"
        InputProps={inputProps}
        classes={{
          root: 'w-100 w-lg-fit-content',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}
