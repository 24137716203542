import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
  NavLink,
  CustomInput,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { cpfSchema } from '~/validators/form/new_pix_link';
import Input from '~/components/Inputs/Input';
import PageHeader from '~/components/Common/PageHeader';
import { defineAlignment } from '~/util/newLayoutHelpers';
import { LG } from '~/util/breakpointWidths';
import InputMask from '~/components/Inputs/InputMask';
import history from '~/services/history';
import api from '~/services/api';
import { getRole } from '~/util/getRole';

const SplitReceiver = () => {
  const formRef = useRef();

  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const [serviceType, setServiceType] = useState('delivery');
  const [transferInterval, setTransferInterval] = useState('Daily');
  const [transferEnabled, setTransferEnabled] = useState(true);
  const [accountType, setAccountType] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [isLiable, setIsLiable] = useState(false);
  const [transferDay, setTransferDay] = useState('');
  const [transferDayValidationError, setTransferDayValidationError] =
    useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryMethodError, setDeliveryMethodError] = useState('');

  const [deliveryMethods, setDeliveryMethods] = useState([]);

  const loadDeliveryMethods = async () => {
    const { data } = await api.get('/utils/available-delivery-services');

    setDeliveryMethods(
      data.delivery_services.map(service => ({
        label: service,
        value: service,
      }))
    );
  };

  useEffect(() => {
    loadDeliveryMethods();
  }, []);

  const submitHandler = async formData => {
    const schema = Yup.object().shape({
      name: Yup.string().required('O nome é obrigatório'),
      document: Yup.string().required('O CPF/CNPJ é obrigatório'),
      email: Yup.string().email('E-mail no formato inválido'),
      bank: Yup.string()
        .length(3, 'Código do Banco deve ter 3 caracteres')
        .required('O Código do Banco é obrigatório'),
      branch_number: Yup.string()
        .matches(/^[0-9]{1,4}(-[0-9]{1,2}){0,1}$/m, 'Formato inválido')
        .required('O Número da Agência é obrigatório'),
      account_number: Yup.string().required('O Número da Conta é obrigatório'),
      account_check_digit: Yup.string().required(
        'O Número Verificador da Conta é obrigatório'
      ),
      transfer_interval: Yup.string().required(
        'O Intervalo de Transferência é obrigatório'
      ),
      transfer_day: Yup.string()
        .required('O Dia da Transferência é obrigatório')
        .test(
          'transfer_day_validation',
          'O Dia da Transferência Mensal deve ser um dia entre 1 e 31',
          val =>
            transferInterval === 'Daily'
              ? true
              : Number(val) > 1 && Number(val) < 31
        ),
      // amount: Yup.string(),
      holder_name: Yup.string()
        .test(
          'len',
          'Nome/Razão Social não pode ultrapassar 30 caracteres',
          val => val.length <= 30
        )
        .required('O Nome/Razão Social é obrigatório'),
    });

    let transfer_day = 0;

    const [accountNumber, accountNumberDV] = formData.account_number.split('-');

    try {
      if (transferInterval !== 'Daily') transfer_day = transferDay;

      if (['Weekly', 'Monthly'].includes(transferInterval) && !transferDay) {
        setTransferDayValidationError('O Dia da Transferência é obrigatório');
      } else {
        setTransferDayValidationError(null);
      }

      await schema.validate(
        {
          ...formData,
          branch_number: branchNumber,
          transfer_interval: transferInterval,
          transfer_day,
          account_number: accountNumber,
          account_check_digit: accountNumberDV,
          // amount,
        },
        {
          abortEarly: false,
        }
      );
    } catch (err) {
      console.error(err);

      setTransferDayValidationError(null);

      const validationErrors = {};

      if (
        transferInterval === 'Weekly' &&
        (Number(transferDay) < 1 || Number(transferDay) > 5)
      ) {
        setTransferDayValidationError('O Dia da Transferência é obrigatório');
        validationErrors.transfer_day = 'O Dia da Transferência é obrigatório';
      }

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          if (e.path === 'account_check_digit')
            validationErrors.account_number = e.message;
          else validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return;
    }

    try {
      await api.post(`/${role}/recipients`, {
        delivery_method: deliveryMethod,
        description: serviceType === 'delivery' ? 'Entrega' : 'Pagamento',
        name: formData.name,
        email: formData.email,
        document: formData.document,
        type: 'individual', // Valores: "individual" (pessoa física) ou "company" (pessoa jurídica).
        service_type: serviceType, // required - Tipo de seviço. Valores: "delivery" ou "payment".
        default_bank_account: {
          holder_name: formData.holder_name, // required - Nome da conta. Max: 30.
          holder_type: 'individual', // required - Tipo do documento. "individual" ou "company".
          holder_document: formData.document, // required - Número do doc. do titular. Deve ser igual ao documento do recebedor. Somente números
          bank: formData.bank, // required - Código do banco. Max 3.
          branch_number: branchNumber, // required - Número da agência. Max 4.
          account_number: accountNumber, // required - Número da conta. Max: 13.
          account_check_digit: accountNumberDV, // required - Digitos validadores da conta. Max 2.
          type: accountType.value, // required - Tipo da conta. Valores: "checking" ou "savings".
        },
        transfer_settings: {
          transfer_enabled: transferEnabled, // Indica se o recebedor receberá seus pagamentos automaticamente.
          transfer_interval: transferInterval, // Indica a frequência das transferências automáticas para a conta do recebedor. Valores: "Daily", "Weekly" ou "Monthly".
          transfer_day, // Indica o dia em que ocorrerá as transferências automáticas.
        },
        split_rules: {
          amount,
          type: 'percentage', // Tipo de divisão. Os valores possíveis são "flat" ou "percentage".
          charge_processing_fee: true, // Indica se o recebedor é responsável pela transação em caso de chargeback.
          charge_remainder_fee: true, // Indica se o recebedor vinculado à regra será cobrado pelas taxas da transação.
          liable: isLiable, // Indica se o recebedor vinculado à regra irá receber o restante dos recebíveis após uma divisão.
        },
      });
      history.push('/recebedores-split');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  const weekDays = useMemo(
    () => [
      {
        label: 'Segunda-feira',
        value: 1,
      },
      {
        label: 'Terça-feira',
        value: 2,
      },
      {
        label: 'Quarta-feira',
        value: 3,
      },
      {
        label: 'Quinta-feira',
        value: 4,
      },
      {
        label: 'Sexta-feira',
        value: 5,
      },
    ],
    []
  );

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="Cadastro de Recebedor"
          backTo="/recebedores-split"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form ref={formRef} onSubmit={submitHandler} initialData={{}}>
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <FormGroup className="mt-3">
                <Label>Tipo de Serviço</Label>
                <Row className="w-100 mx-0">
                  <Col lg={4} className="px-lg-0">
                    <NavLink
                      className={`
                        new-layout-nav-pill card d-flex flex-column
                        align-items-center h-fit-content w-100
                        ${serviceType === 'delivery' ? 'checked' : 'unchecked'}
                        font-size-14
                      `}
                      to="#"
                      onClick={() => setServiceType('delivery')}
                    >
                      <span className="text-nowrap">Entrega</span>
                    </NavLink>
                  </Col>
                  <Col lg={4}>
                    <NavLink
                      className={`
                        new-layout-nav-pill card d-flex flex-column
                        align-items-center h-fit-content w-100
                        ${serviceType === 'payment' ? 'checked' : 'unchecked'}
                        font-size-14
                      `}
                      to="#"
                      onClick={() => setServiceType('payment')}
                    >
                      <span className="text-nowrap">Pagamento</span>
                    </NavLink>
                  </Col>
                </Row>
              </FormGroup>
              <Row className="w-100 mx-0">
                <Col xs={12} lg={7} className="px-lg-0 pr-lg-2">
                  <FormGroup className="mb-0">
                    <Row className="mx-0">
                      <Label>Nome</Label>
                      {serviceType === 'payment' && (
                        <CustomInput
                          type="checkbox"
                          id="liable"
                          name="liable"
                          label="Responsável por Estorno"
                          className="ml-auto text-primary z-index-0 mt-1"
                          checked={isLiable}
                          onChange={() => setIsLiable(state => !state)}
                        />
                      )}
                    </Row>
                    <Input
                      name="name"
                      type="text"
                      max="128"
                      placeholder="Nome do Recebedor"
                    />
                  </FormGroup>
                </Col>
                {serviceType === 'delivery' && (
                  <Col xs={12} lg={5} className="mt-3 mt-lg-0 pl-lg-2">
                    <FormGroup className="mb-0">
                      <Label>Método de Delivery</Label>

                      <Select
                        name="delivery_method"
                        label="Método de Delivery"
                        placeholder="Selecione o método de delivery"
                        options={deliveryMethods}
                        styles={{
                          control: baseStyles => ({
                            ...baseStyles,
                            borderColor: deliveryMethodError
                              ? '#eb5757 !important'
                              : baseStyles.borderColor,
                          }),
                        }}
                        classNamePrefix="select"
                        value={deliveryMethods.find(
                          option => option.value === deliveryMethod
                        )}
                        onChange={e => setDeliveryMethod(e.value)}
                        onFocus={() => setDeliveryMethodError(null)}
                      />
                    </FormGroup>
                  </Col>
                )}
                {serviceType === 'payment' && (
                  <Col xs={12} lg={5} className="px-lg-0 pl-lg-2 mt-3 mt-lg-0">
                    <FormGroup className="mb-0">
                      <Label>Percentual (%)</Label>
                      <Input
                        name="percentage"
                        type="number"
                        placeholder="Valor"
                        value={amount}
                        onChange={e => {
                          if (Number(e.target.value) <= 100) {
                            formRef.current.setFieldError('percentage', null);
                            setAmount(e.target.value);
                            return;
                          }
                          formRef.current.setFieldError(
                            'percentage',
                            'Valor máximo é 100%'
                          );
                        }}
                        onFocus={() => {
                          formRef.current.setFieldError('percentage', null);
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row className="w-100 mx-0 mt-3">
                <Col xs={12} className="px-lg-0">
                  <FormGroup className="mb-4">
                    <Label>Email</Label>
                    <Input name="email" type="email" placeholder="Email" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Intervalo de Transferência</Label>
                <Row className="w-100 mx-0">
                  <Col lg={4} className="px-lg-0">
                    <NavLink
                      className={`
                        new-layout-nav-pill card d-flex flex-column
                        align-items-center h-fit-content w-100
                        ${
                          transferInterval === 'Daily' ? 'checked' : 'unchecked'
                        }
                        font-size-14
                      `}
                      to="#"
                      onClick={() => setTransferInterval('Daily')}
                    >
                      <span className="text-nowrap">Diário</span>
                    </NavLink>
                  </Col>
                  <Col lg={4}>
                    <NavLink
                      className={`
                        new-layout-nav-pill card d-flex flex-column
                        align-items-center h-fit-content w-100
                        ${
                          transferInterval === 'Weekly'
                            ? 'checked'
                            : 'unchecked'
                        }
                        font-size-14
                      `}
                      to="#"
                      onClick={() => setTransferInterval('Weekly')}
                    >
                      <span className="text-nowrap">Semanal</span>
                    </NavLink>
                  </Col>
                  <Col lg={4} className="px-lg-0">
                    <NavLink
                      className={`
                        new-layout-nav-pill card d-flex flex-column
                        align-items-center h-fit-content w-100
                        ${
                          transferInterval === 'Monthly'
                            ? 'checked'
                            : 'unchecked'
                        }
                        font-size-14
                      `}
                      to="#"
                      onClick={() => setTransferInterval('Monthly')}
                    >
                      <span className="text-nowrap">Mensal</span>
                    </NavLink>
                  </Col>
                </Row>
              </FormGroup>
              <Row className="w-100 mx-0 mt-3">
                <Col lg={6} className="pl-lg-0">
                  <FormGroup>
                    <Label>Dia da Transferência</Label>
                    {transferInterval === 'Daily' && (
                      <Input
                        name="transfer_day"
                        placeholder="Dia da Transferência"
                        disabled
                      />
                    )}
                    {transferInterval === 'Weekly' && (
                      <>
                        <Select
                          name="type"
                          label="Dia da Transferência"
                          placeholder="Selecione o Dia da Transferência"
                          options={weekDays}
                          styles={{
                            control: baseStyles => ({
                              ...baseStyles,
                              borderColor: transferDayValidationError
                                ? '#eb5757 !important'
                                : baseStyles.borderColor,
                            }),
                          }}
                          classNamePrefix="select"
                          value={weekDays.find(
                            day => day.value === transferDay
                          )}
                          onChange={e => setTransferDay(e.value)}
                          onFocus={() => setTransferDayValidationError(null)}
                        />
                        {transferDayValidationError && (
                          <span
                            className="text-danger"
                            style={{
                              position: 'unset',
                              whiteSpace: 'unset',
                            }}
                          >
                            {transferDayValidationError}
                          </span>
                        )}
                      </>
                    )}
                    {transferInterval === 'Monthly' && (
                      <Input
                        name="transfer_day"
                        type="number"
                        placeholder="Dia da Transferência"
                        onChange={e => setTransferDay(e.target.value)}
                        value={transferDay}
                      />
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-3">
                  <FormGroup>
                    <Label>Receber Pagamentos Automaticamente</Label>

                    <Row>
                      <Col xs={1}>
                        <CustomInput
                          type="radio"
                          id="mobile-select1"
                          name="transfer_enabled"
                          className="text-primary z-index-0"
                          checked={transferEnabled}
                          onChange={() => setTransferEnabled(true)}
                        />
                      </Col>
                      <Col xs={11}>
                        <label
                          className="new-layout-table-column-label text-dark mb-0"
                          htmlFor="mobile-select1"
                        >
                          Sim
                        </label>
                      </Col>

                      <Col xs={1}>
                        <CustomInput
                          type="radio"
                          id="mobile-select2"
                          name="transfer_enabled"
                          className="text-primary z-index-0"
                          checked={!transferEnabled}
                          onChange={() => setTransferEnabled(false)}
                        />
                      </Col>
                      <Col xs={11}>
                        <label
                          className="new-layout-table-column-label text-dark mb-0"
                          htmlFor="mobile-select2"
                        >
                          Não
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-4">
                <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 px-0 font-size-18 font-weight-bold">
                  Dados Bancários
                </Col>
              </Row>
              <Row className="w-100 mx-0 mt-3">
                <Col lg={6} className="pl-lg-0">
                  <FormGroup>
                    <Label>Tipo de Conta</Label>
                    <Select
                      name="type"
                      label="Tipo de Conta"
                      placeholder="Selecione o Tipo de Conta"
                      options={[
                        {
                          label: 'Poupança',
                          value: 'savings',
                        },
                        {
                          label: 'Corrente',
                          value: 'checking',
                        },
                      ]}
                      classNamePrefix="select"
                      value={accountType}
                      onChange={setAccountType}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="pr-lg-0">
                  <FormGroup>
                    <Label>Código do Banco</Label>
                    <Input
                      name="bank"
                      type="number"
                      placeholder="Código do Banco"
                      maxLength="3"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} className="pl-lg-0">
                  <FormGroup>
                    <Label>Agência</Label>
                    <InputMask
                      name="branch_number"
                      type="number"
                      placeholder="Agência"
                      maxLength="4"
                      value={branchNumber}
                      onChange={e => setBranchNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="pr-lg-0">
                  <FormGroup>
                    <Label>Número da Conta</Label>
                    <Input
                      name="account_number"
                      type="string"
                      placeholder="Número da Conta"
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} className="pl-lg-0">
                  <FormGroup>
                    <Label>CPF/CNPJ</Label>
                    <InputMask
                      name="document"
                      type="text"
                      placeholder="CPF/CNPJ"
                      mask="999.999.999-99"
                      maskChar={null}
                      onBlur={async e => {
                        try {
                          await cpfSchema.validate(e.target.value);
                        } catch (err) {
                          formRef.current.setFieldError(
                            'document',
                            err.message
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="pr-lg-0">
                  <FormGroup>
                    <Label>Nome/Razão Social</Label>
                    <Input
                      name="holder_name"
                      type="text"
                      placeholder="Nome/Razão Social"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4">
            <Col
              align={defineAlignment(windowSize, LG, 'center', 'end')}
              className="px-0"
            >
              <Button
                color="primary"
                className="new-layout-btn main px-4 my-4"
                size="lg"
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default SplitReceiver;
