import * as Yup from 'yup';

// const schema = (cpfValue, maskTelephoneLength, ignoreAddress, birthDateValue) =>
// const schema = (cpfValue, maskTelephoneLength) =>
const schema = (maskTelephoneLength, ignoreAddress, cpfValue, birthDateValue) =>
  Yup.object().shape({
    name: Yup.string().required('Insira o nome do cliente'),
    cpf: cpfValue
      ? Yup.string().min(14, 'Insira todos os dígitos do CPF')
      : Yup.string(),
    // birth_date: Yup.string()
    //   .required('Insira a data de nascimento')
    //   .min(10, 'Insira a data de nascimento'),
    birth_date: birthDateValue
      ? Yup.string().min(10, 'Insira a data de nascimento completa')
      : Yup.string(),
    telephone: Yup.string().min(maskTelephoneLength, 'Insira todos os dígitos'),
    email: Yup.string().email('E-mail no formato inválido'),
    address: !ignoreAddress
      ? Yup.object().shape({
          cep: Yup.string()
            .required('Insira o CEP corretamente')
            .min(9, 'Insira todos os dígitos do CEP'),
          street_name: Yup.string().required('Insira o nome da rua'),
          number: Yup.string()
            .matches(/^(\d+|sn)$/i, 'Informe apenas números neste campo ou SN')
            .max(10, 'Número deve ter no máximo 10 caracteres')
            .required('Insira o número ou use sn'),
          neighborhood: Yup.string().required('Insira o nome do bairro'),
          state: Yup.string().required('Selecione o estado'),
          city: Yup.string().required('Insira o nome da cidade'),
          complement: Yup.string()
            .notRequired()
            .max(128, 'Máximo de 128 caracteres'),
          additional_information: Yup.string()
            .notRequired()
            .max(
              128,
              'As informações adicionais devem ter no máximo 128 caracteres'
            ),
        })
      : Yup.object().notRequired(),
  });

export default schema;
