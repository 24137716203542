import React, { useMemo } from 'react';
import { Badge, Card, CardBody, Col, CustomInput, Row } from 'reactstrap';

import { useSelector } from 'react-redux';
import history from '~/services/history';
import stdProfilePicture from '~/assets/images/user.png';

import IconButton from '~/components/IconButton';

export default function UserProfileCard({
  user,
  isSelected,
  onSelect,
  onDelete,
  onRestore,
  isAllowedToBatch,
}) {
  const roles = useSelector(({ user: User }) => User.profile.roles);

  const isStoreManager = useMemo(
    () => roles.find(role => role.slug === 'store-manager'),
    [roles]
  );

  const defineRoleString = role => {
    if (role === 'Administrador') return 'Admin';
    if (role === 'Consultor Digital') {
      return 'Vendedor';
    }
    return role;
  };

  return (
    <Card className="card new-layout list-card grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 h-100 mx-0 d-none d-lg-flex">
          <Col xs={1} align="center" className="h-100">
            {isAllowedToBatch && (
              <div
                className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap outline-none"
                onClick={e => {
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                aria-label="checkbox-wrapper-div"
              >
                <CustomInput
                  type="checkbox"
                  id={`${user.id}-checkbox`}
                  className="text-primary"
                  checked={isSelected}
                  defaultValue={isSelected}
                  onChange={() => onSelect(user, isSelected)}
                />
              </div>
            )}
          </Col>
          <Col xs={2} align="start" className="h-100">
            <Row className="w-100 h-100 mx-0">
              <Col
                xs={3}
                className="d-flex flex-row align-items-center px-0 mr-2"
              >
                <img
                  src={
                    user?.profile_picture?.url
                      ? user?.profile_picture.url
                      : stdProfilePicture
                  }
                  alt="profile-pic"
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Col>
              <Col
                xs={8}
                className="pr-0 d-flex flex-row align-items-center flex-wrap"
              >
                {user.broken_name.map((word, index) => {
                  return (
                    <div
                      key={`${word}-${user.id}`}
                      className={`new-layout-card-list-strong-value w-100 ${
                        index > 0 && 'text-truncate'
                      }`}
                    >
                      {word}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
          <Col
            xs={3}
            align="start"
            className="h-100 d-flex flex-column justify-content-center"
          >
            {user.broken_email?.map((word, index) => {
              return (
                <div
                  key={`${word}-${user.id}`}
                  className={`new-layout-table-column-value ${
                    index > 0 && 'text-truncate'
                  }`}
                >
                  {`${index === 1 ? '@' : ''}${word}`}
                </div>
              );
            })}
          </Col>
          <Col xs={3} align="center" className="h-100">
            <div className="h-100 d-flex flex-row justify-content-flex-start align-items-center flex-wrap">
              {user.roles.length ? (
                user.roles.map(role => (
                  <h6 key={`${role.name}-badge-${user.id}`}>
                    <Badge className={`role-badge ${role.slug} d-inline mr-2`}>
                      {defineRoleString(role.name)}
                    </Badge>
                  </h6>
                ))
              ) : (
                <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
                  Nenhum cargo atribuído
                </div>
              )}
            </div>
          </Col>
          <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
              {user.formatted_updated_at}
            </div>
          </Col>
          <Col xs={1} align="center" className="h-100">
            <div className="h-100 d-flex flex-row align-items-center justify-content-around">
              {!user.deleted_at ? (
                <>
                  <IconButton
                    icon="las la-search"
                    color="info"
                    id={`details-btn-${user.id}`}
                    tip="Detalhes"
                    forceTip
                    click={() => {
                      history.push(`usuarios/perfil/${user.id}`);
                    }}
                  />
                  {!(
                    (user.roles.find(role => role.slug === 'store-manager') ||
                      user.roles.find(role => role.slug === 'administrator')) &&
                    isStoreManager
                  ) && (
                    <>
                      <IconButton
                        icon="las la-pen"
                        color="primary"
                        id={`edit-btn-${user.id}`}
                        tip="Editar"
                        forceTip
                        click={() => {
                          history.push(`usuarios/perfil/${user.id}/editar`);
                        }}
                      />
                      <IconButton
                        icon="las la-ban"
                        color="danger"
                        id={`ban-btn-${user.id}`}
                        tip="Desativar"
                        forceTip
                        click={() => onDelete(user)}
                      />
                    </>
                  )}
                </>
              ) : (
                <IconButton
                  icon="las la-undo"
                  color="success"
                  id={`restore-btn-${user.id}`}
                  tip="Reativar"
                  forceTip
                  click={() => onRestore(user)}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="w-100 h-100 mx-0 d-lg-none">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col xs={9} align="start" className="px-0">
                <div className="h-100 d-flex flex-row align-items-center flex-wrap">
                  {user.roles.length ? (
                    user.roles.map(role => (
                      <h6 key={`${role.slug}-mobile-badge-${user.id}`}>
                        <Badge
                          className={`role-badge ${role.slug} d-inline mr-2`}
                        >
                          {defineRoleString(role.name)}
                        </Badge>
                      </h6>
                    ))
                  ) : (
                    <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap text-danger">
                      Nenhum cargo atribuído
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={3} className="px-0" align="end">
                {!user.deleted_at ? (
                  <div className="h-100 d-flex flex-row justify-content-around">
                    <IconButton
                      icon="las la-search"
                      color="info"
                      id={`details-mobile-btn-${user.id}`}
                      tip="Detalhes"
                      click={() => {
                        history.push(`usuarios/perfil/${user.id}`);
                      }}
                    />
                    {!(
                      user.roles.find(role => role.slug === 'store-manager') &&
                      isStoreManager
                    ) && (
                      <IconButton
                        icon="las la-pen"
                        color="primary"
                        id={`edit-mobile-btn-${user.id}`}
                        tip="Editar"
                        click={() => {
                          history.push(`usuarios/perfil/${user.id}/editar`);
                        }}
                      />
                    )}
                    <IconButton
                      icon="las la-ban"
                      color="danger"
                      id={`ban-mobile-btn-${user.id}`}
                      tip="Desativar"
                      click={() => onDelete(user)}
                    />
                  </div>
                ) : (
                  <IconButton
                    icon="las la-undo"
                    color="success"
                    id={`restore-mobile-btn-${user.id}`}
                    tip="Reativar"
                    click={() => onRestore(user)}
                  />
                )}
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col
                xs={2}
                sm={1}
                className="new-layout-card-mobile label d-inline px-0 pt-1 mx-0 new-layout-user-card-label-col-width"
                // style={{ minWidth: '17%' }}
              >
                Usuário:
              </Col>
              <Col xs={9} className="px-0">
                <Row className="w-100 h-100 mx-0">
                  <Col
                    xs={1}
                    className="d-flex flex-row align-items-center pr-0"
                  >
                    <img
                      src={
                        user?.profile_picture?.url
                          ? user?.profile_picture.url
                          : stdProfilePicture
                      }
                      alt="profile-pic"
                      style={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />
                  </Col>
                  <Col
                    xs={10}
                    className="pr-0 d-flex flex-row align-items-center"
                  >
                    <div className="new-layout-card-list-strong-value ml-1 text-truncate">
                      {user.name}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline mh-100">
                  <div className="new-layout-card-mobile label d-inline">
                    E-mail:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {user.email}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Última Atualização:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {user.formatted_updated_at}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
