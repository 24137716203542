import produce from 'immer';
import { format, parseISO } from 'date-fns';

import { ptBR } from 'date-fns/locale';
import { SenseCaseSearch } from '~/util/sensecase';

import {
  ADD_SALE,
  REMOVE_SALE_SUCCESS,
  LOCAL_SEARCH,
  SEARCH_SALE_SUCCESS,
  API_ERROR,
  UPDATE_SALE_LIST_SUCCESS,
  SEARCH_SALE,
  SET_LOADING,
  UPDATE_SALE_LIST,
  SET_AS_RESCHEDULED,
} from './actionTypes';

const INITIAL_STATE = {
  sales: [],
  paginationMeta: null,
  loading: true,
  rescheduled: false,
  error: null,
};

export default function Sales(state = INITIAL_STATE, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case SET_LOADING: {
        const { loading } = payload;
        draft.loading = loading;
        break;
      }
      case ADD_SALE: {
        draft.sales = [payload, ...state];
        break;
      }
      case REMOVE_SALE_SUCCESS: {
        const sales = draft.sales.filter(({ id }) => id !== payload);
        draft.sales = [...sales];
        break;
      }
      case UPDATE_SALE_LIST: {
        break;
      }
      case UPDATE_SALE_LIST_SUCCESS: {
        draft.loading = false;
        const { sales, paginationMeta } = payload;

        const salesUpdated = sales.filter(
          sale => sale.id !== draft.sales.map(draftSale => draftSale.id)
        );

        draft.sales = [...salesUpdated];
        draft.paginationMeta = paginationMeta;
        break;
      }
      case LOCAL_SEARCH:
        {
          const sale = draft.sales.filter(
            item =>
              SenseCaseSearch(item.store.name, payload) ||
              SenseCaseSearch(item.client.name, payload) ||
              SenseCaseSearch(item.order_number, payload)
          );

          draft.sales = [...sale];
        }
        break;
      case SEARCH_SALE: {
        draft.loading = true;
        break;
      }
      case SET_AS_RESCHEDULED: {
        draft.rescheduled = true;
        break;
      }
      case SEARCH_SALE_SUCCESS: {
        const sale = payload.map(cart => ({
          ...cart,
          updated_at: format(
            parseISO(cart.updated_at),
            "HH'h'mm '|' dd/MMM/yy",
            {
              locale: ptBR,
            }
          ),
        }));

        draft.sales = [...sale];
        draft.loading = false;
        break;
      }
      case API_ERROR: {
        draft.error = payload;
        break;
      }
      default:
    }
  });
}
