import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SYSTEM_ROLES } from '~/util/ROLES';

const useRoles = () => {
  const [ROLES, setROLES] = useState({});
  const roles = useSelector(({ user }) => user.profile.roles);

  useEffect(() => {
    const RAW_USER_ROLES = SYSTEM_ROLES.map(role => ({
      [role.flag]: roles.some(r => r.slug === role.slug),
    }));

    let ROLES_OBJ = {};

    RAW_USER_ROLES.forEach(role => {
      ROLES_OBJ = { ...ROLES_OBJ, ...role };
    });

    setROLES(ROLES_OBJ);
  }, [roles]);

  return ROLES;
};

export default useRoles;
