import axios from 'axios';
import { toast } from 'react-toastify';

import { logoutUser } from '~/store/modules/auth/login/actions';
import history from './history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

api.CancelToken = axios.CancelToken;
api.isCancel = axios.isCancel;

let isRefreshing = false;
let failedQueue = [];

api.interceptors.request.use(config => {
  return new Promise((resolve, _) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS += 1;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

api.interceptors.response.use(
  response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  error => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.registerInterceptWithStore = store => {
  api.interceptors.response.use(
    response => response,
    async err => {
      if (!err.response) {
        toast.error('Não foi possível comunicar com o servidor');
      }

      const originalRequest = err.config;

      const maintenanceMode = err.response && err.response?.status === 503;

      if (maintenanceMode) {
        const { user } = store.getState();
        const isLogged = !!user.profile;
        if (isLogged) {
          history.push('/manutencao');
        }
        return Promise.reject(err);
      }

      const needsToRefresh =
        err.response &&
        err.response?.status === 401 &&
        typeof err?.response?.data?.message === 'string' &&
        err?.response?.data?.message === 'Token has expired';

      if (needsToRefresh) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers.authorization = `Bearer ${token}`;
              return api(originalRequest);
            })
            .catch(error => error);
        }

        isRefreshing = true;

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const { data } = await api.get('/auth/refresh-token');

            localStorage.setItem('@VIDDY:Token', data.token);
            localStorage.setItem('@VIDDY:X-Server', data.server);

            api.defaults.headers.authorization = `Bearer ${data.token}`;
            api.defaults.headers['X-Server'] = data.server;

            originalRequest.headers.authorization =
              api.defaults.headers.authorization;

            processQueue(null, data.token);
            resolve(api(originalRequest));
          } catch (error) {
            processQueue(error, null);
            store.dispatch(logoutUser());
            reject(error);
          } finally {
            isRefreshing = false;
          }
        });
      }

      return Promise.reject(err);
    }
  );
};

export default api;
