import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import api from '~/services/api';

export default function DropdownFilter({
  options,
  defaultOption,
  url,
  filter,
  setFilter,
  setLoading,
  setData,
  params,
  query,
  setQuery,
  successMsg,
  name,
  id,
  placeholder,
  additionalClassName,
  width,
  clearable,
  clearQueryOnChange,
  keepPageOnFilter,
  setReqRunning,
  menuListWidth,
}) {
  const [prevFilter, setPrevFilter] = useState(filter);
  const [timesFilterWasUsed, setTimesFilterWasUsed] = useState(-1);

  const indicatorWidth = 21;
  const controlPadding = 8;

  const defaultValue = useMemo(() => {
    if (options && defaultOption !== undefined) {
      return options.find(option => option.value === defaultOption);
    }
    return null;
  }, [options, defaultOption]);

  const controlWidth = useMemo(() => {
    if (width) {
      return width;
    }
    return 150;
  }, [width]);

  const valuelWidth = useMemo(() => {
    const multiplier = clearable ? 2 : 1;
    return controlWidth - multiplier * indicatorWidth - 2 * controlPadding;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlWidth]);

  const placeholderWidth = useMemo(() => {
    return controlWidth - indicatorWidth - 2 * controlPadding;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlWidth]);

  const customStyles = {
    control: () => ({
      height: '37px',
      minWidth: 'auto',
      width: controlWidth,
      padding: controlPadding,
      display: 'flex',
      flexDirextion: 'row',
    }),
    menu: (provided, _) => ({
      ...provided,
      width: menuListWidth || controlWidth,
    }),
    option: (provided, _) => ({
      ...provided,
      textAlign: 'start',
    }),
    // menuList: () => ({
    //   width: controlWidth,
    // }),
    valueContainer: () => ({
      height: '21px',
      display: 'inline',
      padding: 0,
      cursor: 'default',
    }),
    singleValue: () => ({
      height: '21px',
      width: valuelWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    indicatorsContainer: () => ({
      height: '21px',
      display: 'flex',
      flexDirextion: 'row',
      justifyContent: 'flex-end',
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      height: '21px',
      padding: 0,
    }),
    clearIndicator: () => ({
      height: '21px',
      padding: 0,
    }),
    // menuList: base => ({
    //   ...base,
    //   width: menuListWidth || null,
    // }),

    placeholder: () => ({
      // ...provided,
      fontSize: '14px !important',
      fontWeight: '500 !important',
      color: 'black !important',
      width: placeholderWidth,
      textAlign: 'start',
    }),
  };

  const handleChange = async () => {
    try {
      if (setReqRunning) {
        setReqRunning(true);
      }

      if (clearQueryOnChange && query) {
        setQuery(null);
      }

      setLoading(true);

      const { data } = await api.get(url, {
        params: {
          ...params,
          page: keepPageOnFilter ? params.page : null,
        },
      });

      setData(data);
      setLoading(false);

      if (setReqRunning) {
        setReqRunning(false);
      }

      if (successMsg) {
        toast.success(successMsg);
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (prevFilter !== filter) {
      setPrevFilter(filter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setTimesFilterWasUsed(timesFilterWasUsed + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevFilter]);

  useEffect(() => {
    if (timesFilterWasUsed > 0) {
      handleChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesFilterWasUsed]);

  return (
    <Select
      name={`${name}-dropdown-filter`}
      id={`${id}-dropdown-filter`}
      options={options}
      isClearable={clearable}
      isSearchable={false}
      classNamePrefix="select"
      className={`new-layout-dropdown-filter ${additionalClassName}`}
      placeholder={placeholder || 'Nenhum filtro selecionado'}
      defaultValue={defaultValue}
      onChange={option => setFilter(option?.value)}
      styles={customStyles}
      // menuIsOpen
    />
  );
}
