/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

export default function ColorPicker({ color, setColor }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const hex2rgba = (hex, alpha = 1) => {
    if (color) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return {
        r,
        g,
        b,
        a: alpha,
      };
    }
    return {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    };
  };

  const [colorPicker, setColorPicker] = useState(
    hex2rgba(color) || {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    }
  );

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = data => {
    setColorPicker(data.rgb);
    setColor(data.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '139px',
        height: '25px',
        borderRadius: '2px',
        background: `rgba(${colorPicker.r}, ${colorPicker.g}, ${colorPicker.b}, ${colorPicker.a})`,
      },
      swatch: {
        padding: '10px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={colorPicker} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}
