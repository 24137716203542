import { useEffect, useState, useCallback } from 'react';
import { Row } from 'reactstrap';
import { formatPrice } from '~/util/format';
import Loading from '~/components/Loading';
import api from '~/services/api';
import CustomTable from './CustomTable';
import Table from './Table';

function ListTab({
  isAdmin,
  selectedStore,
  startDateParameter,
  endDateParameter,
}) {
  const [loading, setLoading] = useState(true);
  const [topSellers, setTopSellers] = useState(null);
  // const [, setTopStores] = useState(null);
  const [topClients, setTopClients] = useState(null);
  const [revenueByStoreAndSeller, setRevenueByStoreAndSeller] = useState(null);
  const [usersWithClients, setUsersWithClients] = useState(null);

  const formatUsersWithClients = useCallback(usersWithClientsData => {
    if (usersWithClientsData.error) return usersWithClientsData;

    const stores = {};

    Object.entries(usersWithClientsData).forEach(([key, value]) => {
      stores[key] = {
        sellers: value.sellers.reduce(
          (acc, seller) => ({
            ...acc,
            [seller.name]: seller.total_clients,
          }),
          {}
        ),
      };

      Object.entries(value.total_clients).forEach(([month, total_clients]) => {
        stores[key][month] = { total: { amount: total_clients.total } };
      });
    });

    return stores;
  }, []);

  const load = useCallback(() => {
    setLoading(true);
    Promise.all([
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/topSellers?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setTopSellers(data))
        .catch(() => setTopSellers({ error: 'Erro ao carregar informações' })),
      // api.get(
      //   `administrator/pages/dashboard/topStores?storeId=${
      //     selectedStore?.value || ''
      //   }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
      // ).then(({data}) => setTopStores(data))
      // .catch(() => setTopStores({error: 'Erro'})),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/topClients?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setTopClients(data))
        .catch(() => setTopClients({ error: 'Erro ao carregar informações' })),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/getRevenueByStoreAndSeller?storeId=${
            selectedStore?.value || ''
          }&dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setRevenueByStoreAndSeller(data))
        .catch(() =>
          setRevenueByStoreAndSeller({ error: 'Erro ao carregar informações' })
        ),
      api
        .get(
          `${
            isAdmin ? 'administrator/pages/dashboard' : 'store-manager/pages'
          }/usersWithClients?dateStart=${startDateParameter}&dateFinish=${endDateParameter}`
        )
        .then(({ data }) => setUsersWithClients(data))
        .catch(() =>
          setUsersWithClients({ error: 'Erro ao carregar informações' })
        ),
    ]).then(() => setLoading(false));
  }, [isAdmin, selectedStore, startDateParameter, endDateParameter]);

  useEffect(load, [load]);

  if (loading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loading />
      </div>
    );

  return (
    <>
      <Row className="w-100 mx-0">
        <CustomTable
          title="Receita por Loja/Vendedor"
          data={revenueByStoreAndSeller}
          formatData={data => formatPrice(Number(data))}
        />
        <CustomTable
          title="Clientes Fidelizados por Loja/Vendedor"
          data={formatUsersWithClients(usersWithClients)}
          formatData={value => value?.total || value}
        />
        <Table
          title="Top 5 Vendedores"
          columns={['Nome', 'Receita']}
          rows={
            topSellers.error
              ? topSellers
              : Object.keys(topSellers).map(key => [
                  key,
                  formatPrice(
                    Object.values(topSellers[key]).reduce(
                      (acc, value) => acc + Number(value.total),
                      0
                    )
                  ),
                ])
          }
        />
      </Row>
      {/* <Row className="w-100 mx-0">
            <Table
            title="Top 5 Lojas"
            columns={['Nome', 'Receita']}
            rows={Object.keys(topStores).map(key => {
                return [
                key,
                `R$${Object.values(topStores[key])
                    .reduce((acc, value) => acc + Number(value.total), 0)
                    .toFixed(2)}`,
                ];
            })}
            />
        </Row> */}
      <Row className="w-100 mx-0">
        <Table
          title="Top 5 Clientes"
          columns={['Nome', 'Receita']}
          rows={
            topClients.error
              ? topClients
              : Object.keys(topClients).map(key => [
                  key,
                  formatPrice(
                    Object.values(topClients[key]).reduce(
                      (acc, value) => acc + Number(value.total),
                      0
                    )
                  ),
                ])
          }
        />
      </Row>
    </>
  );
}

export default ListTab;
