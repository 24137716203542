import { useEffect, useRef, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
import Input from '~/components/Inputs/Input';
import logoWhite from '~/assets/images/logo-white.svg';
import api from '~/services/api';
import isDarkColor from '~/util/isDarkColor';
import { maskBuilder } from '~/util/maskTelephone';
import SellerImage from '../BusinessCard/SellerImage';

function NewClientForm({ setSuccess, profile, xserver, userId }) {
  const formRef = useRef(null);

  // customer id will only be not null when search by telephone
  // finds a customer with the same telephone already, so the submit
  // will update this customer data
  const [customerId, setCustomerId] = useState(null);

  const [interests, setInterests] = useState([]);
  const [telephone, setTelephone] = useState([]);
  const [name, setName] = useState([]);
  const [interest, setInterest] = useState([]);
  const [acceptNewletter, setAcceptNewletter] = useState(false);
  const [acceptAudio, setAcceptAudio] = useState(false);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--primary',
      profile?.stock?.customize?.theme_color
    );
  }, [profile?.stock?.customize?.theme_color]);

  const getInterests = useCallback(async () => {
    const { data } = await api.get('/crm/customers/interests');

    setInterests(
      Object.entries(data.interests).map(([key, value]) => ({
        label: value,
        value: Number(key),
      }))
    );
  }, []);

  useEffect(getInterests, [getInterests]);

  const submitHandler = async rawFormData => {
    const formData = {
      ...rawFormData,
      name,
      telephone,
      accept_newsletter: acceptNewletter,
      accept_audio_whatsapp: acceptAudio,
      birth_date: rawFormData.birth_date.replaceAll('_', ''),
    };

    const schema = Yup.object().shape({
      name: Yup.string().required('O nome é obrigatório'),
      whatsapp: Yup.string()
        .required('O whatsapp é obrigatório')
        .min(14, 'O whatsapp é obrigatório'),
      birth_date: Yup.string().min(
        10,
        'A Data inserida está com formato inválido (DD/MM/AAAA)'
      ),
    });

    try {
      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      console.error(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return;
    }

    if (customerId) {
      try {
        await api.put(
          `/crm/customers/${customerId}`,
          {
            ...formData,
            accept_audio_whatsapp: Boolean(acceptAudio),
            accept_newsletter: Boolean(acceptNewletter),
            whatsapp: telephone,
            type: 2,
            birth_date: formData.birth_date || undefined,
            user_id: userId,
          },
          {
            headers: localStorage.getItem('@VIDDY:X-Server')
              ? undefined
              : { 'x-server': xserver },
          }
        );

        setSuccess(true);
      } catch (err) {
        console.error(err);
      }
      return;
    }

    try {
      await api.post(
        '/crm/customers',
        {
          ...formData,
          accept_audio_whatsapp: acceptAudio,
          accept_newsletter: acceptNewletter,
          whatsapp: telephone,
          type: 2,
          birth_date: formData.birth_date || undefined,
          user_id: userId,
        },
        {
          headers: localStorage.getItem('@VIDDY:X-Server')
            ? undefined
            : {
                'x-server': xserver,
              },
        }
      );

      setSuccess(true);
    } catch (err) {
      if (
        err?.response?.data?.telephone[0] ===
        'O campo número de telefone já está sendo utilizado.'
      ) {
        formRef.current.setFieldError(
          'whatsapp',
          'Número de telefone já está sendo utilizado.'
        );
      }
    }
  };

  const searchByTelephone = async phone => {
    try {
      const {
        data: { data },
      } = await api.get(`/crm/customers/find?telephone=${phone}`, {
        headers: localStorage.getItem('@VIDDY:X-Server')
          ? undefined
          : {
              'x-server': xserver,
            },
      });

      setCustomerId(data.id);

      setName(data.name);

      formRef.current.setFieldValue('birth_date', data.birth_date || '');

      const interestData = interests.find(
        option => option.label === data.interest
      );
      if (interestData) setInterest(interestData);

      setAcceptNewletter(data.accept_newsletter);
      setAcceptAudio(data.accept_audio_whatsapp);

      formRef.current.setFieldValue('observation', data.observation || '');
    } catch (err) {
      console.error(err);
    }
  };

  const formatNumber = tel => {
    const phone = tel.replace(/\D/g, '');
    const ddd = phone.slice(0, 2);
    let first;
    let last;
    if (phone[2] === '9' && phone.length === 11) {
      first = phone.slice(2, 7);
      last = phone.slice(7, 11);
    } else {
      first = phone.slice(2, 6);
      last = phone.slice(6, 10);
    }
    return `(${ddd}) ${first}-${last}`;
  };

  const themeIsWhite = profile?.stock?.customize?.theme_color === '#ffffff';
  const isDarkTheme = profile?.stock?.customize?.theme_color
    ? isDarkColor(profile?.stock?.customize?.theme_color)
    : false;

  return (
    <div className="crm-new-customer-form">
      <Row
        className="w-100 mx-0 mb-md-4"
        style={{
          height: '65px',
          backgroundColor: 'var(--primary)',
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <img
            src={profile?.stock?.customize?.brand_logo?.url || logoWhite}
            alt="logo"
            id="logo"
            style={{
              height: '60px',
              width: '160px',
            }}
          />
        </Container>
      </Row>

      <Col lg={6} className="mx-auto px-4 px-sm-5 px-lg-0">
        <Card className="card new-layout mt-4">
          <CardBody
            className="card-body new-layout d-flex p-0"
            style={{
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            }}
          >
            <Row className="m-0">
              <Col xs={12} align="center" className="p-3 bg-secondary">
                <Row className="seller-card-header">
                  <SellerImage
                    className="seller-image"
                    src={profile?.seller_image}
                  />
                  <h4 className="text-dark seller-title">
                    {profile?.name} - {profile?.stock?.fantasy_name}
                  </h4>
                </Row>
              </Col>
              <Col xs={12} className="p-4">
                <p className="mb-0">
                  Olá seja bem vindo(a) a minha comunidade de cliente. Informe
                  como gostaria de ser atendida.
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <Form
        ref={formRef}
        onSubmit={submitHandler}
        className="px-4 px-sm-5 px-lg-0 crm-new-customer-form"
      >
        <Col lg={6} className="mt-0 mx-auto px-0">
          <Row>
            <Col md={6}>
              <FormGroup>
                <InputMask
                  name="whatsapp"
                  placeholder="Whatsapp"
                  style={{ borderRadius: '8px' }}
                  mask={maskBuilder(telephone)}
                  maskChar={null}
                  value={telephone}
                  onChange={e => {
                    setCustomerId(null);
                    if (
                      e.target.value.length < 15 ||
                      e.target.value[5] === '9'
                    ) {
                      setTelephone(formatNumber(e.target.value));
                    }
                    if (e.target.value.length === 14) {
                      searchByTelephone(formatNumber(e.target.value));
                    }
                    if (
                      e.target.value.length === 15 &&
                      e.target.value[5] === '9'
                    ) {
                      searchByTelephone(formatNumber(e.target.value));
                    }
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <InputMask
                  name="name"
                  placeholder="Nome e Sobrenome"
                  style={{ borderRadius: '8px' }}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <InputMask
                  name="birth_date"
                  mask="99/99/9999"
                  maskChar="_"
                  placeholder="Quando é seu aniversário?"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #CCCCCC',
                    borderRadius: '8px',
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-0">
                <InputSelect
                  name="interest_id"
                  options={interests}
                  placeholder="Classifique por interesse"
                  value={interest}
                  onChange={setInterest}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      height: '40px',
                      borderRadius: '8px !important',
                    }),
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup className="mt-4">
            <Row>
              <Col xs={10}>
                <p>
                  Tem interesse em participar da lista de transmissão e/ou grupo
                  de WhatsApp?
                </p>
              </Col>
              <Col xs={2}>
                <div
                  className="text-primary my-2 float-right custom-switch custom-control"
                  style={{ transform: 'scale(1.4)' }}
                >
                  <input
                    type="checkbox"
                    id="WhatsappNewletterSwitch"
                    name="accept_newsletter"
                    className="custom-control-input crm-control-input"
                    checked={acceptNewletter}
                    onChange={e => setAcceptNewletter(e.target.checked)}
                  />
                  <label
                    className="custom-control-label crm-control-label"
                    htmlFor="WhatsappNewletterSwitch"
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col xs={10}>
                <p>
                  Posso mandar áudios quando entrar em contato pelo Whatsapp?
                </p>
              </Col>
              <Col xs={2}>
                <div
                  className="text-primary my-2 float-right custom-switch custom-control"
                  style={{ transform: 'scale(1.4)' }}
                >
                  <input
                    type="checkbox"
                    id="WhatsappAudioSwitch"
                    name="accept_audio_whatsapp"
                    className="custom-control-input crm-control-input"
                    checked={acceptAudio}
                    onChange={e => setAcceptAudio(e.target.checked)}
                  />
                  <label
                    className="custom-control-label crm-control-label"
                    htmlFor="WhatsappAudioSwitch"
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Input
              name="observation"
              placeholder="Algum cuidado ao falar com você pelo WhatsApp, como horário de atendimento frequência, tratamento e etc."
              multiline
              style={{ height: '5rem', maxHeight: 'none' }}
            />
          </FormGroup>

          <button
            className="new-layout-btn main mt-2 mb-5"
            type="submit"
            style={{
              backgroundColor: 'var(--primary)',
              color: isDarkTheme ? 'white' : 'black',
              border: themeIsWhite ? '1px solid #000000' : 'none',
              padding: '0.5rem 1rem',
            }}
          >
            Confirmar
          </button>
        </Col>
      </Form>
    </div>
  );
}

export default NewClientForm;
