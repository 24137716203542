import React from 'react';

import ReactPaginate from 'react-paginate';
import api from '~/services/api';

function Pagination({
  paginationMeta,
  url,
  setData,
  setLoading,
  params,
  forcePage,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 5,
  position = 'end',
  setReqRunning,
}) {
  const onPageChange = async ({ selected }) => {
    if (setReqRunning) {
      setReqRunning(true);
    }
    setLoading(true);

    const { data } = await api.get(url, {
      params: {
        ...params,
        page: selected >= 1 ? selected + 1 : null,
      },
    });

    setData(data);
    setLoading(false);

    if (setReqRunning) {
      setReqRunning(false);
    }
  };

  const width = window.innerWidth;

  return (
    <nav
      className={`pagination pagination-rounded justify-content-center justify-content-lg-${position}`}
    >
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        pageCount={paginationMeta?.last_page || 1}
        initialPage={paginationMeta?.current_page - 1}
        marginPagesDisplayed={width > 414 ? marginPagesDisplayed : 1}
        onPageChange={data => onPageChange(data)}
        pageRangeDisplayed={width > 414 ? pageRangeDisplayed : 4}
        containerClassName="pagination m-0"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextClassName="page-item"
        disabledClassName="disabled"
        disableInitialCallback
        forcePage={forcePage || paginationMeta?.current_page - 1}
      />
    </nav>
  );
}

export default Pagination;
