import { Form } from '@unform/web';
import { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import PageHeader from '~/components/Common/PageHeader';
import IconButton from '~/components/IconButton';
import Input from '~/components/Inputs/Input';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';
import api from '~/services/api';
import history from '~/services/history';

function EditCustomerForm() {
  const profile_id = useSelector(({ user }) => user.profile.id);
  const { customer_id } = useParams();
  const formRef = useRef();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);

  const loadCustomer = useCallback(async () => {
    const { data } = await api.get(`/crm/customers/${customer_id}`);

    setCustomer(data.data);
    setLoading(false);
  }, [customer_id]);

  const deleteCustomer = async () => {
    await api.delete(`/crm/customers/${customer_id}`);

    history.push('/carteira-de-clientes');
  };

  const loadUrl = urlString => {
    try {
      const url = new URL(urlString);
      return url.pathname.substring(1);
    } catch (_) {
      return undefined;
    }
  };

  useEffect(loadCustomer, [loadCustomer]);

  const userData = useMemo(
    () => customer?.users?.find(({ id }) => id === profile_id),
    [profile_id, customer.users]
  );

  const isValidHttpUrl = string => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
      return false;
    }
  };

  const formatInstagramUsername = username => {
    if (!username) return '';

    if (isValidHttpUrl(username)) return username;

    if (username[0] !== '@') return `https://instagram.com/${username}`;

    return `https://instagram.com/${username.substring(1)}`;
  };

  const formatFacebookUsername = username => {
    if (!username) return '';

    if (isValidHttpUrl(username)) return username;

    return `https://facebook.com/${username}`;
  };

  const submitHandler = async formData => {
    setLoading(true);

    const schema = Yup.object().shape({
      instagram: Yup.string('Insira o usuário do perfil do instagram'),
      facebook: Yup.string('Insira o usuário do perfil do facebook'),
      tag: Yup.string().max(30, 'Etiqueta não pode ultrapassar 30 caracteres.'),
    });

    try {
      await schema.validate(formData, {
        abortEarly: false,
      });

      await api.put(`/crm/customers/${customer_id}`, {
        ...formData,
        name: customer.name,
        telephone: customer.telephone,
        is_read: userData?.is_read,
        fixed: userData?.fixed,
        facebook: formatFacebookUsername(formData?.facebook),
        instagram: formatInstagramUsername(formData?.instagram),
      });
      toast.success('Dados atualizados!');
      history.push(`/carteira-de-clientes/${customer_id}`);
    } catch (err) {
      console.error(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content crm-edit-customer">
      <Container className="container new-layout wide px-2">
        <PageHeader
          responsivePosition="start"
          desktopPosition="start"
          backTo={() => history.push(`/carteira-de-clientes/${customer_id}`)}
        />
        <Form ref={formRef} className="mt-3" onSubmit={submitHandler}>
          <Col md={6} className="px-0 pr-lg-2 mx-auto">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between mb-3">
                  <h2>Dados do contato</h2>
                  <IconButton
                    icon="las la-trash font-size-30"
                    color="red"
                    onClick={() => setShowDeleteConfirmationModal(true)}
                  />
                </div>
                <FormGroup>
                  <label>Instagram</label>
                  <Input
                    name="instagram"
                    placeholder="Instagram.com/"
                    style={{ borderRadius: '8px' }}
                    defaultValue={loadUrl(customer.instagram)}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Facebook</label>
                  <Input
                    name="facebook"
                    placeholder="Facebook.com/"
                    style={{ borderRadius: '8px' }}
                    defaultValue={loadUrl(customer.facebook)}
                  />
                </FormGroup>

                <TextAreaWithCounter
                  name="tag"
                  placeholder="Etiqueta: Defina uma breve etiqueta sobre o cliente. Essa etiqueta vai aparecer na carteira de clientes e deve ter no máximo 30 caracteres."
                  title="Etiqueta"
                  defaultValue={userData?.tag}
                  maxSize={30}
                  multiline
                />

                <FormGroup>
                  <label>Anotações</label>
                  <Input
                    name="seller_notes"
                    placeholder="Anotações: Escreva aqui anotações úteis sobre o cliente, essas anotações só aparecerão no perfil do cliente."
                    multiline
                    style={{ maxHeight: 'none', borderRadius: '8px' }}
                    defaultValue={userData?.seller_notes}
                  />
                </FormGroup>
              </CardBody>
            </Card>
            <Button
              color="primary"
              className="new-layout-btn main mt-2"
              size="lg"
              type="submit"
              disabled={loading}
            >
              Salvar
            </Button>
          </Col>
        </Form>
      </Container>

      <Modal isOpen={showDeleteConfirmationModal}>
        <ModalHeader>Excluir Cliente</ModalHeader>
        <ModalBody>
          <Row className="w-100 mx-0 mb-3">
            <h5>
              O contato do cliente <b>{customer.name}</b> será excluído.
              <br />
              Tem certeza que deseja excluir esse cliente?
            </h5>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="new-layout-btn main"
            style={{
              color: 'var(--primary)',
              border: '1px solid var(--primary)',
              backgroundColor: 'transparent',
            }}
            onClick={() => setShowDeleteConfirmationModal(false)}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            className="new-layout-btn main"
            onClick={deleteCustomer}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditCustomerForm;
