import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Reactotron Development
import './config/ReactotronConfig';
import './config/PusherConfig';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import App from './App';

// Redux storage
import { store, persistor } from './store';

// Import React-toastify css
import 'react-toastify/dist/ReactToastify.css';

const rootNode = document.getElementById('root');

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, rootNode);
