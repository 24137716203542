export const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Negrito', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Itálico', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Título Grande', style: 'header-one' },
    { label: 'Título Médio', style: 'header-two' },
    { label: 'Título Pequeno', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'Lista', style: 'unordered-list-item' },
    { label: 'Lista Ordenada', style: 'ordered-list-item' },
    { label: 'Imagem', style: 'image' },
  ],
};
