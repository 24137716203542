import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from 'reactstrap';
// import InputSelect from '~/components/Form/InputSelect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  setChosenRegion,
  setShowRegionsModal,
  setShowConfirmRegionModal,
  setStoreRegions,
  submitRegion,
} from '~/store/modules/cart/actions';
import { getRegularString } from '~/util/getRegularString';
import Loading from '../Loading';

export default function RegionsModal() {
  const location = useLocation();
  const dispatch = useDispatch();

  const isShared = useMemo(
    () => location.pathname.includes('meu-carrinho'),
    [location]
  );

  // const deliveryAddress = useSelector(
  //   ({ cart: Cart }) => Cart.cart.delivery_address
  // );

  const showRegionsModal = useSelector(({ cart }) => cart.showRegionsModal);
  const showConfirmRegionModal = useSelector(
    ({ cart }) => cart.showConfirmRegionModal
  );
  const loadingRegions = useSelector(({ cart }) => cart.loadingRegions);
  const storeRegionsBackup = useSelector(({ cart }) => cart.storeRegions);
  const chosenRegion = useSelector(({ cart }) => cart.chosenRegion);

  const [storeRegionsPrintable, setStoreRegionsPrintable] = useState(null);

  const handleSelectRegion = region => {
    dispatch(setChosenRegion(region));
  };

  const filterRegions = filter => {
    if (storeRegionsBackup?.length && filter.length > 2) {
      const draft = storeRegionsBackup.filter(region => {
        const regFilter = getRegularString(filter);
        const regRegionName = getRegularString(region.name);
        return regRegionName.includes(regFilter);
      });

      setStoreRegionsPrintable(draft);
    } else if (storeRegionsPrintable?.length !== storeRegionsBackup?.length) {
      setStoreRegionsPrintable(storeRegionsBackup);
    }
  };

  useEffect(() => {
    if (storeRegionsBackup) {
      setStoreRegionsPrintable(storeRegionsBackup);
    }
  }, [storeRegionsBackup]);

  return (
    <>
      <Modal className="choose-regions-modal" isOpen={showRegionsModal}>
        <ModalHeader
          toggle={() => {
            dispatch(setShowRegionsModal(false));
            dispatch(setStoreRegions(null));
            dispatch(setChosenRegion(null));
          }}
        >
          Ops, endereço não encontrado!
        </ModalHeader>
        <ModalBody className={`regions-modal ${loadingRegions && 'loading'}`}>
          {loadingRegions ? (
            <Loading />
          ) : (
            <>
              <span className="mb-2">Selecione uma região abaixo</span>
              <div className="search-box mt-2 mb-2">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border-0 shadow-sm"
                    placeholder="Digite o nome de uma região..."
                    onChange={e => filterRegions(e.target.value)}
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>

              <PerfectScrollbar className="mt-2 mb-2">
                <>
                  {storeRegionsPrintable?.map(region => {
                    // const isSelected = region.id === chosenRegion?.id;
                    return (
                      <div
                        className="region"
                        onClick={() => {
                          dispatch(setShowRegionsModal(false));
                          handleSelectRegion(region);
                          dispatch(setShowConfirmRegionModal(true));
                        }}
                        role="button"
                        tabIndex={0}
                        key={region.id}
                      >
                        <div className="overflow-hidden">
                          <h5 className="text-truncate font-size-14 mb-1">
                            {region.name}
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </>
              </PerfectScrollbar>
            </>
          )}
        </ModalBody>
      </Modal>
      <Modal className="confirm-region-modal" isOpen={showConfirmRegionModal}>
        <ModalHeader
          toggle={() => {
            dispatch(setShowConfirmRegionModal(false));
            dispatch(setShowRegionsModal(false));
            dispatch(setStoreRegions(null));
            dispatch(setChosenRegion(null));
          }}
        >
          Confirmação
        </ModalHeader>
        <ModalBody>
          <p>Tem certeza que deseja escolher a regiao abaixo?</p>
          <h5>{chosenRegion?.name}</h5>
        </ModalBody>

        <ModalFooter>
          <Button
            className="new-layout-btn main float-left mb-2 mb-lg-1"
            color="secondary"
            onClick={() => {
              dispatch(setShowConfirmRegionModal(false));
              dispatch(setShowRegionsModal(true));
            }}
          >
            {'< Voltar'}
          </Button>

          <Button
            className="new-layout-btn main"
            color="primary"
            disabled={!chosenRegion}
            onClick={() => {
              dispatch(submitRegion(isShared));
            }}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
