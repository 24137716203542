import React, { useState } from 'react';
import Context from './index';

const SearchProvider = ({ children }) => {
  const [isSearching, setIsSearching] = useState(null);
  const [search, setSearchValue] = useState('');

  return (
    <Context.Provider
      value={{ search, setSearchValue, isSearching, setIsSearching }}
    >
      {children}
    </Context.Provider>
  );
};

export default SearchProvider;
