/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import IconButton from '~/components/IconButton';
import PixDataCard from '~/components/Pix/PixDataCard';
// import WhatsAppShare from '~/components/WhatsAppShare';
// import api from '~/services/api';
import { LG } from '~/util/breakpointWidths';
import { copyStringToClipboard } from '~/util/copyToClipboard'; // getBankDataStr
import { formatPrice, formatWhatsappPhoneNumber } from '~/util/format';
import { defineExpandedState } from '~/util/newLayoutHelpers';
// import { getRole } from '~/Util/getRole';
// import TransferManagementButtons from '../TransferManagementButtons';
import { formatDate } from '~/util/formatDate';
import useRoles from '~/hooks/useRoles';
import api from '~/services/api';
import { getRole } from '~/util/getRole';
import history from '~/services/history';

export default function PaymentSituationCard({
  payment,
  cart,
  // store,
  done,
  separated,
  setSeparated,
  checkDoneAndChange,
  shareableURL,
  // shareablePix,
}) {
  const location = useLocation();

  // const [loadingQrCode, setLoadingQrCode] = useState(false);

  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'store-manager',
      ]),
    [roles]
  );

  const { isAdmin, isStoreManager } = useRoles();

  const showReprocessBtn = useMemo(() => {
    if (
      cart?.status === 0 &&
      cart.status_text === 'Pagamento rejeitado pelo anti-fraude' &&
      cart.store.payment_gateway === 'cielo' &&
      (isAdmin || isStoreManager)
    ) {
      return true;
    }
    return false;
  }, [cart, isAdmin, isStoreManager]);

  const handleReprocessor = useCallback(async () => {
    try {
      await api.patch(`${role}/carts/${cart.id}/payment/force`);
      history.push('/');
      toast.success('Pagamento reprocessado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }, [cart, role]);

  const handleChangeback = useCallback(async () => {
    try {
      await api.patch(`${role}/carts/${cart.id}/payment/cancel`);
      history.push('/');
      toast.success('Pagamento estornado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }, [cart, role]);

  const showBankData = useMemo(() => {
    if (cart.payment_type === 102) {
      return true;
    }
    return false;
  }, [cart]);

  const showPixData = useMemo(
    () =>
      (cart.payment_type === 105 ||
        cart.payment_type === 106 ||
        cart.payment_type === 110 ||
        cart.payment_type === 112 ||
        cart.payment_type === 114) &&
      cart?.status >= 1 &&
      cart?.payment_process?.qrcode,
    [cart]
  );

  const showShareableLink = useMemo(
    () =>
      (cart?.status < 2 ||
        (cart.is_suitcase_delivery &&
          (cart?.status === 8 || cart?.status === 4))) &&
      shareableURL &&
      cart.is_shareable,
    [cart, shareableURL]
  );

  // const showShareablePix = useMemo(
  //   () =>
  //     cart?.status !== 6 &&
  //     shareablePix &&
  //     cart.is_shareable &&
  //     (cart.payment_type === 105 || cart.payment_type === 106) &&
  //     cart.payment_process,
  //   [cart, shareablePix]
  // );

  // const isCaixa = useMemo(() => {
  //   if (store?.bank_data) {
  //     if (store.bank_data.institution.includes('CAIXA')) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [store]);

  const showInfos = useMemo(() => {
    if (
      !(
        cart?.status > 0 &&
        (cart.payment_process || cart.payment_link || cart.is_payment_checkout)
      ) &&
      !cart.is_shareable
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const isMultipleMethods = useMemo(() => {
    if (cart.payment_type > 300) {
      return true;
    }
    return false;
  }, [cart]);

  const hasCieloLink = useMemo(() => {
    if (
      payment &&
      cart &&
      (cart.payment_type === 101 ||
        cart.payment_type === 301 ||
        cart.payment_type === 302 ||
        cart.payment_type === 303)
    )
      return true;
    return false;
  }, [payment, cart]);

  const hasPagarmeLink = useMemo(() => {
    if (cart && cart.payment_type === 107) return true;
    return false;
  }, [cart]);

  const hasChange = useMemo(() => {
    if (
      cart.payment_type === 203 ||
      cart.payment_type === 303 ||
      cart.payment_type === 305
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const paymentFinished = useMemo(() => {
    if (cart.payment_type === 401) {
      return true;
    }
    return false;
  }, [cart]);

  const change = useMemo(() => {
    if (hasChange) {
      if (
        Number(payment?.second_payment?.change || payment?.change) ===
        Number(payment?.second_payment?.amount || payment?.amount)
      ) {
        return 'Sem troco';
      }
      return `${formatPrice(
        payment?.second_payment?.change || payment?.change
      )}`;
    }
    return null;
  }, [payment, hasChange]);

  const confirmed = useMemo(() => {
    if (done && separated) {
      return true;
    }
    if (cart?.status === 3 && cart.delivery_method === 'PROPRIA') {
      return true;
    }
    return false;
  }, [done, separated, cart]);

  const showPaymentProcessInfo = useMemo(() => {
    if (
      (cart.is_shareable || cart.is_payment_checkout) &&
      cart.payment_process &&
      (cart.payment_type === 103 ||
        cart.payment_type === 108 ||
        cart.payment_type === 109 ||
        cart.payment_type === 111 ||
        cart.payment_type === 113)
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const showConfirmationButtons = useMemo(() => {
    if (
      !location.pathname.includes('venda') &&
      cart?.status < 3 &&
      cart.payment_type !== 101 &&
      cart.payment_type !== 102
    ) {
      return true;
    }
    return false;
  }, [location.pathname, cart]);

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0 mb-0">Pagamento</div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          {paymentFinished && (
            <Row className="w-100 mx-0 my-3">
              <Col className="font-size-18 font-weight-600 text-black px-0">
                Pago antecipadamente
              </Col>
            </Row>
          )}
          {showInfos ? (
            <Row className="w-100 mx-0 my-3">
              <Col className="font-size-18 font-weight-600 text-black px-0">
                Informações não disponíveis
              </Col>
            </Row>
          ) : (
            <>
              {!paymentFinished && (
                <>
                  <Row className="w-100 mx-0 my-3">
                    <Col className="font-size-18 font-weight-600 text-black px-0">
                      {`Pagamento ${
                        cart?.payment_way?.not_detailed !== 'Dinheiro'
                          ? 'via'
                          : 'em'
                      } ${cart?.payment_way?.not_detailed}`}
                    </Col>
                  </Row>
                  {!(
                    cart.payment_type === 102 || cart.payment_type === 203
                  ) && (
                    <Row className="w-100 mx-0 mb-3 flex-column">
                      <Col className="font-size-14 font-weight-600 text-gray-700 px-0">
                        {cart?.payment_way?.detailed}
                      </Col>
                    </Row>
                  )}
                  {showPixData && (
                    <>
                      <PixDataCard qrCode={cart.payment_process.qrcode} />
                      <Row className="w-100 mx-0 mb-2 mt-4">
                        <Col className="px-0 font-size-14 font-weight-600 text-gray-600 text-center">
                          {cart.payment_process.expiration_date
                            ? `Data de expiração: ${formatDate(
                                cart.payment_process.expiration_date
                              )}`
                            : 'Sem data de expiração'}
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0 mb-2 mt-4">
                        <Col className="px-0 font-size-14 font-weight-600 text-gray-600">
                          Identificador da transação
                        </Col>
                      </Row>

                      <Row className="w-100 mx-0 mb-4 d-flex flex-row align-items-center justify-content-between">
                        {/* <div className={`${labelStyle} d-inline mr-1`}>QR Code:</div> */}
                        <div
                          style={{ maxWidth: '100% !important' }}
                          className="d-inline text-truncate font-size-14 font-weight-400 text-gray-700"
                        >
                          {cart.payment_process.transaction_id}
                        </div>
                        {/* <Button
                      className="new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                      color="primary"
                      onClick={() =>
                        copyStringToClipboard(
                          cart.payment_process.transaction_id,
                          'Identificador da transação copiado'
                        )
                      }
                    >
                      <i className="las la-copy mr-2" />
                      Copiar
                    </Button> */}
                      </Row>
                    </>
                  )}
                  {showShareableLink && (
                    <>
                      <Row className="w-100 mx-0 mb-4">
                        <Col className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            Link de Compartilhamento
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            <Row className="w-100 mx-0 d-flex flex-row align-items-center">
                              <a className="d-inline mr-2" href={shareableURL}>
                                {shareableURL}
                              </a>
                              <Button
                                className="mt-1 new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                                color="primary"
                                onClick={() =>
                                  copyStringToClipboard(
                                    shareableURL,
                                    'Link copiado!'
                                  )
                                }
                              >
                                <i className="las la-copy mr-2" />
                                Copiar
                              </Button>
                              <a
                                className="ml-1 mt-1 px-2 border border-primary new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                                target="_blank"
                                rel="noreferrer"
                                href={`
                                  https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                    cart?.client?.telephone
                                  )}&text=Segue link para pagamento da sua compra: ${shareableURL}
                                `}
                              >
                                <i className="las la-share mr-2" />
                                Compartilhar
                              </a>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  {/* {showShareablePix && (
                    <>
                      <Row className="w-100 mx-0 mb-4">
                        <Col className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            Link Pix
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            <Row className="w-100 mx-0 d-flex flex-row align-items-center justify-content-between">
                              <a className="d-inline mr-2" href={shareablePix}>
                                {shareablePix}
                              </a>
                              <Button
                                className="new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                                color="primary"
                                onClick={() =>
                                  copyStringToClipboard(
                                    shareablePix,
                                    'Link copiado!'
                                  )
                                }
                              >
                                <i className="las la-copy mr-2" />
                                Copiar
                              </Button>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )} */}

                  {showPaymentProcessInfo && (
                    <>
                      <Row className="w-100 mx-0 mb-4">
                        <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            Código de Autorização
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            {cart?.payment_process?.authorization_code || '-'}
                          </div>
                        </Col>
                        <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            NSU
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            {cart?.payment_process?.nsu || '-'}
                          </div>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0 mb-4">
                        <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            Nome
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            {cart?.payment_process?.card_holder_name || '-'}
                          </div>
                        </Col>
                        <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                          <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                            Últimos digitos
                          </div>
                          <div className="font-size-14 font-weight-400 text-black d-block">
                            {cart?.payment_process?.card_last_digits || '-'}
                          </div>
                        </Col>
                      </Row>
                      {/* <Row className="w-100 mx-0 mb-4">
                    <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                        TID
                      </div>
                      <div className="font-size-14 font-weight-400 text-black d-block">
                        {cart?.payment_process?.transaction_id ||
                          'Não informado'}
                      </div>
                    </Col>
                  </Row> */}
                    </>
                  )}

                  {cart?.status !== 6 && hasPagarmeLink && (
                    <Row className="w-100 mx-0 mb-2">
                      <Col className="px-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Link de pagamento
                        </div>
                        <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-700">
                          <a
                            href={cart.payment_link.url}
                            target="noblank"
                            name="link_pagarme"
                          >
                            Clique Aqui
                          </a>
                          <IconButton
                            id="copy-url-btn"
                            icon="las la-copy"
                            color="info"
                            tip="Copiar"
                            containerClass="h-100 ml-2 d-flex flex-column justify-content-end d-inline"
                            forceTip
                            click={() =>
                              copyStringToClipboard(
                                cart.payment_link.url,
                                'Link copiado!'
                              )
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {/* {cart?.status !== 6 && hasMercadopagoLink && (
                    <Row className="w-100 mx-0 mb-2">
                      <Col className="px-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Link de pagamento
                        </div>
                        <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-700">
                          <a
                            href={cart.payment_link.url}
                            target="noblank"
                            name="link_mercadopago"
                          >
                            Clique Aqui
                          </a>
                          <IconButton
                            id="copy-url-btn"
                            icon="las la-copy"
                            color="info"
                            tip="Copiar"
                            containerClass="h-100 ml-2 d-flex flex-column justify-content-end d-inline"
                            forceTip
                            click={() =>
                              copyStringToClipboard(
                                cart.payment_link.url,
                                'Link copiado!'
                              )
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  )}

                  {cart?.status !== 6 && hasRedeLink && (
                    <Row className="w-100 mx-0 mb-2">
                      <Col className="px-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Link de pagamento
                        </div>
                        <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-700">
                          <a
                            href={cart.payment_link.url}
                            target="noblank"
                            name="link_rede"
                          >
                            Clique Aqui
                          </a>
                          <IconButton
                            id="copy-url-btn"
                            icon="las la-copy"
                            color="info"
                            tip="Copiar"
                            containerClass="h-100 ml-2 d-flex flex-column justify-content-end d-inline"
                            forceTip
                            click={() =>
                              copyStringToClipboard(
                                cart.payment_link.url,
                                'Link copiado!'
                              )
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  )} */}

                  {payment && cart?.status !== 6 && hasCieloLink && (
                    <>
                      {payment.authorization_code &&
                        cart?.status > 1 &&
                        cart?.status <= 4 && (
                          <Row className="w-100 mx-0 mb-2">
                            <Col className="px-0">
                              <div className="font-size-14 font-weight-600 text-gray-600">
                                Código de autorização
                              </div>
                              <div className="font-size-14 font-weight-400 text-gray-700">
                                {payment.authorization_code}
                              </div>
                            </Col>
                          </Row>
                        )}

                      <Row className="w-100 mx-0 mb-2">
                        <Col className="px-0">
                          <div className="font-size-14 font-weight-600 text-gray-600">
                            Link de pagamento
                          </div>
                          <Row className="w-100 mx-0 font-size-14 font-weight-400 text-gray-700">
                            <a
                              href={
                                isMultipleMethods
                                  ? payment.first_payment.shortUrl
                                  : payment.shortUrl
                              }
                              target="noblank"
                              name="link_cielo"
                            >
                              Clique Aqui
                            </a>
                            <IconButton
                              id="copy-url-btn"
                              icon="las la-copy"
                              color="info"
                              tip="Copiar"
                              containerClass="h-100 ml-2 d-flex flex-column justify-content-end d-inline"
                              forceTip
                              click={() =>
                                copyStringToClipboard(
                                  isMultipleMethods
                                    ? payment.first_payment.shortUrl
                                    : payment.shortUrl,
                                  'Link copiado!'
                                )
                              }
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              {!showBankData && !showPixData && !hasPagarmeLink && (
                <Row className="w-100 mx-0 mb-2">
                  {showPaymentProcessInfo && (
                    <Col xs={12} lg={6} className="px-0 mb-2 mb-lg-0">
                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                        Bandeira
                      </div>
                      <div className="font-size-14 font-weight-400 text-black d-block">
                        {cart?.payment_process?.card_brand || '-'}
                      </div>
                    </Col>
                  )}
                  {cart.payment_type !== 105 &&
                  cart.payment_type !== 106 &&
                  cart.payment_type !== 110 &&
                  cart.payment_type !== 112 &&
                  cart.payment_type !== 114 ? (
                    <Col className="px-0" lg={6}>
                      {(cart?.max_installments ||
                        payment?.formatted_installments) &&
                        cart.payment_type !== 401 && (
                          <>
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              Parcelas
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {payment?.formatted_installments ||
                                cart?.max_installments ||
                                '-'}
                            </div>
                          </>
                        )}
                    </Col>
                  ) : (
                    <Col className="px-0" lg={12}>
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Atenção
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        Não foi possível exibir os dados do PIX, consulte um
                        Administrador.
                      </div>
                    </Col>
                  )}

                  {hasChange && (
                    <Col className="px-0 mt-2 mt-lg-0" lg={6}>
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Troco para
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {change}
                      </div>
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          {showConfirmationButtons && (
            <>
              <Row className="w-100 mx-0 mt-4">
                <Col className="px-0">
                  {confirmed ? (
                    <>
                      <i className="las la-check-circle text-success font-size-16 d-inline mr-2" />
                      <h5 className="text-success d-inline">
                        {`Máquina POS${
                          hasChange ? ' e Troco separados' : ' separada'
                        }.`}
                      </h5>
                    </>
                  ) : (
                    <>
                      <i
                        className="las la-info-circle font-size-16 d-inline mr-2"
                        id="separated-info"
                      />
                      <UncontrolledTooltip target="separated-info">
                        Ação diponível após a separação de todos os produtos do
                        pedido.
                      </UncontrolledTooltip>
                      <h5 className="text-primary d-inline">{`Separar máquina POS${
                        hasChange ? ' e Troco' : ''
                      }`}</h5>
                    </>
                  )}
                </Col>
              </Row>
              {!confirmed && (
                <Row className="w-100 mx-0 my-2">
                  <Col className="px-0">
                    <ButtonGroup size="md" className="z-index-0 d-block">
                      <Button
                        disabled={!done}
                        outline={!separated}
                        color="primary"
                        onClick={() => checkDoneAndChange()}
                      >
                        Separado
                      </Button>
                      <Button
                        disabled={!done}
                        outline={separated}
                        color="primary"
                        onClick={() => setSeparated(false)}
                      >
                        Não separado
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
            </>
          )}
          {showReprocessBtn && (
            <div className="pt-3">
              <Button
                // color="primary"
                className="new-layout-btn main mr-lg-2 mb-2 mb-lg-0"
                onClick={handleReprocessor}
                outline
              >
                <i className="fa fa-spinner pl-1 mr-2 font-size-14" />
                Aprovar
              </Button>

              <Button
                // color="primary"
                className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0"
                onClick={handleChangeback}
                outline
              >
                <i className="fas fa-undo pl-1 mr-2 font-size-14" />
                Estornar
              </Button>
            </div>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
}
