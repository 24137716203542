import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
} from 'react-pro-sidebar';
// import 'react-pro-sidebar/dist/css/styles.css';
import { NavLink, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import logoWhite from '~/assets/images/logo-white.svg';
import { toggleSidebar } from '~/store/modules/layout/actions';
import { logoutUser } from '~/store/modules/auth/login/actions';
import history from '~/services/history';

// import stdProfilePicture from '~/assets/images/user.png';
import { LG } from '~/util/breakpointWidths';
import { getRoles } from '~/util/getRoles';
import { formatWhatsappPhoneNumber } from '~/util/format';
import { generateSidebarItems } from './sidebarItems';

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector(({ user }) => user.profile);
  const showSidebar = useSelector(({ layout }) => layout.showSidebar);
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const whatsappNumber = '5511935354434';
  const suporteMessageText =
    'Olá! Estou com dúvida em relação ao Viddy, você pode me ajudar?';

  const [itemsToggle, setItemsToggle] = useState({});

  const roles = useMemo(() => getRoles(userProfile.roles), [userProfile]);

  const { isConsultant } = roles;

  const sidebarItems = useMemo(
    () => generateSidebarItems(userProfile.roles),
    [userProfile]
  );

  const toggleMenu = () => {
    dispatch(toggleSidebar());
  };

  // const onLogout = () => {
  //   dispatch(logoutUser());
  // };

  useEffect(() => {
    const rawMenuItems = document.querySelectorAll('.pro-menu-item');
    const menuItemsArr = Array.from(rawMenuItems);
    const mainContent = document.querySelector('.main-content');

    if (!showSidebar) {
      menuItemsArr.forEach(item => item.classList.add('collapsed'));
      mainContent.classList.remove('sidebar-open');
    } else {
      menuItemsArr.forEach(item => item.classList.remove('collapsed'));
      mainContent.classList.add('sidebar-open');
    }
  }, [showSidebar]);

  const hideOnClick = useMemo(() => {
    if ((windowSize < LG || isConsultant) && showSidebar) {
      return true;
    }
    return false;
  }, [showSidebar, windowSize, isConsultant]);

  return (
    <ProSidebar collapsed={!showSidebar}>
      <Menu iconShape="square">
        <SidebarHeader
          className={`pl-3 ${!showSidebar && 'collapsed'}`}
          onClick={() => {
            if (!showSidebar) {
              toggleMenu();
            }
          }}
        >
          <div
            onClick={() => {
              if (location.pathname !== '/suporte') {
                history.push('/');
              }
              if (hideOnClick) {
                toggleMenu();
              }
            }}
            role="button"
            tabIndex={0}
            className="w-100 p-0"
          >
            <img
              className={`logo ${!showSidebar && 'cursor-pointer'} h-30px`}
              src={logoWhite}
              alt="logo"
              id="Viddy-logo"
            />
          </div>
        </SidebarHeader>
        {sidebarItems.map(item => (
          <React.Fragment key={item.id}>
            <MenuItem
              className={item.classes}
              onClick={() => {
                if (showSidebar)
                  setItemsToggle(oldState => ({
                    ...oldState,
                    [item.id]: !oldState[item.id],
                  }));
              }}
              onMouseEnter={() => {
                if (!showSidebar)
                  setItemsToggle(oldState => ({
                    ...oldState,
                    [item.id]: true,
                  }));
              }}
              onMouseLeave={() => {
                if (!showSidebar)
                  setItemsToggle(oldState => ({
                    ...oldState,
                    [item.id]: false,
                  }));
              }}
            >
              {item.label === 'Suporte' && item.path === '#' ? (
                <a
                  className={`nav-link waves-effect ${
                    location.pathname.includes(
                      item.path === '#' ? item.alt_path : item.path
                    ) && 'is-current-location'
                  } ${
                    showSidebar
                      ? 'justify-content-start pl-2'
                      : 'justify-content-center'
                  } ${
                    !showSidebar &&
                    itemsToggle[item.id] &&
                    item.childrens?.length
                      ? 'menu-item-collapsed-hovering'
                      : ''
                  } d-flex align-items-center pl-0`}
                  href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                    whatsappNumber
                  )}&text=${suporteMessageText}`}
                  onClick={() => {
                    if (item.label === 'Sair') {
                      dispatch(logoutUser());
                    }
                    if (!item.childrens?.length && hideOnClick) {
                      toggleMenu();
                    }
                  }}
                  id={`label-${item.id}`}
                  style={{
                    whiteSpace: 'pre-wrap',
                    lineHeight: '1.1',
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className={item.icon} />
                  <span className={!showSidebar ? 'd-none' : ''}>
                    {item.label}
                  </span>
                  {item.childrens?.length && showSidebar && (
                    <b
                      className={`ml-auto mr-2 has-arrow ${
                        itemsToggle[item.id] ? 'is-active' : ''
                      }`}
                    />
                  )}
                </a>
              ) : (
                <NavLink
                  className={`nav-link waves-effect ${
                    location.pathname.includes(
                      item.path === '#' ? item.alt_path : item.path
                    ) &&
                    (item.exactPath
                      ? item.exactPath === location.pathname
                      : true) &&
                    'is-current-location'
                  } ${
                    showSidebar
                      ? 'justify-content-start pl-2'
                      : 'justify-content-center'
                  } ${
                    !showSidebar &&
                    itemsToggle[item.id] &&
                    item.childrens?.length
                      ? 'menu-item-collapsed-hovering'
                      : ''
                  } d-flex align-items-center pl-0`}
                  to={item.path}
                  onClick={() => {
                    if (item.label === 'Sair') dispatch(logoutUser());

                    if (!item.childrens?.length && showSidebar) toggleMenu();
                  }}
                  id={`label-${item.id}`}
                  style={{
                    whiteSpace: 'pre-wrap',
                    lineHeight: '1.1',
                  }}
                >
                  <i className={item.icon} />
                  <span className={!showSidebar ? 'd-none' : ''}>
                    {item.label}
                  </span>
                  {item.childrens?.length && showSidebar && (
                    <b
                      className={`ml-auto mr-2 has-arrow ${
                        itemsToggle[item.id] ? 'is-active' : ''
                      }`}
                    />
                  )}
                </NavLink>
              )}
              {item.childrens?.length && !showSidebar && itemsToggle[item.id] && (
                <div className="pro-menu-subitems">
                  {item.childrens?.map(children => (
                    <MenuItem key={children.id}>
                      <NavLink
                        className={`nav-link waves-effect ${
                          location.pathname.includes(children.path)
                            ? 'is-current-location'
                            : ''
                        } justify-content-start pl-2
                            d-flex align-items-center pl-0`}
                        to={children.path}
                        id={`label-${children.id}`}
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: '1.1',
                        }}
                      >
                        <i className={children.icon} />
                        <span className="mr-3">{children.label}</span>
                      </NavLink>
                    </MenuItem>
                  ))}
                </div>
              )}
            </MenuItem>

            {item.childrens?.length &&
              showSidebar &&
              itemsToggle[item.id] &&
              item.childrens?.map(children => (
                <MenuItem key={children.id}>
                  <NavLink
                    className={`nav-link waves-effect ${
                      location.pathname.includes(children.path)
                        ? 'is-current-location'
                        : ''
                    } justify-content-start pl-2
                        d-flex align-items-center pl-0 ml-3`}
                    to={children.path}
                    id={`label-${children.id}`}
                    style={{
                      whiteSpace: 'pre-wrap',
                      lineHeight: '1.1',
                    }}
                    onClick={toggleMenu}
                  >
                    <i className={children.icon} />
                    <span>{children.label}</span>
                  </NavLink>
                </MenuItem>
              ))}
          </React.Fragment>
        ))}

        <SidebarFooter
          className={`sidebar-footer ${showSidebar && 'sidebar-open'}`}
        >
          <Row className="w-100 mx-0 mb-4">
            <Col className="px-0" align={showSidebar ? 'end' : 'center'}>
              <button
                type="button"
                className="btn btn-sm font-size-16 header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i
                  className="las la-angle-double-right"
                  style={{
                    transform: showSidebar ? 'rotate(-180deg)' : '',
                    transition: 'all 300ms',
                  }}
                />
              </button>
            </Col>
          </Row>
        </SidebarFooter>
      </Menu>
    </ProSidebar>
  );
}
