/* LAYOUT */
export const CHANGE_LAYOUT = '@layout/CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = '@layout/CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = '@layout/CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_TYPE = '@layout/CHANGE_SIDEBAR_TYPE';

// topbar
export const CHANGE_TOPBAR_THEME = '@layout/CHANGE_TOPBAR_THEME';

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = '@layout/TOGGLE_RIGHT_SIDEBAR';
export const SHOW_RIGHT_SIDEBAR = '@layout/SHOW_RIGHT_SIDEBAR';
export const HIDE_RIGHT_SIDEBAR = '@layout/HIDE_RIGHT_SIDEBAR';
export const TOGGLE_SIDEBAR = '@layout/TOGGLE_SIDEBAR';
export const TOGGLE_SEARCH = '@layout/TOGGLE_SEARCH';
export const HIDE_SIDEBAR = '@layout/HIDE_SIDEBAR';
export const SET_DOM_LOADED = '@layout/SET_DOM_LOADED';
export const SET_WINDOW_SIZE = '@layout/SET_WINDOW_SIZE';
export const SET_SHOW_SEARCH = '@layout/SET_SHOW_SEARCH';

// Preloader
export const CHANGE_PRELOADER = '@layout/CHANGE_PRELOADER';
