/* eslint-disable consistent-return */
import produce from 'immer';

import { ADD_ERROR } from './actionTypes';
// import { orderBy } from 'lodash';

const initialState = {
  errors: [],
};

export default function Chat(state = initialState, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case ADD_ERROR: {
        const {
          message,
          name,
          description,
          number,
          fileName,
          lineNumber,
          columnNumber,
          stack,
          config,
          code,
        } = payload;

        const error = {
          message,
          name,
          description,
          number,
          fileName,
          lineNumber,
          columnNumber,
          stack,
          config,
          code,
        };

        if (draft.errors.length < 15) {
          draft.errors = [error, ...draft.errors];
        } else {
          const errors = draft.errors.splice(-1, 1);

          draft.errors = [error, ...errors];
        }

        break;
      }

      default:
        return state;
    }
  });
}
