import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { toast } from 'react-toastify';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';

const AddReferralCode = ({ show, setShow, getURL, setData }) => {
  const formRef = useRef(null);

  useEffect(
    () =>
      show
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open'),
    [show]
  );

  const toggle = () => setShow(!show);

  const onSubmitHandler = async code => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Digite o nome do código de indicação'),
        code: Yup.string().required('Digite o código de indicação'),
      });

      await schema.validate(code, {
        abortEarly: false,
      });

      code = { ...code };

      await api.post(getURL, code);

      const { data } = await api.get(getURL);

      setData(data);

      toast.success('Código de indicação criado com sucesso!');
      document.body.style.cssText = null;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
    toggle();
  };

  return (
    // <div className="align-self-end">
    <>
      <Button
        className="new-layout-btn main font-size-lg-14 font-size-xxl-16"
        onClick={toggle}
        color="primary"
      >
        <i className="fas fa-receipt font-size-14 align-middle mr-2" />
        Novo Código
      </Button>
      <Modal isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle}>Novo Código de Indicação</ModalHeader>
        <Form ref={formRef} onSubmit={onSubmitHandler}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Nome</Label>
                  <Input name="name" type="text" placeholder="Nome do código" />
                </FormGroup>
                <FormGroup>
                  <Label>Código</Label>
                  <Input
                    name="code"
                    type="text"
                    placeholder="Código de indicação"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="new-layout-btn main">
              Criar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddReferralCode;
