import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, CustomInput } from 'reactstrap';

import history from '~/services/history';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DeleteModal from '~/components/Modals/DeleteModal';
import Loading from '~/components/Loading';
import BatchActions from '~/components/BatchActions';
import { getRole } from '~/util/getRole';
import useBatchSelect from '~/hooks/useBatchSelect';
import useGetData from '~/hooks/useGetData';
import SupportProfileCard from './SupportProfileCard';

const Supports = () => {
  const {
    items: supports,
    pagination,
    loading,
    setLoading,
    setData,
  } = useGetData();

  const {
    allSelected: allSupports,
    setAllSelected: setAllSupports,
    selectedItems: selectedSupports,
    setSelectedItems: setSelectedSupports,
    handleSelect,
  } = useBatchSelect(supports);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isStoreManager = useMemo(
    () => roles.find(role => role.slug === 'store-manager'),
    [roles]
  );

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const canCreateSupport = useMemo(() => {
    if (!!isStoreManager && !isAdmin) {
      return false;
    }

    return true;
  }, [isAdmin, isStoreManager]);

  const [searchQuery, setSearchQuery] = useState(null);
  const [supportToBeDeleted, setSupportToBeDeleted] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [selectModeOn, setSelectModeOn] = useState(false);

  const role = getRole(roles);

  const getURL = useMemo(() => {
    return '/master/support-users/';
  }, []);

  const deleteURL = useMemo(() => {
    return `/master/support-users/${supportToBeDeleted?.id}`;
  }, [supportToBeDeleted]);

  const batchDeleteURL = useMemo(() => {
    return `/${role}/support-users/`;
  }, [role]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const isAllowedToBatch = useMemo(() => {
    if (roles.some(r => r.slug !== 'stockist')) {
      return true;
    }
    return false;
  }, [roles]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="LISTA DE SUPORTES"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-2 mb-lg-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            {canCreateSupport && (
              <Button
                className="new-layout-btn btn main btn-primary waves-effect waves-light"
                onClick={() => {
                  history.push('/suportes/registrar');
                }}
                color="primary"
              >
                <i className="fas fa-user-plus font-size-16 align-middle mr-2" />
                Novo Suporte
              </Button>
            )}
          </Col>
        </Row>

        {isAllowedToBatch && (
          <Row className="w-100 mx-0 mb-4 d-flex flex-row justify-content-end">
            <Col className="px-0 col-xxl-1" lg={2}>
              <BatchActions
                getURL={getURL}
                deleteURL={batchDeleteURL}
                selectedItems={{
                  support_users: selectedSupports.map(c => c.id),
                }}
                width={150}
                setData={setData}
                setSelectedItems={setSelectedSupports}
                itemsArray={supports}
                params={params}
                keepPage
                pagination={pagination}
                setAllItems={setAllSupports}
                allItems={allSupports}
                tip="Para executar uma ação em lote, selecione, pelo menos, dois clientes."
                id="support-users"
                objName="support-users"
                successMsg="Clientes removidos com sucesso!"
                selectModeOn={selectModeOn}
                setSelectModeOn={setSelectModeOn}
                setLoading={setLoading}
              />
            </Col>
          </Row>
        )}

        {loading ? (
          <Loading />
        ) : (
          <>
            {supports.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex px-3">
                  <Col xs={1} align="center">
                    {isAllowedToBatch && (
                      <CustomInput
                        type="checkbox"
                        id="allClients"
                        className="text-primary z-index-0"
                        checked={allSupports}
                        defaultValue={allSupports}
                        onChange={() => setAllSupports(!allSupports)}
                      />
                    )}
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">Suporte</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">E-mail</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">
                      Telefone
                    </div>
                  </Col>
                  <Col xs={2} align="center">
                    <div className="new-layout-table-column-label">Ações</div>
                  </Col>
                </Row>

                {supports?.map(support => {
                  const isSelected = selectedSupports.some(
                    c => c.id === support.id
                  );

                  return (
                    <Row className="w-100 mx-0">
                      {selectModeOn && (
                        <Col
                          xs={1}
                          className="px-0 d-flex flex-column justify-content-center"
                        >
                          <CustomInput
                            type="checkbox"
                            id={`mobile-select-${support.id}`}
                            className="text-primary z-index-0"
                            checked={isSelected}
                            defaultValue={isSelected}
                            onChange={() => handleSelect(support, isSelected)}
                          />
                        </Col>
                      )}
                      <Col className="px-0" xs={selectModeOn ? 11 : 12}>
                        <SupportProfileCard
                          key={support.id}
                          support={support}
                          isSelected={isSelected}
                          onSelect={handleSelect}
                          onDelete={incomingSupport => {
                            setSupportToBeDeleted(incomingSupport);
                            setOpenModal(true);
                          }}
                          isAllowedToBatch={isAllowedToBatch}
                        />
                      </Col>
                    </Row>
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum suporte encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <DeleteModal
        show={openModal}
        setShow={setOpenModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={supports}
        setItemToBeDeleted={setSupportToBeDeleted}
        headerText="Deseja mesmo excluir o suporte?"
        bodyText="Essa ação irá excluir o suporte"
        itemName={supportToBeDeleted?.name}
        successMsg="Suporte excluído com sucesso!"
      />
    </div>
  );
};

export default Supports;
