import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  CustomInput,
  Button,
  Label,
  ModalBody,
  Modal,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import InputSelect from '~/components/Inputs/InputSelect';
import Pagination from '~/components/Pagination';

import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import Loading from '~/components/Loading';
import history from '~/services/history';
import { fileTypes } from '~/util/fileTypes';

import api from '~/services/api';
import { updateUserRequest } from '~/store/modules/user/actions';
import DatePicker from '~/components/DatePicker';
import SubscriberProfileCard from './card';

const SubscriberSupport = () => {
  const dispatch = useDispatch();
  const formExportType = useRef(null);
  const [subscribers, setSubscribers] = useState([]);
  const [allSubscribers, setAllSubscribers] = useState(false);
  const [exportableSubscribers, setExportableSubscribers] = useState([]);
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [searchQuery, setSearchQuery] = useState(null);
  const [showExportModal, setShowExportModal] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedExport, setSelectedExport] = useState(null);

  const [optionsReport, setOptionsReport] = useState({
    export: false,
    file_type: 'xls',
  });

  const handleGenerateReport = async () => {
    try {
      if (!selectedExport) {
        formExportType.current.setFieldError(
          'export-type',
          'É obrigatório o tipo de relatório.'
        );
        return;
      }
      toast.info(
        'Estamos gerando seu relatório, e ele será enviado para o seu e-mail.'
      );

      await api.get(`/support/pages/${selectedExport.value}`, {
        params: optionsReport,
        responseType: 'blob',
      });

      setStartDate(null);
      setEndDate(null);

      setOptionsReport({
        export: true,
        file_type: 'xls',
      });

      setShowExportModal(false);
      toast.success('Relatório gerado, já deve constar no seu e-mail.');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Erro ao gerar relatório');
      }
    }
  };

  const getURL = '/support/subscribers';

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    setSubscribers(
      data.subscribers.map(s => {
        return {
          ...s,
          formatted_created_at: format(parseISO(s.created_at), 'dd/MMMM/yyyy', {
            locale: ptBR,
          }),
        };
      })
    );
    setPagination(data.meta);
  };

  const handleEnterSubscriber = async id => {
    try {
      const { data } = await api.post(`/support/subscribers/auth/${id}`);

      const oldToken = localStorage.getItem('@VIDDY:Token');
      localStorage.setItem('@VIDDY:SupportToken', oldToken);

      localStorage.setItem('@VIDDY:X-Server', id);
      localStorage.setItem('@VIDDY:Token', data?.token);
      api.defaults.headers['X-Server'] = id;
      api.defaults.headers.authorization = `Bearer ${data?.token}`;

      dispatch(updateUserRequest());

      history.push('/dashboard');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    document.title = 'Suporte Assinantes | Viddy';
    const loadSubscribers = async () => {
      const { data } = await api.get(getURL);
      setData(data);

      setLoading(false);
    };
    loadSubscribers();
  }, [getURL]);

  useEffect(() => {
    const loadExportableSubscribers = async () => {
      const { data } = await api.get(getURL, {
        params: {
          page: false,
        },
      });

      setExportableSubscribers(data.subscribers);
    };
    loadExportableSubscribers();
  }, [getURL]);

  useEffect(() => {
    if (allSubscribers) {
      setSelectedSubscribers(subscribers);
    } else {
      setSelectedSubscribers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubscribers]);

  useEffect(() => {
    if (selectedExport?.value !== 'storesNoSalesByDate') {
      setStartDate(null);
      setEndDate(null);
      setOptionsReport(state => ({
        ...state,
        dateStart: undefined,
        dateEnd: undefined,
      }));
    } else {
      const defaultEndDate = new Date();
      const defaultStartDate = new Date(
        defaultEndDate.getFullYear(),
        defaultEndDate.getMonth(),
        defaultEndDate.getDate() - 7
      );

      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
      setOptionsReport(state => ({
        ...state,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      }));
    }
  }, [selectedExport]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="LISTA DE ASSINANTES"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4 justify-content-between">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0 pl-lg-2" lg={3} align="end">
            <Button
              id="export-data-dashboard-btn"
              color="primary"
              onClick={() => setShowExportModal(true)}
              className="new-layout-btn main d-flex flex-row justify-content-center align-items-center w-100"
              style={{
                height: '40px',
              }}
            >
              <div className="ml-2">Exportar Dados</div>
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {subscribers?.length ? (
              <>
                <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                  <Col xs={1} align="center">
                    <CustomInput
                      type="checkbox"
                      id="allSubscribers"
                      className="text-primary z-index-0 d-none"
                      checked={allSubscribers}
                      defaultValue={allSubscribers}
                      onChange={() => setAllSubscribers(!allSubscribers)}
                    />
                  </Col>

                  <Col
                    xs={4}
                    align="start"
                    // style={{ paddingLeft: '1.25rem' }}
                  >
                    <div className="new-layout-table-column-label">
                      Nome Fantasia
                    </div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">CNPJ</div>
                  </Col>
                  <Col xs={3} align="start">
                    <div className="new-layout-table-column-label">
                      Cadastro
                    </div>
                  </Col>
                  {/* <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">Status</div>
                  </Col>
                  <Col xs={2} align="start">
                    <div className="new-layout-table-column-label">
                      Próximo Pagamento
                    </div>
                  </Col> */}
                  <Col xs={1} align="center">
                    <div className="new-layout-table-column-label">Ação</div>
                  </Col>
                </Row>

                {subscribers?.map(subscriber => {
                  const isSelected = selectedSubscribers.some(
                    s => s.id === subscriber.id
                  );

                  return (
                    <SubscriberProfileCard
                      key={subscriber.id}
                      subscriber={subscriber}
                      isSelected={isSelected}
                      handleEnterSubscriber={handleEnterSubscriber}
                    />
                  );
                })}

                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum assinante encontrado.</h3>
            )}
          </>
        )}
      </Container>
      <Modal isOpen={showExportModal}>
        <ModalHeader toggle={() => setShowExportModal(false)}>
          Exportar Dados
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Tipo de relatório</Label>

              <Form ref={formExportType}>
                <InputSelect
                  name="export-type"
                  options={[
                    { value: 'storesNoSalesByDate', label: 'Nenhuma venda' },
                    { value: 'cartsSalesByDate', label: 'Vendas' },
                    { value: 'chargesByDate', label: 'Pagamentos' },
                    { value: 'deliveriesByDate', label: 'Logística' },
                    { value: 'users', label: 'Usuários' },
                    { value: 'users-clients', label: 'Clientes Fidelizados' },
                    { value: 'storesByDate', label: 'Lojas' },
                    { value: 'management-report', label: 'Gerencial' },
                  ]}
                  onChange={option => {
                    setSelectedExport(option);
                  }}
                  isClearable
                  value={selectedExport}
                  placeholder="Selecione o tipo desejado"
                  singlePath
                />
              </Form>
            </Col>
          </Row>
          <Row className="w-100 mx-0 my-2">
            <Col className="px-0">
              <Label>Deseja filtrar por um assinante específico?</Label>
              <ReactSelect
                name="store-manager-report"
                id="store-manager-report"
                options={exportableSubscribers.map(subscriber => ({
                  label: subscriber.fantasy_name,
                  value: subscriber.id,
                }))}
                classNamePrefix="select"
                placeholder="Todos os assinantes"
                isClearable
                value={exportableSubscribers
                  .map(subscriber => ({
                    label: subscriber.fantasy_name,
                    value: subscriber.id,
                  }))
                  .find(s => s.value === optionsReport.subscriberId)}
                onChange={option => {
                  setOptionsReport({
                    ...optionsReport,
                    subscriberId: option?.value,
                  });
                }}
                noOptionsMessage={() =>
                  'Não há assinantes cadastrados para o seu usuário'
                }
              />
            </Col>
          </Row>
          {selectedExport?.value !== 'users' && (
            <Row className="w-100 mx-0 my-2">
              <Col className="px-0">
                <Label>
                  Deseja filtrar por um intervalo de datas específico?
                </Label>
                <Row className="w-100 mx-0 mb-2 mb-lg-2 d-flex flex-row align-items-center">
                  <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                    <DatePicker
                      value={startDate}
                      onChange={selectedDate => {
                        const date = format(selectedDate, 'yyyy-MM-dd', {
                          locale: ptBR,
                        });
                        setOptionsReport(state => ({
                          ...state,
                          dateStart: date,
                        }));
                        setStartDate(selectedDate);
                      }}
                      mask="__/__/___"
                      placeholder="Dia Inicial"
                    />
                  </Col>
                  <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                    <DatePicker
                      value={endDate}
                      onChange={selectedDate => {
                        const date = format(selectedDate, 'yyyy-MM-dd', {
                          locale: ptBR,
                        });
                        setOptionsReport(state => ({
                          ...state,
                          dateFinish: date,
                        }));
                        setEndDate(selectedDate);
                      }}
                      mask="__/__/___"
                      placeholder="Dia Final"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="w-100 mx-0 mb-4 mt-2">
            <Col className="px-0">
              <Label>Qual formato de arquivo que deseja no relatório?</Label>
              <ReactSelect
                name="filetype-financier-report"
                id="filetype-financier-report"
                options={fileTypes}
                classNamePrefix="select"
                placeholder="Formato de arquivo"
                isClearable
                value={fileTypes.find(
                  p => p?.value === optionsReport?.file_type
                )}
                onChange={option => {
                  setOptionsReport(state => ({
                    ...state,
                    file_type: option?.value,
                  }));
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            color="primary"
            className="new-layout-btn main"
            onClick={handleGenerateReport}
            // disabled={!selectedExport}
          >
            Exportar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SubscriberSupport;
