import {
  ADD_SALE,
  REMOVE_SALE,
  REMOVE_SALE_SUCCESS,
  UPDATE_SALE_LIST,
  UPDATE_SALE_LIST_SUCCESS,
  LOCAL_SEARCH,
  SEARCH_SALE,
  SEARCH_SALE_SUCCESS,
  API_ERROR,
  SET_LOADING,
  SET_AS_RESCHEDULED,
} from './actionTypes';

export const apiSalesError = errorMessage => ({
  type: API_ERROR,
  payload: errorMessage,
});

export const setLoading = loading => ({
  type: SET_LOADING,
  payload: { loading },
});

export const addSale = newSales => ({
  type: ADD_SALE,
  payload: newSales,
});

export const removeSale = id => ({
  type: REMOVE_SALE,
  payload: id,
});

export const removeSaleSuccess = id => ({
  type: REMOVE_SALE_SUCCESS,
  payload: id,
});

export const updateSaleList = page => ({
  type: UPDATE_SALE_LIST,
  payload: { page },
});

export const updateSaleListSuccess = ({ sales, paginationMeta }) => ({
  type: UPDATE_SALE_LIST_SUCCESS,
  payload: { sales, paginationMeta },
});

export const removeProductSuccess = id => ({
  type: REMOVE_SALE_SUCCESS,
  payload: id,
});

export const localSearch = search => ({
  type: LOCAL_SEARCH,
  payload: search,
});

export const searchSale = search => ({
  type: SEARCH_SALE,
  payload: search,
});

export const searchSaleSuccess = sale => {
  return {
    type: SEARCH_SALE_SUCCESS,
    payload: sale,
  };
};

export const setAsRescheduled = () => ({
  type: SET_AS_RESCHEDULED,
});
