import { validateCPF } from 'validations-br';
import valid from 'card-validator';
import * as Yup from 'yup';
import { normalizedText } from '~/util/format';
import { parse, isAfter, isDate } from 'date-fns';

export const emailSchema = Yup.string()
  .required('Insira o seu e-mail')
  .email('E-mail no formato inválido')
  .test(
    'match',
    'E-mail no formato inválido, somente letras, números e pontos são permitidos',
    email => email === normalizedText(email)
  );

export const holderSchema = Yup.string()
  .required('Insira o nome impresso no cartão')
  .test(
    'match',
    'Insira um nome impresso válido, não é permitido acentuação ou caracteres especiais',
    name => name === normalizedText(name)
  );

export const cpfSchema = Yup.string()
  .min(14, 'Insira todos os dígitos do CPF')
  .test('cpf', 'CPF inválido', value => validateCPF(value));

export const birthDateSchema = Yup.string()
  .required('Insira a data de nascimento')
  .test('is-date-valid', 'Data inválida', value => {
    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
    return isDate(parsedDate);
  })
  .test(
    'is-not-future-date',
    'Não é possível incluir uma data futura',
    value => {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
      return !isAfter(parsedDate, new Date());
    }
  );

const schema = maskTelephoneLength => {
  return Yup.object().shape({
    holder: holderSchema,
    card_number: Yup.string()
      .required('Insira o número do cartão')
      .min(16, 'Insira todos os dígitos do cartão')
      .test('card_number', 'Insira um número válido', value => {
        return valid.number(value).isValid;
      }),
    expiration_date: Yup.date()
      .required('Insira a validade do cartão')
      .typeError('Insira uma data válida')
      .min(new Date(), 'Insira uma data válida'),
    security_code: Yup.string()
      .required('Insira o CVV do cartão')
      .min(3, 'Dígitos do CVV'),
    installments: Yup.string().required('Insira o número de parcelas'),
    name: Yup.string().required('Insira seu nome'),
    telephone: Yup.string()
      .required('Insira seu telefone')
      .min(maskTelephoneLength, 'Insira todos os dígitos'),
    email: emailSchema,
    cpf: cpfSchema,
    birth_date: birthDateSchema,
    address: Yup.object().shape({
      cep: Yup.string()
        .required('Insira o CEP corretamente')
        .min(9, 'Insira todos os dígitos do CEP'),
      street_name: Yup.string().required('Insira o nome da rua'),
      number: Yup.string()
        // .matches(/^(\d+|sn)$/i, 'Informe apenas números')
        .max(10, 'Número deve ter no máximo 10 caracteres')
        .required('Insira o número'),
      neighborhood: Yup.string().required('Insira o nome do bairro'),
      state: Yup.string().required('Selecione o estado'),
      city: Yup.string().required('Selecione a cidade'),
      complement: Yup.string()
        .notRequired()
        .max(128, 'Máximo de 128 caracteres'),
      additional_information: Yup.string()
        .notRequired()
        .max(
          128,
          'As informações adicionais devem ter no máximo 128 caracteres'
        ),
    }),
  });
};

export default schema;
