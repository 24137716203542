import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import api from '~/services/api';

export default function TextFilter({
  url,
  query,
  setQuery,
  setData,
  setLoading,
  params,
  keepPageOnFilter,
  setReqRunning,
  ...rest
}) {
  const onSearch = async searchQuery => {
    if (searchQuery.length === 0 || searchQuery.length >= 3) {
      if (setReqRunning) {
        setReqRunning(true);
      }

      setLoading(true);

      const { data } = await api.get(url, {
        params: {
          ...params,
          page: keepPageOnFilter ? params.page : null,
          // query,
        },
      });

      setData(data);
      setLoading(false);

      if (setReqRunning) {
        setReqRunning(false);
      }
    }
  };

  useEffect(() => {
    if (query !== null && query !== undefined) {
      onSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Input
      type="text"
      className="new-layout-text-filter"
      onChange={e => {
        setQuery(e.target.value);
      }}
      value={query || ''}
      {...rest}
    />
  );
}
