import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import useRoles from '~/hooks/useRoles';
import { LG } from '~/util/breakpointWidths';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import FLEETS from '~/util/deliveryFleets';
import {
  CANCELLED,
  DELIVERED,
  GOING_TO_STORE,
  RETURNING_TO_STORE,
  SCHEDULED,
  SEARCHING,
  ARRIVED_AT_DESTINATION,
  ARRIVED_AT_STORE,
  ON_DELIVERY,
} from '~/util/deliveryStatuses';
import { capitalize, formatWhatsappPhoneNumber } from '~/util/format';
import { defineExpandedState } from '~/util/newLayoutHelpers';

export default function DeliveryInfoCard({
  delivery,
  cart,
  store,
  // handleResetDelivery,
  toggleCancelDelivery,
  RetryDelivery,
}) {
  const location = useLocation();
  // const rescheduled = useSelector(({ sales }) => sales.rescheduled);

  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const deliveryFleet = useMemo(
    () => FLEETS.find(fleet => fleet.tag === cart?.delivery_method),
    [cart]
  );

  const { isConsultant, isAdmin, isStoreManager } = useRoles();

  // const [showChangeDeliveryAddressModal, setShowChangeDeliveryAddressModal] =
  //   useState(false);

  // const [showChangeDeliveryFleetModal, setShowChangeDeliveryFleetModal] =
  //   useState(false);

  const showDeliveryRegionInfo = useMemo(() => {
    if (
      cart.delivery_method === 'BEE' &&
      cart.delivery_address.delivery_additional_data
    ) {
      return true;
    }
    return false;
  }, [cart]);

  const showScheduledInfo = useMemo(() => {
    if (
      delivery?.status === SCHEDULED ||
      (delivery?.schedule_to && !cart.delivery)
    ) {
      return true;
    }
    return false;
  }, [cart, delivery]);

  const scheduledDeliveryBeforeDelivery = useMemo(() => {
    if (delivery?.schedule_to && !cart.delivery) {
      return true;
    }
    return false;
  }, [cart, delivery]);

  const showExtraRow = useMemo(() => {
    if (showDeliveryRegionInfo || showScheduledInfo) {
      return true;
    }
    return false;
  }, [showDeliveryRegionInfo, showScheduledInfo]);

  const isReschedulable = useMemo(() => {
    if (
      cart?.status >= 3 &&
      cart.delivery &&
      ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
        cart.delivery_method
      ) && // (cart.delivery_method === 'BEE' || cart.delivery_method === 'PROPRIA')
      delivery?.status !== SEARCHING &&
      delivery?.status !== DELIVERED &&
      delivery?.status !== RETURNING_TO_STORE &&
      delivery?.status !== ARRIVED_AT_DESTINATION
    ) {
      return true;
    }
    return false;
  }, [cart, delivery]);

  const isCancelable = useMemo(() => {
    if (
      cart?.status >= 3 &&
      cart.delivery &&
      ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
        cart.delivery_method
      ) &&
      (delivery?.status === SEARCHING ||
        delivery?.status === GOING_TO_STORE ||
        delivery?.status === ARRIVED_AT_STORE)
    ) {
      return true;
    }
    return false;
  }, [cart, delivery]);

  /* const isRestartable = useMemo(() => {
    if (
      cart?.status >= 3 &&
      cart.delivery &&
      cart.delivery_method === 'BEE' &&
      delivery?.status === GOING_TO_STORE
    ) {
      return true;
    }
    return false;
  }, [cart, delivery]);
  */

  // const addressIsChangeable = useMemo(() => {
  //   if (
  //     (cart?.status === 3 &&
  //       cart?.status < 4 &&
  //       cart.delivery &&
  //       cart.delivery_method === 'BEE' &&
  //       (delivery?.status === SEARCHING ||
  //         delivery?.status === GOING_TO_STORE ||
  //         delivery?.status === CANCELLED ||
  //         delivery?.status === ARRIVED_AT_STORE)) ||
  //     (cart.is_for_delivery && !cart.delivery && cart?.status < 3)
  //   ) {
  //     return true;
  //   }

  //   return false;
  // }, [cart, delivery]);

  // const fleetIsChangeable = useMemo(() => {
  //   if (
  //     cart?.status < 4 &&
  //     delivery?.status !== SEARCHING &&
  //     delivery?.status !== GOING_TO_STORE &&
  //     delivery?.status !== ARRIVED_AT_STORE
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [delivery, cart]);

  const defineDeliveryStatusColor = status => {
    if (status === SEARCHING) {
      return 'text-warning';
    }
    if (status === DELIVERED) {
      return 'text-success';
    }
    if (status === CANCELLED) {
      return 'text-danger';
    }
    return 'text-primary';
  };

  // const toggleChangeDeliveryAddressModal = () => {
  //   setShowChangeDeliveryAddressModal(!showChangeDeliveryAddressModal);
  // };

  // const toggleChangeDeliveryFleetModal = () => {
  //   setShowChangeDeliveryFleetModal(!showChangeDeliveryFleetModal);
  // };

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0 mb-0">Entrega</div>
              <div className="px-0 mb-0">
                <IconButton
                  icon={`las la-${
                    expanded ? 'angle-down d-none d-lg-block' : 'angle-down'
                  }`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          {cart?.status === 0 && !cart.is_shareable ? (
            <Row className="w-100 mx-0 my-3">
              <Col className="font-size-18 font-weight-600 text-black px-0">
                Informações não disponíveis
              </Col>
            </Row>
          ) : (
            <>
              {cart.is_for_delivery ? (
                <>
                  <Row className="w-100 mx-0 mb-lg-4">
                    <Col lg={4} className="px-0 mb-2 mb-lg-0">
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Loja para coleta
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {cart.store.fantasy_name}
                      </div>
                    </Col>
                    <Col lg={8} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0">
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Destino
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {cart.delivery_address?.full_address || '-'}
                      </div>
                    </Col>
                  </Row>

                  <Row className="w-100 mx-0 mb-lg-4">
                    {cart.delivery_method && (
                      <Col lg={4} className="px-0 mb-2 mb-lg-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Transportadora
                        </div>
                        {capitalize(
                          deliveryFleet?.tag !== 'PROPRIA'
                            ? deliveryFleet?.label
                            : 'Alternativa'
                        )}
                      </Col>
                    )}
                    {cart.delivery_vehicle && (
                      <Col lg={8} className="pl-0 pl-lg-3 pr-0 mb-2 mb-lg-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Veículo
                        </div>
                        <div className="font-size-14 font-weight-400 text-gray-700">
                          {cart?.delivery_vehicle === 'M' ? 'Moto' : 'Carro'}
                        </div>
                      </Col>
                    )}
                  </Row>

                  {cart?.status >= 3 &&
                    cart.delivery &&
                    ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
                      cart.delivery_method
                    ) && (
                      <Row className="w-100 mx-0 mb-lg-4">
                        {cart?.delivery_method === 'UBER' ? (
                          <Col lg={4} className="px-0 mb-2 mb-lg-4">
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              Uber ID
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {delivery?.uber_uuid || '-'}
                            </div>
                          </Col>
                        ) : (
                          <Col lg={4} className="px-0 mb-2 mb-lg-4">
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              ID da entrega
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {delivery?.id_on_service || '-'}
                            </div>
                          </Col>
                        )}
                        <Col lg={8} className="px-0 mb-4 mb-lg-4 pl-lg-3">
                          <div className="font-size-14 font-weight-600 text-gray-600">
                            Situação da Entrega
                          </div>
                          <div
                            className={`font-size-18 font-weight-600 ${defineDeliveryStatusColor(
                              delivery?.status
                            )}`}
                          >
                            {delivery?.status_text}
                          </div>
                        </Col>

                        {delivery?.pickup_code && (
                          <Col xs={4} className="px-0 mb-2 mb-lg-4">
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              Cód. de Coleta
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {delivery?.pickup_code}
                            </div>
                          </Col>
                        )}
                        {delivery?.return_code && (
                          <Col xs={8} className="px-0 mb-2 mb-lg-4 pl-lg-3">
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              Cód. de Retorno
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {delivery?.return_code}
                            </div>
                          </Col>
                        )}
                        {delivery?.delivery_code && (
                          <Col xs={6} className="px-0 mb-2 mb-lg-0">
                            <div className="font-size-14 font-weight-600 text-gray-600">
                              Cód. de Entrega
                            </div>
                            <div className="font-size-14 font-weight-400 text-gray-700">
                              {delivery?.delivery_code}
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}

                  {scheduledDeliveryBeforeDelivery && (
                    <Row className="w-100 mx-0 mb-lg-4">
                      <Col className="px-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Entrega agendada para
                        </div>
                        <div className="font-size-18 font-weight-500 text-gray-700">
                          {format(
                            utcToZonedTime(
                              parseISO(cart.delivery_scheduled_to),
                              'America/Fortaleza'
                            ),
                            "dd/MMMM/yyyy 'às' HH'h'mm",
                            {
                              timeZone: 'America/Fortaleza',
                              locale: ptBR,
                            }
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}

                  {cart?.status >= 1 && (
                    <>
                      {showExtraRow && cart.delivery_method === 'BEE' && (
                        <Row className="w-100 mx-0 mb-lg-4">
                          {showDeliveryRegionInfo && (
                            <Col
                              lg={showScheduledInfo ? 4 : 12}
                              className={`px-0 ${
                                showScheduledInfo ? 'mb-2 mb-lg-0' : ''
                              }`}
                            >
                              <div className="font-size-14 font-weight-600 text-gray-600">
                                Região da Entrega
                              </div>
                              <div className="font-size-14 font-weight-400 text-gray-700 text-truncate">
                                {
                                  cart.delivery_address.delivery_additional_data
                                    .name
                                }
                              </div>
                            </Col>
                          )}

                          {showScheduledInfo && (
                            <Col
                              lg={showDeliveryRegionInfo ? 8 : 12}
                              className="px-0"
                            >
                              <div className="font-size-14 font-weight-600 text-gray-600">
                                Entrega agendada para
                              </div>
                              <div className="font-size-18 font-weight-500 text-gray-700">
                                {format(
                                  utcToZonedTime(
                                    parseISO(cart.delivery_scheduled_to),
                                    'America/Fortaleza'
                                  ),
                                  "dd/MMMM/yyyy 'às' HH'h'mm",
                                  {
                                    timeZone: 'America/Fortaleza',
                                    locale: ptBR,
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}

                      {cart.delivery &&
                        [
                          'BEE',
                          'MOTTU',
                          'UBER',
                          'CORREIOS',
                          'PROPRIA',
                        ].includes(cart.delivery_method) && (
                          <>
                            {delivery?.worker_name && (
                              <>
                                <Row className="w-100 mx-0 my-4">
                                  <Col className="font-size-16 font-weight-600 text-gray-600 px-0">
                                    DADOS DO ENTREGADOR
                                  </Col>
                                </Row>
                                <Row className="w-100 mx-0 mb-lg-4">
                                  <Col lg={4} className="px-0 mb-2 mb-lg-0">
                                    <div className="font-size-14 font-weight-600 text-gray-600">
                                      Nome
                                    </div>
                                    <div className="font-size-14 font-weight-400 text-gray-700">
                                      {delivery?.worker_name || 'Não definido'}
                                    </div>
                                  </Col>
                                  <Col
                                    lg={4}
                                    className="px-0 mb-2 mb-lg-0 pl-lg-3"
                                  >
                                    <div className="font-size-14 font-weight-600 text-gray-600">
                                      Telefone
                                    </div>
                                    <div className="font-size-14 font-weight-400 text-gray-700">
                                      {delivery?.worker_phone || 'Não definido'}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Row className="w-100 mx-0">
                              {delivery?.track_data && (
                                <Col lg={12} className="px-0 mb-2 mb-lg-0">
                                  <div className="font-size-14 font-weight-600 text-gray-600">
                                    Link de Rastreio
                                  </div>
                                  <Row className="w-100 mx-0 d-flex flex-row align-items-center">
                                    {delivery?.track_data ? (
                                      <>
                                        <a
                                          className="d-inline mr-2"
                                          href={delivery?.track_data}
                                        >
                                          {delivery?.track_data}
                                        </a>
                                        <Button
                                          className="mt-1 new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                                          color="primary"
                                          onClick={() =>
                                            copyStringToClipboard(
                                              delivery?.track_data,
                                              'Link copiado!'
                                            )
                                          }
                                        >
                                          <i className="las la-copy mr-2" />
                                          Copiar
                                        </Button>
                                        <a
                                          className="ml-md-1 mt-1 px-2 border border-primary new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            delivery?.worker_name
                                              ? `
                                              https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                                cart?.client?.telephone
                                              )}&text=O entregador ${
                                                  delivery.worker_name
                                                } aceitou sua corrida. Segue o link para rastreio ${
                                                  delivery?.track_data
                                                }
                                            `
                                              : `
                                              https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                                cart?.client?.telephone
                                              )}&text=Acabei de chamar o entregador para coletar o seu pedido, vamos aguardar alguém aceitar a corrida. Assim que tiver informações do entregador, compartilho aqui!
                                            `
                                          }
                                        >
                                          <i className="las la-share mr-2 my-auto" />
                                          Compartilhar
                                        </a>
                                      </>
                                    ) : (
                                      'Não gerado'
                                    )}
                                  </Row>
                                </Col>
                              )}
                              {delivery?.status === 'CANCELED' && (
                                <>
                                  <Col
                                    lg={12}
                                    className="px-0 mb-2 mt-2 mb-lg-0"
                                  >
                                    <div className="font-size-14 font-weight-600 text-gray-600">
                                      Motivo do cancelamento
                                    </div>
                                    <Row className="w-100 mx-0 d-flex flex-row align-items-center justify-content-between">
                                      {delivery?.cancellation_reason ? (
                                        <span>
                                          {delivery?.cancellation_reason}
                                        </span>
                                      ) : (
                                        '-'
                                      )}
                                    </Row>
                                  </Col>
                                  <Col
                                    lg={12}
                                    className="px-0 mb-2 mt-2 mb-lg-0"
                                  >
                                    <div className="font-size-14 font-weight-600 text-gray-600">
                                      Motivo da entrega de pedido cancelado
                                    </div>
                                    <Row className="w-100 mx-0 d-flex flex-row align-items-center justify-content-between">
                                      {cart?.delivery?.finalization_details ? (
                                        <span>
                                          {cart?.delivery?.finalization_details}
                                        </span>
                                      ) : (
                                        '-'
                                      )}
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </>
                        )}
                    </>
                  )}
                  {cart?.delivery_score && (
                    <Row className="w-100 mx-0 mb-lg-4">
                      <Col lg={12} className="my-2 px-0 mb-lg-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Avaliação de entrega
                        </div>
                        <div className="font-size-14 font-weight-400 text-gray-700">
                          {Array(cart?.delivery_score?.score)
                            .fill(1)
                            .map((_, i) => (
                              <FaStar
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${cart?.delivery_score?.user_name}${i}`}
                                color="#ffd600"
                                size={40}
                              />
                            ))}
                          {Array(5 - cart?.delivery_score?.score)
                            .fill(1)
                            .map((_, i) => (
                              <FaRegStar
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${cart?.delivery_score?.user_name}${i}`}
                                color="#ffd600"
                                size={40}
                              />
                            ))}
                        </div>
                      </Col>
                      <Col lg={12} className="px-0 my-2 mb-lg-0">
                        <div className="font-size-14 font-weight-600 text-gray-600">
                          Avaliador
                        </div>
                        <div className="font-size-14 font-weight-400 text-gray-700">
                          {cart?.delivery_score?.user_name}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {((location.pathname.includes('venda') &&
                    (isConsultant || isAdmin || isStoreManager)) ||
                    location.pathname.includes('pedido')) && (
                    // {(isStockist || isConsultant) && (
                    <Row className="w-100 mx-0 mt-4">
                      {/* isRestartable && (
                        <Button
                          className="new-layout-btn main mb-2 mr-lg-2"
                          color="warning"
                          onClick={() => handleResetDelivery()}
                          outline
                        >
                          {/* <i className="las la-undo-alt mr-2" /> * /}
                          Reiniciar Entrega
                        </Button>
                      ) */}
                      {isReschedulable &&
                        cart?.status < 4 &&
                        delivery?.status !== ON_DELIVERY && (
                          <>
                            <Button
                              color="info"
                              className="new-layout-btn main mb-2 mr-lg-2"
                              onClick={() => RetryDelivery()}
                              outline
                            >
                              {/* <i className="las la-calendar mr-2" /> */}
                              {delivery?.status === SCHEDULED
                                ? 'Reagendar Entrega'
                                : 'Nova tentativa de Entrega'}
                            </Button>
                          </>
                        )}
                      {isCancelable && (
                        <>
                          {!delivery?.worker_name ? (
                            <a
                              className="px-2 border border-primary new-layout-btn new-layout-btn-transparent main mb-2 mb-lg-0 mr-lg-2 d-flex align-items-center justify-content-center"
                              target="_blank"
                              rel="noreferrer"
                              href={`
                                https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  cart?.client?.telephone
                                )}&text=Acabei de chamar o entregador para coletar o seu pedido, vamos aguardar alguém aceitar a corrida. Assim que tiver informações do entregador, compartilho aqui!
                              `}
                            >
                              <i className="las la-share mr-2 my-auto" />
                              Compartilhar
                            </a>
                          ) : null}
                          <Button
                            color="danger"
                            className="new-layout-btn main mb-2 mb-lg-0 mr-lg-2"
                            onClick={() => toggleCancelDelivery()}
                            outline
                          >
                            {/* <i className="las la-ban mr-2" /> */}
                            Cancelar Entrega
                          </Button>
                        </>
                      )}
                      {/* {addressIsChangeable && (
                        <>
                          <Button
                            color="primary"
                            className="new-layout-btn main mb-2 mb-lg-0 mr-lg-2"
                            onClick={() => toggleChangeDeliveryAddressModal()}
                          >
                            {/* <i className="las la-edit mr-2" /> * /}
                            Alterar End. Entrega
                          </Button>
                          {fleetIsChangeable && (
                            <Button
                              color="primary"
                              className="new-layout-btn main mb-2 mb-lg-0 mr-lg-2"
                              onClick={() => toggleChangeDeliveryFleetModal()}
                            >
                              {/* <i className="las la-edit mr-2" /> * /}
                              Alterar Frota
                            </Button>
                          )}
                          <ChangeDeliveryFleetModal
                            show={showChangeDeliveryFleetModal}
                            toggle={toggleChangeDeliveryFleetModal}
                            stock={cart}
                            setData={setData}
                          />
                          <ChangeDeliveryAddressModal
                            show={showChangeDeliveryAddressModal}
                            toggle={toggleChangeDeliveryAddressModal}
                            stock={cart}
                          />
                        </>
                      ) */}
                    </Row>
                  )}
                </>
              ) : (
                <>
                  <Row className="w-100 mx-0 mb-4">
                    <Col lg={4} className="px-0 mb-2 mb-lg-0">
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Loja
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {cart.store.fantasy_name}
                      </div>
                    </Col>
                    <Col lg={8} className="px-0">
                      <div className="font-size-14 font-weight-600 text-gray-600">
                        Endereço
                      </div>
                      <div className="font-size-14 font-weight-400 text-gray-700">
                        {store?.address?.full_address || '-'}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
}
