import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

const InputSelect = ({
  name,
  singlePath,
  returnAll,
  customSelect,
  isCheckout = false,
  classRest,
  placeholder,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }

        if (returnAll) {
          return ref.state.value;
        }
        return singlePath ? ref.state.value : ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti, returnAll, singlePath]);

  return (
    <>
      {!customSelect ? (
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          className={`${error && 'border-danger'} ${classRest}`}
          onFocus={clearError}
          placeholder={placeholder ?? 'Selecione uma loja'}
          noOptionsMessage={() => null}
          dropdownIndicators
          components={{
            IndicatorSeparator: () => null,
          }}
          {...rest}
        />
      ) : (
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          className={`${classRest} ${error && 'border-danger'}`}
          onFocus={clearError}
          {...rest}
        />
      )}
      {error && (
        <span
          className={`${error && 'text-danger'}`}
          style={{
            position: isCheckout ? 'absolute' : 'unset',
            whiteSpace: isCheckout ? 'nowrap' : 'unset',
          }}
        >
          {error}
        </span>
      )}
    </>
  );
};

export default InputSelect;
