import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';

export default function ImportProducts() {
  const inputRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line consistent-return
  const handleUpload = async file => {
    try {
      setUploading(true);

      // Allows only csv. To add more, divide options by pipe (|)
      const allowedExtensions = /(\.csv)$/i;

      if (!allowedExtensions.exec(file.name)) {
        toast.error('O formato do arquivo deve estar em CSV.');

        setShowModal(false);
        setUploading(false);

        return false;
      }

      // const fileExtension = file.name.split('.').pop();

      // if (fileExtension !== 'csv') {
      //   toast.error('O formato do arquivo deve estar em CSV.');
      //   setShowModal(false);
      //   setUploading(false);

      //   return false;
      // }

      const formData = new FormData();
      formData.append('source', file);

      await api.post('/administrator/products/import-from-file', formData);
      toast.success('Produtos importados com sucesso!');
      history.go(0);
    } catch (err) {
      // const { message, name, description, number, stack, config, code } = error;

      // console.log(`name`, name);
      // console.log(`description`, description);
      // console.log(`number`, number);
      // console.log(`code`, code);
      // console.log(`config`, config);
      // console.log(`stack`, stack);
      // console.log(`message`, message);

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Houve um erro ao importar os produtos');
      }
    }
    setShowModal(false);
    setUploading(false);
  };

  // const handleExportProducts = async () => {
  //   try {
  //     const { data } = await api.get('administrator/products/export/xlsx');

  //     const blob = new Blob([data], {
  //       type:
  //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const tempLink = document.createElement('a');
  //     tempLink.href = url;
  //     tempLink.setAttribute('download', `Produtos.xlsx`);
  //     document.body.appendChild(tempLink);
  //     tempLink.click();
  //   } catch (err) {
  //     console.log(err);
  //     toast.error('Houve um erro ao exportar os produtos');
  //   }
  // };

  return (
    <>
      {/* <Button
        color="primary"
        className="new-layout-btn main mr-2 mb-2 mb-lg-0"
        onClick={() => handleExportProducts()}
      >
        Exportar
      </Button> */}
      <Button
        color="primary"
        className="new-layout-btn main"
        onClick={() => setShowModal(true)}
      >
        Importar
      </Button>
      <Modal isOpen={showModal}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Importador de Produtos
        </ModalHeader>
        <ModalBody>
          {uploading ? (
            <Loading message="Importando produtos" />
          ) : (
            <>
              <p>
                Para importar produtos faça o upload de um arquivo <b>CSV</b>{' '}
                contendo as informações dos produtos.
              </p>
              <p className="font-weight-bold">
                O arquivo deve conter as seguintes colunas, em ordem, nomeadas
                dessa forma e definidas na primeira linha do arquivo:
              </p>
              <p className="text-center break-text">
                nome_do_produto,referencia,codigo_de_barras,preco_base,imagem
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <input
            type="file"
            id="products-import"
            accept=".csv, .xls, .xlsx"
            ref={inputRef}
            className="d-none"
            onChange={e => {
              const { files } = e.target;
              handleUpload(files[0]);
            }}
          />
          <Button
            className={`${uploading && 'd-none'}`}
            color="primary"
            onClick={() => inputRef?.current?.click()}
          >
            Escolher arquivo
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
