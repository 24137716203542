import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';

import { toast } from 'react-toastify';
import { shade } from 'polished';
import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import {
  saveClient,
  updateClient,
} from '~/store/modules/shareable_cart/actions';
import { maskBuilder } from '~/util/maskTelephone';
import SchemaValidator from '~/validators/form/shareable_client_form';
import api from '~/services/api';

const ClientLiteForm = () => {
  const formRef = useRef(null);
  const Client = useSelector(({ shareable_cart }) => shareable_cart.client);
  const Cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  const [telephoneV, setTelephone] = useState('');

  const dispatch = useDispatch();

  const token = useSelector(
    ({ shareable_cart }) => shareable_cart.cart.shareable_token
  );

  const searchByTelephone = async value => {
    try {
      const {
        data: { client },
      } = await api.get(`/shareable-carts/clients/find`, {
        headers: { 'X-Shareable-Token': token },
        params: { telephone: value },
      });
      return client;
    } catch (err) {
      return null;
    }
  };

  const handleSubmit = async data => {
    formRef.current.setErrors({});
    const maskTelephoneLength = maskBuilder(telephoneV).length;
    try {
      await SchemaValidator(maskTelephoneLength).validate(data, {
        abortEarly: false,
      });

      const client = await searchByTelephone(data.telephone);

      if (client) {
        data = {
          ...data,
          id: client.id,
        };
        dispatch(updateClient({ data, formRef }));
      } else {
        dispatch(saveClient({ data, formRef }));
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          ...Client,
        }}
      >
        <Card className="card new-layout">
          <CardBody className="card-body new-layout">
            <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-4 justify-content-center">
              Confira Suas Informações
            </Row>
            <Row className="w-100 mx-0">
              <Col lg={6} className="px-0 pr-lg-2">
                <Label>Nome Completo</Label>
                <FormGroup>
                  <Input name="name" placeholder="Nome" type="text" />
                </FormGroup>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2">
                <Label>WhatsApp</Label>
                <FormGroup>
                  <InputMask
                    mask={maskBuilder(telephoneV)}
                    maskChar={null}
                    defaultValue={telephoneV}
                    name="telephone"
                    placeholder="Telefone"
                    type="tel"
                    onChange={e => {
                      setTelephone(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col lg={6} className="px-0 pr-lg-2 ">
                <Label>
                  E-mail
                  {/* <i
                    className="las la-info-circle font-size-16 d-inline ml-2"
                    id="share-cart-info"
                  /> */}
                  {/* <UncontrolledTooltip target="share-cart-info">
                    Informe o e-mail para o cliente acompanhar o pedido
                  </UncontrolledTooltip> */}
                </Label>
                <FormGroup className="mb-2">
                  <Input name="email" placeholder="E-mail" type="email" />
                </FormGroup>
                <span className="font-weight-300 font-size-12">
                  Informe o e-mail para acompanhar seu pedido
                </span>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2 ">
                <Label>
                  CPF
                  {/* <i
                    className="las la-info-circle font-size-16 d-inline ml-2"
                    id="share-cart-info"
                  />
                  <UncontrolledTooltip target="share-cart-info">
                    Informe o CPF para cadastro no programa de fidelidade
                  </UncontrolledTooltip> */}
                </Label>
                <FormGroup className="mb-2">
                  <InputMask
                    name="cpf"
                    placeholder="CPF"
                    mask="999.999.999-99"
                    maskChar={null}
                    type="tel"
                  />
                </FormGroup>
                <span className="font-weight-300 font-size-12">
                  Informe o seu CPF para finalizar seu pedido
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0" align="end">
            <Button
              color="primary"
              size="lg"
              type="submit"
              className="new-layout-btn main"
              style={{
                backgroundColor: Cart?.store?.customize?.theme_color,
                borderColor: Cart?.store?.customize?.theme_color,
                ':hover': {
                  backgroundColor: shade(
                    0.2,
                    Cart?.store?.customize?.theme_color
                  ),
                  borderColor: shade(0.2, Cart?.store?.customize?.theme_color),
                },
              }}
              disabled={!telephoneV}
            >
              {Client ? 'Salvar alterações' : 'Avançar'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ClientLiteForm;
