import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, NavLink } from 'react-router-dom';

import {
  Badge,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import {
  decrementUnreadNotificationsCount,
  loadOlderNotifications,
  setNotificationsArray,
  setNotificationsPageArray,
} from '~/store/modules/notifications/actions';
import api from '~/services/api';
import {
  calculateTime,
  defineColor,
  defineURL,
} from '~/util/notificationsHelpers';
import IconButton from '../IconButton';
import Loading from '../Loading';

export default function Notifications({ size, color, badgeMarginTop }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const globalNotifications = useSelector(
    ({ notifications }) => notifications.notifications
  );
  const pagination = useSelector(
    ({ notifications }) => notifications.notificationsPagination
  );
  const unreadCount = useSelector(
    ({ notifications }) => notifications.unreadNotificationsCount
  );
  const pageNotifications = useSelector(
    ({ notifications }) => notifications.notificationsPageArray
  );
  const loading = useSelector(({ notifications }) => notifications.loading);
  const [show, setShow] = useState(false);

  const currPage = useMemo(() => {
    if (pagination) {
      return pagination.current_page;
    }
    return undefined;
  }, [pagination]);

  const lastPage = useMemo(() => {
    if (pagination) {
      return pagination.last_page;
    }
    return undefined;
  }, [pagination]);

  window.addEventListener(
    'click',
    () => {
      if (show) {
        setShow(false);
      }
    }
    // eslint-disable-next-line func-names
  );

  const handleMarkAsRead = async notification => {
    try {
      await api.patch(`/notifications/${notification.uuid}/mark-as-read`);

      const notificationsDraft = globalNotifications.map(n => {
        if (n.uuid === notification.uuid) {
          return {
            ...n,
            unread: false,
          };
        }
        return n;
      });

      if (location.pathname === '/notificacoes') {
        const notificationIsShowInPage = pageNotifications.some(
          n => n.uuid === notification.uuid
        );
        if (notificationIsShowInPage) {
          const notificationsPageDraft = pageNotifications.map(n => {
            if (n.uuid === notification.uuid) {
              return {
                ...n,
                unread: false,
              };
            }
            return n;
          });
          dispatch(setNotificationsPageArray(notificationsPageDraft));
        }
      }

      dispatch(decrementUnreadNotificationsCount());
      dispatch(setNotificationsArray(notificationsDraft));
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao tentar marcar a notificação como lida. Entre em contato com um administrador'
        );
      }
    }
  };

  const handleLoadOlderNotifications = async () => {
    try {
      const params = {
        page: currPage + 1,
      };

      const {
        data: { notifications, meta },
      } = await api.get(`/notifications`, {
        params,
      });

      dispatch(loadOlderNotifications({ notifications, meta }));
    } catch (err) {
      if (err.response?.status !== 503) {
        toast.error(
          'Houve um erro ao tentar carregar as notificações mais antigas. Contate um administrador'
        );
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  return (
    <>
      <Row className="w-100 mx-0">
        <Col lg={unreadCount > 0 ? 10 : 12} className="px-0" align="end">
          <IconButton
            icon="las la-bell"
            id="notifications"
            fontSize={size || 25}
            color={color || 'secondary'}
            // containerClass="w-100 h-100 pl-2 pt-4"
          />
        </Col>
        {unreadCount > 0 && (
          <Col
            lg={2}
            className="px-0 d-flex flex-column justify-content-start align-items-end"
          >
            <Badge
              color="danger"
              style={{
                width: 'fit-content',
                marginRight: '-5px',
                marginTop: `${badgeMarginTop}px` || '-5px',
              }}
            >
              {unreadCount}
            </Badge>
          </Col>
        )}
      </Row>

      <Popover
        placement="bottom"
        isOpen={show}
        target="notifications"
        trigger="click"
        toggle={() => {
          setShow(!show);
        }}
        style={{ width: '280px' }}
        delay={0}
      >
        <PopoverHeader>NOTIFICAÇÔES</PopoverHeader>
        <PopoverBody>
          <PerfectScrollbar
            style={{ height: '200px' }}
            onYReachEnd={() => {
              if (currPage < lastPage) {
                handleLoadOlderNotifications();
              }
            }}
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {globalNotifications.length ? (
                  globalNotifications.map(notification => {
                    return (
                      <div style={{ width: '95%' }} key={notification.uuid}>
                        <NavLink
                          onClick={() => {
                            setShow(false);
                          }}
                          to={defineURL(notification)}
                        >
                          <div className="outline-none" key={notification.uuid}>
                            <i
                              className={`fas fa-exclamation-circle text-info d-inline mr-1
                              ${defineColor(notification)}
                              `}
                            />
                            <h5 className="d-inline font-size-14 mb-1">
                              {notification.message}
                            </h5>
                          </div>
                        </NavLink>
                        <Row className="w-100 mx-0">
                          <Col className="px-0" xs={6}>
                            <span className="d-block text-muted">
                              {calculateTime(notification.created_at)}
                            </span>
                          </Col>
                          <Col
                            xs={6}
                            align="end"
                            className={`outline-none pr-0 ${
                              !notification.unread && 'pointer-events-none'
                            }`}
                            role="button"
                            tabIndex={0}
                            aria-label="0"
                            onClick={e => {
                              handleMarkAsRead(notification);
                              e.stopPropagation();
                            }}
                          >
                            <i
                              className={`las la-${
                                notification.unread
                                  ? 'circle text-muted'
                                  : 'check-circle text-success'
                              } mr-1 d-inline font-size-12 outline-none`}
                            />
                            <span
                              className={`d-inline ${
                                notification.unread
                                  ? 'circle text-muted'
                                  : 'check-circle text-success'
                              }`}
                            >
                              {notification.unread ? 'Não lida' : 'Lida'}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div>Não há notificações para serem exibidas</div>
                )}
              </>
            )}
          </PerfectScrollbar>
          <div className="mt-1">
            <a href="/notificacoes">Ver todas as notificações</a>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
}
