import { useMemo } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import QRCodeLib from 'react-qr-code';
import ShareIcon from '@mui/icons-material/Share';
import { getRole } from '~/util/getRole';
import SellerImage from './SellerImage';

function BusinessCard() {
  const profile = useSelector(({ user }) => user.profile);

  const roleName = useMemo(() => {
    const roleSlug = getRole(profile.roles);
    const role = profile.roles.find(({ slug }) => slug === roleSlug);

    return role?.name;
  }, [profile.roles]);

  const width = window.innerWidth;

  const shareLink = profile.shotened_url.url;

  return (
    <div className="page-content business-card">
      <Container
        className="
          container new-layout wide d-flex flex-column
          justify-content-between align-items-center
        "
      >
        <Card className="px-4 px-sm-5 mb-0">
          <CardBody className="d-flex flex-column justify-content-between align-items-center px-4 px-sm-5">
            <Col align="center" className="mt-3">
              <SellerImage src={profile.profile_picture} className="my-3" />
              <h3 className="mb-1 text-dark font-size-20">{profile.name}</h3>
              <p className="mb-0 font-size-16">{roleName}</p>
              <p className="mb-3 pb-3 font-size-14">
                {profile.stock?.fantasy_name}
              </p>
            </Col>

            <Col className="d-flex flex-column justify-content-center align-items-center">
              <Row>
                <a
                  href={`tel:55${profile.telephone.replace(/\D/g, '')}`}
                  className="d-flex align-items-center ml-1 text-dark font-size-16"
                >
                  <i className="lab la-whatsapp mr-1 font-size-24" />
                  <span>+55 {profile.telephone}</span>
                </a>
              </Row>

              <Row className="my-2" style={{ alignText: 'center' }}>
                <a
                  style={{ alignText: 'center' }}
                  href={`mail:${profile.email}`}
                  className="d-flex align-items-center ml-2 text-dark font-size-16"
                >
                  <i className="las la-envelope mr-2 font-size-24" />
                  {profile.email}
                </a>
              </Row>
            </Col>

            <QRCodeLib
              size={width < 500 ? 164 : 200}
              value={shareLink}
              className="my-4 mx-auto"
            />

            <a
              /* href={`${window.location.protocol
                .concat('//')
                .concat(window.location.host)}/cadastro-novo-cliente?id=${
                profile.id
              }`} */
              href={`https://api.whatsapp.com/send?text=Que tal fazer parte da minha comunidade e ter acesso a ofertas exclusivas: ${shareLink.replace(
                '&',
                '%26'
              )}`}
              target="_blank"
              rel="noreferrer"
              className="
                new-layout-btn main mb-4 mt-3 bg-primary text-white
                d-flex justify-content-center align-items-center
              "
              style={{ width: '200px' }}
            >
              <ShareIcon className="mr-2" />
              Compartilhar
            </a>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default BusinessCard;
