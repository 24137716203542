import React, { useState, useEffect, useMemo } from 'react';
import { Container, Col, Row } from 'reactstrap';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DeleteModal from '~/components/Modals/DeleteModal';
import Loading from '~/components/Loading';
import DeliveryGroupCard from './DeliveryGroupCard';
import EditDeliveryGroup from './EditDeliveryGroup';
import NewDeliveryGroup from './NewDeliveryGroup';

const DeliveryGroups = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);

  const [groupToBeEdited, setGroupToBeEdited] = useState(null);
  const [groupToBeDeleted, setGroupToBeDeleted] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getURL = useMemo(() => {
    return '/administrator/store-groups';
  }, []);

  const deleteURL = useMemo(() => {
    return `/administrator/store-groups/${groupToBeDeleted?.id}`;
  }, [groupToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    setGroups(data.store_groups);
    setPagination(data.meta);
  };

  useEffect(() => {
    document.title = 'Grupos de Entrega | Viddy';

    const loadDeliveryGroups = async () => {
      const { data } = await api.get(getURL);

      setData(data);

      setLoading(false);
    };

    loadDeliveryGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="GRUPOS DE ENTREGA"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            <NewDeliveryGroup getURL={getURL} setData={setData} />
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {groups.length ? (
              <>
                <Row className="w-100 mx-0">
                  {groups?.map((group, index) => {
                    return (
                      <Col
                        key={group.id}
                        className={`px-0 pr-md-${
                          (index + 1) % 2 !== 0 ? 2 : 0
                        } pl-md-${(index + 1) % 2 === 0 ? 2 : 0}
                    pr-lg-${(index + 1) % 4 !== 0 ? 2 : 0} pl-lg-${
                          index % 4 !== 0 ? 2 : 0
                        }
                    `}
                        md={6}
                        lg={3}
                      >
                        <DeliveryGroupCard
                          group={group}
                          onEdit={() => {
                            setGroupToBeEdited(group);
                            setShowEditModal(true);
                          }}
                          onDelete={() => {
                            setGroupToBeDeleted(group);
                            setShowDeleteModal(true);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum grupo encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={groups}
        setItemToBeDeleted={setGroupToBeDeleted}
        headerText="Deseja mesmo excluir o Grupo de Entrega?"
        bodyText="Essa ação irá excluir o grupo de entrega"
        itemName={groupToBeDeleted?.name}
        successMsg="Grupo de Entrega excluído com sucesso!"
      />

      <EditDeliveryGroup
        show={showEditModal}
        setShow={setShowEditModal}
        getURL={getURL}
        setData={setData}
        groupToBeEdited={groupToBeEdited}
        setGroupToBeEdited={setGroupToBeEdited}
      />
    </div>
  );
};

export default DeliveryGroups;
