import React, { useState, useEffect, useMemo } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import {
  decrementUnreadNotificationsCount,
  MarkAllAsRead,
  setNotificationsArray,
  setNotificationsPageArray,
} from '~/store/modules/notifications/actions';
import PageHeader from '~/components/Common/PageHeader';
import { defineColor, defineURL } from '~/util/notificationsHelpers';
import Loading from '~/components/Loading';
import NotificationCard from './NotificationCard';

const Notifications = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(null);

  const pageNotifications = useSelector(
    ({ notifications }) => notifications.notificationsPageArray
  );
  const reduxNotifications = useSelector(
    ({ notifications }) => notifications.notifications
  );

  const [loading, setLoading] = useState(true);

  const roles = useSelector(({ user }) => user.profile.roles);

  const getURL = useMemo(() => {
    return '/notifications';
  }, []);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
    };
  }, [pagination]);

  const allRead = useMemo(() => {
    return !pageNotifications.some(
      notification => notification.unread === true
    );
  }, [pageNotifications]);

  const setData = data => {
    const formattedNotifications = data.notifications.map(notification => ({
      ...notification,
      icon_color: defineColor(notification),
      link: defineURL(notification, roles),
      formatted_created_at: format(
        parseISO(notification.created_at),
        "dd/MM/yyyy 'às' HH':'mm",
        {
          locale: ptBR,
        }
      ),
    }));

    dispatch(setNotificationsPageArray(formattedNotifications));
    setPagination(data.meta);
  };

  useEffect(() => {
    document.title = 'Histórico de Notificações | Viddy';

    const loadNotifications = async () => {
      const { data } = await api.get(getURL);

      setData(data);
      setLoading(false);
    };
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarkAsRead = async notification => {
    try {
      await api.patch(`/notifications/${notification.uuid}/mark-as-read`);

      const notificationsDraft = pageNotifications.map(n => {
        if (n.uuid === notification.uuid) {
          return {
            ...n,
            unread: false,
          };
        }
        return n;
      });

      const reduxNotificationsDraft = reduxNotifications.map(n => {
        if (n.uuid === notification.uuid) {
          return {
            ...n,
            unread: false,
          };
        }
        return n;
      });

      dispatch(decrementUnreadNotificationsCount());
      dispatch(setNotificationsArray(reduxNotificationsDraft));
      dispatch(setNotificationsPageArray(notificationsDraft));
      // setNotificationsList(notificationsDraft);
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(
          'Houve um erro ao tentar marcar a notificação como lida. Entre em contato com um administrador'
        );
      }
    }
  };

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0 mb-4 mb-lg-0">
          <Col lg={9} className="px-0">
            <PageHeader
              pageTitle="HISTÓRICO DE NOTIFICAÇÕES"
              responsivePosition="start"
              desktopPosition="start"
            />
          </Col>
          <Col lg={3} className="px-0" align="end">
            <Button
              color="primary"
              className="new-layout-btn main my-lg-4"
              onClick={() => {
                dispatch(MarkAllAsRead());
              }}
              disabled={allRead}
            >
              Marcar tudo como lido
            </Button>
          </Col>
        </Row>

        {loading ? (
          <Loading />
        ) : (
          <>
            {pageNotifications.length ? (
              <>
                {pageNotifications?.map(notification => {
                  return (
                    <NotificationCard
                      key={notification.uuid}
                      notification={notification}
                      onMarkAsRead={handleMarkAsRead}
                    />
                  );
                })}
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhuma notificação encontrada.</h3>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Notifications;
