import React from 'react';

export default function CustomIcon({ icon }) {
  return (
    <div className="dashboard-custom-icon">
      <div className="shine-diagonal-beam" />
      <i className={`${icon} text-white`} />
    </div>
  );
}
