import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import Input from '~/components/Inputs/Input';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';
// import useRoles from '~/hooks/useRoles';

export default function CuponsNumberCard({ cart, handle, formRef }) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const [edit, setEdit] = useState(false);
  const [nfceNumber, setNfceNumber] = useState(cart.nfce?.number || '');

  const handleNfceEditOrSave = () => {
    if (cart.nfce && !edit) {
      setEdit(true);
    } else {
      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Form ref={formRef} onSubmit={handle}>
      <Card className="new-layout card">
        <CardBody className="new-layout card-body px-3 py-3">
          <Row
            className="w-100 mx-0 mb-lg-2 cursor-pointer"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Col className="font-size-16 font-weight-600 px-0">
              <Row className="w-100 mx-0 d-flex justify-content-between align-items-center">
                <div className="px-0 ml-lg-2">Nota Fiscal</div>
                <div className="px-0">
                  <IconButton
                    icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                    click={() => {
                      setExpanded(!expanded);
                    }}
                    color="gray-700"
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Collapse isOpen={expanded}>
            <Row className="w-100 mx-0 header pt-3 d-lg-flex">
              <Row className="w-100 mx-0 mb-3">
                <>
                  <Col lg={6} className="py-0 px-0 pl-lg-2 align-items-center">
                    <Input
                      name="receipt_number"
                      type="text"
                      placeholder="Número da nota"
                      disabled={cart.nfce && !edit}
                      value={nfceNumber}
                      onChange={e => setNfceNumber(e.target.value)}
                    />
                  </Col>
                  <Col lg={4} className="px-0 px-lg-2 d-flex pt-3 pt-lg-0">
                    <Button
                      className="new-layout-btn new-layout-btn-transparent main mb-2 mb-lg-0 mr-lg-2 "
                      color="primary"
                      onClick={handleNfceEditOrSave}
                    >
                      {cart.nfce && !edit ? 'Editar' : 'Salvar'}
                    </Button>
                  </Col>
                </>
                {/* {cart?.status >= 4 && !cart.nfce ? (
                  <Row className="w-100 mx-0 header py-2 d-lg-flex">
                    Não existe nenhum número de nota fiscal cadastrado.
                  </Row>
                ) : (

                )} */}
              </Row>
            </Row>
          </Collapse>
        </CardBody>
      </Card>
    </Form>
  );
}
