/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from '~/store/modules/layout/actions';

// Other Layout related Component
// import Navbar from './Navbar';
// import OldHeader from './OldHeader';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
// import WhatsAppSupportButton from './WhatsAppSupportButton';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
  }

  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);

    const title = this.props.location.pathname;
    const currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = `${currentage} | Viddy`;

    this.props.changeLayout('horizontal');
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
  }

  /**
   * Opens the menu - mobile
   */
  // eslint-disable-next-line no-unused-vars
  openMenu = e => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  render() {
    return (
      <>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>

        <div id="layout-wrapper" className="layout-wrapper overflow-x-hidden">
          <Header
            theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}
            toggleRightSidebar={this.toggleRightSidebar}
            openLeftMenuCallBack={this.openMenu}
          />
          {/* <OldHeader
            theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}
            toggleRightSidebar={this.toggleRightSidebar}
            openLeftMenuCallBack={this.openMenu}
          /> */}
          {/* <Navbar menuOpen={this.state.isMenuOpened} /> */}
          <Sidebar />
          <div className="main-content space-whatsapp-button">
            {this.props.children}
          </div>
          {/* <WhatsAppSupportButton /> */}
          <Footer />
        </div>
      </>
    );
  }
}
const mapStatetoProps = state => {
  return {
    ...state.layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout));
