import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import IconButton from '~/components/IconButton';
import Input from '~/components/Inputs/Input';
import { setShowCieloModal } from '~/store/modules/shareable_cart/actions';
import { copyStringToClipboard } from '~/util/copyToClipboard';

export default function CieloModal() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const show = useSelector(
    ({ shareable_cart }) => shareable_cart.showCieloModal
  );
  const authentication_url = useSelector(
    ({ shareable_cart }) =>
      shareable_cart.cart?.payment_process?.authentication_url
  );
  return (
    <Modal isOpen={show}>
      <ModalHeader
        toggle={() => {
          dispatch(setShowCieloModal(false));
        }}
      >
        Link de confirmação da Cielo
      </ModalHeader>
      <ModalBody>
        <Row className="w-100 mx-0 mb-2">
          A Cielo requer que você confirme seus dados no sistema deles.
        </Row>
        <Row className="w-100 mx-0 mb-2">
          <a className="mr-1" href={authentication_url}>
            Clique Aqui{' '}
          </a>{' '}
          para ir até a página de verificação.
        </Row>
        <Row className="w-100 mx-0 mb-2">
          Caso o link acima não funcione, clique no botão de copiar abaixo e
          cole o link no seu navegador.
        </Row>
        <Row className="w-100 mx-0">
          <Row className="w-100 mx-0">
            <Col xs={10} className="px-0">
              <Form ref={formRef}>
                <Input
                  type="text"
                  name="cielo_authentication_url"
                  placeholder="https://cielo.com.br/example"
                  className="form-control h-46px disabled"
                  readOnly
                  value={authentication_url}
                />
              </Form>
            </Col>
            <Col
              xs={2}
              className="px-0 d-flex flex-column justify-content-center align-items-center"
            >
              <IconButton
                id="copySharedCartLink"
                tip="Copiar"
                icon="las la-copy"
                color="info"
                click={() =>
                  copyStringToClipboard(authentication_url, 'Link copiado!')
                }
                fontSize={30}
                forceTip
              />
            </Col>
          </Row>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="new-layout-btn main"
          onClick={() => {
            dispatch(setShowCieloModal(false));
          }}
        >
          Concluir
        </Button>
      </ModalFooter>
    </Modal>
  );
}
