const adjustMask = v => {
  const digits = !v ? '' : v.replace(/[^\d]/g, '');
  if (!digits || digits.length < 10) return v;
  const cut = digits.length === 10 ? 6 : 7;
  const max = digits.length > 11 ? 11 : digits.length;
  return `(${digits.substring(0, 2)}) ${digits.substring(
    2,
    cut
  )}-${digits.substring(cut, max)}`;
};

export const maskBuilder = v => {
  if (!v || v.length === 0) return '';
  const mask = adjustMask(v);
  return mask.length > 14 && mask[5] === '9'
    ? '(99) 99999-9999'
    : '(99) 9999-99999';
};
