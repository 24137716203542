/* Sale */
export const ADD_SALE = '@sale/ADD_SALE';

export const SET_LOADING = '@sale/SET_LOADING';

export const REMOVE_SALE = '@sale/REMOVE_SALE';
export const REMOVE_SALE_SUCCESS = '@sale/REMOVE_SALE_SUCCESS';
export const REMOVE_SALE_FAILURE = '@sale/REMOVE_SALE_FAILURE';

export const UPDATE_SALE = '@sale/UPDATE_SALE';
export const UPDATE_SALE_SUCCESS = '@sale/UPDATE_SALE_SUCCESS';

/* TEMPORARILY ACTION TO LOAD SALES EVERY 5 SECONDS */
export const UPDATE_SALE_LIST = '@sale/UPDATE_SALE_LIST';
export const UPDATE_SALE_LIST_META = '@sale/UPDATE_SALE_LIST_META';
export const UPDATE_SALE_LIST_SUCCESS = '@sale/UPDATE_SALE_LIST_SUCCESS';

export const LOCAL_SEARCH = '@sale/LOCAL_SEARCH';

export const SEARCH_SALE = '@sale/SEARCH_SALE';
export const SEARCH_SALE_SUCCESS = '@sale/SEARCH_SALE_SUCCES';

export const SET_AS_RESCHEDULED = '@sale/SET_AS_RESCHEDULED';

/* API ERROR MESSAGE */
export const API_ERROR = '@sale/API_ERROR';
