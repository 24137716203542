import api from '~/services/api';

import { stateList } from './stateList';

async function searchByCep({
  formRef,
  setSelectedState,
  setSelectedCity,
  setStreetInfo,
  pathCEP,
  setLoadingByCep,
}) {
  const pathToFieldCEP = pathCEP || 'cep';

  const cep = formRef.current?.getFieldValue(pathToFieldCEP);

  // console.log(formRef.current);

  if (cep?.length > 8) {
    try {
      const { data } = await api.get(`cep/${cep}`);

      formRef.current.setData(data);

      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);

      if (state && city) {
        formRef.current.setFieldError('city', null);
        formRef.current.setFieldError('state', null);
      }

      if (setStreetInfo) setStreetInfo(data.street_name);
    } catch (err) {
      formRef.current.setFieldError('cep', 'CEP não localizado');
    }
  }

  setLoadingByCep(false);
}

export default searchByCep;
