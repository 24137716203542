import { useEffect, useState } from 'react';

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => setValue(JSON.parse(localStorage.getItem(key))), [key]);

  return [
    value || defaultValue,
    nvalue => {
      setValue(nvalue);
      localStorage.setItem(key, JSON.stringify(nvalue));
    },
  ];
};

export default useLocalStorage;
