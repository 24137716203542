import {
  CHAT_INITIAL_LOAD_REQUEST,
  CHAT_INITIAL_LOAD_SUCCESS,
  OPEN_CHAT_REQUEST,
  OPEN_CHAT_SUCCESS,
  CREATE_CHAT_REQUEST,
  RECONNECT,
  SUBSCRIBER_USER_REQUEST,
  PRINT_MSG_ON_FEED,
  PUT_MSG_ON_QUEUE,
  SEND_MSG_TO_SERVER,
  LIST_MSG_AS_SENT,
  SET_HANDLING_QUEUE,
  NEW_CHAT,
  UPDATE_CHAT_LIST,
  REORDER_CHAT_LIST,
  RELOAD_CHAT_LIST,
  NEW_MESSAGE,
  RESET_CHAT_STATE,
  SEARCH_USER,
  SET_LOADING_USERS,
  SET_NOT_LOADING_USERS,
  MODIFY_USERS_ARRAY,
  MODIFY_USERS_PAGINATION,
  LOAD_OLD_MESSAGES,
  PRINT_OLD_MESSAGES,
  LOAD_OLD_CHATS,
  PRINT_OLD_CHATS,
  LOAD_MORE_USERS,
  PRINT_MORE_USERS,
  SET_LOADING_OLD_MSGS,
  SET_LOADING_OLD_CHATS,
  SET_LOADING_MORE_USERS,
  ADD_SELECTED_USER,
  REMOVE_SELECTED_USER,
  CREATE_NEW_GROUP_CHAT,
  REMOVE_ALL_SELECTED_USERS,
  SET_LOCATION,
  CLEAN_CURRENT_CHAT,
  MARK_AS_READ,
  MARK_CHAT_AS_READ_IN_CHATS_ARRAY,
  LOAD_CHATS_ON_LOGIN,
  POPULATE_CHATS_ON_LOGIN,
  DELETE_CHAT,
  EDIT_CHAT,
  REPLACE_EDITED_CHAT,
  SET_ACTIVE_TAB,
  SET_SELECT_MODE_ON,
  SET_CHOSEN_CHAT_TO_MANAGE,
  SET_SHOW_EDIT_CHAT_MODAL,
} from './actionsTypes';

export const Reconnect = () => {
  return {
    type: RECONNECT,
  };
};
export const SetLocation = location => {
  return {
    type: SET_LOCATION,
    payload: location,
  };
};

export const subscriberUserRequest = () => {
  return {
    type: SUBSCRIBER_USER_REQUEST,
  };
};

export const openChatRequest = ({ chat }) => {
  return {
    type: OPEN_CHAT_REQUEST,
    payload: { chat },
  };
};

export const openChatSuccess = ({ chat, messages, msgsPagination }) => {
  return {
    type: OPEN_CHAT_SUCCESS,
    payload: {
      chat,
      messages,
      msgsPagination,
    },
  };
};

export const PrintMsgOnFeed = msg => {
  return {
    type: PRINT_MSG_ON_FEED,
    payload: msg,
  };
};

export const PutMsgOnQueue = msg => {
  return {
    type: PUT_MSG_ON_QUEUE,
    payload: msg,
  };
};

export const SendMsgToServer = msg => {
  return {
    type: SEND_MSG_TO_SERVER,
    payload: msg,
  };
};

export const ListMsgAsSent = msg => {
  return {
    type: LIST_MSG_AS_SENT,
    payload: msg,
  };
};

export const SetHandlingQueue = flag => {
  return {
    type: SET_HANDLING_QUEUE,
    payload: flag,
  };
};

export const addsNewMessage = ({ message }) => {
  return {
    type: NEW_MESSAGE,
    payload: {
      message,
    },
  };
};

export const updateChatList = ({ chats, chatsPagination }) => {
  return {
    type: UPDATE_CHAT_LIST,
    payload: {
      chats,
      chatsPagination,
    },
  };
};

export const reorderChatList = newChat => {
  return {
    type: REORDER_CHAT_LIST,
    payload: newChat,
  };
};

export const reloadChatList = () => {
  return {
    type: RELOAD_CHAT_LIST,
  };
};

export const newChat = ({ chat }) => {
  return {
    type: NEW_CHAT,
    payload: {
      chat,
    },
  };
};

export const createChatRequest = user => {
  return {
    type: CREATE_CHAT_REQUEST,
    payload: {
      user,
    },
  };
};

export const InitialLoadRequest = () => {
  return {
    type: CHAT_INITIAL_LOAD_REQUEST,
  };
};

export const InitialLoadSuccess = ({
  users,
  usersPagination,
  // chats,
  // chatsPagination,
}) => {
  return {
    type: CHAT_INITIAL_LOAD_SUCCESS,
    payload: {
      users,
      usersPagination,
      // chats,
      // chatsPagination,
    },
  };
};

export const resetChatState = () => {
  return {
    type: RESET_CHAT_STATE,
  };
};
export const CleanCurrentChat = () => {
  return {
    type: CLEAN_CURRENT_CHAT,
  };
};

export const searchUser = filter => {
  return {
    type: SEARCH_USER,
    payload: filter,
  };
};

export const SetLoadingUsers = flag => {
  return {
    type: SET_LOADING_USERS,
    payload: flag,
  };
};

export const SetNotLoadingUsers = () => {
  return {
    type: SET_NOT_LOADING_USERS,
  };
};

export const ModifyUsersArray = users => {
  return {
    type: MODIFY_USERS_ARRAY,
    payload: users,
  };
};

export const SetUsersPagination = pagination => {
  return {
    type: MODIFY_USERS_PAGINATION,
    payload: pagination,
  };
};

export const LoadOldMessages = data => {
  return {
    type: LOAD_OLD_MESSAGES,
    payload: data,
  };
};

export const PrintOldMessages = data => {
  return {
    type: PRINT_OLD_MESSAGES,
    payload: data,
  };
};

export const LoadOldChats = data => {
  return {
    type: LOAD_OLD_CHATS,
    payload: data,
  };
};

export const PrintOldChats = data => {
  return {
    type: PRINT_OLD_CHATS,
    payload: data,
  };
};

export const LoadMoreUsers = data => {
  return {
    type: LOAD_MORE_USERS,
    payload: data,
  };
};

export const PrintMoreUsers = data => {
  return {
    type: PRINT_MORE_USERS,
    payload: data,
  };
};

export const SetLoadingOldMsgs = flag => {
  return {
    type: SET_LOADING_OLD_MSGS,
    payload: flag,
  };
};

export const SetLoadingOldChats = flag => {
  return {
    type: SET_LOADING_OLD_CHATS,
    payload: flag,
  };
};

export const SetLoadingMoreUsers = flag => {
  return {
    type: SET_LOADING_MORE_USERS,
    payload: flag,
  };
};

export const CreateNewGroupChat = (users, newGroupName) => {
  return {
    type: CREATE_NEW_GROUP_CHAT,
    payload: {
      users,
      newGroupName,
    },
  };
};

export const AddSelectedUser = user => {
  return {
    type: ADD_SELECTED_USER,
    payload: user,
  };
};

export const RemoveSelectedUser = user => {
  return {
    type: REMOVE_SELECTED_USER,
    payload: user,
  };
};

export const RemoveAllSelectedUsers = users => {
  return {
    type: REMOVE_ALL_SELECTED_USERS,
    payload: users,
  };
};

export const MarkChatAsRead = chat => {
  return {
    type: MARK_AS_READ,
    payload: chat,
  };
};

export const LoadChatsOnLogin = () => {
  return {
    type: LOAD_CHATS_ON_LOGIN,
  };
};

export const PopulateChatsOnLogin = (chats, meta) => {
  return {
    type: POPULATE_CHATS_ON_LOGIN,
    payload: {
      chats,
      chatsPagination: meta,
    },
  };
};

export const MarkChatAsReadInChatsArray = chat => {
  return {
    type: MARK_CHAT_AS_READ_IN_CHATS_ARRAY,
    payload: chat,
  };
};

export const deleteChat = chat => {
  return {
    type: DELETE_CHAT,
    payload: chat,
  };
};

export const editChat = params => {
  return {
    type: EDIT_CHAT,
    payload: params,
  };
};

export const replaceEditedChat = chat => {
  return {
    type: REPLACE_EDITED_CHAT,
    payload: chat,
  };
};

export const setActiveTab = tab => {
  return {
    type: SET_ACTIVE_TAB,
    payload: tab,
  };
};

export const setSelectModeOn = flag => {
  return {
    type: SET_SELECT_MODE_ON,
    payload: flag,
  };
};

export const setChosenChatToManage = chat => {
  return {
    type: SET_CHOSEN_CHAT_TO_MANAGE,
    payload: chat,
  };
};

export const setShowEditChatModal = flag => {
  return {
    type: SET_SHOW_EDIT_CHAT_MODAL,
    payload: flag,
  };
};
