import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '~/services/api';

export default function RestoreUserModal({
  show,
  setShow,
  store,
  setData,
  stores,
  // setFilter,
  setUserToBeRestored,
  params,
}) {
  const roles = useSelector(({ user: User }) => User.profile.roles);

  const isStoreManager = useMemo(
    () => roles.find(role => role.slug === 'store-manager'),
    [roles]
  );

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const handleRestoreUser = async id => {
    try {
      await api.patch(
        `${
          isStoreManager && !isAdmin ? 'store-manager' : 'administrator'
        }/stores/${id}/restore`
      );

      const { data } = await api.get(
        `${
          isStoreManager && !isAdmin ? 'store-manager' : 'administrator'
        }/stores`,
        {
          params: {
            ...params,

            page: stores.length === 1 ? params.page - 1 : params.page,
          },
        }
      );

      setData(data);
      // setFilter(false);
      setShow(false);

      toast.success('Loja reativada com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  const toggle = () => {
    setUserToBeRestored(null);
    setShow(false);
  };

  return (
    <Modal isOpen={show}>
      <ModalHeader toggle={toggle}>Deseja reativar a loja?</ModalHeader>
      <ModalBody>
        <h5>
          A loja <strong>{store?.name}</strong> será reativada.
        </h5>
      </ModalBody>
      <ModalFooter>
        <Button
          className="new-layout-btn main"
          color="success"
          size="lg"
          onClick={() => handleRestoreUser(store?.id)}
        >
          Reativar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
