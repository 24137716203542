import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import {
  NEW_MESSAGE_RECEIVED,
  HANDLE_UNREAD_MSG,
  LOAD_UNREAD_MSGS_REQUEST,
  LOAD_NOTIFICATIONS_REQUEST,
  MARK_ALL_NOTIFICATIONS_AS_READ,
} from './actionsTypes';
import {
  reorderChatList,
  addsNewMessage,
  MarkChatAsRead,
} from '../chat/actions';
import {
  AddUnreadMsg,
  HandleChatNotification,
  SetLoadingUnreadMsgs,
  LoadUnreadMsgsSuccess,
  LoadNotificationsSuccess,
  SetLoadingNotifications,
  setNotificationsArray,
  setNotificationsPageArray,
  SetCounterToZero,
} from './actions';

function* LoadNotifications() {
  try {
    yield put(SetLoadingNotifications(true));

    const {
      data: { notifications, meta },
    } = yield call(api.get, '/notifications');

    const {
      data: { count },
    } = yield call(api.get, '/notifications/unread-count');

    if (notifications) {
      yield put(LoadNotificationsSuccess({ notifications, meta, count }));
      // yield put(LoadUNotificationsSuccess(notifications.reverse()));
    }
  } catch (err) {
    if (err.response && err.response?.status === 503) return;
    toast.error(
      'Houve um erro ao carregar as notificações. Tente novamente mais tarde.'
    );
    yield put(SetLoadingNotifications(false));
  }
}

function* LoadUnreadMsgs() {
  try {
    yield put(SetLoadingUnreadMsgs(true));

    const {
      data: { chats },
    } = yield call(api.get, '/chats/unread');

    if (chats) {
      yield put(LoadUnreadMsgsSuccess(chats.reverse()));
    }
  } catch (err) {
    if (err.response && err.response?.status === 503) return;
    toast.error(
      'Houve um erro ao carregar as mensagens não lidas. Tente novamente mais tarde.'
    );
    yield put(SetLoadingUnreadMsgs(false));
  }
}

export function* handleChatNotification({ payload: newMessage }) {
  // const chats = yield select(state => state.chat.chats);
  const currentChat = yield select(state => state.chat.currentChat);
  const location = yield select(state => state.chat.location);

  function* addUnreadMsgToArray() {
    const res = yield call(api.get, 'chats/unread');
    const updatedNotification = res.data.chats.find(
      updatedChat => updatedChat.id === newMessage.chat.id
    );

    yield put(AddUnreadMsg(updatedNotification));
  }

  toast.info(
    `Nova mensagem recebida de ${
      newMessage.chat.is_group_chat
        ? `${newMessage.created_by.name} em ${newMessage.chat.name}!`
        : `${newMessage.created_by.name}!`
    }`
  );

  if (location.pathname === '/chat') {
    if (!currentChat || currentChat.id !== newMessage.chat.id) {
      // yield put(reorderChatList(newMessage));
      yield addUnreadMsgToArray();
    } else if (currentChat && currentChat.id === newMessage.chat.id) {
      yield put(MarkChatAsRead(newMessage.chat));
    }
  } else {
    yield addUnreadMsgToArray();
  }
}

function* markAllAsRead() {
  try {
    const pageNotifications = yield select(
      ({ notifications }) => notifications.notificationsPageArray
    );
    const reduxNotifications = yield select(
      ({ notifications }) => notifications.notifications
    );

    yield call(api.patch, '/notifications/mark-all-as-read');

    const notificationsDraft = pageNotifications.map(n => {
      return {
        ...n,
        unread: false,
      };
    });

    const reduxNotificationsDraft = reduxNotifications.map(n => {
      return {
        ...n,
        unread: false,
      };
    });

    yield put(setNotificationsArray(reduxNotificationsDraft));
    yield put(setNotificationsPageArray(notificationsDraft));
    yield put(SetCounterToZero());

    toast.success('Todas as notificações foram marcadas como lidas');
  } catch (err) {
    toast.success('Ocorreu um erro. Tente novamente mais tarde.');
  }
}

export function* newMessageReceived({ payload: { newMessage } }) {
  const messages = yield select(state => state.chat.messages);
  const currentChat = yield select(state => state.chat.currentChat);
  try {
    if (newMessage.chat.is_group_chat) {
      yield put(reorderChatList(newMessage.chat));
    } else {
      // const { data } = yield call(api.get, '/chats');
      // const chat = data.chats.find(chat => chat.id === newMessage.chat.id);
      const chat = {
        ...newMessage.chat,
        interlocutor: newMessage.created_by,
      };

      yield put(reorderChatList(chat));
    }

    yield put(HandleChatNotification(newMessage));

    if (
      newMessage.chat.id === currentChat?.id &&
      !messages.find(m => m.id === newMessage.id)
    ) {
      yield put(addsNewMessage({ message: newMessage }));
    }
  } catch (err) {
    toast.error(
      'Houve um erro ao tentar receber uma nova mensagem. Atualize a página.'
    );
  }
}

export default all([
  takeLatest(MARK_ALL_NOTIFICATIONS_AS_READ, markAllAsRead),
  takeLatest(NEW_MESSAGE_RECEIVED, newMessageReceived),
  takeLatest(HANDLE_UNREAD_MSG, handleChatNotification),
  takeLatest(LOAD_UNREAD_MSGS_REQUEST, LoadUnreadMsgs),
  takeLatest(LOAD_NOTIFICATIONS_REQUEST, LoadNotifications),
]);
