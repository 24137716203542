import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';
import RichTextEditor from 'react-rte';
import Loading from '~/components/Loading';

import api from '~/services/api';
import Pagination from '~/components/Pagination';
import PageHeader from '~/components/Common/PageHeader';
import SearchBox from '~/components/SearchBox';
import DeleteModal from '~/components/Modals/DeleteModal';
import AddNewAnnouncement from './AddAnnouncement';
import AnnouncementCard from './AnnouncementCard';
import EditAnnouncement from './EditAnnouncement';
import BroadcastAnnouncement from './BroadcastAnnouncement';

const Announcements = () => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const [announcements, setAnnouncements] = useState(null);
  const [pushNotifications, setPushNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [announcementToBeEdited, setAnnouncementToBeEdited] = useState(null);
  const [announcementToBeDeleted, setAnnouncementToBeDeleted] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showBroadcastModal, setShowBroadcastModal] = useState(false);
  const [announcementToBeBroadcasted, setAnnouncementToBeBroadcasted] =
    useState(false);

  const roles = useSelector(({ user }) => user.profile.roles);

  const isAdmin = useMemo(
    () => roles.find(role => role.slug === 'administrator'),
    [roles]
  );

  const getURL = useMemo(() => {
    return '/announcements';
  }, []);

  const deleteURL = useMemo(() => {
    return `administrator/announcements/${announcementToBeDeleted?.id}`;
  }, [announcementToBeDeleted]);

  const params = useMemo(() => {
    return {
      page: pagination?.current_page > 1 ? pagination?.current_page : null,
      query: searchQuery || null,
    };
  }, [pagination, searchQuery]);

  const setData = data => {
    const formattedAnnouncements = data.announcements.map(announcement => ({
      ...announcement,
      formatted_description: ReactHtmlParser(announcement.description),
    }));

    setAnnouncements(formattedAnnouncements);
    setPagination(data.meta);
  };

  const setPushs = data => {
    const formattedPushs = data.push_notifications.map(push => ({
      ...push,
      formatted_description: ReactHtmlParser(push.description),
    }));
    setPushNotifications(formattedPushs);
  };

  useEffect(() => {
    document.title = 'Anúncios | Viddy';

    const loadAll = async () => {
      const { data } = await api.get(getURL);
      const { data: dataPush } = await api.get(
        '/administrator/push-notifications'
      );

      setData(data);
      setPushs(dataPush);
      setLoading(false);
    };

    loadAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(pushNotifications);
  }, [pushNotifications]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <PageHeader
          pageTitle="ANÚNCIOS"
          responsivePosition="start"
          desktopPosition="start"
        />
        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0 mb-4 my-lg-0" lg={6}>
            <SearchBox
              url={getURL}
              query={searchQuery}
              setQuery={setSearchQuery}
              params={params}
              setData={setData}
              setLoading={setLoading}
            />
          </Col>
          <Col className="px-0" lg={6} align="end">
            {isAdmin && (
              <div className="d-flex justify-content-end">
                <AddNewAnnouncement
                  setAddModalOpen={setShowAddModal}
                  addModalOpen={showAddModal}
                  setAnnouncements={setAnnouncements}
                />
              </div>
            )}
          </Col>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <>
            {announcements.length ? (
              <>
                {announcements?.map(announcement => {
                  return (
                    <AnnouncementCard
                      key={announcement.id}
                      announcement={announcement}
                      onEdit={incomingAnnouncement => {
                        setValue(
                          RichTextEditor.createValueFromString(
                            incomingAnnouncement.description,
                            'html'
                          )
                        );
                        setAnnouncementToBeEdited(incomingAnnouncement);
                        setShowEditModal(true);
                      }}
                      onDelete={incomingAnnouncement => {
                        setAnnouncementToBeDeleted(incomingAnnouncement);
                        setShowDeleteModal(true);
                      }}
                      onBroadcast={incomingAnnouncement => {
                        setAnnouncementToBeBroadcasted(incomingAnnouncement);
                        setShowBroadcastModal(true);
                      }}
                      canManage={isAdmin}
                    />
                  );
                })}
                <Row className="w-100 mx-0 mb-4">
                  <Col className="px-0" align="end">
                    {pagination && (
                      <div className="mb-3">
                        <Pagination
                          paginationMeta={pagination}
                          url={getURL}
                          setData={setData}
                          setLoading={setLoading}
                          params={params}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ) : (
              <h3>Nenhum anúncio encontrado.</h3>
            )}
          </>
        )}
      </Container>

      <BroadcastAnnouncement
        show={showBroadcastModal}
        setShow={setShowBroadcastModal}
        announcementToBeBroadcasted={announcementToBeBroadcasted}
        setAnnouncementToBeBroadcasted={setAnnouncementToBeBroadcasted}
      />

      <EditAnnouncement
        show={showEditModal}
        setShow={setShowEditModal}
        announcementToBeEdited={announcementToBeEdited}
        setAnnouncementToBeEdited={setAnnouncementToBeEdited}
        setData={setData}
        getURL={getURL}
        value={value}
        setValue={setValue}
      />

      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={announcements}
        pagination={pagination}
        query={searchQuery}
        setItemToBeDeleted={setAnnouncementToBeDeleted}
        headerText="Deseja mesmo excluir o anúncio?"
        bodyText="Essa ação irá excluir o anúncio"
        itemName={`"${announcementToBeDeleted?.title}"`}
        successMsg="Anúncio excluído com sucesso!"
      />
    </div>
  );
};

export default Announcements;
