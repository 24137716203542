import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import QRCodeLib from 'react-qr-code';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import IconButton from '../IconButton';

export default function PixDataCard({ isPaymentCard, qrCode, children }) {
  const location = useLocation();

  const isInRegisterPage = location.pathname.includes('registrar');

  // const [expanded, setExpanded] = useState(() => {
  //   if (isInRegisterPage) {
  //     return true;
  //   }
  //   return false;
  // });

  const [expanded, setExpanded] = useState(true);

  const titleStyle = useMemo(() => {
    if (isInRegisterPage) {
      return 'font-size-20 font-weight-600 text-gray-600';
    }
    return 'font-size-16 font-weight-500 text-gray-700';
  }, [isInRegisterPage]);

  const valueStyle = useMemo(() => {
    if (isInRegisterPage) {
      return 'font-size-16 font-weight-300 text-gray-700';
    }
    return 'font-size-14 font-weight-400 text-gray-700';
  }, [isInRegisterPage]);

  if (isPaymentCard)
    return (
      <>
        <Row className="mx-0 mt-4">
          <Col xs={12} lg={7} className="px-0">
            <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end justify-content-between">
              {/* <div className={`${labelStyle} d-inline mr-1`}>QR Code:</div> */}
              <Col className="px-0">Esse é o seu QR Code!</Col>
              <Col
                xs={12}
                style={{ maxWidth: '100% !important' }}
                className={`${valueStyle} px-0 d-flex text-truncate`}
              >
                {`${qrCode.substring(0, 30)}...`}
              </Col>
              {!isInRegisterPage && (
                <Col xs={12} className="px-0 d-flex mt-2">
                  {/* <a
                    target="_blank"
                    rel="noreferrer"
                    href={`
                                        https://api.whatsapp.com/send?phone=55${sale?.client?.telephone.replace(
                                          /\D/g,
                                          ''
                                        )}&text=${
                      sale?.url_shortener?.url
                    }
                              `}
                  >
                    <Button
                      id="sharePaymentURL"
                      color="info"
                      className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                      outline
                    >
                      <i className="las la-share mr-2" />
                      <span className="font-size-14">Compartilhar</span>
                    </Button>
                  </a> */}
                  <Button
                    className="new-layout-btn main bg-light-blue text-primary border-0 d-flex align-items-center justify-content-center"
                    color="info"
                    onClick={() =>
                      copyStringToClipboard(qrCode, 'QR code copiado!')
                    }
                  >
                    <i className="las la-copy mr-2" />
                    Copiar
                  </Button>
                </Col>
              )}
              <Col xs={12} className="px-0 mt-2">
                Agora só falta enviá-lo ao cliente!
              </Col>
            </Row>
            {children}
          </Col>
          <Col
            xs={12}
            lg={5}
            className="w-100 px-0 d-flex flex-row justify-content-end"
          >
            <QRCodeLib value={qrCode} size={220} />
          </Col>
        </Row>

        {/* <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
              <div className={`${labelStyle} d-inline mr-1`}>Chave:</div>
              <div className={`${valueStyle} d-inline`}>{store?.pix_key}</div>
            </Row>

            <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
              <div className={`${labelStyle} d-inline mr-1`}>Nome:</div>
              <div className={`${valueStyle} d-inline`}>{store?.name}</div>
            </Row>
            <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
              <div className={`${labelStyle} d-inline mr-1`}>Banco:</div>
              <div className={`${valueStyle} d-inline`}>
                {store?.bank_data?.institution}
              </div>
            </Row>
            <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
              <div className={`${labelStyle} d-inline mr-1`}>Agência:</div>
              <div className={`${valueStyle} d-inline`}>
                {store?.bank_data?.agency}
              </div>
            </Row>
            <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
              <div className={`${labelStyle} d-inline mr-1`}>Conta:</div>
              <div className={`${valueStyle} d-inline`}>
                {store?.bank_data?.account_number}
              </div>
            </Row> */}
      </>
    );

  return (
    <Card className="box-shadow-none border">
      <CardBody>
        <Row
          className={`w-100 mx-0 ${titleStyle} cursor-pointer`}
          onClick={e => {
            if (isInRegisterPage) return;

            setExpanded(!expanded);
            e.stopPropagation();
          }}
        >
          <Col className="px-0 d-flex justify-content-between" align="start">
            QR Code PIX
            {isInRegisterPage ? (
              <IconButton
                id="copyPixData"
                tip="Copiar"
                icon="las la-copy"
                color="info"
                click={() =>
                  copyStringToClipboard(qrCode, 'QR Code copiado com sucesso!')
                }
                fontSize={32}
                forceTip
              />
            ) : (
              <IconButton
                id="collapse-btn"
                tip={expanded ? 'Esconder' : 'Expandir'}
                icon={`las la-angle-${expanded ? 'up' : 'right'}`}
                color="seconday"
                forceTip
              />
            )}
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 mb-2 mt-3">
            <Col className="px-0">Esse é o seu QR Code!</Col>
          </Row>
          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end justify-content-between">
            {/* <div className={`${labelStyle} d-inline mr-1`}>QR Code:</div> */}
            <div
              style={{ maxWidth: '100% !important' }}
              className={`${valueStyle} d-flex text-truncate`}
            >
              {`${qrCode.substring(0, 30)}...`}
            </div>
            {!isInRegisterPage && (
              <Button
                className="new-layout-btn-copy new-layout-btn-transparent main d-flex align-items-center justify-content-center"
                color="primary"
                onClick={() =>
                  copyStringToClipboard(qrCode, 'QR code copiado!')
                }
              >
                <i className="las la-copy mr-2" />
                Copiar
              </Button>
            )}
          </Row>

          <Row className="w-100 mx-0 mb-2">
            <Col className="px-0">Agora só falta enviá-lo ao cliente!</Col>
          </Row>
          {/* <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
            <div className={`${labelStyle} d-inline mr-1`}>Chave:</div>
            <div className={`${valueStyle} d-inline`}>{store?.pix_key}</div>
          </Row>

          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
            <div className={`${labelStyle} d-inline mr-1`}>Nome:</div>
            <div className={`${valueStyle} d-inline`}>{store?.name}</div>
          </Row>
          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
            <div className={`${labelStyle} d-inline mr-1`}>Banco:</div>
            <div className={`${valueStyle} d-inline`}>
              {store?.bank_data?.institution}
            </div>
          </Row>
          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
            <div className={`${labelStyle} d-inline mr-1`}>Agência:</div>
            <div className={`${valueStyle} d-inline`}>
              {store?.bank_data?.agency}
            </div>
          </Row>
          <Row className="w-100 mx-0 mb-2 d-flex flex-row align-items-end">
            <div className={`${labelStyle} d-inline mr-1`}>Conta:</div>
            <div className={`${valueStyle} d-inline`}>
              {store?.bank_data?.account_number}
            </div>
          </Row> */}
          <Row className="w-100 mx-0 d-flex flex-row justify-content-center mt-4">
            <QRCodeLib value={qrCode} size={256} />
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
