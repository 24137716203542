import { persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

const encryptor = createEncryptor({
  secretKey: '!hNj@?HDZUjxTWtA2u-CyfS3e5@Rys3W8XM3Bw_qte',
});

export default reducers => {
  const persistedReducer = persistReducer(
    {
      transforms: [encryptor],
      key: 'Viddy',
      storage,
      whitelist: ['user'],
    },
    reducers
  );

  return persistedReducer;
};
