import React from 'react';

import {
  Card,
  CardBody,
  Col,
  CustomInput,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import history from '~/services/history';
import shopBagImg from '~/assets/images/shopbag.png';
import IconButton from '~/components/IconButton';
import { formatPrice } from '~/util/format';

export default function ProductInfoCard({
  product,
  isSelected,
  onSelect,
  onDelete,
  canManage,
  isAllowedToBach,
}) {
  return (
    <Card className="card new-layout list-card grow-on-hover">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 h-100 mx-0 d-none d-lg-flex">
          <Col xs={1} align="center" className="h-100">
            {isAllowedToBach && (
              <div
                className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap outline-none"
                onClick={e => {
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                aria-label="checkbox-wrapper-div"
              >
                <CustomInput
                  type="checkbox"
                  id={`${product.id}-checkbox`}
                  className="text-primary"
                  checked={isSelected}
                  defaultValue={isSelected}
                  onChange={() => onSelect(product, isSelected)}
                />
              </div>
            )}
          </Col>
          <Col xs={2} align="start" className="h-100">
            <Row className="w-100 h-100 mx-0">
              <Col
                xs={3}
                className="d-flex flex-row align-items-center px-0 mr-2"
              >
                <img
                  src={product?.image?.url ? product?.image.url : shopBagImg}
                  alt="product-pic"
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    objectFit: 'scale-down',
                  }}
                />
              </Col>
              <Col
                xs={8}
                className="pr-0 d-flex flex-row align-items-center flex-wrap px-0"
              >
                {product.broken_name.map((word, index) => {
                  return (
                    <div
                      key={`${word}-${product.id}`}
                      className={`new-layout-card-list-strong-value ${
                        index > 0 && 'text-truncate'
                      }`}
                    >
                      {word}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
          <Col xs={2} align="start" className="h-100">
            <div className="new-layout-table-column-value h-100 text-truncate">
              #{product.code}
            </div>
          </Col>
          <Col xs={2} align="center" className="h-100">
            <div className="new-layout-table-column-value h-100 text-truncate">
              {product.bar_code || 'Não cadastrado'}
            </div>
          </Col>
          <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
              {formatPrice(product.value)}
            </div>
          </Col>
          <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
              {product.formatted_created_at}
            </div>
          </Col>
          <Col xs={1} align="center" className="h-100 px-0">
            <div className="h-100 d-flex flex-row align-items-center justify-content-around">
              {canManage ? (
                <>
                  <IconButton
                    icon="las la-pen"
                    color="primary"
                    id={`edit-btn-${product.id}`}
                    tip="Editar"
                    forceTip
                    click={() => {
                      history.push(`/produtos/${product.id}/editar`);
                    }}
                  />
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`delete-btn-${product.id}`}
                    tip="Excluir"
                    forceTip
                    click={() => onDelete(product)}
                  />
                </>
              ) : (
                <>
                  <i
                    id={`product-${product.code}-no-actions`}
                    className="las la-info-circle font-size-18"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`product-${product.code}-no-actions`}
                  >
                    Somente usuários Administradores podem executar ações com
                    produtos.
                  </UncontrolledTooltip>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row className="w-100 h-100 mx-0 d-lg-none">
          <Col className="px-0">
            <Row className="w-100 mx-0 mb-4">
              <Col xs={9} />
              <Col xs={3} className="pl-4 pr-0" align="end">
                {canManage ? (
                  <div className="h-100 d-flex flex-row justify-content-around pl-3">
                    <IconButton
                      icon="las la-pen"
                      color="primary"
                      id={`edit-mobile-btn-${product.id}`}
                      tip="Editar"
                      click={() => {
                        history.push(`/produtos/${product.id}/editar`);
                      }}
                    />
                    <IconButton
                      icon="las la-trash"
                      color="danger"
                      id={`delete-mobile-btn-${product.id}`}
                      tip="Excluir"
                      click={() => onDelete(product)}
                    />
                  </div>
                ) : (
                  <div className="h-100 d-flex flex-row justify-content-end">
                    <i
                      id={`product-${product.code}-no-actions`}
                      className="las la-info-circle font-size-18"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`product-${product.code}-no-actions`}
                    >
                      Somente usuários Administradores podem executar ações com
                      produtos.
                    </UncontrolledTooltip>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="w-100 mx-0 mb-4">
              <Col className="px-0" align="center">
                <img
                  src={product?.image?.url ? product?.image.url : shopBagImg}
                  alt="product-pic"
                  style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '50%',
                    objectFit: 'scale-down',
                    // border: '1px solid gray',
                  }}
                />
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Produto:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {product.name}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Referência:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    #{product.code}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Código de Barras:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {product.bar_code}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Preço:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {formatPrice(product.value)}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Criação:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {product.formatted_created_at}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
