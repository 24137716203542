export const defineURL = (notification, roles) => {
  const isStockist = roles?.some(role => role.slug === 'stockist');

  const isSalesman = roles?.some(role => role.slug === 'salesman');

  const isConsultant = roles?.some(role => role.slug === 'digital-consultant');

  const isCrossManager = roles?.some(role => role.slug === 'cross-manager');

  if (notification.class === 'App\\Events\\AnnouncementBroadcasted') {
    return '/anuncios';
  }
  if (notification.type === 'App\\Events\\SaleNotification') {
    if (isStockist) {
      return `/pedido/${notification.data.id}`;
    }
    if (isSalesman || isConsultant || isCrossManager) {
      return `/venda/${notification.data.id}`;
    }
  }
  return '#';
};

export const defineColor = notification => {
  if (notification.type === 'App\\Events\\DangerNotification') {
    return 'text-danger';
  }
  if (notification.type === 'App\\Events\\PublicNotification') {
    return 'text-info';
  }
  if (notification.type === 'App\\Events\\DeliveryDoneNotification') {
    return 'text-info';
  }

  return null;
};

export const calculateTime = t => {
  const toSeconds = myTime => {
    return Math.floor(myTime / 1000);
  };
  const toMinutes = myTime => {
    return Math.floor(myTime / (60 * 1000));
  };
  const toHours = myTime => {
    return Math.floor(myTime / (60 * 60 * 1000));
  };
  const toDays = myTime => {
    return Math.floor(myTime / (24 * 60 * 60 * 1000));
  };
  const toWeeks = myTime => {
    return Math.floor(myTime / (7 * 24 * 60 * 60 * 1000));
  };
  const toMonths = myTime => {
    return Math.floor(myTime / (30.42 * 24 * 60 * 60 * 1000));
  };
  const toYears = myTime => {
    return Math.floor(myTime / (12 * 30.42 * 24 * 60 * 60 * 1000));
  };

  const rawTargetTime = new Date(t);
  const rawNow = new Date();

  const targetTime = rawTargetTime.getTime();
  const now = rawNow.getTime();

  const timeDifference = now - targetTime;

  if (toSeconds(timeDifference) < 60) {
    return `Há ${toSeconds(timeDifference)} segundo${
      toSeconds(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  if (toMinutes(timeDifference) < 60) {
    return `Há ${toMinutes(timeDifference)} minuto${
      toMinutes(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  if (toHours(timeDifference) < 24) {
    return `Há ${toHours(timeDifference)} hora${
      toHours(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  if (toDays(timeDifference) < 7) {
    return `Há ${toDays(timeDifference)} dia${
      toDays(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  if (timeDifference / (7 * 24 * 60 * 60 * 1000) < 4.35) {
    return `Há ${toWeeks(timeDifference)} semana${
      toWeeks(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  if (toMonths(timeDifference) < 12) {
    return `Há ${toMonths(timeDifference)} mese${
      toMonths(timeDifference) !== 1 ? 's' : ''
    }.`;
  }
  return `Há ${toYears(timeDifference)} ano${
    toYears(timeDifference) !== 1 ? 's' : ''
  }.`;
};
