import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Form } from '@unform/web';
import api from '~/services/api';
import InputExternal from '~/components/Inputs/Input';
import { toolbarConfig } from '~/util/textToolbarConfig';

export default function EditFAQ({
  show,
  setShow,
  faqToBeEdited,
  setData,
  getURL,
  value,
  setValue,
}) {
  const formRef = useRef(null);

  const handleEditFAQ = async formData => {
    try {
      formRef.current.setErrors({});
      const answer = value.toString('html');

      const schema = Yup.object().shape({
        question: Yup.string().required('Digite a pergunta'),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      formData = {
        id: faqToBeEdited.id,
        answer,
        ...formData,
      };

      await api.put(`administrator/faqs/${faqToBeEdited?.id}`, formData);

      const { data } = await api.get(getURL);

      setData(data);
      setShow(false);
      setValue(RichTextEditor.createEmptyValue());

      toast.success('FAQ editada com sucesso!');
    } catch (err) {
      setShow(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Ocorreu um erro. Tente novamente.');
      }
      setShow(false);
      // history.push('/faq');
    }
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader
        toggle={() => {
          setShow(false);
        }}
      >
        Editar FAQ
      </ModalHeader>
      <Form ref={formRef} onSubmit={handleEditFAQ} initialData={faqToBeEdited}>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Pergunta</Label>
                <InputExternal
                  name="question"
                  type="text"
                  placeholder="Qual o título da pergunta?"
                />
              </FormGroup>
              <FormGroup>
                <Label>Resposta</Label>
                <RichTextEditor
                  name="answer"
                  value={value}
                  onChange={a => setValue(a)}
                  toolbarConfig={toolbarConfig}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="new-layout-btn main">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
