import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Alert } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

// availity-reactstrap-validation-safe
import { AvForm, AvField } from 'availity-reactstrap-validation-safe';

// actions
import { apiError } from '~/store/modules/auth/login/actions';
import api from '~/services/api';

import logoBlue from '~/assets/images/logo-blue.svg';

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPassword = () => {
  // const [sending, setSending] = useState(false);
  const [isMatch, setIsMatch] = useState(null);
  const [isSent, setIsSent] = useState(false);
  // const [hide, setHide] = useState(false);
  const [isValidToken, setIsValidToken] = useState(null);
  const history = useHistory();
  const token = useQuery().get('token');

  useEffect(() => {
    document.title = 'Nova senha | Viddy';

    if (!token) history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const error = useSelector(({ login }) => login.error);

  const dispatch = useDispatch();

  const handleValidSubmit = (_, { password, confirmpassword }) => {
    if (password === confirmpassword) {
      setIsMatch(false);
      setIsValidToken(false);
      api
        .post('/auth/set-password', { token, password })
        .then(() => {
          // setSending(true);
          setIsSent(true);
          setTimeout(() => {
            // setSending(false);
            // setIsSent(false);
            // setHide(true);
          }, 1000);
        })
        .catch(() => {
          setIsValidToken(true);
          setIsSent(false);
        });
    } else {
      setIsMatch(true);
    }
  };

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);

  return (
    <div className="new-layout-auth account-pages">
      <Row className="w-100 mx-0 my-4">
        <Col
          className="new-layout-logo-col justify-content-center"
          align="center"
        >
          <img className="new-layout-auth-logo" src={logoBlue} alt="" />
        </Col>
      </Row>
      <Row className="w-100 mx-0 my-4">
        <Col>
          <Card className="new-layout-login-card">
            <CardBody>
              {/* {!hide && ( */}
              <Row className="w-100 mx-0 my-4">
                <Col align="center">
                  <div className="new-layout-auth-welcome-msg">
                    <div className="d-block">
                      Defina uma <strong>nova senha</strong>
                    </div>
                    <div className="d-block">
                      para <strong>continuar no Viddy</strong>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* )} */}
              {isMatch && (
                <Row className="w-100 mx-0 mt-4 pt-4 px-4">
                  <Col align="center pt-4 mt-4">
                    <Alert className="mt-4 mb-0" color="danger">
                      As senhas não correspondem!
                    </Alert>
                  </Col>
                </Row>
              )}

              {isValidToken && (
                <Row className="w-100 mx-0 mt-4 pt-4 px-4">
                  <Col align="center pt-4 mt-4">
                    <Alert className="mt-4 mb-0" color="danger">
                      Sua recuperação de senha expirou!
                    </Alert>
                  </Col>
                </Row>
              )}

              {isSent && (
                <Row className="w-100 mx-0 mt-4 pt-4 px-4">
                  <Col align="center pt-4 mt-4">
                    <Alert className="mt-4 mb-0" color="success">
                      Senha alterada com sucesso!
                    </Alert>
                    <button
                      className="new-layout-auth-btn btn btn-primary btn-block waves-effect waves-light my-4"
                      type="button"
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Ir para o Login
                    </button>
                  </Col>
                </Row>
              )}

              {!isSent && (
                <Row className="w-100 mx-0 px-4 py-4">
                  <Col align="center">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      {error && (
                        <Row className="w-100">
                          <Col className="px-0">
                            <Alert color="danger">{error}</Alert>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="new-layout-auth-form-group">
                          <AvField
                            name="password"
                            label="Senha"
                            type="password"
                            errorMessage="Digite sua senha. A senha deve ter no minimo 6 caracteres."
                            required
                            placeholder="Digite sua senha"
                            minLength="6"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className="new-layout-auth-form-group">
                          <AvField
                            name="confirmpassword"
                            label="Confirmar Senha"
                            type="password"
                            errorMessage="Repita sua senha. A senha deve ter no minimo 6 caracteres e corresponder."
                            required
                            placeholder="Repita sua senha"
                            minLength="6"
                          />
                        </Col>
                      </Row>

                      <Row className="my-4">
                        <Col>
                          {isSent ? (
                            <div className="mt-3">
                              <h5 className="text-primary text-center">
                                Enviando...
                              </h5>
                            </div>
                          ) : (
                            <button
                              className="new-layout-auth-btn btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Confirmar Senha
                            </button>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col className="new-layout-auth-register-msg mt-2 text-center">
                          <Link className="d-inline" to="/login">
                            Ir para o Login
                          </Link>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
