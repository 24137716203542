import React from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';

import { Row } from 'reactstrap';

export default function RatingCard({ rating, myStore }) {
  return (
    <div>
      {rating}
      <Row className="mx-0">
        {Array(parseInt(rating, 10))
          .fill(1)
          .map((_, i) => (
            <FaStar
              // eslint-disable-next-line react/no-array-index-key
              key={`${myStore?.fantasy_name}${i}`}
              color="#ffd600"
              size={30}
            />
          ))}

        {Array(parseInt(Math.ceil(5 - rating), 10))
          .fill(1)
          .map((_, i) => (
            <FaRegStar
              // eslint-disable-next-line react/no-array-index-key
              key={`${myStore?.fantasy_name}${i}`}
              color="#ffd600"
              size={30}
            />
          ))}
      </Row>
    </div>
  );
}
