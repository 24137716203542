import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Col, Container, Row } from 'reactstrap';
import { format } from 'date-fns';
import PageHeader from '~/components/Common/PageHeader';
import { formatPrice } from '~/util/format';
import api from '~/services/api';
import MonthPicker from '~/components/MonthPicker';
import RevenueCard from '../Dashboard/RevenueCard';
import DonutChart from './DonutChart';
import MonthlyRevenue from './MonthlyRevenue';

export default function MasterDashboard() {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);

  const formMonthRef = useRef(null);

  const handleSearchByMonth = useCallback(async ({ month }) => {
    setLoading(true);
    let Month;
    if (month) {
      Month = format(month, 'yyyy-MM');
    }

    await api.get('master/pages/dashboard', {
      params: {
        month: Month,
      },
    });

    // const days = Object.keys(salesData.amount_history);
    // const seriesData = Object.values(salesData.amount_history);

    // setOptions(prevOptions => ({
    //   ...prevOptions,
    //   xaxis: { ...prevOptions.xaxis, days },
    // }));

    // const searchedData = month
    //   ? format(month, 'MMMM/yyyy', { locale: ptBR })
    //   : format(new Date(), 'MMMM/yyyy', { locale: ptBR });

    // setSeries([{ name: 'Valor', data: seriesData }]);
    // setSeriesDonut([
    //   salesData.carts_by_status[4],
    //   salesData.carts_with_referral_code_count,
    //   pendingSalesAmount(salesData),
    //   salesData.carts_by_status[6],
    // ]);
    // setStatistics(salesData);
    // setTimeNow(
    //   format(new Date(), "dd MMM yy '-' HH'h'mm", {
    //     locale: ptBR,
    //   })
    // );
    // setSearchedMonth(Month);
    // setTimeSearched(searchedData);
    // setLoading(false);
  }, []);
  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0">
          <Col lg={3} className="px-0 mb-4 mb-lg-0">
            <PageHeader
              pageTitle="DASHBOARD"
              responsivePosition="start"
              desktopPosition="start"
            />
          </Col>
          <Col
            lg={9}
            className="px-0 d-flex flex-column justify-content-center"
          >
            <Row className="w-100 mx-0 mb-4 font-size-14 font-weight-400 text-gray-600 d-flex flex-row align-items-center justify-content-end">
              <Col
                lg={1}
                className="px-0 pr-lg-2 mb-2 mb-lg-0 d-flex flex-column align-items-start align-items-lg-end"
              >
                Filtros:
              </Col>
              <Col lg={2} className="px-0 mb-2 mb-lg-0">
                <Form ref={formMonthRef} onSubmit={handleSearchByMonth}>
                  <MonthPicker
                    value={selectedDate}
                    onChange={date => {
                      setSelectedDate(date);
                      handleSearchByMonth({ date });
                    }}
                  />
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="w-100 mx-0">
          <Col lg={4} className="px-0 pr-lg-4">
            <Row className="w-100 mx-0 mb-2">
              <Col className="px-0">
                <RevenueCard
                  label="Faturamento do Ano"
                  value={formatPrice(36000)}
                />
              </Col>
            </Row>
            <Row className="w-100 mx-0 mb-2">
              <Col className="px-0">
                <RevenueCard
                  label="Faturamento do Mês"
                  value={formatPrice(3000)}
                />
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <DonutChart />
              </Col>
            </Row>
          </Col>
          <Col lg={8} className="px-0 pl-lg-4">
            <MonthlyRevenue />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
