import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Nome obrigatório!')
    .max(255, 'Digite no máximo 255 caracteres'),
  prod_amount: Yup.string()
    .required('Quantidade obrigatória!')
    .max(255, 'Digite no máximo 255 caracteres'),
  value: Yup.number()
    .required('Preço obrigatório!')
    .min(0.01, 'Preço obrigatório!')
    .typeError('Preço obrigatório!'),
});

export default schema;
