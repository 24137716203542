import * as Yup from 'yup';

const schema = maskTelephoneLength =>
  Yup.object().shape({
    name: Yup.string().required('Insira o nome do cliente'),
    telephone: Yup.string().min(maskTelephoneLength, 'Insira todos os dígitos'),
    email: Yup.string().email('E-mail no formato inválido'),
    password: Yup.string().min(6, 'Senha deve ter no mínimo 6 caracteres'),
  });

export default schema;
