import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
// import { orderBy } from 'lodash';
import api from '~/services/api';
import history from '~/services/history';
import {
  InitialLoadSuccess,
  openChatSuccess,
  // reloadChatList,
  ListMsgAsSent,
  // addsNewMessage,
  openChatRequest,
  updateChatList,
  SetNotLoadingUsers,
  ModifyUsersArray,
  SetUsersPagination,
  PrintOldMessages,
  PrintOldChats,
  PrintMoreUsers,
  SetHandlingQueue,
  MarkChatAsRead,
  MarkChatAsReadInChatsArray,
  PopulateChatsOnLogin,
  reorderChatList,
  replaceEditedChat,
} from '~/store/modules/chat/actions';
import {
  CHAT_INITIAL_LOAD_REQUEST,
  OPEN_CHAT_REQUEST,
  SEND_MSG_TO_SERVER,
  RELOAD_CHAT_LIST,
  CREATE_CHAT_REQUEST,
  SEARCH_USER,
  LOAD_OLD_MESSAGES,
  LOAD_OLD_CHATS,
  LOAD_MORE_USERS,
  CREATE_NEW_GROUP_CHAT,
  MARK_AS_READ,
  LOAD_CHATS_ON_LOGIN,
  REORDER_CHAT_LIST,
  DELETE_CHAT,
  EDIT_CHAT,
} from './actionsTypes';
// import { handleChatNotification } from '../notifications/saga';
import { RemoveChatNotification } from '../notifications/actions';

function* ReloadChatList() {
  try {
    const {
      data: { chats, meta: chatsPagination },
    } = yield call(api.get, 'chats');

    if (chats && chatsPagination) {
      yield put(updateChatList({ chats, chatsPagination }));
    }

    return;
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao recarregar a lista de chats. Tente novamente mais tarde.'
    );
  }
}

function* ReorderChatList({ payload: newChat }) {
  const chats = yield select(({ chat }) => chat.chats);

  if (chats.length) {
    const chatExists = chats.some(chat => chat.id === newChat.id);

    if (chatExists) {
      const chatsDraft = chats.map(chat => {
        if (chat.id === newChat.id) {
          return newChat;
        }
        return chat;
      });

      chatsDraft.sort((a, b) => {
        const ta = new Date(a.updated_at);
        const tb = new Date(b.updated_at);
        return tb.getTime() - ta.getTime();
      });

      yield put(updateChatList({ chats: chatsDraft, chatsPagination: null }));
    } else {
      yield put(
        updateChatList({ chats: [newChat, ...chats], chatsPagination: null })
      );
    }
  } else {
    yield put(updateChatList({ chats: [newChat], chatsPagination: null }));
  }
}

function* openChat(action) {
  const { chat } = action.payload;
  try {
    const {
      data: { messages, meta },
    } = yield call(api.get, `chats/${chat.id}/messages`);

    if (messages && meta) {
      yield put(MarkChatAsRead(chat));
      yield put(RemoveChatNotification(chat));
      yield put(
        openChatSuccess({
          chat,
          messages: messages.reverse(),
          msgsPagination: meta,
        })
      );
    }
  } catch (err) {
    // console.log(err);
    toast.error('Houve um erro ao abrir o chat. Tente novamente mais tarde.');
  }
}

function* initialLoad() {
  try {
    const [
      // {
      //   data: { chats, meta: chatsPagination },
      // },
      {
        data: { users, meta },
      },
    ] = yield all([
      // call(api.get, 'chats'),
      call(api.get, 'users'),
    ]);

    // if (chats.lenght === 0 || chats.lenght < chatsPagination.per_page) {
    //   yield put(SetThereIsStillOldChatsToBeLoaded(false));
    // }

    yield put(
      InitialLoadSuccess({
        users,
        usersPagination: meta,
        // chats,
        // chatsPagination,
      })
    );
  } catch (err) {
    history.goBack();
  }
}

function* SendMsgToServer(action) {
  const msg = action.payload;

  try {
    if (msg.content) {
      const data = yield call(api.post, `chats/${msg.chat.id}/messages`, {
        content: msg.content,
      });

      if (data) {
        const response = data.data.message;

        const message = {
          ref: msg.ref,
          ...response,
        };

        const newChatDraft = {
          ...response.chat,
          updated_at: new Date().toISOString(),
          has_unread_messages: false,
        };
        yield put(ListMsgAsSent(message));
        yield put(SetHandlingQueue(false));
        yield put(reorderChatList(newChatDraft));
      }
    } else if (msg.attachment) {
      const formData = new FormData();
      formData.append('attachment', msg.attachment);

      const data = yield call(
        api.post,
        `chats/${msg.chat.id}/messages`,
        formData
      );

      if (data) {
        const response = data.data.message;
        const message = {
          ref: msg.ref,
          ...response,
        };

        const newChatDraft = {
          ...response.chat,
          updated_at: new Date().toISOString(),
          has_unread_messages: false,
        };

        yield put(ListMsgAsSent(message));
        yield put(SetHandlingQueue(false));
        yield put(reorderChatList(newChatDraft));
      }
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao enviar a mensagem. Tente novamente mais tarde.'
    );
  }
}

function* SearchUser({ payload }) {
  const filter = payload;

  const {
    data: { users, meta },
  } = yield call(api.get, 'users', {
    params: { query: filter },
  });

  if (users && meta) {
    yield put(ModifyUsersArray(users));
    yield put(SetUsersPagination(meta));
    yield put(SetNotLoadingUsers());
  }
}

function* CreateChatRequest({ payload: { user } }) {
  try {
    const {
      data: { chat },
    } = yield call(api.post, 'chats', {
      interlocutor_id: user.id,
    });

    if (chat) {
      yield put(openChatRequest({ chat }));
      yield put(reorderChatList(chat));
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao criar um novo chat. Tente novamente mais tarde.'
    );
  }
}

function* LoadOldMessages({ payload }) {
  const { params, chat } = payload;
  try {
    const {
      data: { messages, meta },
    } = yield call(api.get, `chats/${chat.id}/messages`, {
      params,
    });

    if (messages && meta) {
      yield put(
        PrintOldMessages({ oldMsgs: messages.reverse(), msgsPagination: meta })
      );
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao carregar as mensagens anteriores. Tente novamente mais tarde.'
    );
  }
}

function* LoadOldChats({ payload }) {
  const { params } = payload;
  try {
    const {
      data: { chats, meta },
    } = yield call(api.get, `chats`, {
      params,
    });

    if (chats && meta) {
      yield put(PrintOldChats({ oldChats: chats, chatsPagination: meta }));
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao carregar os chats antigos. Tente novamente mais tarde.'
    );
  }
}

function* LoadMoreUsers({ payload }) {
  const { params } = payload;
  try {
    const {
      data: { users, meta },
    } = yield call(api.get, `users`, {
      params,
    });

    if (users && meta) {
      yield put(
        PrintMoreUsers({ incomingUsers: users, usersPagination: meta })
      );
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao carregar mais usuários. Tente novamente mais tarde.'
    );
  }
}

function* CreateNewGroupChat({ payload }) {
  const { users, newGroupName } = payload;
  try {
    const { data } = yield call(api.post, 'chats', {
      members_id: users.map(user => user.id),
      name: newGroupName,
    });
    if (data) {
      const newChat = data.chat;
      yield put(openChatRequest({ chat: newChat }));
      yield put(reorderChatList(newChat));
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao criar um novo chat em grupo. Tente novamente mais tarde.'
    );
  }
}

function* LoadChatsOnLogin() {
  try {
    const {
      data: { chats, meta },
    } = yield call(api.get, 'chats');

    if (chats && meta) {
      yield put(PopulateChatsOnLogin(chats, meta));
    }
  } catch (err) {
    if (err.response && err.response?.status === 503) return;
    toast.error(err?.response?.data?.message);
  }
}

function* DeleteChat({ payload: chat }) {
  try {
    yield call(api.delete, `chats/${chat.id}`);
    toast.success('Chat excluído com sucesso!');
  } catch (err) {
    // console.log(err);
    toast.error('Houve um erro ao excluir o chat. Tente novamente mais tarde.');
  }
}

function* EditChat({ payload: params }) {
  const { chat, newName, users } = params;

  try {
    const newChatObj = {
      ...chat,
      name: newName,
      members: users,
    };

    const res = yield call(api.patch, `chats/${chat.id}`, {
      members_id: users.map(user => user.id),
      name: newName,
    });

    if (res) {
      yield put(replaceEditedChat(newChatObj));
    }
  } catch (err) {
    // console.log(err);
    toast.error(
      'Houve um erro ao editar o chat em grupo. Tente novamente mais tarde.'
    );
  }
}

function* markAsRead({ payload: chat }) {
  try {
    const res = yield call(api.patch, `chats/${chat.id}/mark-as-read`);

    if (res) {
      const readChat = res.data.chat;
      yield put(MarkChatAsReadInChatsArray(readChat));
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
}

export default all([
  takeLatest(CHAT_INITIAL_LOAD_REQUEST, initialLoad),
  takeLatest(OPEN_CHAT_REQUEST, openChat),
  takeLatest(RELOAD_CHAT_LIST, ReloadChatList),
  takeLatest(REORDER_CHAT_LIST, ReorderChatList),
  takeLatest(CREATE_CHAT_REQUEST, CreateChatRequest),
  takeLatest(SEND_MSG_TO_SERVER, SendMsgToServer),
  takeLatest(MARK_AS_READ, markAsRead),
  takeLatest(SEARCH_USER, SearchUser),
  takeLatest(LOAD_OLD_MESSAGES, LoadOldMessages),
  takeLatest(LOAD_OLD_CHATS, LoadOldChats),
  takeLatest(LOAD_CHATS_ON_LOGIN, LoadChatsOnLogin),
  takeLatest(LOAD_MORE_USERS, LoadMoreUsers),
  takeLatest(CREATE_NEW_GROUP_CHAT, CreateNewGroupChat),
  takeLatest(DELETE_CHAT, DeleteChat),
  takeLatest(EDIT_CHAT, EditChat),
]);
