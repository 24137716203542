const { useState } = require('react');

const ShowMoreText = ({ content, limit }) => {
  const [showComplete, setShowComplete] = useState(false);

  if (!content) {
    return <span>-</span>;
  }

  if (content.length <= 200) {
    return content;
  }

  const lines = content.split('\n');

  if (showComplete) {
    return (
      <span className="text-break">
        {lines.map((line, i) => (
          <>
            {line}

            {i === lines.length - 1 && (
              <button
                type="button"
                onClick={() => setShowComplete(false)}
                className="d-inline text-primary bg-transparent border-none px-0 mt-1 mb-2"
              >
                Ver menos
              </button>
            )}
            <br />
          </>
        ))}
      </span>
    );
  }

  const slicedContent = content.slice(0, limit || 200).split('\n');

  return (
    <span className="text-break">
      {slicedContent.map((line, i) => (
        <>
          {line}
          {i === slicedContent.length - 1 && (
            <>
              ...
              <button
                type="button"
                onClick={() => setShowComplete(true)}
                className="d-inline text-primary bg-transparent border-none px-0 mt-1 mb-2"
              >
                Ver mais
              </button>
            </>
          )}
          <br />
        </>
      ))}
    </span>
  );
};

export default ShowMoreText;
