/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
  UncontrolledTooltip,
  CustomInput,
} from 'reactstrap';

import { Scope } from '@unform/core';
import { ValidationError } from 'yup';
import { toast, Flip } from 'react-toastify';
import api from '~/services/api';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
import { maskBuilder } from '~/util/maskTelephone';
import { stateList } from '~/util/stateList';
import history from '~/services/history';
import PageHeader from '~/components/Common/PageHeader';
import SchemaValidator from '~/validators/form/subscriber';
// import { formatPriceToNumber } from '~/Util/format';
import Loading from '~/components/Loading';
import TextAreaWithCounter from '~/components/Inputs/TextAreaWithCounter';

const NewCustomer = () => {
  const formRef = useRef(null);
  const toastId = useRef(null);
  const [cityList, setCityList] = useState([]);

  const [searchedCEP, setSearchedCEP] = useState('');
  const [trackSaleCode, setTrackSaleCode] = useState('');
  const [telephoneV, setTelephone] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [noNumber, setNoNumber] = useState(false);

  const [addressNumber, setAddressNumber] = useState('');

  const [loading, setLoading] = useState(false);

  const formatTrackSaleCode = value => {
    const trackCode = JSON.parse(`[${value.replace(/,\s*$/, '')}]`);
    return trackCode;
  };

  useEffect(() => {
    document.title = 'Registro de Assinantes | Viddy';
  }, []);

  const handleValidSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        // if (!isBirthDateValid(data.birth_date)) return;

        data = {
          ...data,
          tracksale_campaign_code: formatTrackSaleCode(trackSaleCode),
          address: {
            ...data.address,
            number: addressNumber,
            state: selectedState?.value,
            city: selectedCity?.value,
          },
        };

        const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

        await SchemaValidator(maskTelephoneLength).validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await api.post(`/master/subscribers`, data);

        toast.success('Assinante cadastrado com sucesso!');

        toast.info(
          'O assinante cadastrado receberá um e-mail para que possa cadastrar seu usuário administrador!'
        );

        history.push(`/assinantes`);
      } catch (err) {
        if (err.response && err.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
        if (err.response?.data) {
          Object.entries(err.response.data).forEach(entrie =>
            toast.error(entrie[1])
          );
          // toast.error(err.response.data)
        }

        const validationErrors = {};

        if (err instanceof ValidationError) {
          err.inner.forEach(e => {
            validationErrors[e.path] = e.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
    },
    [telephoneV, selectedCity, selectedState, addressNumber, trackSaleCode]
  );

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
        autoClose: false,
      });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();

      toast.update(toastId.current, {
        render: 'Endereço encontrado!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });
      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  useEffect(() => {
    if (noNumber) {
      setAddressNumber('sn');
    }
  }, [noNumber]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="CADASTRAR ASSINANTE"
          backTo="/assinantes"
          responsivePosition="start"
          desktopPosition="center"
        />
        <div className={loading ? '' : 'd-none'}>
          <Loading />
        </div>
        <Form
          id="subscriber-form"
          ref={formRef}
          onSubmit={handleValidSubmit}
          noValidate
          className={!loading ? '' : 'd-none'}
        >
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Dados do Assinante
              </Row>

              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>Nome Fantasia</Label>
                  <FormGroup>
                    <Input
                      name="fantasy_name"
                      placeholder="Nome fantasia"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <Label>CNPJ</Label>
                  <FormGroup>
                    <InputMask
                      name="cnpj"
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      maskChar={null}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label>Razão Social</Label>
                  <FormGroup>
                    <Input name="name" placeholder="Razão Social" type="text" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                Dados do administrador
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2 ">
                  <Label>Nome do Responsável</Label>
                  <FormGroup>
                    <Input
                      onBlur={() => {}}
                      name="responsible_name"
                      placeholder="Nome do Responsável"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2 ">
                  <Label>E-mail</Label>
                  <i
                    id="fill-with-email-exists"
                    className="las la-info-circle font-size-18 text-secondary ml-1"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target="fill-with-email-exists"
                  >
                    Digite um e-mail válido, pois com esse e-mail será criado um
                    usuário.
                  </UncontrolledTooltip>
                  <FormGroup>
                    <Input
                      onBlur={() => {}}
                      name="email"
                      placeholder="E-mail"
                      type="email"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="w-100 mx-0 mb-3">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label>Telefone</Label>
                  <FormGroup>
                    <InputMask
                      mask={maskBuilder(telephoneV)}
                      maskChar={null}
                      value={telephoneV}
                      name="telephone"
                      placeholder="Telefone"
                      type="text"
                      onChange={e => {
                        if (
                          e.target.value.length < 15 ||
                          e.target.value[5] === '9'
                        ) {
                          setTelephone(e.target.value);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* <Scope path="keys">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Credenciais
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2 ">
                    <Label>Chave de Autorização do Track Sale</Label>
                    <FormGroup>
                      <Input
                        name="tracksale"
                        placeholder="Track Sale Key"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2 ">
                    <Label>Chave do Google Places</Label>
                    <FormGroup>
                      <Input
                        name="googleplaces"
                        placeholder="Google Places Key"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2 ">
                    <Label>Chave da Bee Delivery</Label>
                    <FormGroup>
                      <Input
                        name="beedelivery"
                        placeholder="Chave da Bee Delivery"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Scope> */}
              <Scope path="configurations">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Configurações
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2 ">
                    <Row className="w-100 mx-0">
                      <Label>Campanhas do Track Sale</Label>
                      <i
                        id="fill-with-commas-tip"
                        className="las la-info-circle font-size-18 text-secondary ml-1"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="fill-with-commas-tip"
                      >
                        Essa configuração deve ser preenchida com números
                        separados por vírgulas. Exemplo: 8, 2, 4...
                      </UncontrolledTooltip>
                    </Row>
                    <FormGroup>
                      <Input
                        name="tracksale_campaign_code"
                        placeholder="Campanhas do Track-Sale"
                        onChange={e => setTrackSaleCode(e.target.value)}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2 ">
                    <Label>Código de Cadastro para Vendedores</Label>

                    <FormGroup>
                      <Input
                        name="signup_code"
                        placeholder="Código de Cadastro para Vendedores"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Scope>
              {/* <Scope path="database_settings">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Configurações do banco
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Driver</Label>
                      <Input
                        name="driver"
                        placeholder="Driver"
                        type="text"
                        disabled
                        value="pgsql"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Host</Label>
                      <Input name="host" placeholder="Host" type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Port</Label>
                      <Input
                        name="port"
                        placeholder="Port"
                        type="text"
                        disabled
                        value="5432"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>
                        Database
                        <i
                          id="databaseType"
                          className="las la-info-circle font-size-18 ml-1"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="databaseType"
                        >
                          Estamos permitindo cadastro de assinante apenas com
                          banco PostgreSQL!
                        </UncontrolledTooltip>
                      </Label>
                      <Input
                        name="database"
                        placeholder="Database"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Nome de Usuário</Label>
                      <Input
                        name="username"
                        placeholder="Nome de Usuário"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Senha</Label>
                      <Input
                        name="password"
                        placeholder="Senha"
                        type="password"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Scope> */}
              <Scope path="address">
                <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                  Endereço
                </Row>
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <FormGroup>
                      <Label>CEP</Label>
                      <InputMask
                        name="cep"
                        placeholder="CEP"
                        mask="99999-999"
                        maskChar={null}
                        onChange={e =>
                          e.target.value.length === 9 && searchCEP()
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Logradouro</Label>
                      <Input
                        name="street_name"
                        placeholder="Logradouro"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label>Número</Label>

                    <CustomInput
                      type="switch"
                      id="noNumberSwitch"
                      name="noNumber"
                      label="Sem Número"
                      checked={noNumber}
                      className="text-primary float-right"
                      onChange={() => {
                        setNoNumber(state => !state);
                        formRef.current.setFieldError('number', null);
                      }}
                    />
                    <FormGroup>
                      <Input
                        name="number"
                        placeholder="Número"
                        type={noNumber ? 'text' : 'number'}
                        disabled={noNumber}
                        onChange={({ target: { value } }) =>
                          setAddressNumber(value)
                        }
                        value={addressNumber}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Bairro</Label>
                      <Input
                        name="neighborhood"
                        placeholder="Bairro"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <TextAreaWithCounter
                      name="complement"
                      placeholder="Complemento"
                      title="Complemento"
                      maxSize={128}
                      tabIndex={10}
                    />
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col lg={6} className="px-0 pr-lg-2">
                    <FormGroup>
                      <Label>Estado</Label>
                      <InputSelect
                        name="state"
                        options={stateList}
                        onChange={selectedOption =>
                          setSelectedState(selectedOption)
                        }
                        value={selectedState}
                        placeholder="Estado"
                        singlePath
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="px-0 pl-lg-2">
                    <FormGroup>
                      <Label>Cidade</Label>
                      <InputSelect
                        name="city"
                        options={cityList}
                        onChange={selectedOption =>
                          setSelectedCity(selectedOption)
                        }
                        value={selectedCity}
                        placeholder="Cidade"
                        isDisabled={!selectedState}
                        singlePath
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="w-100 mx-0">
                  <Col className="px-0">
                    <TextAreaWithCounter
                      name="additional_information"
                      placeholder="Informações Adicionais"
                      title="Informações Adicionais"
                      maxSize={128}
                      tabIndex={10}
                    />
                  </Col>
                </Row>
              </Scope>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4 ">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
              >
                Salvar alterações
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default NewCustomer;
