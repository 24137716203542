import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import api from '~/services/api';

export default function EditDeliveryGroup({
  show,
  setShow,
  getURL,
  setData,
  groupToBeEdited,
  setGroupToBeEdited,
}) {
  const [newGroupName, setNewGroupName] = useState('');

  const handleEditGroup = async () => {
    try {
      const dataToSend = {
        id: groupToBeEdited.id,
        name: newGroupName,
      };

      await api.put(
        `/administrator/store-groups/${groupToBeEdited.id}`,
        dataToSend
      );

      const { data } = await api.get(getURL);

      setData(data);

      toast.success('Grupo editado com sucesso!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Ocorreu um erro. Tente novamente.');
      }
    }
    setShow(false);
    setNewGroupName('');
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader
        toggle={() => {
          setShow(false);
          setGroupToBeEdited(null);
        }}
      >
        Editar Grupo
      </ModalHeader>
      <ModalBody>
        <Input
          name="name"
          type="text"
          placeholder="Informe um novo nome para o Grupo de Entrega"
          onChange={e => setNewGroupName(e.target.value)}
          defaultValue={groupToBeEdited?.name}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="new-layout-btn main"
          color="primary"
          disabled={newGroupName.length < 3}
          onClick={() => {
            handleEditGroup();
          }}
        >
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
